<script setup>
defineOptions({
  name: 'SettingsTemplatesNotificationsList', // Give the component a name
});
import {
  //axios
  axios,

  //vue
  ref,
  reactive,
  watch,
  onMounted,
  onBeforeMount,

  //pinia stores
  useLoginStore,
  useGlobalPromptsStore,
  useGlobalToastsStore,
  useUsersStore,
  useTenantsCampaignsStore,
  useTenantsTemplatesNotificationsStore,

  //vue-router
  useRouter,
  useRoute,

  //formkit

  //formkit config file

  //fontawesome
  library,
  FontAwesomeIcon,
  fas,
  far,
  fab,

  //global components - all vue components
  registerGlobalComponents,

  //base api url
  BASE_API_URL,
} from "./../clientImports";

const promptsStore = useGlobalPromptsStore();
const toastsStore = useGlobalToastsStore();
const tenantsCampaignsStore = useTenantsCampaignsStore();
const tenantsTemplatesNotificationsStore =
  useTenantsTemplatesNotificationsStore();
const loginStore = useLoginStore();

const route = useRoute();
const router = useRouter();

const tenantsTemplatesNotificationsList = ref();

onMounted(async () => {
  console.log("LIST IS MOUNTED");
  await tenantsTemplatesNotificationsStore.fetchTenantsTemplatesNotifications(); //initial populate list
  tenantsTemplatesNotificationsList.value =
    tenantsTemplatesNotificationsStore.tenantsTemplatesNotificationsList.map(
      (templateNotification) => {
        return {
          //map the templateNotification object to a new object with the keys and values we want
          id: templateNotification.id,
          name: templateNotification.name,
          title: templateNotification.title,
          description: templateNotification.description,
          color: templateNotification.color,
          department: templateNotification.department,
        };
      }
    );
});

// Watch the store's list and update the ref
watch(
  () => tenantsTemplatesNotificationsStore.tenantsTemplatesNotificationsList,
  (newList) => {
    tenantsTemplatesNotificationsList.value = newList.map(
      (templateNotification) => {
        return {
          id: templateNotification.id,
          name: templateNotification.name,
          title: templateNotification.title,
          description: templateNotification.description,
          color: templateNotification.color,
          department: templateNotification.department,
        };
      }
    );
  },
  { immediate: true }
);

//watch updateTenantsTemplateNotification and make sure it remains up to date in the component
</script>

<template>
  <div
    class="left-0 top-0 sticky bg-base-100 p-1 border-b border-b-slate-400 text-white"
  >
    <div class="flex flex-row items-center gap-5">
      <div>Templates Notifications</div>

      <div>
        <router-link
          v-if="
            loginStore?.sessionData?.permissions?.includes(
              'create:tenant-templates-notifications'
            ) ||
            loginStore?.sessionData?.permissions?.includes('*:dev-permissions')
          "
          class="btn btn-xs md:btn-sm bg-blue-500 hover:bg-blue-700 text-white border-none capitalize rounded"
          :to="{ name: 'settings-templates-notifications-create' }"
        >
          <span class="mr-2"> Create Notification </span>
          <font-awesome-icon icon="fa-solid fa-plus" />
        </router-link>
      </div>

      <SettingsTemplatesNotificationsCreate />
    </div>
  </div>
  <div class="flex-1 max-w-full h-full">
    <cLoadingSpinner
      v-if="
        tenantsTemplatesNotificationsStore.tenantsTemplatesNotificationsList ===
        null
      "
    />
    <table
      v-else-if="
        tenantsTemplatesNotificationsStore.tenantsTemplatesNotificationsList
          ?.length > 0
      "
      class="w-full"
    >
      <thead class="">
        <tr>
          <th
            v-for="(
              passport, resultIndex
            ) in tenantsTemplatesNotificationsList?.[0]"
            :key="resultIndex"
            class="bg-gray-500 text-white text-left p-1 md:px-5 md:py-2 md:text-base text-xs whitespace-nowrap"
          >
            {{ resultIndex.replaceAll("_", " ") }}
          </th>
          <th class="text-white bg-base-300 sticky right-0">
            <span class="hidden md:block"> Action </span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(passport, resultIndex) in tenantsTemplatesNotificationsList"
          :key="resultIndex"
          class="odd:bg-slate-700 even:bg-slate-800"
        >
          <td
            v-for="(cellData, cellIndex) in passport"
            :key="cellIndex"
            class="md:px-5 md:py-3 text-white text-xs md:text-base"
          >
            {{ cellData }}
          </td>
          <td class="bg-base-300 sticky right-0 p-1">
            <div
              class="flex flex-col md:flex-row justify-end md:items-center items-stretch"
            >
              <label
                v-if="
                  loginStore?.sessionData?.permissions?.includes(
                    'delete:tenant-templates-notifications'
                  ) ||
                  loginStore?.sessionData?.permissions?.includes(
                    '*:dev-permissions'
                  )
                "
                class="btn btn-xs md:btn-sm rounded-t rounded-b-none md:rounded-l md:rounded-r-none bg-rose-500 hover:bg-rose-700 text-white border-none"
                @click.stop="
                  tenantsTemplatesNotificationsStore.deleteTenantsTemplateNotification(
                    passport.id
                  )
                "
              >
                <font-awesome-icon icon="fa-solid fa-xmark" size="lg" />
              </label>

              <!-- BUG - might add this later - no editing roles for now - just have to add new and delete the old after -->
              <!-- <button
                class="btn btn-xs md:btn-sm rounded-b-none md:rounded-l-none md:rounded-r bg-blue-500 hover:bg-blue-700 text-white border-none"
                @click="
                  tenantsTemplatesNotificationsStore.initializeTenantsTemplatesNotificationsEditModal(
                    passport._id
                  )
                "
              >
                <font-awesome-icon icon="fa-solid fa-pen-to-square" />
              </button> -->

              <!-- need a router-link that sets a param with passport._id -->
              <router-link
                v-if="
                  loginStore?.sessionData?.permissions?.includes(
                    'edit:tenant-templates-notifications'
                  ) ||
                  loginStore?.sessionData?.permissions?.includes(
                    '*:dev-permissions'
                  )
                "
                class="btn btn-xs md:btn-sm rounded-b-none md:rounded-l-none md:rounded-r bg-blue-500 hover:bg-blue-700 text-white border-none"
                :to="{
                  name: 'settings-templates-notifications-edit',
                  params: { id: passport.id },
                }"
              >
                <font-awesome-icon icon="fa-solid fa-pen-to-square" />
              </router-link>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <div v-else class="bg-blue-400 text-white p-10 text-lg">
      <div class="flex flex-row justify-center items-center">
        <span class="ml-3">No TemplatesNotifications Exist Yet</span>
      </div>
    </div>
  </div>

  <div>
    <SettingsTemplatesNotificationsEdit />
  </div>
</template>
