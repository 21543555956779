//system wide logger function to group console logs

const active = true; //toggle to clear up console

const checkForFalsy = (value) => {
  return !value ? "(Falsy)" : "";
};

export const xLog = (
  title,
  message,
  options = { collapsed: true, enableTrace: false }
) => {
  const type = typeof message;

  const isFalsy = checkForFalsy(message);

  const checkLength = (type, message) => {
    if (Array.isArray(message)) {
      return message.length;
    } else if (type === "object") {
      return Object.keys(message).length;
    }
  };

  const length = checkLength(type, message);
  try {
    if (import.meta.env.VITE_ENV !== "development") return;
    if (!active) return;

    // Determine the type of message
    if (Array.isArray(message)) {
      console[options.collapsed ? "groupCollapsed" : "group"](
        "%c" + `(array) ` + "%c" + title + "%c" + `${length} Items`,
        "background: #175e1a; color: white; font-size: 0.8rem; padding: 0.3rem; border-radius: 0.2rem 0 0 0.2rem; margin-bottom: 0.1rem;",
        "background: #28a12e; color: white; font-size: 0.8rem; padding: 0.3rem; border-radius: 0; margin-bottom: 0.1rem;",
        "background: black; color: #f26666; font-size: 0.8rem; padding: 0.3rem; border-radius: 0 0.2rem 0.2rem 0; margin-bottom: 0.1rem;"
      );
      console.table(message);
      console.groupEnd();
    } else if (type === "object") {
      console[options.collapsed ? "group" : "groupCollapsed"](
        "%c" + `(${type}) ` + "%c" + title + "%c" + `${length} Items`,
        "background: #17355e; color: white; font-size: 0.8rem; padding: 0.3rem; border-radius: 0.2rem 0 0 0.2rem; margin-bottom: 0.1rem;",
        "background: #4265cf; color: white; font-size: 0.8rem; padding: 0.3rem; border-radius: 0; margin-bottom: 0.1rem;",
        "background: black; color: #f26666; font-size: 0.8rem; padding: 0.3rem; border-radius: 0 0.2rem 0.2rem 0; margin-bottom: 0.1rem;"
      );
      console.dir(message);
      console.groupEnd();
    } else if (type === "string") {
      console.log(
        "%c" +
          `(${type}) ` +
          "%c" +
          title +
          "%c" +
          `${isFalsy}` +
          "%c" +
          message,
        "background: #ed8805; color: white; font-size: 0.8rem; padding: 0.3rem; border-radius: 0.2rem 0 0 0.2rem; margin-bottom: 0.1rem;",
        "background: orange; color: white; font-size: 0.8rem; padding: 0.3rem; border-radius: 0 0 0 0; margin-bottom: 0.1rem;",
        "background: black; color: #f26666; font-size: 0.8rem; padding: 0.3rem; border-radius: 0 0.2rem 0.2rem 0; margin-bottom: 0.1rem;",
        "color: orange; padding-left: 1rem;"
      );
    } else if (type === "number") {
      console.log(
        "%c" +
          `(${type}) ` +
          "%c" +
          title +
          "%c" +
          `${isFalsy}` +
          "%c" +
          message,
        "background: red; color: white; font-size: 0.8rem; padding: 0.3rem; border-radius: 0.2rem 0 0 0.2rem; margin-bottom: 0.1rem;",
        "background: #f57a7a; color: white; font-size: 0.8rem; padding: 0.3rem; border-radius: 0 0 0 0; margin-bottom: 0.1rem;",
        "background: black; color: #f26666; font-size: 0.8rem; padding: 0.3rem; border-radius: 0 0.2rem 0.2rem 0; margin-bottom: 0.1rem;",
        "color: #f57a7a; padding-left: 1rem;"
      );
    } else if (type === "boolean") {
      console.log(
        "%c" +
          `(${type}) ` +
          "%c" +
          title +
          "%c" +
          `${isFalsy}` +
          "%c" +
          message,
        "background: #ab05ed; color: white; font-size: 0.8rem; padding: 0.3rem; border-radius: 0.2rem 0 0 0.2rem; margin-bottom: 0.1rem;",
        "background: #d27af5; color: white; font-size: 0.8rem; padding: 0.3rem; border-radius: 0 0 0 0; margin-bottom: 0.1rem;",
        "background: black; color: #f26666; font-size: 0.8rem; padding: 0.3rem; border-radius: 0 0.2rem 0.2rem 0; margin-bottom: 0.1rem;",
        "color: #d27af5; padding-left: 1rem;"
      );
    } else if (type === "function") {
      console.log(
        "%c" + `(${type}) ` + "%c" + title + "%c" + message,
        "background: #5c5b5b; color: white; font-size: 0.8rem; padding: 0.3rem; border-radius: 0.2rem 0 0 0.2rem; margin-bottom: 0.1rem;",
        "background: gray; color: white; font-size: 0.8rem; padding: 0.3rem; border-radius: 0 0.2rem 0.2rem 0; margin-bottom: 0.1rem;",
        "color: gray; padding-left: 1rem;"
      );
    }

    if (options.enableTrace === true) {
      console.trace("Trace Log:");
    }
  } catch (error) {
    console.log(error);
  }
};

//testing
//log regular data
// logger("System Logger", "String Test");

//log number data
// logger("Number Data", 100);

//log boolean data
// logger("Boolean Data", false);

//log table data
// logger("Table Data", [false, false, false], {
//   collapsed: true,
// });

//log dir data (ojects) - formats data in a collapsable tree
// logger("JSON Data", { name: "Nick", last: "Lancaster" });

// logger("Function Data", () => {});
