import {
  //axios
  axios,

  //pinia
  defineStore,
  useGlobalToastsStore,
  useGlobalPromptsStore,

  //env base url
  BASE_API_URL,
} from "../clientImports";

export const useTenantsScheduleTypesStore = defineStore({
  id: "tenants-schedule-types",
  state: () => ({
    showTenantsScheduleTypesCreateModal: false,
    showTenantsScheduleTypesEditModal: false,

    idToEdit: "",

    tenantsScheduleTypesList: [],

    currentlyCreatingScheduleType: {
      active: true,
      name: "",
      description: "",
    },

    currentlyEditingScheduleType: {
      active: true,
      name: "",
      description: "",
    },
  }),
  actions: {
    //two responsibilities - set the id to edit and then open the modal
    async initializeTenantsScheduleTypesEditModal(scheduleTypeId) {
      //set's the id of the ScheduleType that we're editing
      this.setIdToEdit(scheduleTypeId);

      //opens the edit modal
      this.showTenantsScheduleTypesEditModal = true;

      await this.fetchTenantsScheduleType();
    },

    toggleTenantsScheduleTypesCreateModal() {
      this.showTenantsScheduleTypesCreateModal =
        !this.showTenantsScheduleTypesCreateModal;
    },

    toggleTenantsScheduleTypesEditModal() {
      this.showTenantsScheduleTypesEditModal =
        !this.showTenantsScheduleTypesEditModal;
    },

    //fetches the ScheduleType for listing
    async fetchTenantsScheduleTypes() {
      try {
        const { data } = await axios.get(
          `${BASE_API_URL}/read-tenants-schedule-types`
        );

        if (data.status === "success") {
          this.tenantsScheduleTypesList = data.scheduleTypesOptions.map(
            (scheduleType) => {
              return {
                id: scheduleType._id,
                name: scheduleType.name,
                description: scheduleType.description,
                active: scheduleType.active,
              };
            }
          );
        } else {
          console.error(data.message);
        }
      } catch (error) {
        console.error();
      }
    },

    //fetching for editing
    async fetchTenantsScheduleType() {
      const id = this.idToEdit;
      try {
        const { data } = await axios.get(
          `${BASE_API_URL}/read-tenants-schedule-type/${id}`
        );

        console.log(data, "FETCHED MILESTONE");

        if (data.status === "success") {
          console.log(data.scheduleType);
          this.currentlyEditingScheduleType = data.scheduleType[0];

          console.log(this.currentlyEditingScheduleType);
        } else {
          console.error(data.message);
        }
      } catch (error) {
        console.error();
      }
    },

    setIdToEdit(scheduleTypeId) {
      this.idToEdit = scheduleTypeId;
    },

    async createTenantsScheduleType() {
      const ScheduleTypeData = this.currentlyCreatingScheduleType;

      console.log("ScheduleType DATA SENDING OUT: ", ScheduleTypeData);
      try {
        const { data } = await axios.post(
          `${BASE_API_URL}/create-tenants-schedule-type`,
          ScheduleTypeData
        );

        console.log("CREATE ScheduleType RESPONSE", data);

        if (data.status === "success") {
          const toastsStore = useGlobalToastsStore();
          toastsStore.addToast({
            title: "ScheduleType Created",
            description: "",
            buttonExists: false,
            color: "emerald",
            type: 1,
          });

          //fetch the updated list of ScheduleType
          this.fetchTenantsScheduleTypes();
          this.toggleTenantsScheduleTypesCreateModal();
        } else {
          console.error(data.message);
          toastsStore.addToast({
            title: "Error Creating ScheduleType",
            description: data.message,
            buttonExists: false,
            color: "red",
            type: 1,
          });
        }
      } catch (error) {
        console.error();
      }
    },

    async updateTenantsScheduleType() {
      const id = this.idToEdit;
      try {
        const { data } = await axios.put(
          `${BASE_API_URL}/update-tenants-schedule-type/${id}`,
          this.currentlyEditingScheduleType
        );

        if (data.status === "success") {
          console.log(data.message);

          this.toggleTenantsScheduleTypesEditModal();
          //fetch the updated list of ScheduleType
          this.fetchTenantsScheduleTypes();

          //throw toast
          const toastsStore = useGlobalToastsStore();
          toastsStore.addToast({
            title: "Schedule Type Updated",
            description: "",
            buttonExists: false,
            color: "emerald",
            type: 1,
          });
        } else {
          console.error(data.message);
          toastsStore.addToast({
            title: "Error Updating ScheduleType",
            description: data.message,
            buttonExists: false,
            color: "red",
            type: 1,
          });
        }
      } catch (error) {
        console.error();
      }
    },

    async deleteTenantsScheduleType(scheduleTypeId) {
      const promptStore = useGlobalPromptsStore();

      const group_id = scheduleTypeId;

      promptStore.addPrompt({
        type: "ScheduleType",
        title: "Delete ScheduleType?",
        message: `Are you sure you want to delete this ScheduleType?`,
      });

      const promptResponse = await promptStore.waitForPromptConfirmation();

      if (promptResponse === true) {
        promptStore.addPrompt({
          type: "ScheduleType",
          title: "Are You Sure?",
          message: `We'll hide this from your view so it can no longer be used. Any contacts or users that have this ScheduleType assigned will remain assigned and have to be manually changed. Do you still want to proceed with the deletion?`,
        });

        const promptResponse2 = await promptStore.waitForPromptConfirmation();

        if (promptResponse2 === true) {
          try {
            const { data } = await axios.delete(
              `${BASE_API_URL}/delete-tenants-schedule-type/${group_id}`
            );

            if (data.status === "success") {
              //fetch the updated list of ScheduleType
              this.fetchTenantsScheduleTypes();
            } else {
              console.error(data.message);
              toastsStore.addToast({
                title: "Error Deleting ScheduleType",
                description: data.message,
                buttonExists: false,
                color: "red",
                type: 1,
              });
            }
          } catch (error) {
            console.error();
          }
          promptStore.removePrompt();
        } else {
          promptStore.removePrompt();
        }
      } else {
        promptStore.removePrompt();
      }
    },
  },
});
