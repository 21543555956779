<script setup>
defineOptions({
  name: 'SettingsAnnouncementsEdit', // Give the component a name
});
import {
  //axios
  axios,

  //vue
  createApp,
  ref,
  reactive,
  watch,
  computed,
  inject,
  provide,
  onMounted,
  onUnmounted,
  nextTick,
  Teleport,

  //pinia
  defineStore,
  createPinia,
  storeToRefs,

  //pinia stores
  useChatStore,
  useContactAlertsStore,
  useContactFileStore,
  useContactsStore,
  useGlobalPromptsStore,
  useGlobalToastsStore,
  useLoginStore,
  useTenantsStore,
  useUsersStore,
  useTenantsAnnouncementsStore,

  //vue-router
  useRouter,
  useRoute,

  //formkit

  //formkit config file
  FKsubmitForm,

  //fontawesome
  library,
  FontAwesomeIcon,
  fas,
  far,
  fab,

  //centralized data
  usersLevelOptions,
  fieldsDefaultValidation,
  stateOptions,

  //utilities
  getRandomNumberBetween,

  //helpers
  registerGlobalComponents,

  //utilities
  stripEmptyFields,

  //base api url
  BASE_API_URL,
} from "./../clientImports";

import {
  browserTimezone,
  timezoneOptions,
} from "./../centralized_data/timezoneOptions";

const router = useRouter();
const route = useRoute();

const loginStore = useLoginStore();

const usersStore = useUsersStore();
const toastsStore = useGlobalToastsStore();
const promptsStore = useGlobalPromptsStore();
const tenantsAnnouncementsStore = useTenantsAnnouncementsStore();

//use FKsubmitForm to submit form
const submitUpdateForm = () => {
  FKsubmitForm("edit-announcements");
};
</script>

<template>
  <cSidebarModal
    sidebar-position="right"
    :show-sidebar-initial="false"
    :show-sidebar-modal="
      tenantsAnnouncementsStore.showTenantsAnnouncementsEditModal
    "
    :toggle-sidebar-modal="
      tenantsAnnouncementsStore.toggleTenantsAnnouncementsEditModal
    "
    :defaultButton="false"
  >
    <template #title>
      <h2>Edit Announcement</h2>
    </template>

    <template #body>
      <FormKit
        id="edit-announcements"
        type="form"
        :incomplete-message="false"
        submit-label="Update"
        form-class="text-white h-full flex flex-col justify-between"
        @submit="tenantsAnnouncementsStore.updateTenantsAnnouncement()"
        outer-class="m-0"
        :actions="false"
        :submit-attrs="{
          'data-theme': `light`,
          ignore: true,
        }"
      >
        <div class="grid grid-cols-1 gap-5">
          <div class="flex flex-row items-end gap-5">
            <FormKit
              type="text"
              label="Title"
              placeholder=""
              :validation="'required:trim|length:5, 40'"
              help-class="text-white"
              help=""
              outer-class="m-0 flex-1"
              input-class="$reset rounded w-full input input-sm text-gray-700 bg-gray-50"
              wrapper-class="max-w-full"
              v-model.trim="
                tenantsAnnouncementsStore.currentlyEditingAnnouncement.title
              "
            />

            <FormKit
              outer-class="col-span-1"
              type="toggle"
              name="toggle"
              label="Active"
              alt-label-position
              v-model="
                tenantsAnnouncementsStore.currentlyEditingAnnouncement.active
              "
            />
          </div>

          <!-- select option for color -->
          <FormKit
            type="select"
            label="Color"
            placeholder=""
            :validation="'required'"
            help-class="text-white"
            help=""
            outer-class="m-0 "
            :input-class="`$reset rounded w-full ${
              tenantsAnnouncementsStore.currentlyEditingAnnouncement.color !==
                '' ||
              tenantsAnnouncementsStore.currentlyEditingAnnouncement.color ===
                null
                ? `bg-${tenantsAnnouncementsStore.currentlyEditingAnnouncement.color}-500`
                : 'bg-gray-100 text-gray-700'
            } select select-sm`"
            wrapper-class="max-w-full"
            v-model="
              tenantsAnnouncementsStore.currentlyEditingAnnouncement.color
            "
            :options="[
              //choose color disabled
              { value: '', label: 'Choose Color', attrs: { disabled: true } },
              { value: 'red', label: 'Red' },
              { value: 'orange', label: 'Orange' },
              { value: 'yellow', label: 'Yellow' },
              { value: 'green', label: 'Green' },
              { value: 'emerald', label: 'Emerald' },
              { value: 'blue', label: 'Blue' },
              { value: 'purple', label: 'Purple' },
              { value: 'teal', label: 'Teal' },
              { value: 'pink', label: 'Pink' },
              { value: 'slate', label: 'Slate' },
            ]"
          />

          <FormKit
            type="textarea"
            label="Announcement"
            placeholder=""
            :validation="'required:trim|length:5, 500'"
            help-class="text-white"
            help=""
            outer-class="m-0 "
            input-class="$reset rounded w-full input input-sm text-gray-700 bg-gray-50"
            wrapper-class="max-w-full"
            v-model.trim="
              tenantsAnnouncementsStore.currentlyEditingAnnouncement
                .announcement
            "
          />

          <FormKit
            type="textarea"
            label="Description"
            placeholder=""
            :validation="'trim|length:1, 255'"
            help-class="text-white"
            help=""
            outer-class="m-0 "
            input-class="$reset rounded w-full input input-sm text-gray-700 bg-gray-50"
            wrapper-class="max-w-full"
            v-model.trim="
              tenantsAnnouncementsStore.currentlyEditingAnnouncement.description
            "
          />
        </div>
      </FormKit>
    </template>

    <template #footer>
      <!-- BUTTON FOOTER - CANCEL & UPDATE AFFILIATE GROUP -->
      <div class="flex flex-row justify-between text-white w-full">
        <button
          @click="tenantsAnnouncementsStore.toggleTenantsAnnouncementsEditModal"
          class="btn btn-xs md:btn-sm rounded bg-red-500 text-white hover:bg-red-700"
        >
          Cancel
        </button>
        <button
          @click="submitUpdateForm"
          class="btn btn-xs md:btn-sm rounded bg-emerald-500 text-white hover:bg-emerald-700"
        >
          Update
        </button>
      </div>
    </template>
  </cSidebarModal>
</template>
