//module table formatter imports
import { tenantsTableFormatter } from "./tenants/tenantsTableFormatter";
import { usersTableFormatter } from "./users/usersTableFormatter";
import { contactHistoryTableFormatter } from "./contact_file/contact_history/contactHistoryTableFormatter";
import { contactNotesTableFormatter } from "./contact_file/contact_notes/contactNotesTableFormatter";
import { tenantsCampaignsTableFormatter } from "./tenants_campaigns/tenantsCampaignsTableFormatter";

//switch statement to use appropriate table formatter based on the table name prop passed to the global cTable component
export const globalTableFormatter = (rowCellKey, rowCellData, tableName) => {
  switch (tableName) {
    case "tenants":
      return tenantsTableFormatter(rowCellKey, rowCellData);
      break;

    case "campaigns":
      return tenantsCampaignsTableFormatter(rowCellKey, rowCellData);
      break;

    case "users":
      return usersTableFormatter(rowCellKey, rowCellData);
      break;

    case "contact_history":
      return contactNotesTableFormatter(rowCellKey, rowCellData);
      break;

    case "contact_notes":
      return contactNotesTableFormatter(rowCellKey, rowCellData);
      break;

    default:
      break;
  }
};
