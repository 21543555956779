<script setup>
defineOptions({
  name: 'RecentContacts', // Give the component a name
});
import {
  ref,
  Teleport,
  useRoute,
  useRouter,
  useGlobalPromptsStore,
  useGlobalToastsStore,
  useRecentContactsStore,
  watch,
  onMounted,

  //utils
  xLog,
} from "./../clientImports";

const route = useRoute();
const router = useRouter();

const promptsStore = useGlobalPromptsStore();
const toastsStore = useGlobalToastsStore();
const recentContactsStore = useRecentContactsStore();

const recentlyVisitedContacts = ref([]);

//watch for changes to the store state var
onMounted(() => {
  recentlyVisitedContacts.value = recentContactsStore.visitedContacts;
});

watch(
  () => recentContactsStore.visitedContacts,
  (newVal, oldVal) => {
    // Set new value to local variable
    xLog("recentContactsStore.visitedContacts: ", newVal);
    recentlyVisitedContacts.value = newVal;
  }
);

const clearVisitedContacts = async () => {
  try {
    promptsStore.addPrompt({
      type: "Recent Contacts",
      title: "Clear Recent Contacts?",
      message: `Are you sure you want to wipe out the recent contacts?`,
    });

    const promptResponse = await promptsStore.waitForPromptConfirmation();

    xLog("Prompt Response: ", promptResponse);
    if (promptResponse === true) {
      promptsStore.removePrompt();

      recentContactsStore.clearRecentContacts();

      toggleModal();

      toastsStore.addToast({
        title: "Recent Contacts Cleared",
        description: "",
        buttonExists: false,
        color: "emerald",
        type: 1, // 1 = small, 2 = md, 3 = lg, 4 = lg dismissable
      });
    } else if (promptResponse === false) {
      //do nothing
      promptsStore.removePrompt();
    }
  } catch (error) {
    console.log(error);
  }
};

const showModal = ref(false);

const toggleModal = () => {
  showModal.value = !showModal.value;
};

const goToContact = () => {
  toggleModal();
};
</script>

<template>
  <button
    v-if="recentlyVisitedContacts.length > 0"
    @click="toggleModal"
    class="btn btn-xs md:btn-sm md:text-base rounded-l-none rounded-r text-xs bg-blue-400 hover:bg-blue-500 border-none"
  >
    <font-awesome-icon
      icon="fa-solid fa-clock-rotate-left"
      class="text-white"
      size="md"
    />
  </button>

  <Teleport to="#modals-target">
    <transition
      enter-active-class="transition-opacity duration-300 ease-in-out"
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="transition-opacity duration-300 ease-in-out"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div
        v-if="showModal"
        @click.self="toggleModal"
        class="z-70 backdrop-brightness-50 h-screen w-full fixed flex flex-row justify-center items-center transition-all ease-in-out duration-300"
      ></div>
    </transition>
    <transition
      enter-active-class="transition-all duration-300 ease-in-out"
      enter-from-class="-translate-x-full"
      enter-to-class="translate-x-0"
      leave-active-class="transition-all duration-300 ease-in-out"
      leave-from-class="translate-x-0"
      leave-to-class="-translate-x-full"
    >
      <div
        v-if="showModal"
        class="fixed top-0 left-0 bg-base-100 max-h-full flex flex-col justify-between border-r border-slate-400 z-70 h-full w-full md:w-1/4"
      >
        <div
          class="flex flex-row items-center justify-between text-center px-5 pt-5"
        >
          <h1 class="text-white">Recently Visited Contacts</h1>
          <button @click="toggleModal" class="btn btn-xs md:btn-sm text-white">
            <font-awesome-icon icon="fa-solid fa-xmark" />
          </button>
        </div>
        <div class="max-h-full p-5 flex-1 overflow-y-auto flex flex-col gap-1">
          <div v-if="recentlyVisitedContacts.length === 0">
            <h1 class="text-white text-center p-3 bg-red-500 rounded">
              No Visited Contacts Yet
            </h1>
          </div>

          <router-link
            @click="goToContact()"
            v-show="
              recentlyVisitedContacts.length > 0 &&
              route.params.contact_id !== contact.contact_id
            "
            class="p-3 bg-blue-500 text-white rounded flex flex-col gap-1 hover:bg-blue-700"
            v-for="(contact, pathIndex) in recentlyVisitedContacts"
            :key="pathIndex"
            :to="{
              name: contact.name,
              params: { contact_id: contact.contact_id },
            }"
            >{{ contact.contact_name }}
            <span class="text-xs italic bg-slate-700 py-1 px-2 rounded-full"
              >ID: {{ contact.contact_id }}</span
            >
          </router-link>
        </div>
        <!-- cancel button -->
        <div class="flex flex-row justify-between items-center p-1 md:p-5">
          <button
            @click="toggleModal()"
            class="btn btn-xs md:btn-sm bg-red-500 border-none capitalize text-white rounded"
          >
            Cancel
          </button>
          <div v-if="recentlyVisitedContacts.length > 0">
            <button
              @click="clearVisitedContacts()"
              class="btn btn-sm btn-block bg-red-500 border-none rounded text-white capitalize"
            >
              Clear All Contacts
            </button>
          </div>
        </div>
      </div>
    </transition>
  </Teleport>
</template>
