<script setup>
defineOptions({
  name: 'GlobalToastNotifications', // Give the component a name
});
import {
  //axios
  axios,

  //vue
  createApp,
  ref,
  reactive,
  watch,
  computed,
  inject,
  provide,
  onMounted,
  onUnmounted,
  nextTick,
  Teleport,

  //pinia
  defineStore,
  createPinia,
  storeToRefs,

  //pinia stores
  useChatStore,
  useContactAlertsStore,
  useContactFileStore,
  useContactsStore,
  useGlobalPromptsStore,
  useGlobalToastsStore,
  useLoginStore,
  useTenantsStore,

  //vue-router
  useRouter,
  useRoute,

  //formkit

  //formkit config file

  //fontawesome
  library,
  FontAwesomeIcon,
  fas,
  far,
  fab,

  //global components - all vue components
  registerGlobalComponents,

  //utils
  xLog,

  //base api url
  BASE_API_URL,
} from "./../clientImports";

const toastsStore = useGlobalToastsStore();

// Call the useContactsStore function to get an instance of the store
const contactsStore = useContactsStore();

const lengthOfToasts = toastsStore.toasts.length;

const testAddToast = () => {
  const colors = ["red", "blue", "yellow", "green"];

  let randomType = getRandomNumberBetween(1, 4);

  xLog("Toast Test, Random Type: ", randomType);

  toastsStore.addToast({
    title: "Title ".repeat(getRandomNumberBetween(1, 10)),
    description: "Lorem ".repeat(getRandomNumberBetween(1, 10)),
    buttonExists: getRandomNumberBetween(0, 1),
    color: colors[getRandomNumberBetween(0, 3)],
    type: Number(randomType), // 1 = small, 2 = md, 3 = lg, 4 = lg dismissable
  });
};
</script>

<template>
  <Teleport to="#toasts-target">
    <transition
      enter-active-class="transition-all duration-100 ease-linear"
      leave-active-class="transition-all duration-100 ease-linear"
      enter-from-class="translate-x-full"
      enter-to-class="translate-x-0"
      leave-from-class="translate-x-0"
      leave-to-class="translate-x-full"
    >
      <div
        v-if="toastsStore.toasts.length > 0"
        class="fixed flex max-md:w-screen flex-col gap-y-1 md:gap-y-2 md:top-5 md:right-5 z-90 text-base"
      >
        <!-- test the notification -->
        <!-- <button @click="testAddToast()" class="btn">TEST</button> -->
        <!-- ALERT/TOAST -->
        <transition-group
          enter-active-class="transition-all duration-100 ease-linear"
          leave-active-class="transition-all duration-100 ease-linear"
          enter-from-class="-translate-y-full md:translate-x-full"
          enter-to-class="translate-y-0 md:translate-x-0"
          leave-from-class="translate-y-0 md:translate-x-0"
          leave-to-class="-translate-y-full md:translate-x-full"
        >
          <div
            v-for="(toast, toastIndex) in toastsStore.toasts"
            @click="toastsStore.removeToast(toastIndex)"
            :key="toastIndex"
            class="relative flex flex-col p-0 overflow-hidden md:rounded shadow-md cursor-pointer max-w-96"
            :class="`bg-${toast.color}-500`"
          >
            <div class="flex flex-row justify-between">
              <div
                class="flex flex-col self-stretch justify-center p-1 md:p-3 bg-white text-slate-700"
              >
                <font-awesome-icon icon="fa-solid fa-info-circle" />
              </div>
              <div class="flex flex-col flex-1">
                <div class="flex flex-col gap-1 md:gap-2 px-2 py-1 md:p-3">
                  <div v-if="toast.title" class="font-bold text-white">
                    {{ toast.title }}
                  </div>
                  <div
                    v-if="toast.description"
                    class="text-xs md:text-sm text-white"
                  >
                    {{ toast.description }}
                  </div>
                </div>

                <div
                  v-if="toast.buttonExists"
                  class="flex flex-row items-center justify-between w-full rounded-none"
                >
                  <div class="flex flex-row w-full">
                    <!-- will conditionally show buttons -->
                    <button
                      class="flex-1 text-white capitalize rounded-none btn btn-sm"
                    >
                      View
                    </button>
                    <button
                      class="flex-1 text-white capitalize rounded-none btn btn-sm"
                    >
                      Button
                    </button>
                    <button
                      class="flex-1 text-white capitalize rounded-none btn btn-sm"
                    >
                      Button
                    </button>
                  </div>
                </div>
              </div>

              <div
                v-if="toast.type === 5"
                class="flex flex-col self-stretch justify-center border-l border-slate-200"
              >
                <button
                  @click="toastsStore.removeToast(toastIndex)"
                  :class="`hover:bg-${color}-700`"
                  class="p-3 text-white h-full"
                >
                  <font-awesome-icon icon="fa-solid fa-xmark" />
                </button>
              </div>
            </div>
          </div>
        </transition-group>
      </div>
    </transition>
  </Teleport>
</template>
