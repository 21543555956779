<script setup>
defineOptions({
  name: 'SupportTicketsCreate', // Give the component a name
});
import {
  //axios
  axios,

  //vue
  createApp,
  ref,
  reactive,
  watch,
  computed,
  inject,
  provide,
  onMounted,
  onUnmounted,
  nextTick,
  Teleport,

  //pinia
  defineStore,
  createPinia,
  storeToRefs,

  //pinia stores
  useGlobalPromptsStore,
  useGlobalToastsStore,
  useLoginStore,
  useUsersStore,
  useSupportTicketsStore,

  //vue-router
  useRouter,
  useRoute,

  //formkit

  //formkit config file
  FKsubmitForm,

  //fontawesome
  library,
  FontAwesomeIcon,
  fas,
  far,
  fab,

  //centralized data
  usersLevelOptions,
  fieldsDefaultValidation,
  stateOptions,

  //utilities
  getRandomNumberBetween,

  //helpers
  registerGlobalComponents,

  //utilities
  stripEmptyFields,

  //base api url
  BASE_API_URL,
} from "./../clientImports";

import {
  browserTimezone,
  timezoneOptions,
} from "./../centralized_data/timezoneOptions";

const router = useRouter();
const route = useRoute();

//computed that returns the current full route path
const currentRoute = computed(() => {
  return route.fullPath;
});

const loginStore = useLoginStore();

const usersStore = useUsersStore();
const toastsStore = useGlobalToastsStore();
const promptsStore = useGlobalPromptsStore();
const supportTicketsStore = useSupportTicketsStore();

//use FKsubmitForm to submit form
const submitCreateForm = () => {
  supportTicketsStore.currentlyCreatingTicket.url = currentRoute.value;
  return FKsubmitForm("create-support-ticket");
};

//onMounted log that the component has been mounted
onMounted(() => {
  console.log("SupportTicketsCreate.vue mounted");
});

const ccToAdd = ref("");

const addToCC = () => {
  supportTicketsStore.currentlyCreatingTicket.cc.push(ccToAdd.value);
  ccToAdd.value = "";
};
</script>

<template>
  <cSidebarModal
    sidebar-position="right"
    :show-sidebar-initial="false"
    :show-sidebar-modal="supportTicketsStore.showSupportTicketsCreateModal"
    :toggle-sidebar-modal="supportTicketsStore.toggleSupportTicketsCreateModal"
    :default-button="false"
  >
    <template #title>
      <h2>Create Ticket</h2>
    </template>

    <template #body>
      <FormKit
        id="create-support-ticket"
        type="form"
        :incomplete-message="false"
        submit-label="Create"
        form-class="text-white h-full flex flex-col justify-between"
        @submit="supportTicketsStore.createSupportTicket()"
        outer-class="m-0"
        :actions="false"
        :submit-attrs="{
          'data-theme': `light`,
          ignore: true,
        }"
      >
        <div class="grid grid-cols-1 gap-5">
          <FormKit
            type="text"
            label="Subject"
            placeholder=""
            :validation="'required:trim|length:5, 40'"
            help-class="text-white"
            help=""
            outer-class="m-0 "
            input-class="$reset rounded w-full input input-sm text-gray-700 bg-gray-50"
            wrapper-class="max-w-full"
            v-model.trim="supportTicketsStore.currentlyCreatingTicket.subject"
          />

          <!-- select option -->
          <FormKit
            type="select"
            label="Priority"
            placeholder=""
            help-class="text-white"
            help=""
            outer-class="m-0 "
            input-class="$reset rounded w-full input input-sm text-gray-700 bg-gray-50"
            wrapper-class="max-w-full"
            v-model.trim="supportTicketsStore.currentlyCreatingTicket.priority"
            :options="[
              {
                value: 'Low - Business Still Operational',
                label: 'Low - Business Still Operational',
              },
              {
                value: 'Medium - Annoying but Operational',
                label: 'Medium - Annoying but Operational',
              },
              {
                value: 'High - Business Processes Degraded',
                label: 'High - Business Processes Degraded',
              },
              {
                value: 'Urgent - Business Non Operational',
                label: 'Urgent - Business Non Operational',
              },
            ]"
          />

          <FormKit
            type="select"
            label="Topic"
            placeholder=""
            help-class="text-white"
            help=""
            outer-class="m-0 "
            input-class="$reset rounded w-full input input-sm text-gray-700 bg-gray-50"
            wrapper-class="max-w-full"
            v-model.trim="supportTicketsStore.currentlyCreatingTicket.topic"
            :options="[
              {
                value: 'Account Billing',
                label: 'Account Billing',
              },
              {
                value: 'System Configuration',
                label: 'System Configuration',
              },
              {
                value: 'Found Bug',
                label: 'Found Bug',
              },
              {
                value: 'Feature Request',
                label: 'Feature Request',
              },
              {
                value: 'Training',
                label: 'Training',
              },
              {
                value: 'Other',
                label: 'Other',
              },
            ]"
          />

          <div class="col-span-1 flex flex-row items-end gap-5">
            <FormKit
              type="email"
              label="CC"
              placeholder="Type email and Press 'Enter' to add to CC List"
              @keyup.enter="
                /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(ccToAdd)
                  ? addToCC()
                  : null
              "
              @keydown.enter.prevent
              :validation="'trim|email'"
              help-class="text-white"
              help=""
              outer-class="m-0 flex-1"
              input-class="$reset rounded w-full input input-sm text-gray-700 bg-gray-50"
              wrapper-class="max-w-full"
              v-model.trim="ccToAdd"
            />

            <button
              v-if="
                /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(ccToAdd)
              "
              @click="addToCC"
              type="button"
              class="btn btn-xs md:btn-sm rounded border border-slate-400 text-white hover:bg-gray-700"
            >
              <!-- Font awesome plus -->
              <font-awesome-icon icon="fa-solid fa-plus" />
            </button>
          </div>

          <div
            class="col-span-1 flex flex-row items-center gap-2 max-w-full overflow-x-none flex-wrap"
          >
            <div
              v-for="(email, i) in supportTicketsStore.currentlyCreatingTicket
                .cc"
              :key="i"
              class="bg-gray-400 rounded pl-3 flex flex-row items-center gap-3 overflow-clip border border-slate-400"
            >
              <span class="text-xs">{{ email }}</span>

              <button
                @click="
                  supportTicketsStore.currentlyCreatingTicket.cc.splice(i, 1)
                "
                class="btn btn-xs rounded-none border-none hover:bg-red-500 text-red-500"
              >
                <font-awesome-icon icon="fa-solid fa-trash" />
              </button>
            </div>
          </div>

          <FormKit
            type="textarea"
            label="Description / Issue / Request"
            :validation="'required|trim|length:5,1500'"
            help-class="text-white"
            help=""
            outer-class="m-0 "
            input-class="$reset rounded w-full input input-sm text-gray-700 bg-gray-50"
            wrapper-class="max-w-full"
            v-model.trim="supportTicketsStore.currentlyCreatingTicket.body"
          />
        </div>
      </FormKit>
    </template>

    <template #footer>
      <!-- BUTTON FOOTER - CANCEL & UPDATE Ticket -->
      <div class="flex flex-row justify-between text-white w-full">
        <button
          @click="supportTicketsStore.toggleSupportTicketsCreateModal"
          class="btn btn-xs md:btn-sm rounded bg-red-500 text-white hover:bg-red-700"
        >
          Cancel
        </button>
        <button
          @click="submitCreateForm"
          class="btn btn-xs md:btn-sm rounded bg-emerald-500 text-white hover:bg-emerald-700"
        >
          Create Ticket
        </button>
      </div>
    </template>
  </cSidebarModal>
</template>
