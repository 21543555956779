<script setup>
defineOptions({
  name: "ContactAttachmentsTab", // Give the component a name
});
import {
  //axios
  axios,

  //vue
  createApp,
  ref,
  reactive,
  watch,
  computed,
  inject,
  provide,
  onMounted,
  onUnmounted,
  nextTick,
  Teleport,

  //pinia
  defineStore,
  createPinia,
  storeToRefs,

  //pinia stores
  useChatStore,
  useContactAlertsStore,
  useContactFileStore,
  useContactsStore,
  useGlobalPromptsStore,
  useGlobalToastsStore,
  useLoginStore,
  useTenantsStore,
  useTenantsAttachmentTypesStore,
  useGlobalUploadStore,

  //vue-router
  useRouter,
  useRoute,

  //formkit

  //formkit config file

  //fontawesome
  library,
  FontAwesomeIcon,
  fas,
  far,
  fab,

  //global components - all vue components
  registerGlobalComponents,

  //utils
  xLog,

  //base api url
  BASE_API_URL,
} from "./../clientImports";

//import global timezone converter
import { globalIsoToLocal } from "./../utilities/globalTimezoneConverter";

// const contactAttachments = [
//   {
//     name: "Example File",
//     key: "contact_attachments/2fd42a69c8523991868346f66346fe13_contact_attachments_Reports (5).pdf",
//   },
// ];

const router = useRouter();
const route = useRoute();

const promptsStore = useGlobalPromptsStore();
const toastsStore = useGlobalToastsStore();
const tenantsAttachmentTypesStore = useTenantsAttachmentTypesStore();
const contactFileStore = useContactFileStore();
const uploadStore = useGlobalUploadStore();

const attachmentTypesOptions = ref([]);

onMounted(async () => {
  await tenantsAttachmentTypesStore.fetchTenantsAttachmentsDropdownList();

  attachmentTypesOptions.value =
    tenantsAttachmentTypesStore?.tenantsAttachmentTypesDropdownList;

  watch(
    () => tenantsAttachmentTypesStore?.tenantsAttachmentTypesDropdownList,
    (newVal, oldVal) => {
      attachmentTypesOptions.value = newVal;
    }
  );

  await contactFileStore.fetchContactFileDataAdditional("attachments");
});

const contactAttachments = computed(() => {
  return contactFileStore?.currentContactFile?.attachments;
});

const deleteFile = async (id, key, contactId) => {
  try {
    promptsStore.addPrompt({
      type: "Delete Attachment?",
      title: "Delete Attachment",
      message: `Are you sure you want to delete this attachment?`,
      color: "red",
      type: 1, // 1 = small, 2 = md, 3 = lg, 4 = lg dismissable
    });

    const promptResponse = await promptsStore.waitForPromptConfirmation();

    if (promptResponse === true) {
      promptsStore.removePrompt();
      const result = await uploadStore.deleteFile(id, key, contactId);

      //if response status === success
      if (result.data.status === "success") {
        toastsStore.addToast({
          title: "Attachment Deleted Successfully",
          description: `File Name: ${key}`,
          buttonExists: false,
          color: "emerald",
          type: 2,
        });
        await contactFileStore.fetchContactFileDataAdditional("attachments");
      }
    } else {
      promptsStore.removePrompt();
    }
  } catch (error) {
    console.log("ERROR DELETING FILE", error);
  }
};

const isLoading = ref(true);

watch(contactAttachments, (newVal) => {
  if (newVal) {
    isLoading.value = false;
  }
});

const markFileViewed = async (id, contactId) => {
  try {
    const result = await uploadStore.markFileViewed(id, contactId);

    //if response status === success
    if (result.data.status === "success") {
      toastsStore.addToast({
        title: "Attachment Marked as Viewed",
        description: ``,
        buttonExists: false,
        color: "emerald",
        type: 2,
      });
      await contactFileStore.fetchContactFileDataAdditional("attachments");
    }
  } catch (error) {
    console.log("ERROR MARKING FILE VIEWED", error);
  }
};

const convertBytes = (bytes) => {
  const KILOBYTES = 1024;
  const MEGABYTES = 1024 * KILOBYTES;

  if (bytes < MEGABYTES) {
    return (bytes / KILOBYTES).toFixed(2) + " KB";
  } else {
    return (bytes / MEGABYTES).toFixed(2) + " MB";
  }
};

const timeConverter = (value) => {
  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    // second: "2-digit",
  };

  let formattedDate = globalIsoToLocal(value, options);

  // Replace slashes with hyphens and remove the comma
  return formattedDate.replace(/\//g, "-").replace(",", "");
};
</script>

<template>
  <div
    class="w-full h-full max-w-full bg-base-100 overflow-y-auto overflow-x-auto"
  >
    <cLoadingSpinner v-if="isLoading" />
    <table
      v-else-if="!isLoading && contactAttachments.length > 0"
      class="w-full text-white"
    >
      <thead>
        <tr>
          <th class="capitalize whitespace-nowrap p-2 text-xs min-w-36">
            File Name
          </th>
          <th class="capitalize whitespace-nowrap p-2 text-xs">Extension</th>
          <th class="capitalize whitespace-nowrap p-2 text-xs hidden md:block">
            Type
          </th>
          <th class="capitalize whitespace-nowrap p-2 text-xs">File Size</th>
          <th class="capitalize whitespace-nowrap p-2 text-xs">Uploaded At</th>
          <th class="capitalize whitespace-nowrap p-2 text-xs">Uploaded By</th>

          <th
            class="capitalize whitespace-nowrap p-2 text-xs sticky right-0 bg-base-300"
          >
            Actions
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="file in contactAttachments" :key="file.id">
          <td class="p-2 break">{{ file.name }}</td>
          <td class="p-2 break">{{ file.extension }}</td>
          <td class="p-2 hidden md:block">
            <!-- TODO - on change post with attachments id - single update attachments array item by the id and update the attachment_type_id on the item -->
            <FormKit
              type="select"
              label=""
              :options="[
                {
                  value: 0,
                  label: '- Select Type -',
                  attrs: { disabled: true },
                },
                ...attachmentTypesOptions,
              ]"
              input-class="$reset select select-sm rounded bg-base-300 text-white w-full"
              inner-class="mt-2 mb-2"
              outer-class="$reset text-white col-span-4 md:col-span-2"
              validation="required|alphanumeric"
              wrapper-class="w-32 max-w-full"
              message-class="bg-red-500 rounded text-xs text-white p-1"
              :value="file.attachment_type_id"
              @input="
                async (value) => {
                  console.log('VALUE', value);
                  try {
                    const result = await uploadStore.updateAttachmentType(
                      file._id,
                      value,
                      contactFileStore.contactId
                    );

                    //if response status === success
                    if (result.data.status === 'success') {
                      toastsStore.addToast({
                        title: 'Attachment Type Updated Successfully',
                        // description: `File Name: ${file.name}`,
                        buttonExists: false,
                        color: 'emerald',
                        type: 2,
                      });
                      await contactFileStore.fetchContactFileDataAdditional(
                        'attachments'
                      );
                    }
                  } catch (error) {
                    console.log('ERROR UPDATING FILE TYPE', error);
                  }
                }
              "
            />
          </td>
          <td class="p-2 whitespace-nowrap">
            {{ convertBytes(Number(file.file_size)) }}
          </td>
          <td class="p-2 whitespace-nowrap">
            {{ timeConverter(file.created_at) }}
          </td>
          <td class="p-2 whitespace-nowrap">{{ file.created_by }}</td>

          <td class="sticky right-0 bg-base-300 p-2">
            <div
              class="flex flex-col md:flex-row gap-2 justify-center items-center"
            >
              <FormKit
                type="select"
                label=""
                :options="[
                  {
                    value: 0,
                    label: '- Select Type -',
                    attrs: { disabled: true },
                  },
                  ...attachmentTypesOptions,
                ]"
                input-class="$reset select select-xs rounded bg-base-300 text-white w-full"
                inner-class="mt-2"
                outer-class="$reset text-white col-span-4 md:col-span-2 md:hidden"
                validation="required|alphanumeric"
                wrapper-class="w-20 max-w-full"
                message-class="bg-red-500 rounded text-xs text-white p-1"
                :value="file.attachment_type_id"
                @input="
                  async (value) => {
                    console.log('VALUE', value);
                    try {
                      const result = await uploadStore.updateAttachmentType(
                        file._id,
                        value,
                        contactFileStore.contactId
                      );

                      //if response status === success
                      if (result.data.status === 'success') {
                        toastsStore.addToast({
                          title: 'Attachment Type Updated Successfully',
                          // description: `File Name: ${file.name}`,
                          buttonExists: false,
                          color: 'emerald',
                          type: 2,
                        });
                        await contactFileStore.fetchContactFileDataAdditional(
                          'attachments'
                        );
                      }
                    } catch (error) {
                      console.log('ERROR UPDATING FILE TYPE', error);
                    }
                  }
                "
              />
              <button
                @click="
                  file.status === 'pending'
                    ? markFileViewed(file._id, contactFileStore.contactId)
                    : null
                "
                :class="{
                  'bg-orange-500 hover:bg-orange-700':
                    file.status === 'pending',
                  'bg-emerald-500 hover:bg-emerald-700 cursor-not-allowed no-animation':
                    file.status === 'viewed',
                }"
                class="capitalize btn btn-xs md:btn-sm rounded text-white border-none"
              >
                {{ file.status }}
              </button>

              <div
                class="flex flex-col md:flex-row justify-center md:items-center items-stretch"
              >
                <!-- TODO - delete - send back id delete the item in the attachments array item -->

                <button
                  class="btn btn-xs md:btn-sm rounded-t rounded-b-none md:rounded-l md:rounded-r-none bg-rose-500 hover:bg-rose-700 text-white border-none"
                  @click="
                    deleteFile(file._id, file.key, contactFileStore.contactId)
                  "
                >
                  <font-awesome-icon icon="fa-solid fa-xmark" />
                </button>
                <!-- PREVIEW FILE -->
                <cFileRead :fileKey="file.key" />

                <!-- DOWNLOAD FILE -->
                <cFileDownload :fileKey="file.key" />
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <div
      v-else-if="!isLoading && contactAttachments.length === 0"
      class="rounded flex flex-row justify-center items-center h-full"
    >
      <span class="text-white capitalize bg-sky-400 p-5 rounded"
        >No Attachments Yet</span
      >
    </div>
  </div>
</template>
