import {
  //axios
  axios,

  //pinia
  defineStore,
  useGlobalToastsStore,
  useGlobalPromptsStore,

  //env base url
  BASE_API_URL,
} from "../clientImports";

export const useTenantsAccountTypesStore = defineStore({
  id: "tenants-account-types",
  state: () => ({
    showTenantsAccountTypesCreateModal: false,
    showTenantsAccountTypesEditModal: false,

    idToEdit: "",

    tenantsAccountTypesList: [],

    currentlyCreatingAccountType: {
      active: true,
      name: "",
      description: "",
    },

    currentlyEditingAccountType: {
      active: true,
      name: "",
      description: "",
    },
  }),
  actions: {
    //two responsibilities - set the id to edit and then open the modal
    async initializeTenantsAccountTypesEditModal(accountTypeId) {
      //set's the id of the AccountType that we're editing
      this.setIdToEdit(accountTypeId);

      //opens the edit modal
      this.showTenantsAccountTypesEditModal = true;

      await this.fetchTenantsAccountType();
    },

    toggleTenantsAccountTypesCreateModal() {
      this.showTenantsAccountTypesCreateModal =
        !this.showTenantsAccountTypesCreateModal;
    },

    toggleTenantsAccountTypesEditModal() {
      this.showTenantsAccountTypesEditModal =
        !this.showTenantsAccountTypesEditModal;
    },

    //fetches the AccountType for listing
    async fetchTenantsAccountTypes() {
      try {
        const { data } = await axios.get(
          `${BASE_API_URL}/read-tenants-account-types`
        );

        if (data.status === "success") {
          this.tenantsAccountTypesList = data.accountTypesOptions.map(
            (accountType) => {
              return {
                id: accountType._id,
                name: accountType.name,
                description: accountType.description,
                active: accountType.active,
              };
            }
          );
        } else {
          console.error(data.message);
        }
      } catch (error) {
        console.error();
      }
    },

    //fetching for editing
    async fetchTenantsAccountType() {
      const id = this.idToEdit;
      try {
        const { data } = await axios.get(
          `${BASE_API_URL}/read-tenants-account-type/${id}`
        );

        console.log(data, "FETCHED MILESTONE");

        if (data.status === "success") {
          console.log(data.accountType);
          this.currentlyEditingAccountType = data.accountType[0];

          console.log(this.currentlyEditingAccountType);
        } else {
          console.error(data.message);
        }
      } catch (error) {
        console.error();
      }
    },

    setIdToEdit(accountTypeId) {
      this.idToEdit = accountTypeId;
    },

    async createTenantsAccountType() {
      const AccountTypeData = this.currentlyCreatingAccountType;

      console.log("AccountType DATA SENDING OUT: ", AccountTypeData);
      try {
        const { data } = await axios.post(
          `${BASE_API_URL}/create-tenants-account-type`,
          AccountTypeData
        );

        console.log("CREATE AccountType RESPONSE", data);

        if (data.status === "success") {
          const toastsStore = useGlobalToastsStore();
          toastsStore.addToast({
            title: "AccountType Created",
            description: "",
            buttonExists: false,
            color: "emerald",
            type: 1,
          });

          //fetch the updated list of AccountType
          this.fetchTenantsAccountTypes();
          this.toggleTenantsAccountTypesCreateModal();
        } else {
          console.error(data.message);
          toastsStore.addToast({
            title: "Error Creating AccountType",
            description: data.message,
            buttonExists: false,
            color: "red",
            type: 1,
          });
        }
      } catch (error) {
        console.error();
      }
    },

    async updateTenantsAccountType() {
      const id = this.idToEdit;
      try {
        const { data } = await axios.put(
          `${BASE_API_URL}/update-tenants-account-type/${id}`,
          this.currentlyEditingAccountType
        );

        if (data.status === "success") {
          console.log(data.message);

          this.toggleTenantsAccountTypesEditModal();
          //fetch the updated list of AccountType
          this.fetchTenantsAccountTypes();

          //throw toast
          const toastsStore = useGlobalToastsStore();
          toastsStore.addToast({
            title: "Account Type Updated",
            description: "",
            buttonExists: false,
            color: "emerald",
            type: 1,
          });
        } else {
          console.error(data.message);
          toastsStore.addToast({
            title: "Error Updating AccountType",
            description: data.message,
            buttonExists: false,
            color: "red",
            type: 1,
          });
        }
      } catch (error) {
        console.error();
      }
    },

    async deleteTenantsAccountType(accountTypeId) {
      const promptStore = useGlobalPromptsStore();

      const group_id = accountTypeId;

      promptStore.addPrompt({
        type: "AccountType",
        title: "Delete AccountType?",
        message: `Are you sure you want to delete this AccountType?`,
      });

      const promptResponse = await promptStore.waitForPromptConfirmation();

      if (promptResponse === true) {
        promptStore.addPrompt({
          type: "AccountType",
          title: "Are You Sure?",
          message: `We'll hide this from your view so it can no longer be used. Any contacts or users that have this AccountType assigned will remain assigned and have to be manually changed. Do you still want to proceed with the deletion?`,
        });

        const promptResponse2 = await promptStore.waitForPromptConfirmation();

        if (promptResponse2 === true) {
          try {
            const { data } = await axios.delete(
              `${BASE_API_URL}/delete-tenants-account-type/${group_id}`
            );

            if (data.status === "success") {
              //fetch the updated list of AccountType
              this.fetchTenantsAccountTypes();
            } else {
              console.error(data.message);
              toastsStore.addToast({
                title: "Error Deleting AccountType",
                description: data.message,
                buttonExists: false,
                color: "red",
                type: 1,
              });
            }
          } catch (error) {
            console.error();
          }
          promptStore.removePrompt();
        } else {
          promptStore.removePrompt();
        }
      } else {
        promptStore.removePrompt();
      }
    },
  },
});
