import {
  //axios
  axios,

  //pinia
  defineStore,

  //env base url
  BASE_API_URL,

  //utils
  identifyQueryTypes,
  xLog,
} from "../clientImports";

export const useSearchContactStore = defineStore({
  id: "search-contact",
  state: () => ({
    searchQuery: "",
    searchResults: false, // set results and then wipe after modal close
    resultsShown: false,
    keysOrder: [
      "_id",
      "first_name",
      "last_name",
      "phone_primary",
      "phone_mobile",
      "business_name",
      "business_phone_primary",
      "email_primary",
      "email_secondary",
      "email_work",
      "business_email",
      "business_email_secondary",
      "spouse_email_primary",
      "spouse_email_secondary",
      "spouse_email_work",
      "phone_secondary",
      "phone_work",
      "business_phone_secondary",
      "spouse_phone_primary",
      "spouse_phone_secondary",
      "spouse_phone_mobile",
      "spouse_phone_work",
    ],
  }),
  actions: {
    async searchContact(searchQuery) {
      //generate query based on search input
      const queryTypes = identifyQueryTypes(searchQuery);

      xLog("queryTypes: ", queryTypes);

      xLog("Posting to:", `${BASE_API_URL}/search-contact`);

      const response = await axios.post(`${BASE_API_URL}/search-contact`, {
        queryTypes,
      });

      this.openResultsModal();

      this.searchResults = this.normalizeKeysOrder(response.data.searchResults);

      xLog("searchResults: ", this.searchResults);
    },

    normalizeKeysOrder(results) {
      const reorderKeys = (keysOrder, obj) => {
        return Object.fromEntries(
          keysOrder
            .filter((key) => obj.hasOwnProperty(key))
            .map((key) => [key, obj[key]])
        );
      };

      // Reorder keys for each object in the array
      const normalizedData = results.map((obj) =>
        reorderKeys(this.keysOrder, obj)
      );

      return normalizedData;
    },

    openResultsModal() {
      this.resultsShown = true;
    },

    closeResultsModal() {
      this.resultsShown = false;
    },
  },
});
