// src/helpers/globalComponents.js

// Function to dynamically import and globally register components
export const getGlobalComponents = () => {
  // Eagerly load all components
  const modules = import.meta.glob("../components/**/*.vue", { eager: true });

  // Create an object to store component names and modules
  const components = {};

  for (const path in modules) {
    const component = modules[path].default || modules[path];
    const componentName = path
      .split("/")
      .pop()
      .replace(/\.\w+$/, ""); // Extract component name
    components[componentName] = component; // Store the component by its name
  }

  return components; // Return the components object for further use
};

export const registerGlobalComponents = (app) => {
  const components = getGlobalComponents();

  // Register each component globally
  for (const [componentName, component] of Object.entries(components)) {
    app.component(componentName, component);
  }
};
