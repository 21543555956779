<script setup>
defineOptions({
  name: 'SupportTicketsList', // Give the component a name
});
import {
  //axios
  axios,

  //vue
  ref,
  reactive,
  computed,
  watch,
  onMounted,
  onBeforeMount,

  //pinia stores
  useLoginStore,
  useGlobalPromptsStore,
  useGlobalToastsStore,
  useUsersStore,
  useSupportTicketsStore,

  //vue-router
  useRouter,
  useRoute,

  //formkit

  //formkit config file

  //fontawesome
  library,
  FontAwesomeIcon,
  fas,
  far,
  fab,

  //global components - all vue components
  registerGlobalComponents,

  //base api url
  BASE_API_URL,
} from "./../clientImports";

const promptsStore = useGlobalPromptsStore();
const toastsStore = useGlobalToastsStore();

const supportTicketsStore = useSupportTicketsStore();
const loginStore = useLoginStore();

const route = useRoute();
const router = useRouter();

let campaignsList = reactive([]);

onMounted(async () => {
  await supportTicketsStore.fetchSupportTickets(); //initial populate affiliate list
});

//computed function to return all the tickets in a new map with these columns: id, subject, status, priority, topic
const supportTicketsList = computed(() => {
  if (supportTicketsStore.supportTicketsList) {
    return supportTicketsStore.supportTicketsList.map((ticket) => {
      return {
        id: ticket.id,
        subject: ticket.subject,
        status: ticket.status,
        priority: ticket.priority,
        topic: ticket.topic,
      };
    });
  }
});
</script>

<template>
  <div
    class="left-0 top-0 sticky bg-base-100 p-1 border-b border-b-slate-400 text-white"
  >
    <div class="flex flex-row items-center gap-5">
      <div>Tickets</div>

      <div>
        <router-link
          class="btn btn-xs md:btn-sm bg-blue-500 hover:bg-blue-700 text-white border-none capitalize rounded"
          :to="{ name: 'support-tickets-create' }"
        >
          <span class="mr-2"> Create Ticket </span>
          <font-awesome-icon icon="fa-solid fa-plus" />
        </router-link>
      </div>

      <!-- <SupportTicketsCreate /> -->
    </div>
  </div>
  <div class="flex-1 max-w-full h-full">
    <cLoadingSpinner v-if="supportTicketsStore.supportTicketsList === null" />
    <table
      v-else-if="supportTicketsStore.supportTicketsList?.length > 0"
      class="w-full"
    >
      <thead class="">
        <tr>
          <th
            v-for="(ticket, resultIndex) in supportTicketsList[0]"
            :key="resultIndex"
            class="bg-gray-500 text-white text-left p-1 md:px-5 md:py-2 md:text-base text-xs whitespace-nowrap"
          >
            {{ resultIndex.replaceAll("_", " ") }}
          </th>
          <th class="text-white bg-base-300 sticky right-0">
            <span class="hidden md:block"> Action </span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(ticket, resultIndex) in supportTicketsList"
          :key="resultIndex"
          class="odd:bg-slate-700 even:bg-slate-800"
        >
          <td
            v-for="(cellData, cellIndex) in ticket"
            :key="cellIndex"
            class="md:px-5 md:py-3 text-white text-xs md:text-base"
          >
            {{ cellData }}
          </td>
          <td class="bg-base-300 sticky right-0 p-1">
            <div
              class="flex flex-col md:flex-row justify-end md:items-center items-stretch"
            >
              <label
                v-if="
                  loginStore?.sessionData?.permissions?.includes(
                    'delete:support-tickets'
                  ) ||
                  loginStore?.sessionData?.permissions?.includes(
                    '*:dev-permissions'
                  )
                "
                class="btn btn-xs md:btn-sm rounded-t rounded-b-none md:rounded-l md:rounded-r-none bg-rose-500 hover:bg-rose-700 text-white border-none"
                @click.stop="supportTicketsStore.deleteSupportTicket(ticket.id)"
              >
                <font-awesome-icon icon="fa-solid fa-xmark" size="lg" />
              </label>

              <!-- BUG - might add this later - no editing roles for now - just have to add new and delete the old after -->
              <!-- <button
                class="btn btn-xs md:btn-sm rounded-b-none md:rounded-l-none md:rounded-r bg-blue-500 hover:bg-blue-700 text-white border-none"
                @click="
                  supportTicketsStore.initializeSupportTicketsEditModal(
                    ticket._id
                  )
                "
              >
                <font-awesome-icon icon="fa-solid fa-pen-to-square" />
              </button> -->

              <!-- need a router-link that sets a param with ticket._id -->
              <router-link
                v-if="
                  loginStore?.sessionData?.permissions?.includes(
                    'edit:support-tickets'
                  ) ||
                  loginStore?.sessionData?.permissions?.includes(
                    '*:dev-permissions'
                  )
                "
                class="btn btn-xs md:btn-sm rounded-b-none md:rounded-l-none md:rounded-r bg-blue-500 hover:bg-blue-700 text-white border-none"
                :to="{
                  name: 'support-tickets-edit',
                  params: { id: ticket.id },
                }"
              >
                <!-- font awesome eyeball icon -->
                <font-awesome-icon icon="fa-solid fa-eye" />
              </router-link>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <div v-else class="bg-blue-400 text-white p-10 text-lg">
      <div class="flex flex-row justify-center items-center">
        <span class="ml-3">No Tickets Exist Yet</span>
      </div>
    </div>
  </div>

  <div></div>
</template>
