const determineApiUrl = () => {
  const protocol = window.location.protocol;
  const host = window.location.hostname;

  switch (import.meta.env.VITE_ENV) {
    case "production":
      console.log("IT'S PRODUCTION", `${protocol}//${host}/api`);
      return `${protocol}//${host}/api`; //no port neccessary
    case "staging":
      console.log("IT'S STAGING", `${protocol}//${host}/api`);
      return `${protocol}//${host}/api`; //no port neccessary
    default:
      // local development
      if (host.endsWith(".localhost")) {
        console.log("IT'S LOCALHOST", `${protocol}//${host}:3000/api`);
        return `${protocol}//${host}:3000/api`; //port neccessary
      } else {
        console.log("IT'S LOCALHOST ELSE", `${protocol}//${host}:3000/api`);
        return `${protocol}//${host}:3000/api`; //port ncessesary
      }
  }
};

export const BASE_API_URL = determineApiUrl();

console.log("App Version: ", import.meta.env.VITE_VERSION);
console.log("Environment: ", import.meta.env.VITE_ENV);

console.log("Protocol: ", window.location.protocol);
console.log("Host: ", window.location.host);

console.log("Backend Calls To: ", BASE_API_URL);
