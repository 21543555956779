<script setup>
defineOptions({
  name: 'ContactTags', // Give the component a name
});
import {
  //axios
  axios,

  //vue
  createApp,
  ref,
  reactive,
  watch,
  computed,
  inject,
  provide,
  onMounted,
  onUpdated,
  onUnmounted,
  nextTick,
  Teleport,

  //pinia
  defineStore,
  createPinia,
  storeToRefs,

  //pinia stores
  useChatStore,
  useContactAlertsStore,
  useContactFileStore,
  useContactsStore,
  useGlobalPromptsStore,
  useGlobalToastsStore,
  useLoginStore,
  useTenantsStore,
  useTenantsTagsStore,

  //vue-router
  useRouter,
  useRoute,

  //formkit

  //formkit config file

  //fontawesome
  library,
  FontAwesomeIcon,
  fas,
  far,
  fab,

  //global components - all vue components
  registerGlobalComponents,

  //base api url
  BASE_API_URL,
} from "./../clientImports";

const promptsStore = useGlobalPromptsStore();

const contactFileStore = useContactFileStore();

const tenantsTagsStore = useTenantsTagsStore();

const toastsStore = useGlobalToastsStore();

const currentTags = ref([]);

// fetch the tenant tags list
onMounted(async () => {
  await tenantsTagsStore.fetchTenantsTags();
});

// set currentTags to the contact files current set of tags
onMounted(() => {
  currentTags.value = contactFileStore.currentContactFile?.tags || [];
});

// LEFTOFF - can't set the currentTags until the tenantsTag list is set on formkit element

// computed that returns the tenant tags for options of formkit format
const currentTagsMap = computed(() => {
  return tenantsTagsStore.tenantsTagsList.map((tag) => {
    return {
      label: tag.name,
      value: tag.id,
      color: tag.color,
      description: tag.description,
      id: tag.id,
    };
  });
});

const handleTaglistChanged = (newTags) => {
  alert("EMITTED TAGS: " + newTags);
  currentTags.value = newTags;

  contactFileStore.updateManyToOneSingle("tags", currentTags.value);
};
</script>

<template>
  <div class="flex flex-row w-full items-center border-y border-y-slate-400">
    <!-- taglist component here -->

    <pre>
      {{ currentTags }}
    </pre>

    <pre>
      {{ currentTagsMap }}
    </pre>
    <cTaglist
      label=""
      :options="currentTagsMap"
      :currentValue="currentTags"
      @tagslist-changed="handleTaglistChanged"
    ></cTaglist>
  </div>
</template>
