<script setup>
defineOptions({
  name: 'ContactFormsTabWrapper', // Give the component a name
});
import {
  //axios
  axios,

  //vue
  createApp,
  ref,
  reactive,
  watch,
  computed,
  inject,
  provide,
  onMounted,
  onUnmounted,
  nextTick,
  Teleport,

  //pinia
  defineStore,
  createPinia,
  storeToRefs,

  //pinia stores
  useChatStore,
  useContactAlertsStore,
  useContactFileStore,
  useContactsStore,
  useGlobalPromptsStore,
  useGlobalToastsStore,
  useLoginStore,
  useTenantsStore,
  useTenantsFormsStore,
  useContactFormsStore,

  //vue-router
  useRouter,
  useRoute,

  //formkit

  //formkit config file

  //fontawesome
  library,
  FontAwesomeIcon,
  fas,
  far,
  fab,

  //global components - all vue components
  registerGlobalComponents,

  //base api url
  BASE_API_URL,
  FKsubmitForm,
} from "./../clientImports";

const contactFormsStore = useContactFormsStore();
</script>

<template>
  <ContactFormsTab v-if="!contactFormsStore.contactFormPoppedOutView" />

  <Teleport
    v-if="contactFormsStore.contactFormPoppedOutView"
    to="#modals-target"
  >
    <div class="w-screen h-screen backdrop-brightness-50 z-50 fixed">
      <ContactFormsTab />
    </div>
  </Teleport>
</template>
