<script setup>
defineOptions({
  name: 'SignUpPage', // Give the component a name
});
import {
  //axios
  axios,

  //vue
  createApp,
  ref,
  reactive,
  watch,
  computed,
  inject,
  provide,
  onMounted,
  onUnmounted,
  onBeforeMount,
  onBeforeUnmount,
  nextTick,
  Teleport,

  //pinia
  defineStore,
  createPinia,
  storeToRefs,

  //pinia stores
  useChatStore,
  useContactAlertsStore,
  useContactFileStore,
  useContactsStore,
  useGlobalPromptsStore,
  useGlobalToastsStore,
  useGlobalUploadStore,
  useLoginStore,
  useTenantsStore,

  //vue-router
  useRouter,
  useRoute,

  //formkit

  //formkit config file

  //fontawesome
  library,
  FontAwesomeIcon,
  fas,
  far,
  fab,

  //global components - all vue components
  registerGlobalComponents,

  //centralized data
  expirationMonths,
  expirationYears,
  stateOptions,

  //utils
  xLog,

  //base api url
  BASE_API_URL,
} from "./../clientImports";

// Call the useLoginStore function to get an instance of the store
const loginStore = useLoginStore();

const toastsStore = useGlobalToastsStore();
const uploadStore = useGlobalUploadStore();

const router = useRouter();
const route = useRoute();

// TODO - un commment these and delete static credentials before launch
const signUpData = reactive({
  //step 1 - company
  tenant_name: "",
  company_abbreviation: "",
  user_count: "",

  //step 2 - user
  first_name: "",
  last_name: "",
  phone_primary: "",
  email: "",
  password: "",
  confirm_password: "",

  //step 3 - payment method

  card_type: "", //visa, mastercard, amex, discover
  name_on_card: "",
  card_number: "",
  cvv: "",
  expiration_month: "",
  expiration_year: "",

  //step 4 - billing details

  billing_address: "",
  billing_apt_unit: "",
  billing_city: "",
  billing_state: "",
  billing_zip: "",

  //step 5 - terms and conditions

  agreed_to_terms: false,
  opt_ins: false,
  agree_to_immediate_billing: false,
  agree_to_subscription: false,
  creator_signature_file_reference: null,
  // creator_ip_address: "", // get this from the backend
});

const userCountList = computed(() => {
  const users = [];
  for (let i = 5; i <= 50; i++) {
    users.push({ value: i, label: `${i} Users` });
  }
  return users;
});

const userDiscount = computed(() => {
  const users = [];
  for (let i = 51; i <= 100; i++) {
    users.push({ value: i, label: `${i} Users - Discount Applied` });
  }
  return users;
});

const dynamicCvv = computed(() => {
  const cardType = signUpData.card_type;

  if (cardType === "amex") {
    return [["required"], ["number"], ["matches", /^\d{4}$/]];
  } else {
    return [["required"], ["number"], ["matches", /^\d{3}$/]];
  }
});

const cvvValidationMessage = computed(() => {
  if (signUpData.card_type === "amex") {
    return { matches: "Must be 4 digits" };
  } else {
    return { matches: "Must be 3 digits" };
  }
});

const termsOfServiceCompanyName = ref("FinnyPi");

const createAccount = async () => {
  xLog("createAccount Ran: ", signUpData);
};

const scrollContainer = ref(null);

const scrollToBottom = () => {
  setTimeout(() => {
    if (scrollContainer.value) {
      scrollContainer.value.scrollTop = scrollContainer.value.scrollHeight;
    }
  }, 200);
};

watch(
  () => signUpData.user_count,
  (value) => {
    toastsStore.addToast({
      title: "Pricing Updated",
      description: `New Total: $${value * (value < 50 ? 15 : 10)}`,
      type: 1,
      color: "emerald",
    });
    setTimeout(() => {}, 1000);
  }
);

const signaturePad = ref(null);
const container = ref(null);
const signaturePadWidth = ref("100%");
const signaturePadHeight = ref("100%");

const undo = () => {
  if (signaturePad.value) {
    signaturePad.value.undoSignature();
  } else {
    console.log("Signature pad reference is not available");
  }
};

// Define the dataURLtoBlob function
const dataURLtoBlob = (dataURL) => {
  const byteString = atob(dataURL.split(",")[1]);
  const mimeString = dataURL.split(",")[0].split(":")[1].split(";")[0];
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: mimeString });
};

const save = async () => {
  if (signaturePad.value) {
    const { isEmpty, data } = signaturePad.value.saveSignature("image/png");

    console.log("data", data);
    console.log("Is signature pad empty:", isEmpty);

    if (!isEmpty) {
      try {
        // Convert Base64 to Blob
        const blob = dataURLtoBlob(data);

        // Create FormData and append the file
        let formData = new FormData();
        formData.append(
          "file",
          blob,
          `sign_up_${signUpData.first_name}_${
            signUpData.last_name
          }_${Date.now()}.png`
        );
        // if the route is /sign-up then append 'tenant_signatures'

        formData.append("file_ref_destination", "tenant_signatures");

        const response = await uploadStore.uploadFile(formData);

        console.log(response);

        if (response.status !== 200)
          throw new Error("Failed to upload signature");

        // Handle success
        console.log(
          "Signature uploaded successfully",
          response.data.signatureReference
        );

        signUpData.creator_signature_file_reference =
          response.data.signatureReference;
      } catch (error) {
        console.error("Error uploading signature:", error);
      }
    } else {
      console.log("No signature detected. Please draw a signature first.");
    }
  } else {
    console.log("Signature pad reference is not available");
  }
};

const clear = () => {
  if (signaturePad.value) {
    //clear the signture pad
    signaturePad.value.clearSignature();
  }
};

const updateSignaturePadSize = () => {
  if (container.value) {
    signaturePadWidth.value = `${container.value.offsetWidth}px`;
    signaturePadHeight.value = `${container.value.offsetHeight}px`;
  }
};

onMounted(() => {
  updateSignaturePadSize();
  window.addEventListener("resize", updateSignaturePadSize);
});

onBeforeUnmount(() => {
  window.removeEventListener("resize", updateSignaturePadSize);
});

// Optional: Watch for container size changes
watch(container, () => {
  updateSignaturePadSize();
});

const showSignaturePad = ref(true);
const toggleSignaturePad = () => {
  showSignaturePad.value = !showSignaturePad.value;
};
</script>

<template>
  <div
    class="flex flex-col justify-center items-center h-screen bg-gradient-to-br from-emerald-300 to-green-600 p-3"
  >
    <!-- Form -->

    <Teleport to="#modals-target">
      <transition
        enter-active-class="transition-opacity duration-300 ease-in-out"
        enter-from-class="opacity-0"
        enter-to-class="opacity-100"
        leave-active-class="transition-opacity duration-300 ease-in-out"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <div
          @click.self="toggleSignaturePad"
          v-if="
            signUpData.agreed_to_terms &&
            signUpData.opt_ins &&
            signUpData.agree_to_immediate_billing &&
            signUpData.agree_to_subscription &&
            showSignaturePad
          "
          class="fixed z-50 top-0 left-0 w-screen h-screen backdrop-brightness-50 flex flex-col justify-center items-center p-1"
        >
          <div
            class="border border-slate-400 flex flex-col justify-between items-center bg-base-100 rounded p-3 gap-3"
          >
            <div class="flex flex-row items-center justify-start w-full gap-2">
              <font-awesome-icon icon="fa-solid fa-signature" />
              <h1 class="capitalize text-white text-xl">
                Electronic Signature
              </h1>
              <button @click="toggleSignaturePad" class="ml-auto btn btn-sm">
                <font-awesome-icon icon="fa-solid fa-times" />
              </button>
            </div>
            <div
              class="flex flex-col gap-2 justify-center items-start border border-slate-400 w-full p-2"
            >
              <span class="text-white"
                >{{ signUpData.first_name }} {{ signUpData.last_name }}</span
              >
            </div>
            <!-- <img
              :src="signUpData.creator_signature_file_reference"
              width="600"
              height="400"
            /> -->

            <vue-pdf-app
              :file-url="'https://www.apple.com/legal/sla/docs/iOS7.pdf'"
            ></vue-pdf-app>

            <div
              ref="container"
              class="rounded-xl border-4 border-slate-400 border-dotted bg-white"
            >
              <vue-signature-pad
                ref="signaturePad"
                :height="`150px`"
                :width="`100%`"
                :options="{
                  onBegin,
                  onEnd,
                  backgroundColor: 'rgba(0, 0, 0, 0)',
                  penColor: '#232324',
                  minWidth: 1,

                  maxWidth: 3.5,
                }"
              ></vue-signature-pad>
            </div>

            <div class="flex flex-row justify-start items-center w-full gap-3">
              <div class="flex flex-row">
                <button
                  @click="clear"
                  class="btn btn-sm rounded-l rounded-r-none border-none capitalize text-white bg-red-500 hover:bg-red-700"
                >
                  Clear
                </button>
                <!-- undo button with undo font awesome icon -->
                <button
                  @click="undo"
                  class="btn btn-sm rounded-r rounded-l-none border-none capitalize text-white bg-orange-500 hover:bg-orange-700"
                >
                  <font-awesome-icon icon="fa-solid fa-undo" />
                </button>
              </div>
              <button
                @click="save"
                class="px-3 py-1 ml-auto rounded border-none capitalize text-white bg-emerald-500 hover:bg-emerald-700 flex flex-wrap justify-center items-center gap-2"
              >
                Sign & Pay
                <span class="w-full text-lg"
                  >Total: ${{
                    signUpData.user_count *
                    (signUpData.user_count <= 50 ? 15 : 10)
                  }}</span
                >
              </button>
            </div>
          </div>
        </div>
      </transition>
    </Teleport>

    <div
      class="bg-white p-3 rounded w-full sm:w-8/12 md:w-6/12 lg:w-5/12 xl:w-4/12 2xl:w-3/12"
    >
      <div class="w-full text-center">
        <cSystemLogo></cSystemLogo>
      </div>
      <h3
        class="text-lg text-center text-white mb-8 bg-gradient-to-br from-emerald-300 to-green-600 mt-2"
      >
        Create an Account
      </h3>

      <transition
        enter-active-class="transition transform duration-500 ease-in-out"
        enter-from-class="opacity-0 scale-95"
        enter-to-class="opacity-100 scale-100"
        leave-active-class="transition transform duration-500 ease-in-out"
        leave-from-class="opacity-100 scale-100"
        leave-to-class="opacity-0 scale-95"
      >
        <div
          v-if="signUpData.user_count >= 5"
          class="fixed max-sm:bottom-0 md:top-0 left-0 w-screen bg-base-300 text-white border-t border-t-slate-400 flex flex-row justify-end"
        >
          <!-- Real Time Pricing - Each user is 15 per seat -->

          <div class="flex flex-row justify-between items-center p-2 gap-5">
            <span class="text-xs md:text-base">
              {{ signUpData.user_count }} Users x $15.00
            </span>
            <div
              class="rounded text-xs md:text-base flex flex-row justify-between items-center border border-white"
            >
              <div
                class="p-2 text-xs md:text-base bg-emerald-50 text-emerald-600 rounded-l"
              >
                Due Today
              </div>
              <div class="p-2 text-xs md:text-base bg-emerald-500 rounded-r">
                ${{
                  signUpData.user_count *
                  (signUpData.user_count <= 50 ? 15 : 10)
                }}
              </div>
            </div>
          </div>
        </div>
      </transition>

      <FormKit type="form" :actions="false" @submit="createAccount">
        <FormKit
          type="multi-step"
          steps-class="$reset"
          tab-style="progress"
          :allow-incomplete="false"
          tabLabelClass="hidden md:block"
        >
          <FormKit type="step" name="company">
            <h1 class="mb-5 text-slate-700 text-center md:hidden">
              Company Details
            </h1>
            <FormKit
              label-class="text-gray-700"
              inner-class="mt-2 mb-2"
              label="Company Name"
              type="text"
              input-class="input input-sm rounded bg-base-300 text-white w-full"
              validation="required|length:3,55"
              :validation-messages="{
                length: 'Must be between 3 and 55 characters',
              }"
              messages-class="relative"
              message-class="bg-red-500 rounded text-xs text-white p-1"
              v-model="signUpData.company_name"
            />

            <FormKit
              label-class="text-gray-700 mb-3"
              inner-class="mt-2 mb-2"
              label="Company Abbreviation"
              outer-class="relative"
              help-class="absolute top-3 text-xs"
              help="Individualizes your company's URL's"
              type="text"
              input-class="input input-sm rounded bg-base-300 text-white w-full"
              validation-visibility="dirty"
              :validation="[['required'], ['matches', /^[a-zA-Z]{3,10}$/]]"
              :validation-messages="{
                matches: 'Must be letters only and between 3 and 10 characters',
              }"
              messages-class="relative"
              message-class="bg-red-500 rounded text-xs text-white p-1"
              v-model="signUpData.company_abbreviation"
            />

            <FormKit
              type="select"
              label="Number of Users"
              :options="[
                {
                  value: 0,
                  label: '- Select Number of Users Needed -',
                  attrs: { disabled: true },
                },
                ...userCountList,
                ...userDiscount,
              ]"
              input-class="$reset select select-sm rounded bg-base-300 text-white w-full"
              inner-class="mt-2 mb-2"
              outer-class="$reset text-gray-700 col-span-4 md:col-span-2"
              validation="required|alphanumeric"
              wrapper-class="w-full max-w-full"
              message-class="bg-red-500 rounded text-xs text-white p-1"
              v-model="signUpData.user_count"
            />
          </FormKit>

          <FormKit type="step" name="user">
            <h1 class="mb-5 text-slate-700 text-center md:hidden">
              User Details
            </h1>
            <div class="flex flex-row justify-between gap-4">
              <FormKit
                label-class="text-gray-700"
                inner-class="mt-2 mb-2"
                label="First Name"
                type="text"
                outer-class="w-full"
                input-class="input input-sm rounded bg-base-300 text-white w-full"
                validation="required|length:2,55"
                :validation-messages="{
                  length: 'Must be between 2 and 55 characters',
                }"
                messages-class="relative"
                message-class="bg-red-500 rounded text-xs text-white p-1"
                v-model="signUpData.first_name"
              />
              <FormKit
                label-class="text-gray-700"
                inner-class="mt-2 mb-2"
                label="Last Name"
                type="text"
                outer-class="w-full"
                input-class="input input-sm rounded input input-sm rounded bg-base-300 text-white w-full"
                validation="required|length:2,55"
                :validation-messages="{
                  length: 'Must be between 2 and 55 characters',
                }"
                messages-class="relative"
                message-class="bg-red-500 rounded text-xs text-white p-1"
                v-model="signUpData.last_name"
              />
            </div>

            <FormKit
              label-class="text-gray-700"
              inner-class="mt-2 mb-2"
              label="Phone"
              type="tel"
              input-class="input input-sm rounded bg-base-300 text-white w-full"
              validation="matches:/^\d{10}$/"
              :validation-messages="{
                matches: 'Phone must be a 10 digit number',
              }"
              messages-class="relative"
              message-class="bg-red-500 rounded text-xs text-white p-1"
              v-model="signUpData.phone_primary"
            />

            <FormKit
              label-class="text-gray-700"
              inner-class="mt-2 mb-2"
              label="Email"
              type="text"
              input-class="input input-sm rounded bg-base-300 text-white w-full"
              validation="required|email"
              :validation-messages="{ email: 'Invalid Email Format' }"
              messages-class="relative"
              message-class="bg-red-500 rounded text-xs text-white p-1"
              v-model="signUpData.email"
            />

            <div class="flex flex-row justify-between gap-4">
              <FormKit
                label-class="text-gray-700"
                inner-class="mt-2 mb-2"
                label="Password"
                name="password"
                type="password"
                outer-class="w-full"
                input-class="input input-sm rounded bg-base-300 text-white w-full"
                validation="required|length:6"
                :validation-messages="{ length: '6 Character Minimum' }"
                messages-class="relative"
                message-class="bg-red-500 rounded text-xs text-white p-1"
                v-model="signUpData.password"
              />
              <FormKit
                label-class="text-gray-700"
                inner-class="mt-2 mb-2"
                label="Confirm Password"
                name="password_confirm"
                type="password"
                outer-class="w-full"
                input-class="input input-sm rounded bg-base-300 text-white w-full"
                validation-visibility="dirty"
                validation="required|confirm"
                :validation-messages="{ confirm: 'Password does not match' }"
                messages-class="relative"
                message-class="bg-red-500 rounded text-xs text-white p-1"
                v-model="signUpData.confirm_password"
              />
            </div>
          </FormKit>

          <FormKit type="step" name="payment">
            <h1 class="mb-5 text-slate-700 text-center md:hidden">
              Payment Details
            </h1>
            <div class="grid grid-cols-4 gap-x-4">
              <FormKit
                type="select"
                label="Card Type"
                :options="[
                  {
                    value: 0,
                    label: '- Select Card Type -',
                    attrs: { disabled: true },
                  },
                  {
                    value: 'visa',
                    label: 'Visa',
                  },
                  {
                    value: 'mastercard',
                    label: 'Mastercard',
                  },
                  {
                    value: 'amex',
                    label: 'American Express',
                  },
                  {
                    value: 'discover',
                    label: 'Discover',
                  },
                ]"
                input-class="$reset select select-sm rounded bg-base-300 text-white w-full"
                inner-class="mt-2 mb-2"
                outer-class="$reset text-gray-700 col-span-4 md:col-span-2"
                validation="required|alphanumeric"
                wrapper-class="w-full max-w-full"
                message-class="bg-red-500 rounded text-xs text-white p-1"
                v-model="signUpData.card_type"
              />

              <FormKit
                label-class="text-gray-700"
                inner-class="mt-2 mb-2"
                label="Cardholder Name"
                type="text"
                outer-class="col-span-4 md:col-span-2"
                input-class="input input-sm rounded bg-base-300 text-white w-full"
                validation="required|length:3,70"
                :validation-messages="{
                  length: 'Must be between 3 and 70 characters',
                }"
                messages-class="relative"
                message-class="bg-red-500 rounded text-xs text-white p-1"
                v-model="signUpData.name_on_card"
              />

              <FormKit
                label-class="text-gray-700"
                inner-class="mt-2 mb-2"
                label="Card Number"
                type="text"
                outer-class="col-span-3 md:col-span-3"
                input-class="input input-sm rounded bg-base-300 text-white w-full"
                :validation="[
                  ['required'],
                  ['number'],
                  ['matches', /^(?:\S{13}|\S{15}|\S{16})$/],
                ]"
                :validation-messages="{
                  matches: 'Must be 13, 15 or 16 digits. No spaces or dashes.',
                }"
                messages-class="relative"
                message-class="bg-red-500 rounded text-xs text-white p-1"
                v-model="signUpData.card_number"
              />

              <FormKit
                label-class="text-gray-700"
                inner-class="mt-2 mb-2"
                label="CVV"
                outer-class="col-span-1 md:col-span-1"
                type="text"
                input-class="input input-sm rounded bg-base-300 text-white w-full"
                :validation="dynamicCvv"
                :validation-messages="cvvValidationMessage"
                messages-class=""
                message-class="bg-red-500 rounded text-xs text-white p-1"
                v-model="signUpData.cvv"
              />

              <FormKit
                label-class="text-gray-700"
                inner-class="mt-2 mb-2"
                label="Expiration Month"
                outer-class="col-span-2 md:col-span-2"
                help-class="absolute top-3 text-xs"
                :options="expirationMonths"
                type="select"
                input-class="$reset select select-sm rounded bg-base-300 text-white w-full"
                validation="required|alphanumeric"
                :validation-messages="{ required: 'Select Expiration Month' }"
                messages-class=""
                message-class="bg-red-500 rounded text-xs text-white p-1"
                v-model="signUpData.expiration_month"
              />

              <FormKit
                label-class="text-gray-700"
                inner-class="mt-2 mb-2"
                label="Expiration Year"
                outer-class="col-span-2 md:col-span-2"
                help-class="absolute top-3 text-xs"
                :options="expirationYears"
                type="select"
                input-class="$reset select select-sm rounded bg-base-300 text-white w-full"
                validation="required|alphanumeric"
                :validation-messages="{ required: 'Select Expiration Year' }"
                messages-class=""
                message-class="bg-red-500 rounded text-xs text-white p-1"
                v-model="signUpData.expiration_year"
              />
            </div>
          </FormKit>

          <FormKit type="step" name="billingInfo">
            <h1 class="mb-5 text-slate-700 text-center md:hidden">
              Billing Details
            </h1>
            <div class="grid grid-cols-4 gap-x-4">
              <FormKit
                label-class="text-gray-700"
                inner-class="mt-2 mb-2"
                label="Billing Street Address"
                outer-class="col-span-3 md:col-span-3"
                type="text"
                input-class="input input-sm rounded bg-base-300 text-white w-full"
                validation="required|length:4,70"
                :validation-messages="{
                  length: 'Must be between 4 and 70 characters',
                }"
                messages-class=""
                message-class="bg-red-500 rounded text-xs text-white p-1"
                v-model.trim="signUpData.billing_address"
              />
              <FormKit
                label-class="text-gray-700"
                inner-class="mt-2 mb-2"
                label="Apt / Unit"
                outer-class="col-span-1 md:col-span-1"
                type="text"
                input-class="input input-sm rounded bg-base-300 text-white w-full"
                messages-class=""
                message-class="bg-red-500 rounded text-xs text-white p-1"
                v-model="signUpData.billing_apt_unit"
              />
              <FormKit
                label-class="text-gray-700"
                inner-class="mt-2 mb-2"
                label="City"
                outer-class="col-span-2 md:col-span-2"
                type="text"
                input-class="input input-sm rounded bg-base-300 text-white w-full"
                validation="required|length:2,70"
                :validation-messages="{
                  length: 'Must be between 2 and 70 characters',
                }"
                messages-class=""
                message-class="bg-red-500 rounded text-xs text-white p-1"
                v-model.trim="signUpData.billing_city"
              />
              <FormKit
                label-class="text-gray-700"
                inner-class="mt-2 mb-2"
                label="State"
                :options="stateOptions"
                outer-class="col-span-1 md:col-span-1"
                type="select"
                input-class="$reset select select-sm rounded bg-base-300 text-white w-full"
                validation="required"
                :validation-messages="{ required: 'Choose State' }"
                messages-class=""
                message-class="bg-red-500 rounded text-xs text-white p-1"
                v-model="signUpData.billing_state"
              />
              <FormKit
                label-class="text-gray-700"
                inner-class="mt-2 mb-2"
                label="Zip"
                outer-class="col-span-1 md:col-span-1"
                type="text"
                input-class="input input-sm rounded bg-base-300 text-white w-full"
                :validation="[['required'], ['number'], ['matches', /^\d{5}$/]]"
                :validation-messages="{ matches: 'Must be 5 digits.' }"
                messages-class=""
                message-class="bg-red-500 rounded text-xs text-white p-1"
                v-model="signUpData.billing_zip"
              />
            </div>
          </FormKit>

          <FormKit type="step" name="terms">
            <h1 class="mb-5 text-slate-700 text-center md:hidden">
              Terms of Service
            </h1>
            <div
              ref="scrollContainer"
              class="scroll-smooth text-white bg-base-300pb-10 h-52 overflow-y-auto mb-4 border-1 border-black"
            >
              <div
                class="flex flex-col gap-y-4 overflow-x-hidden border border-slate-400 rounded"
              >
                <WebsiteTerms></WebsiteTerms>

                <div class="">
                  <FormKit
                    @change="scrollToBottom()"
                    type="checkbox"
                    outer-class="$reset bg-gray-500 text-white text-center p-3 mb-4"
                    wrapper-class="w-full"
                    label-class="w-full"
                    inner-class="bg-white"
                    decorator-class="bg-white"
                    decorator-icon-class="bg-white"
                    label="I Agree to the Terms and Conditions"
                    name="agreed_to_terms"
                    :value="false"
                    validation="required"
                    :validation-messages="{
                      required: 'Please agree to our Terms of Service',
                    }"
                    v-model="signUpData.agreed_to_terms"
                  />

                  <FormKit
                    @change="scrollToBottom()"
                    v-show="signUpData.agreed_to_terms"
                    type="checkbox"
                    outer-class="$reset bg-blue-500 text-white text-center p-3 mb-4"
                    wrapper-class="w-full"
                    label="I conscent to receive text message and email updates from FinnyPi"
                    label-class="w-full"
                    inner-class="bg-white"
                    decorator-class="bg-white"
                    decorator-icon-class="bg-white"
                    name="agree_to_immediate_billing"
                    :value="false"
                    validation="required"
                    :validation-messages="{
                      required: 'Please agree to the immediate billing amount',
                    }"
                    message-class="text-white pb-4 pt-3"
                    messages-class="bg-red-700 mt-4"
                    v-model="signUpData.opt_ins"
                  />
                  <FormKit
                    @change="scrollToBottom()"
                    v-show="signUpData.opt_ins"
                    type="checkbox"
                    outer-class="$reset bg-orange-500 text-white text-center p-3 mb-4"
                    wrapper-class="w-full"
                    :label="`I selected ${signUpData.user_count} users and understand I will be billed $15 for each user immediately`"
                    label-class="w-full"
                    inner-class="bg-white"
                    decorator-class="bg-white"
                    decorator-icon-class="bg-white"
                    name="agree_to_immediate_billing"
                    :value="false"
                    validation="required"
                    :validation-messages="{
                      required: 'Please agree to the immediate billing amount',
                    }"
                    message-class="text-white pb-4 pt-3"
                    messages-class="bg-red-700 mt-4"
                    v-model="signUpData.agree_to_immediate_billing"
                  />

                  <FormKit
                    v-show="signUpData.agree_to_immediate_billing"
                    @change="scrollToBottom()"
                    type="checkbox"
                    outer-class="$reset bg-emerald-500 text-white text-center p-3 mb-4"
                    wrapper-class="w-full"
                    :label="`I understand this is a subscription service and I will be billed $14.99 per user per month to the card I previiously provided ending in ${signUpData.card_number.slice(
                      -4
                    )}`"
                    label-class="w-full"
                    inner-class="bg-white"
                    decorator-class="bg-white"
                    decorator-icon-class="bg-white"
                    name="agree_to_subscription"
                    :value="false"
                    validation="required"
                    :validation-messages="{
                      required: 'Please agree to your monthly subscription',
                    }"
                    message-class="text-white pb-4 pt-3"
                    messages-class="bg-red-700 mt-4"
                    v-model="signUpData.agree_to_subscription"
                  />
                  <div
                    v-if="
                      signUpData.agree_to_subscription &&
                      signUpData.agree_to_immediate_billing &&
                      signUpData.agreed_to_terms &&
                      signUpData.opt_ins
                    "
                    class="flex flex-row justify-center pb-5"
                  >
                    <button
                      @click="toggleSignaturePad"
                      class="btn btn-sm gradient-animation border-none text-white rounded"
                    >
                      Finish Signature
                    </button>
                  </div>

                  <!-- VUE SIGNATURE PAD GOES HERE -->
                </div>
              </div>
            </div>

            <template #stepNext>
              <FormKit
                v-show="
                  signUpData.agree_to_subscription &&
                  signUpData.agree_to_immediate_billing &&
                  signUpData.agreed_to_terms &&
                  signUpData.opt_ins &&
                  signUpData.creator_signature_file_reference
                "
                type="submit"
              />
            </template>
          </FormKit>

          <div
            v-show="
              signUpData.tenant_name.length === 0 &&
              signUpData.company_abbreviation.length === 0
            "
            class="flex flex-row justify-between gap-4 mt-4"
          >
            <FormKit
              type="button"
              label="Password Reset"
              outer-class="w-full"
              input-class="input input-sm rounded $reset btn btn-sm rounded capitalize text-white bg-orange-500 hover:bg-orange-700 border-none w-full"
              @click="router.push({ name: 'password-reset' })"
            />

            <FormKit
              type="button"
              label="Sign In"
              outer-class="w-full"
              input-class="input input-sm rounded $reset btn btn-sm rounded capitalize text-white bg-indigo-500 hover:bg-indigo-700 border-none w-full"
              @click="router.push({ name: 'login' })"
            />
          </div>
        </FormKit>
      </FormKit>
    </div>
  </div>
</template>
