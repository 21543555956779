import {
  //axios
  axios,

  //pinia
  defineStore,
  useGlobalToastsStore,
  useGlobalPromptsStore,

  //env base url
  BASE_API_URL,
} from "../clientImports";

export const useTenantsPassportsStore = defineStore({
  id: "tenants-passports",
  state: () => ({
    showTenantsPassportsCreateModal: false,
    showTenantsPassportsEditModal: false,

    idToEdit: "",

    tenantsPassportsList: [],

    currentlyCreatingPassport: {
      name: "",
      ip_address: "",
      description: "",
    },

    currentlyEditingPassport: {
      name: "",
      ip_address: "",
      description: "",
    },
  }),
  actions: {
    //two responsibilities - set the id to edit and then open the modal
    async initializeTenantsPassportsEditModal(passportId) {
      //set's the id of the Passport that we're editing
      this.setIdToEdit(passportId);

      await this.fetchTenantPassport();
      //opens the edit modal
      this.showTenantsPassportsEditModal = true;
    },

    toggleTenantsPassportsCreateModal() {
      this.showTenantsPassportsCreateModal =
        !this.showTenantsPassportsCreateModal;
    },

    toggleTenantsPassportsEditModal() {
      this.showTenantsPassportsEditModal = !this.showTenantsPassportsEditModal;
    },

    //fetches the Passports for listing
    async fetchTenantsPassports() {
      try {
        const { data } = await axios.get(
          `${BASE_API_URL}/read-tenants-passports`
        );

        if (data.status === "success") {
          this.tenantsPassportsList = data.passportsOptions.map((passport) => {
            return {
              id: passport._id,
              name: passport.name,
              ip_address: passport.ip_address,
              description: passport.description,
            };
          });
        } else {
          console.error(data.message);
        }
      } catch (error) {
        console.error();
      }
    },

    //fetching for editing
    async fetchTenantPassport() {
      const id = this.idToEdit;
      try {
        const { data } = await axios.get(
          `${BASE_API_URL}/read-tenants-passport/${id}`
        );

        console.log(data, "FETCHED MILESTONE");

        if (data.status === "success") {
          console.log(data.passport);
          this.currentlyEditingPassport = data.passport[0];

          console.log(this.currentlyEditingPassport);
        } else {
          console.error(data.message);
        }
      } catch (error) {
        console.error();
      }
    },

    setIdToEdit(passportId) {
      this.idToEdit = passportId;
    },

    async createTenantsPassport() {
      const passportData = this.currentlyCreatingPassport;

      console.log("PASSPORT DATA SENDING OUT: ", passportData);
      try {
        const { data } = await axios.post(
          `${BASE_API_URL}/create-tenants-passport`,
          passportData
        );

        console.log("CREATE PASSPORT RESPONSE", data);

        if (data.status === "success") {
          const toastsStore = useGlobalToastsStore();
          toastsStore.addToast({
            title: "Passport Created",
            description: "",
            buttonExists: false,
            color: "emerald",
            type: 1,
          });

          //fetch the updated list of Passports
          this.fetchTenantsPassports();
          this.toggleTenantsPassportsCreateModal();
        } else {
          console.error(data.message);
          toastsStore.addToast({
            title: "Error Creating Passport",
            description: data.message,
            buttonExists: false,
            color: "red",
            type: 1,
          });
        }
      } catch (error) {
        console.error();
      }
    },

    async updateTenantsPassport() {
      const id = this.idToEdit;
      try {
        const { data } = await axios.put(
          `${BASE_API_URL}/update-tenants-passport/${id}`,
          this.currentlyEditingPassport
        );

        if (data.status === "success") {
          console.log(data.message);

          this.toggleTenantsPassportsEditModal();
          //fetch the updated list of Passports
          this.fetchTenantsPassports();

          //throw toast
          const toastsStore = useGlobalToastsStore();
          toastsStore.addToast({
            title: "Passport Updated",
            description: "",
            buttonExists: false,
            color: "emerald",
            type: 1,
          });
        } else {
          console.error(data.message);
          toastsStore.addToast({
            title: "Error Updating Passport",
            description: data.message,
            buttonExists: false,
            color: "red",
            type: 1,
          });
        }
      } catch (error) {
        console.error();
      }
    },

    async deleteTenantsPassport(passportId) {
      const promptStore = useGlobalPromptsStore();

      const group_id = passportId;

      promptStore.addPrompt({
        type: "Passport",
        title: "Delete Passport?",
        message: `Are you sure you want to delete this Passport?`,
      });

      const promptResponse = await promptStore.waitForPromptConfirmation();

      if (promptResponse === true) {
        promptStore.addPrompt({
          type: "Passport",
          title: "Are You Sure?",
          message: `We'll hide this from your view so it can no longer be used. Any contacts or users that have this passport assigned will remain assigned and have to be manually changed. Do you still want to proceed with the deletion?`,
        });

        const promptResponse2 = await promptStore.waitForPromptConfirmation();

        if (promptResponse2 === true) {
          try {
            const { data } = await axios.delete(
              `${BASE_API_URL}/delete-tenants-passport/${group_id}`
            );

            if (data.status === "success") {
              //fetch the updated list of Passports
              this.fetchTenantsPassports();
            } else {
              console.error(data.message);
              toastsStore.addToast({
                title: "Error Deleting Passport",
                description: data.message,
                buttonExists: false,
                color: "red",
                type: 1,
              });
            }
          } catch (error) {
            console.error();
          }
          promptStore.removePrompt();
        } else {
          promptStore.removePrompt();
        }
      } else {
        promptStore.removePrompt();
      }
    },
  },
});
