<script setup>
defineOptions({
  name: 'SettingsGatewaysEdit', // Give the component a name
});
import {
  //axios
  axios,

  //vue
  createApp,
  ref,
  reactive,
  watch,
  computed,
  inject,
  provide,
  onMounted,
  onUnmounted,
  nextTick,
  Teleport,

  //pinia
  defineStore,
  createPinia,
  storeToRefs,

  //pinia stores
  useChatStore,
  useContactAlertsStore,
  useContactFileStore,
  useContactsStore,
  useGlobalPromptsStore,
  useGlobalToastsStore,
  useLoginStore,
  useTenantsStore,
  useUsersStore,
  useTenantsGatewaysStore,

  //vue-router
  useRouter,
  useRoute,

  //formkit

  //formkit config file
  FKsubmitForm,

  //fontawesome
  library,
  FontAwesomeIcon,
  fas,
  far,
  fab,

  //centralized data
  usersLevelOptions,
  fieldsDefaultValidation,
  stateOptions,

  //utilities
  getRandomNumberBetween,

  //helpers
  registerGlobalComponents,

  //utilities
  stripEmptyFields,

  //base api url
  BASE_API_URL,
} from "./../clientImports";

import {
  browserTimezone,
  timezoneOptions,
} from "./../centralized_data/timezoneOptions";

const router = useRouter();
const route = useRoute();

const loginStore = useLoginStore();

const usersStore = useUsersStore();
const toastsStore = useGlobalToastsStore();
const promptsStore = useGlobalPromptsStore();
const tenantsGatewaysStore = useTenantsGatewaysStore();

const gatewayDataKeysVals = ref([]);

const addGatewayDataKeyVal = () => {
  gatewayDataKeysVals.value.push({ key: "", value: "" });
};

watch(
  () => tenantsGatewaysStore.currentlyEditingGateway.gateway_data,
  (newVal) => {
    if (newVal) {
      gatewayDataKeysVals.value = Object.keys(newVal).map((key) => ({
        key: key,
        value: newVal[key],
      }));
    } else {
      gatewayDataKeysVals.value = [];
    }
  }
);

//use FKsubmitForm to submit form
const submitUpdateForm = () => {
  console.log(gatewayDataKeysVals.value);
  FKsubmitForm("edit-gateways");
};
</script>

<template>
  <cSidebarModal
    sidebar-position="right"
    :show-sidebar-initial="false"
    :show-sidebar-modal="tenantsGatewaysStore.showTenantsGatewaysEditModal"
    :toggle-sidebar-modal="tenantsGatewaysStore.toggleTenantsGatewaysEditModal"
    :defaultButton="false"
  >
    <template #title>
      <h2>Edit Gateway</h2>
    </template>

    <template #body>
      <FormKit
        id="edit-gateways"
        type="form"
        :incomplete-message="false"
        submit-label="Update"
        form-class="text-white h-full flex flex-col justify-between"
        @submit="tenantsGatewaysStore.updateTenantsGateway(gatewayDataKeysVals)"
        outer-class="m-0"
        :actions="false"
        :submit-attrs="{
          'data-theme': `light`,
          ignore: true,
        }"
      >
        <div class="grid grid-cols-1 gap-5">
          <div class="flex flex-row gap-5 items-end">
            <FormKit
              type="text"
              label="Name"
              placeholder=""
              :validation="'required:trim|length:5, 40'"
              help-class="text-white"
              help=""
              outer-class="m-0 flex-1"
              input-class="$reset rounded w-full input input-sm text-gray-700 bg-gray-50"
              wrapper-class="max-w-full"
              v-model.trim="tenantsGatewaysStore.currentlyEditingGateway.name"
            />

            <FormKit
              outer-class="col-span-1"
              type="toggle"
              name="toggle"
              label="Active"
              alt-label-position
              v-model="tenantsGatewaysStore.currentlyEditingGateway.active"
            />
          </div>

          <FormKit
            type="select"
            label="Type"
            placeholder=""
            help-class="text-white"
            help=""
            outer-class="m-0 flex-1"
            input-class="$reset rounded w-full input input-sm text-gray-700 bg-gray-50"
            wrapper-class="max-w-full"
            v-model.trim="tenantsGatewaysStore.currentlyEditingGateway.type"
            :options="[
              {
                value: 'authorize_net_credit_card',
                label: 'Authorize.net - Credit Card',
              },
              {
                value: 'authorize_net_echeck',
                label: 'Authorize.net - E-Check',
              },
              {
                value: 'nmi_credit_card',
                label: 'NMI - Credit Card',
              },
              {
                value: 'nmi_echeck',
                label: 'NMI - E-Check',
              },
              {
                value: 'reliant_account_management_trust',
                label: 'Reliant Account Management - Trust Account',
              },
              {
                value: 'unity_fi_echeck',
                label: 'Unity FI - E-Check',
              },
              {
                value: 'unity_fi_trust',
                label: 'Unity FI - Trust Account',
              },
            ]"
          />

          <FormKit
            type="textarea"
            label="Description"
            placeholder=""
            :validation="'trim|length:1, 255'"
            help-class="text-white"
            help=""
            outer-class="m-0 "
            input-class="$reset rounded w-full input input-sm text-gray-700 bg-gray-50"
            wrapper-class="max-w-full"
            v-model.trim="
              tenantsGatewaysStore.currentlyEditingGateway.description
            "
          />
          <div
            class="border border-slate-400 rounded p-2 flex flex-col items-center"
          >
            <div class="flex flex-row items-center w-full">
              <div>
                <h1>Add Gateway Data Key / Value Pairs</h1>
              </div>
              <button
                type="button"
                @click="addGatewayDataKeyVal"
                class="ml-auto btn btn-xs md:btn-sm rounded border border-slate-400 text-white hover:bg-white hover:text-gray-600 capitalize"
              >
                <font-awesome-icon icon="fa-solid fa-plus" />
              </button>
            </div>
            <div class="w-full flex flex-col gap-3">
              <div
                v-for="(datapoint, i) in gatewayDataKeysVals"
                :key="i"
                class="flex flex-row gap-5 items-end"
              >
                <FormKit
                  type="text"
                  label="Key"
                  placeholder=""
                  :validation="'required:trim|length:1, 40'"
                  help-class="text-white"
                  help=""
                  outer-class="m-0 flex-1"
                  input-class="$reset rounded w-full input input-sm text-gray-700 bg-gray-50"
                  wrapper-class="max-w-full"
                  v-model.trim="gatewayDataKeysVals[i].key"
                />

                <FormKit
                  type="text"
                  label="Value"
                  placeholder=""
                  :validation="'required:trim|length:1, 40'"
                  help-class="text-white"
                  help=""
                  outer-class="m-0 flex-1"
                  input-class="$reset rounded w-full input input-sm text-gray-700 bg-gray-50"
                  wrapper-class="max-w-full"
                  v-model.trim="gatewayDataKeysVals[i].value"
                />

                <!-- delete button -->
                <button
                  type="button"
                  @click="gatewayDataKeysVals.splice(i, 1)"
                  class="btn btn-xs md:btn-sm rounded-lg bg-base-100 border border-red-400 text-red-500 hover:bg-red-700 hover:text-white"
                >
                  <font-awesome-icon icon="fa-solid fa-trash" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </FormKit>
    </template>

    <template #footer>
      <!-- BUTTON FOOTER - CANCEL & UPDATE AFFILIATE GROUP -->
      <div class="flex flex-row justify-between text-white w-full">
        <button
          @click="tenantsGatewaysStore.toggleTenantsGatewaysEditModal"
          class="btn btn-xs md:btn-sm rounded bg-red-500 text-white hover:bg-red-700"
        >
          Cancel
        </button>
        <button
          @click="submitUpdateForm"
          class="btn btn-xs md:btn-sm rounded bg-emerald-500 text-white hover:bg-emerald-700"
        >
          Update
        </button>
      </div>
    </template>
  </cSidebarModal>
</template>
