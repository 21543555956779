<script setup>
defineOptions({
  name: 'ContactLinks', // Give the component a name
});
import {
  //axios
  axios,

  //vue
  createApp,
  ref,
  reactive,
  watch,
  computed,
  inject,
  provide,
  onMounted,
  onUnmounted,
  nextTick,
  Teleport,

  //pinia
  defineStore,
  createPinia,
  storeToRefs,

  //pinia stores
  useChatStore,
  useContactAlertsStore,
  useContactFileStore,
  useContactsStore,
  useGlobalPromptsStore,
  useGlobalToastsStore,
  useLoginStore,
  useTenantsStore,

  //vue-router
  useRouter,
  useRoute,

  //formkit

  //formkit config file

  //fontawesome
  library,
  FontAwesomeIcon,
  fas,
  far,
  fab,

  //global components - all vue components
  registerGlobalComponents,

  //utils
  xLog,

  //base api url
  BASE_API_URL,
} from "./../clientImports";

const toastsStore = useGlobalToastsStore();

const contactLinks = ref([
  // { id: 1, department: "creator", userId: 1, username: "Joe Bent" },
  // { id: 2, department: "processor", userId: 2, username: "Justin Land" },
  // { id: 3, department: "sales", userId: 3, username: "Johnny Mayoral" },
]);

const fileLinkOptions = ref([
  { label: "Creator", value: "creator" },
  { label: "Processor", value: "Processor" },
  { label: "Sales", value: "sales" },
  { label: "Billing", value: "billing" },
]);

const addNewFileLink = () => {
  contactLinks.value.push({
    department: 0,
    userId: 0,
    username: "",
  });

  toastsStore.addToast({
    title: "Link Added",
    description: "",
    buttonExists: false,
    color: "emerald",
    type: 1, // 1 = small, 2 = md, 3 = lg, 4 = lg dismissable
  });
};

// const convertUsersIcon = computed(() => {
//   if (contactLinks.length < 1) {
//     xLog("users Links length is 0: ", contactLinks.length < 1);
//     return "fa-solid fa-plus";
//   } else if (contactLinks.length > 1) {
//     return "fa-solid fa-pen-to-square";
//   } else {
//     xLog("users Links length is 0: ", contactLinks.length < 1);
//   }
// });

const deleteAssignment = (index) => {
  xLog("Contact Link Index: ", index);

  let deleteAssignmentResponse = confirm(
    `Are you sure you want to delete this assignment?`
  );

  if (deleteAssignmentResponse) {
    contactLinks.value.splice(index, 1);

    toastsStore.addToast({
      title: "File Link Deleted",
      description: "",
      buttonExists: false,
      color: "red",
      type: 1, // 1 = small, 2 = md, 3 = lg, 4 = lg dismissable
    });
  }
};

const contactLinksModal = ref(false);
const togglecontactLinksModal = () => {
  contactLinksModal.value = !contactLinksModal.value;
};
</script>

<template>
  <div class="bg-base-100 flex flex-row items-center">
    <div
      class="bg-sky-500 text-white p-1 md:p-2 flex-1 text-xs md:text-sm whitespace-nowrap flex flex-row items-center"
    >
      <span class="flex-1">
        <font-awesome-icon icon="fa-solid fa-link" /> File Links
      </span>
      <span
        :class="`${contactLinks.length === 0 ? 'bg-red-500' : 'bg-sky-500'}`"
        class="p-0.5 rounded text-xs border-white border"
        >{{
          contactLinks.length > 0
            ? `${contactLinks.length} ${
                contactLinks.length > 1 ? "Links" : "Link"
              }`
            : "None"
        }}
      </span>
    </div>
    <div class="self-stretch">
      <button
        @click.stop="addNewFileLink"
        class="bg-blue-500 px-4 text-white capitalize rounded-none h-full"
      >
        <font-awesome-icon icon="fa-solid fa-plus" size="sm" />
      </button>
    </div>
  </div>
  <div class="flex flex-col text-gray-700 bg-white">
    <!-- v-for the Links -->
    <div
      v-for="(assignment, assignmentIndex) in contactLinks"
      :key="assignmentIndex"
      class="flex flex-col"
    >
      <div
        :class="[
          assignmentIndex !== contactLinks.length - 1
            ? 'border-b border-slate-400'
            : '',
        ]"
        class="flex flex-row justify-between items-center gap-0"
      >
        <div class="capitalize font-bold w-3/6">
          <FormKit
            outer-class="m-0 rounded-none bg-base-100"
            input-class="select text-white w-full border-none select-sm py-0 px-3"
            inner-class="rounded-none border-none"
            type="select"
            name="assignmentDepartment"
            :options="fileLinkOptions"
            v-model="assignment.department"
          />
        </div>

        <div class="text-right w-3/6">
          <FormKit
            outer-class="m-0 rounded-none bg-base-100"
            input-class="select py-0 px-3 select-sm text-white w-full border-none"
            inner-class="rounded-none border-none"
            type="select"
            name="assignmentDepartmentUser"
            :options="contactLinks"
            v-model="assignment.username"
            :value="assignment.username"
          />
        </div>

        <button
          @click.stop="deleteAssignment(assignmentIndex)"
          class="btn btn-sm bg-red-500 hover:bg-red-600 border-none rounded-none text-white"
        >
          <font-awesome-icon icon="fa-solid fa-xmark" />
        </button>
      </div>
    </div>
  </div>
</template>
