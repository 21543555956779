<script setup>
defineOptions({
  name: "SessionDashboard", // Give the component a name
});
import {
  ref,
  watch,
  computed,
  onMounted,
  Teleport,
  useTenantsSessionsStore,
  useLoginStore,
} from "../clientImports";

import { globalIsoToLocal } from "./../utilities/globalTimezoneConverter";

const loginStore = useLoginStore();

const tenantsSessionsStore = useTenantsSessionsStore();

const timeConverter = (value) => {
  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    // second: "2-digit",
  };

  let formattedDate = globalIsoToLocal(value, options);

  // Replace slashes with hyphens and remove the comma
  return formattedDate.replace(/\//g, "-").replace(",", "");
};
onMounted(async () => {
  console.log("SESSION DASHBOARD MOUNTED");
  await tenantsSessionsStore.readTenantSessions();
});
</script>

<template>
  <router-link
    :to="{ name: 'session-dashboard' }"
    v-if="
      (tenantsSessionsStore.tenantsSessions.filter(
        (sesh) => sesh.user_id !== loginStore?.sessionData?.foundUser._id
      ).length > 0 &&
        loginStore?.sessionData?.permissions?.includes(
          'view:tenant-sessions'
        )) ||
      (tenantsSessionsStore.tenantsSessions.filter(
        (sesh) => sesh.user_id !== loginStore?.sessionData?.foundUser._id
      ).length > 0 &&
        loginStore?.sessionData?.permissions?.includes('*:dev-permissions'))
    "
    :class="`${
      tenantsSessionsStore.tenantsSessions.filter(
        (sesh) => sesh.user_id !== loginStore?.sessionData?.foundUser._id
      ).length > 0
        ? 'pulse-border'
        : ''
    }`"
    class="btn btn-sm rounded capitalize glass text-gray-50"
  >
    <font-awesome-icon icon="fa-solid fa-users" />
    <span class="ml-2">{{
      tenantsSessionsStore.tenantsSessions.filter(
        (sesh) => sesh.user_id !== loginStore?.sessionData?.foundUser._id
      ).length
    }}</span>
  </router-link>

  <Teleport to="#modals-target">
    <transition
      enter-active-class="transition-opacity duration-500 ease-in-out"
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="transition-opacity duration-500 ease-in-out"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div
        v-if="tenantsSessionsStore.showSessionsDashboard"
        @click.self="tenantsSessionsStore.toggleSessionDashboard"
        class="fixed h-screen w-screen z-50 backdrop-brightness-50 md:p-5 flex flex-col items-center justify-center"
      >
        <div
          class="h-screen w-screen md:w-2/3 md:h-2/3 border border-slate-400 rounded bg-base-100 flex flex-col"
        >
          <div class="p-3 flex flex-row">
            <h1 class="text-white text-2xl">Active User Sessions</h1>
            <button
              class="ml-auto btn btn-sm border border-slate-400 hover:bg-base-300 hover:border-white"
            >
              <font-awesome-icon
                @click="tenantsSessionsStore.toggleSessionDashboard"
                icon="fa-solid fa-times"
                class="text-white"
              />
            </button>
          </div>
          <div class="flex-1 flex flex-col gap-3 p-5 overflow-y-auto">
            <div
              v-if="
                tenantsSessionsStore.tenantsSessions.filter(
                  (sesh) =>
                    sesh.user_id !== loginStore?.sessionData?.foundUser._id
                ).length === 0
              "
              class="flex flex-col items-center justify-center gap-5 p-3 bg-base-200 border border-slate-400 rounded"
            >
              <p class="text-white">No active sessions</p>
            </div>
            <div
              v-for="(user, i) in tenantsSessionsStore.tenantsSessions.filter(
                (sesh) =>
                  sesh.user_id !== loginStore?.sessionData?.foundUser._id
              )"
              :key="i"
              class="flex flex-col md:flex-row md:items-center gap-5 p-3 bg-base-200 border border-slate-400 rounded"
            >
              <div
                class="relative flex flex-col items-center justify-center mx-5"
              >
                <div
                  class="pulse-green rounded-full border border-emerald-500 bg-emerald-400 absolute"
                ></div>
              </div>
              <p class="text-white">{{ user.user_first_name }}</p>
              <p class="text-white">{{ user.user_last_name }}</p>
              <p class="text-white ml-auto bg-blue-400 rounded p-1">
                Session Started: {{ timeConverter(user.created_at) }}
              </p>

              <button
                @click="tenantsSessionsStore.destroyUserSession(user.user_id)"
                class="bg-red-500 btn btn-sm rounded border-none text-white capitalize"
              >
                Force Logout
              </button>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </Teleport>
</template>

<style scoped>
/* Custom CSS for pulsating animation */
@keyframes pulse-green {
  0%,
  100% {
    padding: 5px;

    background-color: #4eff45; /* Tailwind's green-500 */
    opacity: 1;
  }

  75% {
    padding: 7px;

    background-color: #4eff45; /* Tailwind's green-500 */
    opacity: 1;
  }
  50% {
    padding: 5px;
    background-color: #00ff08; /* A lighter green for a pulsing effect */
    opacity: 0.5;
  }
}

.pulse-green {
  animation: pulse-green 1.5s infinite;
}

@keyframes pulse-border {
  0%,
  100% {
    border: 2px solid #4eff45; /* Tailwind's green-500 */
  }

  75% {
    border: 2px solid #32fb28; /* Tailwind's green-500 */
  }
  50% {
    border: 2px solid #002301; /* A lighter green for a pulsing effect */
  }
}

.pulse-border {
  animation: pulse-border 1.5s infinite;
}
</style>
