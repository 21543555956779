export const fieldsDefaultValidation = {
  name: {
    validation: "length:0,50",
  },
  email: {
    validation: "email|length:1,255",
  },
  phone: {
    validation: [["phone"], ["number"], ["matches", /^\d{10}$/]],
    validationMessages: {
      matches: "Phone number must be 10 digits and contain no spaces",
    },
  },
  callerId: {
    validation: "length:0,15",
  },
  //make sure to set the name attribute - it's used by the comfirm password field - example: name="sign_in_password"
  password: {
    validation: "length:6,50",
  },
  //make sure to set _confirm on name attribute of the confirm password field - sign_in_password_confirm
  passwordConfirm: {
    validation: "required|confirm",
  },
};
