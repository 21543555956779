<script setup>
defineOptions({
  name: 'SettingsTenantsCreate', // Give the component a name
});
import {
  //axios
  axios,

  //vue
  reactive,
  ref,
  onMounted,
  Teleport,

  //pinia stores
  useGlobalPromptsStore,
  useGlobalToastsStore,
  useUsersStore,

  //vue-router
  useRouter,
  useRoute,

  //utilities
  usersLevelOptions,
  departmentOptions,
  fieldsDefaultValidation,
  cleanUpperCase,

  //base api url
  BASE_API_URL,
} from "./../clientImports";

import {
  browserTimezone,
  timezoneOptions,
} from "./../centralized_data/timezoneOptions";

const router = useRouter();
const route = useRoute();

const toastsStore = useGlobalToastsStore();
const promptsStore = useGlobalPromptsStore();
const tenantsStore = useUsersStore();

const resetUser = () => {
  for (const key in user) {
    user[key] = getDefault(key);
  }
};

const getDefault = (key) => {
  const defaults = {
    company_abbreviation: "",
    company_display_name: "",
    company_legal_name: "",
    website: "",
    office_phone: "",
    customer_service_phone: "",
    office_street_address: "",
    office_suite_unit: "",
    office_city: "",
    office_state: "",
    office_zip: "",

    //, tax, ,student loan, credit repair, etc ** this will most likely be set automatically based on the file type.
    // portal_type: "",
  };
  return defaults[key];
};

const company = reactive({
  company_abbreviation: "",
  company_display_name: "",
  company_legal_name: "",
  website: "",
  office_phone: "",
  customer_service_phone: "",
  office_street_address: "",
  office_suite_unit: "",
  office_city: "",
  office_state: "",
  office_zip: "",

  //, tax, ,student loan, credit repair, etc ** this will most likely be set automatically based on the file type.
  // portal_type: "",
});

const createCompany = async () => {
  const response = await tenantsStore.createUser(user);

  if (response.status === "success") {
    toastsStore.addToast({
      title: "Company Created",
      description: `${user.first_name} ${user.last_name} has been created successfully`,
      buttonExists: false,
      color: "emerald",
      type: 1,
    });

    resetUser();

    tenantsStore.toggleCreateUserModal();

    await tenantsStore.readUsers();
  } else {
    toastsStore.addToast({
      title: "Error",
      description: `${response.specific}`,
      buttonExists: false,
      color: "red",
      type: 1,
    });
  }
};
</script>

<template>
  <button
    @click="tenantsStore.toggleCreateTenantModal"
    class="btn btn-sm bg-blue-500 rounded text-white capitalize"
  >
    <span class="mr-2">Create Company</span
    ><font-awesome-icon icon="fa-solid fa-plus" />
  </button>
  <Teleport to="#modals-target">
    <transition
      enter-active-class="transition-opacity duration-500 ease-in-out"
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="transition-opacity duration-500 ease-in-out"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div
        v-if="tenantsStore.showCreateUserModal"
        @click.self="tenantsStore.toggleCreateUserModal"
        class="z-70 fixed flex flex-col items-end top-0 h-full w-full left-0 backdrop-blur-sm"
      ></div>
    </transition>

    <!-- modal right bar -->
    <transition
      enter-active-class="transition-all duration-300 ease-in-out"
      enter-from-class="translate-x-full"
      enter-to-class="translate-x-0"
      leave-active-class="transition-all duration-300 ease-in-out"
      leave-from-class="translate-x-0"
      leave-to-class="translate-x-full"
    >
      <div
        v-if="tenantsStore.showCreateUserModal"
        class="z-70 fixed top-0 right-0 bg-base-100 border-l border-slate-400 w-full h-full max-h-full flex-1 md:w-1/3"
      >
        <!-- <h1
            class="p-5 text-white text-xl sticky top-0 bg-base-300 border-b border-slate-400 w-full z-10"
          >
            Create User
          </h1> -->

        <div class="h-full overflow-y-auto">
          <FormKit
            type="form"
            :incomplete-message="false"
            form-class="text-white h-full flex flex-col justify-between"
            @submit="createUser"
            :actions="false"
            :submit-attrs="{
              'data-theme': `light`,
              ignore: true,
            }"
          >
            <FormKit name="create-user" type="group">
              <template #default="{ state: { valid } }">
                <div class="grid grid-cols-2 gap-5 p-5">
                  <FormKit
                    type="text"
                    label="First Name"
                    value=""
                    placeholder=""
                    :validation="
                      'required:trim|' + fieldsDefaultValidation.name.validation
                    "
                    help-class="text-white"
                    help=""
                    outer-class="m-0"
                    input-class="$reset rounded w-full input input-sm text-gray-700 bg-gray-50"
                    wrapper-class="max-w-full"
                    v-model.trim="user.first_name"
                  />

                  <FormKit
                    type="text"
                    label="Last Name"
                    placeholder=""
                    :validation="
                      'required:trim|' + fieldsDefaultValidation.name.validation
                    "
                    help-class="text-white"
                    help=""
                    outer-class="m-0"
                    input-class="$reset rounded w-full input input-sm text-gray-700 bg-gray-50"
                    wrapper-class="max-w-full"
                    v-model.trim="user.last_name"
                  />

                  <FormKit
                    type="email"
                    label="Email"
                    placeholder=""
                    :validation="
                      'required:trim|' +
                      fieldsDefaultValidation.email.validation
                    "
                    help-class="text-white"
                    help=""
                    outer-class="m-0"
                    input-class="$reset rounded w-full input input-sm text-gray-700 bg-gray-50"
                    wrapper-class="max-w-full"
                    v-model.trim="user.email"
                  />

                  <!-- <FormKit
                              type="text"
                              label="Vendor User ID"
                              placeholder=""
                              help-class="text-white"
                              help=""
                              outer-class="m-0"
                              input-class="input input-sm text-gray-700 bg-gray-50"
                              wrapper-class="max-w-full"
                              v-model.trim="user.vendor_user_id"
                            /> -->

                  <FormKit
                    type="text"
                    label="Phone Caller ID"
                    placeholder=""
                    help-class="text-white"
                    :validation="fieldsDefaultValidation.callerId.validation"
                    help=""
                    outer-class="m-0"
                    input-class="$reset rounded w-full input input-sm text-gray-700 bg-gray-50"
                    wrapper-class="max-w-full"
                    v-model.trim="user.phone_caller_id"
                  />

                  <FormKit
                    type="tel"
                    label="User Phone Number"
                    placeholder=""
                    :validation="fieldsDefaultValidation.phone.validation"
                    :validation-messages="
                      fieldsDefaultValidation.phone.validationMessages
                    "
                    help-class="text-white"
                    help=""
                    outer-class="m-0"
                    input-class="$reset rounded w-full input input-sm text-gray-700 bg-gray-50"
                    wrapper-class="max-w-full"
                    v-model.trim="user.user_phone_number"
                  />

                  <FormKit
                    type="text"
                    label="User Phone Extension"
                    placeholder=""
                    help-class="text-white"
                    help=""
                    validation="length:1,10"
                    outer-class="m-0"
                    input-class="$reset rounded w-full input input-sm text-gray-700 bg-gray-50"
                    wrapper-class="max-w-full"
                    v-model.trim="user.user_phone_extension"
                  />

                  <FormKit
                    type="select"
                    label="Level Access"
                    validation="required"
                    :value="0"
                    :options="usersLevelOptions"
                    help-class="text-white"
                    help=""
                    outer-class="m-0 $reset"
                    input-class="$reset select select-sm text-gray-700 bg-gray-50 rounded w-full"
                    wrapper-class="max-w-full"
                    v-model="user.level_access"
                  />

                  <FormKit
                    type="select"
                    label="Department"
                    placeholder=""
                    validation="required"
                    help-class="text-white"
                    :options="departmentOptions"
                    help=""
                    input-class="$reset rounded w-full select select-sm text-gray-700 bg-gray-50"
                    wrapper-class="max-w-full"
                    v-model.trim="user.department"
                  />

                  <FormKit
                    type="password"
                    label="Password"
                    name="password"
                    placeholder=""
                    :validation="
                      'required:trim|' +
                      fieldsDefaultValidation.password.validation
                    "
                    help-class="text-white"
                    help=""
                    outer-class="m-0"
                    input-class="$reset rounded w-full input input-sm text-gray-700 bg-gray-50"
                    wrapper-class="max-w-full"
                    v-model.trim="user.password"
                  />

                  <FormKit
                    type="password"
                    label="Confirm Password"
                    name="password_confirm"
                    placeholder=""
                    :validation="
                      fieldsDefaultValidation.passwordConfirm.validation
                    "
                    help-class="text-white"
                    help=""
                    outer-class="m-0"
                    input-class="$reset rounded w-full input input-sm text-gray-700 bg-gray-50"
                    wrapper-class="max-w-full"
                    v-model.trim="user.password_confirmation"
                  />

                  <FormKit
                    type="autocomplete"
                    label="Timezone of User"
                    validation="required"
                    :options="timezoneOptions"
                    listbox-class="border border-slate-400 rounded"
                    listitem-class="bg-white"
                    listbox-button-class="bg-gray-50 text-gray-700"
                    option-class="text-gray-700"
                    selection-appearance="option"
                    selection-class="text-gray-700"
                    help-class="text-white"
                    :help="`Your Device Timezone: ${browserTimezone}`"
                    outer-class="m-0 col-span-2"
                    input-class="$reset h-8 rounded-r-none text-gray-700 bg-gray-50 rounded w-full"
                    wrapper-class="max-w-full"
                    v-model="user.timezone"
                    :filter="
                      (option, search) =>
                        option.label
                          .toLowerCase()
                          .includes(search.toLowerCase())
                    "
                  />

                  <FormKit
                    type="checkbox"
                    label="Passport Required - Forces IP Restriction on User"
                    help-class="text-white"
                    help=""
                    outer-class="m-0 col-span-2"
                    input-class="text-gray-700 bg-gray-50"
                    decorator-class="bg-white"
                    wrapper-class="max-w-full"
                    v-model="user.passport_required"
                  />

                  <FormKit
                    type="checkbox"
                    label="Enable Appointment Email Alerts"
                    help-class="text-white"
                    help=""
                    outer-class="m-0 col-span-2"
                    input-class="input input-sm text-gray-700 bg-gray-50"
                    wrapper-class="max-w-full"
                    v-model="user.alert_email"
                  />

                  <FormKit
                    type="radio"
                    label="Make User Active"
                    :options="[
                      {
                        label:
                          'Yes - You will be billed for the user immediately',
                        value: true,
                      },
                      {
                        label:
                          'No - User is not immediately billed and will list under Inactive Users',
                        value: false,
                      },
                    ]"
                    outer-class="m-0 col-span-2 border border-white border-width-2 bg-red-500 rounded"
                    fieldset-class="$reset w-full max-w-full p-3"
                    wrapper-class="w-full max-w-full"
                    decorator-class="border border-white"
                    decorator-icon-class="bg-white rounded-full border-white border"
                    v-model="user.active"
                  />
                </div>

                <div class="p-5">
                  <FormKit
                    type="submit"
                    label="Create User"
                    :ingore="false"
                    wrapper-class="max-w-full"
                    outer-class="m-0"
                    input-class="$reset bg-emerald-500 w-full w-full rounded py-3 px-2 text-white"
                  />
                </div>
              </template>
            </FormKit>
          </FormKit>
        </div>
      </div>
    </transition>
    <!-- backdrop -->
  </Teleport>
</template>
