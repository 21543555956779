<script setup>
defineOptions({
  name: 'SettingsTenants', // Give the component a name
});
import {
  //axios
  axios,

  //vue
  createApp,
  ref,
  reactive,
  watch,
  computed,
  inject,
  provide,
  onMounted,
  onUnmounted,
  nextTick,
  Teleport,

  //pinia
  defineStore,
  createPinia,
  storeToRefs,

  //pinia stores
  useChatStore,
  useContactAlertsStore,
  useContactFileStore,
  useContactsStore,
  useGlobalPromptsStore,
  useGlobalToastsStore,
  useLoginStore,
  useTenantsStore,
  useUsersStore,

  //vue-router
  useRouter,
  useRoute,

  //formkit

  //formkit config file

  //fontawesome
  library,
  FontAwesomeIcon,
  fas,
  far,
  fab,

  //centralized data
  usersLevelOptions,
  departmentOptions,
  fieldsDefaultValidation,

  //helpers
  registerGlobalComponents,

  //base api url
  BASE_API_URL,
} from "./../clientImports";

// Call the useContactsStore function to get an instance of the store
const contactsStore = useContactsStore();
const usersStore = useUsersStore();

const sortAndFilterUsersRules = ref(usersStore.sortAndFilterRules);

const applySortAndFilterUserRules = (rules) => {
  usersStore.applySortAndFilter(rules);
};
</script>

<template>
  <div class="h-full flex flex-col bg-base-100">
    <div
      class="w-full bg-base-300 text-white flex flex-row justify-between items-center"
    >
      <div class="flex flex-row items-center p-2">
        <!-- create user router - goes to modals target so no need to nest -->

        <font-awesome-icon icon="fa-solid fa-users" /><span class="ml-3 mr-3"
          >Users</span
        >
        <SettingsTenantsCreate />
      </div>
      <div class="pr-2">
        <cColumnSelector
          :sortAndFilterRules="sortAndFilterUsersRules"
          :applySortAndFilterRules="applySortAndFilterUserRules"
        />
      </div>
    </div>
    <div class="w-full overflow-y-auto flex flex-row justify-between flex-1">
      <div class="w-full overflow-y-auto flex flex-row justify-between flex-1">
        <div class="flex-1 h-full max-w-full overflow-y-auto text-white">
          <router-view></router-view>
        </div>
      </div>
    </div>
    <div class="w-full p-3 bg-base-300 text-white flex flex-row justify-end">
      Some Data Points Will Go Here Eventually - User Count - Active Users vs
      Inactive Users - etc
    </div>
  </div>
</template>
