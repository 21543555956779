<script setup>
defineOptions({
  name: 'TenantSwitchScreen', // Give the component a name
});
import {
  ref,
  onMounted,
  computed,
  watch,
  useNetworkedCompaniesStore,
  useGlobalPromptsStore,
  useTenantsStore,
  useContactsStore,
  useGlobalToastsStore,
  useLoginStore,
  useRoute,
  useRouter,
} from "../clientImports";

const toastsStore = useGlobalToastsStore();

const route = useRoute();
const { tenantLegalName } = route.params;

onMounted(async () => {
  const router = useRouter();

  setTimeout(() => {
    router.push({ name: "dashboard-tab" });

    toastsStore.addToast({
      title: "Tenant Switched",
      description: "",
      buttonExists: false,
      color: "green",
      type: 2, // 1 = small, 2 = md, 3 = lg, 4 = lg dismissable
    });
  }, 1500);
});
</script>

<template>
  <div
    class="h-screen w-screen bg-base-100 flex flex-col justify-center items-center"
  >
    <div class="p-5">
      <div class="text-2xl text-center text-white mb-5">Switching To:</div>
      <div class="text-2xl text-center text-white bg-emerald-500 p-3 rounded">
        {{ tenantLegalName }}
      </div>
    </div>

    <div class="text-center bg-base-300">
      <cLoadingSpinner />
    </div>
  </div>
</template>
