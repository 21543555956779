import {
  //axios
  axios,

  //pinia
  defineStore,

  //env base url
  BASE_API_URL,
} from "../clientImports";

export const useTenantsAttachmentTypesStore = defineStore({
  id: "tenants-attachment-types-store",

  state: () => ({
    tenantsAttachmentTypesDropdownList: [],
  }),
  actions: {
    async fetchTenantsAttachmentsDropdownList() {
      const response = await axios.get(
        `${BASE_API_URL}/read-tenant-attachment-types`
      );

      this.tenantsAttachmentTypesDropdownList =
        response.data.tenantAttachmentTypesList.map((attachment) => {
          return {
            value: attachment._id,
            label: attachment.name,
          };
        });
    },
  },
});
