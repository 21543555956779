<script setup>
defineOptions({
  name: 'cTable', // Give the component a name
});
import {
  axios,
  ref,
  reactive,
  watch,
  useRoute,
  toRefs,
  useRouter,

  //utilities
  globalTableFormatter,
  FontAwesomeIcon,
  tableHeaderFormatting,
  xLog,
} from "./../clientImports";

// Use current route info
const route = useRoute();

// Define props
const props = defineProps({
  tableData: {
    type: Array,
    required: true,
    // default: [
    //   {
    //     name: "Test Data",
    //     email: "Test@test.com",
    //     phone: "555-555-5555",
    //     address: "123 Test St",
    //     city: "Test City",
    //     state: "TS",
    //     zip: "12345",
    //   },
    // ],
  },

  tableName: {
    type: String,
    required: true,
  },

  //prop functions

  onDelete: {
    type: Function,
  },

  onEdit: {
    type: Function,
  },

  onSort: {
    type: Function,
    required: false,
  },

  tableTransformerFunction: {
    type: Function,
    required: false,
  },
});

// Destructure props to reactive variables
const { tableData, tableName } = toRefs(props);

//log incoming table data
// xLog("Incoming Table Data: ", tableData.value);

const isLoading = ref(true);

// Watch props if necessary
watch(tableData, (newVal) => {
  // Do something when tableData changes
  if (newVal) {
    isLoading.value = false;
  }
});

watch(tableName, (newVal) => {
  // Do something when tableName changes
});

const handleDelete = (row) => {
  props.onDelete(row);
};

const handleEdit = (row) => {
  props.onEdit(row);
};

const sorting = reactive({
  sortBy: null,
  sortDirection: null,
});

// Modified handleSort function
const handleSort = (key) => {
  if (sorting.sortBy === key) {
    if (sorting.sortDirection === "asc") {
      sorting.sortDirection = "desc";
    } else if (sorting.sortDirection === "desc") {
      sorting.sortBy = null;
      sorting.sortDirection = null;
    }
  } else {
    sorting.sortBy = key;
    sorting.sortDirection = "asc";
  }

  props.onSort({
    sortBy: sorting.sortBy,
    sortDirection: sorting.sortDirection,
  });
};
</script>

<template>
  <div class="w-full h-full">
    <cLoadingSpinner v-if="isLoading" />
    <table
      v-else-if="!isLoading && tableData?.length > 0"
      class="w-full rounded-none bg-base-100"
    >
      <thead class="sticky top-0 bg-base-100">
        <tr class="text-sm text-white whitespace-nowrap">
          <!-- table headers -->
          <th
            v-for="(header, key) in tableData[0]"
            :key="key"
            class="text-left capitalize py-1 px-1"
          >
            <button
              @click.stop="handleSort(key)"
              :class="{
                'glass bg-red-300 pr-7':
                  sorting.sortBy === key && sorting.sortDirection === 'asc',
                'glass bg-blue-300 pr-7':
                  sorting.sortBy === key && sorting.sortDirection === 'desc',
              }"
              class="btn btn-xs md:btn-sm text-xs md:text-base rounded text-white bg-slate-600 w-full text-left capitalize border-none"
            >
              {{ tableHeaderFormatting(key) }}

              <span
                class="absolute right-2 flex flex-row justify-center items-center"
              >
                <font-awesome-icon
                  v-if="
                    sorting.sortBy === key && sorting.sortDirection === 'asc'
                  "
                  icon="fa-solid fa-sort-up"
                />

                <font-awesome-icon
                  v-else-if="
                    sorting.sortBy === key && sorting.sortDirection === 'desc'
                  "
                  icon="fa-solid fa-sort-down"
                />
              </span>
            </button>
          </th>
          <!-- static columns header -->
          <th class="p-1 md:p-2 text-center sticky right-0 bg-slate-900 w-22">
            <span class="hidden md:block">Actions</span>
          </th>
        </tr>
      </thead>
      <tbody class="">
        <!-- rows -->
        <tr
          v-for="(row, rowKeys, rowIndex) in tableData"
          :key="rowKeys"
          class="text-white h-10 odd:bg-slate-700"
        >
          <!-- row cells -->
          <td
            v-for="(rowCell, rowCellKey, rowCellIndex) in row"
            :key="rowCellKey"
            class="px-1 md:p-3 text-center whitespace-nowrap text-xs md:text-base"
          >
            <component
              :is="globalTableFormatter(rowCellKey, rowCell, tableName).value"
              v-if="
                globalTableFormatter(rowCellKey, rowCell, tableName)
                  ?.isVueComponent
              "
            ></component>
            <span
              v-else-if="
                globalTableFormatter(rowCellKey, rowCell, tableName)?.isHtml
              "
              v-html="
                globalTableFormatter(rowCellKey, rowCell, tableName)?.value
              "
            ></span>
            <span v-else>
              {{ globalTableFormatter(rowCellKey, rowCell, tableName)?.value }}
            </span>
          </td>
          <td
            class="even:bg-slate-800 odd:bg-slate-900 p-1 md:p-2 text-right sticky right-0 w-24"
            :class="rowCellIndex % 2 === 0 ? 'bg-slate-800' : 'bg-slate-900'"
          >
            <div
              class="flex flex-col md:flex-row justify-center md:items-center items-stretch"
            >
              <button
                v-if="props.onDelete !== null"
                class="btn btn-xs md:btn-sm rounded-t rounded-b-none md:rounded-l md:rounded-r-none bg-rose-500 hover:bg-rose-700 text-white border-none"
                @click.stop="handleDelete(row._id)"
              >
                <font-awesome-icon icon="fa-solid fa-xmark" />
              </button>
              <!-- <button
                class="btn bg-orange-500 hover:bg-orange-700 text-white border-none"
              >
                <font-awesome-icon
                  icon="fa-solid fa-ellipsis-vertical"
                  size="xl"
                />
              </button> -->
              <button
                v-if="props.onEdit !== null"
                @click.stop="handleEdit(row._id)"
                class="btn btn-xs md:btn-sm bg-blue-500 rounded-b rounded-t-none md:rounded-l-none md:rounded-r hover:bg-blue-700 text-white border-none"
              >
                <font-awesome-icon icon="fa-solid fa-edit" />
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <!-- IF CONTACTS EMPTY SHOW LOAD CONTACTS BTN -->
    <div
      v-else-if="!isLoading && tableData?.length === 0"
      class="bg-sky-400 p-5 rounded flex flex-row justify-center items-center"
    >
      <span class="text-white mr-3 capitalize"
        >No {{ tableName.replaceAll("_", " ") }} Yet</span
      >
    </div>
  </div>
</template>
