<script setup>
defineOptions({
  name: 'LoginPage', // Give the component a name
});
import { io } from "socket.io-client";
import {
  //axios
  axios,

  //vue
  createApp,
  ref,
  reactive,
  watch,
  computed,
  inject,
  provide,
  onMounted,
  onUnmounted,
  nextTick,
  Teleport,

  //pinia
  defineStore,
  createPinia,
  storeToRefs,

  //pinia stores
  useChatStore,
  useContactAlertsStore,
  useContactFileStore,
  useContactsStore,
  useGlobalPromptsStore,
  useGlobalToastsStore,
  useLoginStore,
  useTenantsStore,

  //vue-router
  useRouter,
  useRoute,

  //formkit

  //formkit config file

  //fontawesome
  library,
  FontAwesomeIcon,
  fas,
  far,
  fab,

  //global components - all vue components
  registerGlobalComponents,

  //utils
  xLog,

  //base api url
  BASE_API_URL,
} from "./../clientImports";

// Call the useLoginStore function to get an instance of the store
const loginStore = useLoginStore();

const toastsStore = useGlobalToastsStore();

const router = useRouter();
const route = useRoute();

// TODO - un commment these and delete static credentials before launch
const domainPrefix = ref("");

const companyName = ref("");
const email = ref("");
const password = ref("");

const domainLocated = ref(false);

onMounted(() => {
  const domainExtension =
    import.meta.env.VITE_ENV === "staging" ||
    import.meta.env.VITE_ENV === "production"
      ? "com"
      : "localhost:5173";

  if (loginStore?.sessionData) {
    if (
      loginStore?.sessionData?.permissions?.includes("view:dashboard tab") ||
      loginStore?.sessionData?.permissions?.includes("*:dev-permissions")
    ) {
      window.location.href = `${window.location.protocol}//${loginStore.sessionData.tenant.domain_prefix}.app.finnypi.${domainExtension}/home/dashboard`;
    } else {
      window.location.href = `${window.location.protocol}//${loginStore.sessionData.tenant.domain_prefix}.app.finnypi.${domainExtension}/home`;
    }
  }
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const param1 = urlParams.get("domain_id");
  const param2 = urlParams.get("domain_prefix");

  companyName.value = param2;

  if (param1?.length === 24) {
    domainLocated.value = true;
  } else {
    if (!window.location.hostname.startsWith("crm")) {
      window.location.href = `${window.location.protocol}//crm.app.finnypi.${domainExtension}/login`;
    }
  }
});

const instantLogin = () => {
  const promptPass = prompt("Enter Admin Pass");

  switch (promptPass) {
    case "stage1":
      email.value = "staging@one.com";
      password.value = "myClutchSuperSecretPass321!@#";
      break;
    case "stage2":
      email.value = "staging@two.com";
      password.value = "asdfasdfasdf";
      break;
    case "stage3":
      email.value = "staging@three.com";
      password.value = "asdfasdfasdf";
      break;
    case "prod1":
      email.value = "nick@finnypi.com";
      password.value = "myClutchSuperSecretPass321!@#";
      break;
    case "prod2":
      email.value = "nick2@finnypi.com";
      password.value = "asdfasdfasdf";
      break;
    case "prod3":
      email.value = "nick3@finnypi.com";
      password.value = "asdfasdfasdf";
      break;
    default:
      toastsStore.addToast({
        title: "Invalid Admin Pass",
        description: `Please try again.`,
        buttonExists: false,
        color: "red",
        type: 1, // 1 = small, 2 = md, 3 = lg, 4 = lg dismissable
      });
      break;
  }
};

const loginDomain = async () => {
  const domain_prefix = domainPrefix.value.toLowerCase();
  try {
    const { data } = await axios.get(
      `${BASE_API_URL}/login-domain/${domain_prefix}`
    );

    console.log(data);

    if (data.status === "success") {
      //navigate to login with 1 param which will contain the domain _id
      window.location.href = `${data.domain.url}?domain_id=${data.domain.tenant_id}&domain_prefix=${domain_prefix}`;
    } else {
      toastsStore.addToast({
        title: "Domain Not Found",
        description: `Issue Logging In ${data.message}`,
        buttonExists: false,
        color: "red",
        type: 4, // 1 = small, 2 = md, 3 = lg, 4 = lg dismissable
      });
    }
  } catch (error) {
    console.log(error);
    toastsStore.addToast({
      title: "Error",
      description: `Domain Doesn't Exist`,
      buttonExists: false,
      color: "red",
      type: 3, // 1 = small, 2 = md, 3 = lg, 4 = lg dismissable
    });
  }
};

const login = async () => {
  try {
    const response = await axios.post(`${BASE_API_URL}/login`, {
      companyName: companyName.value.toLowerCase(),
      email: email.value.toLowerCase(),
      password: password.value,
    });

    const sessionData = response.data;

    // xLog("Sesssion Data: ", sessionData);
    // xLog("Login Response Status: ", response.data.status);

    console.log("LOGIN RESPONSE LOGIN PAGE: ", response.data);

    if (response.data.status === "success") {
      // use the loginStore to set the user data
      loginStore.setSessionData(sessionData);

      loginStore.setDomainPrefix(sessionData.tenant.domain_prefix);

      // show toast
      toastsStore.addToast({
        title: "Login Successful",
        description: `Welcome ${loginStore.sessionData.foundUser.first_name}! 😃`,
        buttonExists: false,
        color: "emerald",
        type: 1, // 1 = small, 2 = md, 3 = lg, 4 = lg dismissable
      });
      if (
        loginStore?.sessionData?.permissions?.includes("view:dashboard tab") ||
        loginStore?.sessionData?.permissions?.includes("*:dev-permissions")
      ) {
        router.push({ name: "dashboard-tab" });
      } else {
        router.push({ name: "root" });
      }
    } else if (response.data.status === "failed") {
      toastsStore.addToast({
        title: "Login Failed",
        description: `REASON: ${response.data.message}`,
        buttonExists: false,
        color: "red",
        type: 4, // 1 = small, 2 = md, 3 = lg, 4 = lg dismissable
      });
    } else {
      toastsStore.addToast({
        title: "Login Failed",
        description: `REASON: ${response.data.message}`,
        buttonExists: false,
        color: "red",
        type: 1, // 1 = small, 2 = md, 3 = lg, 4 = lg dismissable
      });
    }
  } catch (error) {
    console.error(error);
    toastsStore.addToast({
      title: "Login Failed",
      description: `REASON: ${error.message}`,
      buttonExists: false,
      color: "red",
      type: 1, // 1 = small, 2 = md, 3 = lg, 4 = lg dismissable
    });
  }
};

const callback = (response) => {
  // This callback will be triggered when the user selects or login to
  // his Google account from the popup
  console.log("Handle the response", response);
};
</script>

<template>
  <div @click.stop="instantLogin" class="p-3 fixed top-0 right-0"></div>

  <div
    class="flex flex-col justify-center items-center h-screen bg-gradient-to-tr from-orange-500 via-indigo-500 to-green-500 p-5"
  >
    <!-- Form -->

    <div class="bg-white p-10 rounded w-full md:w-96">
      <div class="w-full text-center">
        <cSystemLogo></cSystemLogo>
      </div>
      <h3
        v-if="domainLocated === false"
        class="text-lg text-center text-white mb-8 bg-gradient-to-br from-indigo-300 to-blue-600 mt-2"
      >
        Domain Portal Access
      </h3>
      <FormKit
        v-if="domainLocated === false"
        id="domain-access-form"
        type="form"
        :incomplete-message="false"
        submit-label="Login"
        actions-class="mt-8"
        :actions="false"
        @submit="loginDomain"
        :submit-attrs="{
          wrapperClass: 'flex flex-row justify-between',
          inputClass:
            '$reset bg-blue-500 w-full max-w-full rounded py-3 px-2 text-white',
          ignore: false,
        }"
      >
        <FormKit
          label-class="text-gray-700"
          inner-class="mt-2"
          label="Domain Portal"
          type="text"
          input-class="$reset input input-xs md:input-sm max-w-full w-full rounded bg-white text-slate-600"
          validation="required|length:2"
          :validation-messages="{ length: '3 Character Minimum' }"
          messages-class="relative"
          message-class="absolute -top-5 right-2 bg-red-500 rounded text-xs text-white p-1"
          v-model.trim="domainPrefix"
        />

        <!-- <h1
          v-if="domainPrefix.length >= 2"
          class="border p-2 rounded bg-gray-100 text-center text-xl text-gray-600"
        >
          <span class="text-emerald-500 bold">{{ domainPrefix }}</span
          >.app.finnypi.com
        </h1> -->

        <div
          v-if="domainPrefix.length >= 2"
          class="flex flex-col mb-4 border border-slate-400 rounded p-2"
        >
          <FormKit
            v-if="domainPrefix.length >= 2"
            type="submit"
            :label="`Log In @ ${domainPrefix}.app.finnypi.com`"
            outer-class="m-0"
            :ingore="false"
            input-class="$reset bg-gradient-to-br from-indigo-300 to-blue-600 w-full max-w-full rounded py-3 px-2 text-white"
          />

          <!-- <p
            v-if="domainPrefix.length >= 2"
            class="text-center text-gray-600 mb-4 text-xl"
          >
            OR
          </p>

          <button
            type="button"
            v-if="domainPrefix.length >= 2"
            @click="loginWithGoogle"
            class="btn text-white rounded capitalize"
          >
            <img
              class="w-5"
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/c1/Google_%22G%22_logo.svg/800px-Google_%22G%22_logo.svg.png"
              alt=""
            />
            <span class="ml-2">Login With Google</span>
          </button> -->
        </div>

        <div class="flex flex-row justify-between gap-4">
          <FormKit
            type="button"
            label="Password Reset"
            outer-class="w-full"
            input-class="$reset btn btn-sm rounded capitalize text-white bg-orange-500 hover:bg-orange-700 border-none w-full"
            @click="router.push({ name: 'password-reset' })"
          />

          <FormKit
            type="button"
            outer-class="w-full"
            label="Sign Up"
            input-class="$reset btn btn-sm rounded capitalize text-white bg-emerald-500 hover:bg-emerald-700  border-none w-full"
            @click="router.push({ name: 'sign-up' })"
          />
        </div>
        <div class="flex flex-row justify-center items-center">
          <!-- <InstallPWA /> -->
        </div>
      </FormKit>

      <h3
        v-if="domainLocated === true"
        class="text-lg text-center text-white mb-8 bg-gradient-to-br from-indigo-300 to-blue-600 mt-2"
      >
        Account Sign In
      </h3>
      <FormKit
        v-if="domainLocated === true"
        id="login-form"
        type="form"
        :incomplete-message="false"
        submit-label="Login"
        actions-class="mt-8"
        :actions="false"
        @submit="login"
        :submit-attrs="{
          wrapperClass: 'flex flex-row justify-between',
          inputClass:
            '$reset bg-blue-500 w-full max-w-full rounded py-3 px-2 text-white',
          ignore: false,
        }"
        #default="{ state }"
      >
        <!-- <FormKit
          label-class="text-gray-700"
          inner-class="mt-2 mb-2"
          label="Company Abbreviation"
          type="text"
          input-class="$reset input input-xs md:input-sm max-w-full w-full rounded bg-white text-slate-600"
          validation="required|length:2"
          :validation-messages="{ length: '2 Character Minimum' }"
          messages-class="relative"
          message-class="absolute -top-5 right-2 bg-red-500 rounded text-xs text-white p-1"
          v-model.trim="companyName"
        /> -->

        <FormKit
          label-class="text-gray-700"
          inner-class="mt-2 mb-2"
          label="Email"
          type="text"
          input-class="$reset input input-xs md:input-sm max-w-full w-full rounded bg-white text-slate-600"
          validation="required|email"
          :validation-messages="{ email: 'Invalid Email Format' }"
          messages-class="relative"
          message-class="absolute -top-5 right-2 bg-red-500 rounded text-xs text-white p-1"
          v-model.trim="email"
        />

        <FormKit
          label-class="text-gray-700"
          inner-class="mt-2 mb-2"
          label="Password"
          type="text"
          input-class="$reset input input-xs md:input-sm max-w-full w-full rounded bg-white text-slate-600"
          validation="required|length:6"
          :validation-messages="{ length: '6 Character Minimum' }"
          messages-class="relative"
          message-class="absolute -top-5 right-2 bg-red-500 rounded text-xs text-white p-1"
          v-model.trim="password"
        />

        <FormKit
          v-if="!state.submitted"
          type="submit"
          label="Sign In"
          :ingore="false"
          input-class="$reset bg-gradient-to-br from-indigo-300 to-blue-600 w-full max-w-full rounded py-3 px-2 text-white mt-7"
        />

        <div class="flex flex-row justify-between gap-4">
          <FormKit
            type="button"
            label="Password Reset"
            outer-class="w-full"
            input-class="$reset btn btn-sm rounded capitalize text-white bg-orange-500 hover:bg-orange-700 border-none w-full"
            @click="router.push({ name: 'password-reset' })"
          />

          <FormKit
            type="button"
            outer-class="w-full"
            label="Sign Up"
            input-class="$reset btn btn-sm rounded capitalize text-white bg-emerald-500 hover:bg-emerald-700  border-none w-full"
            @click="router.push({ name: 'sign-up' })"
          />
        </div>
        <div class="flex flex-row justify-between items-center">
          <InstallPWA />
        </div>
      </FormKit>
    </div>
  </div>
</template>
