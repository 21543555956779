<script setup>
defineOptions({
  name: 'ContactOptIn', // Give the component a name
});
import {
  //axios
  axios,

  //vue
  createApp,
  ref,
  reactive,
  watch,
  computed,
  inject,
  provide,
  onMounted,
  onUnmounted,
  nextTick,
  Teleport,

  //pinia
  defineStore,
  createPinia,
  storeToRefs,

  //pinia stores
  useChatStore,
  useContactAlertsStore,
  useContactFileStore,
  useContactsStore,
  useGlobalPromptsStore,
  useGlobalToastsStore,
  useLoginStore,
  useTenantsStore,

  //vue-router
  useRouter,
  useRoute,

  //formkit

  //formkit config file

  //fontawesome
  library,
  FontAwesomeIcon,
  fas,
  far,
  fab,

  //global components - all vue components
  registerGlobalComponents,

  //base api url
  BASE_API_URL,
} from "./../clientImports";

const toastsStore = useGlobalToastsStore();
const contactFileStore = useContactFileStore();

const optOptionsPhone = ref([
  { value: "not-opted", label: "Not Opted" },
  { value: "opted-out", label: "Opted Out" },
  { value: "opted-in", label: "Opted In" },
]);

const optOptionsSMS = ref([
  { value: "not-opted", label: "Not Opted" },
  { value: "opted-in", label: "Opted In" },
  { value: "opted-out", label: "Opted Out" },
]);

const optOptionsEmail = ref([
  { value: "not-opted", label: "Not Opted" },
  { value: "opted-in", label: "Opted In" },
  { value: "opted-out", label: "Opted Out" },
]);
const optOptionsMailers = ref([
  { value: "not-opted", label: "Not Opted" },
  { value: "opted-in", label: "Opted In" },
  { value: "opted-out", label: "Opted Out" },
]);

const contactOptInPhone = ref("not-opted");

const contactOptInSMS = ref("not-opted");

const contactOptInEmail = ref("not-opted");

const contactOptInMailers = ref("not-opted");

onMounted(() => {
  contactOptInPhone.value = contactFileStore.currentContactFile?.calls_opt_in;
  contactOptInSMS.value = contactFileStore.currentContactFile?.sms_opt_in;
  contactOptInEmail.value = contactFileStore.currentContactFile?.emails_opt_in;
  contactOptInMailers.value =
    contactFileStore.currentContactFile?.mailers_opt_in;

  watch(
    () => contactFileStore.currentContactFile?.calls_opt_in,
    (newVal, oldVal) => {
      contactOptInPhone.value = newVal;
    }
  );

  watch(
    () => contactFileStore.currentContactFile?.sms_opt_in,
    (newVal, oldVal) => {
      contactOptInSMS.value = newVal;
    }
  );

  watch(
    () => contactFileStore.currentContactFile?.emails_opt_in,
    (newVal, oldVal) => {
      contactOptInEmail.value = newVal;
    }
  );

  watch(
    () => contactFileStore.currentContactFile?.mailers_opt_in,
    (newVal, oldVal) => {
      contactOptInMailers.value = newVal;
    }
  );
});

//sends patch to api to update any opt in status
const updateOptInStatus = async (optType, optInStatusVal) => {
  contactFileStore.updateContactFlag(`${optType}_opt_in`, optInStatusVal);

  toastsStore.addToast({
    title: `${
      optType.charAt(0).toUpperCase() + optType.slice(1)
    } Opt-In Status Updated`,
    buttonExists: false,
    color: "emerald",
    type: 1, // 1 = small, 2 = md, 3 = lg, 4 = lg dismissable
  });
};
</script>
<template>
  <div class="flex flex-col justify-between">
    <!-- Phone -->
    <div class="flex flex-row justify-between items-center">
      <div class="p-1 text-xs md:text-sm md:p-2 bg-indigo-900 text-white w-3/6">
        <font-awesome-icon icon="fa-solid fa-phone" /> Calls
      </div>

      <select
        @change="updateOptInStatus('calls', contactOptInPhone)"
        v-model="contactOptInPhone"
        :value="contactFileStore.currentContactFile?.calls_opt_in"
        name=""
        id=""
        class="flex-1 text-white h-full w-full text-xs md:text-sm text-center"
      >
        <option
          v-for="(option, optionIndex) in optOptionsPhone"
          :key="optionIndex"
          :value="option.value"
        >
          {{ option.label }}
        </option>
      </select>
    </div>
    <!-- SMS -->
    <div class="flex flex-row justify-between items-center">
      <div
        class="p-1 text-xs md:text-sm md:p-2 bg-emerald-900 text-white w-3/6"
      >
        <font-awesome-icon icon="fa-solid fa-message" /> Texts
      </div>
      <select
        @change="updateOptInStatus('sms', contactOptInSMS)"
        :value="contactFileStore.currentContactFile?.sms_opt_in"
        name=""
        id=""
        class="flex-1 text-white h-full w-full text-xs md:text-sm text-center"
        v-model="contactOptInSMS"
      >
        <option
          v-for="(option, optionIndex) in optOptionsSMS"
          :key="optionIndex"
          :value="option.value"
        >
          {{ option.label }}
        </option>
      </select>
    </div>
    <!-- Email -->
    <div class="flex flex-row justify-between items-center">
      <div class="p-1 md:p-2 text-white bg-blue-900 w-3/6 text-xs md:text-sm">
        <font-awesome-icon icon="fa-solid fa-envelope" /> Emails
      </div>
      <select
        @change="updateOptInStatus('emails', contactOptInEmail)"
        :value="contactFileStore.currentContactFile?.emails_opt_in"
        v-model="contactOptInEmail"
        name=""
        id=""
        class="flex-1 text-white h-full w-full text-xs md:text-sm text-center"
      >
        <option
          v-for="(option, optionIndex) in optOptionsEmail"
          :key="optionIndex"
          :value="option.value"
        >
          {{ option.label }}
        </option>
      </select>
    </div>

    <!-- Mailers -->
    <div class="flex flex-row justify-between items-center">
      <div class="p-1 md:p-2 text-white bg-orange-900 w-3/6 text-xs md:text-sm">
        <font-awesome-icon icon="fa-solid fa-envelopes-bulk" /> Mailers
      </div>
      <select
        @change="updateOptInStatus('mailers', contactOptInMailers)"
        :value="contactFileStore.currentContactFile?.mailers_opt_in"
        v-model="contactOptInMailers"
        name=""
        id=""
        class="flex-1 text-white h-full w-full text-xs md:text-sm text-center"
      >
        <option
          v-for="(option, optionIndex) in optOptionsMailers"
          :key="optionIndex"
          :value="option.value"
        >
          {{ option.label }}
        </option>
      </select>
    </div>
  </div>
</template>
