<script setup>
defineOptions({
  name: 'SettingsTemplatesEmailsCreate', // Give the component a name
});
import {
  //axios
  axios,

  //vue
  createApp,
  ref,
  reactive,
  watch,
  computed,
  inject,
  provide,
  onMounted,
  onUnmounted,
  nextTick,
  Teleport,

  //pinia
  defineStore,
  createPinia,
  storeToRefs,

  //pinia stores
  useChatStore,
  useContactAlertsStore,
  useContactFileStore,
  useContactsStore,
  useGlobalPromptsStore,
  useGlobalToastsStore,
  useLoginStore,
  useTenantsStore,
  useUsersStore,
  useTenantsTemplatesEmailsStore,

  //vue-router
  useRouter,
  useRoute,

  //formkit

  //formkit config file
  FKsubmitForm,

  //fontawesome
  library,
  FontAwesomeIcon,
  fas,
  far,
  fab,

  //centralized data
  usersLevelOptions,
  fieldsDefaultValidation,
  stateOptions,

  //utilities
  getRandomNumberBetween,

  //helpers
  registerGlobalComponents,

  //utilities
  stripEmptyFields,

  //base api url
  BASE_API_URL,
  Editor,
} from "./../clientImports";

import {
  browserTimezone,
  timezoneOptions,
} from "./../centralized_data/timezoneOptions";

const router = useRouter();
const route = useRoute();

const loginStore = useLoginStore();

const usersStore = useUsersStore();
const toastsStore = useGlobalToastsStore();
const promptsStore = useGlobalPromptsStore();
const tenantsTemplatesEmailsStore = useTenantsTemplatesEmailsStore();

//use FKsubmitForm to submit form
const submitCreateForm = () => {
  tenantsTemplatesEmailsStore.currentlyCreatingTemplatesEmail.body = tinymce
    .get("templates-emails-create")
    .getContent();

  return FKsubmitForm("create-templates-emails");
};

const ccToAdd = ref("");

const addToCC = () => {
  tenantsTemplatesEmailsStore.currentlyCreatingTemplatesEmail.cc.push(
    ccToAdd.value
  );
  ccToAdd.value = "";
};

const bccToAdd = ref("");

const addToBCC = () => {
  tenantsTemplatesEmailsStore.currentlyCreatingTemplatesEmail.bcc.push(
    bccToAdd.value
  );
  bccToAdd.value = "";
};
</script>

<template>
  <cSidebarModal
    sidebar-position="right"
    :show-sidebar-initial="false"
    :show-sidebar-modal="
      tenantsTemplatesEmailsStore.showTenantsTemplatesEmailsCreateModal
    "
    :toggle-sidebar-modal="
      tenantsTemplatesEmailsStore.toggleTenantsTemplatesEmailsCreateModal
    "
    :default-button="false"
  >
    <template #title>
      <h2>Create Email Template</h2>
    </template>

    <template #body>
      <FormKit
        id="create-templates-emails"
        type="form"
        :incomplete-message="false"
        submit-label="Create"
        form-class="text-white h-full flex flex-col justify-between"
        @submit="tenantsTemplatesEmailsStore.createTenantsTemplatesEmail()"
        outer-class="m-0"
        :actions="false"
        :submit-attrs="{
          'data-theme': `light`,
          ignore: true,
        }"
      >
        <div class="grid grid-cols-4 gap-5 pb-5">
          <FormKit
            type="text"
            label="Name"
            placeholder=""
            :validation="'required:trim|length:5, 40'"
            help-class="text-white"
            help=""
            outer-class="m-0 col-span-3"
            input-class="$reset rounded w-full input input-sm text-gray-700 bg-gray-50"
            wrapper-class="max-w-full"
            v-model.trim="
              tenantsTemplatesEmailsStore.currentlyCreatingTemplatesEmail.name
            "
          />

          <FormKit
            outer-class="col-span-1"
            type="toggle"
            name="toggle"
            label="Active"
            alt-label-position
            v-model="
              tenantsTemplatesEmailsStore.currentlyCreatingTemplatesEmail.active
            "
          />

          <FormKit
            type="textarea"
            label="Description"
            placeholder=""
            :validation="'trim|length:1, 255'"
            help-class="text-white"
            help=""
            outer-class="m-0 col-span-4"
            input-class="$reset rounded w-full input input-sm text-gray-700 bg-gray-50"
            wrapper-class="max-w-full"
            v-model.trim="
              tenantsTemplatesEmailsStore.currentlyCreatingTemplatesEmail
                .description
            "
          />

          <FormKit
            type="email"
            label="From Email"
            placeholder=""
            :validation="'trim|email'"
            help-class="text-white"
            help=""
            outer-class="m-0 col-span-2"
            input-class="$reset rounded w-full input input-sm text-gray-700 bg-gray-50"
            wrapper-class="max-w-full"
            v-model.trim="
              tenantsTemplatesEmailsStore.currentlyCreatingTemplatesEmail.from
            "
          />

          <FormKit
            type="email"
            label="To Email"
            placeholder=""
            :validation="[
              ['required'],
              [
                'matches',
                /^([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}|\{.*\})$/,
              ],
            ]"
            :validation-messages="{
              matches: 'Must be a valid email or tag',
            }"
            help-class="text-white"
            help=""
            outer-class="m-0 col-span-2"
            input-class="$reset rounded w-full input input-sm text-gray-700 bg-gray-50"
            wrapper-class="max-w-full"
            v-model.trim="
              tenantsTemplatesEmailsStore.currentlyCreatingTemplatesEmail.to
            "
          />
          <div class="col-span-4 flex flex-row items-end gap-5">
            <FormKit
              type="email"
              label="CC"
              placeholder="Add to CC list"
              @keyup.enter="
                /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(ccToAdd)
                  ? addToCC()
                  : null
              "
              @keydown.enter.prevent
              :validation="'trim|email'"
              help-class="text-white"
              help=""
              outer-class="m-0 flex-1"
              input-class="$reset rounded w-full input input-sm text-gray-700 bg-gray-50"
              wrapper-class="max-w-full"
              v-model.trim="ccToAdd"
            />

            <button
              v-if="
                /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(ccToAdd)
              "
              @click="addToCC"
              type="button"
              class="btn btn-xs md:btn-sm rounded border border-slate-400 text-white hover:bg-gray-700"
            >
              <!-- Font awesome plus -->
              <font-awesome-icon icon="fa-solid fa-plus" />
            </button>
          </div>

          <div
            v-if="
              tenantsTemplatesEmailsStore.currentlyCreatingTemplatesEmail.cc
                .length > 0
            "
            class="col-span-4 flex flex-row items-center gap-2 max-w-full overflow-x-none flex-wrap"
          >
            <div
              v-for="(email, i) in tenantsTemplatesEmailsStore
                .currentlyCreatingTemplatesEmail.cc"
              :key="i"
              class="bg-gray-400 rounded pl-3 flex flex-row items-center gap-3 overflow-clip border border-slate-400"
            >
              <span class="text-xs">{{ email }}</span>
              <!-- button to delete item from array -->
              <button
                @click="
                  tenantsTemplatesEmailsStore.currentlyCreatingTemplatesEmail.cc.splice(
                    i,
                    1
                  )
                "
                class="btn btn-xs rounded-none border-none hover:bg-red-500 text-red-500"
              >
                <!-- garbage can font awesome -->
                <font-awesome-icon icon="fa-solid fa-trash" />
              </button>
            </div>
          </div>
          <div class="col-span-4 flex flex-row items-end gap-5">
            <FormKit
              type="email"
              label="BCC"
              @keyup.enter="
                /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
                  bccToAdd
                )
                  ? addToBCC()
                  : null
              "
              @keydown.enter.prevent
              placeholder="Add to BCC list"
              :validation="'trim|email'"
              help-class="text-white"
              help=""
              outer-class="m-0 flex-1"
              input-class="$reset rounded w-full input input-sm text-gray-700 bg-gray-50"
              wrapper-class="max-w-full"
              v-model.trim="bccToAdd"
            />

            <button
              v-if="
                /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
                  bccToAdd
                )
              "
              @click="addToBCC"
              type="button"
              class="btn btn-xs md:btn-sm rounded border border-slate-400 text-white hover:bg-gray-700"
            >
              <!-- Font awesome plus -->
              <font-awesome-icon icon="fa-solid fa-plus" />
            </button>
          </div>
          <div
            v-if="
              tenantsTemplatesEmailsStore.currentlyCreatingTemplatesEmail.bcc
                .length > 0
            "
            class="col-span-4 flex flex-row items-center gap-2 max-w-full overflow-x-none flex-wrap"
          >
            <div
              v-for="(email, i) in tenantsTemplatesEmailsStore
                .currentlyCreatingTemplatesEmail.bcc"
              :key="i"
              class="bg-gray-400 rounded pl-3 flex flex-row items-center gap-3 overflow-clip border border-slate-400"
            >
              <span class="text-xs">{{ email }}</span>
              <!-- button to delete item from array -->
              <button
                @click="
                  tenantsTemplatesEmailsStore.currentlyCreatingTemplatesEmail.bcc.splice(
                    i,
                    1
                  )
                "
                class="btn btn-xs rounded-none border-none hover:bg-red-500 text-red-500"
              >
                <!-- garbage can font awesome -->
                <font-awesome-icon icon="fa-solid fa-trash" />
              </button>
            </div>
          </div>

          <FormKit
            type="text"
            label="Subject"
            placeholder=""
            :validation="'required:trim|length:5, 40'"
            help-class="text-white"
            help=""
            outer-class="m-0 col-span-4"
            input-class="$reset rounded w-full input input-sm text-gray-700 bg-gray-50"
            wrapper-class="max-w-full"
            v-model.trim="
              tenantsTemplatesEmailsStore.currentlyCreatingTemplatesEmail
                .subject
            "
          />

          <cTinyEditor
            editorId="templates-emails-create"
            propHeight="500px"
            propWidth="100%"
          />
        </div>
      </FormKit>
    </template>

    <template #footer>
      <!-- BUTTON FOOTER - CANCEL & UPDATE TemplatesEmail -->
      <div class="flex flex-row justify-between text-white w-full">
        <button
          @click="
            tenantsTemplatesEmailsStore.toggleTenantsTemplatesEmailsCreateModal
          "
          class="btn btn-xs md:btn-sm rounded bg-red-500 text-white hover:bg-red-700"
        >
          Cancel
        </button>
        <button
          @click="submitCreateForm"
          class="btn btn-xs md:btn-sm rounded bg-emerald-500 text-white hover:bg-emerald-700"
        >
          Create Email Template
        </button>
      </div>
    </template>
  </cSidebarModal>
</template>
