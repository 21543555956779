<script setup>
defineOptions({
  name: "SupportTicketsEdit", // Give the component a name
});
import {
  //axios
  axios,

  //vue
  createApp,
  ref,
  reactive,
  watch,
  computed,
  inject,
  provide,
  onMounted,
  onUnmounted,
  nextTick,
  Teleport,

  //pinia
  defineStore,
  createPinia,
  storeToRefs,

  //pinia stores
  useChatStore,
  useContactAlertsStore,
  useContactFileStore,
  useContactsStore,
  useGlobalPromptsStore,
  useGlobalToastsStore,
  useLoginStore,
  useTenantsStore,
  useUsersStore,
  useSupportTicketsStore,

  //vue-router
  useRouter,
  useRoute,

  //formkit

  //formkit config file
  FKsubmitForm,

  //fontawesome
  library,
  FontAwesomeIcon,
  fas,
  far,
  fab,

  //centralized data
  usersLevelOptions,
  fieldsDefaultValidation,
  stateOptions,

  //utilities
  getRandomNumberBetween,

  //helpers
  registerGlobalComponents,

  //utilities
  stripEmptyFields,

  //base api url
  BASE_API_URL,
} from "./../clientImports";

import {
  browserTimezone,
  timezoneOptions,
} from "./../centralized_data/timezoneOptions";

import { globalIsoToLocal } from "./../utilities/globalTimezoneConverter";

const router = useRouter();
const route = useRoute();

const loginStore = useLoginStore();

const usersStore = useUsersStore();
const toastsStore = useGlobalToastsStore();
const promptsStore = useGlobalPromptsStore();
const supportTicketsStore = useSupportTicketsStore();

//use FKsubmitForm to submit form
const submitUpdateForm = () => {
  FKsubmitForm("edit-tickets");
};

const timeConverter = (value) => {
  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    // second: "2-digit",
  };

  let formattedDate = globalIsoToLocal(value, options);

  // Replace slashes with hyphens and remove the comma
  return formattedDate.replace(/\//g, "-").replace(",", "");
};

const addReply = ref(false);
const toggleAddReply = () => {
  addReply.value = !addReply.value;
};
</script>

<template>
  <cSidebarModal
    sidebar-position="right"
    :show-sidebar-initial="false"
    :show-sidebar-modal="supportTicketsStore.showSupportTicketsEditModal"
    :toggle-sidebar-modal="supportTicketsStore.cancelEditTicket"
    :defaultButton="false"
  >
    <template #title>
      <h2>Viewing Ticket</h2>
    </template>

    <template #body>
      <FormKit
        id="edit-tickets"
        type="form"
        :incomplete-message="false"
        submit-label="Update"
        form-class="text-white h-full flex flex-col justify-between"
        @submit="supportTicketsStore.updateSupportTicket()"
        outer-class="m-0"
        :actions="false"
        :submit-attrs="{
          'data-theme': `light`,
          ignore: true,
        }"
      >
        <div class="grid grid-cols-1 gap-5">
          <div class="flex flex-row items-center gap-3 text-gray-500">
            <label for="" class="font-bold text-xs"> Reported From URL:</label>
            <p class="text-center text-xs ml-auto">
              {{ supportTicketsStore.currentlyEditingTicket.url }}
            </p>
          </div>
          <div class="flex flex-col">
            <label for="" class="font-bold"> Subject </label>
            <p>{{ supportTicketsStore.currentlyEditingTicket.subject }}</p>
          </div>

          <div class="">
            <span class="font-bold">Status</span>
            <div
              class="bg-emerald-500 p-2 rounded text-white capitalize text-center"
            >
              {{ supportTicketsStore?.currentlyEditingTicket?.status }}
            </div>
          </div>
          <div class="">
            <span class="font-bold">Priority</span>
            <div
              class="bg-purple-500 p-2 rounded text-white capitalize text-center"
            >
              {{ supportTicketsStore?.currentlyEditingTicket?.priority }}
            </div>
          </div>

          <div class="">
            <span class="font-bold">Topic</span>
            <div
              class="bg-blue-500 p-2 rounded text-white capitalize text-center"
            >
              {{ supportTicketsStore?.currentlyEditingTicket?.topic }}
            </div>
          </div>

          <div
            v-if="supportTicketsStore.currentlyEditingTicket.cc.length > 0"
            class="col-span-1 flex flex-col"
          >
            <label for="" class="font-bold">CC'd </label>
            <div
              class="flex flex-row gap-2 max-w-full overflow-x-none flex-wrap"
            >
              <div
                v-for="(email, i) in supportTicketsStore.currentlyEditingTicket
                  .cc"
                :key="i"
                class="bg-gray-400 rounded p-1 flex flex-row items-center gap-3 overflow-clip border border-slate-400"
              >
                <span class="text-xs">{{ email }}</span>

                <!-- <button
                @click="
                  supportTicketsStore.currentlyEditingTicket.cc.splice(i, 1)
                "
                class="btn btn-xs rounded-none border-none hover:bg-red-500 text-red-500"
              >
                <font-awesome-icon icon="fa-solid fa-trash" />
              </button> -->
              </div>
            </div>
          </div>

          <div class="flex flex-col">
            <label for="" class="font-bold">
              Description / Issue / Request
            </label>
            <p class="border border-slate-400 p-5 rounded">
              {{ supportTicketsStore.currentlyEditingTicket.body }}
            </p>
          </div>

          <div
            class="col-span-1 flex flex-col gap-3 p-1 border border-slate-400 rounded"
          >
            <div class="flex flex-row items-center">
              <label for="" class="font-bold flex-1">Message Thread </label>

              <button
                v-if="!supportTicketsStore.showEditingAddReply"
                @click="supportTicketsStore.toggleEditingReply"
                type="button"
                class="btn btn-sm border border-slate-400 text-gray-50 rounded hover:bg-gray-50 hover:text-gray-600"
              >
                <!-- font awesome reply icon -->

                <font-awesome-icon icon="fa-solid fa-reply" />
              </button>
            </div>
            <div
              class="flex flex-col gap-2 max-w-full overflow-x-none flex-wrap"
            >
              <div
                v-if="
                  !supportTicketsStore.currentlyEditingTicket.message_thread
                    .length > 0
                "
                class="p-3 bg-blue-400 rounded text-center"
              >
                Replies to this ticket will appear here
              </div>
              <FormKit
                v-if="supportTicketsStore.showEditingAddReply"
                type="textarea"
                label="Reply"
                placeholder=""
                :validation="'trim|length:3, 1500'"
                help-class="text-white"
                help=""
                outer-class="m-0 "
                input-class="$reset rounded w-full input input-sm text-gray-700 bg-gray-50"
                wrapper-class="max-w-full"
                v-model.trim="supportTicketsStore.editingAddReply"
              />
              <div
                v-for="(message, i) in supportTicketsStore
                  .currentlyEditingTicket.message_thread"
                :key="i"
                :class="`${message.is_support ? 'bg-emerald-500' : 'bg-blue-400'} rounded p-1 flex flex-row items-center gap-3 overflow-clip`"
                class="rounded p-1 flex flex-row items-center gap-3"
              >
                <div class="flex flex-col w-full gap-3">
                  <div class="flex flex-row justify-between">
                    <div class="text-xs">
                      {{
                        message.is_support
                          ? "Support"
                          : `User: ${message.message_by}`
                      }}
                    </div>
                    <div class="text-xs">
                      {{ timeConverter(message.message_at) }}
                    </div>
                  </div>
                  <div class="text-xs">{{ message.message }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </FormKit>
    </template>

    <template #footer>
      <!-- BUTTON FOOTER - CANCEL & UPDATE AFFILIATE GROUP -->
      <div class="flex flex-row justify-between text-white w-full">
        <button
          @click="supportTicketsStore.cancelEditTicket()"
          class="btn btn-xs md:btn-sm capitalize rounded bg-red-500 text-white hover:bg-red-700"
        >
          Close
        </button>
        <button
          v-if="supportTicketsStore.editingAddReply.length >= 3"
          @click="submitUpdateForm"
          class="btn btn-xs md:btn-sm rounded bg-emerald-500 text-white hover:bg-emerald-700"
        >
          Update
        </button>
      </div>
    </template>
  </cSidebarModal>
</template>
