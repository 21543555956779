import {
  //axios
  axios,

  //pinia
  defineStore,
  useGlobalToastsStore,

  //env base url
  BASE_API_URL,
} from "../clientImports";

export const useContactFormsStore = defineStore({
  id: "contact-forms",
  state: () => ({
    //data
    currentFormId: "",
    currentFormData: [],
    contactFormPoppedOutView: false,

    //an object
    fetchedRebuttalData: null,

    showRebuttalPopUp: false,
    currentFormIndex: 0,
  }),
  actions: {
    async intialalizeRebuttalView(id) {
      this.showRebuttalPopUp = true;
      await this.getReubuttalData(id);
    },

    popOutContactFormView() {
      this.contactFormPoppedOutView = true;
    },

    popInContactFormView() {
      this.contactFormPoppedOutView = false;
    },

    async getReubuttalData(id) {
      const { data } = await axios.get(
        `${BASE_API_URL}/read-tenants-rebuttal/${id}`
      );

      if (data.status === "success") {
        const toastStore = useGlobalToastsStore();

        this.fetchedRebuttalData = data.rebuttal[0];

        console.log("GET FETCHED REBUTTAL DATA: ", this.fetchedRebuttalData);

        //toast
        // toastStore.addToast({
        //   title: "Form Data Fetched",
        //   buttonExists: false,
        //   color: "emerald",
        //   type: 2, //
        // });
      } else {
        console.log("GET CUSTOM FORM DATA FAILED");
        const toastStore = useGlobalToastsStore();

        //toast
        toastStore.addToast({
          title: "Error Fetching Form Data",
          buttonExists: false,
          color: "red",
          type: 2, //
        });
      }
    },

    async getCustomFormContactData(contact_id, form_id) {
      //get from get-contact-custom-form-data

      console.log("STORE LOGGGGGG", contact_id, form_id);

      const { data } = await axios.get(
        `${BASE_API_URL}/get-contact-custom-form-data/${contact_id}/${form_id}`
      );

      if (data.status === "success") {
        const toastStore = useGlobalToastsStore();

        this.currentFormData = data.data;

        console.log("GET CUSTOM FORM DATA SUCCESSFUL: ", this.currentFormData);

        //toast
        // toastStore.addToast({
        //   title: "Form Data Fetched",
        //   buttonExists: false,
        //   color: "emerald",
        //   type: 2, //
        // });
      } else {
        console.log("GET CUSTOM FORM DATA FAILED");
        const toastStore = useGlobalToastsStore();

        //toast
        toastStore.addToast({
          title: "Error Fetching Form Data",
          buttonExists: false,
          color: "red",
          type: 2, //
        });
      }
    },

    async putCustomFormContactData(formPutArray) {
      //put to put-contact-custom-form-data

      const { data } = await axios.put(
        `${BASE_API_URL}/put-contact-custom-form-data`,
        formPutArray
      );

      if (data.status === "success") {
        const toastStore = useGlobalToastsStore();
        console.log("PUT CUSTOM FORM DATA SUCCESSFUL");

        //toast
        toastStore.addToast({
          title: "Form Data Updated",
          buttonExists: false,
          color: "emerald",
          type: 2, //
        });
      } else {
        const toastStore = useGlobalToastsStore();
        console.log("PUT CUSTOM FORM DATA FAILED");

        //toast
        toastStore.addToast({
          title: "Form Data Update Failed",
          buttonExists: false,
          color: "red",
          type: 2, //
        });
      }
    },
  },
});
