import {
  //axios
  axios,

  //vue
  watch,

  //pinia
  defineStore,

  //env base url
  BASE_API_URL,
} from "../clientImports";

export const useGlobalPromptsStore = defineStore({
  id: "prompts",
  state: () => ({
    prompt: null, // Single prompt object
  }),
  actions: {
    removePrompt() {
      this.prompt = null;
    },

    addPrompt(prompt) {
      this.prompt = prompt; // Set the entire prompt object
    },

    async waitForPromptConfirmation() {
      return new Promise((resolve) => {
        watch(
          () => this.prompt && this.prompt.response,
          (value) => {
            if (value !== null && value !== undefined) {
              resolve(value);
            }
          }
        );
      });
    },
  },
});
