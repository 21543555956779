<script setup>
defineOptions({
  name: 'GlobalPrompts', // Give the component a name
});
import {
  //axios
  axios,

  //vue
  createApp,
  ref,
  reactive,
  watch,
  computed,
  inject,
  provide,
  onMounted,
  onUnmounted,
  nextTick,
  Teleport,

  //pinia
  defineStore,
  createPinia,
  storeToRefs,

  //pinia stores
  useChatStore,
  useContactAlertsStore,
  useContactFileStore,
  useContactsStore,
  useGlobalPromptsStore,
  useGlobalToastsStore,
  useLoginStore,
  useTenantsStore,

  //vue-router
  useRouter,
  useRoute,

  //formkit

  //formkit config file

  //fontawesome
  library,
  FontAwesomeIcon,
  fas,
  far,
  fab,

  //global components - all vue components
  registerGlobalComponents,

  //base api url
  BASE_API_URL,
} from "./../clientImports";

const toastsStore = useGlobalToastsStore();
const promptsStore = useGlobalPromptsStore();
// const contactsStore = useContactsStore();
</script>

<template>
  <Teleport to="#prompts-target">
    <transition
      enter-active-class="transition-opacity duration-500 ease-in-out"
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="transition-opacity duration-500 ease-in-out"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div
        v-if="
          promptsStore.prompt && Object.keys(promptsStore.prompt).length > 0
        "
        :key="promptKey"
        class="fixed top-0 left-0 z-80 flex flex-col justify-center items-center h-screen w-screen backdrop-brightness-50 p-5"
      >
        <div
          class="bg-gray-100 w-auto p-10 rounded flex flex-col justify-center items-center text-center text-slate-600 gap-5 border-2 border-slate-100"
        >
          <font-awesome-icon
            icon="fa-solid fa-circle-exclamation"
            class="text-red-400"
            size="2xl"
          />
          <h1 class="text-lg font-bold">{{ promptsStore.prompt.title }}</h1>
          <h1>{{ promptsStore.prompt.message }}</h1>

          <div class="flex flex-row justify-between items-center gap-3 w-full">
            <button
              @click="promptsStore.prompt.response = false"
              class="btn bg-blue-500 text-white capitalize border-none flex-1"
            >
              Cancel
            </button>
            <button
              @click="promptsStore.prompt.response = true"
              class="btn bg-red-500 text-white capitalize border-none flex-1"
            >
              Yes
            </button>
          </div>
        </div>
      </div>
    </transition>
  </Teleport>
</template>
