import {
  //axios
  axios,

  //pinia
  defineStore,
  useGlobalToastsStore,
  useGlobalPromptsStore,

  //env base url
  BASE_API_URL,
} from "../clientImports";

export const useTenantsContactTypesStore = defineStore({
  id: "tenants-contact-types",
  state: () => ({
    showTenantsContactTypesCreateModal: false,
    showTenantsContactTypesEditModal: false,

    idToEdit: "",

    tenantsContactTypesList: [],

    currentlyCreatingContactType: {
      name: "",
      description: "",
    },

    currentlyEditingContactType: {
      name: "",
      description: "",
    },
  }),
  actions: {
    //two responsibilities - set the id to edit and then open the modal
    async initializeTenantsContactTypesEditModal(contactTypeId) {
      //set's the id of the ContactType that we're editing
      this.setIdToEdit(contactTypeId);

      await this.fetchTenantContactType();
      //opens the edit modal
      this.showTenantsContactTypesEditModal = true;
    },

    toggleTenantsContactTypesCreateModal() {
      this.showTenantsContactTypesCreateModal =
        !this.showTenantsContactTypesCreateModal;
    },

    toggleTenantsContactTypesEditModal() {
      this.showTenantsContactTypesEditModal =
        !this.showTenantsContactTypesEditModal;
    },

    //fetches the ContactTypes for listing in settings
    async fetchTenantsContactTypes() {
      try {
        const { data } = await axios.get(
          `${BASE_API_URL}/read-tenants-contact-types`
        );

        if (data.status === "success") {
          this.tenantsContactTypesList = data.contactTypesOptions.map(
            (contactType) => {
              return {
                id: contactType._id,
                name: contactType.name,
                description: contactType.description,
                system_default: contactType.system_default,
              };
            }
          );
        } else {
          console.error(data.message);
        }
      } catch (error) {
        console.error();
      }
    },

    //fetching for editing
    async fetchTenantContactType() {
      const id = this.idToEdit;
      try {
        const { data } = await axios.get(
          `${BASE_API_URL}/read-tenants-contact-type/${id}`
        );

        console.log(data, "FETCHED MILESTONE");

        if (data.status === "success") {
          console.log(data.contactType);
          this.currentlyEditingContactType = data.contactType[0];

          console.log(this.currentlyEditingContactType);
        } else {
          console.error(data.message);
        }
      } catch (error) {
        console.error();
      }
    },

    setIdToEdit(contactTypeId) {
      this.idToEdit = contactTypeId;
    },

    async createTenantsContactType() {
      const contactTypeData = this.currentlyCreatingContactType;

      console.log("PASSPORT DATA SENDING OUT: ", contactTypeData);
      try {
        const { data } = await axios.post(
          `${BASE_API_URL}/create-tenants-contact-type`,
          contactTypeData
        );

        console.log("CREATE PASSPORT RESPONSE", data);

        if (data.status === "success") {
          const toastsStore = useGlobalToastsStore();
          toastsStore.addToast({
            title: "ContactType Created",
            description: "",
            buttonExists: false,
            color: "emerald",
            type: 1,
          });

          //fetch the updated list of ContactTypes
          this.fetchTenantsContactTypes();
          this.toggleTenantsContactTypesCreateModal();
        } else {
          console.error(data.message);
          toastsStore.addToast({
            title: "Error Creating ContactType",
            description: data.message,
            buttonExists: false,
            color: "red",
            type: 1,
          });
        }
      } catch (error) {
        console.error();
      }
    },

    async updateTenantsContactType() {
      const id = this.idToEdit;
      try {
        const { data } = await axios.put(
          `${BASE_API_URL}/update-tenants-contact-type/${id}`,
          this.currentlyEditingContactType
        );

        if (data.status === "success") {
          console.log(data.message);

          this.toggleTenantsContactTypesEditModal();
          //fetch the updated list of ContactTypes
          this.fetchTenantsContactTypes();

          //throw toast
          const toastsStore = useGlobalToastsStore();
          toastsStore.addToast({
            title: "ContactType Updated",
            description: "",
            buttonExists: false,
            color: "emerald",
            type: 1,
          });
        } else {
          console.error(data.message);
          toastsStore.addToast({
            title: "Error Updating Contact Type",
            description: data.message,
            buttonExists: false,
            color: "red",
            type: 1,
          });
        }
      } catch (error) {
        console.error();
      }
    },

    async deleteTenantsContactType(contactTypeId) {
      const promptStore = useGlobalPromptsStore();

      const id = contactTypeId;

      promptStore.addPrompt({
        type: "Contact Type",
        title: "Delete Contact Type?",
        message: `Are you sure you want to delete this Contact Type?`,
      });

      const promptResponse = await promptStore.waitForPromptConfirmation();

      if (promptResponse === true) {
        promptStore.addPrompt({
          type: "Contact Type",
          title: "Are You Sure?",
          message: `We'll hide this from your view so it can no longer be used. Any contacts with this Contact Type assigned will remain assigned and have to be manually changed. Do you still want to proceed with the deletion?`,
        });

        const promptResponse2 = await promptStore.waitForPromptConfirmation();

        if (promptResponse2 === true) {
          try {
            const { data } = await axios.delete(
              `${BASE_API_URL}/delete-tenants-contact-type/${id}`
            );

            if (data.status === "success") {
              //fetch the updated list of ContactTypes
              this.fetchTenantsContactTypes();
            } else {
              console.error(data.message);
              toastsStore.addToast({
                title: "Error Deleting Contact Type",
                description: data.message,
                buttonExists: false,
                color: "red",
                type: 1,
              });
            }
          } catch (error) {
            console.error();
          }
          promptStore.removePrompt();
        } else {
          promptStore.removePrompt();
        }
      } else {
        promptStore.removePrompt();
      }
    },
  },
});
