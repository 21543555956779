import {
  //axios
  axios,

  //pinia
  defineStore,
  useGlobalToastsStore,
  useGlobalPromptsStore,

  //env base url
  BASE_API_URL,
} from "../clientImports";

export const useTenantsTemplatesEmailsStore = defineStore({
  id: "tenants-template-emails",
  state: () => ({
    showTenantsTemplatesEmailsCreateModal: false,
    showTenantsTemplatesEmailsEditModal: false,

    idToEdit: "",

    tenantsTemplatesEmailsList: [],

    currentlyCreatingTemplatesEmail: {
      name: "",
      description: "",
      from: "",
      to: "{primary_email}",
      cc: [],
      bcc: [],
      subject: "",
      body: "",
      active: true,
    },

    currentlyEditingTemplatesEmail: {
      name: "",
      description: "",
      from: "",
      to: "{primary_email}",
      cc: [],
      bcc: [],
      subject: "",
      body: "",
      active: true,
    },
  }),
  actions: {
    //two responsibilities - set the id to edit and then open the modal
    async initializeTenantsTemplatesEmailsEditModal(templatesEmailId) {
      //set's the id of the TemplatesEmail that we're editing
      this.setIdToEdit(templatesEmailId);

      // await this.fetchTenantTemplatesEmail();
      //opens the edit modal
      this.showTenantsTemplatesEmailsEditModal = true;
    },

    toggleTenantsTemplatesEmailsCreateModal() {
      this.showTenantsTemplatesEmailsCreateModal =
        !this.showTenantsTemplatesEmailsCreateModal;
    },

    toggleTenantsTemplatesEmailsEditModal() {
      this.showTenantsTemplatesEmailsEditModal =
        !this.showTenantsTemplatesEmailsEditModal;
    },

    //fetches the TemplatesEmails for listing
    async fetchTenantsTemplatesEmails() {
      try {
        const { data } = await axios.get(
          `${BASE_API_URL}/read-tenants-templates-emails`
        );

        if (data.status === "success") {
          this.tenantsTemplatesEmailsList = data.templatesEmailsOptions.map(
            (templatesEmail) => {
              return {
                id: templatesEmail._id,
                name: templatesEmail.name,
                description: templatesEmail.description,
                from: templatesEmail.from,
                to: templatesEmail.to,
                subject: templatesEmail.subject,
                // body: templatesEmail.body, // not needed to show in list - will fetch for editing
                active: templatesEmail.active,
              };
            }
          );
        } else {
          console.error(data.message);
        }
      } catch (error) {
        console.error();
      }
    },

    //fetching for editing
    async fetchTenantTemplatesEmail() {
      const id = this.idToEdit;
      try {
        const { data } = await axios.get(
          `${BASE_API_URL}/read-tenants-templates-email/${id}`
        );

        console.log(data, "FETCHED MILESTONE");

        if (data.status === "success") {
          console.log(data.templatesEmail);
          this.currentlyEditingTemplatesEmail = data.templatesEmail[0];

          console.log(this.currentlyEditingTemplatesEmail);
        } else {
          console.error(data.message);
        }
      } catch (error) {
        console.error();
      }
    },

    setIdToEdit(templatesEmailId) {
      this.idToEdit = templatesEmailId;
    },

    async createTenantsTemplatesEmail() {
      const templatesEmailData = this.currentlyCreatingTemplatesEmail;

      console.log("PASSPORT DATA SENDING OUT: ", templatesEmailData);
      try {
        const { data } = await axios.post(
          `${BASE_API_URL}/create-tenants-templates-email`,
          templatesEmailData
        );

        console.log("CREATE PASSPORT RESPONSE", data);

        if (data.status === "success") {
          const toastsStore = useGlobalToastsStore();
          toastsStore.addToast({
            title: "Email Template Created",
            description: "",
            buttonExists: false,
            color: "emerald",
            type: 1,
          });

          //fetch the updated list of TemplatesEmails
          this.fetchTenantsTemplatesEmails();
          this.toggleTenantsTemplatesEmailsCreateModal();
        } else {
          console.error(data.message);
          toastsStore.addToast({
            title: "Error Creating Email Template",
            description: data.message,
            buttonExists: false,
            color: "red",
            type: 1,
          });
        }
      } catch (error) {
        console.error();
      }
    },

    async updateTenantsTemplatesEmail() {
      const id = this.idToEdit;
      try {
        const { data } = await axios.put(
          `${BASE_API_URL}/update-tenants-templates-email/${id}`,
          this.currentlyEditingTemplatesEmail
        );

        if (data.status === "success") {
          console.log(data.message);

          this.toggleTenantsTemplatesEmailsEditModal();
          //fetch the updated list of TemplatesEmails
          this.fetchTenantsTemplatesEmails();

          //throw toast
          const toastsStore = useGlobalToastsStore();
          toastsStore.addToast({
            title: "Email Updated",
            description: "",
            buttonExists: false,
            color: "emerald",
            type: 1,
          });
        } else {
          console.error(data.message);
          toastsStore.addToast({
            title: "Error Updating Email Template",
            description: data.message,
            buttonExists: false,
            color: "red",
            type: 1,
          });
        }
      } catch (error) {
        console.error();
      }
    },

    async deleteTenantsTemplatesEmail(templatesEmailId) {
      const promptStore = useGlobalPromptsStore();

      const id = templatesEmailId;

      promptStore.addPrompt({
        type: "TemplatesEmail",
        title: "Delete TemplatesEmail?",
        message: `Are you sure you want to delete this TemplatesEmail?`,
      });

      const promptResponse = await promptStore.waitForPromptConfirmation();

      if (promptResponse === true) {
        promptStore.addPrompt({
          type: "TemplatesEmail",
          title: "Are You Sure?",
          message: `We'll hide this from your view so it can no longer be used. Any contacts or users that have this templatesEmail assigned will remain assigned and have to be manually changed. Do you still want to proceed with the deletion?`,
        });

        const promptResponse2 = await promptStore.waitForPromptConfirmation();

        if (promptResponse2 === true) {
          try {
            const { data } = await axios.delete(
              `${BASE_API_URL}/delete-tenants-templates-email/${id}`
            );

            if (data.status === "success") {
              //fetch the updated list of TemplatesEmails
              this.fetchTenantsTemplatesEmails();
            } else {
              console.error(data.message);
              toastsStore.addToast({
                title: "Error Deleting Email Template",
                description: data.message,
                buttonExists: false,
                color: "red",
                type: 1,
              });
            }
          } catch (error) {
            console.error();
          }
          promptStore.removePrompt();
        } else {
          promptStore.removePrompt();
        }
      } else {
        promptStore.removePrompt();
      }
    },
  },
});
