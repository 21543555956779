<script setup>
defineOptions({
  name: 'SettingsTemplatesNotificationsEdit', // Give the component a name
});
import {
  //axios
  axios,

  //vue
  createApp,
  ref,
  reactive,
  watch,
  computed,
  inject,
  provide,
  onMounted,
  onUnmounted,
  nextTick,
  Teleport,

  //pinia
  defineStore,
  createPinia,
  storeToRefs,

  //pinia stores
  useChatStore,
  useContactAlertsStore,
  useContactFileStore,
  useContactsStore,
  useGlobalPromptsStore,
  useGlobalToastsStore,
  useLoginStore,
  useTenantsStore,
  useUsersStore,
  useTenantsTemplatesNotificationsStore,
  useTenantsDepartmentsStore,

  //vue-router
  useRouter,
  useRoute,

  //formkit

  //formkit config file
  FKsubmitForm,

  //fontawesome
  library,
  FontAwesomeIcon,
  fas,
  far,
  fab,

  //centralized data
  usersLevelOptions,
  fieldsDefaultValidation,
  stateOptions,

  //utilities
  getRandomNumberBetween,

  //helpers
  registerGlobalComponents,

  //utilities
  stripEmptyFields,

  //base api url
  BASE_API_URL,
} from "./../clientImports";

import {
  browserTimezone,
  timezoneOptions,
} from "./../centralized_data/timezoneOptions";

const router = useRouter();
const route = useRoute();

const loginStore = useLoginStore();

const usersStore = useUsersStore();
const toastsStore = useGlobalToastsStore();
const promptsStore = useGlobalPromptsStore();
const departmentsStore = useTenantsDepartmentsStore();
const tenantsTemplatesNotificationsStore =
  useTenantsTemplatesNotificationsStore();

onMounted(async () => {
  await departmentsStore.fetchTenantsDepartments();
});

const departmentsOptionsListComputed = computed(() => {
  return departmentsStore.tenantsDepartmentsList.map((department) => {
    return {
      value: department.id,
      label: department.name,
    };
  });
});

//use FKsubmitForm to submit form
const submitUpdateForm = () => {
  FKsubmitForm("edit-templates-notificationss");
};
</script>

<template>
  <cSidebarModal
    sidebar-position="right"
    :show-sidebar-initial="false"
    :show-sidebar-modal="
      tenantsTemplatesNotificationsStore.showTenantsTemplatesNotificationsEditModal
    "
    :toggle-sidebar-modal="
      tenantsTemplatesNotificationsStore.toggleTenantsTemplatesNotificationsEditModal
    "
    :defaultButton="false"
  >
    <template #title>
      <h2>Edit TemplatesNotifications</h2>
    </template>

    <template #body>
      <FormKit
        id="edit-templates-notificationss"
        type="form"
        :incomplete-message="false"
        submit-label="Update"
        form-class="text-white h-full flex flex-col justify-between"
        @submit="
          tenantsTemplatesNotificationsStore.updateTenantsTemplateNotification()
        "
        outer-class="m-0"
        :actions="false"
        :submit-attrs="{
          'data-theme': `light`,
          ignore: true,
        }"
      >
        <div class="grid grid-cols-1 gap-5">
          <FormKit
            type="text"
            label="Name"
            placeholder=""
            :validation="[['required'], ['length', 5, 40]]"
            :validation-messages="{
              required: 'Name is required',
              length: 'Must be between 5 and 40 characters',
            }"
            help-class="text-white"
            help="This is the internal name of the template"
            outer-class="m-0 "
            input-class="$reset rounded w-full input input-sm text-gray-700 bg-gray-50"
            wrapper-class="max-w-full"
            v-model.trim="
              tenantsTemplatesNotificationsStore
                .currentlyEditingTemplateNotification.name
            "
          />
          <FormKit
            type="text"
            label="Title"
            placeholder=""
            :validation="[['required'], ['length', 5, 40]]"
            :validation-messages="{
              required: 'Title is required',
              length: 'Must be between 5 and 40 characters',
            }"
            help-class="text-white"
            help="The actual title of the toast notification"
            outer-class="m-0 "
            input-class="$reset rounded w-full input input-sm text-gray-700 bg-gray-50"
            wrapper-class="max-w-full"
            v-model.trim="
              tenantsTemplatesNotificationsStore
                .currentlyEditingTemplateNotification.title
            "
          />

          <FormKit
            type="textarea"
            label="Description"
            placeholder=""
            :validation="[['length', 1, 255]]"
            :validation-messages="{
              length: 'Must be between 5 and 40 characters',
            }"
            help-class="text-white"
            help="A more descriptive message for the toast notification"
            outer-class="m-0 "
            input-class="$reset rounded w-full input input-sm text-gray-700 bg-gray-50"
            wrapper-class="max-w-full"
            v-model.trim="
              tenantsTemplatesNotificationsStore
                .currentlyEditingTemplateNotification.description
            "
          />

          <FormKit
            type="select"
            label="Color"
            placeholder=""
            :validation="[['required']]"
            :validation-messages="{
              required: 'Color is required',
            }"
            help-class="text-white"
            help="Customize the color of your Toast notification"
            outer-class="m-0 "
            :input-class="`$reset rounded w-full ${
              tenantsTemplatesNotificationsStore
                .currentlyEditingTemplateNotification.color !== '' ||
              tenantsTemplatesNotificationsStore
                .currentlyEditingTemplateNotification.color === null
                ? `bg-${tenantsTemplatesNotificationsStore.currentlyEditingTemplateNotification.color}-500`
                : 'bg-gray-100 text-gray-700'
            } select select-sm`"
            wrapper-class="max-w-full"
            v-model="
              tenantsTemplatesNotificationsStore
                .currentlyEditingTemplateNotification.color
            "
            :options="[
              //choose color disabled
              { value: '', label: 'Choose Color', attrs: { disabled: true } },
              { value: 'red', label: 'Red' },
              { value: 'orange', label: 'Orange' },
              { value: 'yellow', label: 'Yellow' },
              { value: 'green', label: 'Green' },
              { value: 'emerald', label: 'Emerald' },
              { value: 'blue', label: 'Blue' },
              { value: 'purple', label: 'Purple' },
              { value: 'teal', label: 'Teal' },
              { value: 'pink', label: 'Pink' },
              { value: 'slate', label: 'Slate' },
            ]"
          />

          <!-- type -->
          <FormKit
            type="select"
            label="Type"
            placeholder=""
            :validation="[['required']]"
            :validation-messages="{
              required: 'Type is required',
            }"
            help-class="text-white"
            help="How long should this Toast notification stay on screen"
            outer-class="m-0 "
            input-class="$reset rounded w-full select select-sm"
            wrapper-class="max-w-full"
            v-model="
              tenantsTemplatesNotificationsStore
                .currentlyEditingTemplateNotification.type
            "
            :options="[
              //choose type disabled
              { value: '', label: 'Choose Type', attrs: { disabled: true } },
              {
                value: '1',
                label: 'Really Fast - Lasts 2 seconds before disappearing',
              },
              {
                value: '2',
                label: 'Fast - Lasts 3.5 seconds before disappearing',
              },
              {
                value: '3',
                label: 'Normal - Lasts 5 seconds before disappearing',
              },
              {
                value: '4',
                label: 'Slow - Lasts 10 seconds before disappearing',
              },
              {
                value: '5',
                label:
                  'Require Dismiss - Stays on screen until dismissed by user',
              },
            ]"
          />

          <button
            type="button"
            v-if="
              tenantsTemplatesNotificationsStore
                .currentlyEditingTemplateNotification.title &&
              tenantsTemplatesNotificationsStore
                .currentlyEditingTemplateNotification.name &&
              tenantsTemplatesNotificationsStore
                .currentlyEditingTemplateNotification.color &&
              tenantsTemplatesNotificationsStore
                .currentlyEditingTemplateNotification.type
            "
            @click="
              tenantsTemplatesNotificationsStore.testToast(
                tenantsTemplatesNotificationsStore.currentlyEditingTemplateNotification
              )
            "
            class="btn capitalize rounded-xl bg-blue-500 text-gray-50 hover:bg-blue-700"
          >
            Test Toast
          </button>

          <!-- department -->
          <FormKit
            type="select"
            label="Notify Assigned Department"
            placeholder=""
            :validation="[['required']]"
            :validation-messages="{
              required: 'Department is required',
            }"
            help-class="text-white"
            help="Which department should receive this notification?"
            outer-class="m-0 "
            input-class="$reset rounded w-full select select-sm"
            wrapper-class="max-w-full"
            v-model="
              tenantsTemplatesNotificationsStore
                .currentlyEditingTemplateNotification.department_id
            "
            :options="[
              {
                value: '',
                label: 'Choose Department',
                attrs: { disabled: true },
              },
              ...departmentsOptionsListComputed,
            ]"
          />
        </div>
      </FormKit>
    </template>

    <template #footer>
      <!-- BUTTON FOOTER - CANCEL & UPDATE AFFILIATE GROUP -->
      <div class="flex flex-row justify-between text-white w-full">
        <button
          @click="
            tenantsTemplatesNotificationsStore.toggleTenantsTemplatesNotificationsEditModal
          "
          class="btn btn-xs md:btn-sm rounded bg-red-500 text-white hover:bg-red-700"
        >
          Cancel
        </button>

        <button
          @click="submitUpdateForm"
          class="btn btn-xs md:btn-sm rounded bg-emerald-500 text-white hover:bg-emerald-700"
        >
          Update
        </button>
      </div>
    </template>
  </cSidebarModal>
</template>
