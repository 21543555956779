<script setup>
defineOptions({
  name: 'CommunicationCenter', // Give the component a name
});
import {
  //axios
  axios,

  //vue
  createApp,
  ref,
  reactive,
  watch,
  computed,
  inject,
  provide,
  onMounted,
  onUnmounted,
  nextTick,
  Teleport,

  //pinia
  defineStore,
  createPinia,
  storeToRefs,

  //pinia stores
  useChatStore,
  useContactAlertsStore,
  useContactFileStore,
  useContactsStore,
  useGlobalPromptsStore,
  useGlobalToastsStore,
  useLoginStore,
  useTenantsStore,

  //vue-router
  useRouter,
  useRoute,

  //formkit
  FKsubmitForm,

  //formkit config file

  //fontawesome
  library,
  FontAwesomeIcon,
  fas,
  far,
  fab,

  //global components - all vue components
  registerGlobalComponents,

  //base api url
  BASE_API_URL,
} from "./../clientImports";

const loginStore = useLoginStore();

const communicationCenterModal = ref(false);

const toggleCommunicationCenter = (view) => {
  setCommunicationView(view);
  communicationCenterModal.value = !communicationCenterModal.value;
};

const communicationView = ref("");
const setCommunicationView = (type) => {
  directionView.value = "";
  communicationView.value = type;
};

const directionView = ref("");
const setDirectionView = (dView) => {
  directionView.value = dView;
  console.log("directionView.value", directionView.value);
};

const scopeView = ref("global");
const setScopeView = (view) => {
  scopeView.value = view;
};

const comsColor = computed(() => {
  if (communicationView.value === "sms") {
    return "emerald";
  } else if (communicationView.value === "email") {
    return "blue";
  }
});

const submitFormKitForm = () => {
  FKsubmitForm("sms-email-form");
};

const messages = ref([
  {
    direction: "in",
    type: "sms",
    from: "714-395-2644",
    to: "714-395-2633",
    body: "This is a test message",
    date: "2021-08-01 12:00:00",
  },
  {
    direction: "out",
    type: "sms",
    from: "714-395-2633",
    to: "714-395-2644",
    body: "This is a test message",
    date: "2021-08-01 12:00:00",
  },
]);
</script>

<template>
  <div class="flex flex-row items-center justify-between">
    <button
      v-if="
        loginStore?.sessionData?.permissions?.includes('view:sms tab') ||
        loginStore?.sessionData?.permissions?.includes('*:dev-permissions')
      "
      @click="toggleCommunicationCenter('sms')"
      for="my-modal-4"
      class="text-white capitalize border-emerald-300 border-none rounded rounded-r-none btn text-xs md:text-base btn-xs md:btn-sm bg-emerald-500"
    >
      <span class="text-white">
        <font-awesome-icon icon="fa-solid fa-message" size="md" />
      </span>

      <span class="hidden ml-2 lg:block">SMS</span>
    </button>
    <button
      v-if="
        loginStore?.sessionData?.permissions?.includes('view:emails tab') ||
        loginStore?.sessionData?.permissions?.includes('*:dev-permissions')
      "
      @click="toggleCommunicationCenter('email')"
      for="my-modal-4"
      class="text-white capitalize bg-blue-500 rounded-none border-none btn text-xs md:text-base btn-xs md:btn-sm"
    >
      <span class="text-white">
        <font-awesome-icon icon="fa-solid fa-envelope" size="md" />
      </span>
      <span class="hidden ml-2 lg:block">Emails</span>
    </button>
    <button
      v-if="
        loginStore?.sessionData?.permissions?.includes('view:mentions tab') ||
        loginStore?.sessionData?.permissions?.includes('*:dev-permissions')
      "
      @click="toggleCommunicationCenter('mentions')"
      for="my-modal-4"
      class="border-t border text-white capitalize bg-orange-500 rounded-none border-none btn text-xs md:text-base btn-xs md:btn-sm"
    >
      <span class="text-white">
        <font-awesome-icon icon="fa-solid fa-at" size="md" />
      </span>

      <span class="hidden ml-2 lg:block">Mentions</span>
    </button>

    <Notifications />
  </div>

  <Teleport to="#modals-target">
    <!-- Put this part before </body> tag -->

    <div
      v-if="communicationCenterModal"
      @click.self="toggleCommunicationCenter"
      class="cursor-pointer fixed top-0 left-0 h-screen w-screen z-50 backdrop-brightness-50 flex flex-col items-center justify-center"
    >
      <div
        class="bg-base-100 border border-slate-400 p-2 md:p-5 rounded h-full w-full md:3/4 md:w-4/5 lg:h-3/4 lg:w-3/4 flex flex-col"
      >
        <div class="flex flex-1 rounded-b rounded-t overflow-hidden">
          <FormKit
            id="sms-email-form"
            type="form"
            form-class="flex flex-col flex-1 bg-gray-50 text-slate-600"
            :actions="false"
            :submit-attrs="{
              inputClass: '',
              wrapperClass: '',
              'data-theme': `dark`,
              help: '',
              ignore: false,
            }"
          >
            <div class="relative flex flex-row justify-between items-center">
              <div class="flex flex-row">
                <button
                  type="button"
                  @click="setCommunicationView('sms')"
                  class="btn btn-sm capitalize bg-emerald-500 text-white rounded-none border-none"
                >
                  <font-awesome-icon
                    icon="fa-solid fa-message"
                    size="md"
                  /><span class="hidden ml-2 sm:block">SMS</span>
                </button>
                <button
                  type="button"
                  @click="setCommunicationView('email')"
                  class="btn btn-sm capitalize bg-blue-500 text-white rounded-t-none rounded-bl-none rounded-br border-none"
                >
                  <font-awesome-icon
                    icon="fa-solid fa-envelope"
                    size="md"
                  /><span class="hidden ml-2 sm:block">Emails</span>
                </button>
              </div>

              <div class="flex flex-row">
                <button
                  type="button"
                  @click="setDirectionView(`in_${communicationView}`)"
                  class="btn btn-sm capitalize bg-gray-100 text-slate-500 rounded-r-none rounded-tl-none rounded-bl border-slate-400 border-t-none border-r-none"
                >
                  <span class="">In</span>
                </button>
                <button
                  type="button"
                  @click="setDirectionView(`out_${communicationView}`)"
                  class="btn btn-sm capitalize bg-gray-100 text-slate-500 rounded-l-none rounded-tr-none rounded-br border-slate-400 p-2 border-t-none border-l-none"
                >
                  <span class="">Out</span>
                </button>
              </div>

              <div class="flex flex-row">
                <button
                  type="button"
                  @click="setScopeView('global')"
                  class="btn btn-sm capitalize bg-slate-500 text-white rounded-r-none rounded-tl-none rounded-bl border-none"
                >
                  <font-awesome-icon icon="fa-solid fa-globe" size="lg" /><span
                    class="hidden ml-2 sm:block"
                    >Global</span
                  >
                </button>
                <button
                  type="button"
                  @click="setScopeView('current-contact')"
                  class="btn btn-sm capitalize bg-slate-500 text-white rounded-none border-none"
                >
                  <font-awesome-icon
                    icon="fa-solid fa-address-book"
                    size="lg"
                  /><span class="hidden ml-2 sm:block">Current Contact</span>
                </button>
              </div>
            </div>
            <div v-if="!directionView" class="flex flex-col gap-5 flex-1 p-5">
              <div class="grid grid-cols-2 md:grid-cols-3 gap-5">
                <FormKit
                  type="select"
                  :label="
                    communicationView === 'email'
                      ? 'Email Template'
                      : 'SMS Template'
                  "
                  outer-class="m-0 flex-none col-span-2 md:col-span-1"
                  wrapper-class="w-full max-w-full"
                  input-class="select select-sm rounded"
                >
                </FormKit>

                <FormKit
                  type="select"
                  :label="
                    communicationView === 'email' ? 'From Email' : 'From Phone'
                  "
                  outer-class="m-0 flex-none"
                  wrapper-class="w-full max-w-full"
                  input-class="select select-sm rounded"
                >
                </FormKit>

                <FormKit
                  type="select"
                  outer-class="m-0 flex-none"
                  :label="
                    communicationView === 'email' ? 'To Email' : 'To Phone'
                  "
                  wrapper-class="w-full max-w-full"
                  input-class="select select-sm rounded"
                >
                </FormKit>
              </div>
              <div class="flex-1 flex flex-col gap-5">
                <FormKit
                  v-if="communicationView === 'email'"
                  type="text"
                  label="Subject"
                  outer-class="col-span-2 m-0 flex"
                  wrapper-class="w-full max-w-full"
                  inner-class=""
                  :input-class="`input input-sm rounded text-white bg-${comsColor}-100 rounded text-slate-500`"
                >
                </FormKit>
                <FormKit
                  type="textarea"
                  :label="communicationView === 'email' ? 'Body' : 'Message'"
                  outer-class="col-span-2 m-0 flex flex-col flex-1"
                  wrapper-class="w-full max-w-full flex-1 flex flex-col"
                  inner-class="flex-1 flex flex-col"
                  :input-class="`rounded text-slate-500 bg-${comsColor}-100 flex-1`"
                >
                </FormKit>
              </div>
              <div class="flex flex-row justify-between gap-2">
                <button
                  key="submit"
                  @click="toggleCommunicationCenter"
                  :class="`btn btn-sm rounded border-none text-white capitalize transition-transform duration-100 ease-out bg-red-500`"
                >
                  Cancel
                </button>
                <button
                  key="submit"
                  @click="submitFormKitForm()"
                  :class="`btn btn-sm rounded border-none text-white capitalize transition-transform duration-100 ease-out bg-${comsColor}-500`"
                >
                  {{
                    communicationView === "email" ? "Send Email" : "Send SMS"
                  }}
                </button>
              </div>
            </div>

            <div
              v-if="directionView === 'in_sms'"
              class="flex flex-col gap-2 flex-1 p-2"
            >
              <div
                v-for="(message, i) in messages"
                :key="i"
                class="flex flex-col p-1 bg-blue-500 rounded text-xs md:text-sm text-white"
              >
                <div class="flex flex-row justify-between items-center">
                  <h1>{{ message.from }}</h1>
                  <h1>{{ message.to }}</h1>
                </div>
                <div class="flex flex-row justify-between">
                  <h1 class="bg-white w-full rounded text-slate-500">
                    {{ message.body }}
                  </h1>
                </div>
                <div class="flex flex-row justify-end">
                  <h1>{{ message.date }}</h1>
                </div>
              </div>
            </div>

            <div
              v-if="directionView === 'out_sms'"
              class="flex flex-col gap-5 flex-1 p-5"
            >
              <div
                v-for="(message, i) in messages"
                :key="i"
                class="flex flex-col p-1"
              >
                <div class="flex flex-row justify-between items-center">
                  <h1>{{ message.from }}</h1>
                  <h1>{{ message.to }}</h1>
                </div>
              </div>
            </div>
          </FormKit>
        </div>
      </div>
    </div>
  </Teleport>
</template>
