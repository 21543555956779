<script setup>
defineOptions({
  name: 'ContactsTab', // Give the component a name
});
import {
  //axios
  axios,

  //vue
  ref,
  reactive,
  watch,
  computed,
  onBeforeMount,
  onMounted,
  onUnmounted,
  Teleport,

  //pinia
  defineStore,
  createPinia,
  storeToRefs,

  //pinia stores
  useContactFileStore,
  useContactsStore,
  useGlobalPromptsStore,
  useGlobalToastsStore,
  useLoginStore,

  //vue-router
  useRouter,
  useRoute,

  //utils
  xLog,

  //base api url
  BASE_API_URL,
} from "./../clientImports";

const loginStore = useLoginStore();

// Call the useContactsStore function to get an instance of the store
const contactFileStore = useContactFileStore();

// Call the useContactsStore function to get an instance of the store
const contactsStore = useContactsStore();

const toastsStore = useGlobalToastsStore();
const promptsStore = useGlobalPromptsStore();

const columnsUlMenu = ref(null); // create a reference to the element

const isLoading = ref(true);
// use the reference to set the element's blur
const setElementBlur = () => {
  setElementBlur();
};

watch(
  () => contactsStore.page,
  async (n, o) => {
    if (contactsStore.pageCount > 0) {
      xLog("The page changed to " + n);
      contactsStore.skipCount = n * contactsStore.pageCount;
      await contactsStore.fetchContacts();
    }
  }
);

watch(
  () => contactsStore.pageCount,
  async (n, o) => {
    if (contactsStore.page >= 0) {
      contactsStore.page = 0;
      contactsStore.skipCount = contactsStore.page * n;
      await contactsStore.fetchContacts();
    }
  }
);

const nextPage = async () => {
  if (contactsStore.page !== contactsStore.contactsResultsPageCount - 1) {
    contactsStore.page++;
  }
};

const lastPage = async () => {
  if (contactsStore.page !== 0) {
    contactsStore.page--;
  }
};

const selectedFilter = ref(0);

const presetFilters = ref([
  { id: 1, name: "Leads" },
  { id: 2, name: "Clients" },
]);

const defaultFilter = computed(() => {
  if (selectedFilter.value === 0) selectedFilter.value = 2;
});

// const selectedColumns = ref([
//   { id: 0, columnName: "id", uniqueName: "_id" },
//   { id: 1, columnName: "first name", uniqueName: "first_name" },
//   { id: 3, columnName: "last name", uniqueName: "last_name" },
//   { id: 4, columnName: "email primary", uniqueName: "email_primary" },
//   { id: 7, columnName: "phone primary", uniqueName: "phone_primary" },
// ]);

// watch(selectedColumns, (n, o) => {
//   xLog("selectedColumns: ", n);
//   if (n.length === 0) {
//     selectedColumns.value = [
//       { id: 0, columnName: "id", uniqueName: "_id" },
//       { id: 1, columnName: "first name", uniqueName: "first_name" },
//       { id: 3, columnName: "last name", uniqueName: "last_name" },
//       { id: 4, columnName: "email primary", uniqueName: "email_primary" },
//       { id: 7, columnName: "phone primary", uniqueName: "phone_primary" },
//     ];
//   }
// });

const applyColumns = async () => {
  await contactsStore.setSelectedColumns();

  toastsStore.addToast({
    title: "Columns Applied",
    description: "Contacts View Refreshed",
    buttonExists: false,
    color: "emerald",
    type: 1, // 1 = small, 2 = md, 3 = lg, 4 = lg dismissable
  });

  toggleContactColumnSelectors();
};

const refreshContactData = async () => {
  await contactsStore.fetchContacts();
};

const resetColumns = async () => {
  contactsStore.selectedColumns = contactsStore.defaultColumns;

  await contactsStore.fetchContacts();
};

//feed column options to this array
let allContactsColumnOptions = ref([]);

onBeforeMount(async () => {
  await contactsStore.fetchContactsColumns();
  isLoading.value = false;
  allContactsColumnOptions.value = contactsStore.contactsColumns;
});

const contactsFiltersModal = ref(false);

const toggleContactsFiltersModal = () => {
  contactsFiltersModal.value = !contactsFiltersModal.value;
};

const deleteContact = async (contact_id) => {
  try {
    promptsStore.addPrompt({
      type: "Contact",
      title: "Delete Contact?",
      message: `Are you sure you want to delete this contact?`,
    });

    const promptResponse = await promptsStore.waitForPromptConfirmation();

    if (promptResponse === true) {
      promptsStore.removePrompt();

      promptsStore.addPrompt({
        type: "Contact",
        title: "Confirm Deletion of Contact",
        message: `It's not typical that a contact would be deleted, if you still wish to proceed please understand our support team cannot recover this for you and it will be gone forever`,
      });

      const promptResponse2 = await promptsStore.waitForPromptConfirmation();

      if (promptResponse2 === true) {
        promptsStore.removePrompt();

        const response = await contactsStore.deleteContact(contact_id);

        if (response.status === "success") {
          toastsStore.addToast({
            title: "Contact Deleted",
            description: "",
            buttonExists: false,
            color: "red",
            type: 1, // 1 = small, 2 = md, 3 = lg, 4 = lg dismissable
          });
          await contactsStore.fetchContacts();
        } else {
          toastsStore.addToast({
            title: "Error Deleting Contact",
            description: "",
            buttonExists: false,
            color: "red",
            type: 1, // 1 = small, 2 = md, 3 = lg, 4 = lg dismissable
          });
        }
      } else {
        promptsStore.removePrompt();
      }
    } else {
      promptsStore.removePrompt();
    }
  } catch (error) {
    console.log(error);
  }
};

const showColumnSelectors = ref(false);
const toggleContactColumnSelectors = () => {
  showColumnSelectors.value = !showColumnSelectors.value;
};
</script>

<template>
  <Teleport to="#modals-target">
    <!-- TODO  - need to complete filter system -->
    <!-- Put this part before </body> tag -->
    <input
      type="checkbox"
      v-model="contactsFiltersModal"
      class="modal-toggle"
    />
    <label
      @click.stop="toggleContactsFiltersModal"
      class="modal cursor-pointer"
    >
      <label
        @click.stop
        class="modal-box relative w-11/12 max-w-7xl h-4/5 border-2 p-0"
        for=""
      >
        <div
          class="sticky top-0 border-b flex flex-row justify-between bg-base-100 p-5"
        >
          <h3 class="text-lg font-bold text-white">Set Filters</h3>
        </div>

        <div class="h-full overflow-y-auto bg-gray-200">
          <!-- ADDER -->
          <div class="w-full">
            <FormKit
              type="form"
              :incomplete-message="false"
              submit-label="Update"
              :submit-attrs="{
                inputClass: 'my-input-class',
                wrapperClass: 'my-wrapper-class',
                'data-theme': `dark`,
                help: 'My button help text',
                ignore: false,
              }"
            >
              <FormKit></FormKit>
            </FormKit>
          </div>
        </div>
        <div
          class="sticky bottom-0 border-t flex flex-row justify-between p-5 bg-base-100"
        >
          <button class="btn bg-red-500 text-white border-none">Cancel</button>
          <button class="btn bg-emerald-500 text-white border-none">
            Save
          </button>
        </div>
      </label>
    </label>
  </Teleport>
  <div class="h-full w-full flex flex-col divide-y divide-slate-400">
    <!-- mnagement bar wrapper - prevent's vertical overflow -->
    <div>
      <!-- list management bar -->
      <div
        class="hide-scroll bg-base-100 flex flex-row p-1 md:p-2 gap-3 overflow-x-auto"
      >
        <div class="flex flex-row gap-1 md:gap-3">
          <CreateContact
            v-if="
              loginStore?.sessionData?.permissions?.includes(
                'create:contacts'
              ) ||
              loginStore?.sessionData?.permissions?.includes(
                '*:dev-permissions'
              )
            "
          />

          <button
            class="btn rounded bg-white text-black hover:text-white btn-xs md:btn-sm capitalize"
          >
            <font-awesome-icon icon="fa-solid fa-arrow-up-from-bracket" />
          </button>
        </div>
        <div class="flex flex-row gap-1 md:gap-3">
          <button
            @click="toggleContactsFiltersModal"
            class="btn rounded bg-emerald-500 hover:bg-emerald-700 text-white btn-xs md:btn-sm"
          >
            <font-awesome-icon icon="fa-solid fa-filter" />
          </button>

          <cSidebarModal
            sidebar-position="left"
            :show-sidebar-modal="showColumnSelectors"
            :toggle-sidebar-modal="toggleContactColumnSelectors"
            toggle-button-color="purple"
          >
            <template #title> Customize Contacts Column </template>
            <template #toggle-button>
              <font-awesome-icon icon="fa-solid fa-table-columns" />
            </template>

            <template #button-group>
              <div class="flex flex-row justify-end">
                <p class="text-xs p-2 bg-purple-500">
                  {{ contactsStore.selectedColumns.length }} Selected
                </p>
              </div>
            </template>
            <template #body>
              <div
                class="form-control border rounded mt-2"
                v-for="(
                  columnOption, columnOptionsIndex
                ) in allContactsColumnOptions"
                :key="columnOptionsIndex"
              >
                <label class="cursor-pointer label p-2 text-white">
                  <input
                    :id="columnOption.id"
                    :for="columnOption.columnName"
                    v-model="contactsStore.selectedColumns"
                    :value="columnOption"
                    type="checkbox"
                    checked="checked"
                    class="checkbox checkbox-primary bg-purple-300 mr-2"
                  />
                  <span class="label-text text-right text-white capitalize">{{
                    columnOption.columnName
                  }}</span>
                </label>
              </div>
            </template>
            <template #footer>
              <div class="flex flex-row-reverse justify-between items-center">
                <button
                  :class="
                    contactsStore.selectedColumns.length === 0 ? `hidden` : ``
                  "
                  @click="applyColumns()"
                  class="capitalize btn btn-sm bg-emerald-500 hover:bg-emerald-700 text-white border-none rounded"
                >
                  Apply
                </button>
                <button
                  :class="
                    contactsStore.selectedColumns ===
                    contactsStore.defaultColumns
                      ? `hidden`
                      : ``
                  "
                  @click="resetColumns()"
                  class="capitalize btn btn-sm bg-red-500 hover:bg-red-700 text-white border-none rounded"
                >
                  Reset to Default
                </button>
              </div>
            </template>
          </cSidebarModal>

          <div class="flex flex-row">
            <select
              v-model="selectedFilter"
              :value="defaultFilter"
              class="select select-xs md:select-sm rounded-none text-white border-none bg-slate-600"
            >
              <option
                v-for="(filter, filterIndex) in presetFilters"
                :key="filterIndex"
                :value="filter.id"
              >
                {{ filter.name }}
              </option>
            </select>

            <button
              class="btn btn-xs md:btn-sm rounded-none bg-red-500 hover:bg-red-700 text-white border-none"
            >
              <font-awesome-icon icon="fa-solid fa-xmark" />
            </button>

            <button
              :class="
                contactsStore.selectedColumns.length === 0 ? `hidden` : ``
              "
              @click="refreshContactData()"
              class="btn btn-xs md:btn-sm bg-sky-500 hover:bg-sky-700 text-white border-none rounded-l-none rounded-r"
            >
              <font-awesome-icon icon="fa-solid fa-rotate-right" />
            </button>
          </div>
        </div>

        <button
          v-if="
            loginStore?.sessionData?.permissions?.includes(
              'edit:contacts-mass-edit'
            ) ||
            loginStore?.sessionData?.permissions?.includes('*:dev-permissions')
          "
          class="btn rounded bg-red-500 hover:bg-red-700 text-white btn-xs md:btn-sm capitalize ml-auto border-none"
        >
          Mass Edit
        </button>
      </div>
    </div>

    <!-- Contact Table -->
    <div class="w-full h-full overflow-x-auto hide-scroll">
      <cLoadingSpinner v-if="isLoading"></cLoadingSpinner>
      <table
        v-else-if="!isLoading"
        class="h-full w-full rounded-none bg-base-100"
      >
        <thead class="sticky top-0 bg-base-100 z-10">
          <tr class="text-sm text-white whitespace-nowrap">
            <!-- table headers - selected columns -->
            <th
              v-for="(column, index) in contactsStore.selectedColumns"
              :key="index"
              class="p-1 md:p-3 text-xs md:text-base text-left capitalize"
            >
              {{ column.columnName }}
            </th>
            <!-- static columns header -->
            <th class="p-1 md:p-3 text-center sticky right-0 bg-slate-900 w-22">
              <span class="hidden md:block md:text-base">Actions</span>
              <tippy
                arrow
                interactive
                :hide-on-click="false"
                @state="onStateChange"
                class="md:hidden"
              >
                <template #default="{ state }">
                  <div class="">
                    <button
                      class="btn btn-xs text-white bg-teal-500 focus:bg-teal-600 rounded-full px-1"
                    >
                      <font-awesome-icon icon="fa-solid fa-circle-info" />
                    </button>
                  </div>
                </template>

                <template #content="{ hide }">
                  <div @click="hide()" class="-mt-2 -ml-4 -mb-4 -mr-4">
                    <span
                      class="btn btn-xs btn-block rounded-none no-animation bg-teal-700 border-none capitalize text-white"
                      >Showing</span
                    >
                    <button
                      class="btn btn-xs btn-block rounded-none no-animation bg-teal-500 border-none capitalize text-white"
                    >
                      {{ contactsStore.currentPageCount }}
                      <span class="mx-1 lowercase">of</span>
                      {{ contactsStore.contactsCount }}
                    </button>
                    <span
                      class="btn btn-xs btn-block rounded-none no-animation bg-teal-700 border-none capitalize text-white"
                      >Page</span
                    >
                    <button
                      class="btn btn-xs btn-block rounded-none no-animation capitalize bg-teal-500 border-none text-white"
                    >
                      {{
                        contactsStore.contactsCount !== 0
                          ? contactsStore.page + 1
                          : contactsStore.page
                      }}
                      <span class="mx-1 lowercase">of</span>
                      {{ contactsStore.contactsResultsPageCount }}
                    </button>
                  </div>
                </template>
              </tippy>
            </th>
          </tr>
        </thead>
        <tbody class="">
          <!-- the contact row -->
          <tr
            v-for="(contact, contactIndex) in contactsStore.contacts"
            :key="contactIndex"
            class="text-white odd:bg-slate-500 h-8 md:h-10"
          >
            <!-- the contact col data point -->
            <td
              v-for="(
                columnOption, columnOptionsIndex
              ) in contactsStore.selectedColumns"
              :key="columnOptionsIndex"
              class="p-1 md:p-3 text-xs md:text-base text-left"
            >
              <!-- TODO - make table fields editable -->
              <!-- <FormKit
                :type="field.fieldType"
                :label="field.label"
                inner-class="mt-3"
                :name="field.label"
                outer-class="flex-1"
                :input-class="field.styling"
                :value="field.value"
                :validation="field.validation"
                :validation-messages="field.validation_messages"
                messages-class="relative"
                message-class="absolute -top-3 right-2 bg-red-500 rounded text-xs text-white p-1"
                :options="field.options"
                v-model.trim="field.value"
                validation-visibility="submit"
              /> -->

              {{ contact[columnOption.uniqueName] }}
            </td>
            <td
              class="p-1 md:p-2 text-right sticky right-0 z-0 w-24"
              :class="contactIndex % 2 === 0 ? 'bg-slate-800' : 'bg-slate-900'"
            >
              <div
                class="flex flex-col md:flex-row justify-center items-stretch md:items-center"
              >
                <button
                  v-if="
                    loginStore?.sessionData?.permissions?.includes(
                      'delete:contacts'
                    ) ||
                    loginStore?.sessionData?.permissions?.includes(
                      '*:dev-permissions'
                    )
                  "
                  class="btn btn-xs md:btn-sm text-xs md:text-base rounded-t md:rounded-l rounded-b-none md:rounded-r-none bg-rose-500 hover:bg-rose-700 text-white border-none"
                  @click.stop="deleteContact(contact._id)"
                >
                  <font-awesome-icon icon="fa-solid fa-xmark" />
                </button>
                <button
                  v-if="
                    loginStore?.sessionData?.permissions?.includes(
                      'view:contact-quick-action'
                    ) ||
                    loginStore?.sessionData?.permissions?.includes(
                      '*:dev-permissions'
                    )
                  "
                  class="btn btn-xs md:btn-sm text-xs md:text-base rounded-none bg-orange-500 hover:bg-orange-700 text-white border-none"
                >
                  <font-awesome-icon icon="fa-solid fa-ellipsis-vertical" />
                </button>
                <router-link
                  v-if="
                    loginStore?.sessionData?.permissions?.includes(
                      'view:contact-file-page'
                    ) ||
                    loginStore?.sessionData?.permissions?.includes(
                      '*:dev-permissions'
                    )
                  "
                  :to="{
                    name: 'contact-file',
                    params: { contact_id: contact._id },
                  }"
                  class="btn btn-xs md:btn-sm text-xs md:text-base rounded-b md:rounded-l-none rounded-t-none md:rounded-r bg-blue-500 hover:bg-blue-700 text-white border-none"
                >
                  <font-awesome-icon icon="fa-solid fa-expand" />
                </router-link>
              </div>
            </td>
          </tr>
          <!-- NO DATA ROW -->
          <tr>
            <th colspan="100%" class="p-5">
              <!-- IF CONTACTS EMPTY SHOW LOAD CONTACTS BTN -->
            </th>
          </tr>
        </tbody>
      </table>
      <div
        v-else-if="!isLoading && contactsStore.contacts.length === 0"
        class="bg-sky-400 p-5 rounded flex flex-row justify-center items-center"
      >
        <span class="text-white mr-3">No Contacts Exist:</span>
        <button
          @click="contactsStore.toggleCreateContactModal()"
          class="text-white btn capitalize bg-sky-600 border-none"
        >
          Create One
        </button>
      </div>
    </div>

    <!-- Pagination Bar -->
    <div
      class="w-full bg-slate-700 flex flex-row justify-between items-center p-1 md:p-2"
    >
      <div class="md:flex flex-row gap-3 hidden md:block">
        <div class="flex flex-row">
          <span
            class="btn btn-sm rounded-r-none rounded-l no-animation bg-teal-700 border-none capitalize text-white"
            >Showing</span
          >
          <button
            class="btn btn-sm no-animation rounded-none bg-teal-500 border-none capitalize text-white"
          >
            {{ contactsStore.currentPageCount }}
            <span class="mx-1 lowercase">of</span>
            {{ contactsStore.contactsCount }}
          </button>
          <span
            class="btn btn-sm no-animation rounded-none bg-teal-700 border-none capitalize text-white"
            >Page</span
          >
          <button
            class="btn btn-sm no-animation rounded-l-none rounded-r capitalize bg-teal-500 border-none text-white"
          >
            {{
              contactsStore.contactsCount !== 0
                ? contactsStore.page + 1
                : contactsStore.page
            }}
            <span class="mx-1 lowercase">of</span>
            {{ contactsStore.contactsResultsPageCount }}
          </button>
        </div>
      </div>
      <div
        class="flex flex-row items-center text-white divide-x divide-slate-400"
      >
        <div class="self-stretch">
          <button
            v-if="contactsStore.contactsResultsPageCount > 1"
            @click="lastPage"
            class="btn btn-xs md:btn-sm rounded text-white h-full rounded-l rounded-r-none"
          >
            «
          </button>
        </div>

        <span
          v-if="contactsStore.contactsResultsPageCount > 1"
          class="btn btn-xs md:btn-sm rounded-none text-white capitalize no-animation bg-slate-600 border-none"
          >Page</span
        >
        <select
          v-if="contactsStore.contactsResultsPageCount > 1"
          v-model.number="contactsStore.page"
          class="select select-xs md:select-sm rounded-none bg-indigo-500 text-white"
        >
          <option
            v-for="i in contactsStore.contactsResultsPageCount"
            :value="i - 1"
            :key="i"
          >
            {{ i }}
          </option>
        </select>

        <span
          class="btn btn-xs md:btn-sm rounded-none text-white capitalize no-animation bg-slate-600 border-none"
          >Rows</span
        >
        <select
          v-model.number="contactsStore.pageCount"
          class="select select-xs md:select-sm rounded-none bg-indigo-500"
        >
          <option class="text-xs md:text-base" value="10">10</option>
          <option class="text-xs md:text-base" value="20">20</option>
          <option class="text-xs md:text-base" value="50">50</option>
          <option class="text-xs md:text-base" value="100">100</option>
        </select>

        <div class="self-stretch">
          <button
            v-if="contactsStore.contactsResultsPageCount > 1"
            @click="nextPage"
            class="btn btn-xs md:btn-sm text-white h-full rounded-r rounded-l-none"
          >
            »
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
