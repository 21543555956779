<script setup>
defineOptions({
  name: 'SettingsPaymentsPresetsList', // Give the component a name
});
import {
  //axios
  axios,

  //vue
  ref,
  reactive,
  watch,
  computed,
  onMounted,
  onBeforeMount,

  //pinia stores
  useLoginStore,
  useGlobalPromptsStore,
  useGlobalToastsStore,
  useUsersStore,
  useTenantsCampaignsStore,
  useTenantsPaymentsPresetsStore,

  //vue-router
  useRouter,
  useRoute,

  //formkit

  //formkit config file

  //fontawesome
  library,
  FontAwesomeIcon,
  fas,
  far,
  fab,

  //global components - all vue components
  registerGlobalComponents,

  //base api url
  BASE_API_URL,
} from "./../clientImports";

const promptsStore = useGlobalPromptsStore();
const toastsStore = useGlobalToastsStore();
const tenantsCampaignsStore = useTenantsCampaignsStore();
const tenantsPaymentsPresetsStore = useTenantsPaymentsPresetsStore();
const loginStore = useLoginStore();

const route = useRoute();
const router = useRouter();

onMounted(async () => {
  await tenantsPaymentsPresetsStore.fetchTenantsPaymentsPresets(); //initial populate affiliate list
});

const paymentPresetsList = ref(null);

//watch tenantsPaymentsPresetsStore.tenantsPaymentsPresetsList
watch(
  () => tenantsPaymentsPresetsStore.tenantsPaymentsPresetsList,
  async (newValue, oldValue) => {
    if (newValue !== null) {
      paymentPresetsList.value =
        tenantsPaymentsPresetsStore.tenantsPaymentsPresetsList.map((item) => {
          return {
            id: item.id,
            name: item.name,
            plan_amount: item.plan_amount,
            number_of_payments: item.number_of_payments,
            schedule_frequency: item.schedule_frequency,
            frequency_amount: item.frequency_amount,
            active: item.active,
          };
        });
    }
  }
);
</script>

<template>
  <div
    class="left-0 top-0 sticky bg-base-100 p-1 border-b border-b-slate-400 text-white"
  >
    <div class="flex flex-row items-center gap-5">
      <div>PaymentsPresets</div>

      <div>
        <router-link
          class="btn btn-xs md:btn-sm bg-blue-500 hover:bg-blue-700 text-white border-none capitalize rounded"
          :to="{ name: 'settings-payments-presets-create' }"
        >
          <span class="mr-2"> Create Plan </span>
          <font-awesome-icon icon="fa-solid fa-plus" />
        </router-link>
      </div>

      <SettingsPaymentsPresetsCreate />
    </div>
  </div>
  <div class="flex-1 max-w-full h-full">
    <cLoadingSpinner
      v-if="tenantsPaymentsPresetsStore.tenantsPaymentsPresetsList === null"
    />
    <table
      v-else-if="
        tenantsPaymentsPresetsStore.tenantsPaymentsPresetsList?.length > 0
      "
      class="w-full"
    >
      <thead class="">
        <tr>
          <th
            v-if="paymentPresetsList && paymentPresetsList[0]"
            v-for="(paymentsPreset, resultIndex) in paymentPresetsList[0]"
            :key="resultIndex"
            class="bg-gray-500 text-white text-left p-1 md:px-5 md:py-2 md:text-base text-xs whitespace-nowrap"
          >
            {{ resultIndex.replaceAll("_", " ") }}
          </th>
          <th class="text-white bg-base-300 sticky right-0">
            <span class="hidden md:block"> Action </span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(paymentsPreset, resultIndex) in paymentPresetsList"
          :key="resultIndex"
          class="odd:bg-slate-700 even:bg-slate-800"
        >
          <td
            v-for="(cellData, cellIndex) in paymentsPreset"
            :key="cellIndex"
            class="md:px-5 md:py-3 text-white text-xs md:text-base"
          >
            {{ cellData }}
          </td>
          <td class="bg-base-300 sticky right-0 p-1">
            <div
              class="flex flex-col md:flex-row justify-end md:items-center items-stretch"
            >
              <label
                v-if="
                  loginStore?.sessionData?.permissions?.includes(
                    'delete:payments-presets'
                  ) ||
                  loginStore?.sessionData?.permissions?.includes(
                    '*:dev-permissions'
                  )
                "
                class="btn btn-xs md:btn-sm rounded-t rounded-b-none md:rounded-l md:rounded-r-none bg-rose-500 hover:bg-rose-700 text-white border-none"
                @click.stop="
                  tenantsPaymentsPresetsStore.deleteTenantsPaymentsPreset(
                    paymentsPreset.id
                  )
                "
              >
                <font-awesome-icon icon="fa-solid fa-xmark" size="lg" />
              </label>

              <!-- BUG - might add this later - no editing roles for now - just have to add new and delete the old after -->
              <!-- <button
                class="btn btn-xs md:btn-sm rounded-b-none md:rounded-l-none md:rounded-r bg-blue-500 hover:bg-blue-700 text-white border-none"
                @click="
                  tenantsPaymentsPresetsStore.initializeTenantsPaymentsPresetsEditModal(
                    paymentsPreset._id
                  )
                "
              >
                <font-awesome-icon icon="fa-solid fa-pen-to-square" />
              </button> -->

              <!-- need a router-link that sets a param with paymentsPreset._id -->
              <router-link
                v-if="
                  loginStore?.sessionData?.permissions?.includes(
                    'edit:payments-presets'
                  ) ||
                  loginStore?.sessionData?.permissions?.includes(
                    '*:dev-permissions'
                  )
                "
                class="btn btn-xs md:btn-sm rounded-b-none md:rounded-l-none md:rounded-r bg-blue-500 hover:bg-blue-700 text-white border-none"
                :to="{
                  name: 'settings-payments-presets-edit',
                  params: { id: paymentsPreset.id },
                }"
              >
                <font-awesome-icon icon="fa-solid fa-pen-to-square" />
              </router-link>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <div v-else class="bg-blue-400 text-white p-10 text-lg">
      <div class="flex flex-row justify-center items-center">
        <span class="ml-3">No PaymentsPresets Exist Yet</span>
      </div>
    </div>
  </div>

  <div>
    <SettingsPaymentsPresetsEdit />
  </div>
</template>
