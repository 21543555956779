import {
  //axios
  axios,

  //pinia
  defineStore,

  //env base url
  BASE_API_URL,
} from "../clientImports";

export const useGlobalToastsStore = defineStore({
  id: "toasts",
  state: () => ({
    test_store: "Hello from the Toasts Store",
    toasts: [],
  }),
  actions: {
    removeToast(index) {
      this.toasts.splice(index, 1);
    },

    addToast(toast) {
      this.toasts.push(toast);

      if (toast.type === 1) {
        setTimeout(() => {
          this.removeToast(this.toasts.length - 1);
        }, 2000);
      } else if (toast.type === 2) {
        setTimeout(() => {
          this.removeToast(this.toasts.length - 1);
        }, 3500);
      } else if (toast.type === 3) {
        setTimeout(() => {
          this.removeToast(this.toasts.length - 1);
        }, 5000);
      } else if (toast.type === 4) {
        setTimeout(() => {
          this.removeToast(this.toasts.length - 1);
        }, 10000);
      } else if (toast.type === 5) {
        //leave for user to dismiss
      }
    },
  },
});
