export const expirationMonths = [
  { value: 0, label: "- Select Exp Month -", attrs: { disabled: true } },
  { value: 1, label: "01 - January" },
  { value: 2, label: "02 - February" },
  { value: 3, label: "03 - March" },
  { value: 4, label: "04 - April" },
  { value: 5, label: "05 - May" },
  { value: 6, label: "06 - June" },
  { value: 7, label: "07 - July" },
  { value: 8, label: "08 - August" },
  { value: 9, label: "09 - September" },
  { value: 10, label: "10 - October" },
  { value: 11, label: "11 - November" },
  { value: 12, label: "12 - December" },
];

export const expirationYears = [
  { value: 0, label: "- Select Exp Year -", attrs: { disabled: true } },
  { value: 2023, label: "2023" },
  { value: 2024, label: "2024" },
  { value: 2025, label: "2025" },
  { value: 2026, label: "2026" },
  { value: 2027, label: "2027" },
  { value: 2028, label: "2028" },
  { value: 2029, label: "2029" },
  { value: 2030, label: "2030" },
  { value: 2031, label: "2031" },
  { value: 2032, label: "2032" },
  { value: 2033, label: "2033" },
  { value: 2034, label: "2034" },
  { value: 2035, label: "2035" },
  { value: 2036, label: "2036" },
  { value: 2037, label: "2037" },
  { value: 2038, label: "2038" },
  { value: 2039, label: "2039" },
  { value: 2040, label: "2040" },
];
