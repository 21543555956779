import {
  //axios
  axios,

  //pinia
  defineStore,
  useGlobalToastsStore,
  useGlobalPromptsStore,

  //env base url
  BASE_API_URL,
} from "../clientImports";

export const useTenantsPaymentsRoutesStore = defineStore({
  id: "tenants-payments-routes",
  state: () => ({
    showTenantsPaymentsRoutesCreateModal: false,
    showTenantsPaymentsRoutesEditModal: false,

    idToEdit: "",

    tenantsPaymentsRoutesList: [],

    currentlyCreatingPaymentsRoute: {
      active: true,
      name: "",
      account_type_id: "",
      schedule_type_id: "",
      gateway_id: "",
      payment_method_type: "",
      description: "",
    },

    currentlyEditingPaymentsRoute: {
      active: true,
      name: "",
      account_type_id: "",
      schedule_type_id: "",
      gateway_id: "",
      payment_method_type: "",
      description: "",
    },
  }),
  actions: {
    //two responsibilities - set the id to edit and then open the modal
    async initializeTenantsPaymentsRoutesEditModal(paymentsRouteId) {
      //set's the id of the PaymentsRoute that we're editing
      this.setIdToEdit(paymentsRouteId);

      await this.fetchTenantPaymentsRoute();
      //opens the edit modal
      this.showTenantsPaymentsRoutesEditModal = true;
    },

    toggleTenantsPaymentsRoutesCreateModal() {
      this.showTenantsPaymentsRoutesCreateModal =
        !this.showTenantsPaymentsRoutesCreateModal;
    },

    toggleTenantsPaymentsRoutesEditModal() {
      this.showTenantsPaymentsRoutesEditModal =
        !this.showTenantsPaymentsRoutesEditModal;
    },

    //fetches the PaymentsRoutes for listing
    async fetchTenantsPaymentsRoutes() {
      try {
        const { data } = await axios.get(
          `${BASE_API_URL}/read-tenants-payments-routes`
        );

        if (data.status === "success") {
          this.tenantsPaymentsRoutesList = data.paymentsRouteOptions.map(
            (paymentsRoute) => {
              return {
                id: paymentsRoute._id,
                active: paymentsRoute.active,
                name: paymentsRoute.name,
                account_type_id: paymentsRoute.account_type_id,
                schedule_type_id: paymentsRoute.schedule_type_id,
                gateway_id: paymentsRoute.gateway_id,
                payment_method_type: paymentsRoute.payment_method_type,
                description: paymentsRoute.description,
              };
            }
          );
        } else {
          console.error(data.message);
        }
      } catch (error) {
        console.error();
      }
    },

    //fetching for editing
    async fetchTenantPaymentsRoute() {
      const id = this.idToEdit;
      try {
        const { data } = await axios.get(
          `${BASE_API_URL}/read-tenants-payments-route/${id}`
        );

        console.log(data, "FETCHED MILESTONE");

        if (data.status === "success") {
          console.log(data.paymentsRoute);
          this.currentlyEditingPaymentsRoute = data.paymentsRoute[0];

          console.log(this.currentlyEditingPaymentsRoute);
        } else {
          console.error(data.message);
        }
      } catch (error) {
        console.error();
      }
    },

    setIdToEdit(paymentsRouteId) {
      this.idToEdit = paymentsRouteId;
    },

    async createTenantsPaymentsRoute() {
      const paymentsRouteData = this.currentlyCreatingPaymentsRoute;

      console.log("PASSPORT DATA SENDING OUT: ", paymentsRouteData);
      try {
        const { data } = await axios.post(
          `${BASE_API_URL}/create-tenants-payments-route`,
          paymentsRouteData
        );

        console.log("CREATE PASSPORT RESPONSE", data);

        if (data.status === "success") {
          const toastsStore = useGlobalToastsStore();
          toastsStore.addToast({
            title: "PaymentsRoute Created",
            description: "",
            buttonExists: false,
            color: "emerald",
            type: 1,
          });

          //fetch the updated list of PaymentsRoutes
          this.fetchTenantsPaymentsRoutes();
          this.toggleTenantsPaymentsRoutesCreateModal();
        } else {
          console.error(data.message);
          toastsStore.addToast({
            title: "Error Creating PaymentsRoute",
            description: data.message,
            buttonExists: false,
            color: "red",
            type: 1,
          });
        }
      } catch (error) {
        console.error();
      }
    },

    async updateTenantsPaymentsRoute() {
      const id = this.idToEdit;
      try {
        const { data } = await axios.put(
          `${BASE_API_URL}/update-tenants-payments-route/${id}`,
          this.currentlyEditingPaymentsRoute
        );

        if (data.status === "success") {
          console.log(data.message);

          this.toggleTenantsPaymentsRoutesEditModal();
          //fetch the updated list of PaymentsRoutes
          this.fetchTenantsPaymentsRoutes();

          //throw toast
          const toastsStore = useGlobalToastsStore();
          toastsStore.addToast({
            title: "PaymentsRoute Updated",
            description: "",
            buttonExists: false,
            color: "emerald",
            type: 1,
          });
        } else {
          console.error(data.message);
          toastsStore.addToast({
            title: "Error Updating PaymentsRoute",
            description: data.message,
            buttonExists: false,
            color: "red",
            type: 1,
          });
        }
      } catch (error) {
        console.error();
      }
    },

    async deleteTenantsPaymentsRoute(paymentsRouteId) {
      const promptStore = useGlobalPromptsStore();

      const group_id = paymentsRouteId;

      promptStore.addPrompt({
        type: "PaymentsRoute",
        title: "Delete PaymentsRoute?",
        message: `Are you sure you want to delete this PaymentsRoute?`,
      });

      const promptResponse = await promptStore.waitForPromptConfirmation();

      if (promptResponse === true) {
        promptStore.addPrompt({
          type: "PaymentsRoute",
          title: "Are You Sure?",
          message: `We'll hide this from your view so it can no longer be used. Any contacts or users that have this paymentsRoute assigned will remain assigned and have to be manually changed. Do you still want to proceed with the deletion?`,
        });

        const promptResponse2 = await promptStore.waitForPromptConfirmation();

        if (promptResponse2 === true) {
          try {
            const { data } = await axios.delete(
              `${BASE_API_URL}/delete-tenants-payments-route/${group_id}`
            );

            if (data.status === "success") {
              //fetch the updated list of PaymentsRoutes
              this.fetchTenantsPaymentsRoutes();
            } else {
              console.error(data.message);
              toastsStore.addToast({
                title: "Error Deleting PaymentsRoute",
                description: data.message,
                buttonExists: false,
                color: "red",
                type: 1,
              });
            }
          } catch (error) {
            console.error();
          }
          promptStore.removePrompt();
        } else {
          promptStore.removePrompt();
        }
      } else {
        promptStore.removePrompt();
      }
    },
  },
});
