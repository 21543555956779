<script setup>
defineOptions({
  name: 'SupportPhone', // Give the component a name
});
import {
  //axios
  axios,

  //vue
  createApp,
  ref,
  reactive,
  watch,
  computed,
  inject,
  provide,
  onMounted,
  onUnmounted,
  nextTick,
  Teleport,

  //pinia
  defineStore,
  createPinia,
  storeToRefs,

  //pinia stores
  useChatStore,
  useContactAlertsStore,
  useContactFileStore,
  useContactsStore,
  useGlobalPromptsStore,
  useGlobalToastsStore,
  useLoginStore,
  useTenantsStore,

  //vue-router
  useRouter,
  useRoute,

  //formkit

  //formkit config file

  //fontawesome
  library,
  FontAwesomeIcon,
  fas,
  far,
  fab,

  //utilities
  copyText,

  //global components - all vue components
  registerGlobalComponents,

  //base api url
  BASE_API_URL,
} from "./../clientImports";

// Call the useContactsStore function to get an instance of the store
const contactsStore = useContactsStore();
</script>

<template>
  <div class="h-full flex flex-col bg-base-100">
    <div
      class="w-full p-2 md:p-2 bg-base-300 text-white text-xs md:text-base border-b border-b-slate-400"
    >
      <font-awesome-icon icon="fa-solid fa-phone" /><span class="ml-3"
        >Phone Support</span
      >
    </div>
    <div class="w-full overflow-y-auto flex flex-col justify-between flex-1">
      <div
        class="flex-1 h-full p-1 md:p-5 overflow-y-auto flex flex-col justify-center items-center text-white gap-5 text-xs md:text-base"
      >
        <font-awesome-icon icon="fa-solid fa-phone" size="2xl" />
        <h1 class="text-center text-sm md:text-xl">FinnyPi Phone Support</h1>

        <div class="form-control max-w-full">
          <label class="input-group">
            <input
              disabled
              type="text"
              class="input input-xs md:input-md input-bordered disabled:text-gray-700 disabled:bg-white w-36"
              value="714-395-2633"
            />
            <span
              @click="copyText('7143952633')"
              class="bg-slate-500 cursor-pointer"
              >Copy</span
            >
          </label>
        </div>

        <a href="tel:7143952633" class="px-3 py-1 bg-emerald-500 rounded"
          >Call Now</a
        >
      </div>
    </div>
  </div>
</template>
