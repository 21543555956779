<script setup>
defineOptions({
  name: 'ContactCampaign', // Give the component a name
});
import {
  //axios
  axios,

  //vue
  createApp,
  ref,
  reactive,
  watch,
  computed,
  inject,
  provide,
  onMounted,
  onUnmounted,
  nextTick,
  Teleport,

  //pinia
  defineStore,
  createPinia,
  storeToRefs,

  //pinia stores
  useChatStore,
  useContactAlertsStore,
  useContactFileStore,
  useContactsStore,
  useGlobalPromptsStore,
  useGlobalToastsStore,
  useLoginStore,
  useTenantsStore,
  useTenantsCampaignsStore,

  //vue-router
  useRouter,
  useRoute,

  //formkit

  //formkit config file

  //fontawesome
  library,
  FontAwesomeIcon,
  fas,
  far,
  fab,

  //global components - all vue components
  registerGlobalComponents,

  //base api url
  BASE_API_URL,
} from "./../clientImports";
const tenantsCampaignsStore = useTenantsCampaignsStore();
const contactFileStore = useContactFileStore();
const toastsStore = useGlobalToastsStore();

const contactCampaigns = ref([
  { id: 1, name: "Default" },
  { id: 2, name: "Referral" },
]);

let campaignOptions = ref([]);

let currentCampaign = ref("");

onMounted(async () => {
  tenantsCampaignsStore.fetchTenantsCampaignsOptions();

  campaignOptions.value = tenantsCampaignsStore?.tenantsCampaignsOptions;

  currentCampaign.value = contactFileStore.currentContactFile?.campaign;

  watch(
    () => contactFileStore.currentContactFile?.campaign,
    (newVal, oldVal) => {
      currentCampaign.value = newVal;
    }
  );
});

watch(currentCampaign, (n, o) => {
  toggleCampaignSelect();
});

const updateCampaign = async (flagType, campaignVal) => {
  await contactFileStore.updateContactFlag(flagType, campaignVal);

  toastsStore.addToast({
    title: "Campaign Updated",
    buttonExists: false,
    color: "emerald",
    type: 1, // 1 = small, 2 = md, 3 = lg, 4 = lg dismissable
  });
};

const showCampaignSelect = ref(false);
const toggleCampaignSelect = () => {
  showCampaignSelect.value = !showCampaignSelect.value;
};
</script>

<template>
  <div class="flex flex-row">
    <button
      @click="toggleCampaignSelect"
      class="btn btn-xs no-animation rounded-none text-white capitalize"
    >
      Campaign
    </button>
    <select
      @change="updateCampaign('campaign', currentCampaign)"
      class="select select-xs text-white rounded-l-none rounded-none"
      :value="contactFileStore.currentContactFile?.campaign"
      v-model="currentCampaign"
      :class="[stageColor, { 'max-sm:hidden': !showCampaignSelect }]"
    >
      <option disabled selected>Choose Campaign</option>
      <option
        v-for="(
          campaign, campaignIndex
        ) in tenantsCampaignsStore.tenantsCampaignsOptions"
        :key="campaignIndex"
        :value="campaign.value"
      >
        {{ campaign.label }}
      </option>
    </select>
  </div>
</template>
