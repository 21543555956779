<script setup>
defineOptions({
  name: 'SettingsTemplatesEmailsList', // Give the component a name
});
import {
  //axios
  axios,

  //vue
  ref,
  reactive,
  watch,
  onMounted,
  onBeforeMount,

  //pinia stores
  useLoginStore,
  useGlobalPromptsStore,
  useGlobalToastsStore,
  useUsersStore,
  useTenantsCampaignsStore,
  useTenantsTemplatesEmailsStore,

  //vue-router
  useRouter,
  useRoute,

  //formkit

  //formkit config file

  //fontawesome
  library,
  FontAwesomeIcon,
  fas,
  far,
  fab,

  //global components - all vue components
  registerGlobalComponents,

  //base api url
  BASE_API_URL,
} from "./../clientImports";

const promptsStore = useGlobalPromptsStore();
const toastsStore = useGlobalToastsStore();
const tenantsCampaignsStore = useTenantsCampaignsStore();
const tenantsTemplatesEmailsStore = useTenantsTemplatesEmailsStore();
const loginStore = useLoginStore();

const route = useRoute();
const router = useRouter();

let campaignsList = reactive([]);

onMounted(async () => {
  await tenantsTemplatesEmailsStore.fetchTenantsTemplatesEmails(); //initial populate affiliate list
});
</script>

<template>
  <div
    class="left-0 top-0 sticky bg-base-100 p-1 border-b border-b-slate-400 text-white"
  >
    <div class="flex flex-row items-center gap-5">
      <div>Email Templates</div>

      <div>
        <router-link
          class="btn btn-xs md:btn-sm bg-blue-500 hover:bg-blue-700 text-white border-none capitalize rounded"
          :to="{ name: 'settings-templates-emails-create' }"
        >
          <span class="mr-2"> Create Email Template </span>
          <font-awesome-icon icon="fa-solid fa-plus" />
        </router-link>
      </div>

      <SettingsTemplatesEmailsCreate />
    </div>
  </div>
  <div class="flex-1 max-w-full h-full">
    <cLoadingSpinner
      v-if="tenantsTemplatesEmailsStore.tenantsTemplatesEmailsList === null"
    />
    <table
      v-else-if="
        tenantsTemplatesEmailsStore.tenantsTemplatesEmailsList?.length > 0
      "
      class="w-full"
    >
      <thead class="">
        <tr>
          <th
            v-for="(templatesEmail, resultIndex) in tenantsTemplatesEmailsStore
              .tenantsTemplatesEmailsList[0]"
            :key="resultIndex"
            class="bg-gray-500 text-white text-left p-1 md:px-5 md:py-2 md:text-base text-xs whitespace-nowrap"
          >
            {{ resultIndex.replaceAll("_", " ") }}
          </th>
          <th class="text-white bg-base-300 sticky right-0">
            <span class="hidden md:block"> Action </span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(
            templatesEmail, resultIndex
          ) in tenantsTemplatesEmailsStore.tenantsTemplatesEmailsList"
          :key="resultIndex"
          class="odd:bg-slate-700 even:bg-slate-800"
        >
          <td
            v-for="(cellData, cellIndex) in templatesEmail"
            :key="cellIndex"
            class="md:px-5 md:py-3 text-white text-xs md:text-base"
          >
            {{ cellData }}
          </td>
          <td class="bg-base-300 sticky right-0 p-1">
            <div
              class="flex flex-col md:flex-row justify-end md:items-center items-stretch"
            >
              <label
                v-if="
                  loginStore?.sessionData?.permissions?.includes(
                    'delete:email-templates'
                  ) ||
                  loginStore?.sessionData?.permissions?.includes(
                    '*:dev-permissions'
                  )
                "
                class="btn btn-xs md:btn-sm rounded-t rounded-b-none md:rounded-l md:rounded-r-none bg-rose-500 hover:bg-rose-700 text-white border-none"
                @click.stop="
                  tenantsTemplatesEmailsStore.deleteTenantsTemplatesEmail(
                    templatesEmail.id
                  )
                "
              >
                <font-awesome-icon icon="fa-solid fa-xmark" size="lg" />
              </label>

              <!-- BUG - might add this later - no editing roles for now - just have to add new and delete the old after -->
              <!-- <button
                class="btn btn-xs md:btn-sm rounded-b-none md:rounded-l-none md:rounded-r bg-blue-500 hover:bg-blue-700 text-white border-none"
                @click="
                  tenantsTemplatesEmailsStore.initializeTenantsTemplatesEmailsEditModal(
                    templatesEmail._id
                  )
                "
              >
                <font-awesome-icon icon="fa-solid fa-pen-to-square" />
              </button> -->

              <!-- need a router-link that sets a param with templatesEmail._id -->
              <router-link
                v-if="
                  loginStore?.sessionData?.permissions?.includes(
                    'edit:email-templates'
                  ) ||
                  loginStore?.sessionData?.permissions?.includes(
                    '*:dev-permissions'
                  )
                "
                class="btn btn-xs md:btn-sm rounded-b-none md:rounded-l-none md:rounded-r bg-blue-500 hover:bg-blue-700 text-white border-none"
                :to="{
                  name: 'settings-templates-emails-edit',
                  params: { id: templatesEmail.id },
                }"
              >
                <font-awesome-icon icon="fa-solid fa-pen-to-square" />
              </router-link>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <div v-else class="bg-blue-400 text-white p-10 text-lg">
      <div class="flex flex-row justify-center items-center">
        <span class="ml-3">No Email Templates Exist Yet</span>
      </div>
    </div>
  </div>

  <div>
    <SettingsTemplatesEmailsEdit />
  </div>
</template>
