<script setup>
defineOptions({
  name: 'UserDropdown', // Give the component a name
});
import {
  //axios
  axios,

  //vue
  createApp,
  ref,
  reactive,
  watch,
  computed,
  inject,
  provide,
  onBeforeMount,
  onMounted,
  onUnmounted,
  nextTick,
  Teleport,

  //pinia
  defineStore,
  createPinia,
  storeToRefs,

  //pinia stores
  useChatStore,
  useContactAlertsStore,
  useContactFileStore,
  useContactsStore,
  useGlobalPromptsStore,
  useGlobalToastsStore,
  useLoginStore,
  useMasterStore,
  useTenantsStore,

  //vue-router
  useRouter,
  useRoute,

  //formkit

  //formkit config file

  //fontawesome
  library,
  FontAwesomeIcon,
  fas,
  far,
  fab,

  //global components - all vue components
  registerGlobalComponents,

  //utils
  xLog,

  //base api url
  BASE_API_URL,
} from "./../clientImports";
const masterStore = useMasterStore();

const contactsStore = useContactsStore();
const contactFileStore = useContactFileStore();
const loginStore = useLoginStore();

const toastsStore = useGlobalToastsStore();

const router = useRouter();
const route = useRoute();

const logout = async () => {
  try {
    const result = await axios.get(`${BASE_API_URL}/logout`);

    xLog("Logout Response: ", result.data.message);

    router.push({ name: "login" });

    toastsStore.addToast({
      title: "Logged Out",
      description: `See you soon ${loginStore?.sessionData?.foundUser.first_name}! 😃`,
      buttonExists: false,
      color: "indigo",
      type: 1, // 1 = small, 2 = md, 3 = lg, 4 = lg dismissable
    });

    //reset the chatwoot instance
    window.$chatwoot.reset();

    contactFileStore.deleteLocalStorageData();
    contactsStore.deleteLocalStorageData();
    loginStore.deleteLocalStorageData();

    //wipe pinia state on logout

    if (route.name === "login") {
      masterStore.resetAllStores();
    }
  } catch (error) {
    console.log(error);
  }
};
const showUserDropdownSidebar = ref(false);

const toggleUserDropdownSidebar = () => {
  showUserDropdownSidebar.value = !showUserDropdownSidebar.value;
};
</script>

<template>
  <div
    class="cursor-pointer flex flex-row items-center sticky right-0 bg-base-300"
  >
    <button
      @click="toggleUserDropdownSidebar"
      class="btn-sm md:btn-md text-white rounded-none bg-gradient-to-br border-l px-2 border-l-slate-50 text-base"
    >
      <span class="text-white whitespace-nowrap text-xs">
        {{ loginStore?.sessionData?.foundUser?.first_name.charAt(0) }}
        {{ loginStore?.sessionData?.foundUser?.last_name.charAt(0) }}
      </span>
    </button>
  </div>
  <cSidebarModal
    sidebar-position="right"
    :show-sidebar-modal="showUserDropdownSidebar"
    :toggle-sidebar-modal="toggleUserDropdownSidebar"
    :default-button="false"
  >
    <template #title>
      <div class="flex flex-row items-center flex-1">
        <button class="cursor-pointer">
          <div
            @click="toggleUserDropdownSidebar"
            class="mr-2 text-white bg-black rounded-full btn btn-xs p-5 flex flex-col"
          >
            <div class="rounded-full">
              <span class="text-white">
                {{
                  loginStore?.sessionData?.foundUser?.first_name.charAt(0) +
                  loginStore?.sessionData?.foundUser?.last_name.charAt(0)
                }}
              </span>
            </div>
          </div>
        </button>
        <span class="flex flex-no-wrap"
          >{{ loginStore?.sessionData?.foundUser?.first_name }}
          {{ loginStore?.sessionData?.foundUser?.last_name }}</span
        >
      </div>
    </template>

    <template #body>
      <span></span>
    </template>
    <template #footer>
      <button
        @click="logout"
        class="text-white capitalize bg-red-500 border-none rounded btn btn-sm hover:bg-red-700 btn-block"
      >
        <span class="mr-3">Logout</span>
        <font-awesome-icon icon="fa-solid fa-right-from-bracket" />
      </button>
    </template>
  </cSidebarModal>
</template>
