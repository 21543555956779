import {
  //axios
  axios,

  //pinia
  defineStore,
  useGlobalPromptsStore,
  useGlobalToastsStore,

  //env base url
  BASE_API_URL,
} from "../clientImports";

export const useGptStore = defineStore({
  id: "gpt",
  state: () => ({
    showGptWindow: false,
    currentlyCreatingPrompt: "",
    conversation: JSON.parse(localStorage.getItem("gptConversation")) || [],
  }),
  actions: {
    toggleGptWindow() {
      this.showGptWindow = !this.showGptWindow;
    },

    async submitPrompt() {
      this.conversation.push({
        type: "user",
        content: this.currentlyCreatingPrompt,
      });

      //axios post to /create-ai-support-prompt
      const { data } = await axios.post(
        `${BASE_API_URL}/create-ai-support-prompt`,
        {
          user_prompt: this.currentlyCreatingPrompt,
        }
      );

      //if success then add the response to the conversation
      if (data.status === "success") {
        if (JSON.parse(data.aiResponse).noContentFound === true) {
          this.conversation.push({
            type: "assistant",
            noContentFound: true,
          });
        } else {
          this.conversation.push({
            type: "assistant",
            aiLocatedArticle: JSON.parse(data.aiResponse).aiLocatedArticle.body,
          });
        }

        //save to local storage
        localStorage.setItem(
          "gptConversation",
          JSON.stringify(this.conversation)
        );

        return "success";
      }
    },

    async clearConversation() {
      //prompt the user to confirm
      const promptsStore = useGlobalPromptsStore();
      const toastsStore = useGlobalToastsStore();

      promptsStore.addPrompt({});

      promptsStore.addPrompt({
        type: "Clear GPT Conversation",
        title: "Clear GPT Conversation",
        message:
          "This will wipe out the gpt history you currently have saved? Are you sure you would like to proceed?",
      });

      const promptResponse = await promptsStore.waitForPromptConfirmation();

      if (promptResponse === true) {
        this.conversation = [];
        localStorage.removeItem("gptConversation");

        toastsStore.addToast({
          title: "GPT Conversation Cleared",
          description: `The GPT conversation has been wiped.`,
          buttonExists: false,
          color: "green",
          type: 3, // 1 = small, 2 = md, 3 = lg, 4 = lg dismissable
        });
        promptsStore.removePrompt();
      } else {
        //only close the prompt
        promptsStore.removePrompt();
      }
    },
  },
});
