<script setup>
defineOptions({
  name: 'SettingsUsersList', // Give the component a name
});
import {
  //axios
  axios,

  //vue
  ref,
  reactive,
  watch,
  onBeforeMount,

  //pinia stores
  useLoginStore,
  useGlobalPromptsStore,
  useGlobalToastsStore,
  useUsersStore,

  //vue-router
  useRouter,
  useRoute,

  //formkit

  //formkit config file

  //fontawesome
  library,
  FontAwesomeIcon,
  fas,
  far,
  fab,

  //global components - all vue components
  registerGlobalComponents,

  //base api url
  BASE_API_URL,
} from "./../clientImports";

const loginStore = useLoginStore();
const promptsStore = useGlobalPromptsStore();
const toastsStore = useGlobalToastsStore();
const usersStore = useUsersStore();

const route = useRoute();
const router = useRouter();

const usersList = ref([]);

watch(
  () => usersStore.fetchedUsers,
  (newVal) => {
    usersList.value = newVal; // Update usersList whenever fetchedUsers changes
  }
);

onBeforeMount(async () => {
  await usersStore.readUsers();
});

const deleteUser = async (userId) => {
  if (userId === loginStore.sessionData.foundUser._id) {
    toastsStore.addToast({
      title: "Cannot Delete User",
      description: "You cannot delete yourself",
      buttonExists: false,
      color: "red",
      type: 1, // 1 = small, 2 = md, 3 = lg, 4 = lg dismissable
    });
    return;
  }

  try {
    promptsStore.addPrompt({
      type: "User",
      title: "Delete User?",
      message: `Are you sure you want to delete this User?`,
    });

    const promptResponse = await promptsStore.waitForPromptConfirmation();

    if (promptResponse === true) {
      promptsStore.removePrompt();

      promptsStore.addPrompt({
        type: "User",
        title: "Confirm Deletion of User",
        message: `Users aren't deleted often so before we delete this user, please confirm you want it deleted.`,
      });

      const promptResponse2 = await promptsStore.waitForPromptConfirmation();

      if (promptResponse2 === true) {
        promptsStore.removePrompt();

        const response = await usersStore.deleteUser(userId);

        if (response.status === "success") {
          toastsStore.addToast({
            title: "User Deleted",
            description: "",
            buttonExists: false,
            color: "emerald",
            type: 1, // 1 = small, 2 = md, 3 = lg, 4 = lg dismissable
          });
          await usersStore.readUsers();
        } else {
          toastsStore.addToast({
            title: "Error Deleting User",
            description: "",
            buttonExists: false,
            color: "red",
            type: 1, // 1 = small, 2 = md, 3 = lg, 4 = lg dismissable
          });
        }
      } else {
        promptsStore.removePrompt();
      }
    } else {
      promptsStore.removePrompt();
    }
  } catch (error) {
    console.log(error);
  }
};

const editUser = async (userId) => {
  router.push({
    name: "settings-edit-user",
    params: {
      id: userId,
    },
  });
};

const applyUsersSortCol = (rules) => {
  usersStore.applySortByColumn(rules);
};
</script>

<template>
  <div class="flex-1 max-w-full h-full">
    <cTable
      tableName="users"
      :tableData="usersList"
      :onDelete="
        loginStore?.sessionData?.permissions?.includes('delete:users') ||
        loginStore?.sessionData?.permissions?.includes('*:dev-permissions')
          ? deleteUser
          : null
      "
      :onEdit="
        loginStore?.sessionData?.permissions?.includes('edit:users') ||
        loginStore?.sessionData?.permissions?.includes('*:dev-permissions')
          ? editUser
          : null
      "
      :onSort="applyUsersSortCol"
    >
    </cTable>
  </div>
  <div>
    <SettingsUsersEdit v-if="usersStore.idToEdit" />
  </div>
</template>
