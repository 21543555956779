import {
  //axios
  axios,

  //pinia
  defineStore,
  useGlobalToastsStore,
  useGlobalPromptsStore,

  //env base url
  BASE_API_URL,
} from "../clientImports";

export const useSupportTicketsStore = defineStore({
  id: "support-tickets",
  state: () => ({
    showSupportTicketsCreateModal: false,
    showSupportTicketsEditModal: false,

    idToEdit: "",

    showEditingAddReply: false,
    editingAddReply: "",

    supportTicketsList: null,

    currentlyCreatingTicket: {
      url: "",
      subject: "",
      body: "",
      status: "",
      priority: "Low - Business Still Operational",
      topic: "Account Billing",
      cc: [],
      message_thread: [],
    },

    currentlyEditingTicket: {
      url: "",
      subject: "",
      body: "",
      status: "",
      priority: "",
      topic: "",
      cc: [],
      message_thread: [],
    },
  }),
  actions: {
    //two responsibilities - set the id to edit and then open the modal
    async initializeSupportTicketsEditModal(ticketId) {
      //set's the id of the Ticket that we're editing
      this.setIdToEdit(ticketId);

      //opens the edit modal
      this.showSupportTicketsEditModal = true;
      await this.fetchTenantTicket();
    },

    cancelEditTicket() {
      this.showSupportTicketsEditModal = false;
      this.showEditingAddReply = false;
      this.editingAddReply = "";
    },

    toggleSupportTicketsCreateModal() {
      this.showSupportTicketsCreateModal = !this.showSupportTicketsCreateModal;
    },

    toggleSupportTicketsEditModal() {
      this.showSupportTicketsEditModal = !this.showSupportTicketsEditModal;
    },

    toggleEditingReply() {
      this.showEditingAddReply = !this.showEditingAddReply;
    },

    //fetches the Tickets for listing
    async fetchSupportTickets() {
      try {
        const { data } = await axios.get(
          `${BASE_API_URL}/read-support-tickets`
        );

        if (data.status === "success") {
          this.supportTicketsList = data.ticketsOptions.map((ticket) => {
            return {
              id: ticket._id,
              url: ticket.url,
              subject: ticket.subject,
              body: ticket.body,
              status: ticket.status,
              priority: ticket.priority,
              topic: ticket.topic,
              cc: ticket.cc,
              message_thread: ticket.message_thread,
            };
          });

          console.log(this.supportTicketsList);
        } else {
          console.log(data.message);
        }
      } catch (error) {
        console.error();
      }
    },

    //fetching for editing
    async fetchTenantTicket() {
      const id = this.idToEdit;
      try {
        const { data } = await axios.get(
          `${BASE_API_URL}/read-support-ticket/${id}`
        );

        console.log(data, "FETCHED MILESTONE");

        if (data.status === "success") {
          console.log(data.ticket);

          //order the message_thread by date - newest first
          data.ticket[0].message_thread.sort((a, b) => {
            return new Date(b.message_at) - new Date(a.message_at);
          });

          this.currentlyEditingTicket = data.ticket[0];

          console.log(this.currentlyEditingTicket);
        } else {
          console.error(data.message);
        }
      } catch (error) {
        console.error();
      }
    },

    setIdToEdit(ticketId) {
      this.idToEdit = ticketId;
    },

    async createSupportTicket() {
      const ticketData = this.currentlyCreatingTicket;

      console.log("PASSPORT DATA SENDING OUT: ", ticketData);
      try {
        const { data } = await axios.post(
          `${BASE_API_URL}/create-support-ticket`,
          ticketData
        );

        console.log("CREATE PASSPORT RESPONSE", data);

        if (data.status === "success") {
          const toastsStore = useGlobalToastsStore();
          toastsStore.addToast({
            title: "Ticket Created",
            description: "",
            buttonExists: false,
            color: "emerald",
            type: 1,
          });

          //fetch the updated list of Tickets
          this.fetchSupportTickets();
          //reset the form
          this.currentlyCreatingTicket = {
            url: "",
            subject: "",
            body: "",
            status: "",
            priority: "Low - Business Still Operational",
            topic: "Account Billing",
            cc: [],
            message_thread: [],
          };

          this.toggleSupportTicketsCreateModal();
        } else {
          console.error(data.message);
          toastsStore.addToast({
            title: "Error Creating Ticket",
            description: data.message,
            buttonExists: false,
            color: "red",
            type: 1,
          });
        }
      } catch (error) {
        console.error();
      }
    },

    async updateSupportTicket() {
      const id = this.idToEdit;

      const new_message_thread_reply = {
        message: this.editingAddReply,
        message_by: null,
        message_at: new Date(),
      };
      try {
        const { data } = await axios.put(
          `${BASE_API_URL}/update-support-ticket/${id}`,
          new_message_thread_reply
        );

        if (data.status === "success") {
          console.log(data.message);

          this.toggleSupportTicketsEditModal();

          //reset the reply feild
          this.editingAddReply = "";

          this.showEditingAddReply = false;

          //fetch the updated list of Tickets
          this.fetchSupportTickets();

          //throw toast
          const toastsStore = useGlobalToastsStore();
          toastsStore.addToast({
            title: "Ticket Updated",
            description: "",
            buttonExists: false,
            color: "emerald",
            type: 1,
          });
        } else {
          console.error(data.message);
          toastsStore.addToast({
            title: "Error Updating Ticket",
            description: data.message,
            buttonExists: false,
            color: "red",
            type: 1,
          });
        }
      } catch (error) {
        console.error();
      }
    },

    async deleteSupportTicket(ticketId) {
      const promptStore = useGlobalPromptsStore();

      const group_id = ticketId;

      promptStore.addPrompt({
        type: "Ticket",
        title: "Delete Ticket?",
        message: `Are you sure you want to delete this Ticket?`,
      });

      const promptResponse = await promptStore.waitForPromptConfirmation();

      if (promptResponse === true) {
        promptStore.addPrompt({
          type: "Ticket",
          title: "Are You Sure?",
          message: `We'll hide this from your view so it can no longer be used. Any contacts or users that have this ticket assigned will remain assigned and have to be manually changed. Do you still want to proceed with the deletion?`,
        });

        const promptResponse2 = await promptStore.waitForPromptConfirmation();

        if (promptResponse2 === true) {
          try {
            const { data } = await axios.delete(
              `${BASE_API_URL}/delete-support-ticket/${group_id}`
            );

            if (data.status === "success") {
              //fetch the updated list of Tickets
              this.fetchSupportTickets();
            } else {
              console.error(data.message);
              toastsStore.addToast({
                title: "Error Deleting Ticket",
                description: data.message,
                buttonExists: false,
                color: "red",
                type: 1,
              });
            }
          } catch (error) {
            console.error();
          }
          promptStore.removePrompt();
        } else {
          promptStore.removePrompt();
        }
      } else {
        promptStore.removePrompt();
      }
    },
  },
});
