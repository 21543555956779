<script setup>
defineOptions({
  name: 'SettingsUsersEdit', // Give the component a name
});
import {
  //axios
  axios,

  //vue
  createApp,
  ref,
  reactive,
  watch,
  computed,
  inject,
  provide,
  onMounted,
  onUnmounted,
  nextTick,
  Teleport,

  //pinia
  defineStore,
  createPinia,
  storeToRefs,

  //pinia stores
  useChatStore,
  useContactAlertsStore,
  useContactFileStore,
  useContactsStore,
  useGlobalPromptsStore,
  useGlobalToastsStore,
  useLoginStore,
  useTenantsStore,
  useUsersStore,

  //vue-router
  useRouter,
  useRoute,

  //formkit

  //formkit config file

  //fontawesome
  library,
  FontAwesomeIcon,
  fas,
  far,
  fab,

  //centralized data
  usersLevelOptions,
  departmentOptions, //TODO - need to replace this with fetched departments from db
  fieldsDefaultValidation,

  //utilities
  getRandomNumberBetween,

  //helpers
  registerGlobalComponents,

  //utilities
  stripEmptyFields,

  //base api url
  BASE_API_URL,
} from "./../clientImports";

import {
  browserTimezone,
  timezoneOptions,
} from "./../centralized_data/timezoneOptions";

const router = useRouter();
const route = useRoute();

const loginStore = useLoginStore();

const usersStore = useUsersStore();
const toastsStore = useGlobalToastsStore();
const promptsStore = useGlobalPromptsStore();

//watch the usersStore.idToEdit value and update idToUpdate variable

onMounted(async () => {
  await usersStore.fetchAllRoles();
  await usersStore.fetchAllAffiliateGroups();
});

const user = reactive({
  first_name: "",
  last_name: "",
  email: "",
  password: "",
  password_confirmation: "",

  // vendor_user_id: "",

  phone_caller_id: "",

  user_phone_number: "",
  user_phone_extension: "",

  active: false,
  level_access: "",

  passport_required: false,
  department: "",

  affiliate_group_id: "",

  roles: {},

  alert_email: false,

  timezone: "",
});

const idToUpdate = ref(null);

// In your CreateUser component
watch(
  () => usersStore.idToEdit,
  async (newValue) => {
    // Update idToUpdate ref
    idToUpdate.value = newValue;

    // Fetch and update user data
    const userToUpdate = await usersStore.readUser(newValue);
    Object.assign(user, userToUpdate);
  },
  { immediate: true }
);

const resetAffiliateGroup = (event) => {
  if (
    event.target.value !== "affiliate-admin" &&
    event.target.value !== "affiliate-user"
  ) {
    user.affiliate_group_id = "";
  }
};

const updateUser = async () => {
  const neccessaryUserData = stripEmptyFields(user);

  //if the updated user is the current user, update the session data
  if (idToUpdate.value === loginStore.sessionData.foundUser._id) {
    loginStore.updateSessionData({
      email: neccessaryUserData.email,
      first_name: neccessaryUserData.first_name,
      last_name: neccessaryUserData.last_name,
      timezone: neccessaryUserData.timezone,
    });
  }

  const response = await usersStore.updateUser(
    idToUpdate.value,
    neccessaryUserData
  );

  if (response.status === "success") {
    toastsStore.addToast({
      title: "User Updated Successfully",
      description: "",
      buttonExists: false,
      color: "emerald",
      type: 1, // 1 = small, 2 = md, 3 = lg, 4 = lg dismissable
    });

    usersStore.toggleEditUserModal();

    await usersStore.readUsers();
  } else {
    toastsStore.addToast({
      title: "Error",
      description: "Error Updating User",
      buttonExists: false,
      color: "red",
      type: 1, // 1 = small, 2 = md, 3 = lg, 4 = lg dismissable
    });
  }
};
</script>

<template>
  <Teleport to="#modals-target">
    <transition
      enter-active-class="transition-opacity duration-300 ease-in-out"
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="transition-opacity duration-300 ease-in-out"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div
        v-if="usersStore.showEditUserModal"
        @click.self="usersStore.toggleEditUserModal"
        class="z-70 fixed flex flex-col items-end top-0 h-full w-full left-0 backdrop-blur-sm"
      ></div>
    </transition>

    <transition
      enter-active-class="transition-all duration-300 ease-in-out"
      enter-from-class="translate-x-full"
      enter-to-class="translate-x-0"
      leave-active-class="transition-all duration-300 ease-in-out"
      leave-from-class="translate-x-0"
      leave-to-class="translate-x-full"
    >
      <div
        v-if="usersStore.showEditUserModal"
        class="z-70 fixed top-0 right-0 bg-base-100 border-l border-slate-400 w-full h-full max-h-full flex-1 md:w-1/3"
      >
        <div class="h-full overflow-y-auto">
          <FormKit
            type="form"
            :incomplete-message="false"
            submit-label="Update"
            form-class="text-white p-5 h-full flex flex-col justify-between"
            @submit="updateUser"
            outer-class="m-0"
            :actions="false"
            :submit-attrs="{
              'data-theme': `light`,
              ignore: true,
            }"
          >
            <FormKit name="edit-user" type="group">
              <template #default="{ state: { valid } }">
                <div class="grid grid-cols-2 gap-5">
                  <FormKit
                    type="text"
                    label="First Name"
                    value=""
                    placeholder=""
                    :validation="
                      'required:trim|' + fieldsDefaultValidation.name.validation
                    "
                    help-class="text-white"
                    help=""
                    outer-class="m-0 "
                    input-class="$reset rounded w-full input input-sm text-gray-700 bg-gray-50"
                    wrapper-class="max-w-full"
                    v-model.trim="user.first_name"
                  />

                  <FormKit
                    type="text"
                    label="Last Name"
                    placeholder=""
                    :validation="
                      'required:trim|' + fieldsDefaultValidation.name.validation
                    "
                    help-class="text-white"
                    help=""
                    outer-class="m-0 "
                    input-class="$reset rounded w-full input input-sm text-gray-700 bg-gray-50"
                    wrapper-class="max-w-full"
                    v-model.trim="user.last_name"
                  />

                  <FormKit
                    type="email"
                    label="Email"
                    placeholder=""
                    :validation="
                      'required:trim|' +
                      fieldsDefaultValidation.email.validation
                    "
                    help-class="text-white"
                    help=""
                    outer-class="m-0 "
                    input-class="$reset rounded w-full input input-sm text-gray-700 bg-gray-50"
                    wrapper-class="max-w-full"
                    v-model.trim="user.email"
                  />

                  <!-- <FormKit
                        type="text"
                        label="Vendor User ID"
                        placeholder=""
                        help-class="text-white"
                        help=""
                        outer-class="m-0 "
                        input-class="input input-sm text-gray-700 bg-gray-50"
                        wrapper-class="max-w-full"
                        v-model.trim="user.vendor_user_id"
                      /> -->

                  <FormKit
                    type="text"
                    label="Phone Caller ID"
                    placeholder=""
                    help-class="text-white"
                    :validation="fieldsDefaultValidation.callerId.validation"
                    help=""
                    outer-class="m-0 "
                    input-class="$reset rounded w-full input input-sm text-gray-700 bg-gray-50"
                    wrapper-class="max-w-full"
                    v-model.trim="user.phone_caller_id"
                  />

                  <FormKit
                    type="tel"
                    label="User Phone Number"
                    placeholder=""
                    :validation="fieldsDefaultValidation.phone.validation"
                    :validation-messages="
                      fieldsDefaultValidation.phone.validationMessages
                    "
                    help-class="text-white"
                    help=""
                    outer-class="m-0 "
                    input-class="$reset rounded w-full input input-sm text-gray-700 bg-gray-50"
                    wrapper-class="max-w-full"
                    v-model.trim="user.user_phone_number"
                  />

                  <FormKit
                    type="text"
                    label="User Phone Extension"
                    placeholder=""
                    help-class="text-white"
                    help=""
                    validation="length:1,10"
                    outer-class="m-0 "
                    input-class="$reset rounded w-full input input-sm text-gray-700 bg-gray-50"
                    wrapper-class="max-w-full"
                    v-model.trim="user.user_phone_extension"
                  />

                  <FormKit
                    type="select"
                    label="Level Access"
                    validation="required"
                    :value="0"
                    @change="resetAffiliateGroup($event)"
                    :options="usersLevelOptions"
                    help-class="text-white"
                    help=""
                    outer-class="m-0 $reset col-span-2"
                    input-class="$reset select select-sm text-gray-700 bg-gray-50 rounded w-full"
                    wrapper-class="max-w-full"
                    v-model="user.level_access"
                  />

                  <FormKit
                    v-if="
                      user.level_access === 'affiliate-admin' ||
                      user.level_access === 'affiliate-user'
                    "
                    type="select"
                    label="Affiliate Group"
                    :validation="`${
                      user.level_access === 'affiliate-admin' ||
                      user.level_access === 'affiliate-user'
                        ? 'required'
                        : ''
                    }`"
                    :options="[
                      //disabled option
                      {
                        value: '',
                        label: '- No Group Selected -',
                      },
                      ...usersStore?.affiliateGroupOptions,
                    ]"
                    help-class="text-white"
                    help="OPTIONAL: Limits Contact File's Scope to this Affiliate Group"
                    outer-class="m-0 $reset col-span-2"
                    input-class="$reset select select-sm text-gray-700 bg-gray-50 rounded w-full"
                    wrapper-class="max-w-full"
                    v-model="user.affiliate_group_id"
                  />

                  <FormKit
                    type="select"
                    label="Department"
                    placeholder=""
                    validation="required"
                    help-class="text-white"
                    outer-class="$reset m-0 col-span-2"
                    :options="departmentOptions"
                    help=""
                    input-class="$reset rounded w-full select select-sm text-gray-700 bg-gray-50"
                    wrapper-class="max-w-full"
                    v-model.trim="user.department"
                  />

                  <FormKit
                    type="select"
                    label="Role"
                    :validation="[
                      !loginStore?.sessionData?.permissions?.includes(
                        '*:dev-permissions'
                      )
                        ? ['required']
                        : ['trim'],
                    ]"
                    :options="[
                      {
                        value: null,
                        label: '- No Role Selected -',
                        attrs: {
                          disabled: true,
                        },
                      },
                      ...usersStore.rolesOptions,
                    ]"
                    help-class="text-white"
                    help="Hides/Shows Certain Features Based on Role"
                    outer-class="m-0 $reset col-span-2"
                    input-class="$reset select select-sm text-gray-700 bg-gray-50 rounded w-full"
                    wrapper-class="max-w-full"
                    v-model="user.roles"
                  />

                  <FormKit
                    type="password"
                    label="Password"
                    name="password"
                    placeholder=""
                    :validation="
                      'trim|' + fieldsDefaultValidation.password.validation
                    "
                    help-class="text-white"
                    help=""
                    outer-class="m-0 "
                    input-class="$reset rounded w-full input input-sm text-gray-700 bg-gray-50"
                    wrapper-class="max-w-full"
                    v-model.trim="user.password"
                  />

                  <FormKit
                    type="password"
                    label="Confirm Password"
                    name="password_confirm"
                    placeholder=""
                    :validation="'confirm'"
                    help-class="text-white"
                    help=""
                    outer-class="m-0 "
                    input-class="$reset rounded w-full input input-sm text-gray-700 bg-gray-50"
                    wrapper-class="max-w-full"
                    v-model.trim="user.password_confirmation"
                  />

                  <FormKit
                    type="autocomplete"
                    label="Timezone of User"
                    validation="required"
                    :options="timezoneOptions"
                    listbox-class="border border-slate-400 rounded"
                    listitem-class="bg-white"
                    listbox-button-class="bg-gray-50 text-gray-700"
                    option-class="text-gray-700"
                    selection-appearance="text-input"
                    selection-class="text-gray-700"
                    help-class="text-white"
                    :help="`Your Device Timezone: ${browserTimezone}`"
                    outer-class="m-0 col-span-2"
                    input-class="rounded-r-none text-gray-700 bg-gray-50 rounded w-full"
                    wrapper-class="max-w-full"
                    v-model="user.timezone"
                    :value="user.timezone"
                    :filter="
                      (option, search) =>
                        option.label
                          .toLowerCase()
                          .includes(search.toLowerCase())
                    "
                  />

                  <FormKit
                    type="checkbox"
                    label="Passport Required - Forces IP Restriction on User"
                    help-class="text-white"
                    help=""
                    outer-class="m-0 col-span-2"
                    input-class="text-gray-700 bg-gray-50"
                    decorator-class="bg-white"
                    wrapper-class="max-w-full"
                    v-model="user.passport_required"
                  />

                  <FormKit
                    type="checkbox"
                    label="Enable Appointment Email Alerts"
                    help-class="text-white"
                    help=""
                    outer-class="m-0 col-span-2"
                    input-class="input input-sm text-gray-700 bg-gray-50"
                    wrapper-class="max-w-full"
                    v-model="user.alert_email"
                  />

                  <FormKit
                    type="radio"
                    label="Make User Active"
                    :options="[
                      {
                        label:
                          'Yes - User will show under active users list - billable next cycle',
                        value: true,
                      },
                      {
                        label:
                          'No - User will show under inactive users list - not billable next cycle',
                        value: false,
                      },
                    ]"
                    outer-class="m-0 col-span-2 border border-white border-width-2 bg-red-500 rounded"
                    fieldset-class="$reset w-full max-w-full p-3"
                    wrapper-class="w-full max-w-full"
                    decorator-class="border border-white"
                    decorator-icon-class="bg-white rounded-full border-white border"
                    v-model="user.active"
                  />
                </div>

                <FormKit
                  type="submit"
                  label="Update User"
                  :ingore="false"
                  wrapper-class="max-w-full"
                  outer-class="m-0 sticky bottom-0"
                  input-class="$reset bg-emerald-500 w-full w-full rounded py-3 px-2 text-white"
                />
              </template>
            </FormKit>
          </FormKit>
        </div>
      </div>
    </transition>
  </Teleport>
</template>
