import {
  //axios
  axios,

  //pinia
  defineStore,
  useGlobalToastsStore,
  useGlobalPromptsStore,

  //env base url
  BASE_API_URL,
} from "../clientImports";

export const useTenantsTemplatesNotesStore = defineStore({
  id: "tenants-template-notes",
  state: () => ({
    showTenantsTemplatesNotesCreateModal: false,
    showTenantsTemplatesNotesEditModal: false,

    idToEdit: "",

    tenantsTemplatesNotesList: [],

    currentlyCreatingTemplatesNote: {
      active: true,
      name: "",
      description: "",
      note: "",
    },

    currentlyEditingTemplatesNote: {
      active: true,
      name: "",
      description: "",
      note: "",
    },
  }),
  actions: {
    //two responsibilities - set the id to edit and then open the modal
    async initializeTenantsTemplatesNotesEditModal(templatesNoteId) {
      //set's the id of the TemplatesNote that we're editing
      this.setIdToEdit(templatesNoteId);

      //opens the edit modal
      this.showTenantsTemplatesNotesEditModal = true;
    },

    toggleTenantsTemplatesNotesCreateModal() {
      this.showTenantsTemplatesNotesCreateModal =
        !this.showTenantsTemplatesNotesCreateModal;
    },

    toggleTenantsTemplatesNotesEditModal() {
      this.showTenantsTemplatesNotesEditModal =
        !this.showTenantsTemplatesNotesEditModal;
    },

    //fetches the TemplatesNotes for listing
    async fetchTenantsTemplatesNotes() {
      try {
        const { data } = await axios.get(
          `${BASE_API_URL}/read-tenants-templates-notes`
        );

        if (data.status === "success") {
          this.tenantsTemplatesNotesList = data.templatesNotesOptions.map(
            (templatesNote) => {
              return {
                id: templatesNote._id,
                active: templatesNote.active,
                name: templatesNote.name,
                description: templatesNote.description,
              };
            }
          );
        } else {
          console.error(data.message);
        }
      } catch (error) {
        console.error();
      }
    },

    //fetching for editing
    async fetchTenantsTemplatesNote() {
      const id = this.idToEdit;
      try {
        const { data } = await axios.get(
          `${BASE_API_URL}/read-tenants-templates-note/${id}`
        );

        if (data.status === "success") {
          console.log(data.templatesNote);
          this.currentlyEditingTemplatesNote = data.templatesNote[0];
        } else {
          console.error(data.message);
        }
      } catch (error) {
        console.error();
      }
    },

    setIdToEdit(templatesNoteId) {
      this.idToEdit = templatesNoteId;
    },

    async createTenantsTemplatesNote() {
      const templatesNoteData = this.currentlyCreatingTemplatesNote;

      console.log("PASSPORT DATA SENDING OUT: ", templatesNoteData);
      try {
        const { data } = await axios.post(
          `${BASE_API_URL}/create-tenants-templates-note`,
          templatesNoteData
        );

        console.log("CREATE PASSPORT RESPONSE", data);

        if (data.status === "success") {
          const toastsStore = useGlobalToastsStore();
          toastsStore.addToast({
            title: "Note Template Created",
            description: "",
            buttonExists: false,
            color: "emerald",
            type: 1,
          });

          //fetch the updated list of TemplatesNotes
          this.fetchTenantsTemplatesNotes();
          this.toggleTenantsTemplatesNotesCreateModal();
        } else {
          console.error(data.message);
          toastsStore.addToast({
            title: "Error Creating Note Template",
            description: data.message,
            buttonExists: false,
            color: "red",
            type: 1,
          });
        }
      } catch (error) {
        console.error();
      }
    },

    async updateTenantsTemplatesNote() {
      const id = this.idToEdit;
      try {
        const { data } = await axios.put(
          `${BASE_API_URL}/update-tenants-templates-note/${id}`,
          this.currentlyEditingTemplatesNote
        );

        if (data.status === "success") {
          console.log(data.message);

          this.toggleTenantsTemplatesNotesEditModal();
          //fetch the updated list of TemplatesNotes
          this.fetchTenantsTemplatesNotes();

          //throw toast
          const toastsStore = useGlobalToastsStore();
          toastsStore.addToast({
            title: "Note Updated",
            description: "",
            buttonExists: false,
            color: "emerald",
            type: 1,
          });
        } else {
          console.error(data.message);
          toastsStore.addToast({
            title: "Error Updating Note Template",
            description: data.message,
            buttonExists: false,
            color: "red",
            type: 1,
          });
        }
      } catch (error) {
        console.error();
      }
    },

    async deleteTenantsTemplatesNote(templatesNoteId) {
      const promptStore = useGlobalPromptsStore();

      const id = templatesNoteId;

      promptStore.addPrompt({
        type: "TemplatesNote",
        title: "Delete TemplatesNote?",
        message: `Are you sure you want to delete this TemplatesNote?`,
      });

      const promptResponse = await promptStore.waitForPromptConfirmation();

      if (promptResponse === true) {
        promptStore.addPrompt({
          type: "TemplatesNote",
          title: "Are You Sure?",
          message: `We'll hide this from your view so it can no longer be used. Any contacts or users that have this templatesNote assigned will remain assigned and have to be manually changed. Do you still want to proceed with the deletion?`,
        });

        const promptResponse2 = await promptStore.waitForPromptConfirmation();

        if (promptResponse2 === true) {
          try {
            const { data } = await axios.delete(
              `${BASE_API_URL}/delete-tenants-templates-note/${id}`
            );

            if (data.status === "success") {
              //fetch the updated list of TemplatesNotes
              this.fetchTenantsTemplatesNotes();
            } else {
              console.error(data.message);
              toastsStore.addToast({
                title: "Error Deleting Note Template",
                description: data.message,
                buttonExists: false,
                color: "red",
                type: 1,
              });
            }
          } catch (error) {
            console.error();
          }
          promptStore.removePrompt();
        } else {
          promptStore.removePrompt();
        }
      } else {
        promptStore.removePrompt();
      }
    },
  },
});
