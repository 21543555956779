import {
  //axios
  axios,

  //pinia
  defineStore,
  useGlobalToastsStore,
  useGlobalPromptsStore,
  useTenantsWorkflowStatusesStore,

  //env base url
  BASE_API_URL,
} from "../clientImports";

export const useTenantsBotsStore = defineStore({
  id: "tenants-bots",
  state: () => ({
    showTenantsBotsCreateModal: false,
    showTenantsBotsEditModal: false,

    idToEdit: "",

    tenantsBotsList: [],

    currentlyCreatingBot: {
      active: true,
      name: "",
      description: "",
      routines: [],
    },

    currentlyEditingBot: {
      active: true,
      name: "",
      description: "",
      routines: [],
    },
  }),
  actions: {
    //two responsibilities - set the id to edit and then open the modal
    async initializeTenantsBotsEditModal(botId) {
      //set's the id of the Bot that we're editing
      this.setIdToEdit(botId);

      //opens the edit modal
      this.showTenantsBotsEditModal = true;

      await this.fetchTenantBot();
    },

    toggleTenantsBotsCreateModal() {
      this.showTenantsBotsCreateModal = !this.showTenantsBotsCreateModal;
    },

    toggleTenantsBotsEditModal() {
      this.showTenantsBotsEditModal = !this.showTenantsBotsEditModal;
    },

    //fetches the Bots for listing
    async fetchTenantsBots() {
      try {
        const { data } = await axios.get(`${BASE_API_URL}/read-tenants-bots`);

        if (data.status === "success") {
          this.tenantsBotsList = data.botsOptions.map((bot) => {
            return {
              id: bot._id,
              active: bot.active,
              name: bot.name,
              description: bot.description,
              routines: bot.routines,
            };
          });
        } else {
          console.error(data.message);
        }
      } catch (error) {
        console.error();
      }
    },

    //fetching for editing
    async fetchTenantBot() {
      const id = this.idToEdit;

      try {
        const { data } = await axios.get(
          `${BASE_API_URL}/read-tenants-bot/${id}`
        );

        console.log(data, "FETCHED MILESTONE");

        if (data.status === "success") {
          console.log(data.bot);

          //set the currentlyEditingBot.routines
          this.currentlyEditingBot.routines = data.bot[0].routines;

          this.currentlyEditingBot = data.bot[0];

          console.log(this.currentlyEditingBot);
        } else {
          console.log(data.message);
        }
      } catch (error) {
        console.log();
      }
    },

    setIdToEdit(botId) {
      this.idToEdit = botId;
    },

    async createTenantsBot(botRoutines) {
      this.currentlyCreatingBot.routines = botRoutines.filter(
        (botRoutine) =>
          botRoutine.type !== null &&
          botRoutine.target_id !== null &&
          botRoutine.target_id !== 0
      );

      //return map of routines excluding the target_options key
      this.currentlyCreatingBot.routines =
        this.currentlyCreatingBot.routines.map((botRoutine) => {
          const { target_options, ...rest } = botRoutine;
          return rest;
        });

      const botData = this.currentlyCreatingBot;

      console.log("PASSPORT DATA SENDING OUT: ", botData);
      try {
        const { data } = await axios.post(
          `${BASE_API_URL}/create-tenants-bot`,
          botData
        );

        console.log("CREATE PASSPORT RESPONSE", data);

        if (data.status === "success") {
          const toastsStore = useGlobalToastsStore();
          toastsStore.addToast({
            title: "Bot Created",
            description: "",
            buttonExists: false,
            color: "emerald",
            type: 1,
          });

          //fetch the updated list of Bots
          this.fetchTenantsBots();
          this.toggleTenantsBotsCreateModal();
        } else {
          console.error(data.message);
          toastsStore.addToast({
            title: "Error Creating Bot",
            description: data.message,
            buttonExists: false,
            color: "red",
            type: 1,
          });
        }
      } catch (error) {
        console.error();
      }
    },

    async updateTenantsBot() {
      const id = this.idToEdit;

      const botRoutines = this.currentlyEditingBot.routines;
      const botRoutinesFiltered =
        botRoutines?.filter(
          (botRoutine) =>
            botRoutine.type !== null &&
            botRoutine.target_id !== null &&
            botRoutine.target_id !== 0
        ) || [];
      this.currentlyEditingBot.routines = botRoutinesFiltered;
      try {
        const { data } = await axios.put(
          `${BASE_API_URL}/update-tenants-bot/${id}`,
          this.currentlyEditingBot
        );

        if (data.status === "success") {
          console.log(data.message);

          this.toggleTenantsBotsEditModal();
          //fetch the updated list of Bots
          this.fetchTenantsBots();

          //throw toast
          const toastsStore = useGlobalToastsStore();
          toastsStore.addToast({
            title: "Bot Updated",
            description: "",
            buttonExists: false,
            color: "emerald",
            type: 1,
          });
        } else {
          console.error(data.message);
          toastsStore.addToast({
            title: "Error Updating Bot",
            description: data.message,
            buttonExists: false,
            color: "red",
            type: 1,
          });
        }
      } catch (error) {
        console.error();
      }
    },

    async deleteTenantsBot(botId) {
      const promptStore = useGlobalPromptsStore();

      const group_id = botId;

      promptStore.addPrompt({
        type: "Bot",
        title: "Delete Bot?",
        message: `Are you sure you want to delete this Bot?`,
      });

      const promptResponse = await promptStore.waitForPromptConfirmation();

      if (promptResponse === true) {
        promptStore.addPrompt({
          type: "Bot",
          title: "Are You Sure?",
          message: `We'll hide this from your view so it can no longer be used. Any contacts or users that have this bot assigned will remain assigned and have to be manually changed. Do you still want to proceed with the deletion?`,
        });

        const promptResponse2 = await promptStore.waitForPromptConfirmation();

        if (promptResponse2 === true) {
          try {
            const { data } = await axios.delete(
              `${BASE_API_URL}/delete-tenants-bot/${group_id}`
            );

            if (data.status === "success") {
              //fetch the updated list of Bots
              this.fetchTenantsBots();
            } else {
              console.error(data.message);
              toastsStore.addToast({
                title: "Error Deleting Bot",
                description: data.message,
                buttonExists: false,
                color: "red",
                type: 1,
              });
            }
          } catch (error) {
            console.error();
          }
          promptStore.removePrompt();
        } else {
          promptStore.removePrompt();
        }
      } else {
        promptStore.removePrompt();
      }
    },
  },
});
