import { xLog } from "./../clientImports";
import { DateTime } from "luxon";

/**
 * Get the user's timezone from local storage or fallback to browser's timezone.
 * @return {string} The user's time zone or browser's time zone.
 */

const userTimezoneOrDefault = () => {
  // define an empty timezone variable
  let tzLocalStorageOrLocale;
  // check if there is a timezone in local storage
  const sessionData = JSON.parse(
    localStorage?.getItem("sessionData")
  )?.foundUser;

  // if there is a timezone in local storage, set the timezone variable to that value
  if (sessionData?.timezone) {
    tzLocalStorageOrLocale = sessionData?.timezone;
  } else {
    // if there is no timezone in local storage, set the timezone variable to the timezone of the user's browser
    tzLocalStorageOrLocale = Intl.DateTimeFormat().resolvedOptions().timeZone;
  }
  return tzLocalStorageOrLocale;
};

/**
 * Utility function to build format string for DateTime formatting.
 * @param {Object} options - Formatting options.
 * @return {string} The format string for DateTime.
 */

// Utility function to build format string
const buildFormatString = (options) => {
  return [
    options.month ? "M" : "",
    options.day ? "d" : "",
    options.year ? "y" : "",
    options.hour ? "h" : "",
    options.minute ? "mm" : "",
    options.hour ? " a" : "",
  ]
    .filter(Boolean)
    .join("-")
    .replace("-h", " h")
    .replace("h-mm", "h:mm");
};

/**
 * Convert an ISO 8601 date-time string to local time using specified formatting options.
 * @param {string} isoString - ISO 8601 date-time string.
 * @param {Object} options - Formatting options for the DateTime.
 * @return {string} The formatted date-time string.
 */

// Convert ISO string to local time
export const globalIsoToLocal = (isoString, options) => {
  // Build format string based on options
  const formatString = buildFormatString(options);

  // Convert ISO string to DateTime object and set time zone
  const formattedDate = DateTime.fromISO(isoString)
    .setZone(userTimezoneOrDefault())
    .toFormat(formatString);

  // Replace '- ' in formatted string and return
  return formattedDate.replace("- ", " ");
};
