import {
  //axios
  axios,

  //pinia
  defineStore,
  useGlobalToastsStore,
  useGlobalPromptsStore,

  //env base url
  BASE_API_URL,
} from "../clientImports";

export const useTenantsGatewaysStore = defineStore({
  id: "tenants-gateways",
  state: () => ({
    showTenantsGatewaysCreateModal: false,
    showTenantsGatewaysEditModal: false,

    idToEdit: "",

    tenantsGatewaysList: [],

    currentlyCreatingGateway: {
      active: true,
      type: "",
      name: "",
      description: "",
      gateway_data: {},
    },

    currentlyEditingGateway: {
      active: true,
      type: "",
      name: "",
      description: "",
      gateway_data: {},
    },
  }),
  actions: {
    addGatewayKeyValue() {
      this.currentlyCreatingGateway.gateway_data.push({ key, value });
    },

    removeGatewayKeyValue() {
      delete this.currentlyCreatingGateway.gateway_data[property];
    },

    //two responsibilities - set the id to edit and then open the modal
    async initializeTenantsGatewaysEditModal(gatewayId) {
      //set's the id of the Gateway that we're editing
      this.setIdToEdit(gatewayId);

      //opens the edit modal
      this.showTenantsGatewaysEditModal = true;

      await this.fetchTenantGateway();
    },

    toggleTenantsGatewaysCreateModal() {
      this.showTenantsGatewaysCreateModal =
        !this.showTenantsGatewaysCreateModal;
    },

    toggleTenantsGatewaysEditModal() {
      this.showTenantsGatewaysEditModal = !this.showTenantsGatewaysEditModal;
    },

    //fetches the Gateways for listing
    async fetchTenantsGateways() {
      try {
        const { data } = await axios.get(
          `${BASE_API_URL}/read-tenants-gateways`
        );

        if (data.status === "success") {
          this.tenantsGatewaysList = data.gatewaysOptions.map((gateway) => {
            return {
              id: gateway._id,
              type: gateway.type,
              name: gateway.name,
              description: gateway.description,
              gateway_data: gateway.gateway_data,
              active: gateway.active,
            };
          });
        } else {
          console.error(data.message);
        }
      } catch (error) {
        console.error();
      }
    },

    //fetching for editing
    async fetchTenantGateway() {
      const id = this.idToEdit;
      try {
        const { data } = await axios.get(
          `${BASE_API_URL}/read-tenants-gateway/${id}`
        );

        console.log(data, "FETCHED MILESTONE");

        if (data.status === "success") {
          console.log(data.gateway);
          this.currentlyEditingGateway = data.gateway[0];

          console.log(this.currentlyEditingGateway);
        } else {
          console.error(data.message);
        }
      } catch (error) {
        console.error();
      }
    },

    setIdToEdit(gatewayId) {
      this.idToEdit = gatewayId;
    },

    async createTenantsGateway() {
      const gatewayData = this.currentlyCreatingGateway;

      console.log("PASSPORT DATA SENDING OUT: ", gatewayData);
      try {
        const { data } = await axios.post(
          `${BASE_API_URL}/create-tenants-gateway`,
          gatewayData
        );

        console.log("CREATE PASSPORT RESPONSE", data);

        if (data.status === "success") {
          const toastsStore = useGlobalToastsStore();
          toastsStore.addToast({
            title: "Gateway Created",
            description: "",
            buttonExists: false,
            color: "emerald",
            type: 1,
          });

          //fetch the updated list of Gateways
          this.fetchTenantsGateways();
          this.toggleTenantsGatewaysCreateModal();
        } else {
          console.error(data.message);
          toastsStore.addToast({
            title: "Error Creating Gateway",
            description: data.message,
            buttonExists: false,
            color: "red",
            type: 1,
          });
        }
      } catch (error) {
        console.error();
      }
    },

    async updateTenantsGateway(keyValData) {
      console.log("KEYVALDATA", keyValData);

      //keyValData is an array of objects with key and value properties, we need to convert it to an object
      const keyValObj = keyValData.reduce((acc, curr) => {
        acc[curr.key] = curr.value;
        return acc;
      }, {});

      this.currentlyEditingGateway.gateway_data = keyValObj;

      const id = this.idToEdit;
      try {
        const { data } = await axios.put(
          `${BASE_API_URL}/update-tenants-gateway/${id}`,
          this.currentlyEditingGateway
        );

        if (data.status === "success") {
          console.log(data.message);

          this.toggleTenantsGatewaysEditModal();
          //fetch the updated list of Gateways
          this.fetchTenantsGateways();

          //throw toast
          const toastsStore = useGlobalToastsStore();
          toastsStore.addToast({
            title: "Gateway Updated",
            description: "",
            buttonExists: false,
            color: "emerald",
            type: 1,
          });
        } else {
          console.error(data.message);
          toastsStore.addToast({
            title: "Error Updating Gateway",
            description: data.message,
            buttonExists: false,
            color: "red",
            type: 1,
          });
        }
      } catch (error) {
        console.error();
      }
    },

    async deleteTenantsGateway(gatewayId) {
      const promptStore = useGlobalPromptsStore();

      const group_id = gatewayId;

      promptStore.addPrompt({
        type: "Gateway",
        title: "Delete Gateway?",
        message: `Are you sure you want to delete this Gateway?`,
      });

      const promptResponse = await promptStore.waitForPromptConfirmation();

      if (promptResponse === true) {
        promptStore.addPrompt({
          type: "Gateway",
          title: "Are You Sure?",
          message: `We'll hide this from your view so it can no longer be used. Any contacts or users that have this gateway assigned will remain assigned and have to be manually changed. Do you still want to proceed with the deletion?`,
        });

        const promptResponse2 = await promptStore.waitForPromptConfirmation();

        if (promptResponse2 === true) {
          try {
            const { data } = await axios.delete(
              `${BASE_API_URL}/delete-tenants-gateway/${group_id}`
            );

            if (data.status === "success") {
              //fetch the updated list of Gateways
              this.fetchTenantsGateways();
            } else {
              console.error(data.message);
              toastsStore.addToast({
                title: "Error Deleting Gateway",
                description: data.message,
                buttonExists: false,
                color: "red",
                type: 1,
              });
            }
          } catch (error) {
            console.error();
          }
          promptStore.removePrompt();
        } else {
          promptStore.removePrompt();
        }
      } else {
        promptStore.removePrompt();
      }
    },
  },
});
