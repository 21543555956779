<script setup>
defineOptions({
  name: 'GlobalModal', // Give the component a name
});
import {
  //axios
  axios,

  //vue
  createApp,
  ref,
  reactive,
  watch,
  computed,
  inject,
  provide,
  onMounted,
  onUnmounted,
  nextTick,
  Teleport,

  //pinia
  defineStore,
  createPinia,
  storeToRefs,

  //pinia stores
  useChatStore,
  useContactAlertsStore,
  useContactFileStore,
  useContactsStore,
  useGlobalPromptsStore,
  useGlobalToastsStore,
  useLoginStore,
  useTenantsStore,

  //vue-router
  useRouter,
  useRoute,

  //formkit

  //formkit config file

  //fontawesome
  library,
  FontAwesomeIcon,
  fas,
  far,
  fab,

  //global components - all vue components
  registerGlobalComponents,

  //base api url
  BASE_API_URL,
} from "./../clientImports";

const toastsStore = useGlobalToastsStore();
const promptsStore = useGlobalPromptsStore();
// const contactsStore = useContactsStore();

const router = useRouter();
const route = useRoute();
const modalReady = ref(false);
const currentComponent = ref();
onMounted(() => {
  // Your existing logic if any

  setTimeout(() => {
    modalReady.value = true;
    currentComponent.value = route.meta.component;
  }, 100);
});

const clickedBackdrop = async () => {
  modalReady.value = false;
  await nextTick();
  setTimeout(() => {
    router.go(-1);
  }, 300);
};
</script>

<template>
  <!-- teleporting allows easier management of z-index -->
  <Teleport to="#modals-target">
    <transition
      enter-active-class="transition-opacity duration-500 ease-in-out"
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="transition-opacity duration-500 ease-in-out"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <!-- this is the backdrop -->
      <div
        @click.self="clickedBackdrop"
        :class="{
          'opacity-0': !modalReady,
          'opacity-100': modalReady,
        }"
        class="h-screen w-full fixed flex flex-row justify-center items-center z-50 transition-all ease-in-out duration-300"
      >
        <!-- this is the modal -->
        <div
          class="bg-base-300 rounded overflow-y-auto max-h-full border border-slate-400"
        >
          <Component :is="currentComponent" />
        </div>
      </div>
    </transition>
  </Teleport>
</template>
