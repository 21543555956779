<script setup>
defineOptions({
  name: 'SettingsUsage', // Give the component a name
});
import {
  //axios
  axios,

  //vue
  createApp,
  ref,
  reactive,
  watch,
  computed,
  inject,
  provide,
  onMounted,
  onUnmounted,
  nextTick,
  Teleport,

  //pinia
  defineStore,
  createPinia,
  storeToRefs,

  //pinia stores
  useChatStore,
  useContactAlertsStore,
  useContactFileStore,
  useContactsStore,
  useGlobalPromptsStore,
  useGlobalToastsStore,
  useLoginStore,
  useTenantsStore,
  useTenantsUsageStore,

  //vue-router
  useRouter,
  useRoute,

  //formkit

  //formkit config file

  //fontawesome
  library,
  FontAwesomeIcon,
  fas,
  far,
  fab,

  //global components - all vue components
  registerGlobalComponents,

  //base api url
  BASE_API_URL,
} from "./../clientImports";

const usageStore = useTenantsUsageStore();

onMounted(async () => {
  console.log("SettingsUsage.vue mounted");

  //axios get request to
  await usageStore.readUsage();
});

const formattedDbSize = computed(() => {
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (usageStore.database_bytes === 0) return "0 Byte";
  const i = Math.floor(Math.log(usageStore.database_bytes) / Math.log(1024));
  return `${(usageStore.database_bytes / Math.pow(1024, i)).toFixed(2)} ${sizes[i]}`;
});

const formattedFilesSize = computed(() => {
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (usageStore.files_bytes === 0) return "0 Bytes";
  const i = Math.floor(Math.log(usageStore.files_bytes) / Math.log(1024));
  return `${(usageStore.files_bytes / Math.pow(1024, i)).toFixed(2)} ${sizes[i]}`;
});
</script>

<template>
  <div class="h-full flex flex-col bg-base-100">
    <div class="w-full p-3 bg-base-300 text-white">
      <font-awesome-icon icon="fa-solid fa-gauge-high" /><span class="ml-3"
        >Usage</span
      >
    </div>

    <cLoadingSpinner
      :loadingMessage="'Please wait while we calculate your usage...'"
      v-if="
        usageStore.database_bytes === null && usageStore.database_bytes === null
      "
    />

    <div
      v-else
      class="w-full overflow-y-auto flex flex-row items-start flex-1 p-5"
    >
      <div class="grid grid-cols-1 md:grid-cols-2">
        <pie-chart
          width="100%"
          height="100%"
          :donut="true"
          :data="[
            ['Database', formattedDbSize],
            ['Files', formattedFilesSize],
          ]"
        ></pie-chart>

        <div class="flex flex-col gap-2">
          <div class="flex flex-row justify-between gap-4">
            <span class="text-blue-200 text-xl"> Database: </span>
            <span class="text-gray-50 text-xl">{{ formattedDbSize }}</span>
          </div>
          <div class="flex flex-row justify-between gap-4">
            <span class="text-red-200 text-xl"> Files: </span>
            <span class="text-gray-50 text-xl">{{ formattedFilesSize }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="w-full p-3 bg-base-300 text-white flex flex-row justify-end">
      <button class="btn bg-emerald-500 text-white capitalize border-none">
        Save Users
      </button>
    </div>
  </div>
</template>
