<script setup>
defineOptions({
  name: 'MainPage', // Give the component a name
});

import { onErrorCaptured } from 'vue';

onErrorCaptured((err, instance, info) => {
  // err: the error object
  // instance: the component instance where the error was captured
  // info: additional info such as the type of error (e.g., lifecycle hook, event handler)

  console.error('Error captured:', err);
  console.log('Component instance:', instance);
  console.log('Error info:', info);

  // Optionally return `false` to prevent the error from propagating further.
  return false;
});
import { io } from "socket.io-client";
import {
  //axios
  axios,
  ref,
  onMounted,
  computed,

  //pinia stores
  useChatStore,
  useGlobalToastsStore,
  useGlobalPromptsStore,
  FontAwesomeIcon,
  useNetworkedCompaniesStore,
  useTenantsAnnouncementsStore,
  useLoginStore,
  useTenantsSessionsStore,

  //base api url
  BASE_API_URL,
  useSupportTicketsStore,
} from "./../clientImports";

const networkedCompaniesStore = useNetworkedCompaniesStore();
const tenantsAnnouncementsStore = useTenantsAnnouncementsStore();

const chatStore = useChatStore();
const toastsStore = useGlobalToastsStore();
const promptsStore = useGlobalPromptsStore();
const loginStore = useLoginStore();
const supportTicketsStore = useSupportTicketsStore();
const tenantsSessionsStore = useTenantsSessionsStore();

let mainTabsNav = ref(false);

const toggleMainTabsNav = () => {
  mainTabsNav.value = !mainTabsNav.value;
};

const helpModal = ref(false);

const checkSocketStatus = async () => {
  try {
    const response = await axios.get(`${BASE_API_URL}/check-socket`);

    if (response.data.status === "success") {
      toastsStore.addToast({
        title: "Web Socket Check Complete",
        // description: `${response.data.message}`,
        buttonExists: false,
        color: "emerald",
        type: 4, // 1 = small, 2 = md, 3 = lg, 4 = lg dismissable
      });
    }
  } catch (error) {
    console.log(error);
    toastsStore.addToast({
      title: "Error Checking Web Socket Status",
      description: `${response.data.message}`,
      buttonExists: false,
      color: "red",
      type: 4, // 1 = small, 2 = md, 3 = lg, 4 = lg dismissable
    });
  }
};

const getAnnouncements = computed(() => {
  const x = tenantsAnnouncementsStore.tenantsAnnouncementsList
    .filter((item) => item.forced === true || item.active === true)
    .map((announcement) => {
      return {
        id: announcement.id,
        title: announcement.title,
        announcement: announcement.announcement,
        color: announcement.color,
        forced: announcement.forced,
        active: announcement.active,
      };
    });

  console.log("THE ANNOUNCMENTS", x);

  return x;
});

onMounted(() => {
  loginStore.initSocketConnection();
});

// State to track if the chat is open
const isChatOpen = ref(false);

const toggleChatwoot = () => {
  if (window.$chatwoot) {
    //if chatwoot is open
    if (!isChatOpen.value) {
      window.$chatwoot.toggle(); // open it

      //inject custom data to chatwoot instance for user
      window.$chatwoot.setUser(loginStore.sessionData.foundUser._id, {
        email: loginStore.sessionData.foundUser.email,
        name:
          loginStore.sessionData.foundUser.first_name +
          " " +
          loginStore.sessionData.foundUser.last_name,

        //need company and phone number
      });

      window.$chatwoot.setCustomAttributes({
        crm_user_id: loginStore.sessionData.foundUser._id,
        crm_username:
          loginStore.sessionData.foundUser.first_name +
          " " +
          loginStore.sessionData.foundUser.last_name,
        crm_user_email: loginStore.sessionData.foundUser.email,

        crm_tenant_id: loginStore.sessionData.tenant._id,
        crm_company_abbreviation:
          loginStore.sessionData.tenant.company_abbreviation,

        crm_domain_prefix: loginStore.sessionData.tenant.domain_prefix,

        crm_company_display_name:
          loginStore.sessionData.tenant.company_display_name,
      });
    } else {
      console.log(window.$chatwoot);
      // window.$chatwoot.toggleBubbleVisibility("show"); //shows and hides the chat bubble - useless honestly
      // window.$chatwoot.popoutChatWindow(); // pops out in a new window altogether
      window.$chatwoot.toggle(); //toggles the chat window - user can begin using chat at this point
    }
    isChatOpen.value = !isChatOpen.value;
  } else {
    console.log("Chatwoot is not available.");
  }
};
</script>

<template>
  <!-- GLOBAL TOGGLERS - CREATION COMPONENTS - HAVE TO BE IN MAINPAGE IN ORDER TO BE ACCESSIBLE -->
  <SupportTicketsCreate />
  <SupportTicketsEdit />

  <SettingsRebuttalsCreate />
  <!-- GLOBAL TOGGLERS -->

  <!-- <div @click.stop="checkSocketStatus" class="p-3 fixed bottom-0 left-0"></div> -->
  <!-- OUTERMOST -->
  <div class="flex h-screen w-screen flex-row overflow-hidden bg-base-100">
    <!-- COMPANY DIV | TOGGLE -->
    <transition
      enter-active-class="transition-all duration-300 ease-out-in"
      enter-from-class="-translate-x-full"
      enter-to-class="translate-x-0"
      leave-active-class="transition-all duration-300 ease-out-in"
      leave-from-class="translate-x-0"
      leave-to-class="-translate-x-full"
    >
      <NetworkedCompanies
        v-if="networkedCompaniesStore.showNetworkedCompanies"
      ></NetworkedCompanies>
    </transition>

    <!-- MAIN PAGE -->
    <div class="flex flex-1 flex-col overflow-hidden">
      <!-- ANNOUNCEMENTS -->
      <AnnouncementsList />

      <!-- NAV -->
      <div class="flex flex-row">
        <!-- NAV ITEMS BAR -->
        <div
          class="flex flex-1 flex-row bg-base-100 justify-between border-b border-b-slate-400 gap-1"
        >
          <div class="flex flex-row items-center h-full gap-1 md:gap-2">
            <!-- logo -->
            <CompanyLogo />
            <!-- search -->
            <SearchContact />
          </div>

          <!-- sms / emails / mentions -->
          <CommunicationCenter />
          <!-- chat -->
          <!-- MIGHT REMOVE -->
          <!--
        <div class="flex flex-row items-center">
           <button
            @click="chatStore.toggleChat"
            class="text-white capitalize border-none rounded btn btn-xs text-xs md:text-base md:btn-sm bg-secondary"
          >
            <font-awesome-icon icon="fa-solid fa-comments" size="lg" /><span
              class="hidden ml-2 md:block"
              >Chat</span
            >
          </button> 
        </div>
        -->

          <div class="flex flex-row gap-2 items-center">
            <!-- timeclock -->
            <div class="">
              <TimeClock />
            </div>

            <div>
              <SessionDashboard />
            </div>

            <!-- announcements toggle -->
            <button
              v-if="getAnnouncements && getAnnouncements.length > 0"
              @click="tenantsAnnouncementsStore.showAnnouncements = true"
              class="text-gray-600 capitalize border-none rounded btn btn-xs text-xs md:text-base md:btn-sm bg-gray-100 hover:bg-base-100 hover:text-white"
            >
              <font-awesome-icon icon="fa-solid fa-bullhorn" />
            </button>

            <!-- user dropdown -->
            <UserDropdown />
          </div>
        </div>
      </div>

      <!-- SIDEBAR / SELECTED VIEW - CONTAINER -->
      <div class="flex flex-1 flex-row">
        <!-- SIDEBAR -->
        <div
          :class="[
            mainTabsNav
              ? 'w-26 md:w-40 transition-all duration-100 ease-in-out'
              : 'w-10 md:w-16 transition-all duration-100 ease-in-out delay-100 overflow-hidden',
          ]"
          @click="toggleMainTabsNav"
          class="flex flex-col gap-2 p-1 md:p-2 hover:cursor-pointer bg-slate-700 hover:bg-slate-600 border-r border-slate-400"
        >
          <router-link
            v-if="
              loginStore?.sessionData?.permissions?.includes(
                'view:dashboard tab'
              ) ||
              loginStore?.sessionData?.permissions?.includes(
                '*:dev-permissions'
              )
            "
            @click.native.stop
            :to="{ name: 'dashboard-tab' }"
            active-class="disabled"
            class="flex flex-row items-center justify-between text-white btn btn-sm md:btn-md btn-block hover:border hover:border-slate-50 hover:border-dashed hover:bg-black"
          >
            <div
              class="flex items-center justify-center md:justify-between w-full"
            >
              <font-awesome-icon icon="fa-solid fa-gauge" />
              <div v-if="mainTabsNav" class="flex-1">
                <transition
                  enter-active-class="transition-opacity duration-200 ease-out delay-100 overflow-hidden"
                  enter-from-class="opacity-0"
                  enter-to-class="opacity-100"
                  leave-active-class="transition-opacity duration-100 ease-in"
                  leave-from-class="opacity-100"
                  leave-to-class="opacity-0"
                >
                  <span v-if="mainTabsNav" class="ml-2 capitalize"
                    >Dashboard</span
                  >
                </transition>
              </div>
            </div>
          </router-link>

          <router-link
            v-if="
              loginStore?.sessionData?.permissions?.includes(
                'view:contacts tab'
              ) ||
              loginStore?.sessionData?.permissions?.includes(
                '*:dev-permissions'
              )
            "
            @click.native.stop
            :to="{ name: 'contacts-tab' }"
            active-class="disabled"
            class="flex flex-row items-center justify-between text-white btn btn-sm md:btn-md btn-block hover:border hover:border-slate-50 hover:border-dashed hover:bg-black"
          >
            <div
              class="flex items-center justify-center md:justify-between w-full"
            >
              <font-awesome-icon icon="fa-solid fa-user" />
              <div v-if="mainTabsNav" class="flex-1">
                <transition
                  enter-active-class="transition-opacity duration-200 ease-out delay-100 overflow-hidden"
                  enter-from-class="opacity-0"
                  enter-to-class="opacity-100"
                  leave-active-class="transition-opacity duration-100 ease-in"
                  leave-from-class="opacity-100"
                  leave-to-class="opacity-0"
                >
                  <span v-if="mainTabsNav" class="ml-2 capitalize"
                    >Contacts</span
                  >
                </transition>
              </div>
            </div>
          </router-link>

          <router-link
            v-if="
              loginStore?.sessionData?.permissions?.includes(
                'view:tasks tab'
              ) ||
              loginStore?.sessionData?.permissions?.includes(
                '*:dev-permissions'
              )
            "
            @click.native.stop
            :to="{ name: 'tasks-tab' }"
            active-class="disabled"
            class="flex flex-row items-center justify-between text-white btn btn-sm md:btn-md btn-block hover:border hover:border-slate-50 hover:border-dashed hover:bg-black"
          >
            <div
              class="flex items-center justify-center md:justify-between w-full"
            >
              <font-awesome-icon icon="fa-solid fa-list-check" />
              <div v-if="mainTabsNav" class="flex-1">
                <transition
                  enter-active-class="transition-opacity duration-200 ease-out delay-100 overflow-hidden"
                  enter-from-class="opacity-0"
                  enter-to-class="opacity-100"
                  leave-active-class="transition-opacity duration-100 ease-in"
                  leave-from-class="opacity-100"
                  leave-to-class="opacity-0"
                >
                  <span v-if="mainTabsNav" class="ml-2 capitalize">Tasks</span>
                </transition>
              </div>
            </div>
          </router-link>

          <router-link
            v-if="
              loginStore?.sessionData?.permissions?.includes(
                'view:events tab'
              ) ||
              loginStore?.sessionData?.permissions?.includes(
                '*:dev-permissions'
              )
            "
            @click.native.stop
            :to="{ name: 'events-tab' }"
            active-class="disabled"
            class="flex flex-row items-center justify-between text-white btn btn-sm md:btn-md btn-block hover:border hover:border-slate-50 hover:border-dashed hover:bg-black"
          >
            <div
              class="flex items-center justify-center md:justify-between w-full"
            >
              <font-awesome-icon icon="fa-solid fa-calendar-days" />
              <div v-if="mainTabsNav" class="flex-1">
                <transition
                  enter-active-class="transition-opacity duration-200 ease-out delay-100 overflow-hidden"
                  enter-from-class="opacity-0"
                  enter-to-class="opacity-100"
                  leave-active-class="transition-opacity duration-100 ease-in"
                  leave-from-class="opacity-100"
                  leave-to-class="opacity-0"
                >
                  <span v-if="mainTabsNav" class="ml-2 capitalize">Events</span>
                </transition>
              </div>
            </div>
          </router-link>

          <router-link
            v-if="
              loginStore?.sessionData?.permissions?.includes(
                'view:reports tab'
              ) ||
              loginStore?.sessionData?.permissions?.includes(
                '*:dev-permissions'
              )
            "
            @click.native.stop
            :to="{ name: 'reports-tab' }"
            active-class="disabled"
            class="flex flex-row items-center justify-between text-white btn btn-sm md:btn-md btn-block hover:border hover:border-slate-50 hover:border-dashed hover:bg-black"
          >
            <div
              class="flex items-center justify-center md:justify-between w-full"
            >
              <font-awesome-icon icon="fa-solid fa-chart-pie" />
              <div v-if="mainTabsNav" class="flex-1">
                <transition
                  enter-active-class="transition-opacity duration-200 ease-out delay-100 overflow-hidden"
                  enter-from-class="opacity-0"
                  enter-to-class="opacity-100"
                  leave-active-class="transition-opacity duration-100 ease-in"
                  leave-from-class="opacity-100"
                  leave-to-class="opacity-0"
                >
                  <span v-if="mainTabsNav" class="ml-2 capitalize"
                    >Reports</span
                  >
                </transition>
              </div>
            </div>
          </router-link>

          <router-link
            v-if="
              loginStore?.sessionData?.permissions?.includes(
                'view:billables tab'
              ) ||
              loginStore?.sessionData?.permissions?.includes(
                '*:dev-permissions'
              )
            "
            @click.native.stop
            :to="{ name: 'billables-tab' }"
            active-class="disabled"
            class="flex flex-row items-center justify-between text-white btn btn-sm md:btn-md btn-block hover:border hover:border-slate-50 hover:border-dashed hover:bg-black"
          >
            <div
              class="flex items-center justify-center md:justify-between w-full"
            >
              <font-awesome-icon icon="fa-solid fa-landmark" />
              <div v-if="mainTabsNav" class="flex-1">
                <transition
                  enter-active-class="transition-opacity duration-200 ease-out delay-100 overflow-hidden"
                  enter-from-class="opacity-0"
                  enter-to-class="opacity-100"
                  leave-active-class="transition-opacity duration-100 ease-in"
                  leave-from-class="opacity-100"
                  leave-to-class="opacity-0"
                >
                  <span v-if="mainTabsNav" class="ml-2 capitalize"
                    >Billables
                  </span>
                </transition>
              </div>
            </div>
          </router-link>

          <router-link
            v-if="
              loginStore?.sessionData?.permissions?.includes(
                'view:payroll tab'
              ) ||
              loginStore?.sessionData?.permissions?.includes(
                '*:dev-permissions'
              )
            "
            @click.native.stop
            :to="{ name: 'payroll-tab' }"
            active-class="disabled"
            class="flex flex-row items-center justify-between text-white btn btn-sm md:btn-md btn-block hover:border hover:border-slate-50 hover:border-dashed hover:bg-black"
          >
            <div
              class="flex items-center justify-center md:justify-between w-full"
            >
              <font-awesome-icon icon="fa-solid fa-sack-dollar" />
              <div v-if="mainTabsNav" class="flex-1">
                <transition
                  enter-active-class="transition-opacity duration-200 ease-out delay-100 overflow-hidden"
                  enter-from-class="opacity-0"
                  enter-to-class="opacity-100"
                  leave-active-class="transition-opacity duration-100 ease-in"
                  leave-from-class="opacity-100"
                  leave-to-class="opacity-0"
                >
                  <span v-if="mainTabsNav" class="ml-2 capitalize"
                    >Payroll
                  </span>
                </transition>
              </div>
            </div>
          </router-link>

          <hr class="mt-auto" />

          <router-link
            v-if="
              loginStore?.sessionData?.permissions?.includes(
                'view:settings tab'
              ) ||
              loginStore?.sessionData?.permissions?.includes(
                '*:dev-permissions'
              )
            "
            @click.native.stop
            :to="{ name: 'settings-tab' }"
            active-class="disabled"
            class="overflow-hidden flex flex-row items-center justify-between text-white border-none btn btn-sm md:btn-md btn-block bg-indigo-600 hover:border hover:border-slate-50 hover:border-dashed hover:bg-indigo-700"
          >
            <div
              class="flex items-center justify-center md:justify-between w-full"
            >
              <font-awesome-icon icon="fa-solid fa-gear" />
              <div v-if="mainTabsNav" class="flex-1">
                <transition
                  enter-active-class="transition-opacity duration-200 ease-out delay-100 overflow-hidden"
                  enter-from-class="opacity-0"
                  enter-to-class="opacity-100"
                  leave-active-class="transition-opacity duration-100 ease-in"
                  leave-from-class="opacity-100"
                  leave-to-class="opacity-0"
                >
                  <span v-if="mainTabsNav" class="ml-2 capitalize"
                    >Settings</span
                  >
                </transition>
              </div>
            </div>
          </router-link>

          <div class="flex flex-col">
            <!-- bottom buttons -->

            <button
              type="button"
              v-if="
                loginStore?.sessionData?.permissions?.includes(
                  'create:support-tickets'
                ) ||
                loginStore?.sessionData?.permissions?.includes(
                  '*:dev-permissions'
                )
              "
              @click.stop="supportTicketsStore.toggleSupportTicketsCreateModal"
              class="flex flex-row items-center justify-between text-white border-none hover:border hover:border-slate-50 hover:border-dashed btn btn-sm md:btn-sm btn-block glass rounded-t rounded-b-none"
            >
              <div
                class="flex items-center justify-center md:justify-between w-full"
              >
                <font-awesome-icon
                  icon="fa-solid fa-ticket"
                  size="xl"
                  class="text-white"
                />

                <div v-if="mainTabsNav" class="flex-1">
                  <transition
                    enter-active-class="transition-opacity duration-200 ease-out delay-100 overflow-hidden"
                    enter-from-class="opacity-0"
                    enter-to-class="opacity-100"
                    leave-active-class="transition-opacity duration-100 ease-in"
                    leave-from-class="opacity-100"
                    leave-to-class="opacity-0"
                  >
                    <span v-if="mainTabsNav" class="ml-2 capitalize"
                      >New Ticket</span
                    >
                  </transition>
                </div>
              </div>
            </button>

            <router-link
              v-if="
                loginStore?.sessionData?.permissions?.includes(
                  'view:support tab'
                ) ||
                loginStore?.sessionData?.permissions?.includes(
                  '*:dev-permissions'
                )
              "
              @click.native.stop
              :to="{ name: 'support-articles' }"
              active-class="disabled"
              class="flex flex-row items-center justify-between text-white border-none hover:border hover:border-slate-50 hover:border-dashed btn btn-sm md:btn-sm btn-block glass rounded-none"
            >
              <div
                class="flex items-center justify-center md:justify-between w-full pl-0.5"
              >
                <font-awesome-icon icon="fa-solid fa-life-ring" size="xl" />

                <div v-if="mainTabsNav" class="flex-1">
                  <transition
                    enter-active-class="transition-opacity duration-200 ease-out delay-100 overflow-hidden"
                    enter-from-class="opacity-0"
                    enter-to-class="opacity-100"
                    leave-active-class="transition-opacity duration-100 ease-in"
                    leave-from-class="opacity-100"
                    leave-to-class="opacity-0"
                  >
                    <span v-if="mainTabsNav" class="ml-2 capitalize"
                      >Support</span
                    >
                  </transition>
                </div>
              </div>
            </router-link>

            <button
              v-if="
                loginStore?.sessionData?.permissions?.includes(
                  'view:support-chat'
                ) ||
                loginStore?.sessionData?.permissions?.includes(
                  '*:dev-permissions'
                )
              "
              type="button"
              @click.stop="toggleChatwoot"
              class="flex flex-row items-center justify-between text-white border-none hover:border hover:border-slate-50 hover:border-dashed btn btn-sm md:btn-sm btn-block glass rounded-b rounded-t-none"
            >
              <div
                class="flex items-center justify-center md:justify-between w-full pl-0.5"
              >
                <font-awesome-icon icon="fa-solid fa-headset" size="xl" />

                <div v-if="mainTabsNav" class="flex-1">
                  <transition
                    enter-active-class="transition-opacity duration-200 ease-out delay-100 overflow-hidden"
                    enter-from-class="opacity-0"
                    enter-to-class="opacity-100"
                    leave-active-class="transition-opacity duration-100 ease-in"
                    leave-from-class="opacity-100"
                    leave-to-class="opacity-0"
                  >
                    <span v-if="mainTabsNav" class="ml-2 capitalize">Chat</span>
                  </transition>
                </div>
              </div>
            </button>
          </div>

          <SupportGPT></SupportGPT>

          <router-link
            v-if="
              loginStore?.sessionData?.permissions?.includes(
                'view:support-gpt'
              ) ||
              loginStore?.sessionData?.permissions?.includes(
                '*:dev-permissions'
              )
            "
            @click.native.stop
            :to="{ name: 'support-gpt' }"
            active-class="disabled"
            class="flex flex-row items-center justify-between text-white border-none hover:border hover:border-slate-50 hover:border-dashed btn btn-sm md:btn-sm btn-block bg-gradient-to-br from-indigo-500 via-red-500 to-emerald-500 hover:bg-gradient-to-br hover:from-indigo-600 hover:via-red-600 hover:to-emerald-600 rounded-full glass"
          >
            <div
              class="flex items-center justify-center md:justify-between w-full pl-1"
            >
              <font-awesome-icon icon="fa-solid fa-wand-sparkles" size="lg" />

              <div v-if="mainTabsNav" class="flex-1">
                <transition
                  enter-active-class="transition-opacity duration-200 ease-out delay-100 overflow-hidden"
                  enter-from-class="opacity-0"
                  enter-to-class="opacity-100"
                  leave-active-class="transition-opacity duration-100 ease-in"
                  leave-from-class="opacity-100"
                  leave-to-class="opacity-0"
                >
                  <span v-if="mainTabsNav" class="ml-2 capitalize"
                    >FinnyPi GPT</span
                  >
                </transition>
              </div>
            </div>
          </router-link>
        </div>
        <!-- MAIN VIEW -->
        <div
          class="flex flex-col flex-1 bg-gradient-to-br max-h-[calc(100vh-33px)] md:max-h-[calc(100vh-48px)] overflow-x-hidden hide-scroll"
        >
          <router-view :key="$route.params.contact_id"></router-view>
        </div>
      </div>
    </div>
  </div>
</template>
