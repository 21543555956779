<script setup>
defineOptions({
  name: 'CreateContact', // Give the component a name
});
import {
  //axios
  axios,

  //vue
  createApp,
  ref,
  reactive,
  watch,
  computed,
  inject,
  provide,
  onMounted,
  onUnmounted,
  nextTick,
  Teleport,

  //vuedraggable
  draggable,

  //pinia
  defineStore,
  createPinia,
  storeToRefs,

  //pinia stores
  useChatStore,
  useContactAlertsStore,
  useContactFileStore,
  useContactsStore,
  useGlobalPromptsStore,
  useGlobalToastsStore,
  useLoginStore,
  useTenantsStore,

  //vue-router
  useRouter,
  useRoute,

  //formkit
  FKsubmitForm,

  //formkit config file

  //fontawesome
  library,
  FontAwesomeIcon,
  fas,
  far,
  fab,

  //global components - all vue components
  registerGlobalComponents,

  //centralized data
  businessTypeOptions,
  stateOptions,

  //utils
  cleanUpperCase,
  xLog,

  //base api url
  BASE_API_URL,
} from "./../clientImports";

const toastsStore = useGlobalToastsStore();
const contactsStore = useContactsStore();
const tenantsStore = useTenantsStore();
const loginStore = useLoginStore();

const tenantsOptions = ref(null);

const contactFieldsDefault = reactive([
  {
    sort: 1,
    name: "first_name",
    value: "",
    validation: "required",
    label: "First Name",
    shown: true,
    fieldType: "text",
    styling: "input-sm bg-white rounded input w-full flex-1 border-blue-500",
  },
  {
    sort: 2,
    name: "last_name",
    value: "",
    validation: "required",
    label: "Last Name",
    shown: true,
    styling: "input-sm bg-white rounded input w-full flex-1 border-blue-500",
  },
  {
    sort: 3,
    name: "middle_name",
    value: "",
    validation: "",
    label: "Middle Name",
    shown: false,
    fieldType: "text",
    styling: "input-sm bg-white rounded input w-full flex-1 border-blue-500",
  },
  {
    sort: 4,
    name: "email_primary",
    value: "",
    validation: "email",
    label: "Email Primary",
    shown: true,
    fieldType: "email",
    styling: "input-sm bg-white rounded input w-full flex-1 border-blue-500",
  },
  {
    sort: 5,
    name: "email_secondary",
    value: "",
    validation: "email",
    label: "Email Secondary",
    shown: false,
    fieldType: "email",
    styling: "input-sm bg-white rounded input w-full flex-1 border-blue-500",
  },
  {
    sort: 6,
    name: "email_work",
    value: "",
    validation: "email",
    label: "Email Work",
    shown: false,
    fieldType: "email",
    styling: "input-sm bg-white rounded input w-full flex-1 border-blue-500",
  },
  {
    sort: 7,
    name: "phone_primary",
    value: "",
    validation: "required|number|matches:/^[0-9]{3}[0-9]{3}[0-9]{4}$/",
    validation_messages: {
      matches: "Must be 10 digits",
      number: "Must be a number",
    },
    label: "Phone Primary",
    shown: true,
    fieldType: "tel",
    styling: "input-sm bg-white rounded input w-full flex-1 border-blue-500",
  },
  {
    sort: 8,
    name: "phone_secondary",
    value: "",
    validation: "number|matches:/^[0-9]{3}[0-9]{3}[0-9]{4}$/",
    validation_messages: {
      matches: "Must be 10 digits",
      number: "Must be a number",
    },
    label: "Phone Secondary",
    shown: false,
    fieldType: "tel",
    styling: "input-sm bg-white rounded input w-full flex-1 border-blue-500",
  },
  {
    sort: 9,
    name: "phone_mobile",
    value: "",
    validation: "number|matches:/^[0-9]{3}[0-9]{3}[0-9]{4}$/",
    validation_messages: {
      matches: "Must be 10 digits",
      number: "Must be a number",
    },
    label: "Phone Mobile",
    shown: true,
    fieldType: "tel",
    styling: "input-sm bg-white rounded input w-full flex-1 border-blue-500",
  },
  {
    sort: 10,
    name: "phone_work",
    value: "",
    validation: "",
    validation: "number|matches:/^[0-9]{3}[0-9]{3}[0-9]{4}$/",
    validation_messages: {
      matches: "Must be 10 digits",
      number: "Must be a number",
    },
    label: "Phone Work",
    shown: false,
    fieldType: "tel",
    styling: "input-sm bg-white rounded input w-full flex-1 border-blue-500",
  },
  {
    sort: 11,
    name: "street_address",
    value: "",
    validation: "",
    label: "Street Address",
    shown: true,
    fieldType: "text",
    styling: "input-sm bg-white rounded input w-full flex-1 border-blue-500",
  },
  {
    sort: 12,
    name: "apt_unit_suite",
    value: "",
    validation: "",
    label: "Apt / Unit / Suite",
    shown: true,
    fieldType: "text",
    styling: "input-sm bg-white rounded input w-full flex-1 border-blue-500",
  },
  {
    sort: 13,
    name: "city",
    value: "",
    validation: "",
    label: "City",
    shown: true,
    fieldType: "text",
    styling: "input-sm bg-white rounded input w-full flex-1 border-blue-500",
  },
  {
    sort: 14,
    name: "state",
    value: "",
    label: "State",
    shown: true,
    fieldType: "select",
    styling:
      "select-sm bg-white rounded select w-full flex-1 border-blue-500 py-0 px-2",
    options: stateOptions,
  },
  {
    sort: 15,
    name: "zip",
    value: "",
    validation: "",
    label: "Zip",
    shown: true,
    fieldType: "text",
    styling: "input-sm bg-white rounded input w-full flex-1 border-blue-500",
  },
  {
    sort: 16,
    name: "spouse_first_name",
    value: "",
    validation: "",
    label: "Spouse First Name",
    shown: false,
    fieldType: "text",
    styling: "input-sm bg-white rounded input w-full flex-1 border-blue-500",
  },
  {
    sort: 17,
    name: "spouse_last_name",
    value: "",
    validation: "",
    label: "Spouse Last Name",
    shown: false,
    fieldType: "text",
    styling: "input-sm bg-white rounded input w-full flex-1 border-blue-500",
  },
  {
    sort: 18,
    name: "spouse_middle_name",
    value: "",
    validation: "",
    label: "Spouse Middle Name",
    shown: false,
    fieldType: "text",
    styling: "input-sm bg-white rounded input w-full flex-1 border-blue-500",
  },
  {
    sort: 19,
    name: "spouse_email_primary",
    value: "",
    validation: "email",
    label: "Spouse Email Primary",
    shown: false,
    fieldType: "email",
    styling: "input-sm bg-white rounded input w-full flex-1 border-blue-500",
  },
  {
    sort: 20,
    name: "spouse_email_secondary",
    value: "",
    validation: "email",
    label: "Spouse Email Secondary",
    shown: false,
    fieldType: "email",
    styling: "input-sm bg-white rounded input w-full flex-1 border-blue-500",
  },
  {
    sort: 21,
    name: "spouse_email_work",
    value: "",
    validation: "email",
    label: "Spouse Email Work",
    shown: false,
    fieldType: "email",
    styling: "input-sm bg-white rounded input w-full flex-1 border-blue-500",
  },

  {
    sort: 22,
    name: "spouse_phone_primary",
    value: "",
    validation: "number|matches:/^[0-9]{3}[0-9]{3}[0-9]{4}$/",
    validation_messages: {
      matches: "Must be 10 digits",
      number: "Must be a number",
    },
    label: "Spouse Phone Primary",
    shown: false,
    fieldType: "tel",
    styling: "input-sm bg-white rounded input w-full flex-1 border-blue-500",
  },
  {
    sort: 23,
    name: "spouse_phone_secondary",
    value: "",
    validation: "number|matches:/^[0-9]{3}[0-9]{3}[0-9]{4}$/",
    validation_messages: {
      matches: "Must be 10 digits",
      number: "Must be a number",
    },
    label: "Spouse Phone Secondary",
    shown: false,
    fieldType: "tel",
    styling: "input-sm bg-white rounded input w-full flex-1 border-blue-500",
  },
  {
    sort: 24,
    name: "spouse_phone_mobile",
    value: "",
    validation: "number|matches:/^[0-9]{3}[0-9]{3}[0-9]{4}$/",
    validation_messages: {
      matches: "Must be 10 digits",
      number: "Must be a number",
    },
    label: "Spouse Phone Mobile",
    shown: false,
    fieldType: "tel",
    styling: "input-sm bg-white rounded input w-full flex-1 border-blue-500",
  },

  {
    sort: 25,
    name: "spouse_phone_work",
    value: "",
    validation: "number|matches:/^[0-9]{3}[0-9]{3}[0-9]{4}$/",
    validation_messages: {
      matches: "Must be 10 digits",
      number: "Must be a number",
    },
    label: "Spouse Phone Work",
    shown: false,
    fieldType: "tel",
    styling: "input-sm bg-white rounded input w-full flex-1 border-blue-500",
  },

  {
    sort: 26,
    name: "spouse_street_address",
    value: "",
    validation: "",
    label: "Spouse Street Address",
    shown: false,
    fieldType: "text",
    styling: "input-sm bg-white rounded input w-full flex-1 border-blue-500",
  },

  {
    sort: 27,
    name: "spouse_apt_unit_suite",
    value: "",
    validation: "",
    label: "Spouse Apt / Unit / Zip",
    shown: false,
    fieldType: "text",
    styling: "input-sm bg-white rounded input w-full flex-1 border-blue-500",
  },

  {
    sort: 28,
    name: "spouse_city",
    value: "",
    validation: "",
    label: "Spouse City",
    shown: false,
    fieldType: "text",
    styling: "input-sm bg-white rounded input w-full flex-1 border-blue-500",
  },

  {
    sort: 29,
    name: "spouse_state",
    value: "",
    validation: "",
    label: "Spouse State",
    shown: false,
    fieldType: "text",
    styling: "input-sm bg-white rounded input w-full flex-1 border-blue-500",
  },
  {
    sort: 30,
    name: "spouse_zip",
    value: "",
    validation: "",
    label: "Spouse Zip",
    shown: false,
    fieldType: "text",
    styling: "input-sm bg-white rounded input w-full flex-1 border-blue-500",
  },

  {
    sort: 31,
    name: "business_name",
    value: "",
    validation: "",
    label: "Business Name",
    shown: false,
    fieldType: "text",
    styling: "input-sm bg-white rounded input w-full flex-1 border-blue-500",
  },

  {
    sort: 32,
    name: "business_email",
    value: "",
    validation: "email",
    label: "Business Email",
    shown: false,
    fieldType: "email",
    styling: "input-sm bg-white rounded input w-full flex-1 border-blue-500",
  },
  {
    sort: 33,
    name: "business_email_secondary",
    value: "",
    validation: "email",
    label: "Business Email Secondary",
    shown: false,
    fieldType: "email",
    styling: "input-sm bg-white rounded input w-full flex-1 border-blue-500",
  },

  {
    sort: 34,
    name: "business_phone_primary",
    value: "",
    validation: "number|matches:/^[0-9]{3}[0-9]{3}[0-9]{4}$/",
    validation_messages: {
      matches: "Must be 10 digits",
      number: "Must be a number",
    },
    label: "Business Phone Primary",
    shown: false,
    fieldType: "tel",
    styling: "input-sm bg-white rounded input w-full flex-1 border-blue-500",
  },

  {
    sort: 35,
    name: "business_phone_secondary",
    value: "",
    validation: "number|matches:/^[0-9]{3}[0-9]{3}[0-9]{4}$/",
    validation_messages: {
      matches: "Must be 10 digits",
      number: "Must be a number",
    },
    label: "Business Phone Secondary",
    shown: false,
    fieldType: "tel",
    styling: "input-sm bg-white rounded input w-full flex-1 border-blue-500",
  },

  {
    sort: 36,
    name: "business_ein",
    value: "",
    validation: "",
    label: "Business EIN",
    shown: false,
    fieldType: "text",
    styling: "input-sm bg-white rounded input w-full flex-1 border-blue-500",
  },

  {
    sort: 37,
    name: "business_type",
    value: "",
    label: "Business Type",
    shown: false,
    fieldType: "select",
    styling:
      "select-sm bg-white rounded select w-full flex-1 border-blue-500 py-0 px-2",
    options: businessTypeOptions,
  },

  {
    sort: 38,
    name: "business_street_address",
    value: "",
    validation: "",
    label: "Business Street Address",
    shown: false,
    fieldType: "text",
    styling: "input-sm bg-white rounded input w-full flex-1 border-blue-500",
  },

  {
    sort: 39,
    name: "business_suite_unit",
    value: "",
    validation: "",
    label: "Business Suite / Unit",
    shown: false,
    fieldType: "text",
    styling: "input-sm bg-white rounded input w-full flex-1 border-blue-500",
  },

  {
    sort: 40,
    name: "business_city",
    value: "",
    validation: "",
    label: "Business City",
    shown: false,
    fieldType: "text",
    styling: "input-sm bg-white rounded input w-full flex-1 border-blue-500",
  },

  {
    sort: 41,
    name: "business_state",
    value: "",
    validation: "",
    label: "Business State",
    shown: false,
    fieldType: "text",
    styling: "input-sm bg-white rounded input w-full flex-1 border-blue-500",
  },

  {
    sort: 42,
    name: "business_zip",
    value: "",
    validation: "",
    label: "Business Zip",
    shown: false,
    fieldType: "text",
    styling: "input-sm bg-white rounded input w-full flex-1 border-blue-500",
  },

  {
    sort: 43,
    name: "shared_with_company_ids",
    value: "",
    label: "Companies Assigned",
    shown: false,
    fieldType: "select",
    styling:
      "select-sm bg-white rounded select w-full flex-1 border-blue-500 py-0 px-2",
    options: businessTypeOptions,
  },
]);

// use local storage / fallback to static
let contactFields = ref(
  JSON.parse(localStorage.getItem("createContactManagedFields")) ||
    contactFieldsDefault
);

let contactDetails = reactive({});

const resetCreateContactForm = () => {
  contactFields.value.forEach((field) => {
    field.value = "";
  });
};

watch(contactFields.value, (newFields, oldFields) => {
  for (let i = 0; i < newFields.length; i++) {
    const property = newFields[i];

    if (property.value !== "") {
      contactDetails[
        property.label
          .toLowerCase()
          .replaceAll("/", "")
          .replaceAll(" ", "_")
          .replaceAll("__", "_")
      ] = property.value;
    }
  }
});

const createContact = async () => {
  // try {
  const result = await contactsStore.createContact(contactDetails);

  if (result.data.status === "success") {
    toastsStore.addToast({
      title: "Contact Created",
      description: "Contact created successfully",
      buttonExists: false,
      color: "emerald",
      type: 1, // 1 = small, 2 = md, 3 = lg, 4 = lg dismissable
    });

    await contactsStore.fetchContacts();

    resetCreateContactForm();

    contactsStore.toggleCreateContactModal();
  }
  // } catch (e) {
  //   const { error, friendlyError } = e.response.data.specific;
  //   const [key, val] = Object.entries(error.keyValue)[0];
  //   toastsStore.addToast({
  //     title: "Error Creating Contact",
  //     description: `${cleanUpperCase(key)} ${friendlyError}`,
  //     buttonExists: false,
  //     color: "red",
  //     type: 1, // 1 = small, 2 = md, 3 = lg, 4 = lg dismissable
  //   });
  // }
};

let showManageContactFields = ref(false);

const toggleManageContactFields = () => {
  showManageContactFields.value = !showManageContactFields.value;
};

const applyManagedContactFields = () => {
  //save to local storage
  localStorage.setItem(
    "createContactManagedFields",
    JSON.stringify(contactFields.value)
  );

  toastsStore.addToast({
    title: "Customization Saved",
    description: "",
    buttonExists: false,
    color: "emerald",
    type: 1, // 1 = small, 2 = md, 3 = lg, 4 = lg dismissable
  });

  toggleManageContactFields();
};

const wipeManagedContactFields = () => {
  localStorage.removeItem("createContactManagedFields");

  contactFields.value = contactFieldsDefault;

  toastsStore.addToast({
    title: "Customization Reset to Default",
    description: "",
    buttonExists: false,
    color: "emerald",
    type: 1, // 1 = small, 2 = md, 3 = lg, 4 = lg dismissable
  });

  toggleManageContactFields();
};

const selectAllContactFields = () => {
  const areAllSelected = contactFields.value.every((item) => item.shown);

  contactFields.value.forEach((item) => {
    if (
      item.name === "first_name" ||
      item.name === "last_name" ||
      item.name === "email_primary" ||
      item.name === "phone_primary"
    )
      return;
    item.shown = !areAllSelected;
  });
};

const submitFormKitForm = () => FKsubmitForm("create-contact-form");
</script>
<template>
  <cSidebarModal
    sidebar-position="right"
    :show-sidebar-modal="contactsStore.showCreateContactModal"
    :toggle-sidebar-modal="
      loginStore?.sessionData?.permissions?.includes('create:contacts') ||
      loginStore?.sessionData?.permissions?.includes('*:dev-permissions')
        ? contactsStore.toggleCreateContactModal
        : null
    "
  >
    <template #toggle-button>
      <font-awesome-icon icon="fa-solid fa-plus" />
    </template>
    <template #title>Create Contact</template>
    <template #button-group>
      <div class="flex flex-row justify-end">
        <button
          @click="toggleManageContactFields"
          class="btn btn-xs md:btn-sm bg-purple-500 text-white capitalize rounded border-none"
        >
          Manage Fields
        </button>
      </div>
    </template>

    <template #body>
      <FormKit
        id="create-contact-form"
        type="form"
        :incomplete-message="false"
        actions-class="mt-8"
        form-class="text-white h-full flex-1 flex flex-col justify-between"
        @submit="createContact"
        :actions="false"
        :submit-attrs="{
          'data-theme': 'light',
          ignore: true,
        }"
      >
        <div class="grid grid-cols-2 gap-5">
          <div
            v-for="(field, fieldIndex) in contactFields"
            :key="fieldIndex"
            v-show="field.shown"
          >
            <FormKit
              v-if="field.shown"
              :type="field.fieldType"
              :label="field.label"
              inner-class="mt-3"
              :name="field.label"
              outer-class="m-0"
              :input-class="field.styling"
              :value="field.value"
              :validation="field.validation"
              :validation-messages="field.validation_messages"
              messages-class="relative"
              message-class="absolute -top-3 right-2 bg-red-500 rounded text-xs text-white p-1"
              :options="field.options"
              v-model.trim="field.value"
              validation-visibility="submit"
            />
          </div>
        </div>
      </FormKit>
    </template>

    <template #footer>
      <div class="flex flex-row justify-between items-center gap-5">
        <button
          @click="contactsStore.toggleCreateContactModal"
          class="btn btn-sm rounded border-none capitalize bg-red-500 hover:bg-red-700 text-white"
        >
          Cancel
        </button>

        <button
          @click="submitFormKitForm"
          class="btn btn-sm rounded border-none capitalize bg-emerald-500 hover:bg-emerald-700 text-white"
        >
          Create Contact
        </button>
      </div>
    </template>
  </cSidebarModal>

  <cSidebarModal
    sidebar-position="left"
    :show-sidebar-modal="showManageContactFields"
    :toggle-sidebar-modal="toggleManageContactFields"
    :default-button="false"
    :has-backdrop="false"
  >
    <template #title>Manage Contact Fields</template>

    <template #button-group>
      <div class="flex flex-row justify-end gap-2">
        <!-- select all button -->
        <button
          @click="selectAllContactFields"
          class="btn btn-sm rounded border-none capitalize bg-blue-500 hover:bg-blue-700 text-white"
        >
          Select All
        </button>

        <div class="text-xs p-2 bg-purple-500 text-white">
          {{ contactFields.filter((item) => item.shown === true).length }}
          Selected
        </div>
      </div>
    </template>

    <template #body>
      <draggable
        class="grid grid-cols-2 flex-col gap-5"
        handle=".drag-handle"
        v-model="contactFields"
      >
        <template #item="{ element, index }">
          <div :key="element.name">
            <div
              class="bg-purple-500 rounded flex flex-row gap-2 items-center p-2 h-full"
            >
              <input
                :id="element.label"
                type="checkbox"
                :disabled="
                  element.name === 'first_name' ||
                  element.name === 'last_name' ||
                  element.name === 'email_primary' ||
                  element.name === 'phone_primary'
                "
                :class="
                  element.name === 'first_name' ||
                  element.name === 'last_name' ||
                  element.name === 'email_primary' ||
                  element.name === 'phone_primary'
                    ? 'pointer-events-none disabled'
                    : ''
                "
                class="checkbox checkbox-primary bg-white border-none"
                v-model="element.shown"
              />
              <label
                :for="element.label"
                class="text-white flex-1 capitalize"
                >{{ element.label }}</label
              >
              <div
                class="drag-handle text-white cursor-pointer hover:bg-gray-400 rounded py-1 px-2"
              >
                <font-awesome-icon icon="fa-solid fa-grip-lines" />
              </div>
            </div>
          </div>
        </template>
      </draggable>
    </template>

    <template #footer>
      <div class="flex flex-row justify-between items center">
        <button
          @click="toggleManageContactFields"
          class="btn btn-sm rounded border-none capitalize bg-red-500 hover:bg-red-700 text-white"
        >
          Cancel
        </button>

        <!-- reset button -->
        <button
          @click="wipeManagedContactFields"
          class="btn btn-sm rounded border-none capitalize bg-orange-500 hover:bg-orange-700 text-white"
        >
          Reset to Default
        </button>

        <button
          @click="applyManagedContactFields"
          class="btn btn-sm rounded border-none capitalize bg-emerald-500 hover:bg-emerald-700 text-white"
        >
          Apply
        </button>
      </div>
    </template>
  </cSidebarModal>
</template>
