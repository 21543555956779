<script setup>
defineOptions({
  name: 'ContactFormsTab', // Give the component a name
});
import {
  //axios
  axios,

  //vue
  createApp,
  ref,
  reactive,
  watch,
  computed,
  inject,
  provide,
  onMounted,
  onUnmounted,
  nextTick,
  Teleport,

  //pinia
  defineStore,
  createPinia,
  storeToRefs,

  //pinia stores
  useChatStore,
  useContactAlertsStore,
  useContactFileStore,
  useContactsStore,
  useGlobalPromptsStore,
  useGlobalToastsStore,
  useLoginStore,
  useTenantsStore,
  useTenantsFormsStore,
  useContactFormsStore,

  //vue-router
  useRouter,
  useRoute,

  //formkit

  //formkit config file

  //fontawesome
  library,
  FontAwesomeIcon,
  fas,
  far,
  fab,

  //global components - all vue components
  registerGlobalComponents,

  //base api url
  BASE_API_URL,
  FKsubmitForm,
} from "./../clientImports";

const router = useRouter();
const route = useRoute();

const tenantsForms = useTenantsFormsStore();
const contactFileStore = useContactFileStore();
const contactFormsStore = useContactFormsStore();

onMounted(async () => {
  // Fetch the forms
  await tenantsForms.readForms();

  // Ensure forms are fetched
  if (!tenantsForms.fetchedCustomForms.length) {
    throw new Error("No forms fetched");
  }

  // Retrieve contactId and currentFormId
  const contactIdPromise = Promise.resolve(contactFileStore.contactId);
  const currentFormIdPromise = Promise.resolve(
    tenantsForms.fetchedCustomForms[contactFormsStore.currentFormIndex]?._id
  );

  // Wait for both promises to resolve
  const [contactId, currentFormId] = await Promise.all([
    contactIdPromise,
    currentFormIdPromise,
  ]);

  if (!contactId) {
    throw new Error("Contact ID is not available");
  }

  if (!currentFormId) {
    throw new Error("Current Form ID is not available");
  }

  // Fetch custom form contact data
  await contactFormsStore.getCustomFormContactData(contactId, currentFormId);

  // Loop over contactFormsStore.currentFormData array, find the matching key in the currentFormData object by the _id and set the value to the field_value property
  contactFormsStore.currentFormData.forEach((item) => {
    currentFormData.value[item.field_name] = item.field_value;
  });

  console.log("contactFormsStore.currentFormData", contactRenderedForm.value);
});

//computed function that returns the array of forms sorted by their type property first then by their name property in ascending order
const getForms = computed(() => {
  return tenantsForms.fetchedCustomForms.sort((a, b) => {
    if (a.type < b.type) {
      return -1;
    }
    if (a.type > b.type) {
      return 1;
    }
    return a.name.localeCompare(b.name);
  });
});

const setCurrentFormIndex = async (index) => {
  contactFormsStore.currentFormIndex = index;
  currentFormData.value = {};

  // Retrieve contactId and currentFormId
  const contactIdPromise = Promise.resolve(contactFileStore.contactId);
  const currentFormIdPromise = Promise.resolve(
    tenantsForms.fetchedCustomForms[contactFormsStore.currentFormIndex]?._id
  );

  // Wait for both promises to resolve
  const [contactId, currentFormId] = await Promise.all([
    contactIdPromise,
    currentFormIdPromise,
  ]);

  if (!contactId) {
    throw new Error("Contact ID is not available");
  }

  if (!currentFormId) {
    throw new Error("Current Form ID is not available");
  }

  console.log("CHECKING contactId, currentFormId: ", contactId, currentFormId);

  // Fetch custom form contact data
  await contactFormsStore.getCustomFormContactData(contactId, currentFormId);

  // Loop over contactFormsStore.currentFormData array, find the matching key in the currentFormData object by the _id and set the value to the field_value property
  contactFormsStore.currentFormData.forEach((item) => {
    currentFormData.value[item.field_name] = item.field_value;
  });

  //scroll to the first group section in the form
  backToFormsTop(getSectionLinks.value[0]?.anchor);

  console.log("contactFormsStore.currentFormData", contactRenderedForm.value);
};

const handlePopOutAndIn = async () => {
  if (contactFormsStore.contactFormPoppedOutView) {
    contactFormsStore.contactFormPoppedOutView = false;
  } else {
    contactFormsStore.contactFormPoppedOutView = true;
  }
};

const getCurrentFormElements = computed(() => {
  return getForms.value[contactFormsStore.currentFormIndex]?.elements;
});

const getCurrentRebuttals = computed(() => {
  return rebuttalButtons.value;
});

//get the current elements again but without the separators
const getCurrentFormElementsNoSeparators = computed(() => {
  return getForms.value[contactFormsStore.currentFormIndex]?.elements.filter(
    (item) => item.type !== "separator"
  );
});

//computed function that returns the 'separator' type obj's from tenantsCustomForms.elements array
const getSectionLinks = computed(() => {
  return getCurrentForm.value?.elements.filter(
    (item) => item.type === "separator"
  );
});

const showCompanyContactForms = ref(false);

let currentCustomForm = reactive({});
const getCurrentForm = computed(() => {
  return getForms.value[contactFormsStore.currentFormIndex];
});

const saveCustomForm = () => {
  //save the form
};

const showFormCol = ref(false);
const toggleFormCol = () => {
  showFormCol.value = !showFormCol.value;
};

const submitUniversalCustomContactForm = (
  contactId,
  formId,
  formName,
  formDataArray
) => {
  const theFinalPutArray = buildFinalFormDataObj(
    contactId,
    formId,
    formName,
    formDataArray
  );

  contactFormsStore.putCustomFormContactData(theFinalPutArray);
};

const universalCustomFormSubmitButton = () => {
  return FKsubmitForm("universal-contact-custom-form");
};

const sendFormExternally = () => {
  alert("send form externally");
};

const handleSetCurrentForm = (index) => {
  setCurrentFormIndex(index);

  //thang

  showCompanyContactForms.value = false;
};

const handleKeyDown = (event) => {
  if (event.key === "Enter") {
    event.preventDefault(); // Prevent the default form submission
    universalCustomFormSubmitButton();
  }
};

const backToFormsTop = (elId) => {
  const el = document.getElementById(elId);
  el.scrollIntoView({
    block: "start",
  });
};

const scrollToElement = (elId) => {
  const el = document.getElementById(elId);
  if (el) {
    el.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });

    // Add blue border after scrolling
    el.classList.add("glass", "text-white", "bg-blue-500");
    setTimeout(() => {
      el.classList.remove("glass", "text-white", "bg-blue-500");
      setTimeout(() => {
        el.classList.add("glass", "text-white", "bg-blue-500");
        setTimeout(() => {
          el.classList.remove("glass", "text-white", "bg-blue-500");
          setTimeout(() => {
            el.classList.add("glass", "text-white", "bg-blue-500");
            setTimeout(() => {
              el.classList.remove("glass", "text-white", "bg-blue-500");
              setTimeout(() => {
                el.classList.add("glass", "text-white", "bg-blue-500");
                setTimeout(() => {
                  el.classList.remove("glass", "text-white", "bg-blue-500");
                }, 175);
              }, 175);
            }, 175);
          }, 175);
        }, 175);
      }, 175);
    }, 175);
  }
};

const buildFinalFormDataObj = (contactId, formId, formName, formDataArray) => {
  //build an array of objects from each element - _id, form_id, field_type, field_id, field_value

  const finalFormDataArr = formDataArray.map((item) => {
    return {
      contact_id: contactId,
      form_id: formId,
      form_name: formName,
      field_type: item.type,
      field_name: item.name,
      field_id: item._id,
      field_value: item.value,
    };
  });

  //use keys in currentFormData to locate the object with the same key as it's name and set the value property to the value of the currentFormData key
  const findFormKeys = Object.keys(currentFormData.value).map((key) => {
    return {
      field_name: key,
      field_value: currentFormData.value[key],
    };
  });

  // Merge objects from finalFormDataArr into findFormKeys based on matching field_name
  const mergedFormData = findFormKeys.map((item) => {
    const matchingItem = finalFormDataArr.find(
      (arrItem) => arrItem.field_name === item.field_name
    );
    if (matchingItem) {
      return { ...matchingItem, ...item };
    }
    return item;
  });

  console.log("mergedFormData", mergedFormData);

  return mergedFormData;
};

//live data obj
let currentFormData = ref({});

//gets the current data in object format with key values | key === field name, value === field value
const getCurrentFormDataKeys = computed(() => {
  //get the current form elements
  const currentFormElements = getCurrentFormElementsNoSeparators.value;
});

let contactRenderedForm = ref({});

watch(getCurrentFormElements, (newVal) => {
  //use computed transformArrayToObject to create the contactRenderedForm object
  contactRenderedForm.value = transformArrayToObject.value;
});

const transformArrayToObject = computed(() => {
  const result = {};

  getCurrentFormElements.value?.forEach((item) => {
    if (item.type === "separator") {
      const { anchor, ...rest } = item;
      result[item.anchor] = item;
    } else if (item.type === "script") {
      const { script_name, ...rest } = item;
      result[item.script_name] = item;
    } else {
      const { name, ...rest } = item; // Destructure item into name and rest of the properties
      result[name] = item; // Assign rest of the properties to the name key
    }
  });

  return result;
});

const handleCustomFormValue = (defaultValue, handleSavedValue) => {
  //do
};

const regexStringToRegexObj = (validationArray) => {
  const matchesIndex = validationArray.findIndex((innerArray) =>
    innerArray.includes("matches")
  );

  if (matchesIndex !== -1) {
    validationArray[matchesIndex][1] =
      validationArray[matchesIndex][1].toString();
  }

  return validationArray;
};

const formType = ref("form");

const setFormTypeFilter = (type) => {
  formType.value = type;
};

const rebuttalButtons = ref([]);

//watch the tenantsForms.fetchedCustomForms array and when it changes, set the rebuttalButtons array to the fetchedCustomForms.rebuttals array
watch(
  () => tenantsForms.fetchedCustomForms,
  (newVal) => {
    //we only need the rebuttals array getForms[contactFormsStore.currentFormIndex].rebuttals
    rebuttalButtons.value =
      getForms.value[contactFormsStore.currentFormIndex]?.rebuttals || [];
  }
);

const showDynamicRebuttalModal = (id) => {
  contactFormsStore.fetchedRebuttalData = {};
  //call store method to show the modal and then fetch the modal data
  contactFormsStore.intialalizeRebuttalView(id);
};
</script>

<template>
  <Teleport to="#modals-target">
    <transition
      enter-active-class="transition-opacity duration-500 ease-in-out"
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="transition-opacity duration-500 ease-in-out"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div
        @click.self="showCompanyContactForms = false"
        v-if="showCompanyContactForms"
        class="w-screen h-screen backdrop-brightness-50 fixed top-0 left-0 z-50 flex flex-col justify-center items-center"
      >
        <transition
          enter-active-class="transform transition-transform duration-500 ease-in-out"
          enter-from-class="-translate-x-full"
          enter-to-class="translate-x-0"
          leave-active-class="transform transition-transform duration-500 ease-in-out"
          leave-from-class="translate-x-0"
          leave-to-class="-translate-x-full"
        >
          <div
            class="fixed top-0 right-0 h-screen w-96 flex flex-col gap-5 items-start bg-slate-600 p-5 rounded"
          >
            <h1 class="text-lg text-gray-50">Your Companies Forms</h1>

            <div class="flex flex-row justify-between items-center w-full">
              <button
                type="button"
                @click="setFormTypeFilter('form')"
                :class="`${formType === 'form' ? 'bg-gray-50 border-b-4 text-gray-500 border-x-0 border-t-0 border-b-blue-400 hover:bg-gray-50 hover:border-b-blue-500' : 'bg-gray-50 text-gray-500 border-none hover:bg-blue-500 hover:text-gray-50'}`"
                class="flex-1 btn btn-sm capitalize rounded-none"
              >
                Forms
              </button>
              <button
                type="button"
                @click="setFormTypeFilter('script')"
                :class="`${formType === 'script' ? 'bg-gray-50 border-b-4 text-gray-500 border-x-0 border-t-0 border-b-blue-400 hover:bg-gray-50 hover:border-b-blue-500' : 'bg-gray-50 text-gray-500 border-none hover:bg-blue-500 hover:text-gray-50'}`"
                class="flex-1 btn btn-sm capitalize rounded-none"
              >
                Scripts
              </button>
            </div>

            <div
              v-if="getForms.length < 1"
              class="bg-blue-500 p-5 text-center text-gray-50 rounded"
            >
              No Forms Yet. Start Dragging and Dropping Fields to Create one.
            </div>

            <div
              v-for="(item, i) in getForms"
              v-show="formType === item.type"
              :key="i"
              :class="
                contactFormsStore.currentFormIndex === i
                  ? `bg-gray-50 border-indigo-400 border-l-8 text-gray-600 font-bold`
                  : `bg-gray-50 text-gray-600`
              "
              class="flex flex-row items-center w-full"
            >
              <span v-if="item.type === 'form'" class="mx-2">
                <font-awesome-icon :icon="['fas', 'align-justify']" />
              </span>
              <span v-if="item.type === 'script'" class="mx-2">
                <font-awesome-icon :icon="['fas', 'scroll']" />
              </span>
              <span class="hidden md:block py-3">
                {{ item.name }}
              </span>

              <div class="flex flex-col ml-auto">
                <button
                  v-if="contactFormsStore.currentFormIndex !== i"
                  @click="handleSetCurrentForm(i)"
                  class="btn border-none bg-blue-500 hover:bg-blue-700 rounded-none text-gray-50"
                >
                  <span class="capitalize">Use</span>
                </button>

                <button
                  v-if="item.type === 'form'"
                  type="button"
                  @click="sendFormExternally"
                  class="btn rounded-none capitalize bg-purple-500 hover:bg-purple-700 border-none text-white"
                >
                  Send Form
                </button>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </transition>
  </Teleport>

  <!-- vue animation that slides in from left and leaves to left -->
  <transition
    enter-active-class="transform transition-transform duration-500 ease-in-out"
    enter-from-class="-translate-x-full"
    enter-to-class="translate-x-0"
    leave-active-class="transform transition-transform duration-500 ease-in-out"
    leave-from-class="translate-x-0"
    leave-to-class="-translate-x-full"
  >
    <Teleport to="#modals-target">
      <transition
        enter-active-class="transform transition-transform duration-100 ease-in-out"
        enter-from-class="-translate-x-full"
        enter-to-class="translate-x-0"
        leave-active-class="transform transition-transform duration-100 ease-in-out"
        leave-from-class="translate-x-0"
        leave-to-class="-translate-x-full"
      >
        <div
          @click.self="contactFormsStore.showRebuttalPopUp = false"
          v-if="contactFormsStore.showRebuttalPopUp"
          class="fixed w-screen h-screen z-60"
        >
          <div
            v-if="
              contactFormsStore.showRebuttalPopUp &&
              contactFormsStore.fetchedRebuttalData.color
            "
            :class="`bg-${contactFormsStore.fetchedRebuttalData.color}-500`"
            class="w-72 h-full bg-base-100 flex flex-col items-center justify-center"
          >
            <h1 class="text-gray-50 p-3 text-2xl">
              {{ contactFormsStore.fetchedRebuttalData?.title || "TITLE" }}
            </h1>

            <h2 class="text-gray-50 p-3 text-xl">
              {{
                contactFormsStore.fetchedRebuttalData?.rebuttal || "REBUTTAL"
              }}
            </h2>
          </div>
        </div>
      </transition>
    </Teleport>
  </transition>

  <div class="flex flex-row items-start h-full max-h-full bg-base-100">
    <div
      v-if="
        tenantsForms.fetchedCustomForms[contactFormsStore.currentFormIndex]
          ?.rebuttals.length > 0
      "
      class="flex flex-col w-72 max-w-72 h-full bg-base-100 p-2 gap-3"
    >
      <button
        type="button"
        v-for="(item, i) in tenantsForms.fetchedCustomForms[
          contactFormsStore.currentFormIndex
        ]?.rebuttals"
        @click="showDynamicRebuttalModal(item._id)"
        :class="`bg-${item.color}-500 hover:bg-hover:bg-${item.color}-600`"
        class="btn text-gray-50 border-none capitalize"
      >
        {{ item.name }}
      </button>
    </div>

    <div
      :class="`${contactFormsStore.contactFormPoppedOutView ? 'h-full' : 'max-h-[calc(100vh-160px)]'}`"
      class="flex flex-1 flex-col bg-base-100 border-r border-r-slate-400"
    >
      <FormKit
        id="universal-contact-custom-form"
        type="form"
        :auto-animate="false"
        @keydown="handleKeyDown"
        :incomplete-message="false"
        submit-label="Create"
        form-class="text-white flex flex-col border-t h-full overflow-y-auto border-t-slate-400"
        @submit.self="
          submitUniversalCustomContactForm(
            contactFileStore.contactId,
            getForms[contactFormsStore.currentFormIndex]._id,
            getForms[contactFormsStore.currentFormIndex].name,
            getCurrentFormElementsNoSeparators
          )
        "
        outer-class="m-0"
        :actions="false"
        :submit-attrs="{
          'data-theme': `light`,
          ignore: true,
        }"
      >
        <div
          v-if="Object.entries(contactRenderedForm).length < 1"
          class="bg-gray-50 flex flex-row justify-center p-10 text-center"
        >
          <span class="bg-blue-400 rounded-lg p-10 w-full"
            >You Don't Have Any Forms Yet.</span
          >
        </div>

        <div
          v-for="(field, index) in contactRenderedForm"
          tag="div"
          group="universal-custom-contact-form"
          class=""
          :key="index"
        >
          <div class="relative bg-slate-500 w-full no-animation">
            <!-- FLOATING BUTTON OPTIONS - IMPLEMENT LATER -->
            <!-- <div class="absolute bottom-1 right-1 z-10 flex flex-row gap-2">
              <button
                class="btn btn-xs border border-slate-400 text-white capitalize"
              >
                <font-awesome-icon :icon="['fas', 'plus']" />
                <span class="ml-2">Option 1</span>
              </button>

              <button
                class="btn btn-xs border border-slate-400 text-white capitalize"
              >
                <font-awesome-icon :icon="['fas', 'plus']" />
                <span class="ml-2">Option 2</span>
              </button>

              <button
                class="btn btn-xs text-gray-50 rounded bg-red-500 hover:bg-red-700 border border-slate-400"
              >
                <font-awesome-icon :icon="['fas', 'trash-alt']" />
              </button>
            </div> -->
            <div
              :id="`${field.anchor}`"
              v-if="field.type === 'separator'"
              class="bg-base-100"
            >
              <div class="relative border-b border-b-slate-400 rounded p-5">
                <button
                  type="button"
                  @click="handlePopOutAndIn"
                  class="btn btn-xs md:btn-sm bg-gray-500 absolute right-2 top-2 text-gray-50 hover:bg-gray-600"
                >
                  <font-awesome-icon
                    :class="`${contactFormsStore.contactFormPoppedOutView ? 'rotate-180' : ''}`"
                    :icon="['fas', 'arrow-up-right-from-square']"
                  />
                </button>
                <h1 class="text-lg">
                  {{ field.title }}
                </h1>

                <h1 class="">
                  {{ field.description }}
                </h1>
              </div>
            </div>

            <div
              :id="`${field.script_name}`"
              v-if="field.type === 'script'"
              class="border-b bg-blue-100 p-3"
            >
              <div class="rounded p-5">
                <h1 class="text-lg text-gray-600 whitespace-pre-wrap">
                  {{ field.content }}
                </h1>
              </div>
            </div>

            <div
              v-if="field.type === 'currency'"
              class="flex flex-row items-center gap-5 p-5"
            >
              <FormKit
                type="currency"
                help-class="text-white"
                message-class="p-0.5 text-gray-50 rounded bg-rose-500 inline"
                wrapper-class="flex-1 max-w-full"
                outer-class="flex-1 m-0"
                input-class="$reset rounded-sm w-full input input-sm text-gray-700 bg-gray-50"
                :label="field.label || 'My Currency Field'"
                :help="field.help"
                :validation="field.validation"
                :validation-messages="field.validation_messages"
                v-model="currentFormData[field.name]"
                :value="field.value"
                :name="field.name"
                :min="field.min_attribute || 0"
                :max="field.max_attribute"
                :step="field.step"
                :currency="field.currency"
                :display-locale="field.locale"
                :decimals="field.decimals"
                :minDecimals="field.min_decimals"
              />
            </div>
            <div
              v-if="field.type === 'text'"
              class="flex flex-row items-center gap-5 p-5"
            >
              <FormKit
                type="text"
                help-class="text-white"
                message-class="p-0.5 text-gray-50 rounded bg-rose-500 inline"
                wrapper-class="flex-1 max-w-full"
                outer-class="flex-1 m-0"
                input-class="$reset rounded-sm w-full input input-sm text-gray-700 bg-gray-50"
                :label="field.label || 'Text'"
                :help="field.help"
                :placeholder="field.placeholder"
                :validation="regexStringToRegexObj(field.validation)"
                :validation-messages="field.validation_messages"
                v-model="currentFormData[field.name]"
                :value="field.value"
                :name="field.name"
              />
            </div>
            <div
              v-if="field.type === 'textarea'"
              class="flex flex-row items-center gap-5 p-5"
            >
              <FormKit
                type="textarea"
                help-class="text-white"
                message-class="p-0.5 text-gray-50 rounded bg-rose-500 inline"
                wrapper-class="flex-1 max-w-full"
                outer-class="flex-1 m-0"
                input-class="$reset rounded-sm w-full textarea-lg text-gray-700 bg-gray-50 p-2"
                :label="field.label || 'Textarea'"
                :help="field.help"
                :placeholder="field.placeholder"
                :validation="field.validation"
                :validation-messages="field.validation_messages"
                v-model="currentFormData[field.name]"
                :value="field.value"
                :name="field.name"
              />
            </div>
            <div
              v-if="field.type === 'number'"
              class="flex flex-row items-center gap-5 p-5"
            >
              <FormKit
                type="number"
                help-class="text-white"
                message-class="p-0.5 text-gray-50 rounded bg-rose-500 inline"
                wrapper-class="flex-1 max-w-full"
                outer-class="flex-1 m-0"
                input-class="$reset rounded-sm w-full input input-sm text-gray-700 bg-gray-50"
                :label="field.label || 'Number'"
                :help="field.help"
                :placeholder="field.placeholder"
                :validation="[['number'], ...field.validation]"
                :validation-messages="field.validation_messages"
                v-model="currentFormData[field.name]"
                :value="field.value"
                :name="field.name"
                :min="field.min_attribute"
                :step="field.step"
                :max="field.max_attribute"
              />
            </div>
            <div
              v-if="field.type === 'email'"
              class="flex flex-row items-center gap-5 p-5"
            >
              <FormKit
                type="email"
                help-class="text-white"
                message-class="p-0.5 text-gray-50 rounded bg-rose-500 inline"
                wrapper-class="flex-1 max-w-full"
                outer-class="flex-1 m-0"
                input-class="$reset rounded-sm w-full input input-sm text-gray-700 bg-gray-50"
                :label="field.label || 'Email'"
                :help="field.help"
                :placeholder="field.placeholder"
                :validation="[['email'], ...field.validation]"
                :validation-messages="field.validation_messages"
                v-model="currentFormData[field.name]"
                :value="field.value"
                :name="field.name"
              />
            </div>
            <div
              v-if="field.type === 'tel'"
              class="flex flex-row items-center gap-5 p-5"
            >
              <FormKit
                type="tel"
                help-class="text-white"
                message-class="p-0.5 text-gray-50 rounded bg-rose-500 inline"
                wrapper-class="flex-1 max-w-full"
                outer-class="flex-1 m-0"
                input-class="$reset rounded-sm w-full input input-sm text-gray-700 bg-gray-50"
                :label="field.label || 'Phone'"
                :help="field.help"
                :placeholder="field.placeholder"
                :validation="[['matches', /^\d{10}$/], ...field.validation]"
                :validation-messages="{
                  matches: 'Must be a 10 digit number',
                }"
                v-model="currentFormData[field.name]"
                :value="field.value"
                :name="field.name"
              />
            </div>
            <div
              v-if="field.type === 'toggle'"
              class="flex flex-row items-center gap-5 p-5"
            >
              <FormKit
                type="toggle"
                help-class="text-white"
                message-class="p-0.5 text-gray-50 rounded bg-rose-500 inline"
                wrapper-class="flex-1 max-w-full"
                outer-class="flex-1 m-0"
                input-class="$reset rounded-sm w-full input input-sm text-gray-700 bg-gray-50"
                :label="field.label || 'My Toggle'"
                :help="field.help"
                :validation="field.validation"
                :validation-messages="field.validation_messages"
                v-model="currentFormData[field.name]"
                :value="field.value"
                :name="field.name"
                :on-value="field.on_value"
                :off-value="field.off_value"
              />
            </div>
            <div
              v-if="field.type === 'date'"
              class="flex flex-row items-center gap-5 p-5"
            >
              <FormKit
                type="date"
                help-class="text-white"
                message-class="p-0.5 text-gray-50 rounded bg-rose-500 inline"
                wrapper-class="flex-1 max-w-full"
                outer-class="flex-1 m-0"
                input-class="rounded-sm w-full p-2 bg-base-100 text-white"
                :label="field.label || 'Date'"
                :help="field.help"
                :validation="field.validation"
                :validation-messages="field.validation_messages"
                v-model="currentFormData[field.name]"
                :value="field.value"
                :name="field.name"
                :min="field.min"
                :step="field.step"
                :max="field.max"
              />
            </div>
            <div
              v-if="field.type === 'time'"
              class="flex flex-row items-center gap-5 p-5"
            >
              <FormKit
                help-class="text-white"
                message-class="p-0.5 text-gray-50 rounded bg-rose-500 inline"
                type="time"
                wrapper-class="flex-1 max-w-full"
                outer-class="flex-1 m-0"
                input-class="$reset rounded-sm input input-sm w-full bg-base-100 text-white"
                :label="field.label || 'Time'"
                :help="field.help"
                :validation="field.validation"
                :validation-messages="field.validation_messages"
                v-model="currentFormData[field.name]"
                :value="field.value"
                :name="field.name"
                :min="field.min"
                :step="field.step"
                :max="field.max"
              />
            </div>
            <div
              v-if="field.type === 'date-picker'"
              class="flex flex-row items-center gap-5 p-5"
            >
              <FormKit
                type="datepicker"
                help-class="text-white"
                message-class="p-0.5 text-gray-50 rounded bg-rose-500 inline"
                label-class="text-gray-50"
                wrapper-class="flex-1 max-w-full text-gray-700"
                outer-class="flex-1 m-0"
                input-class="rounded-sm w-full p-2 bg-gray-50"
                open-button-class="bg-gray-50"
                format="MM/DD/YYYY"
                time-input-class="text-white"
                time-class="text-gray-600"
                :label="field.label || 'Date Picker'"
                :help="field.help"
                :validation="field.validation"
                :validation-messages="field.validation_messages"
                v-model="currentFormData[field.name]"
                :value="field.value"
                :name="field.name"
                :sequence="['day']"
                picker-only
              />
            </div>
            <div
              v-if="field.type === 'datetime-picker'"
              class="flex flex-row items-center gap-5 p-5"
            >
              <FormKit
                type="datepicker"
                help-class="text-white"
                message-class="p-0.5 text-gray-50 rounded bg-rose-500 inline"
                label-class="text-gray-50"
                wrapper-class="flex-1 max-w-full text-gray-700"
                outer-class="flex-1 m-0"
                input-class="rounded-sm w-full p-2 bg-gray-50"
                open-button-class="bg-gray-50"
                time-input-class="text-white"
                time-class="text-gray-600"
                :label="field.label || 'Datetime Picker'"
                :help="field.help"
                :validation="field.validation"
                :validation-messages="field.validation_messages"
                v-model="currentFormData[field.name]"
                :value="field.value"
                :name="field.name"
                :min="field.min_attribute"
                :step="field.step"
                :max="field.max_attribute"
                :format="{ date: 'short', time: 'short' }"
                :sequence="['day', 'time']"
                picker-only
              />
            </div>
            <div
              v-if="field.type === 'time-picker'"
              class="flex flex-row items-center gap-5 p-5"
            >
              <FormKit
                type="datepicker"
                help-class="text-white"
                message-class="p-0.5 text-gray-50 rounded bg-rose-500 inline"
                label-class="text-gray-50"
                wrapper-class="flex-1 max-w-full text-gray-700"
                outer-class="flex-1 m-0"
                input-class="rounded-sm w-full p-2 bg-gray-50"
                open-button-class="bg-gray-50"
                format="hh:mm A"
                time-input-class="text-white"
                time-class="text-gray-600"
                :help="field.help"
                :label="field.label || 'Time Picker'"
                :validation="field.validation"
                :validation-messages="field.validation_messages"
                v-model="currentFormData[field.name]"
                :value="field.value"
                :name="field.name"
                :min="field.min_attribute"
                :step="field.step"
                :max="field.max_attribute"
                :sequence="['time']"
                panel-header-class="$reset hidden"
                picker-only
              />
            </div>

            <div
              v-if="field.type === 'datetime-local'"
              class="flex flex-row items-center gap-5 p-5"
            >
              <FormKit
                help-class="text-white"
                message-class="p-0.5 text-gray-50 rounded bg-rose-500 inline"
                type="datetime-local"
                placeholder=""
                wrapper-class="flex-1 max-w-full"
                outer-class="flex-1 m-0"
                input-class="$reset rounded-sm w-full input input-sm bg-base-100 text-gray-50"
                :help="field.help"
                :label="field.label || 'Datetime Local'"
                :validation="field.validation"
                :validation-messages="field.validation_messages"
                v-model="currentFormData[field.name]"
                :value="field.value"
                :name="field.name"
                :min="field.min_attribute"
                :step="field.step"
                :max="field.max_attribute"
              />
            </div>

            <div
              v-if="field.type === 'slider'"
              class="flex flex-row items-center gap-5 p-5"
            >
              <FormKit
                type="slider"
                :label="field.label || 'Slider'"
                :validation="field.validation"
                :min="field.min"
                :step="field.step"
                :max="field.max"
                :help="field.help"
                help-class="text-white"
                message-class="p-0.5 text-gray-50 rounded bg-rose-500 inline"
                wrapper-class="w-full max-w-full"
                outer-class="flex-1 m-0 w-full max-w-full"
                inner-class="flex-1 max-w-full w-full"
                tooltip="true"
              />
            </div>
            <div
              v-if="field.type === 'dropdown'"
              class="flex flex-row items-center gap-5 p-5"
            >
              <FormKit
                type="dropdown"
                outer-class="flex-1 m-0 max-w-full"
                input-class="$reset rounded-sm w-full text-gray-700 bg-gray-50"
                listitem-class="bg-base-300 hover:bg-gray-500"
                listbox-class="border border-slate-400"
                selector-class="bg-gray-50 dropdown-lg"
                wrapper-class="w-full max-w-full"
                help-class="text-white"
                message-class="p-0.5 text-gray-50 rounded bg-rose-500 inline"
                :label="field.label || 'Dropdown'"
                :help="field.help"
                :options="field.options"
                :validation="field.validation"
                :validation-messages="field.validation_messages"
                v-model="currentFormData[field.name]"
                :value="field.value"
                :name="field.name"
              />
            </div>
            <div
              v-if="field.type === 'dropdown-multi'"
              class="flex flex-row items-center gap-5 p-5"
            >
              <FormKit
                type="dropdown"
                wrapper-class="flex-1 m-0 w-full max-w-full"
                outer-class="flex-1 m-0 w-full max-w-full"
                inner-class="w-full max-w-full"
                input-class="$reset rounded-sm w-full text-gray-700 bg-gray-50"
                listitem-class="bg-base-300 hover:bg-gray-500"
                listbox-class="border border-slate-400"
                selector-class="bg-gray-50 dropdown-lg"
                help-class="text-white"
                message-class="p-0.5 text-gray-50 rounded bg-rose-500 inline"
                :label="field.label || 'Dropdown Multi Select'"
                :help="field.help"
                :options="field.options"
                :validation="field.validation"
                :validation-messages="field.validation_messages"
                v-model="currentFormData[field.name]"
                :value="field.value"
                :name="field.name"
                multiple
              />
            </div>
            <div
              v-if="field.type === 'select'"
              class="flex flex-row items-center gap-5 p-5"
            >
              <FormKit
                type="select"
                help-class="text-white"
                message-class="p-0.5 text-gray-50 rounded bg-rose-500 inline"
                wrapper-class="flex-1 max-w-full"
                outer-class="flex-1 m-0"
                input-class="$reset rounded-sm w-full input input-sm text-gray-700 bg-gray-50"
                :label="field.label || 'Select'"
                :help="field.help"
                :validation="field.validation"
                :validation-messages="field.validation_messages"
                v-model="currentFormData[field.name]"
                :value="field.value"
                :name="field.name"
                :options="field.options"
              />
            </div>

            <!-- checkbox -->
            <div
              v-if="field.type === 'checkbox'"
              class="flex flex-row items-center gap-5 p-5"
            >
              <FormKit
                type="checkbox"
                help-class="text-white"
                message-class="p-0.5 text-gray-50 rounded bg-rose-500 inline"
                wrapper-class="flex-1 max-w-full"
                outer-class="flex-1 m-0"
                input-class="w-full text-gray-700 bg-gray-50"
                fieldset-class="w-full max-w-full"
                :label="field.label || 'Checkbox'"
                :help="field.help"
                :validation="field.validation"
                :validation-messages="field.validation_messages"
                v-model="currentFormData[field.name]"
                :value="field.value"
                :name="field.name"
                :options="field.options"
              />
            </div>

            <!-- radio -->
            <div
              v-if="field.type === 'radio'"
              class="flex flex-row items-center gap-5 p-5"
            >
              <FormKit
                :help="field.help"
                help-class="text-white"
                message-class="p-0.5 text-gray-50 rounded bg-rose-500 inline"
                type="radio"
                :label="field.label || 'Radio Buttons'"
                placeholder=""
                wrapper-class="flex-1 max-w-full"
                fieldset-class="flex-1 max-w-full w-full"
                outer-class="flex-1 m-0"
                :validation="field.validation"
                :validation-messages="field.validation_messages"
                v-model="currentFormData[field.name]"
                :value="field.value"
                :name="field.name"
                input-class="rounded-sm w-full text-gray-700 bg-gray-50"
                :options="field.options"
              />
            </div>

            <!-- color -->
            <div
              v-if="field.type === 'color'"
              class="flex flex-row items-center gap-5 p-5"
            >
              <FormKit
                :help="field.help"
                help-class="text-white"
                message-class="p-0.5 text-gray-50 rounded bg-rose-500 inline"
                type="color"
                :label="`${field.label} (${field.value})` || 'Color'"
                wrapper-class="flex-1 max-w-full rounded-full"
                outer-class="flex-1 m-0"
                inner-class="$reset flex-1 max-w-full w-full border border-slate-400 rounded"
                :validation="field.validation"
                input-class="$reset w-full h-12 rounded-full text-gray-700 bg-gray-50 max-w-full"
                v-model="currentFormData[field.name]"
                :value="field.value"
                :name="field.name"
              />
            </div>

            <div
              v-if="field.type === 'mask'"
              class="flex flex-row items-center gap-5 p-5"
            >
              <FormKit
                type="mask"
                :mask="field.mask"
                outer-class="flex-1 m-0"
                :label="field.label || 'Mask'"
                help-class="text-white"
                message-class="p-0.5 text-gray-50 rounded bg-rose-500 inline"
                wrapper-class="w-full max-w-full"
                input-class="$reset rounded-sm w-full input input-sm text-gray-700 bg-gray-50"
                :help="field.help"
                v-model="currentFormData[field.name]"
                :value="field.value"
                :name="field.name"
              />
            </div>
            <div
              v-if="field.type === 'taglist'"
              class="flex flex-row items-center gap-5 p-5"
            >
              <FormKit
                type="taglist"
                :name="field.name"
                wrapper-class="w-full max-w-full"
                outer-class="flex-1 m-0"
                :label="field.label || 'Tag List'"
                help-class="text-white"
                message-class="p-0.5 text-gray-50 rounded bg-rose-500 inline"
                selected-icon-class="text-black"
                listbox-class="border border-slate-400"
                selector-class="bg-gray-50"
                tags-class=""
                tag-label-class="text-gray-600"
                tag-class=""
                tags-wrapper-class="bg-gray-50 text-white"
                inner-class="bg-white"
                select-icon-class="chevron-down text-gray-50 rounded-r border-l border-l-slate-400"
                popover
                :close-on-select="false"
                input-class="rounded-sm w-full text-gray-700"
                dropdown-wrapper-class=""
                listitem-class="bg-base-300 focus:bg-base-300 active:bg-blue-700 pl-7 hover:border-y-2 hover:border-r-2 hover:border-r-white hover:border-y-white"
                option-class="bg-base-300 hover:border-y hover: border-r hover:border-r-white hover:border-y-white"
                :options="field.options"
                :filter="
                  (option, search) =>
                    option.label.toLowerCase().startsWith(search.toLowerCase())
                "
                :help="field.help"
                :validation="field.validation"
                :validation-messages="field.validation_messages"
                v-model="currentFormData[field.name]"
                :value="field.value"
              />
            </div>
          </div>
        </div>
      </FormKit>
    </div>
    <!-- GROUP LINKS -->
    <div class="flex flex-col w-72 max-w-72 h-full bg-base-100">
      <div
        class="bg-base-100 p-2 flex flex-col gap-2 sticky top-0 z-20 border-b border-b-slate-400"
      >
        <div class="flex flex-row">
          <button
            type="button"
            @click="showCompanyContactForms = true"
            class="btn btn-block rounded capitalize bg-purple-500 hover:bg-purple-700 border-none text-white ml-auto"
          >
            {{ getCurrentForm?.name }}
          </button>
        </div>
        <div class="flex flex-row gap-2">
          <button
            @click="universalCustomFormSubmitButton"
            class="btn rounded capitalize bg-emerald-500 hover:bg-emerald-700 border-none text-white rouned-none flex-1"
          >
            <div class="flex flex-row items-center">
              <span class="md:absolute md:left-5">
                <font-awesome-icon
                  :icon="['fas', 'save']"
                  class="ml-auto"
                  size="xl"
                />
              </span>
              <div class="max-sm:hidden flex flex-col divide-y gap-0.5">
                <span class="mr-2">Save</span>
                <span class="text-xs">Press Enter</span>
              </div>
            </div>

            <!-- save icon -->
          </button>
        </div>
      </div>
      <a
        v-for="(group, groupIndex) in getSectionLinks"
        :key="groupIndex"
        @click="scrollToElement(group.anchor)"
        class="bg-white capitalize p-1 md:p-3 text-xs md:text-base rounded-none text-gray-700 hover:bg-indigo-400 hover:cursor-pointer hover:text-white border-b border-x-0 border-t-0 border-slate-300"
        >{{ group.title }}</a
      >

      <!-- <pre>
 {{ rebuttalButtons }}

      </pre>
      <pre>
        {{ contactFormsStore.currentFormData }}
      </pre>
      <pre>
        {{ contactRenderedForm }}
      </pre> -->
      <!-- <pre>
        {{ transformArrayToObject }}
      </pre> -->
    </div>
  </div>
</template>
