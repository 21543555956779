<script setup>
defineOptions({
  name: 'InstallPWA', // Give the component a name
});
import { ref, onMounted, useGlobalToastsStore } from "../clientImports";
const toastsStore = useGlobalToastsStore();

const installEvent = ref(null);
const os = ref("");

const instructionPrompt = ref(false); // show "add to home screen" instructions then trigger share API
const showDismiss = ref(false);
const showInstallMessage = ref(false); // State to control pop-up visibility

window.addEventListener("beforeinstallprompt", (e) => {
  e.preventDefault();
  installEvent.value = e;
});

// fires when the user clicks the button - !iphone/ios
const showPrompt = () => {
  if (installEvent.value) {
    installEvent.value.prompt();
    installEvent.value.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === "accepted") {
        console.log("User accepted the install prompt");
      } else {
        console.log("User dismissed the install prompt");
      }
    });
  }
};

const showToast = () => {
  toastsStore.addToast({
    title: "Device OS",
    description: os.value,
    buttonExists: false,
    color: "orange",
    type: 1,
  });
};

// reads user agent string and finds OS
const getOS = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  if (/android/.test(userAgent)) return "android";
  if (/iphone|ipad/.test(userAgent)) return "ios";
  if (/mac/.test(userAgent)) return "macos";
  if (/windows/.test(userAgent)) return "windows";
  return "unknown";
};

const formatDeviceName = (osName) => {
  if (/android/.test(osName)) return "Android";
  if (/ios/.test(osName)) return "iOS";
  if (/mac/.test(osName)) return "MacOS";
  if (/windows/.test(osName)) return "Windows";
  return "Download App";
};

// checks if the app is running in standalone mode
const checkIosStandalone = () => {
  if (
    (os.value === "android" || os.value === "ios" || os.value === "macos") &&
    !("standalone" in window.navigator && window.navigator.standalone)
  ) {
    console.log("Device: ", os.value);
    showInstallMessage.value = true;
  }
};

// on mount, run getOS to store the OS in the os variable
onMounted(() => {
  os.value = getOS();

  console.log("OS: ", os.value);

  // check if the app is running in standalone mode
  checkIosStandalone();
});

const showInstructions = () => {
  instructionPrompt.value = true;
};
</script>

<template>
  <button
    type="button"
    @click.stop="
      os === 'ios' || os === 'iphone' || os === 'ipad' || os === 'macos'
        ? showInstructions()
        : showPrompt()
    "
    class="px-2 py-1 text-white rounded gradient-animation border-none"
  >
    <font-awesome-icon
      icon="fa-brands fa-apple"
      size="lg"
      v-if="os === 'ios' || os === 'iphone' || os === 'ipad' || os === 'macos'"
    />

    <font-awesome-icon
      icon="fa-brands fa-android"
      size="lg"
      v-else-if="os === 'android'"
    />

    <font-awesome-icon
      icon="fa-brands fa-windows"
      size="lg"
      v-else-if="os === 'windows'"
    />

    <font-awesome-icon icon="fa-solid fa-circle-down" v-else />

    <span class="ml-3">{{ formatDeviceName(os) }}</span>
  </button>

  <transition
    enter-active-class="transition-opacity duration-500 ease-in-out"
    enter-from-class="opacity-0"
    enter-to-class="opacity-100"
    leave-active-class="transition-opacity duration-500 ease-in-out"
    leave-from-class="opacity-100"
    leave-to-class="opacity-0"
  >
    <div
      v-if="instructionPrompt"
      class="fixed backdrop-blur-sm top-0 left-0 h-screen w-screen flex flex-col items-center justify-center"
    >
      <div
        class="bg-base-100 p-5 rounded-xl text-white leading-loose border border-slate-400"
      >
        <div class="text-center">On Your Apple Device</div>
        <div class="">
          <ol class="list-decimal list-inside">
            <li>
              Tap the
              <span class="text-blue-300 italic">"Share" </span>icon:
              <span class="text-white">
                <font-awesome-icon icon="fa-solid fa-arrow-up-from-bracket" />
              </span>
            </li>
            <li>
              Select

              <span class="text-blue-300 italic"> 'Add to Home Screen' </span>
            </li>
            <li>Launch FinnyPi from your devices Home Screen</li>
          </ol>
        </div>
        <div class="mt-4">
          <button
            type="button"
            @click="instructionPrompt = false"
            class="btn btn-sm btn-block bg-rose-500 capitalize border-none text-white"
          >
            Dismiss
          </button>
        </div>
      </div>
    </div>
  </transition>

  <!-- hidden test os -->
  <div @click="showToast()" class="p-3 fixed top-0 left-0"></div>
</template>
