<script setup>
defineOptions({
  name: 'cTermsViolation', // Give the component a name
});
import {
  //axios
  axios,
  ref,
  useGlobalToastsStore,
  useLoginStore,

  //vue-router
  useRouter,
  useRoute,

  //base api url
  BASE_API_URL,
} from "./../clientImports";

// Call the useLoginStore function to get an instance of the store
const loginStore = useLoginStore();

const toastsStore = useGlobalToastsStore();

const router = useRouter();
const route = useRoute();

// TODO - un commment these and delete static credentials before launch
// const companyName = ref("");
// const email = ref("");
// const password = ref("");

const makePayment = ref(false);

const companyName = ref("finpi");
const email = ref("nick@finnypi.app");
const password = ref("finnypi123!");

const login = async () => {
  try {
    const response = await axios.post(`${BASE_API_URL}/login`, {
      companyName: companyName.value.toLowerCase(),
      email: email.value.toLowerCase(),
      password: password.value,
    });

    const sessionData = response.data;

    // xLog("Sesssion Data: ", sessionData);
    // xLog("Login Response Status: ", response.data.status);

    if (response.data.status === "success") {
      // use the loginStore to set the user data
      loginStore.setSessionData(sessionData);

      // show toast
      toastsStore.addToast({
        title: "Login Successful",
        description: `Welcome ${loginStore.sessionData.foundUser.first_name}! 😃`,
        buttonExists: false,
        color: "emerald",
        type: 1, // 1 = small, 2 = md, 3 = lg, 4 = lg dismissable
      });
      router.push({ name: "dashboard-tab" });
    }
  } catch (error) {
    console.log(error);
  }
};
</script>

<template>
  <div
    class="flex flex-col justify-center items-center h-screen bg-gradient-to-br from-red-300 to-red-600"
  >
    <!-- Form -->

    <div class="bg-white p-10 rounded w-full md:w-96 border border-red-500">
      <div class="w-full text-center">
        <cSystemLogo></cSystemLogo>
      </div>
      <h3
        class="text-lg text-center text-white mb-8 bg-gradient-to-br from-red-300 to-red-600 bg- mt-2"
      >
        Account Suspended: <br />
        Violation of Terms
      </h3>

      <div class="text-center text-gray-500 p-3">
        <h4>Contact Support</h4>
      </div>

      <div class="grid grid-cols-2 flex-row justify-center items-center gap-5">
        <a
          href="mailto:support@finnypi.com"
          class="bg-gradient-to-br from-red-300 to-red-600 rounded text-white btn btn-md capitalize border-none"
        >
          Email
        </a>
        <button
          class="bg-gradient-to-br from-purple-300 to-purple-600 rounded text-white btn btn-md capitalize border-none"
        >
          Chat
        </button>
        <!-- router link to login page -->
        <router-link
          to="/login"
          class="bg-gradient-to-br from-indigo-300 to-indigo-600 rounded text-white btn btn-md capitalize border-none"
        >
          Sign In
        </router-link>
        <!-- router link to website page -->
        <router-link
          to="/"
          class="bg-base-100 rounded text-white btn btn-md capitalize border-none"
        >
          Website
        </router-link>
      </div>

      <FormKit
        v-if="makePayment"
        type="form"
        submit-label="Login"
        actions-class="mt-8"
        :actions="false"
        @submit="login"
        :submit-attrs="{
          wrapperClass: 'flex flex-row justify-between',
          inputClass:
            '$reset bg-blue-500 w-full max-w-full rounded py-3 px-2 text-white',
          ignore: false,
        }"
      >
        <FormKit
          label-class="text-gray-700"
          inner-class="mt-2 mb-2"
          label="Company Abbreviation"
          type="text"
          input-class="bg-base-300 text-white w-full"
          validation="required|length:3"
          :validation-messages="{ length: '3 Character Minimum' }"
          messages-class="relative"
          message-class="absolute -top-5 right-2 bg-red-500 rounded text-xs text-white p-1"
          v-model="companyName"
        />

        <FormKit
          label-class="text-gray-700"
          inner-class="mt-2 mb-2"
          label="Email"
          type="text"
          input-class="bg-base-300 text-white w-full"
          validation="required|email"
          :validation-messages="{ email: 'Invalid Email Format' }"
          messages-class="relative"
          message-class="absolute -top-5 right-2 bg-red-500 rounded text-xs text-white p-1"
          v-model="email"
        />

        <FormKit
          label-class="text-gray-700"
          inner-class="mt-2 mb-2"
          label="Password"
          type="text"
          input-class="bg-base-300 text-white w-full"
          validation="required|length:6"
          :validation-messages="{ length: '6 Character Minimum' }"
          messages-class="relative"
          message-class="absolute -top-5 right-2 bg-red-500 rounded text-xs text-white p-1"
          v-model="password"
        />

        <FormKit
          type="submit"
          label="Process Payment"
          :ingore="false"
          input-class="$reset bg-gradient-to-br from-emerald-300 to-emerald-600 w-full max-w-full rounded py-3 px-2 text-white mt-7"
        />
      </FormKit>
    </div>
  </div>
</template>
