<script setup>
defineOptions({
  name: 'cColumnSelector', // Give the component a name
});
import {
  ref,
  Teleport,
  toRefs,

  //vuedraggable
  draggable,
  useGlobalToastsStore,
  FontAwesomeIcon,
  tableHeaderFormatting,
} from "./../clientImports";

const toastsStore = useGlobalToastsStore();

const props = defineProps({
  sortAndFilterRules: {
    type: Array,
    required: true,
  },

  applySortAndFilterRules: {
    type: Function,
    required: true,
  },
});

const {} = toRefs(props);

const columnSelectorRules = ref([...props.sortAndFilterRules]);

const showDropdown = ref(false);

const toggleDropdown = () => {
  showDropdown.value = !showDropdown.value;
};

const applySortAndFilterRules = (rules) => {
  // Check if at least one column is selected (true)
  const isAnySelected = rules.some((item) => item.value === true);

  if (isAnySelected) {
    props.applySortAndFilterRules(rules);

    toastsStore.addToast({
      title: "Custom Columns Applied",
      description: "Successfully applied sort and filter rules",
      buttonExists: false,
      color: "emerald",
      type: 1, // 1 = small, 2 = md, 3 = lg, 4 = lg dismissable
    });

    toggleDropdown();
  } else {
    toastsStore.addToast({
      title: "No Columns Selected",
      description: "Please select and sort your columns",
      buttonExists: false,
      color: "red",
      type: 1, // 1 = small, 2 = md, 3 = lg, 4 = lg dismissable
    });
  }
};

const selectAllCols = () => {
  const areAllSelected = columnSelectorRules.value.every((item) => item.value);

  columnSelectorRules.value.forEach((item) => {
    item.value = !areAllSelected;
  });
};
</script>

<template>
  <button
    @click="toggleDropdown"
    class="btn btn-xs md:btn-sm capitalize bg-purple-500 hover:bg-purple-700 text-white rounded text-xs md:text-base border-none"
  >
    <font-awesome-icon icon="fa-solid fa-table-columns" />
    <span class="ml-2 hidden md:block"> Manage Columns</span>
  </button>

  <Teleport to="#modals-target">
    <transition
      enter-active-class="transition-opacity duration-300 ease-in-out"
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="transition-opacity duration-300 ease-in-out"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div
        v-if="showDropdown"
        @click="toggleDropdown"
        class="fixed left-0 top-0 z-70 w-screen h-screen backdrop-blur-sm flex flex-col justify-center items-center"
      ></div>
    </transition>

    <transition
      enter-active-class="transition-all duration-300 ease-in-out"
      enter-from-class="-translate-x-full"
      enter-to-class="translate-x-0"
      leave-active-class="transition-all duration-300 ease-in-out"
      leave-from-class="translate-x-0"
      leave-to-class="-translate-x-full"
    >
      <div
        v-if="showDropdown"
        @click.stop
        class="z-70 fixed top-0 left-0 bg-base-100 border-r border-slate-400 w-full h-full max-h-full flex-1 md:w-1/4"
      >
        <div
          class="sticky top-0 p-2 flex flex-row justify-between items-center"
        >
          <h1 class="text-white">Manage Columns</h1>
          <div class="flex flex-row items-center gap-2">
            <p class="text-xs p-2 bg-purple-500 text-white">
              {{
                columnSelectorRules.filter((item) => item.value === true).length
              }}
              Selected
            </p>
            <button
              @click="toggleDropdown"
              class="btn btn-xs md:btn-sm text-white"
            >
              <font-awesome-icon icon="fa-solid fa-times" />
            </button>
          </div>
        </div>
        <!-- selector body -->
        <div class="flex flex-col h-full justify-between">
          <div
            class="overflow-y-auto max-h-full flex flex-col justify-between pb-10"
          >
            <draggable
              class="flex flex-col gap-3 p-2"
              handle=".drag-handle"
              v-model="columnSelectorRules"
            >
              <template #item="{ element, index }">
                <div :key="element.label">
                  <div
                    class="bg-purple-500 rounded flex flex-row gap-2 max-h-full overflow-y-auto items-center p-2"
                  >
                    <input
                      :id="element.label"
                      type="checkbox"
                      class="checkbox checkbox-primary bg-white border-none"
                      v-model="element.value"
                    />
                    <label
                      :for="element.label"
                      class="text-white flex-1 capitalize"
                      >{{ tableHeaderFormatting(element.label) }}</label
                    >
                    <div
                      class="drag-handle text-white cursor-pointer hover:bg-gray-400 rounded py-1 px-2"
                    >
                      <font-awesome-icon icon="fa-solid fa-grip-lines" />
                    </div>
                  </div>
                </div>
              </template>
            </draggable>
          </div>
          <!-- selector footer -->
          <div
            class="sticky bottom-0 p-2 flex flex-row justify-between bg-base-100"
          >
            <!-- cancel button -->
            <button
              @click="toggleDropdown"
              class="btn btn-xs md:btn-sm bg-red-500 hover:bg-red-700 text-white border-none rounded"
            >
              Cancel
            </button>
            <button
              @click="selectAllCols"
              class="btn btn-xs md:btn-sm bg-blue-500 hover:bg-blue-700 text-white border-none rounded"
            >
              Select All
            </button>

            <button
              @click="applySortAndFilterRules(columnSelectorRules)"
              class="btn btn-xs md:btn-sm bg-emerald-500 hover:bg-emerald-700 text-white border-none rounded"
            >
              Apply
            </button>
          </div>
        </div>
      </div>
    </transition>
  </Teleport>
</template>
