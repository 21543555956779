<script setup>
defineOptions({
  name: 'TimeClock', // Give the component a name
});
import {
  //axios
  axios,

  //vue
  createApp,
  ref,
  reactive,
  watch,
  computed,
  inject,
  provide,
  onMounted,
  onUnmounted,
  nextTick,
  Teleport,

  //pinia
  defineStore,
  createPinia,
  storeToRefs,

  //pinia stores
  useChatStore,
  useContactAlertsStore,
  useContactFileStore,
  useContactsStore,
  useGlobalPromptsStore,
  useGlobalToastsStore,
  useLoginStore,
  useTenantsStore,

  //vue-router
  useRouter,
  useRoute,

  //formkit

  //formkit config file

  //fontawesome
  library,
  FontAwesomeIcon,
  fas,
  far,
  fab,

  //global components - all vue components
  registerGlobalComponents,

  //base api url
  BASE_API_URL,
} from "./../clientImports";

const loginStore = useLoginStore();

const clockPunches = reactive([
  { punch: "Clock In", type: "Start Day" },
  { punch: "Clock Out", type: "Break 1 Start" },
  { punch: "Clock In", type: "Break 1 End" },
  { punch: "Clock Out", type: "Lunch Start" },
  { punch: "Clock In", type: "Lunch End" },
  { punch: "Clock Out", type: "Break 2 Start" },
  { punch: "Clock In", type: "Break 2 End" },
  { punch: "Clock Out", type: "End Day" },
]);

const punchIndex = ref(0);

const punch = (punchValue) => {
  if (punchValue) {
    punchIndex.value = punchValue;
  } else {
    punchIndex.value++;
  }
};

const showTimeclockSidebar = ref(false);

const toggleTimeclockSidebar = () => {
  showTimeclockSidebar.value = !showTimeclockSidebar.value;
};
</script>

<template>
  <div v-if="punchIndex < 8" class="dropdown dropdown-bottom dropdown-end">
    <cSidebarModal
      v-if="
        loginStore?.sessionData?.permissions?.includes('view:timeclock tab') ||
        loginStore?.sessionData?.permissions?.includes('*:dev-permissions')
      "
      sidebar-position="right"
      :toggle-sidebar-modal="toggleTimeclockSidebar"
      :show-sidebar-modal="showTimeclockSidebar"
      toggle-button-color="gray"
    >
      <template #toggle-button>
        <div class="flex flex-row items-center">
          <font-awesome-icon icon="fa-solid fa-clock" /><span
            class="hidden ml-2 lg:block"
            >Timeclock</span
          >
        </div>
      </template>
      <template #title> Timeclock </template>

      <template #body>
        <div class="flex flex-col gap-3">
          <div
            class="flex flex-row items-center justify-between flex-1 p-2 text-white border rounded cursor-pointer border-slate-400 border-slate- hover:bg-slate-600"
            @click="punch()"
          >
            <p>{{ clockPunches[punchIndex].type }}</p>
            <div
              class="p-2 text-white rounded"
              :class="punchIndex % 2 === 0 ? 'bg-green-500' : 'bg-red-500'"
            >
              {{ clockPunches[punchIndex].punch }}
            </div>
          </div>
        </div>
      </template>
      <template #footer>
        <div
          v-if="punchIndex > 0 && punchIndex < 7"
          class="flex flex-row items-center justify-between flex-1 p-2 text-white border rounded cursor-pointer border-slate-400 border-slate- hover:bg-slate-600"
          @click="punch(8)"
        >
          <p>{{ clockPunches[7].type }}</p>
          <div class="p-2 text-white bg-red-500 rounded">
            {{ clockPunches[7].punch }}
          </div>
        </div>
      </template>
    </cSidebarModal>
  </div>
</template>
