<script setup>
defineOptions({
  name: 'SettingsTemplatesSmsCreate', // Give the component a name
});
import {
  //axios
  axios,

  //vue
  createApp,
  ref,
  reactive,
  watch,
  computed,
  inject,
  provide,
  onMounted,
  onUnmounted,
  nextTick,
  Teleport,

  //pinia
  defineStore,
  createPinia,
  storeToRefs,

  //pinia stores
  useChatStore,
  useContactAlertsStore,
  useContactFileStore,
  useContactsStore,
  useGlobalPromptsStore,
  useGlobalToastsStore,
  useLoginStore,
  useTenantsStore,
  useUsersStore,
  useTenantsTemplatesSmsStore,
  useTenantsPhoneNumbersStore,

  //vue-router
  useRouter,
  useRoute,

  //formkit

  //formkit config file
  FKsubmitForm,

  //fontawesome
  library,
  FontAwesomeIcon,
  fas,
  far,
  fab,

  //centralized data
  usersLevelOptions,
  fieldsDefaultValidation,
  stateOptions,

  //utilities
  getRandomNumberBetween,

  //helpers
  registerGlobalComponents,

  //utilities
  stripEmptyFields,

  //base api url
  BASE_API_URL,
} from "./../clientImports";

import {
  browserTimezone,
  timezoneOptions,
} from "./../centralized_data/timezoneOptions";

const router = useRouter();
const route = useRoute();

const loginStore = useLoginStore();

const usersStore = useUsersStore();
const toastsStore = useGlobalToastsStore();
const promptsStore = useGlobalPromptsStore();
const tenantsTemplatesSmsStore = useTenantsTemplatesSmsStore();
const tenantsPhoneNumbersStore = useTenantsPhoneNumbersStore();

//use FKsubmitForm to submit form
const submitCreateForm = () => {
  return FKsubmitForm("create-templates-sms");
};

onMounted(async () => {
  await tenantsPhoneNumbersStore.fetchTenantsPhoneNumbers(); //initial populate affiliate list
});

const phoneNumberOptions = computed(() => {
  return tenantsPhoneNumbersStore.tenantsPhoneNumbersList.map((phoneNumber) => {
    return {
      value: phoneNumber.id,
      //format number like this xxx-xxx-xxxx
      label:
        phoneNumber.phone_number.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3") +
        " | " +
        phoneNumber.description,
    };
  });
});

const liveBodyCharacterCount = computed(() => {
  // This counts all characters in the string, including spaces
  return tenantsTemplatesSmsStore.currentlyCreatingTemplatesSms.body.length;
});
</script>

<template>
  <cSidebarModal
    sidebar-position="right"
    :show-sidebar-initial="false"
    :show-sidebar-modal="
      tenantsTemplatesSmsStore.showTenantsTemplatesSmsCreateModal
    "
    :toggle-sidebar-modal="
      tenantsTemplatesSmsStore.toggleTenantsTemplatesSmsCreateModal
    "
    :default-button="false"
  >
    <template #title>
      <h2>Create SMS Template</h2>
    </template>

    <template #body>
      <FormKit
        id="create-templates-sms"
        type="form"
        :incomplete-message="false"
        submit-label="Create"
        form-class="text-white h-full flex flex-col justify-between"
        @submit="tenantsTemplatesSmsStore.createTenantsTemplatesSms()"
        outer-class="m-0"
        :actions="false"
        :submit-attrs="{
          'data-theme': `light`,
          ignore: true,
        }"
      >
        <div class="grid grid-cols-1 gap-5">
          <div class="flex flex-row items-center gap-5">
            <FormKit
              type="text"
              label="Name"
              placeholder=""
              :validation="'required:trim|length:5, 40'"
              help-class="text-white"
              help=""
              outer-class="m-0 flex-1"
              input-class="$reset rounded w-full input input-sm text-gray-700 bg-gray-50"
              wrapper-class="max-w-full"
              v-model.trim="
                tenantsTemplatesSmsStore.currentlyCreatingTemplatesSms.name
              "
            />

            <FormKit
              outer-class="col-span-1"
              type="toggle"
              name="toggle"
              label="Active"
              alt-label-position
              v-model="
                tenantsTemplatesSmsStore.currentlyCreatingTemplatesSms.active
              "
            />
          </div>

          <FormKit
            type="textarea"
            label="Description"
            placeholder=""
            :validation="'trim|length:1, 255'"
            help-class="text-white"
            help=""
            outer-class="m-0 "
            input-class="$reset rounded w-full input input-sm text-gray-700 bg-gray-50"
            wrapper-class="max-w-full"
            v-model.trim="
              tenantsTemplatesSmsStore.currentlyCreatingTemplatesSms.description
            "
          />
          <div class="flex flex-row items-center gap-5">
            <FormKit
              type="select"
              label="From Number"
              placeholder=""
              help-class="text-white"
              help=""
              outer-class="m-0 flex-1"
              input-class="$reset rounded w-full input input-sm text-gray-700 bg-gray-50"
              wrapper-class="max-w-full"
              v-model.trim="
                tenantsTemplatesSmsStore.currentlyCreatingTemplatesSms
                  .from_number
              "
              :options="[...phoneNumberOptions]"
            />

            <FormKit
              type="tel"
              label="To Number"
              placeholder=""
              :validation="[
                ['required'],
                ['matches', /^(\d{10}|\{.*\})$/], // Updated regex for exactly 10 digits
              ]"
              help-class="text-white"
              help=""
              outer-class="m-0 flex-1"
              input-class="$reset rounded w-full input input-sm text-gray-700 bg-gray-50"
              wrapper-class="max-w-full"
              v-model.trim="
                tenantsTemplatesSmsStore.currentlyCreatingTemplatesSms.to_number
              "
            />
          </div>

          <FormKit
            v-auto
            type="textarea"
            label="Message Body"
            placeholder=""
            :validation="'trim|length:1, 1599'"
            help-class="text-white"
            help=""
            rows="5"
            outer-class="m-0 "
            input-class="$reset rounded w-full input input-sm text-gray-700 bg-gray-50"
            wrapper-class="max-w-full"
            v-model.trim="
              tenantsTemplatesSmsStore.currentlyCreatingTemplatesSms.body
            "
            :value="tenantsTemplatesSmsStore.currentlyCreatingTemplatesSms.body"
          />
          <p>
            {{ liveBodyCharacterCount }}
            Characters -
            {{
              liveBodyCharacterCount / 160 < 1
                ? 1
                : (liveBodyCharacterCount / 160).toFixed(0)
            }}
            Segment{{
              (liveBodyCharacterCount / 160 < 1
                ? 1
                : (liveBodyCharacterCount / 160).toFixed(0)) > 1
                ? "s"
                : ""
            }}
          </p>
        </div>
      </FormKit>
    </template>

    <template #footer>
      <!-- BUTTON FOOTER - CANCEL & UPDATE TemplatesSms -->
      <div class="flex flex-row justify-between text-white w-full">
        <button
          @click="tenantsTemplatesSmsStore.toggleTenantsTemplatesSmsCreateModal"
          class="btn btn-xs md:btn-sm rounded bg-red-500 text-white hover:bg-red-700"
        >
          Cancel
        </button>
        <button
          @click="submitCreateForm"
          class="btn btn-xs md:btn-sm rounded bg-emerald-500 text-white hover:bg-emerald-700"
        >
          Create SMS Template
        </button>
      </div>
    </template>
  </cSidebarModal>
</template>
