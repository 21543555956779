<script setup>
defineOptions({
  name: 'SettingsTemplatesNotesCreate', // Give the component a name
});
import {
  //axios
  axios,

  //vue
  createApp,
  ref,
  reactive,
  watch,
  computed,
  inject,
  provide,
  onMounted,
  onUnmounted,
  nextTick,
  Teleport,

  //pinia
  defineStore,
  createPinia,
  storeToRefs,

  //pinia stores
  useChatStore,
  useContactAlertsStore,
  useContactFileStore,
  useContactsStore,
  useGlobalPromptsStore,
  useGlobalToastsStore,
  useLoginStore,
  useTenantsStore,
  useUsersStore,
  useTenantsTemplatesNotesStore,

  //vue-router
  useRouter,
  useRoute,

  //formkit

  //formkit config file
  FKsubmitForm,

  //fontawesome
  library,
  FontAwesomeIcon,
  fas,
  far,
  fab,

  //centralized data
  usersLevelOptions,
  fieldsDefaultValidation,
  stateOptions,

  //utilities
  getRandomNumberBetween,

  //helpers
  registerGlobalComponents,

  //utilities
  stripEmptyFields,

  //base api url
  BASE_API_URL,
  Editor,
} from "./../clientImports";

import {
  browserTimezone,
  timezoneOptions,
} from "./../centralized_data/timezoneOptions";

const router = useRouter();
const route = useRoute();

const loginStore = useLoginStore();

const usersStore = useUsersStore();
const toastsStore = useGlobalToastsStore();
const promptsStore = useGlobalPromptsStore();
const tenantsTemplatesNotesStore = useTenantsTemplatesNotesStore();

//use FKsubmitForm to submit form
const submitCreateForm = () => {
  tenantsTemplatesNotesStore.currentlyCreatingTemplatesNote.note = tinymce
    .get("notes-templates-create")
    .getContent();

  return FKsubmitForm("create-templates-notes");
};
</script>

<template>
  <cSidebarModal
    sidebar-position="right"
    :show-sidebar-initial="false"
    :show-sidebar-modal="
      tenantsTemplatesNotesStore.showTenantsTemplatesNotesCreateModal
    "
    :toggle-sidebar-modal="
      tenantsTemplatesNotesStore.toggleTenantsTemplatesNotesCreateModal
    "
    :default-button="false"
  >
    <template #title>
      <h2>Create Note Template</h2>
    </template>

    <template #body>
      <FormKit
        id="create-templates-notes"
        type="form"
        :incomplete-message="false"
        submit-label="Create"
        form-class="text-white h-full flex flex-col justify-between"
        @submit="tenantsTemplatesNotesStore.createTenantsTemplatesNote()"
        outer-class="m-0"
        :actions="false"
        :submit-attrs="{
          'data-theme': `light`,
          ignore: true,
        }"
      >
        <div class="grid grid-cols-4 gap-5 pb-5">
          <FormKit
            type="text"
            label="Name"
            placeholder=""
            :validation="'required:trim|length:5, 40'"
            help-class="text-white"
            help=""
            outer-class="m-0 col-span-3"
            input-class="$reset rounded w-full input input-sm text-gray-700 bg-gray-50"
            wrapper-class="max-w-full"
            v-model.trim="
              tenantsTemplatesNotesStore.currentlyCreatingTemplatesNote.name
            "
          />

          <FormKit
            outer-class="col-span-1"
            type="toggle"
            name="toggle"
            label="Active"
            alt-label-position
            v-model="
              tenantsTemplatesNotesStore.currentlyCreatingTemplatesNote.active
            "
            :value="
              tenantsTemplatesNotesStore.currentlyCreatingTemplatesNote.active
            "
          />

          <FormKit
            type="textarea"
            label="Description"
            placeholder=""
            :validation="'trim|length:1, 255'"
            help-class="text-white"
            help=""
            outer-class="m-0 col-span-4"
            input-class="$reset rounded w-full input input-sm text-gray-700 bg-gray-50"
            wrapper-class="max-w-full"
            v-model.trim="
              tenantsTemplatesNotesStore.currentlyCreatingTemplatesNote
                .description
            "
          />

          <cTinyEditor
            editorId="templates-notes-create"
            propHeight="500px"
            propWidth="100%"
          />
        </div>
      </FormKit>
    </template>

    <template #footer>
      <!-- BUTTON FOOTER - CANCEL & UPDATE TemplatesNote -->
      <div class="flex flex-row justify-between text-white w-full">
        <button
          @click="
            tenantsTemplatesNotesStore.toggleTenantsTemplatesNotesCreateModal
          "
          class="btn btn-xs md:btn-sm rounded bg-red-500 text-white hover:bg-red-700"
        >
          Cancel
        </button>
        <button
          @click="submitCreateForm"
          class="btn btn-xs md:btn-sm rounded bg-emerald-500 text-white hover:bg-emerald-700"
        >
          Create Note Template
        </button>
      </div>
    </template>
  </cSidebarModal>
</template>
