import {
  //axios
  axios,

  //pinia
  defineStore,
  useGlobalToastsStore,
  useGlobalPromptsStore,

  //env base url
  BASE_API_URL,
} from "../clientImports";

export const useTenantsTagsStore = defineStore({
  id: "tenants-tags",
  state: () => ({
    showTenantsTagsCreateModal: false,
    showTenantsTagsEditModal: false,

    showTenantsTagsModal: false, // this is in the contact file

    idToEdit: "",

    tenantsTagsList: [],

    currentlyCreatingTag: {
      active: true,
      name: "",
      description: "",
      color: "",
    },

    currentlyEditingTag: {
      active: true,
      name: "",
      description: "",
      color: "",
    },
  }),
  actions: {
    //two responsibilities - set the id to edit and then open the modal
    async initializeTenantsTagsEditModal(tagId) {
      //set's the id of the Tag that we're editing
      this.setIdToEdit(tagId);

      await this.fetchTenantTag();
      //opens the edit modal
      this.showTenantsTagsEditModal = true;
    },

    // this is in the contact file to open the modal for adding/removing tags on a contact
    toggleTenantsTagsModal() {
      this.showTenantsTagsModal = !this.showTenantsTagsModal;
    },

    toggleTenantsTagsCreateModal() {
      this.showTenantsTagsCreateModal = !this.showTenantsTagsCreateModal;
    },

    toggleTenantsTagsEditModal() {
      this.showTenantsTagsEditModal = !this.showTenantsTagsEditModal;
    },

    //fetches the Tags for listing
    async fetchTenantsTags() {
      try {
        const { data } = await axios.get(`${BASE_API_URL}/read-tenants-tags`);

        if (data.status === "success") {
          this.tenantsTagsList = data.tagsOptions.map((tag) => {
            return {
              id: tag._id,
              name: tag.name,
              description: tag.description,
              color: tag.color,
              active: tag.active,
            };
          });
        } else {
          console.error(data.message);
        }
      } catch (error) {
        console.error();
      }
    },

    //fetching for editing
    async fetchTenantTag() {
      const id = this.idToEdit;
      try {
        const { data } = await axios.get(
          `${BASE_API_URL}/read-tenants-tag/${id}`
        );

        console.log(data, "FETCHED MILESTONE");

        if (data.status === "success") {
          console.log(data.tag);
          this.currentlyEditingTag = data.tag[0];

          console.log(this.currentlyEditingTag);
        } else {
          console.error(data.message);
        }
      } catch (error) {
        console.error();
      }
    },

    setIdToEdit(tagId) {
      this.idToEdit = tagId;
    },

    async createTenantsTag() {
      const tagData = this.currentlyCreatingTag;

      console.log("PASSPORT DATA SENDING OUT: ", tagData);
      try {
        const { data } = await axios.post(
          `${BASE_API_URL}/create-tenants-tag`,
          tagData
        );

        console.log("CREATE PASSPORT RESPONSE", data);

        if (data.status === "success") {
          const toastsStore = useGlobalToastsStore();
          toastsStore.addToast({
            title: "Tag Created",
            description: "",
            buttonExists: false,
            color: "emerald",
            type: 1,
          });

          //fetch the updated list of Tags
          this.fetchTenantsTags();
          this.toggleTenantsTagsCreateModal();
        } else {
          console.error(data.message);
          toastsStore.addToast({
            title: "Error Creating Tag",
            description: data.message,
            buttonExists: false,
            color: "red",
            type: 1,
          });
        }
      } catch (error) {
        console.error();
      }
    },

    async updateTenantsTag() {
      const id = this.idToEdit;
      try {
        const { data } = await axios.put(
          `${BASE_API_URL}/update-tenants-tag/${id}`,
          this.currentlyEditingTag
        );

        if (data.status === "success") {
          console.log(data.message);

          this.toggleTenantsTagsEditModal();
          //fetch the updated list of Tags
          this.fetchTenantsTags();

          //throw toast
          const toastsStore = useGlobalToastsStore();
          toastsStore.addToast({
            title: "Tag Updated",
            description: "",
            buttonExists: false,
            color: "emerald",
            type: 1,
          });
        } else {
          console.error(data.message);
          toastsStore.addToast({
            title: "Error Updating Tag",
            description: data.message,
            buttonExists: false,
            color: "red",
            type: 1,
          });
        }
      } catch (error) {
        console.error();
      }
    },

    async deleteTenantsTag(tagId) {
      const promptStore = useGlobalPromptsStore();

      const group_id = tagId;

      promptStore.addPrompt({
        type: "Tag",
        title: "Delete Tag?",
        message: `Are you sure you want to delete this Tag?`,
      });

      const promptResponse = await promptStore.waitForPromptConfirmation();

      if (promptResponse === true) {
        promptStore.addPrompt({
          type: "Tag",
          title: "Are You Sure?",
          message: `We'll hide this from your view so it can no longer be used. Any contacts or users that have this tag assigned will remain assigned and have to be manually changed. Do you still want to proceed with the deletion?`,
        });

        const promptResponse2 = await promptStore.waitForPromptConfirmation();

        if (promptResponse2 === true) {
          try {
            const { data } = await axios.delete(
              `${BASE_API_URL}/delete-tenants-tag/${group_id}`
            );

            if (data.status === "success") {
              //fetch the updated list of Tags
              this.fetchTenantsTags();
            } else {
              console.error(data.message);
              toastsStore.addToast({
                title: "Error Deleting Tag",
                description: data.message,
                buttonExists: false,
                color: "red",
                type: 1,
              });
            }
          } catch (error) {
            console.error();
          }
          promptStore.removePrompt();
        } else {
          promptStore.removePrompt();
        }
      } else {
        promptStore.removePrompt();
      }
    },
  },
});
