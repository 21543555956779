<script setup>
defineOptions({
  name: 'ContactWorkflowStatus', // Give the component a name
});
import {
  //axios
  axios,

  //vue
  createApp,
  ref,
  reactive,
  watch,
  computed,
  inject,
  provide,
  onMounted,
  onUnmounted,
  nextTick,
  Teleport,

  //pinia
  defineStore,
  createPinia,
  storeToRefs,

  //pinia stores
  useChatStore,
  useContactAlertsStore,
  useContactFileStore,
  useContactsStore,
  useGlobalPromptsStore,
  useGlobalToastsStore,
  useLoginStore,
  useTenantsStore,

  //vue-router
  useRouter,
  useRoute,

  //formkit

  //formkit config file

  //fontawesome
  library,
  FontAwesomeIcon,
  fas,
  far,
  fab,

  //global components - all vue components
  registerGlobalComponents,

  //base api url
  BASE_API_URL,
} from "./../clientImports";

const toastsStore = useGlobalToastsStore();

const currentWorkflowStatus = ref({ id: 1, workflowStatus: "New" });

const workflowStatuses = ref([
  { id: 1, workflowStatus: "New" },
  { id: 2, workflowStatus: "My New Workflow Status" },
]);

watch(currentWorkflowStatus, (n, o) => {
  toastsStore.addToast({
    title: "Workflow Status Updated",
    description: `Workflow Status set to: (${n.workflowStatus})`,
    buttonExists: false,
    color: "sky",
    type: 1, // 1 = small, 2 = md, 3 = lg, 4 = lg dismissable
  });

  toggleWorkflowStatusSelect();
});

const showWorkflowStatusSelect = ref(false);
const toggleWorkflowStatusSelect = () => {
  showWorkflowStatusSelect.value = !showWorkflowStatusSelect.value;
};
</script>

<template>
  <div class="flex flex-row">
    <button
      @click="toggleWorkflowStatusSelect"
      class="btn btn-xs rounded-none no-animation text-white capitalize"
    >
      Workflow
    </button>
    <select
      :class="{ 'max-sm:hidden': !showWorkflowStatusSelect }"
      v-model="currentWorkflowStatus"
      class="select select-xs text-white rounded-none w-28 hover:w-auto"
    >
      <option disabled selected>Choose Status</option>
      <option v-for="(status, statusIndex) in workflowStatuses" :value="status">
        {{ status.workflowStatus }}
      </option>
    </select>
    <button
      :class="{ 'max-sm:hidden': !showWorkflowStatusSelect }"
      class="btn btn-xs text-orange-500 rounded-none no-animation"
    >
      <font-awesome-icon icon="fa-solid fa-list-ul" size="xl" />
    </button>
    <button
      ref="focusElement"
      :class="{ 'max-sm:hidden': !showWorkflowStatusSelect }"
      class="btn btn-xs text-green-500 rounded-l-none rounded-r"
    >
      <font-awesome-icon icon="fa-solid fa-chevron-right" size="xl" />
    </button>
  </div>
</template>
