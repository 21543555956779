<script setup>
defineOptions({
  name: 'ContactAlerts', // Give the component a name
});
import {
  //axios
  axios,

  //vue
  createApp,
  ref,
  reactive,
  watch,
  computed,
  inject,
  provide,
  onMounted,
  onUnmounted,
  nextTick,
  Teleport,

  //pinia
  defineStore,
  createPinia,
  storeToRefs,

  //pinia stores
  useChatStore,
  useContactAlertsStore,
  useContactFileStore,
  useContactsStore,
  useGlobalPromptsStore,
  useGlobalToastsStore,
  useLoginStore,
  useTenantsStore,

  //vue-router
  useRouter,
  useRoute,

  //formkit

  //formkit config file

  //fontawesome
  library,
  FontAwesomeIcon,
  fas,
  far,
  fab,

  //global components - all vue components
  registerGlobalComponents,

  //utils
  xLog,

  //base api url
  BASE_API_URL,
} from "./../clientImports";

const contactAlertsStore = useContactAlertsStore();
const promptsStore = useGlobalPromptsStore();
const toastsStore = useGlobalToastsStore();

const contactAlerts = ref([]);

const deleteAlert = async (alertObj, index) => {
  let alertName =
    alertObj.message === false || alertObj.message === ""
      ? "No Name"
      : alertObj.message;
  xLog("alertObj", alertObj);

  promptsStore.addPrompt({
    type: "Contact Alert",
    title: "Delete Alert?",
    message: `Are you sure you want to delete this alert: ${alertName}?`,
  });

  const promptResponse = await promptsStore.waitForPromptConfirmation();

  if (promptResponse === true) {
    contactAlertsStore.contactAlerts.splice(index, 1);
    toastsStore.addToast({
      title: "Alert Deleted",
      description: `Deleted: ${alertName}`,
      buttonExists: false,
      color: "green",
      type: 1, // 1 = small, 2 = md, 3 = lg, 4 = lg dismissable
    });
    promptsStore.removePrompt();
  } else {
    //only close the prompt
    promptsStore.removePrompt();
  }
};
</script>
<template>
  <div class="flex flex-row justify-between items-center w-full">
    <div
      :class="{ 'md:p-2': contactAlertsStore.contactAlerts.length > 0 }"
      class="w-full flex flex-col items-center md:gap-2 bg-slate-200"
    >
      <div
        v-for="(alert, alertIndex) in contactAlertsStore.contactAlerts"
        :key="alertIndex"
        class="alert rounded-none md:rounded p-0 overflow-hidden"
        :class="`bg-${alert.color}-500`"
        @mouseover="alert.editable = true"
        @mouseout="alert.editable = false"
      >
        <div class="flex flex-row justify-between w-full gap-0">
          <div class="self-stretch">
            <select
              v-show="alert.color === '0'"
              v-model="alert.color"
              class="select select-xs text-white rounded-r-none rounded-l h-full"
              :class="`bg-slate-500`"
            >
              <option disabled selected value="0">Choose Color</option>
              <option value="red">Red</option>
              <option value="orange">Orange</option>
              <option value="yellow">Yellow</option>
              <option value="green">Green</option>
              <option value="blue">Blue</option>
              <option value="purple">Purple</option>
            </select>
          </div>
          <FormKit
            type="textarea"
            auto-height
            outer-class="$reset m-0 flex flex-row flex-1"
            wrapper-class="$reset flex flex-row flex-1 w-full"
            inner-class="$reset flex flex-row flex-1"
            :input-class="`$reset text-xs md:text-sm bg-${alert.color}-500 p-1 md:p-2 h-full min-w-0 rounded-none text-white flex-1 `"
            v-model.trim="alert.message"
            placeholder="Message Here..."
          ></FormKit>

          <div class="self-stretch bg-blue-500">
            <button
              @click.stop="deleteAlert(alert, alertIndex)"
              class="btn btn-sm hover:bg-red-700 rounded-l-none rounded-r border-none text-white h-full"
              :class="`bg-${alert.color}-500`"
            >
              <font-awesome-icon icon="fa-solid fa-xmark" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
