import {
  //axios
  axios,

  //pinia
  defineStore,

  //env base url
  BASE_API_URL,
} from "../clientImports";

export const useTenantsUsageStore = defineStore({
  id: "tenants-usage",
  state: () => ({
    database_bytes: null,
    files_bytes: null,
  }),
  actions: {
    async readUsage() {
      const response = await axios.get(`${BASE_API_URL}/read-tenants-usage`);

      this.database_bytes = response.data.database_bytes;
      this.files_bytes = response.data.files_bytes;

      console.log("FILES BYTES: ", this.files_bytes);
    },
  },
});
