<script setup>
defineOptions({
  name: 'SettingsCampaignsList', // Give the component a name
});
import {
  //axios
  axios,

  //vue
  ref,
  reactive,
  watch,
  onMounted,
  onBeforeMount,

  //pinia stores
  useLoginStore,
  useGlobalPromptsStore,
  useGlobalToastsStore,
  useUsersStore,
  useTenantsCampaignsStore,

  //vue-router
  useRouter,
  useRoute,

  //formkit

  //formkit config file

  //fontawesome
  library,
  FontAwesomeIcon,
  fas,
  far,
  fab,

  //global components - all vue components
  registerGlobalComponents,

  //base api url
  BASE_API_URL,
} from "./../clientImports";

const promptsStore = useGlobalPromptsStore();
const toastsStore = useGlobalToastsStore();
const tenantsCampaignsStore = useTenantsCampaignsStore();
const loginStore = useLoginStore();

const route = useRoute();
const router = useRouter();

let campaignsList = reactive([]);

onMounted(async () => {
  await tenantsCampaignsStore.fetchTenantsCampaignsListing();
});

const deleteCampaign = async (campaignId) => {
  try {
    promptsStore.addPrompt({
      type: "Campaign",
      title: "Delete Campaign?",
      message: `Are you sure you want to delete this Campaign? If you have any files assigned with this campaign they will no longer have a status.`,
    });

    const promptResponse = await promptsStore.waitForPromptConfirmation();

    if (promptResponse === true) {
      const response =
        await tenantsCampaignsStore.deleteTenantCampaign(campaignId);

      if (response.status === "success") {
        promptsStore.removePrompt();

        toastsStore.addToast({
          title: "Campaign Deleted",
          description: "",
          buttonExists: false,
          color: "emerald",
          type: 1, // 1 = small, 2 = md, 3 = lg, 4 = lg dismissable
        });
        await tenantsCampaignsStore.fetchTenantsCampaignsListing();
      } else {
        toastsStore.addToast({
          title: "Error Deleting Campaign",
          description: "",
          buttonExists: false,
          color: "red",
          type: 1, // 1 = small, 2 = md, 3 = lg, 4 = lg dismissable
        });
      }
    } else {
      promptsStore.removePrompt();
    }
  } catch (error) {
    console.error(error);
  }
};

const editCampaign = async (campaignId) => {
  router.push({
    name: "settings-campaigns-edit",
    params: {
      id: campaignId,
    },
  });
};
</script>

<template>
  <div class="flex-1 max-w-full h-full">
    <cLoadingSpinner
      v-if="tenantsCampaignsStore.tenantsCampaignsListing === null"
    />
    <table
      v-else-if="tenantsCampaignsStore.tenantsCampaignsListing?.length > 0"
      class="w-full"
    >
      <thead class="">
        <tr>
          <th
            v-for="(campaign, resultIndex) in tenantsCampaignsStore
              .tenantsCampaignsListing[0]"
            :key="resultIndex"
            v-show="resultIndex !== 'system_default'"
            class="bg-gray-500 text-white text-left p-1 md:px-5 md:py-2 md:text-base text-xs whitespace-nowrap capitalize"
          >
            {{ resultIndex.replaceAll("_", " ") }}
          </th>
          <th class="text-white bg-base-300 sticky right-0">
            <span class="hidden md:block"> Action </span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(
            campaign, resultIndex
          ) in tenantsCampaignsStore.tenantsCampaignsListing"
          :key="resultIndex"
          class="odd:bg-slate-700 even:bg-slate-800"
        >
          <td
            v-for="(cellData, cellIndex) in campaign"
            :key="cellIndex"
            v-show="cellIndex !== 'system_default'"
            class="md:px-5 md:py-3 text-white text-xs md:text-base"
          >
            {{ cellData }}
          </td>
          <td class="bg-base-300 sticky right-0 p-1">
            <div
              class="flex flex-col md:flex-row justify-center md:items-center items-stretch"
            >
              <label
                v-if="
                  (loginStore?.sessionData?.permissions?.includes(
                    'delete:campaigns'
                  ) ||
                    loginStore?.sessionData?.permissions?.includes(
                      '*:dev-permissions'
                    )) &&
                  !campaign.system_default
                "
                class="btn btn-xs md:btn-sm rounded-t rounded-b-none md:rounded-l md:rounded-r-none bg-rose-500 hover:bg-rose-700 text-white border-none"
                @click.stop="deleteCampaign(campaign.id)"
              >
                <font-awesome-icon icon="fa-solid fa-xmark" size="lg" />
              </label>

              <!-- BUG - might add this later - no editing roles for now - just have to add new and delete the old after -->
              <!-- <button
                class="btn btn-xs md:btn-sm rounded-b-none md:rounded-l-none md:rounded-r bg-blue-500 hover:bg-blue-700 text-white border-none"
                @click="
                  tenantsMilestonesStore.initializeTenantsMilestonesEditModal(
                    campaign._id
                  )
                "
              >
                <font-awesome-icon icon="fa-solid fa-pen-to-square" />
              </button> -->

              <!-- need a router-link that sets a param with campaign._id -->
              <button
                v-if="
                  (loginStore?.sessionData?.permissions?.includes(
                    'edit:campaigns'
                  ) ||
                    loginStore?.sessionData?.permissions?.includes(
                      '*:dev-permissions'
                    )) &&
                  !campaign.system_default
                "
                class="btn btn-xs md:btn-sm rounded-b-none md:rounded-l-none md:rounded-r bg-blue-500 hover:bg-blue-700 text-white border-none"
                @click="editCampaign(campaign.id)"
              >
                <font-awesome-icon icon="fa-solid fa-pen-to-square" />
              </button>

              <cSystemDefaultIcon v-if="campaign.system_default" />

              <!-- LEFTOFF - permission hide / showing and edit / delete working -->
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div>
    <SettingsCampaignsEdit />
  </div>
</template>
