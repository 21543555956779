<script setup>
defineOptions({
  name: 'SearchContact', // Give the component a name
});
import {
  //axios
  axios,

  //vue
  createApp,
  ref,
  reactive,
  watch,
  computed,
  inject,
  provide,
  onMounted,
  onUnmounted,
  nextTick,
  Teleport,

  //pinia
  defineStore,
  createPinia,
  storeToRefs,

  //pinia stores
  useChatStore,
  useContactAlertsStore,
  useContactFileStore,
  useContactsStore,
  useGlobalPromptsStore,
  useGlobalToastsStore,
  useLoginStore,
  useSearchContactStore,
  useTenantsStore,

  //vue-router
  useRouter,
  useRoute,

  //formkit

  //formkit config file

  //fontawesome
  library,
  FontAwesomeIcon,
  fas,
  far,
  fab,

  //global components - all vue components
  registerGlobalComponents,

  //base api url
  BASE_API_URL,
} from "./../clientImports";

const router = useRouter();
const route = useRoute();

const searchContactStore = useSearchContactStore();
const contactsStore = useContactsStore();
const promptsStore = useGlobalPromptsStore();
const toastsStore = useGlobalToastsStore();
const loginStore = useLoginStore();

let lastSearchQuery = ref("");

// Your search function
const search = async (searchQuery) => {
  // Trim the search query before assigning it to lastSearchQuery and using it for search
  const trimmedQuery = searchQuery.trim();
  await searchContactStore.searchContact(trimmedQuery);
  lastSearchQuery.value = trimmedQuery;
  searchContactStore.searchQuery = ""; // Reset after search
};

const resultsShown = ref(true);

const closeResultsModal = () => {
  searchContactStore.closeResultsModal();
};

const goToContactFile = (id) => {
  router.push({ name: "contact-file", params: { contact_id: id } });
  closeResultsModal();
};

const deleteContact = async (id) => {
  try {
    promptsStore.addPrompt({
      type: "Contact",
      title: "Delete Contact?",
      message: `Are you sure you want to delete this contact?`,
    });

    const promptResponse = await promptsStore.waitForPromptConfirmation();

    if (promptResponse === true) {
      promptsStore.removePrompt();

      promptsStore.addPrompt({
        type: "Contact",
        title: "Confirm Deletion of Contact",
        message: `It's not typical that a contact would be deleted, if you still wish to proceed please understand our support team cannot recover this for you and it will be gone forever`,
      });

      const promptResponse2 = await promptsStore.waitForPromptConfirmation();

      if (promptResponse2 === true) {
        promptsStore.removePrompt();

        const response = await contactsStore.deleteContact(id);

        if (response.status === "success") {
          toastsStore.addToast({
            title: "Contact Deleted",
            description: "",
            buttonExists: false,
            color: "red",
            type: 1, // 1 = small, 2 = md, 3 = lg, 4 = lg dismissable
          });
          await searchContactStore.searchContact(lastSearchQuery.value);
        } else {
          toastsStore.addToast({
            title: "Error Deleting Contact",
            description: "",
            buttonExists: false,
            color: "red",
            type: 1, // 1 = small, 2 = md, 3 = lg, 4 = lg dismissable
          });
        }
      } else {
        promptsStore.removePrompt();
      }
    } else {
      promptsStore.removePrompt();
    }
  } catch (error) {
    console.log(error);
  }
};
</script>

<template>
  <div class="form-control">
    <div class="flex flex-row justify-between items-center">
      <div
        class="whitespace-nowrap flex flex-row"
        v-if="
          loginStore?.sessionData?.permissions?.includes(
            'view:search contacts'
          ) ||
          loginStore?.sessionData?.permissions?.includes('*:dev-permissions')
        "
      >
        <input
          @keyup.enter="search(searchContactStore.searchQuery)"
          v-model="searchContactStore.searchQuery"
          type="text"
          placeholder="Name, Email, Phone"
          class="input input-xs hidden md:block md:input-sm border-none bg-white rounded-l rounded-r-none text-slate-600"
        />

        <button
          @click="search(searchContactStore.searchQuery)"
          class="hidden md:block btn btn-xs md:btn-sm bg-slate-500 rounded-none hover:bg-slate-700 text-white text-xs md-text-base border-none"
        >
          <font-awesome-icon icon="fa-solid fa-magnifying-glass" size="md" />
        </button>
      </div>

      <button
        v-if="
          loginStore?.sessionData?.permissions?.includes(
            'view:search contacts'
          ) ||
          loginStore?.sessionData?.permissions?.includes('*:dev-permissions')
        "
        @click="searchContactStore.openResultsModal()"
        class="block md:hidden btn btn-xs md:btn-sm rounded-r-none bg-slate-500 btn-square rounded md:rounded-l-none md:rounded-r hover:bg-slate-700 text-white border-none text-xs md-text-base"
      >
        <font-awesome-icon icon="fa-solid fa-magnifying-glass" size="md" />
      </button>

      <RecentContacts></RecentContacts>
    </div>
    <Teleport to="#modals-target">
      <transition
        enter-active-class="transition ease-in-out duration-300"
        enter-from-class="opacity-0 -translate-y-full"
        enter-to-class="opacity-100 translate-y-0"
        leave-active-class="transition ease-in-out duration-300"
        leave-from-class="opacity-100 translate-y-0"
        leave-to-class="opacity-0 -translate-y-full"
      >
        <div
          v-if="searchContactStore.resultsShown"
          @click.self="closeResultsModal()"
          class="fixed top-0 left-0 backdrop-brightness-50 flex flex-col justify-center items-center md:p-5 w-full h-full z-70"
        >
          <div
            class="bg-base-100 border border-slate-400 rounded px-5 pb-5 w-full h-full flex flex-col"
          >
            <div
              class="flex flex-row justify-between items-center py-5 relative"
            >
              <h1 class="text-white text-xs md:text-xl">Results</h1>
              <div
                class="absolute left-1/2 transform -translate-x-1/2 flex flex-row justify-between items-center"
              >
                <input
                  @keyup.enter="search(searchContactStore.searchQuery)"
                  v-model="searchContactStore.searchQuery"
                  type="text"
                  placeholder="Name, Email, Phone"
                  class="input input-xs md:w-52 md:input-sm rounded-l border-none bg-white rounded-r-none text-slate-600"
                />
                <button
                  @click="search(searchContactStore.searchQuery)"
                  class="btn btn-xs md:btn-sm bg-slate-500 btn-square rounded-l-none rounded-r hover:bg-slate-700 text-white border-none"
                >
                  <font-awesome-icon
                    icon="fa-solid fa-magnifying-glass"
                    size="lg"
                  />
                </button>
              </div>
              <button
                @click="closeResultsModal()"
                type="button"
                class="btn btn-sm text-white border-none rounded-full bg-slate-500 hover:bg-red-500"
              >
                <font-awesome-icon icon="fa-solid fa-xmark" />
              </button>
            </div>
            <div class="flex-1 bg-gray-500 rounded overflow-auto hide-scroll">
              <div
                v-if="searchContactStore.searchResults.length === 0"
                class="w-full h-full flex flex-col justify-center items-center"
              >
                <div class="bg-red-500 rounded p-5">
                  <h1 class="text-white text-lg">
                    No Results For:
                    <span class="bg-red-600 px-2 pt-1 pb-2 rounded">{{
                      lastSearchQuery
                    }}</span>
                  </h1>
                </div>
              </div>

              <table
                v-if="searchContactStore.searchResults.length > 0"
                class="w-full"
              >
                <thead class="">
                  <tr>
                    <th
                      v-for="(contact, resultIndex) in searchContactStore
                        .searchResults[0]"
                      :key="resultIndex"
                      class="bg-gray-500 text-white text-left p-1 md:px-5 md:py-2 md:text-base text-xs whitespace-nowrap"
                    >
                      {{ resultIndex.replaceAll("_", " ") }}
                    </th>
                    <th class="text-white bg-base-300 sticky right-0">
                      <span class="hidden md:block"> Action </span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(
                      contact, resultIndex
                    ) in searchContactStore.searchResults"
                    :key="resultIndex"
                    class="odd:bg-slate-700 even:bg-slate-800"
                  >
                    <td
                      v-for="(cellData, cellIndex) in contact"
                      :key="cellIndex"
                      class="md:px-5 md:py-3 text-white text-xs md:text-base"
                    >
                      {{ cellData }}
                    </td>
                    <td class="bg-base-300 sticky right-0 p-1">
                      <div
                        class="flex flex-col md:flex-row justify-end md:items-center items-stretch"
                      >
                        <label
                          class="btn btn-xs md:btn-sm rounded-t rounded-b-none md:rounded-l md:rounded-r-none bg-rose-500 hover:bg-rose-700 text-white border-none"
                          @click.stop="deleteContact(contact._id)"
                        >
                          <font-awesome-icon
                            icon="fa-solid fa-xmark"
                            size="lg"
                          />
                        </label>
                        <label
                          class="btn btn-xs md:btn-sm rounded-none bg-orange-500 hover:bg-orange-700 text-white border-none"
                        >
                          <font-awesome-icon
                            icon="fa-solid fa-ellipsis-vertical"
                            size="xl"
                          />
                        </label>
                        <button
                          @click="goToContactFile(contact._id)"
                          class="btn btn-xs md:btn-sm rounded-b rounded-t-none md:rounded-l-none md:rounded-r bg-blue-500 hover:bg-blue-700 text-white border-none"
                        >
                          <font-awesome-icon
                            icon="fa-solid fa-expand"
                            size="xl"
                          />
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <!-- <pre>

        {{ searchContactStore.searchResults }}
      </pre> -->
            </div>
          </div>
        </div>
      </transition>
    </Teleport>
  </div>
</template>
