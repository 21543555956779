<script setup>
defineOptions({
  name: 'cLoadingSpinner', // Give the component a name
});
// Comments: Component logic (if needed)

//add defined props with the loadingMessage
const props = defineProps({
  loadingMessage: {
    type: String,
    required: true,
    default: "Loading...",
  },
});
</script>

<template>
  <!-- Comments: Container for loading spinner and text -->
  <div
    class="flex justify-center items-center h-full bg-opacity-50 bg-gray-700"
  >
    <!-- Comments: Spinner Container -->
    <div class="flex items-center space-x-3 glass p-5 rounded">
      <!-- Comments: Spinner using Tailwind CSS -->
      <div
        class="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12"
      ></div>
      <!-- Comments: Loading text -->
      <div class="text-white text-xl">{{ props.loadingMessage }}</div>
    </div>
  </div>
</template>
