//test rules
// const rules = [
//   { label: "_id", value: true },
//   { label: "first_name", value: true },
//   { label: "last_name", value: true },
//   { label: "email", value: true },
//   { label: "user_phone_number", value: false },
//   { label: "phone_caller_id", value: false },
//   { label: "user_phone_extension", value: false },
//   { label: "department", value: true },
//   { label: "active", value: true },
//   { label: "passport_required", value: true },
//   { label: "email_alerts", value: false },
//   { label: "level_access", value: true },
//   { label: "created_date", value: false },
//   { label: "last_updated", value: false },
//   { label: "created_by", value: false },
//   { label: "updated_by", value: false },
//   { label: "company_name", value: false },
// ];
//test data
// const data = [
//   {
//     _id: "64eea7c6cbbcce3aa0850d0d",
//     first_name: "Nick",
//     last_name: "Lancaster",
//     email: "nick@finnypi.app",
//     user_phone_number: "7143952633",
//     phone_caller_id: "Nicks Caller ID",
//     user_phone_extension: "101",
//     department: "compliance",
//     active: false,
//     passport_required: false,
//     email_alerts: true,
//     level_access: "master",
//     created_date: "2023-08-30T02:21:58.590Z",
//     last_updated: "2023-09-06T02:43:40.282Z",
//     created_by: "Nick Lancaster",
//     updated_by: "Nick Lancaster",
//     company_name: "finpi",
//   },
//   {
//     _id: "64f6e6bf92a1a044112a38b7",
//     first_name: "Katie",
//     last_name: "Lancaster",
//     email: "katie@finnypi.app",
//     user_phone_number: "8587778485",
//     phone_caller_id: "katie l",
//     user_phone_extension: "1001",
//     department: "support",
//     active: true,
//     passport_required: true,
//     email_alerts: true,
//     level_access: "master",
//     created_date: "2023-08-30T02:21:58.590Z",
//     last_updated: "2023-09-06T02:36:03.926Z",
//     created_by: "Nick Lancaster",
//     updated_by: "Nick Lancaster",
//     company_name: "finpi",
//   },
// ];

export const sortAndFilterProperties = (rules, data) => {
  return data.map((obj) => {
    return rules.reduce((acc, rule) => {
      if (rule.value && obj.hasOwnProperty(rule.label)) {
        acc[rule.label] = obj[rule.label];
      }
      return acc;
    }, {});
  });
};

//usage

// const x = sortAndFilterProperties(rules, data);

// console.log(x);
