import {
  //axios
  axios,

  //pinia
  defineStore,

  //env base url
  BASE_API_URL,
  xLog,
} from "../clientImports";

export const useContactAlertsStore = defineStore({
  id: "contactAlerts",
  state: () => ({
    contactAlerts: [], // the current contact file id - this will be used to fetch the contact file data
  }),
  actions: {
    async addNewAlert() {
      this.contactAlerts.push({ message: "", color: "0" });
    },
  },
});
