<script setup>
defineOptions({
  name: 'SupportGPT', // Give the component a name
});
import {
  //axios
  axios,

  //vue
  createApp,
  ref,
  reactive,
  watch,
  computed,
  inject,
  provide,
  onMounted,
  onUnmounted,
  nextTick,
  Teleport,

  //pinia
  defineStore,
  createPinia,
  storeToRefs,

  //pinia stores
  useChatStore,
  useContactAlertsStore,
  useContactFileStore,
  useContactsStore,
  useGlobalPromptsStore,
  useGlobalToastsStore,
  useLoginStore,
  useTenantsStore,
  useGptStore,

  //vue-router
  useRouter,
  useRoute,

  //formkit

  //formkit config file

  //fontawesome
  library,
  FontAwesomeIcon,
  fas,
  far,
  fab,
  FKsubmitForm,
  //global components - all vue components
  registerGlobalComponents,

  //base api url
  BASE_API_URL,
} from "./../clientImports";

// Call the useContactsStore function to get an instance of the store
const contactsStore = useContactsStore();
const gptStore = useGptStore();

const waitingForAIresponse = ref(false);
const waitingForAIresponse2 = ref(false);

const submitCreateForm = () => {
  return FKsubmitForm("prompt-form");
};

const scrollableDiv = ref(null);

const promptInput = ref(null);

const submitPromptForm = async () => {
  waitingForAIresponse.value = true;
  waitingForAIresponse2.value = true; //secondary delay for the warning
  const response = await gptStore.submitPrompt();

  if (response === "success") {
    scrollToBottom();

    gptStore.currentlyCreatingPrompt = "";
    waitingForAIresponse.value = false;

    setTimeout(() => {
      waitingForAIresponse2.value = false;
    }, 5000);
  } else {
    waitingForAIresponse.value = false;
    setTimeout(() => {
      waitingForAIresponse2.value = false;
    }, 5000);
  }
};

const scrollToBottom = () => {
  if (scrollableDiv.value) {
    scrollableDiv.value.scrollTo({
      top: scrollableDiv.value.scrollHeight,
      behavior: "smooth",
    });
  }
};

//watch the conversation array and scroll to the bottom of the div
watch(
  () => gptStore.showGptWindow,
  () => {
    if (gptStore.showGptWindow) {
      nextTick(() => {
        scrollToBottom();
      });
    }
  }
);
</script>

<template>
  <cSidebarModal
    sidebar-position="right"
    :show-sidebar-initial="false"
    :show-sidebar-modal="gptStore.showGptWindow"
    :toggle-sidebar-modal="gptStore.toggleGptWindow"
    :default-button="false"
  >
    <template #title>
      <div class="flex flex-col">
        <h2 class="text-xl">FinnyPi Support GPT</h2>
        <h3 class="text-sm">Knows mostly everything about FinnyPi.</h3>
      </div>
    </template>

    <template #button-group> </template>

    <template #body>
      <div
        ref="scrollableDiv"
        class="flex flex-col gap-5 h-full overflow-auto hide-scroll"
      >
        <div
          v-if="gptStore.conversation.length === 0"
          class="h-full flex flex-col justify-center items-center"
        >
          <div
            class="flex flex-row gap-5 justify-center items-center bg-blue-500 rounded p-5"
          >
            <font-awesome-icon icon="fa-solid fa-wand-sparkles" />
            <span
              >Your conversation feed with FinnyPi GPT will appear hear. Ask any
              question you have about FinnyPi.</span
            >
          </div>
        </div>

        <div
          v-for="(item, i) in gptStore.conversation"
          :key="i"
          :class="`${item.type === 'system' || item.type === 'assistant' ? 'border-emerald-400 bg-emerald-900' : 'border-blue-400 bg-blue-900'}`"
          class="flex flex-col gap-5 border rounded p-5 text-white"
        >
          <div class="flex flex-row">
            <span
              :class="`${item.type === 'system' || item.type === 'assistant' ? ' border-white bg-gradient-to-br from-indigo-500 via-red-500 to-emerald-500' : 'border-blue-300 bg-base-100'}`"
              class="border rounded-full px-3 py-0.5"
            >
              <font-awesome-icon
                v-if="item.type === 'system' || item.type === 'assistant'"
                icon="fa-solid fa-wand-sparkles"
                class="mr-2"
              />

              <font-awesome-icon v-else icon="fa-solid fa-user" class="mr-2" />
              {{
                item.type === "system" || item.type === "assistant"
                  ? "FinnyPi GPT"
                  : "You"
              }}</span
            >
          </div>

          <div v-if="item.type === 'assistant'">
            <div v-if="item.noContentFound">
              <div class="text-red-500">
                I couldn't find any information to answer your question.
                Rephrase your question and try again.
              </div>
            </div>
            <div v-else v-html="item.aiLocatedArticle"></div>
          </div>
          <div v-else>
            {{ item.content }}
          </div>
        </div>
      </div>
    </template>

    <template #footer>
      <!-- BUTTON FOOTER - CANCEL & UPDATE Passport -->
      <div class="flex flex-col gap-5 text-white w-full">
        <FormKit
          id="prompt-form"
          type="form"
          :incomplete-message="false"
          submit-label="Create"
          form-class="text-white h-full flex flex-col gap-5 flex-1"
          @submit="submitPromptForm"
          outer-class="m-0 flex-1"
          :actions="false"
          :submit-attrs="{
            'data-theme': `light`,
            ignore: true,
          }"
        >
          <div class="flex-1">
            <transition
              enter-active-class="transition-opacity duration-500 ease-in-out"
              enter-from-class="opacity-0"
              enter-to-class="opacity-100"
              leave-active-class="transition-opacity duration-500 ease-in-out"
              leave-from-class="opacity-100"
              leave-to-class="opacity-0"
            >
              <div
                v-if="waitingForAIresponse || waitingForAIresponse2"
                class="flex flex-row items-center gap-2 text-xs bg-red-900 border border-red-400 p-2 rounded mb-2"
              >
                <font-awesome-icon
                  class="text-yellow-500"
                  icon="fa-solid fa-triangle-exclamation"
                  size="xl"
                />

                <div
                  v-if="waitingForAIresponse"
                  class="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-8 w-8"
                ></div>

                <span class="text-center"
                  >AI results can sometimes be inaccurate. Try again if
                  so.</span
                >
              </div>
            </transition>

            <FormKit
              v-if="!waitingForAIresponse"
              type="textarea"
              @keydown.enter.prevent="submitCreateForm"
              label=""
              placeholder="...How do I create a user?"
              :validation="'required|trim|length:5, 255'"
              :validation-messages="{
                required: 'Please enter a prompt',
                length: 'Prompt must be between 1 and 255 characters',
              }"
              help-class="text-white"
              outer-class="m-0"
              input-class="$reset rounded w-full textarea textarea-sm text-gray-700 bg-gray-50"
              wrapper-class="max-w-full"
              v-model.trim="gptStore.currentlyCreatingPrompt"
            />
          </div>
        </FormKit>

        <div
          v-if="
            gptStore.conversation.length > 0 ||
            gptStore.currentlyCreatingPrompt.length >= 5
          "
          class="flex flex-row"
        >
          <button
            v-if="gptStore.conversation.length > 0"
            @click="gptStore.clearConversation"
            class="btn btn-sm rounded bg-red-500 hover:bg-red-700 border-none capitalize text-gray-50"
          >
            Delete Conversation
          </button>

          <button
            v-if="gptStore.currentlyCreatingPrompt.length >= 5"
            @click="submitCreateForm"
            class="btn capitalize btn-sm ml-auto rounded bg-emerald-500 text-white hover:bg-emerald-700"
          >
            <span class="">Submit</span><span class="mx-2">|</span
            ><span class="text-xs">Press ENTER</span>
          </button>
        </div>
      </div>
    </template>
  </cSidebarModal>
</template>
