export const departmentOptions = [
  { value: 0, label: "- Select Department -", attrs: { disabled: true } },
  { value: "creator", label: "Creator" },
  { value: "inside-sales", label: "Inside Sales" }, // inside sales - customer aquistion call center
  { value: "outside-sales", label: "Outside Sales" }, // outside sales - customer aquistion in the field
  { value: "compliance", label: "Compliance" }, // compliance - underwriting, quality assurance - determines if the customer is eligible for the product after sales
  { value: "support", label: "Support" }, // support - customer service
  { value: "billing", label: "Billing" }, // billing - customer service billing help
  { value: "retention", label: "Retention" }, // retention - save customers from leaving
  { value: "manager", label: "Manager" }, // manager - manages the team
  { value: "affiliate", label: "Affiliate" }, // manager - manages the team
];
