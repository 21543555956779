<script setup>
defineOptions({
  name: 'SettingsCampaignsEdit', // Give the component a name
});
import {
  //axios
  axios,

  //vue
  reactive,
  ref,
  onMounted,
  watch,
  Teleport,

  //pinia stores
  useGlobalPromptsStore,
  useGlobalToastsStore,
  useUsersStore,
  useLoginStore,

  //vue-router
  useRouter,
  useRoute,

  //utilities
  usersLevelOptions,
  departmentOptions,
  fieldsDefaultValidation,
  cleanUpperCase,

  //base api url
  BASE_API_URL,
  useTenantsCampaignsStore,
  FKsubmitForm,
} from "./../clientImports";

import {
  browserTimezone,
  timezoneOptions,
} from "./../centralized_data/timezoneOptions";
import { tenantsCampaignsTableFormatter } from "../utilities/tenants_campaigns/tenantsCampaignsTableFormatter";

const router = useRouter();
const route = useRoute();

const usersStore = useUsersStore();
const toastsStore = useGlobalToastsStore();
const promptsStore = useGlobalPromptsStore();
const tenantsCampaignsStore = useTenantsCampaignsStore();
const loginStore = useLoginStore();

//watch the usersStore.idToEdit value and update idToUpdate variable

const campaign = reactive({
  name: "",
  description: "",
});

const idToUpdate = ref(null);

let campaignToUpdate = reactive({});

const updateCampaign = async () => {
  try {
    const response = await tenantsCampaignsStore.updateTenantCampaign(campaign);
    if (response.status === "success") {
      toastsStore.addToast({
        title: "Campaign Updated",
        description: "",
        buttonExists: false,
        color: "green",
        type: 1, // 1 = small, 2 = md, 3 = lg, 4 = lg dismissable
      });
      tenantsCampaignsStore.toggleEditCampaignModal();

      tenantsCampaignsStore.fetchTenantsCampaignsListing();
    }
  } catch (error) {
    console.error("error", error);
    toastsStore.addToast({
      title: "Error Deleting Campaign",
      description: "",
      buttonExists: false,
      color: "red",
      type: 1, // 1 = small, 2 = md, 3 = lg, 4 = lg dismissable
    });
  }
};

const submitEditCampaignForm = async () => {
  FKsubmitForm("edit-tenant-campaign-form");
};

watch(
  () => tenantsCampaignsStore.idToEdit,
  async (newValue) => {
    // Update idToUpdate ref
    idToUpdate.value = newValue;

    // Fetch and update user data
    campaignToUpdate = await tenantsCampaignsStore.readCampaign(newValue);

    // update campaign reactive object with the fetched data
    campaign.name = tenantsCampaignsStore.currentlyEditing.name;
    campaign.description = tenantsCampaignsStore.currentlyEditing.description;
  }
);
</script>

<template>
  <cSidebarModal
    :default-button="false"
    sidebar-position="right"
    :show-sidebar-modal="tenantsCampaignsStore.showEditCampaignModal"
    :toggle-sidebar-modal="tenantsCampaignsStore.toggleEditCampaignModal"
    :has-backdrop="true"
  >
    <template #title> Edit Campaign </template>

    <template #body>
      <div class="w-full flex flex-col">
        <FormKit
          @submit="updateCampaign"
          id="edit-tenant-campaign-form"
          :incomplete-message="false"
          type="form"
          :actions="false"
          :submit-attrs="{
            inputClass: '',
            wrapperClass: '',
            'data-theme': `dark`,
            help: '',
            ignore: true,
          }"
        >
          <FormKit
            type="text"
            label="Name"
            form-class="w-full"
            outer-class="w-full"
            wrapper-class="w-full max-w-full"
            inner-class="w-full"
            input-class="w-full max-w-full bg-base-300 text-white"
            :value="campaign.name"
            v-model="campaign.name"
            validation="required"
          ></FormKit>
          <FormKit
            type="textarea"
            input-class="bg-base-300 text-white"
            wrapper-class="max-w-full"
            label="Description"
            :value="campaign.description"
            v-model="campaign.description"
          ></FormKit>
        </FormKit>
      </div>
    </template>

    <template #footer>
      <div class="flex flex-row justify-between">
        <button
          type="button"
          @click="tenantsCampaignsStore.toggleEditCampaignModal"
          class="btn btn-sm bg-red-500 rounded border-none text-white capitalize"
        >
          Cancel
        </button>

        <button
          type="button"
          @click.stop="submitEditCampaignForm"
          class="btn btn-sm rounded border-none text-white capitalize bg-emerald-500"
        >
          Update Campaign
        </button>
      </div>
    </template>
  </cSidebarModal>
</template>
