<script setup>
defineOptions({
  name: 'SettingsUsers', // Give the component a name
});
import {
  //axios
  axios,

  //vue
  createApp,
  ref,
  reactive,
  watch,
  computed,
  inject,
  provide,
  onMounted,
  onUnmounted,
  nextTick,
  Teleport,

  //pinia
  defineStore,
  createPinia,
  storeToRefs,

  //pinia stores
  useChatStore,
  useContactAlertsStore,
  useContactFileStore,
  useContactsStore,
  useGlobalPromptsStore,
  useGlobalToastsStore,
  useLoginStore,
  useTenantsStore,
  useUsersStore,
  useNetworkedUsersStore,

  //vue-router
  useRouter,
  useRoute,

  //formkit

  //formkit config file

  //fontawesome
  library,
  FontAwesomeIcon,
  fas,
  far,
  fab,

  //centralized data
  usersLevelOptions,
  departmentOptions,
  fieldsDefaultValidation,

  //helpers
  registerGlobalComponents,

  //base api url
  BASE_API_URL,
} from "./../clientImports";

const router = useRouter();
const route = useRoute();

// computed property to get the current route name
const currentRoute = computed(() => route.name);

// Call the useContactsStore function to get an instance of the store
const contactsStore = useContactsStore();
const usersStore = useUsersStore();
const networkedUsersStore = useNetworkedUsersStore();

const sortAndFilterUsersRules = ref(usersStore.sortAndFilterRules);

const applySortAndFilterUserRules = (rules) => {
  usersStore.applySortAndFilter(rules);
};

const toggleAddNetworkedOutUsersModal = () => {
  networkedUsersStore.toggleAddNetworkedOutUsersModal();
};
</script>

<template>
  <div class="h-full flex flex-col bg-base-100 divide-y divide-slate-400">
    <div
      class="relative w-full bg-base-300 text-white flex flex-row justify-between items-center p-1"
    >
      <div
        v-if="currentRoute !== 'settings-network-users'"
        class="flex flex-row items-center text-xs md:text-base"
      >
        <!-- create user router - goes to modals target so no need to nest -->

        <font-awesome-icon icon="fa-solid fa-users" class="ml-2" />
        <span class="ml-3 mr-3">Users</span>
        <SettingsUsersCreate />
      </div>

      <div class="" v-if="currentRoute !== 'settings-network-users'">
        <cColumnSelector
          :sortAndFilterRules="sortAndFilterUsersRules"
          :applySortAndFilterRules="applySortAndFilterUserRules"
        />
      </div>

      <!-- add networked out user -->
      <div class="" v-if="currentRoute === 'settings-network-users'">
        <font-awesome-icon
          icon="fa-solid fa-elevator"
          class="mr-2 ml-2"
          size="xs md:lg"
        />

        <span class="ml-3 mr-3 md:text-base text-xs max-sm:hidden"
          >Networked
          {{
            networkedUsersStore.currentNetworkView === "networked-out-view"
              ? "Out"
              : "In"
          }}
          Users</span
        >
        <button
          v-if="networkedUsersStore.currentNetworkView === 'networked-out-view'"
          @click="toggleAddNetworkedOutUsersModal"
          class="bg-blue-500 btn rounded text-white hover:bg-blue-700 capitalize btn-xs md:btn-sm border-none"
        >
          <span class="mr-2 hidden md:block"> Share Out User </span>
          <font-awesome-icon icon="fa-solid fa-plus" />
        </button>
      </div>

      <!-- toggle switch for in/out network view -->
      <div
        v-if="currentRoute === 'settings-network-users'"
        class="flex flex-row"
      >
        <!-- select dropdown with in / out options -->
        <select
          v-model="networkedUsersStore.currentNetworkView"
          class="select-xs md:select-sm text-white bg-gray-500 border-none rounded"
        >
          <option selected value="networked-out-view">Out</option>
          <option value="networked-in-view">In</option>
        </select>
      </div>
    </div>
    <div class="w-full overflow-y-auto flex flex-row justify-between flex-1">
      <div class="w-full overflow-y-auto flex flex-row justify-between flex-1">
        <div
          class="flex-1 h-full max-w-full overflow-y-auto hide-scroll text-white"
        >
          <router-view></router-view>
        </div>
      </div>
    </div>
    <div
      class="w-full p-1 md:p-3 bg-base-300 text-white flex flex-row justify-center"
    >
      <div class="bg-base-300">
        <router-link
          :to="{ name: 'settings-list-users' }"
          class="btn btn-xs md:btn-sm rounded-l rounded-r-none border-none text-white bg-gray-500 hover:bg-indigo-600 hover:text-white cursor-pointer"
          active-class="disabled bg-indigo-400 text-white"
        >
          <span class="capitalize">Internal</span>
        </router-link>
        <router-link
          :to="{ name: 'settings-network-users' }"
          class="btn btn-xs md:btn-sm rounded-r rounded-l-none border-none text-white bg-gray-500 hover:bg-indigo-600 hover:text-white cursor-pointer"
          active-class="disabled bg-indigo-400 text-white"
        >
          <span class="capitalize">Network</span>
        </router-link>
      </div>
    </div>
  </div>
</template>
