<script setup>
defineOptions({
  name: 'cPayWall', // Give the component a name
});
import {
  //axios
  axios,
  ref,
  computed,
  reactive,
  useGlobalToastsStore,
  useLoginStore,

  //vue-router
  useRouter,
  useRoute,

  //centralized data
  expirationMonths,
  expirationYears,
  stateOptions,

  //base api url
  BASE_API_URL,
} from "./../clientImports";

// Call the useLoginStore function to get an instance of the store
const loginStore = useLoginStore();

const toastsStore = useGlobalToastsStore();

const router = useRouter();
const route = useRoute();

// TODO - un commment these and delete static credentials before launch
// const companyName = ref("");
// const email = ref("");
// const password = ref("");

const makePayment = ref(false);

let paywallData = ref({
  //card info
  card_type: "",
  name_on_card: "",
  card_number: "",
  cvv: "",
  expiration_month: "",
  expiration_year: "",

  //billing info
  billing_address: "",
  billing_apt_unit: "",
  billing_city: "",
  billing_state: "",
  billing_zip: "",
});

const dynamicCvv = computed(() => {
  const cardType = paywallData.card_type;

  if (cardType === "amex") {
    return [["required"], ["number"], ["matches", /^\d{4}$/]];
  } else {
    return [["required"], ["number"], ["matches", /^\d{3}$/]];
  }
});

const processPayment = async () => {
  try {
    const response = await axios.post(`${BASE_API_URL}/process-payment`, {});

    const sessionData = response.data;

    // xLog("Sesssion Data: ", sessionData);
    // xLog("Login Response Status: ", response.data.status);

    if (response.data.status === "success") {
      // use the loginStore to set the user data
      loginStore.setSessionData(sessionData);

      // show toast
      toastsStore.addToast({
        title: "Login Successful",
        description: `Welcome ${loginStore.sessionData.foundUser.first_name}! 😃`,
        buttonExists: false,
        color: "emerald",
        type: 1, // 1 = small, 2 = md, 3 = lg, 4 = lg dismissable
      });
      router.push({ name: "dashboard-tab" });
    }
  } catch (error) {
    console.log(error);
  }
};
</script>

<template>
  <div
    :class="[
      makePayment
        ? 'bg-gradient-to-br from-emerald-300 to-emerald-600'
        : 'bg-gradient-to-br from-red-300 to-red-600',
    ]"
    class="p-3 flex flex-col justify-start md:justify-center items-center h-screen overflow-y-auto"
  >
    <!-- Form -->

    <div class="bg-white p-3 rounded w-full md:w-96">
      <div class="w-full text-center">
        <cSystemLogo></cSystemLogo>
      </div>
      <h3
        :class="[
          makePayment
            ? 'bg-gradient-to-br from-emerald-300 to-emerald-600'
            : 'bg-gradient-to-br from-red-300 to-red-600',
        ]"
        class="text-lg text-center text-white mb-8 bg- mt-2"
      >
        {{
          makePayment ? "Renew Subscription" : "Your Subscription Has Expired"
        }}
      </h3>

      <div class="flex flex-row justify-between items-center">
        <button
          v-if="!makePayment"
          @click="makePayment = true"
          class="bg-gradient-to-br from-emerald-300 to-emerald-600 w-full max-w-full rounded py-3 px-2 text-white"
        >
          Renew It
        </button>
        <!-- <button
          class="bg-gradient-to-br from-emerald-300 to-emerald-600 w-full max-w-full rounded py-3 px-2 text-white"
        >
          Delete Account
        </button> -->
      </div>

      <div v-if="!makePayment" class="text-center text-gray-500 p-3">
        <h4>Contact Support</h4>
      </div>

      <div
        v-if="!makePayment"
        class="grid grid-cols-2 flex-row justify-center items-center gap-5"
      >
        <a
          href="mailto:support@finnypi.com"
          class="bg-gradient-to-br from-red-300 to-red-600 rounded text-white btn btn-md capitalize border-none"
        >
          Email
        </a>
        <button
          class="bg-gradient-to-br from-purple-300 to-purple-600 rounded text-white btn btn-md capitalize border-none"
        >
          Chat
        </button>
        <!-- router link to login page -->
        <router-link
          to="/login"
          class="bg-gradient-to-br from-indigo-300 to-indigo-600 rounded text-white btn btn-md capitalize border-none"
        >
          Sign In
        </router-link>
        <!-- router link to website page -->
        <router-link
          to="/"
          class="bg-base-100 rounded text-white btn btn-md capitalize border-none"
        >
          Website
        </router-link>
      </div>

      <FormKit
        v-if="makePayment"
        type="form"
        submit-label="Login"
        actions-class="mt-8"
        :actions="false"
        @submit="processPayment"
        :submit-attrs="{
          wrapperClass: 'flex flex-row justify-between',
          inputClass:
            '$reset bg-blue-500 w-full max-w-full rounded py-3 px-2 text-white',
          ignore: false,
        }"
      >
        <h1 class="mb-5 text-slate-700 text-center md:hidden">
          Payment Details
        </h1>
        <div class="grid grid-cols-1 md:grid-cols-4 gap-x-4">
          <FormKit
            type="select"
            label="Card Type"
            :options="[
              {
                value: 0,
                label: '- Select Card Type -',
                attrs: { disabled: true },
              },
              {
                value: 'visa',
                label: 'Visa',
              },
              {
                value: 'mastercard',
                label: 'Mastercard',
              },
              {
                value: 'amex',
                label: 'American Express',
              },
              {
                value: 'discover',
                label: 'Discover',
              },
            ]"
            input-class="$reset select select-sm rounded bg-base-300 text-white w-full"
            inner-class="mt-2 mb-2"
            outer-class="$reset text-gray-700 col-span-4 md:col-span-2"
            validation="required|alphanumeric"
            wrapper-class="w-full max-w-full"
            message-class="bg-red-500 rounded text-xs text-white p-1"
            v-model="paywallData.card_type"
          />

          <FormKit
            label-class="text-gray-700"
            inner-class="mt-2 mb-2"
            label="Cardholder Name"
            type="text"
            outer-class="col-span-4 md:col-span-2"
            input-class="input input-sm rounded bg-base-300 text-white w-full"
            validation="required|length:3,70"
            :validation-messages="{
              length: 'Must be between 3 and 70 characters',
            }"
            messages-class="relative"
            message-class="bg-red-500 rounded text-xs text-white p-1"
            v-model="paywallData.name_on_card"
          />

          <FormKit
            label-class="text-gray-700"
            inner-class="mt-2 mb-2"
            label="Card Number"
            type="text"
            outer-class="col-span-4 md:col-span-3"
            input-class="input input-sm rounded bg-base-300 text-white w-full"
            :validation="[
              ['required'],
              ['number'],
              ['matches', /^(?:\S{13}|\S{15}|\S{16})$/],
            ]"
            :validation-messages="{
              matches: 'Must be 13, 15 or 16 digits. No spaces or dashes.',
            }"
            messages-class="relative"
            message-class="bg-red-500 rounded text-xs text-white p-1"
            v-model="paywallData.card_number"
          />

          <FormKit
            label-class="text-gray-700"
            inner-class="mt-2 mb-2"
            label="CVV"
            outer-class="col-span-4 md:col-span-1"
            type="text"
            input-class="input input-sm rounded bg-base-300 text-white w-full"
            :validation="dynamicCvv"
            :validation-messages="cvvValidationMessage"
            messages-class=""
            message-class="bg-red-500 rounded text-xs text-white p-1"
            v-model="paywallData.cvv"
          />

          <FormKit
            label-class="text-gray-700"
            inner-class="mt-2 mb-2"
            label="Expiration Month"
            outer-class="col-span-4 md:col-span-2"
            help-class="absolute top-3 text-xs"
            :options="expirationMonths"
            type="select"
            input-class="$reset select select-sm rounded bg-base-300 text-white w-full"
            validation="required|alphanumeric"
            :validation-messages="{ required: 'Select Expiration Month' }"
            messages-class=""
            message-class="bg-red-500 rounded text-xs text-white p-1"
            v-model="paywallData.expiration_month"
          />

          <FormKit
            label-class="text-gray-700"
            inner-class="mt-2 mb-2"
            label="Expiration Year"
            outer-class="col-span-4 md:col-span-2"
            help-class="absolute top-3 text-xs"
            :options="expirationYears"
            type="select"
            input-class="$reset select select-sm rounded bg-base-300 text-white w-full"
            validation="required|alphanumeric"
            :validation-messages="{ required: 'Select Expiration Year' }"
            messages-class=""
            message-class="bg-red-500 rounded text-xs text-white p-1"
            v-model="paywallData.expiration_year"
          />
        </div>
        <div class="grid grid-cols-4 md:grid-cols-4 gap-x-4">
          <FormKit
            label-class="text-gray-700"
            inner-class="mt-2 mb-2"
            label="Billing Street Address"
            outer-class="col-span-4 md:col-span-4"
            type="text"
            input-class="input input-sm rounded bg-base-300 text-white w-full"
            validation="required|length:4,70"
            :validation-messages="{
              length: 'Must be between 4 and 70 characters',
            }"
            messages-class=""
            message-class="bg-red-500 rounded text-xs text-white p-1"
            v-model.trim="paywallData.billing_address"
          />
          <FormKit
            label-class="text-gray-700"
            inner-class="mt-2 mb-2"
            label="Apt / Unit"
            outer-class="col-span-4 md:col-span-2"
            type="text"
            input-class="input input-sm rounded bg-base-300 text-white w-full"
            messages-class=""
            message-class="bg-red-500 rounded text-xs text-white p-1"
            v-model="paywallData.billing_apt_unit"
          />
          <FormKit
            label-class="text-gray-700"
            inner-class="mt-2 mb-2"
            label="City"
            outer-class="col-span-4 md:col-span-2"
            type="text"
            input-class="input input-sm rounded bg-base-300 text-white w-full"
            validation="required|length:2,70"
            :validation-messages="{
              length: 'Must be between 2 and 70 characters',
            }"
            messages-class=""
            message-class="bg-red-500 rounded text-xs text-white p-1"
            v-model.trim="paywallData.billing_city"
          />
          <FormKit
            label-class="text-gray-700"
            inner-class="mt-2 mb-2"
            label="State"
            :options="stateOptions"
            outer-class="col-span-4 md:col-span-2"
            type="select"
            input-class="$reset select select-sm rounded bg-base-300 text-white w-full"
            validation="required"
            :validation-messages="{ required: 'Choose State' }"
            messages-class=""
            message-class="bg-red-500 rounded text-xs text-white p-1"
            v-model="paywallData.billing_state"
          />
          <FormKit
            label-class="text-gray-700"
            inner-class="mt-2 mb-2"
            label="Zip"
            outer-class="col-span-4 md:col-span-2"
            type="text"
            input-class="input input-sm rounded bg-base-300 text-white w-full"
            :validation="[['required'], ['number'], ['matches', /^\d{5}$/]]"
            :validation-messages="{ matches: 'Must be 5 digits.' }"
            messages-class=""
            message-class="bg-red-500 rounded text-xs text-white p-1"
            v-model="paywallData.billing_zip"
          />
        </div>

        <FormKit
          type="submit"
          label="Process Payment"
          :ingore="false"
          input-class="$reset bg-gradient-to-br from-emerald-300 to-emerald-600 w-full max-w-full rounded py-3 px-2 text-white mt-7"
        />
      </FormKit>
    </div>
  </div>
</template>
