import {
  //axios
  axios,

  //pinia
  defineStore,

  //utils
  sortAndFilterProperties,

  //env base url
  BASE_API_URL,

  // utils
  xLog,
} from "../clientImports";

export const useUsersStore = defineStore({
  id: "users",
  state: () => ({
    //variables
    rolesOptions: {},
    affiliateGroupOptions: null,
    showCreateUserModal: false,
    showEditUserModal: false,
    idToEdit: null,
    fetchedUsers: null,
    sortRules: { sortBy: "created_at", sortDirection: "asc" },
    sortAndFilterRules: JSON.parse(
      localStorage.getItem("usersListColumnModifiers")
    ) || [
      { label: "_id", value: true },
      { label: "first_name", value: true },
      { label: "last_name", value: true },
      { label: "email", value: true },
      { label: "department", value: true },
      { label: "affiliate_group", value: false },
      { label: "role", value: true },
      { label: "level_access", value: true },
      { label: "active", value: true },
      { label: "created_at", value: true },
      { label: "user_phone_number", value: false },
      { label: "phone_caller_id", value: false },
      { label: "user_phone_extension", value: false },
      { label: "passport_required", value: false },
      { label: "alert_email", value: false },
      { label: "timezone", value: false },
      { label: "updated_at", value: false },
      { label: "created_by", value: false },
      { label: "updated_by", value: false },
      { label: "tenant_id", value: false },
    ],
  }),
  actions: {
    async toggleCreateUserModal() {
      this.showCreateUserModal = !this.showCreateUserModal;
    },

    async fetchAllAffiliateGroups() {
      const { data } = await axios.get(`${BASE_API_URL}/read-affiliate-groups`);
      console.log(data);

      //set this only on the store - no need to return it afterward
      this.affiliateGroupOptions = data.affiliateGroups.map((group) => {
        return {
          label: `${group.abbreviation.toUpperCase()} - ${group.name}`,
          value: group._id,
        };
      });
    },

    async fetchAllRoles() {
      const results = await axios.get(`${BASE_API_URL}/read-roles`);

      this.rolesOptions = results.data.roles.map((role) => {
        return {
          label: `${role.display_name} - ${role.description}`,
          value: { tenant_id: role.tenant_id, role_id: role._id },
        };
      });
    },

    async createUser(user) {
      try {
        const response = await axios.post(`${BASE_API_URL}/create-user`, user);
        return response.data;
      } catch (error) {
        return error.response.data;
      }
    },

    async readUser(id) {
      const response = await axios.get(`${BASE_API_URL}/read-user/${id}`);

      return response.data.user;
    },

    //column sorters
    async applySortByColumn(rules) {
      this.sortRules = rules;

      if (
        this.sortRules.sortBy === null &&
        this.sortRules.sortDirection === null
      ) {
        this.sortRules = { sortBy: "created_at", sortDirection: "asc" };
      }

      this.readUsers();
    },

    //column selector modifier
    async applySortAndFilter(rules) {
      this.sortAndFilterRules = rules;

      //add sortAndFilterRules to localStorage
      localStorage.setItem(
        "usersListColumnModifiers",
        JSON.stringify(this.sortAndFilterRules)
      );

      this.readUsers();
    },

    async readUsers() {
      const response = await axios.get(`${BASE_API_URL}/read-users`, {
        params: this.sortRules,
      });

      this.fetchedUsers = sortAndFilterProperties(
        this.sortAndFilterRules,
        response.data.users
      );
    },

    setIdToEdit(id) {
      this.idToEdit = id;
    },

    toggleEditUserModal() {
      this.showEditUserModal = !this.showEditUserModal;
    },

    async updateUser(id, userUpdateData) {
      try {
        const response = await axios.patch(
          `${BASE_API_URL}/update-user/${id}`,
          {
            userUpdateData,
          }
        );

        return response.data;
      } catch (error) {
        console.error(error);
      }
    },

    async deleteUser(id) {
      try {
        const response = await axios.delete(
          `${BASE_API_URL}/delete-user/${id}`
        );

        return response.data;
      } catch (error) {
        console.log(error.response.data);
        return error.response.data;
      }
    },

    //delete local storage items
    deleteLocalStorageData() {
      //delete all local storage items
    },
  },
});
