import {
  //axios
  axios,

  //pinia
  defineStore,

  //env base url
  BASE_API_URL,
} from "../clientImports";

export const useGlobalUploadStore = defineStore({
  id: "global-upload-store",
  state: () => ({
    fileData: false, //the post containing the file
  }),
  actions: {
    //to be chained
    async uploadFile(formData) {
      try {
        const response = await axios.post(
          `${BASE_API_URL}/upload-file`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        return response; // Return the response data
      } catch (error) {
        console.error("Error uploading file:", error);
        throw error; // Rethrow the error for handling it outside the store
      }
    },

    async getFile(fileKey) {
      try {
        const response = await axios.get(`${BASE_API_URL}/get-file/${fileKey}`);
        return response;
      } catch (error) {
        console.error("Error fetching file:", error);
        throw error;
      }
    },

    async downloadFile(fileKey) {
      console.log("fileKey STORE: ", fileKey);
      try {
        const response = await axios.get(
          `${BASE_API_URL}/download-file/${fileKey}`
        );
        return response;
      } catch (error) {
        console.error("Error downloading file:", error);
        throw error;
      }
    },

    async deleteFile(id, key, contactId) {
      try {
        const response = await axios.delete(
          `${BASE_API_URL}/delete-file/${id}?key=${key}&contactId=${contactId}`
        );

        return response;
      } catch (error) {
        console.error("Error deleting file:", error);
        throw error;
      }
    },

    async markFileViewed(id, contactId) {
      try {
        const response = await axios.patch(
          `${BASE_API_URL}/mark-file-viewed/${id}?contactId=${contactId}`
        );

        return response;
      } catch (error) {
        console.error("Error marking file as viewed:", error);
        throw error;
      }
    },

    //updateAttachmentType
    async updateAttachmentType(id, type, contactId) {
      console.log("ATTACHMENT TYPE STORE HIT - updateAttachmentType");
      try {
        const response = await axios.patch(
          `${BASE_API_URL}/update-attachment-type/${id}?contactId=${contactId}&type=${type}`
        );

        return response;
      } catch (error) {
        console.error("Error updating attachment type:", error);
        throw error;
      }
    },
  },
});
