<script setup>
defineOptions({
  name: 'SettingsRoles', // Give the component a name
});
import {
  //axios
  axios,

  //vue
  createApp,
  ref,
  reactive,
  watch,
  computed,
  inject,
  provide,
  onMounted,
  onUnmounted,
  nextTick,
  Teleport,

  //pinia
  defineStore,
  createPinia,
  storeToRefs,

  //pinia stores
  useChatStore,
  useContactAlertsStore,
  useContactFileStore,
  useContactsStore,
  useGlobalPromptsStore,
  useGlobalToastsStore,
  useLoginStore,
  useTenantsStore,
  useRolesStore,

  //vue-router
  useRouter,
  useRoute,

  //formkit

  //formkit config file

  //fontawesome
  library,
  FontAwesomeIcon,
  fas,
  far,
  fab,

  //global components - all vue components
  registerGlobalComponents,

  //base api url
  BASE_API_URL,
} from "./../clientImports";

const rolesStore = useRolesStore();
</script>

<template>
  <div class="h-full flex flex-col bg-base-100">
    <div
      class="flex flex-row items-center w-full p-1 border-b border-b-slate-400 bg-base-300 text-white"
    >
      <font-awesome-icon icon="fa-brands fa-redhat" class="ml-2" /><span
        class="ml-3"
        >Roles</span
      >
      <div class="pl-2">
        <SettingsRolesCreate></SettingsRolesCreate>
      </div>
    </div>
    <div
      class="w-full overflow-y-auto overflow-x-auto flex flex-row justify-between flex-1"
    >
      <router-view></router-view>
    </div>
    <div class="w-full p-3 bg-base-300 text-white flex flex-row justify-end">
      <!-- Other -->
    </div>
  </div>
</template>
