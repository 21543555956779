import {
  //axios
  axios,

  //pinia
  defineStore,
  useGlobalToastsStore,

  //env base url
  BASE_API_URL,
} from "../clientImports";

export const useTenantsFormsStore = defineStore({
  id: "tenants-forms",
  state: () => ({
    showFormFieldAdderModal: false,
    editingFieldType: "", // text, number, date, time, toggle, select, radio, checkbox - each field type needs it's own form

    fetchedSystemFields: [],
    fetchedCustomFields: [],
    currentlyEditingFormId: "",
    currentlyEditingForm: {},
    fetchedCustomForms: [],

    searchField: "",
    filterByType: [],
  }),
  actions: {
    openFormFieldAdderModal() {
      this.showFormFieldAdderModal = true;
    },
    closeFormFieldAdderModal() {
      this.showFormFieldAdderModal = false;
    },
    async createFormField(fieldObj) {
      //check if conditionalObjectBuild.validation array has a nested array with the first index being "matches" and if it does,  then the second item in the array needs to be returned string to itself
      const matchesIndex = fieldObj.validation.findIndex((innerArray) =>
        innerArray.includes("matches")
      );

      if (matchesIndex !== -1) {
        fieldObj.validation[matchesIndex][1] =
          fieldObj.validation[matchesIndex][1].toString();
      }

      console.log("ABOUT TO CREATE FIELD", fieldObj);

      const { data } = await axios.post(
        `${BASE_API_URL}/tenants-create-form-field`,
        fieldObj
      );

      if (data.status === "success") {
        const toastStore = useGlobalToastsStore();

        this.readFormFields();

        toastStore.addToast({
          title: "Field Created Successfully",
          buttonExists: false,
          color: "emerald",
          type: 2, //
        });

        this.showFormFieldAdderModal = false;
      }
    },
    async readFormFields() {
      const { data } = await axios.get(
        `${BASE_API_URL}/tenants-read-form-fields`
      );
      console.log("FORM FIELDS", data);

      if (data.status === "success") {
        this.fetchedCustomFields = data.fields;
      }
    },
    async createForm(formObj) {
      console.log("ABOUT TO CREATE FORM", formObj);

      const { data } = await axios.post(
        `${BASE_API_URL}/tenants-create-form`,
        formObj
      );

      if (data.status === "success") {
        const toastStore = useGlobalToastsStore();
        this.readForms();

        toastStore.addToast({
          title: "Form Created Successfully",
          buttonExists: false,
          color: "emerald",
          type: 2, //
        });
      }
    },

    async readForm(formId) {
      const id = formId;

      const { data } = await axios.get(
        `${BASE_API_URL}/tenants-read-form/${id}`
      );
      console.log("FORM", data);

      if (data.status === "success") {
        this.currentlyEditingForm = data.form;
      }
    },

    async readForms() {
      const { data } = await axios.get(`${BASE_API_URL}/tenants-read-forms`);
      console.log("FORMS", data);

      if (data.status === "success") {
        this.fetchedCustomForms = data.forms;

        console.log(
          "THE CONTACT FILE CURRENT CUSTOM FORMS: ",
          this.fetchedCustomForms
        );
      }
    },

    async updateForm(formId, formObj) {
      const id = formId;

      const { data } = await axios.put(
        `${BASE_API_URL}/tenants-update-form/${id}`,
        formObj
      );

      if (data.status === "success") {
        const toastStore = useGlobalToastsStore();
        this.readForms();

        toastStore.addToast({
          title: "Form Updated Successfully",
          buttonExists: false,
          color: "emerald",
          type: 2, //
        });
      }
    },

    async deleteForm(formId) {
      const id = formId;

      const { data } = await axios.delete(
        `${BASE_API_URL}/tenants-delete-form/${id}`
      );

      if (data.status === "success") {
        const toastStore = useGlobalToastsStore();
        this.readForms();

        toastStore.addToast({
          title: "Form Deleted Successfully",
          buttonExists: false,
          color: "emerald",
          type: 2, //
        });
      }
    },
  },
});
