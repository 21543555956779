<script setup>
defineOptions({
  name: 'SettingsBotsCreate', // Give the component a name
});
import {
  //axios
  axios,

  //vue
  createApp,
  ref,
  reactive,
  watch,
  computed,
  inject,
  provide,
  onMounted,
  onUnmounted,
  nextTick,
  Teleport,

  //pinia
  defineStore,
  createPinia,
  storeToRefs,

  //pinia stores
  useChatStore,
  useContactAlertsStore,
  useContactFileStore,
  useContactsStore,
  useGlobalPromptsStore,
  useGlobalToastsStore,
  useLoginStore,
  useTenantsStore,
  useUsersStore,
  useTenantsBotsStore,
  useTenantsWorkflowStatusesStore,

  //vue-router
  useRouter,
  useRoute,

  //formkit

  //formkit config file
  FKsubmitForm,

  //fontawesome
  library,
  FontAwesomeIcon,
  fas,
  far,
  fab,

  //centralized data
  usersLevelOptions,
  fieldsDefaultValidation,
  stateOptions,

  //utilities
  getRandomNumberBetween,

  //helpers
  registerGlobalComponents,

  //utilities
  stripEmptyFields,

  //base api url
  BASE_API_URL,
} from "./../clientImports";

import {
  browserTimezone,
  timezoneOptions,
} from "./../centralized_data/timezoneOptions";

const router = useRouter();
const route = useRoute();

const loginStore = useLoginStore();

const usersStore = useUsersStore();
const toastsStore = useGlobalToastsStore();
const promptsStore = useGlobalPromptsStore();
const tenantsBotsStore = useTenantsBotsStore();
const tenantsWorkflowStatusesStore = useTenantsWorkflowStatusesStore();

const botRoutines = ref([]);

const getBotRoutineTargetOptions = async (index, targetListType) => {
  //5 different switch statements where they each do a fetch for the target options
  //based on this list here:

  /*
      change-workflow-status
      send-email
      send-sms
      notify-user
      send-webhook
      change-payment-plan-status
      change-email-opt-in-status
      change-sms-opt-in-status
      change-calls-opt-in-status
      change-mailers-opt-in-status
      change-file-type
      change-esign-status
      change-campaign
      send-client-portal-invite
      leave-note
      create-task
      create-event
      assign-user
      */

  console.log("THE TYPE: ", targetListType);

  switch (targetListType) {
    case "tenants_workflow_statuses":
      //fetch the workflow statuses
      console.log("FETCHING WORKFLOW STATUSES");

      //fetch the workflow statuses

      await tenantsWorkflowStatusesStore.fetchTenantsWorkflowStatuses();

      //to test
      // botRoutines.value[index].target_options = {
      //   value: null,
      //   label: "- Choose Target -",
      // };

      console.log(
        tenantsWorkflowStatusesStore.tenantsWorkflowStatusesList.map(
          (workflowStatus) => {
            return {
              value: workflowStatus.id,
              label: workflowStatus.name,
            };
          }
        )
      );

      botRoutines.value[index].target_options =
        tenantsWorkflowStatusesStore.tenantsWorkflowStatusesList.map(
          (workflowStatus) => {
            return {
              value: workflowStatus.id,
              label: workflowStatus.name,
            };
          }
        );

      break;
    case "tenants_templates_emails":
      //fetch the email templates
      console.log("FETCHING EMAIL TEMPLATES");
      break;
    case "tenants_templates_sms":
      //fetch the sms templates
      console.log("FETCHING SMS TEMPLATES");
      break;
    case "tenants_templates_notifications":
      //fetch the users
      console.log("FETCHING USERS");
      break;
    case "tenants_webhooks_outbound":
      //fetch the webhooks
      console.log("FETCHING OUTBOUND WEBHOOKS");
      break;
    case "tenants_payment_plan_statuses":
      //fetch the payment plan statuses
      console.log("FETCHING PAYMENT PLAN STATUSES");
      break;
    case "change-email-opt-in-status": // static options set with 1 to 1 update function
      //fetch the email opt in statuses
      console.log("FETCHING EMAIL OPT IN STATUSES");
      break;
    case "change-sms-opt-in-status": // static options set with 1 to 1 update function
      //fetch the sms opt in statuses
      console.log("FETCHING SMS OPT IN STATUSES");
      break;
    case "change-calls-opt-in-status": // static options set with 1 to 1 update function
      //fetch the calls opt in statuses
      console.log("FETCHING CALLS OPT IN STATUSES");
      break;
    case "change-mailers-opt-in-status": // static options set with 1 to 1 update function
      //fetch the mailers opt in statuses
      console.log("FETCHING MAILERS OPT IN STATUSES");
      break;
    case "tenants_contact_types":
      //fetch the file types
      console.log("FETCHING FILE TYPES");
      break;
    case "change-esign-status": // static options set with 1 to 1 update function
      //fetch the esign statuses
      console.log("FETCHING ESIGN STATUSES");
      break;
    case "tennats_campaigns":
      //fetch the campaigns
      console.log("FETCHING CAMPAIGNS");
      break;
    case "send-client-portal-invite": // RESEARCH - not sure how to implement this yet
      //fetch the users
      console.log("FETCHING CLIENT PORT INVITE");
      break;
    case "tenants_templates_notes":
      //fetch the users
      console.log("FETCHING TEMPLATE NOTES");
      break;
    case "tenants_templates_tasks":
      //fetch the users
      console.log("FETCHING TEMPLATE TASKS");
      break;
    case "tenants_template_events":
      //fetch the users
      console.log("FETCHING TEMPLATE EVENTS");
      break;
    case "users":
      //fetch the users
      console.log("FETCHING USERS");
      break;
  }
};

//use FKsubmitForm to submit form
const submitCreateForm = () => {
  return FKsubmitForm("create-bot");
};

const addRoutineItem = () => {
  botRoutines.value.push({
    type: null,
    target_id: null,
    target_options: [], //dynamically populated based on type
  });
};
</script>

<template>
  <cSidebarModal
    sidebar-position="right"
    :show-sidebar-initial="false"
    :show-sidebar-modal="tenantsBotsStore.showTenantsBotsCreateModal"
    :toggle-sidebar-modal="tenantsBotsStore.toggleTenantsBotsCreateModal"
    :default-button="false"
  >
    <template #title>
      <h2>Create Bot</h2>
    </template>

    <template #body>
      <FormKit
        id="create-bot"
        type="form"
        :incomplete-message="false"
        submit-label="Create"
        form-class="text-white h-full flex flex-col justify-between"
        @submit="tenantsBotsStore.createTenantsBot(botRoutines)"
        outer-class="m-0"
        :actions="false"
        :submit-attrs="{
          'data-theme': `light`,
          ignore: true,
        }"
      >
        <div class="grid grid-cols-1 gap-5">
          <div class="flex flex-row gap-5">
            <FormKit
              type="text"
              label="Name"
              placeholder=""
              :validation="'required:trim|length:5, 40'"
              help-class="text-white"
              help=""
              outer-class="m-0 flex-1"
              input-class="$reset rounded w-full input input-sm text-gray-700 bg-gray-50"
              wrapper-class="max-w-full"
              v-model.trim="tenantsBotsStore.currentlyCreatingBot.name"
            />

            <FormKit
              outer-class="col-span-1"
              type="toggle"
              name="toggle"
              label="Active"
              alt-label-position
              v-model="tenantsBotsStore.currentlyCreatingBot.active"
              :value="tenantsBotsStore.currentlyCreatingBot.active"
            />
          </div>

          <FormKit
            type="textarea"
            label="Description"
            placeholder=""
            :validation="'trim|length:1, 255'"
            help-class="text-white"
            help=""
            outer-class="m-0 "
            input-class="$reset rounded w-full input input-sm text-gray-700 bg-gray-50"
            wrapper-class="max-w-full"
            v-model.trim="tenantsBotsStore.currentlyCreatingBot.description"
          />

          <div class="border border-slate-400 p-2 flex flex-col gap-4 rounded">
            <div class="flex flex-row items-center">
              <h1>Add Routine</h1>
              <button
                type="button"
                @click="addRoutineItem"
                class="btn btn-sm ml-auto border border-slate-400 hover:bg-gray-100 hover:text-gray-600"
              >
                <font-awesome-icon icon="fa-solid fa-plus" />
              </button>
            </div>

            <div
              v-for="(status, i) in botRoutines"
              :key="i"
              class="flex flex-row items-end gap-4"
            >
              <FormKit
                type="select"
                label="Automation Type"
                placeholder=""
                help-class="text-white"
                outer-class="m-0 flex-1"
                @change="getBotRoutineTargetOptions(i, $event.target.value)"
                input-class="$reset rounded w-full input input-sm text-gray-700 bg-gray-50"
                wrapper-class="max-w-full"
                v-model="botRoutines[i].type"
                :options="[
                  {
                    value: null,
                    label: '- Choose Type -',
                  },
                  {
                    value: 'tenants_workflow_statuses',
                    label: 'Change Workflow Status',
                  },
                  {
                    value: 'tenants_templates_emails',
                    label: 'Send Email - COMING SOON',
                    attrs: {
                      disabled: true,
                    },
                  },
                  {
                    value: 'tenants_templates_sms',
                    label: 'Send SMS - COMING SOON',
                    attrs: {
                      disabled: true,
                    },
                  },
                  {
                    value: 'notify-user',
                    label: 'Notify a User - COMING SOON',
                    attrs: {
                      disabled: true,
                    },
                  },
                  {
                    value: 'tenants_templates_webhooks',
                    label: 'Send Webhook - COMING SOON',
                    attrs: {
                      disabled: true,
                    },
                  },
                  {
                    value: 'change-payment-plan-status',
                    label: 'Change Payment Plan Status - COMING SOON',
                    attrs: {
                      disabled: true,
                    },
                  },
                  {
                    value: 'change-email-opt-in-status',
                    label: 'Change Email Opt-In Status - COMING SOON',
                    attrs: {
                      disabled: true,
                    },
                  },
                  {
                    value: 'change-sms-opt-in-status',
                    label: 'Change SMS Opt-In Status - COMING SOON',
                    attrs: {
                      disabled: true,
                    },
                  },
                  {
                    value: 'change-calls-opt-in-status',
                    label: 'Change Calls Opt-In Status - COMING SOON',
                    attrs: {
                      disabled: true,
                    },
                  },
                  {
                    value: 'change-mailers-opt-in-status',
                    label: 'Change Mailers Opt-In Status - COMING SOON',
                    attrs: {
                      disabled: true,
                    },
                  },
                  {
                    value: 'tenants_contact_types',
                    label: 'Change File Type - COMING SOON',
                    attrs: {
                      disabled: true,
                    },
                  },
                  {
                    value: 'change-esign-status',
                    label: 'Change E-Sign Status - COMING SOON',
                    attrs: {
                      disabled: true,
                    },
                  },
                  {
                    value: 'tenants_campaigns',
                    label: 'Change Campaign - COMING SOON',
                    attrs: {
                      disabled: true,
                    },
                  },
                  {
                    value: 'send-client-portal-invite',
                    label: 'Send Client Portal Invite - COMING SOON',
                    attrs: {
                      disabled: true,
                    },
                  },
                  {
                    value: 'tenants_templates_notes',
                    label: 'Leave a Note - COMING SOON',
                    attrs: {
                      disabled: true,
                    },
                  },
                  {
                    value: 'tenants_templates_tasks',
                    label: 'Create a Task - COMING SOON',
                    attrs: {
                      disabled: true,
                    },
                  },
                  {
                    value: 'tenants_templates_events',
                    label: 'Create an Event - COMING SOON',
                    attrs: {
                      disabled: true,
                    },
                  },
                  {
                    value: 'users',
                    label: 'Assign a User - COMING SOON',
                    attrs: {
                      disabled: true,
                    },
                  },
                ]"
              />
              <FormKit
                type="select"
                label="Automation Type Target"
                placeholder=""
                help-class="text-white"
                outer-class="m-0 flex-1"
                input-class="$reset rounded w-full input input-sm text-gray-700 bg-gray-50"
                wrapper-class="max-w-full"
                v-model="botRoutines[i].target_id"
                :options="[
                  {
                    value: null,
                    label: '- Choose Target -',
                  },
                  ...botRoutines[i].target_options,
                ]"
              />
              <button
                type="button"
                @click="botRoutines.splice(i, 1)"
                class="btn btn-sm border border-red-400 text-red-500 hover:bg-red-500 hover:text-white"
              >
                <font-awesome-icon icon="fa-solid fa-trash" />
              </button>
            </div>
          </div>
        </div>
      </FormKit>
    </template>

    <template #footer>
      <!-- BUTTON FOOTER - CANCEL & UPDATE Bot -->
      <div class="flex flex-row justify-between text-white w-full">
        <button
          @click="tenantsBotsStore.toggleTenantsBotsCreateModal"
          class="btn btn-xs md:btn-sm rounded bg-red-500 text-white hover:bg-red-700"
        >
          Cancel
        </button>
        <button
          @click="submitCreateForm"
          class="btn btn-xs md:btn-sm rounded bg-emerald-500 text-white hover:bg-emerald-700"
        >
          Create Bot
        </button>
      </div>
    </template>
  </cSidebarModal>
</template>
