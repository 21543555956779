<script setup>
defineOptions({
  name: 'cReusableComponents', // Give the component a name
});
import { ref, useRoute, useRouter, xLog } from "./../clientImports";

const router = useRouter();
const route = useRoute();

const reusableRouteChildren = router.options.routes.find(
  (route) => route.name === "reusable-components"
).children;

xLog("reusableRouteChildren: ", reusableRouteChildren);

const showReusables = ref(false);

const toggleReusables = () => {
  showReusables.value = !showReusables.value;
};
</script>

<template>
  <div class="h-screen w-screen bg-base-100 flex flex-col">
    <button
      class="fixed top-0 left-0 btn btn-xs md:btn-sm rounded-none text-white"
      @click="toggleReusables"
    >
      <font-awesome-icon icon="fa-solid fa-bars" />
    </button>
    <div
      @click="toggleReusables"
      v-if="showReusables"
      class="fixed top-0 left-0 h-screen w-screen backdrop-blur-sm"
    >
      <div class="flex flex-row overflow-x-auto hide-scroll">
        <router-link
          v-for="(reusable, index) in reusableRouteChildren"
          :key="index"
          :to="{ name: reusable.name }"
          class="btn capitalize border-none rounded-none bg-base-100 text-white p-0"
        >
          {{ reusable.name }}
        </router-link>
      </div>
    </div>
    <div class="flex-1 bg-base-100 flex flex-col justify-center items-center">
      <router-view></router-view>
    </div>
  </div>
</template>
