<script setup>
defineOptions({
  name: 'SettingsEvents', // Give the component a name
});
import {
  //axios
  axios,

  //vue
  createApp,
  ref,
  reactive,
  watch,
  computed,
  inject,
  provide,
  onMounted,
  onUnmounted,
  nextTick,
  Teleport,

  //pinia
  defineStore,
  createPinia,
  storeToRefs,

  //pinia stores
  useChatStore,
  useContactAlertsStore,
  useContactFileStore,
  useContactsStore,
  useGlobalPromptsStore,
  useGlobalToastsStore,
  useLoginStore,
  useTenantsStore,

  //vue-router
  useRouter,
  useRoute,

  //formkit

  //formkit config file

  //fontawesome
  library,
  FontAwesomeIcon,
  fas,
  far,
  fab,

  //global components - all vue components
  registerGlobalComponents,

  //base api url
  BASE_API_URL,
} from "./../clientImports";

// Call the useContactsStore function to get an instance of the store
const contactsStore = useContactsStore();
</script>

<template>
  <div class="h-full flex flex-col bg-base-100">
    <div class="w-full p-3 bg-base-300 text-white">
      <font-awesome-icon icon="fa-solid fa-calendar-days" /><span class="ml-3"
        >Events</span
      >
    </div>
    <div class="w-full overflow-y-auto flex flex-row justify-between flex-1">
      <div class="flex-1 h-full p-5 overflow-y-auto text-white">
        <h1>Details</h1>
      </div>
      <div class="flex-1 h-full p-5 overflow-y-auto text-white">
        <h1>Features</h1>
      </div>
    </div>
    <div class="w-full p-3 bg-base-300 text-white flex flex-row justify-end">
      <button class="btn bg-emerald-500 text-white capitalize border-none">
        Save Users
      </button>
    </div>
  </div>
</template>
