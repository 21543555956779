<script setup>
defineOptions({
  name: "ContactFileNameBar", // Give the component a name
});
import {
  //vue
  ref,
  computed,
  watch,
  reactive,
  Teleport,
  useContactAlertsStore,

  //formkit
  FKsubmitForm,

  //utils
  xLog,
  copyText,
  onBeforeMount,
  onMounted,
  useContactFileStore,
} from "../clientImports";

import { globalIsoToLocal } from "./../utilities/globalTimezoneConverter";

const contactAlertsStore = useContactAlertsStore();
const contactFileStore = useContactFileStore();

const showPhoneMenu = ref(false);
const togglePhoneMenu = () => {
  showPhoneMenu.value = !showPhoneMenu.value;
};
const showEmailMenu = ref(false);
const toggleEmailMenu = () => {
  showEmailMenu.value = !showEmailMenu.value;
};

// TESTING

const options = {
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
  hour: "2-digit",
  minute: "2-digit",
  // second: "2-digit",
};
</script>

<template>
  <div
    class="w-full flex flex-col 2xl:flex-row items-center justify-start gap-y-0 bg-gray-100 border-b border-b-slate-300"
  >
    <!-- name bar -->
    <div class="w-full 2xl:w-4/12 flex flex-row items-center">
      <div
        class="relative flex flex-row justify-between items-center bg-white flex-wrap self-stretch"
      >
        <div class="flex flex-col whitespace-normal h-12">
          <!-- Name - FN/LN -->
          <div class="flex flex-row items-center h-full">
            <slot name="glanced-view-toggle"></slot>
            <div class="flex flex-col">
              <span class="text-slate-600 text-xs md:text-lg break-words pl-3">
                {{
                  contactFileStore.currentContactFile?.first_name +
                  " " +
                  contactFileStore.currentContactFile?.last_name
                }}
              </span>
              <!-- Business -->
              <span
                v-if="contactFileStore.currentContactFile?.business_name"
                class="text-slate-600 text-xs pl-3"
              >
                {{ contactFileStore.currentContactFile?.business_name }}
              </span>
            </div>
          </div>
        </div>
        <!-- Phone - Copy/Compose/Call -->
        <div class="max-2xl:ml-auto flex flex-row items-center self-stretch">
          <button
            v-if="contactFileStore.currentContactFile?.phone_primary"
            @click="togglePhoneMenu"
            class="btn btn-sm h-full bg-indigo-500 text-xs hover:bg-indigo-700 text-white rounded-none border-none"
          >
            <font-awesome-icon icon="fa-solid fa-phone-flip" />
          </button>

          <!-- Email - Copy/Compose -->
          <button
            v-if="contactFileStore.currentContactFile?.email_primary"
            @click="toggleEmailMenu"
            class="btn btn-sm h-full text-xs bg-blue-500 hover:bg-blue-700 text-white rounded-none lowercase border-none"
          >
            <font-awesome-icon icon="fa-solid fa-envelope" />
          </button>
        </div>
        <div
          @click="copyText(contactFileStore.contactId)"
          style="font-size: 10px"
          class="w-full border-t border-t-slate-400 bg-blue-100 flex flex-row items-center justify-between"
        >
          <span
            v-tippy="{ content: 'Copy Contact ID' }"
            class="italic text-slate-500 p-0.5 hover:cursor-pointer hover:bg-blue-200"
            ><font-awesome-icon icon="fa-solid fa-fingerprint" size="xl" />
            {{ contactFileStore.contactId }}</span
          >
          <span
            v-tippy="{ content: 'Date Created' }"
            class="italic text-slate-500 p-0.5 text-right"
          >
            <font-awesome-icon
              icon="fa-regular fa-calendar-days"
              class="mr-1"
              size="xl"
            />{{
              globalIsoToLocal(
                contactFileStore.currentContactFile?.created_at,
                options
              )
            }}
          </span>
        </div>
        <Teleport to="#modals-target">
          <transition
            enter-active-class="transition-opacity duration-300 ease-in-out"
            enter-from-class="opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="transition-opacity duration-300 ease-in-out"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
          >
            <div
              v-if="showPhoneMenu"
              @click.self="togglePhoneMenu"
              class="z-70 backdrop-brightness-50 h-screen w-full fixed flex flex-row justify-center items-center transition-all ease-in-out duration-300"
            >
              <div
                class="border border-slate-400 flex flex-col gap-y-2 rounded shadow-md p-2 text-center bg-base-100 text-xs md:text-sm"
              >
                <span class="text-white">
                  {{ contactFileStore.currentContactFile?.phone_primary }}
                </span>
                <button
                  @click.stop="
                    copyText(contactFileStore.currentContactFile?.phone_primary)
                  "
                  class="btn btn-xs md:btn-sm bg-gray-500 hover:bg-gray-700 text-white rounded capitalize border-none"
                >
                  <font-awesome-icon icon="fa-solid fa-clipboard" />
                  <span class="ml-2">Copy Phone</span>
                </button>
                <button
                  class="btn btn-xs md:btn-sm bg-emerald-500 hover:bg-emerald-700 text-white rounded capitalize border-none"
                >
                  <font-awesome-icon icon="fa-solid fa-message" />
                  <span class="ml-2">Compose SMS</span>
                </button>
                <button
                  class="btn btn-xs md:btn-sm bg-indigo-500 hover:bg-indigo-700 text-white rounded capitalize border-none"
                >
                  <font-awesome-icon icon="fa-solid fa-phone-flip" />
                  <span class="ml-2">Call Now</span>
                </button>
              </div>
            </div>
          </transition>
        </Teleport>

        <Teleport to="#modals-target">
          <transition
            enter-active-class="transition-opacity duration-300 ease-in-out"
            enter-from-class="opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="transition-opacity duration-300 ease-in-out"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
          >
            <div
              v-if="showEmailMenu"
              @click.self="toggleEmailMenu"
              class="z-70 backdrop-brightness-50 h-screen w-full fixed flex flex-row justify-center items-center transition-all ease-in-out duration-300"
            >
              <div
                class="flex border border-slate-400 flex-col gap-y-2 rounded shadow-md p-2 bg-base-100 text-center"
              >
                <span class="text-white text-xs md:text-sm">
                  {{ contactFileStore.currentContactFile?.email_primary }}
                </span>
                <button
                  @click.stop="
                    copyText(contactFileStore.currentContactFile?.email_primary)
                  "
                  class="btn btn-xs md:btn-sm bg-gray-500 hover:bg-gray-700 text-white rounded capitalize border-none"
                >
                  <font-awesome-icon icon="fa-solid fa-clipboard" />
                  <span class="ml-2">Copy Email</span>
                </button>
                <button
                  class="btn btn-xs md:btn-sm bg-blue-500 hover:bg-blue-700 text-white rounded capitalize border-none"
                >
                  <font-awesome-icon icon="fa-solid fa-message" />
                  <span class="ml-2">Compose Email</span>
                </button>
              </div>
            </div>
          </transition>
        </Teleport>
      </div>
    </div>
    <div class="bg-base-100 w-full p-1 h-full flex flex-row items-center">
      <slot name="flags-bar"> </slot>
    </div>
  </div>
</template>
