import {
  //axios
  axios,

  //pinia
  defineStore,
  useGlobalToastsStore,
  useGlobalPromptsStore,

  //env base url
  BASE_API_URL,
} from "../clientImports";

export const useTenantsMilestonesStore = defineStore({
  id: "tenants-milestones",
  state: () => ({
    showTenantsMilestonesCreateModal: false,
    showTenantsMilestonesEditModal: false,

    idToEdit: "",

    tenantsMilestonesList: [],

    tenantsMilestonesOptions: [],

    currentlyCreatingMilestone: {
      name: "",
      descriiption: "",
    },

    currentlyEditingMilestone: {
      name: "",
      descriiption: "",
    },
  }),
  actions: {
    //two responsibilities - set the id to edit and then open the modal
    async initializeTenantsMilestonesEditModal(milestoneId) {
      //set's the id of the Milestone that we're editing
      this.setIdToEdit(milestoneId);

      await this.fetchTenantMilestone();
      //opens the edit modal
      this.showTenantsMilestonesEditModal = true;
    },

    toggleTenantsMilestonesCreateModal() {
      this.showTenantsMilestonesCreateModal =
        !this.showTenantsMilestonesCreateModal;
    },

    toggleTenantsMilestonesEditModal() {
      this.showTenantsMilestonesEditModal =
        !this.showTenantsMilestonesEditModal;
    },

    //should be read but accidentally named fetch - will rename in future
    async fetchTenantsMilestones() {
      try {
        const { data } = await axios.get(
          `${BASE_API_URL}/read-tenants-milestones`
        );

        if (data.status === "success") {
          this.tenantsMilestonesList = data.milestonesOptions.map(
            (milestone) => {
              return {
                id: milestone._id,
                name: milestone.name,
                description: milestone.description,
                system_default: milestone.system_default,
              };
            }
          );
        } else {
          console.error(data.message);
        }
      } catch (error) {
        console.error();
      }
    },

    //for fetching the list of Milestones for selection
    //should be fetch but was taken - will rename in future
    async getTenantsMilestones() {
      try {
        const { data } = await axios.get(
          `${BASE_API_URL}/fetch-tenants-milestones`
        );

        if (data.status === "success") {
          this.tenantsMilestonesOptions = data.milestonesOptions.map(
            (milestone) => {
              return {
                value: milestone._id,
                label: milestone.name + " - " + milestone.description,
              };
            }
          );
        } else {
          console.error(data.message);
        }
      } catch (error) {
        console.error();
      }
    },

    //fetching for editing
    async fetchTenantMilestone() {
      const id = this.idToEdit;
      try {
        const { data } = await axios.get(
          `${BASE_API_URL}/read-tenants-milestone/${id}`
        );

        console.log(data, "FETCHED MILESTONE");

        if (data.status === "success") {
          console.log(data.milestone);
          this.currentlyEditingMilestone = data.milestone[0];

          console.log(this.currentlyEditingMilestone);
        } else {
          console.error(data.message);
        }
      } catch (error) {
        console.error();
      }
    },

    setIdToEdit(milestoneId) {
      this.idToEdit = milestoneId;
    },

    async createTenantsMilestone() {
      const milestoneData = this.currentlyCreatingMilestone;
      try {
        const { data } = await axios.post(
          `${BASE_API_URL}/create-tenants-milestone`,
          milestoneData
        );

        if (data.status === "success") {
          const toastsStore = useGlobalToastsStore();
          toastsStore.addToast({
            title: "Milestone Created",
            description: "",
            buttonExists: false,
            color: "emerald",
            type: 1,
          });

          //fetch the updated list of Milestones
          this.fetchTenantsMilestones();
          this.toggleTenantsMilestonesCreateModal();
        } else {
          console.error(data.message);
          toastsStore.addToast({
            title: "Error Creating Milestone",
            description: data.message,
            buttonExists: false,
            color: "red",
            type: 1,
          });
        }
      } catch (error) {
        console.error();
      }
    },

    async updateTenantsMilestone() {
      const id = this.idToEdit;
      try {
        const { data } = await axios.put(
          `${BASE_API_URL}/update-tenants-milestone/${id}`,
          this.currentlyEditingMilestone
        );

        if (data.status === "success") {
          console.log(data.message);

          this.toggleTenantsMilestonesEditModal();
          //fetch the updated list of Milestones
          this.fetchTenantsMilestones();

          //throw toast
          const toastsStore = useGlobalToastsStore();
          toastsStore.addToast({
            title: "Milestone Updated",
            description: "",
            buttonExists: false,
            color: "emerald",
            type: 1,
          });
        } else {
          console.error(data.message);
          toastsStore.addToast({
            title: "Error Updating Milestone",
            description: data.message,
            buttonExists: false,
            color: "red",
            type: 1,
          });
        }
      } catch (error) {
        console.error();
      }
    },

    async deleteTenantsMilestone(milestoneId) {
      const promptStore = useGlobalPromptsStore();

      const group_id = milestoneId;

      promptStore.addPrompt({
        type: "Milestone",
        title: "Delete Milestone?",
        message: `Are you sure you want to delete this Milestone?`,
      });

      const promptResponse = await promptStore.waitForPromptConfirmation();

      if (promptResponse === true) {
        promptStore.addPrompt({
          type: "Milestone",
          title: "Are You Sure?",
          message: `We'll hide this from your view so it can no longer be used. Any contacts or users that have this milestone assigned will remain assigned and have to be manually changed. Do you still want to proceed with the deletion?`,
        });

        const promptResponse2 = await promptStore.waitForPromptConfirmation();

        if (promptResponse2 === true) {
          try {
            const { data } = await axios.delete(
              `${BASE_API_URL}/delete-tenants-milestone/${group_id}`
            );

            if (data.status === "success") {
              //fetch the updated list of Milestones
              this.fetchTenantsMilestones();
            } else {
              console.error(data.message);
              toastsStore.addToast({
                title: "Error Deleting Milestone",
                description: data.message,
                buttonExists: false,
                color: "red",
                type: 1,
              });
            }
          } catch (error) {
            console.error();
          }
          promptStore.removePrompt();
        } else {
          promptStore.removePrompt();
        }
      } else {
        promptStore.removePrompt();
      }
    },
  },
});
