<script setup>
defineOptions({
  name: 'ContactFilePage', // Give the component a name
});
import {
  //axios
  axios,

  //vue
  createApp,
  ref,
  reactive,
  watch,
  computed,
  inject,
  provide,
  onBeforeMount,
  onMounted,
  onUnmounted,
  nextTick,
  Teleport,

  //pinia
  defineStore,
  createPinia,
  storeToRefs,

  //pinia stores
  useChatStore,
  useContactAlertsStore,
  useContactFileStore,
  useRecentContactsStore,

  //vue-router
  useRouter,
  useRoute,

  //formkit

  //formkit config file

  //fontawesome
  library,
  FontAwesomeIcon,
  fas,
  far,
  fab,

  //global components - all vue components
  registerGlobalComponents,

  //base api url
  BASE_API_URL,
} from "./../clientImports";

const contactAlertsStore = useContactAlertsStore();
const contactFileStore = useContactFileStore();
const recentContactsStore = useRecentContactsStore();

const route = useRoute();

//TODO - moved from contactsFileModal - finish later
const contactFileDataDefaults = ref([
  //contact header is only neccessary data - all other components load from individual component and use the current contact file id to load in the contacts associated data
  "first_name",
  "last_name",
  "email_primary",
  "phone_primary",
  "business_name",
  "business_ein",
  "business_type",
  "business_address",
  "business_suite_unit",
  "business_city",
  "business_state",
  "business_zip",
  "phone_primary",
]);

//fetch contact data with id param from route
//setters to set the contact files data
onBeforeMount(async () => {
  const route = useRoute();
  contactFileStore.setCurrentContactFile(route.params.contact_id);

  await contactFileStore.fetchContactFileData();

  if (route.name === "contact-file") {
    recentContactsStore.addVisitedRoute({
      name: route.name,
      contact_id: route.params.contact_id,
      contact_name:
        contactFileStore.currentContactFile.first_name +
        " " +
        contactFileStore.currentContactFile.last_name,
    });
  }
});

const showGlancedView = ref(false);
const toggleGlancedView = () => {
  showGlancedView.value = !showGlancedView.value;
};
</script>

<template>
  <div class="flex flex-col flex-1">
    <div
      class="flex flex-col justify-start flex-1 text-left bg-gray-50 border-b border-b-slate-400"
    >
      <div class="flex flex-row">
        <ContactAlerts />
      </div>
      <ContactFileNameBar
        :contact-name="
          contactFileStore.currentContactFile?.first_name +
          ' ' +
          contactFileStore.currentContactFile?.last_name
        "
        :contact-business="contactFileStore.currentContactFile?.business_name"
      >
        <template #glanced-view-toggle>
          <button
            @click="toggleGlancedView"
            class="lg:hidden px-3 btn btn-xs md:btn-sm rounded-none bg-base-100 text-white border-none h-full border-b border-b-slate-400"
          >
            <font-awesome-icon :icon="['fas', 'bars']" />
          </button>
        </template>
        <template #flags-bar>
          <div class="flex flex-col md:flex-row gap-1 md:gap-3 w-full">
            <div
              class="rounded-full max-md:w-full flex flex-row justify-between items-center gap-1 overflow-x-auto hide-scroll whitespace-nowrap bg-slate-400 border border-slate-400"
            >
              <div class="flex flex-row pr-5">
                <ContactNotes />
                <button
                  @click="contactAlertsStore.addNewAlert"
                  class="btn btn-xs md:btn-sm bg-rose-500 border-none rounded-none text-white"
                >
                  <font-awesome-icon icon="fa-solid fa-triangle-exclamation" />
                </button>
                <ContactAttachmentsAdd></ContactAttachmentsAdd>
                <ContactBots></ContactBots>
              </div>
            </div>
            <div
              class="rounded-full w-full flex flex-row justify-between items-center gap-1 overflow-x-auto hide-scroll whitespace-nowrap bg-slate-400 border border-slate-400"
            >
              <ContactCampaign></ContactCampaign>
              <ContactStage></ContactStage>
              <ContactWorkflowStatus></ContactWorkflowStatus>
              <ContactPaymentPlanStatus></ContactPaymentPlanStatus>
            </div>
          </div>
        </template>
      </ContactFileNameBar>

      <!-- middle contact file -->
      <div class="flex flex-row flex-1">
        <!-- Left Sidebar -->
        <div
          :class="showGlancedView ? 'max-lg:flex' : 'max-lg:hidden'"
          class="flex flex-col justify-start w-full 2xl:w-3/12 border-r-2 border-r-slate-300"
        >
          <div class="bg-slate-200 flex-1 flex flex-col">
            <ContactAssignments />
            <ContactType />

            <ContactOptIn />
            <ContactEsignStatus />
            <ContactTags />
            <!-- client portal -->
            <ContactLinks />
          </div>
          <div class="p-0.5 flex flex-col border-t border-t-slate-400">
            <button
              class="btn btn-xs md:btn-sm bg-emerald-500 border-none capitalize text-white rounded btn-block"
            >
              Send Portal Signup
            </button>
            <div class="flex flex-row gap-1 py-1">
              <input
                placeholder="Portal Username"
                class="input input-xs md:input-sm rounded w-full"
                type="text"
              />
              <input
                placeholder="Portal Password"
                class="input input-xs md:input-sm rounded w-full"
                type="text"
              />
            </div>
          </div>
        </div>

        <!-- tabs views -->
        <div
          :class="!showGlancedView ? 'max-lg:flex' : 'max-lg:hidden'"
          class="flex flex-col flex-1 overflow-hidden"
        >
          <div class="flex flex-col">
            <div
              class="flex flex-row bg-white divide-x divide-slate-400 border-b border-slate-400 overflow-x-auto hide-scroll text-xs md:text-sm whitespace-nowrap"
            >
              <router-link
                :to="{ name: 'contact-file-history-tab' }"
                class="p-3 md:px-4 md:py-2 text-gray-700 hover:bg-indigo-400 hover:text-white cursor-pointer"
                active-class="disabled bg-indigo-400 text-white border-b-4 border-b-indigo-700"
              >
                <font-awesome-icon icon="fa-solid fa-clock-rotate-left" /><span
                  class="ml-2 capitalize"
                  >History</span
                >
              </router-link>

              <router-link
                :to="{ name: 'contact-file-forms-tab' }"
                class="p-3 md:px-4 md:py-2 text-gray-700 hover:bg-indigo-400 hover:text-white cursor-pointer"
                active-class="disabled bg-indigo-400 text-white border-b-4 border-b-indigo-700"
              >
                <font-awesome-icon icon="fa-solid fa-clock-rotate-left" /><span
                  class="ml-2 capitalize"
                  >Forms & Data</span
                >
              </router-link>

              <router-link
                :to="{ name: 'contact-file-communication-tab' }"
                class="p-3 md:px-4 md:py-2 text-gray-700 hover:bg-indigo-400 hover:text-white cursor-pointer"
                active-class="disabled bg-indigo-400 text-white border-b-4 border-b-indigo-700"
              >
                <font-awesome-icon icon="fa-solid fa-clock-rotate-left" /><span
                  class="ml-2 capitalize"
                  >Communication</span
                >
              </router-link>

              <router-link
                :to="{ name: 'contact-file-notes-tab' }"
                class="p-3 md:px-4 md:py-2 text-gray-700 hover:bg-indigo-400 hover:text-white cursor-pointer"
                active-class="disabled bg-indigo-400 text-white border-b-4 border-b-indigo-700"
              >
                <font-awesome-icon icon="fa-solid fa-clock-rotate-left" /><span
                  class="ml-2 capitalize"
                  >Notes</span
                >
              </router-link>

              <router-link
                :to="{ name: 'contact-file-attachments-tab' }"
                class="p-3 md:px-4 md:py-2 text-gray-700 hover:bg-indigo-400 hover:text-white cursor-pointer"
                active-class="disabled bg-indigo-400 text-white border-b-4 border-b-indigo-700"
              >
                <font-awesome-icon icon="fa-solid fa-clock-rotate-left" /><span
                  class="ml-2 capitalize"
                  >Attachments</span
                >
              </router-link>
            </div>
          </div>
          <div class="flex flex-col flex-1">
            <router-view></router-view>
          </div>
          <!-- adds an additional row at the bottom of every contact file tab. global to the tab -->
          <!-- <div class="w-full bg-blue-500"></div> -->
        </div>
      </div>

      <!-- bottom fixed toolbar -->
      <!-- <div
        class="flex flex-row justify-between items-center border-t-4 border-slate-300 bg-white"
      >
        <button class="btn">Hello</button>
      </div> -->
    </div>
  </div>
</template>
