<script setup>
defineOptions({
  name: 'Chat', // Give the component a name
});
import {
  //axios
  axios,

  //vue
  createApp,
  ref,
  reactive,
  watch,
  computed,
  inject,
  provide,
  onMounted,
  onUnmounted,
  nextTick,
  Teleport,

  //pinia
  defineStore,
  createPinia,
  storeToRefs,

  //pinia stores
  useChatStore,
  useContactAlertsStore,
  useContactFileStore,
  useContactsStore,
  useGlobalPromptsStore,
  useGlobalToastsStore,
  useLoginStore,
  useTenantsStore,

  //vue-router
  useRouter,
  useRoute,

  //formkit

  //formkit config file

  //fontawesome
  library,
  FontAwesomeIcon,
  fas,
  far,
  fab,

  //global components - all vue components
  registerGlobalComponents,

  //base api url
  BASE_API_URL,
} from "./../clientImports";

const chatStore = useChatStore();

const existingChats = ref([
  {
    hidden: true,
    minimized: false,
    chatId: 1,
    chattingWith: "Nick Lancaster",
    conversation: [
      {
        chatOwner: false,
        username: "Obiwan",
        dateTime: "2:45",
        userAvatar: "myImage",
        message: "You were the chosen one!",
        viewedStatus: "seen", //delivered, read
      },
      {
        chatOwner: true,
        username: "Nick",
        dateTime: "2:45",
        userAvatar: "myImage",
        message: "You're a turd",
        viewedStatus: "delivered", //delivered, seen
      },
    ],
  },
  {
    hidden: true,
    minimized: false,
    chatId: 2,
    chattingWith: "Nick Lancaster",
    conversation: [
      {
        chatOwner: false,
        username: "Obiwan",
        dateTime: "2:45",
        userAvatar: "myImage",
        message: "You were the chosen one!",
        viewedStatus: "seen", //delivered, read
      },
      {
        chatOwner: true,
        username: "Nick",
        dateTime: "2:45",
        userAvatar: "myImage",
        message: "You're a turd",
        viewedStatus: "delivered", //delivered, seen
      },
    ],
  },
]);

const usersList = reactive([
  { id: 1, first_name: "Nick", last_name: "Lancaster" },
]);

const chat = ref(false);
const minimized = ref(false);
const fixedChatBar = ref(false);

const toggleChatBar = () => {
  fixedChatBar.value = !fixedChatBar.value;
};

const chatContactsSidebar = ref(false);

const toggleChatContactsSidebar = () => {
  chatContactsSidebar.value = !chatContactsSidebar.value;
};
</script>

<template>
  <div
    v-show="chatStore.chat"
    class="z-30 flex flex-row items-end h-10 bg-transparent bg-white"
  >
    <div
      v-for="(chatConvo, chatConvoIndex) in existingChats"
      :key="chatConvoIndex"
      v-show="chatConvo.hidden"
      class="z-50 text-white border-l-4 bg-base-100 border-l-secondary"
    >
      <div class="flex flex-row items-center justify-between cursor-pointer">
        <div
          @click="
            existingChats[chatConvoIndex].minimized =
              !existingChats[chatConvoIndex].minimized
          "
          class="flex flex-row items-center self-stretch flex-1 px-3 hover:bg-gray-700"
        >
          {{ chatConvo.chattingWith }}
        </div>
        <div class="flex flex-row items-center">
          <!-- <button class="px-3 py-2 hover:bg-white hover:text-gray-700">
          <font-awesome-icon icon="fa-solid fa-minus" size="lg" />
        </button> -->
          <button
            @click="
              existingChats[chatConvoIndex].hidden =
                !existingChats[chatConvoIndex].hidden
            "
            class="p-2 hover:bg-red-700"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="w-6 h-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
      </div>
      <div
        v-show="existingChats[chatConvoIndex].minimized"
        class="flex flex-col justify-end h-96 w-72"
      >
        <div class="h-full p-2 overflow-y-auto glass">
          <div
            v-for="(chatFeed, chatFeedIndex) in chatConvo.conversation"
            :key="chatFeedIndex"
            class="chat"
            :class="[chatFeed.chatOwner ? 'chat-end' : 'chat-start']"
          >
            <div class="chat-image avatar">
              <div class="w-5 rounded-full">
                <img src="" :alt="chatFeed.userAvatar" />
              </div>
            </div>
            <div class="chat-header">
              {{ chatFeed.username }}
              <time class="text-xs opacity-50">12:45</time>
            </div>
            <div class="chat-bubble">{{ chatFeed.message }}</div>
            <div class="opacity-50 chat-footer">
              {{ chatFeed.viewedStatus }}
            </div>
          </div>
        </div>
        <div class="p-2">
          <FormKit
            type="textarea"
            label=""
            rows="2"
            input-class="w-full textarea textarea-secondary"
            placeholder="Your Message Here..."
          />
          <FormKit
            input-class="capitalize btn btn-block btn-secondary"
            type="button"
            label="Send"
          />
        </div>
      </div>
    </div>

    <div class="rounded-tr-lg bg-base-100">
      <label
        @click="toggleChatContactsSidebar"
        for="add-chat-modal"
        class="h-10 rounded-none btn btn-sm btn-secondary"
      >
        <font-awesome-icon icon="fa-solid fa-plus" size="lg" />
      </label>
    </div>

    <Teleport to="#sidebars-target">
      <div v-show="chatContactsSidebar" class="fixed z-40 drawer">
        <input id="add-chat-modal" type="checkbox" class="drawer-toggle" />
        <div class="drawer-content"></div>
        <div class="drawer-side">
          <label
            @click="toggleChatContactsSidebar"
            for="add-chat-modal"
            class="drawer-overlay"
          ></label>
          <ul class="menu w-72 bg-base-100 text-base-content">
            <div class="flex items-center h-16 px-5 text-white bg-secondary">
              <font-awesome-icon icon="fa-solid fa-bell" size="lg" />
              <h3 class="ml-2 text-lg font-bold">Chat Contacts</h3>
            </div>
            <div
              v-for="(user, userIndex) in usersList"
              class="w-full mb-3 text-white rounded cursor-pointer hover:bg-gray-700"
            >
              <!-- TYPE LABEL -->
              <div
                class="flex flex-row items-center col-span-1 p-3 text-center rounded-tl-lg"
              >
                <div class="avatar online">
                  <div class="w-10 rounded-full">
                    <!-- <img src="myImage" /> -->
                  </div>
                </div>
                <p class="flex-1 ml-5 text-xl text-right">
                  {{ `${user.first_name} ${user.last_name}` }}
                </p>
              </div>
            </div>
          </ul>
        </div>
      </div>
    </Teleport>
  </div>
</template>
