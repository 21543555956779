import {
  //axios
  axios,

  //pinia
  defineStore,

  //env base url
  BASE_API_URL,
} from "../clientImports";

export const useTenantsCampaignsStore = defineStore({
  id: "tenants-campaigns-store",

  state: () => ({
    showEditCampaignModal: false,
    showCreateCampaignModal: false, //will toggle to show the chat at the bottom of view port. Expands thrid row in vp with flex
    tenantsCampaignsListing: [],
    tenantsCampaignsOptions: [],
    idToEdit: "",
    currentlyEditing: null,
  }),
  actions: {
    async setIdToEdit(id) {
      this.idToEdit = id;
    },

    async toggleEditCampaignModal() {
      this.showEditCampaignModal = !this.showEditCampaignModal;
    },

    async toggleCreateCampaignModal() {
      this.showCreateCampaignModal = !this.showCreateCampaignModal;
    },
    // FOR DROPDOWN
    async fetchTenantsCampaignsOptions() {
      const response = await axios.get(
        `${BASE_API_URL}/tenants-read-campaigns`
      );

      this.tenantsCampaignsOptions = response.data.campaignOptions.map(
        (campaign) => {
          return {
            value: campaign._id,
            label: campaign.name,
          };
        }
      );
    },

    async readCampaign(idToEdit) {
      const { data } = await axios.get(
        `${BASE_API_URL}/tenants-read-campaign/${this.idToEdit}`
      );

      this.currentlyEditing = data.campaign[0];

      console.log("currentlyEditing: ", this.currentlyEditing);

      return data;
    },

    async updateTenantCampaign(updateCampaignData) {
      const { data } = await axios.put(
        `${BASE_API_URL}/tenants-update-campaign/${this.idToEdit}`,
        updateCampaignData
      );

      return data;
    },

    // FOR LIST AREA
    async fetchTenantsCampaignsListing() {
      const response = await axios.get(
        `${BASE_API_URL}/tenants-read-campaigns`
      );

      this.tenantsCampaignsListing = response.data.campaignOptions.map(
        (campaign) => {
          return {
            id: campaign._id,
            name: campaign.name,
            description: campaign.description,
            system_default: campaign.system_default,
          };
        }
      );
    },

    async createTenantCampaign(createCampaignData) {
      const response = await axios.post(
        `${BASE_API_URL}/tenants-create-campaign`,
        createCampaignData
      );

      return response;
    },

    async deleteTenantCampaign(id) {
      const response = await axios.delete(
        `${BASE_API_URL}/tenants-delete-campaign/${id}`
      );

      return response.data;
    },
  },
});
