<script setup>
defineOptions({
  name: 'ContactEsignStatus', // Give the component a name
});
import {
  //axios
  axios,

  //vue
  createApp,
  ref,
  reactive,
  watch,
  computed,
  inject,
  provide,
  onMounted,
  onUnmounted,
  nextTick,
  Teleport,

  //pinia
  defineStore,
  createPinia,
  storeToRefs,

  //pinia stores
  useChatStore,
  useContactAlertsStore,
  useContactFileStore,
  useContactsStore,
  useGlobalPromptsStore,
  useGlobalToastsStore,
  useLoginStore,
  useTenantsStore,
  useContactTypesStore,

  //vue-router
  useRouter,
  useRoute,

  //formkit

  //formkit config file

  //fontawesome
  library,
  FontAwesomeIcon,
  fas,
  far,
  fab,

  //global components - all vue components
  registerGlobalComponents,

  //base api url
  BASE_API_URL,
} from "./../clientImports";

const toastsStore = useGlobalToastsStore();

const contactFileStore = useContactFileStore();

let esignStatus = ref("");

const contactEsignStatusOptions = ref([
  { label: "Not Sent", value: "not-sent" },
  { label: "Sent", value: "sent" },
  { label: "Expired", value: "expired" },
  { label: "Signed", value: "signed" },
]);

onMounted(async () => {
  esignStatus.value = contactFileStore.currentContactFile?.esign_status;

  watch(
    () => contactFileStore.currentContactFile?.esign_status,
    (newVal, oldVal) => {
      esignStatus.value = newVal;
    }
  );
});

const updateEsignStatus = async (flagName, esignStatusVal) => {
  contactFileStore.updateContactFlag(flagName, esignStatusVal);

  toastsStore.addToast({
    title: "E-Sign Status Updated",
    buttonExists: false,
    color: "emerald",
    type: 1, // 1 = small, 2 = md, 3 = lg, 4 = lg dismissable
  });
};
</script>
<template>
  <div class="flex flex-col justify-between">
    <!-- Phone -->
    <div
      class="flex flex-row justify-between items-center text-xs whitespace-nowrap"
    >
      <div class="p-1 md:p-2 text-white bg-purple-900 w-3/6 text-sm">
        <font-awesome-icon icon="fa-regular fa-folder-open" /> E-Sign
      </div>
      <div class="self-stretch flex-1">
        <select
          @change="updateEsignStatus('esign_status', esignStatus)"
          v-model="esignStatus"
          :value="contactFileStore.currentContactFile?.esign_status"
          class="flex-1 text-white text-xs md:text-sm w-full h-full text-center"
        >
          <option
            v-for="(option, optionIndex) in contactEsignStatusOptions"
            :value="option.value"
            :key="optionIndex"
          >
            {{ option.label }}
          </option>
        </select>
      </div>
    </div>
  </div>
</template>
