import {
  //axios
  axios,

  //pinia
  defineStore,

  //env base url
  BASE_API_URL,
} from "../clientImports";

export const useRecentContactsStore = defineStore({
  id: "recent-contacts",
  state: () => ({
    //TODO - set local storage
    visitedContacts: JSON.parse(localStorage.getItem("visitedContacts")) || [],
  }),
  actions: {
    addVisitedRoute(route) {
      // Find the index of the object with the same 'path'
      const index = this.visitedContacts.findIndex(
        (existingRoute) => existingRoute.contact_id === route.contact_id
      );

      // If found, remove it from the array
      if (index !== -1) {
        this.visitedContacts.splice(index, 1);
      }

      // Add the new route object to the array
      this.visitedContacts.push(route);

      localStorage.setItem(
        "visitedContacts",
        JSON.stringify(this.visitedContacts)
      );
    },

    clearRecentContacts() {
      localStorage.removeItem("visitedContacts");
      this.visitedContacts = [];
    },

    //delete localStorage data function
    deleteLocalStorageData() {
      localStorage.removeItem("visitedContacts");
    },

    removeFromRecentContacts(contact_id) {
      // Find the index of the object with the same 'path'
      const index = this.visitedContacts.findIndex(
        (existingRoute) => existingRoute.contact_id === contact_id
      );

      // If found, remove it from the array
      if (index !== -1) {
        this.visitedContacts.splice(index, 1);
      }

      localStorage.setItem(
        "visitedContacts",
        JSON.stringify(this.visitedContacts)
      );
    },
  },
});
