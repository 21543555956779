import {
  //axios
  axios,

  //pinia
  defineStore,
  useGlobalToastsStore,
  useGlobalPromptsStore,

  //env base url
  BASE_API_URL,
} from "../clientImports";

export const useTenantsPaymentsPresetsStore = defineStore({
  id: "tenants-payments-presets",
  state: () => ({
    showTenantsPaymentsPresetsCreateModal: false,
    showTenantsPaymentsPresetsEditModal: false,

    idToEdit: "",

    tenantsPaymentsPresetsList: [],

    currentlyCreatingPaymentsPreset: {
      active: true,
      name: "",
      description: "",
      plan_amount: null,
      number_of_payments: null,
      schedule_frequency: "monthly",
      frequency_amount: null,
    },

    currentlyEditingPaymentsPreset: {
      active: true,
      name: "",
      description: "",
      plan_amount: null,
      number_of_payments: null,
      schedule_frequency: "monthly",
      frequency_amount: null,
    },
  }),
  actions: {
    //two responsibilities - set the id to edit and then open the modal
    async initializeTenantsPaymentsPresetsEditModal(paymentsPresetId) {
      //set's the id of the PaymentsPreset that we're editing
      this.setIdToEdit(paymentsPresetId);

      await this.fetchTenantPaymentsPreset();
      //opens the edit modal
      this.showTenantsPaymentsPresetsEditModal = true;
    },

    toggleTenantsPaymentsPresetsCreateModal() {
      this.showTenantsPaymentsPresetsCreateModal =
        !this.showTenantsPaymentsPresetsCreateModal;
    },

    toggleTenantsPaymentsPresetsEditModal() {
      this.showTenantsPaymentsPresetsEditModal =
        !this.showTenantsPaymentsPresetsEditModal;
    },

    //fetches the PaymentsPresets for listing
    async fetchTenantsPaymentsPresets() {
      try {
        const { data } = await axios.get(
          `${BASE_API_URL}/read-tenants-payments-presets`
        );

        if (data.status === "success") {
          this.tenantsPaymentsPresetsList = data.paymentsPresetsOptions.map(
            (paymentsPreset) => {
              return {
                id: paymentsPreset._id,
                name: paymentsPreset.name,
                plan_amount: paymentsPreset.plan_amount,
                number_of_payments: paymentsPreset.number_of_payments,
                schedule_frequency: paymentsPreset.schedule_frequency,
                frequency_amount: paymentsPreset.frequency_amount,
                schedule_type: paymentsPreset.schedule_type,
                active: paymentsPreset.active,
              };
            }
          );
        } else {
          console.error(data.message);
        }
      } catch (error) {
        console.error();
      }
    },

    //fetching for editing
    async fetchTenantPaymentsPreset() {
      const id = this.idToEdit;
      try {
        const { data } = await axios.get(
          `${BASE_API_URL}/read-tenants-payments-preset/${id}`
        );

        console.log(data, "FETCHED MILESTONE");

        if (data.status === "success") {
          console.log(data.paymentsPreset);
          this.currentlyEditingPaymentsPreset = data.paymentsPreset[0];

          console.log(this.currentlyEditingPaymentsPreset);
        } else {
          console.error(data.message);
        }
      } catch (error) {
        console.error();
      }
    },

    setIdToEdit(paymentsPresetId) {
      this.idToEdit = paymentsPresetId;
    },

    async createTenantsPaymentsPreset() {
      const paymentsPresetData = this.currentlyCreatingPaymentsPreset;

      console.log("PAY PRESET DATA SENDING OUT: ", paymentsPresetData);
      try {
        const { data } = await axios.post(
          `${BASE_API_URL}/create-tenants-payments-preset`,
          paymentsPresetData
        );

        console.log("CREATE PAY PRESET RESPONSE", data);

        if (data.status === "success") {
          const toastsStore = useGlobalToastsStore();
          toastsStore.addToast({
            title: "PaymentsPreset Created",
            description: "",
            buttonExists: false,
            color: "emerald",
            type: 1,
          });

          //fetch the updated list of PaymentsPresets
          this.fetchTenantsPaymentsPresets();
          this.toggleTenantsPaymentsPresetsCreateModal();
        } else {
          console.error(data.message);
          toastsStore.addToast({
            title: "Error Creating PaymentsPreset",
            description: data.message,
            buttonExists: false,
            color: "red",
            type: 1,
          });
        }
      } catch (error) {
        console.error();
      }
    },

    async updateTenantsPaymentsPreset() {
      const id = this.idToEdit;
      try {
        const { data } = await axios.put(
          `${BASE_API_URL}/update-tenants-payments-preset/${id}`,
          this.currentlyEditingPaymentsPreset
        );

        if (data.status === "success") {
          console.log(data.message);

          this.toggleTenantsPaymentsPresetsEditModal();
          //fetch the updated list of PaymentsPresets
          await this.fetchTenantsPaymentsPresets();

          //throw toast
          const toastsStore = useGlobalToastsStore();
          toastsStore.addToast({
            title: "PaymentsPreset Updated",
            description: "",
            buttonExists: false,
            color: "emerald",
            type: 1,
          });
        } else {
          console.error(data.message);
          toastsStore.addToast({
            title: "Error Updating PaymentsPreset",
            description: data.message,
            buttonExists: false,
            color: "red",
            type: 1,
          });
        }
      } catch (error) {
        console.error();
      }
    },

    async deleteTenantsPaymentsPreset(paymentsPresetId) {
      const promptStore = useGlobalPromptsStore();

      const group_id = paymentsPresetId;

      promptStore.addPrompt({
        type: "PaymentsPreset",
        title: "Delete PaymentsPreset?",
        message: `Are you sure you want to delete this PaymentsPreset?`,
      });

      const promptResponse = await promptStore.waitForPromptConfirmation();

      if (promptResponse === true) {
        promptStore.addPrompt({
          type: "PaymentsPreset",
          title: "Are You Sure?",
          message: `We'll hide this from your view so it can no longer be used. Any contacts or users that have this paymentsPreset assigned will remain assigned and have to be manually changed. Do you still want to proceed with the deletion?`,
        });

        const promptResponse2 = await promptStore.waitForPromptConfirmation();

        if (promptResponse2 === true) {
          try {
            const { data } = await axios.delete(
              `${BASE_API_URL}/delete-tenants-payments-preset/${group_id}`
            );

            if (data.status === "success") {
              //fetch the updated list of PaymentsPresets
              this.fetchTenantsPaymentsPresets();
            } else {
              console.error(data.message);
              toastsStore.addToast({
                title: "Error Deleting PaymentsPreset",
                description: data.message,
                buttonExists: false,
                color: "red",
                type: 1,
              });
            }
          } catch (error) {
            console.error();
          }
          promptStore.removePrompt();
        } else {
          promptStore.removePrompt();
        }
      } else {
        promptStore.removePrompt();
      }
    },
  },
});
