import {
  //axios
  axios,

  //pinia
  defineStore,
  useGlobalToastsStore,
  useGlobalPromptsStore,

  //env base url
  BASE_API_URL,
} from "../clientImports";

export const useTenantsPhoneNumbersStore = defineStore({
  id: "tenants-phone-numbers",
  state: () => ({
    showTenantsPhoneNumbersCreateModal: false,
    showTenantsPhoneNumbersEditModal: false,

    idToEdit: "",

    tenantsPhoneNumbersList: [],

    currentlyCreatingPhoneNumber: {
      name: "",
      phone_number: "",
      description: "",
      texting: false,
      calling: false,
      faxing: false,
      source: "twilio",
      default: false,
      phone_settings: "",
    },

    currentlyEditingPhoneNumber: {
      name: "",
      phone_number: "",
      description: "",
      texting: false,
      calling: false,
      faxing: false,
      source: "twilio",
      default: false,
      phone_settings: "",
    },
  }),
  actions: {
    //two responsibilities - set the id to edit and then open the modal
    async initializeTenantsPhoneNumbersEditModal(phoneNumberId) {
      //set's the id of the PhoneNumber that we're editing
      this.setIdToEdit(phoneNumberId);

      await this.fetchTenantPhoneNumber();
      //opens the edit modal
      this.showTenantsPhoneNumbersEditModal = true;
    },

    toggleTenantsPhoneNumbersCreateModal() {
      this.showTenantsPhoneNumbersCreateModal =
        !this.showTenantsPhoneNumbersCreateModal;
    },

    toggleTenantsPhoneNumbersEditModal() {
      this.showTenantsPhoneNumbersEditModal =
        !this.showTenantsPhoneNumbersEditModal;
    },

    //fetches the PhoneNumbers for listing
    async fetchTenantsPhoneNumbers() {
      try {
        const { data } = await axios.get(
          `${BASE_API_URL}/read-tenants-phone-numbers`
        );

        if (data.status === "success") {
          this.tenantsPhoneNumbersList = data.phoneNumberOptions.map(
            (phoneNumber) => {
              return {
                id: phoneNumber._id,
                name: phoneNumber.name,
                phone_number: phoneNumber.phone_number,
                texting: phoneNumber.texting,
                calling: phoneNumber.calling,
                faxing: phoneNumber.faxing,
                source: phoneNumber.source,
                description: phoneNumber.description,
                default: phoneNumber.default,
              };
            }
          );
        } else {
          console.error(data.message);
        }
      } catch (error) {
        console.error();
      }
    },

    //fetching for editing
    async fetchTenantPhoneNumber() {
      const id = this.idToEdit;
      try {
        const { data } = await axios.get(
          `${BASE_API_URL}/read-tenants-phone-number/${id}`
        );

        console.log(data, "FETCHED MILESTONE");

        if (data.status === "success") {
          console.log(data.phoneNumber);
          this.currentlyEditingPhoneNumber = data.phoneNumber[0];

          console.log(this.currentlyEditingPhoneNumber);
        } else {
          console.error(data.message);
        }
      } catch (error) {
        console.error();
      }
    },

    setIdToEdit(phoneNumberId) {
      this.idToEdit = phoneNumberId;
    },

    async createTenantsPhoneNumber() {
      const phoneNumberData = this.currentlyCreatingPhoneNumber;

      console.log("PHONE NUMBER DATA SENDING OUT: ", phoneNumberData);
      try {
        const { data } = await axios.post(
          `${BASE_API_URL}/create-tenants-phone-number`,
          phoneNumberData
        );

        console.log("CREATE PHONE NUMBER RESPONSE", data);

        if (data.status === "success") {
          const toastsStore = useGlobalToastsStore();
          toastsStore.addToast({
            title: "Phone Number Created",
            description: "",
            buttonExists: false,
            color: "emerald",
            type: 1,
          });

          //fetch the updated list of PhoneNumbers
          this.fetchTenantsPhoneNumbers();
          this.toggleTenantsPhoneNumbersCreateModal();
        } else {
          console.error(data.message);
          toastsStore.addToast({
            title: "Error Creating Phone Number",
            description: data.message,
            buttonExists: false,
            color: "red",
            type: 1,
          });
        }
      } catch (error) {
        console.error();
      }
    },

    async updateTenantsPhoneNumber() {
      const id = this.idToEdit;
      try {
        const { data } = await axios.put(
          `${BASE_API_URL}/update-tenants-phone-number/${id}`,
          this.currentlyEditingPhoneNumber
        );

        if (data.status === "success") {
          console.log(data.message);

          this.toggleTenantsPhoneNumbersEditModal();
          //fetch the updated list of PhoneNumbers
          this.fetchTenantsPhoneNumbers();

          //throw toast
          const toastsStore = useGlobalToastsStore();
          toastsStore.addToast({
            title: "Phone Number Updated",
            description: "",
            buttonExists: false,
            color: "emerald",
            type: 1,
          });
        } else {
          console.error(data.message);
          toastsStore.addToast({
            title: "Error Updating Phone Number",
            description: data.message,
            buttonExists: false,
            color: "red",
            type: 1,
          });
        }
      } catch (error) {
        console.error();
      }
    },

    async deleteTenantsPhoneNumber(passportId) {
      const promptStore = useGlobalPromptsStore();

      const group_id = passportId;

      promptStore.addPrompt({
        type: "Phone Number",
        title: "Delete Phone Number?",
        message: `Are you sure you want to delete this Phone Number?`,
      });

      const promptResponse = await promptStore.waitForPromptConfirmation();

      if (promptResponse === true) {
        promptStore.addPrompt({
          type: "Phone Number",
          title: "Are You Sure?",
          message: `You can manually add this number back in later if you need to but users won't be able to use it once deleted. Are you sure you want to delete this Phone Number?`,
        });

        const promptResponse2 = await promptStore.waitForPromptConfirmation();

        if (promptResponse2 === true) {
          try {
            const { data } = await axios.delete(
              `${BASE_API_URL}/delete-tenants-phone-number/${group_id}`
            );

            if (data.status === "success") {
              //fetch the updated list of PhoneNumbers
              this.fetchTenantsPhoneNumbers();
            } else {
              console.error(data.message);
              toastsStore.addToast({
                title: "Error Deleting Phone Number",
                description: data.message,
                buttonExists: false,
                color: "red",
                type: 1,
              });
            }
          } catch (error) {
            console.error();
          }
          promptStore.removePrompt();
        } else {
          promptStore.removePrompt();
        }
      } else {
        promptStore.removePrompt();
      }
    },
  },
});
