<script setup>
defineOptions({
  name: 'cTinyEditor', // Give the component a name
});
import {
  ref,
  watch,
  reactive,
  watchEffect,
  onMounted,
  nextTick,
  toRefs,
  Editor,
} from "./../clientImports";

const emits = defineEmits(["editorInitialized"]);
const props = defineProps({
  editorId: {
    type: String,
  },

  propWidth: {
    type: String,
    default: "100%",
  },
});

const { editorId, propWidth } = props;

const fullScreenEditor = ref(false);

const toggleFullScreen = () => {
  fullScreenEditor.value = !fullScreenEditor.value;
};

// Editor configuration
const editorConfig = reactive({
  setup(editor) {
    editor.on("init", () => {
      emits("editorInitialized");
    });
    // Include any other setup logic you need
  },

  height: "100%",
  max_height: "100%",
  width: propWidth,
  max_width: "100%",
  resize: false,
  elementpath: true,
  branding: false,
  skin: "oxide-dark",
  icon: "jam",

  //paste configs
  paste_data_images: true, // Allow data images to be pasted
  paste_filter_drop: true, // Filter paste content
  paste_as_text: false, // Paste as text

  //enable the menu item
  menubar: [
    "file",
    "edit",
    "view",
    "insert",
    "format",
    "tools",
    "table",
    "help",
  ].join(" "),

  //customized menus
  // menu: {
  //   file: {
  //     title: "File",
  //     items: "saveas | print",
  //   },
  //   edit: {
  //     title: "Edit",
  //     items: "undo redo",
  //   },
  //   view: {
  //     title: "View",
  //     items: "",
  //   },
  //   insert: {
  //     title: "Insert",
  //     items: "",
  //   },
  //   format: {
  //     title: "Format",
  //     items: "",
  //   },
  //   tools: {
  //     title: "Tools",
  //     items: "searchreplace",
  //   },
  //   table: {
  //     title: "Table",
  //     items: "",
  //   },
  //   help: {
  //     title: "Help",
  //     items: "",
  //   },
  // },

  plugins: [
    "preview importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount help charmap quickbars emoticons accordion",
  ],

  file_picker_callback: (callback, value, meta) => {
    /* Provide file and text for the link dialog */
    // if (meta.filetype === "file") {
    //   callback("https://www.google.com/logos/google.jpg", { text: "My text" });
    // }
    /* Provide image and alt text for the image dialog */
    // if (meta.filetype === "image") {
    //   callback("https://www.google.com/logos/google.jpg", {
    //     alt: "My alt text",
    //   });
    // }
    /* Provide alternative source and posted for the media dialog */
    // if (meta.filetype === "media") {
    //   callback("movie.mp4", {
    //     source2: "alt.ogg",
    //     poster: "https://www.google.com/logos/google.jpg",
    //   });
    // }
    console.log("file_picker_callback NOT IN USE");
  },

  //free plugins
  freePlugins: [
    // "anchor",
    // "autolink",
    // "link",
    // "image",
    // "media",
    // "mediaembed",
    // "lists",
    // "nonbreaking",
    // "pagebreak",
    // "table",
    // "accordion",
    // "charmap",
    // "code",
    // "codesample",
    // "directionality",
    // "emoticons",
    // "fullscreen",
    // "help",
    // "importcss",
    // "insertdatetime",
    // "preview",
    // "searchreplace",
    // "template",
    // "visualblocks",
    // "visualchars",
    // "autosave",
    // "save",
    // "quickbars",
    // "wordcount",
  ],

  //paid plugins
  paidPlugins: [
    // "ai",
    // "mentions",
    // "mergetags",
    // "tinycomments",
    // "tinycomments",
    // "a11ychecker",
    // "linkchecker",
    // "tinymcespellchecker",
    // "autocorrect",
    // "casechange",
    // "formatpainter",
    // "typography",
    // "permanentpen",
    // "advcode",
    // "inlinecss",
    // "editimage",
    // "advtemplate",
    // "advtable",
    // "checklist",
    // "tableofcontents",
    // "footnotes",
    // "pageembed",
    // "export",
    // "powerpaste",
    // "mediaembed",
  ],

  //toolbar settings
  // toolbarSettings: [
  //   "undo",
  //   "redo",
  //   "restoredraft",
  //   "removeformat",
  //   "fonts",
  //   "fontsize",
  //   "blocks",
  //   "bold",
  //   "italic",
  //   "underline",
  //   "strikethrough",
  //   "align",
  //   "lineheight",
  //   "ltr",
  //   "rtl",
  //   "indent",
  //   "outdent",
  //   "numlist",
  //   "bullist",
  //   "blockquote",
  // ],

  toolbar:
    "code | undo redo | accordion accordionremove | blocks fontfamily fontsize | bold italic underline strikethrough | align numlist bullist | link image | table media | lineheight outdent indent| forecolor backcolor removeformat | charmap emoticons | code fullscreen preview | save print | pagebreak anchor codesample | ltr rtl",

  quickbars_selection_toolbar:
    "bold italic underline h1 h2 h3 h4 h5 h6 quicklink quickimage quicktable", // when selecting text
  quickbars_insert_toolbar: "", // auto shows when cursor is in editor

  //good
  contextmenu:
    "inserttable | tableinsertrowbefore tableinsertrowafter | tabledeleterow | tablecellprops tablesplitcells tablemergecells tableprops",
  contextmenu_never_use_native: true, // Always use TinyMCE context menu

  insertdatetime_dateformat: "%dd-%mm-%YYYY", // Date format

  //   image_list: [
  //     { title: "My image 1", value: "https://www.example.com/my1.gif" },
  //     { title: "My image 2", value: "http://www.moxiecode.com/my2.gif" },
  //   ],

  //   menu: { tools: { title: "Tools", items: "listprops" } },

  //   tinycomments_mode: "embedded",
  //   tinycomments_author: "Author name",

  //   mergetags_list: [
  //     { value: "First.Name", title: "First Name" },
  //     { value: "Email", title: "Email" },
  //   ],

  //   ai_request: (request, respondWith) =>
  //     respondWith.string(() =>
  //       Promise.reject("See docs to implement AI Assistant")
  //     ),
});
</script>

<template>
  <div
    :class="
      fullScreenEditor
        ? 'fixed top-0 left-0 h-screen w-screen'
        : 'relative h-[440px]'
    "
    class="col-span-4"
  >
    <Editor
      :id="editorId"
      api-key="f4c491mp7nk1lw7puzz6d9udhwb07h561p6gynuqc0n6c4tk"
      :init="editorConfig"
    />

    <button
      type="button"
      @click="toggleFullScreen"
      class="absolute top-1 right-1 text-white rounded-br-lg text-xs btn z-100"
    >
      <font-awesome-icon icon="fa-solid fa-expand" />
    </button>
    <!-- <div
      class="absolute bottom-0 right-0 bg-gradient-to-br from-blue-500 to-orange-500 text-white rounded-br-lg text-xs w-16 h-7 flex flex-row items-center justify-center"
    >
      FinnyPi
    </div> -->
  </div>
</template>
