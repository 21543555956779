<script setup>
defineOptions({
  name: 'Website', // Give the component a name
});
import {
  //vue
  ref,
  computed,
  watch,
  reactive,

  // stores
  useGlobalToastsStore,
  useWebsiteStore,

  //formkit
  FKsubmitForm,
  copyText,

  //utils
  xLog,
} from "../clientImports";

const toastsStore = useGlobalToastsStore();
const websiteStore = useWebsiteStore();

const companyDetails = reactive({
  name: "FinnyPi",
  phone: "714-395-2633",
  email: "info@finnypi.com",
  legal_address: "22501 Chase Aliso Viejo, CA 92656",
  mailing_address: "22501 Chase Aliso Viejo, CA 92656",
  googe_maps_link:
    "https://www.google.com/maps/place/22501+Chase,+Aliso+Viejo,+CA+92656/@33.5795662,-117.7376901,17z/data=!3m1!4b1!4m6!3m5!1s0x80dce7cb1ddc62a3:0x47420c1495a25437!8m2!3d33.5795662!4d-117.7376901!16s%2Fg%2F11pr04856m?entry=ttu",
});

const showMobileSidebar = ref(false);
const toggleMobileSidebar = () => {
  showMobileSidebar.value = !showMobileSidebar.value;
};

const displayItems = ref([
  {
    title: "Contact Management",
    iconClass: "fa-solid fa-address-book",
    color: "red",
    borderColor: "border-red-400 mt-10",
  },
  {
    title: "Appointments",
    iconClass: "fa-regular fa-calendar-check",
    color: "orange",
    borderColor: "border-orange-400",
  },
  {
    title: "Tasks",
    iconClass: "fa-solid fa-list-check",
    color: "yellow",
    borderColor: "border-yellow-400 mt-10",
  },
  {
    title: "Custom Permissions",
    iconClass: "fa-solid fa-shield-halved",
    color: "emerald",
    borderColor: "border-emerald-400",
  },
  {
    title: "Custom Roles",
    iconClass: "fa-brands fa-redhat",
    color: "indigo",
    borderColor: "border-indigo-400 mt-10",
  },
  {
    title: "SMS Integrations",
    iconClass: "fa-solid fa-comments",
    color: "purple",
    borderColor: "border-purple-400",
  },
  {
    title: "Email Integrations",
    iconClass: "fa-solid fa-envelope",
    color: "teal",
    borderColor: "border-teal-400 mt-10",
  },
  {
    title: "Integrated Payments",
    iconClass: "fa-solid fa-dollar-sign",
    color: "emerald",
    borderColor: "border-emerald-400",
  },
  {
    title: "Time Clock Management",
    iconClass: "fa-solid fa-clock",
    color: "gray",
    borderColor: "border-gray-400 mt-10",
  },
  {
    title: "Bot Automations",
    iconClass: "fa-solid fa-robot",
    color: "rose",
    borderColor: "border-rose-400",
  },
]);

const settingsItems = ref([
  {
    title: "Companies",
    description: "Manage multiple companies from one account",
    iconClass: "fa-solid fa-address fa-building",
    color: "bg-stone-500",
    borderColor: "border-stone-400",
  },

  {
    title: "Users",
    description: "Manage users and their permissiones",
    iconClass: "fa-solid fa-address-book",
    color: "bg-red-500",
    borderColor: "border-red-400",
  },

  {
    title: "Campaigns",
    description: "Create custom campaigns and apply them to contacts",
    iconClass: "fa-solid fa-map-pin",
    color: "bg-orange-500",
    borderColor: "border-orange-400",
  },

  {
    title: "Departments",
    description: "Create custom departments that users can be assigned to",
    iconClass: "fa-solid fa-building-user",
    color: "bg-yellow-500",
    borderColor: "border-yellow-400",
  },

  {
    title: "Roles",
    description:
      "Create custom roles and add permissiones to them to be assigned to users",
    iconClass: "fa-brands fa-redhat",
    color: "bg-violet-500",
    borderColor: "border-violet-400",
  },

  {
    title: "Commissions",
    description: "Create commissions rules to track owed commissions to users",
    iconClass: "fa-solid fa-sack-dollar",
    color: "bg-emerald-500",
    borderColor: "border-emerald-400",
  },

  {
    title: "Passports",
    description: "Create an IP Passport to restrict access to specific IPs",
    iconClass: "fa-solid fa-location-dot",
    color: "bg-red-500",
    borderColor: "border-red-400",
  },

  {
    title: "Contact Types",
    description:
      "Classify your contacts into different types such as: Person, Company, Employee, Etc",
    iconClass: "fa-regular fa-clone",
    color: "bg-orange-500",
    borderColor: "border-orange-400",
  },

  {
    title: "Stages",
    description:
      "Create custom stages to track your contacts progress through your sales process",
    iconClass: "fa-solid fa-bars-staggered",
    color: "bg-stone-500",
    borderColor: "border-stone-400",
  },

  {
    title: "Milestones",
    description:
      "Create custom milestones to group your statuses into different categories such as: Lead, Prospect, Client, Etc",
    iconClass: "fa-solid fa-star",
    color: "bg-yellow-500",
    borderColor: "border-yellow-400",
  },

  {
    title: "Tags",
    description:
      "Create custom tags that allows one or more to be applied to your contacts",
    iconClass: "fa-solid fa-tags",
    color: "bg-blue-500",
    borderColor: "border-blue-400",
  },

  {
    title: "Templates",
    description:
      "Create custom templates for emails, sms, notes, notifications and more!",
    iconClass: "fa-regular fa-copy",
    color: "bg-indigo-500",
    borderColor: "border-indigo-400",
  },

  {
    title: "Events",
    description:
      "Create custom events that are used to schedule calendar events for your contacts",
    iconClass: "fa-solid fa-calendar-days",
    color: "bg-green-500",
    borderColor: "border-green-400",
  },

  {
    title: "Tasks",
    description:
      "Custom tasks that can be assigned to contacts to be completed by users",
    iconClass: "fa-solid fa-list-check",
    color: "bg-rose-500",
    borderColor: "border-rose-400",
  },

  {
    title: "Email Domain",
    description:
      "Add your custom email domain to send emails to and from your contacts. Emails are stored in the contacts file.",
    iconClass: "fa-solid fa-at",
    color: "bg-purple-500",
    borderColor: "border-purple-400",
  },

  {
    title: "SMS Integration",
    description:
      "Add your twilio API Credentials to our system to send and receive text messages with your contacts.",
    iconClass: "fa-solid fa-comments",
    color: "bg-emerald-500",
    borderColor: "border-emerald-400",
  },

  {
    title: "Phone Numbers",
    description:
      "Add phone numbers to the system that can be used to send and receive text messages",
    iconClass: "fa-solid fa-phone",
    color: "bg-gray-500",
    borderColor: "border-gray-400",
  },

  {
    title: "Form Builder",
    description:
      "Build custom forms with custom fields that can be used to collect custom data points on your contacts files.",
    iconClass: "fa-regular fa-rectangle-list",
    color: "bg-sky-500",
    borderColor: "border-sky-400",
  },

  {
    title: "PDF Generator",
    description:
      "Map your custom fields to a PDF template to generate a PDF document for your contacts.",
    iconClass: "fa-solid fa-file-lines",
    color: "bg-red-500",
    borderColor: "border-red-400",
  },

  {
    title: "Webhooks",
    description:
      "Create custom webhook templates to post data inbound or outbound.",
    iconClass: "fa-solid fa-right-left",
    color: "bg-purple-500",
    borderColor: "border-purple-400",
  },

  {
    title: "Connections",
    description:
      "Add your payment Gateway's connection to the system to take payments from your contacts.",
    iconClass: "fa-solid fa-building-columns",
    color: "bg-emerald-500",
    borderColor: "border-emerald-400",
  },

  {
    title: "Routes",
    description:
      "Route your payments to different merchants using custom Routes",
    iconClass: "fa-solid fa-money-bill-transfer",
    color: "bg-green-500",
    borderColor: "border-green-400",
  },

  {
    title: "Payment Plan Presets",
    description:
      "Create a custom payment plan preset and apply it to your contacts to automatically create a payment schedule for them",
    iconClass: "fa-solid fa-list",
    color: "bg-teal-500",
    borderColor: "border-teal-400",
  },

  {
    title: "Bots",
    description:
      "Create bots that can have a set of tasks assigned to them to be performed at different occurences with the system.",
    iconClass: "fa-solid fa-robot",
    color: "bg-teal-500",
    borderColor: "border-teal-400",
  },

  {
    title: "FinnyPi GPT",
    description:
      "A custom GPT-3 AI that can be used to ask helpful business questions to, as well as help speed up content creation for things like email templates",
    iconClass: "fa-solid fa-robot",
    color: "bg-gradient-to-tr from-red-500 via-indigo-500 to-emerald-500",
    borderColor: "border-red-400",
  },
  {
    title: "And More To Come!",
    description:
      "As a software company, we like to code things that make our clients happy. We are always adding new features and functionality to the system. If you have a feature request, please let us know!",
    iconClass: "fa-solid fa-ellipsis",
    color: "bg-slate-500",
    borderColor: "border-slate-400 col-span-2 xl:col-span-5",
  },
]);

const pageTop = ref(null);
const contactFile = ref(null);
const settings = ref(null);
const mobileResponsive = ref(null);

const scrollToElement = (id) => {
  if (id === "contactFile") {
    contactFile.value.scrollIntoView({ behavior: "smooth" });
  }

  if (id === "settings") {
    settings.value.scrollIntoView({ behavior: "smooth" });
  }

  if (id === "mobileResponsive") {
    mobileResponsive.value.scrollIntoView({ behavior: "smooth" });
  }

  if (id === "pageTop") {
    pageTop.value.scrollIntoView({ behavior: "smooth" });
  }
};

const scrollToElementMobile = (origin) => {
  scrollToElement(origin);
  toggleMobileSidebar();
};

const businessAddressGoogleMapsLink = computed(() => {
  return `https://www.google.com/maps/place/22501+Chase,+Aliso+Viejo,+CA+92656/@33.5795662,-117.7376901,17z/data=!3m1!4b1!4m6!3m5!1s0x80dce7cb1ddc62a3:0x47420c1495a25437!8m2!3d33.5795662!4d-117.7376901!16s%2Fg%2F11pr04856m?entry=ttu`;
});

const isHovered = ref(false);

const toggleHoverState = () => {
  setTimeout(() => {
    isHovered.value = !isHovered.value;
  }, 900);
};

const handleScroll = (event) => {
  if (!isHovered.value) {
    return;
  }

  event.preventDefault(); // Prevents the default vertical scroll
  const element = event.currentTarget;
  element.scrollLeft += event.deltaY; // Translates vertical scroll to horizontal
};

const showTermsModal = ref(false);

const toggleTermsModal = () => {
  websiteStore.toggleTermsOfService();
};

const showPrivacyPolicyModal = ref(false);
const togglePrivacyPolicyModal = () => {
  websiteStore.togglePrivacyPolicy();
};

const showCallModal = ref(false);
const toggleCallModal = () => {
  showCallModal.value = !showCallModal.value;
};

const copyTextAndAlert = (type, value) => {
  copyText(value);
  toastsStore.addToast({
    title: `${type} Copied`,
    buttonExists: false,
    color: "blue",
    type: 1, // 1 = small, 2 = md, 3 = lg, 4 = lg dismissable
  });
};

const showEmailModal = ref(false);

const toggleEmailModal = () => {
  showEmailModal.value = !showEmailModal.value;
};

const showAddressModal = ref(false);
const toggleAddressModal = () => {
  showAddressModal.value = !showAddressModal.value;
};
</script>

<template>
  <div class="h-screen w-screen flex flex-col">
    <!-- nav -->
    <div
      class="flex flex-row items-center justify-between bg-base-300 border-b border-b-slate-400"
    >
      <div
        class="border-r border-r-slate-400 h-full flex justify-center items-center bg-gray-900"
      >
        <cSystemLogo
          classes="text-lg py-2 px-5"
          marginBottom="m-0"
        ></cSystemLogo>
      </div>
      <div class="flex flex-row items-center">
        <div class="max-sm:hidden p-1 flex-1 flex flex-row items-center gap-5">
          <div
            @click="scrollToElement('contactFile')"
            class="cursor-pointer hover:bg-blue-600 px-4 py-1 max-sm:text-3xl text-right text-sky-50 capitalize rounded border-none glass"
          >
            UI
          </div>

          <div
            @click="scrollToElement('mobileResponsive')"
            class="cursor-pointer hover:bg-blue-600 px-4 py-1 max-sm:text-3xl text-right text-sky-50 capitalize rounded border-none glass"
          >
            App
          </div>

          <div
            @click="scrollToElement('settings')"
            class="cursor-pointer hover:bg-blue-600 px-4 py-1 max-sm:text-3xl text-right text-sky-50 capitalize rounded border-none glass"
          >
            Features
          </div>

          <button
            @click="scrollToElement('pageTop')"
            class="fixed bottom-2 left-2 btn btn-xs rounded bg-base-300 text-white border border-slate-400"
          >
            <font-awesome-icon icon="fa-solid fa-chevron-up" />
          </button>

          <button
            class="fixed bottom-2 right-2 btn btn-md rounded-full bg-blue-500 text-white border border-white"
          >
            <font-awesome-icon icon="fa-solid fa-comments" />
          </button>

          <button
            @click="toggleEmailModal"
            class="btn btn-sm rounded bg-red-500 text-white border-none"
          >
            <font-awesome-icon icon="fa-solid fa-at" />
          </button>

          <button
            @click="toggleCallModal"
            class="btn btn-sm rounded bg-indigo-500 text-white border-none"
          >
            <font-awesome-icon icon="fa-solid fa-phone-flip" />
          </button>

          <router-link
            to="/sign-up"
            class="btn btn-md rounded-full bg-emerald-500 text-white border-none capitalize"
          >
            Get Started
          </router-link>
        </div>
        <transition
          enter-active-class="transition-all duration-300 ease-in-out"
          enter-from-class="translate-x-full"
          enter-to-class="translate-x-0"
          leave-active-class="transition-all duration-300 ease-in-out"
          leave-from-class="translate-x-0"
          leave-to-class="translate-x-full"
        >
          <div
            v-if="showMobileSidebar"
            @click.self="toggleMobileSidebar"
            class="max-sm:z-50 flex flex-col items-end max-sm:fixed max-sm:top-0 max-sm:left-0 max-sm:w-screen max-sm:h-screen md:flex-row"
          >
            <div
              v-if="showMobileSidebar"
              class="w-10/12 flex-1 bg-base-100 flex flex-col justify-start gap-3 border-l border-l-slate-400"
            >
              <div
                class="flex flex-row justify-between items-center border-b border-b-slate-400"
              >
                <div
                  class="flex flex-row justify-between items-center gap-1 px-3"
                >
                  <div class="flex flex-row gap-3">
                    <button
                      class="btn btn-xs bg-blue-500 text-white border-none rounded-full"
                    >
                      <font-awesome-icon icon="fa-solid fa-comments" />
                    </button>
                  </div>

                  <div class="flex flex-row gap-3">
                    <button
                      @click="toggleEmailModal"
                      class="btn btn-xs bg-red-500 text-white border-none rounded-full"
                    >
                      <font-awesome-icon icon="fa-solid fa-at" />
                    </button>
                  </div>

                  <div class="flex flex-row gap-3">
                    <a
                      @click="toggleCallModal"
                      class="btn btn-xs bg-indigo-500 text-white border-none rounded-full"
                    >
                      <font-awesome-icon icon="fa-solid fa-phone-flip" />
                    </a>
                  </div>
                </div>
                <button
                  @click="toggleMobileSidebar"
                  class="sticky top-0 right-0 btn btn-sm text-white rounded-none"
                >
                  <font-awesome-icon icon="fa-solid fa-xmark" />
                </button>
              </div>
              <div class="p-5 flex-1 flex flex-col gap-5">
                <div
                  @click="scrollToElementMobile('contactFile')"
                  class="p-2 max-sm:text-3xl text-left text-sky-50 capitalize rounded border-none glass"
                >
                  UI
                </div>

                <div
                  @click="scrollToElementMobile('mobileResponsive')"
                  class="p-2 max-sm:text-3xl text-left text-sky-50 capitalize rounded border-none glass"
                >
                  App
                </div>

                <div
                  @click="scrollToElementMobile('settings')"
                  class="p-2 max-sm:text-3xl text-left text-sky-50 capitalize rounded border-none glass"
                >
                  Features
                </div>
              </div>
              <div class="flex flex-row item-center justify-between px-2 gap-2">
                <router-link
                  :to="{ name: 'login' }"
                  class="btn btn-sm rounded bg-blue--500 text-white border-none capitalize bg-blue-500"
                >
                  Login
                </router-link>
                <router-link
                  :to="{ name: 'sign-up' }"
                  class="btn btn-sm rounded bg-emerald-500 text-white border-none capitalize flex-1"
                >
                  Create Account
                </router-link>
              </div>

              <div
                class="flex flex-row justify-between gap-2 w-full px-2 py-3 overflow-x-auto hide-scroll border-t border-t-slate-400"
              >
                <button
                  class="btn btn-sm md:btn-md rounded-full bg-blue-500 text-white border-none shadow-2xl shadow-slate-900"
                >
                  <font-awesome-icon icon="fa-brands fa-facebook" />
                </button>
                <button
                  class="btn btn-sm md:btn-md rounded-full bg-cyan-500 text-white border-none shadow-2xl shadow-slate-900"
                >
                  <font-awesome-icon icon="fa-brands fa-twitter" />
                </button>
                <button
                  class="btn btn-sm md:btn-md rounded-full bg-blue-500 text-white border-none shadow-2xl shadow-slate-900"
                >
                  <font-awesome-icon icon="fa-brands fa-linkedin" />
                </button>
                <button
                  class="btn btn-sm md:btn-md rounded-full bg-pink-500 text-white border-none shadow-2xl shadow-slate-900"
                >
                  <font-awesome-icon icon="fa-brands fa-instagram" />
                </button>

                <button
                  class="btn btn-sm md:btn-md rounded-full bg-red-500 text-white border-none shadow-2xl shadow-slate-900"
                >
                  <font-awesome-icon icon="fa-brands fa-youtube" />
                </button>

                <button
                  class="btn btn-sm md:btn-md rounded-full bg-gradient-to-br from-blue-500 via-black to-pink-500 text-white border-none shadow-2xl shadow-slate-900"
                >
                  <!-- tiktok -->

                  <font-awesome-icon icon="fa-brands fa-tiktok" />
                </button>
              </div>
            </div>
          </div>
        </transition>

        <div class="pr-2 py-2">
          <button
            @click="toggleMobileSidebar"
            class="sm:hidden btn btn-sm md:btn-md text-white border border-slate-400"
          >
            <font-awesome-icon icon="fa-solid fa-bars" />
          </button>
        </div>
      </div>
    </div>

    <!-- main -->
    <div
      class="flex-1 bg-base-100 overflow-y-auto overflow-x-hidden hide-scroll"
    >
      <!-- Intro Slide -->
      <div ref="pageTop" class="h-full max-h-full flex-1 flex flex-col">
        <div
          class="flex-1 flex flex-col justify-center items-center text-shadow-lg gap-12 md:gap-28"
        >
          <div class="px-5">
            <div
              class="p-1 gradient-animation rounded shadow-lg shadow-slate-900"
            >
              <h1
                class="text-3xl md:text-6xl p-5 rounded border border-slate-400 text-sky-50 bg-base-200"
              >
                So Intuitive, So Simple... Your New
                <span class="text-5xl md:text-6xl"> CRM </span>
              </h1>
            </div>
          </div>

          <div
            @mouseenter="toggleHoverState"
            @mouseleave="toggleHoverState"
            @wheel="handleScroll"
            class="justify-between flex flex-row gap-5 w-full max-w-full overflow-x-auto overflow-y-hidden hide-scroll"
          >
            <div class="px-1 text-white sticky left-0 translate-y-1/2">
              <font-awesome-icon icon="fa-solid fa-chevron-left" />
            </div>
            <div
              v-for="(item, i) in displayItems"
              :class="`bg-base-200 h-24 md:h-28 w-24 md:w-28 shrink-0 p-2 rounded border ${item?.borderColor} text-center shadow-lg shadow-slate-900 flex flex-col justify-center items-center gap-2 cursor-pointer hover:bg-base-300`"
            >
              <font-awesome-icon
                :icon="item?.iconClass"
                :class="`bg-${item.color}-500 text-white rounded-full p-2`"
              />
              <span class="text-xs md:text-sm text-white">{{
                item.title
              }}</span>
            </div>
            <div class="p-1 text-white sticky right-0 translate-y-1/2">
              <font-awesome-icon icon="fa-solid fa-chevron-right" />
            </div>
          </div>

          <button
            @click="scrollToElement('contactFile')"
            class="btn btn-lg gradient-animation text-sky-50 capitalize rounded-full border-none"
          >
            Show Me The UI
          </button>
        </div>
      </div>
      <!-- slide-1 -->
      <div
        ref="contactFile"
        class="md:h-full md:max-h-full flex-1 flex flex-col items-center justify-center gap-12 bg-base-300 border-y-2 border-y-slate-400 border-dotted md:p-3 max-sm:py-16"
      >
        <h5 class="text-3xl md:text-5xl text-sky-50">FinnyPi UI</h5>

        <div class="md:w-2/5">
          <div class="text-sky-50 p-3">
            View company metrics at the dashboard tab where you land upon login.
            Here you'll see the data based on your scope of access.
            <br />
            <br />
            Send and receive text messages through your clients contact file.
            Set all kinds of different flags on your contact like: campaign
            source, stage, payment plan status or workflow status (And more!).
            <br />
            <br />

            Filter and save preset lists of contacts. Create custom fields to be
            used in the filters. Take Notes, Schedule Events, Create Tasks and
            features being released all the time!
          </div>
        </div>

        <div
          @mouseenter="toggleHoverState"
          @mouseleave="toggleHoverState"
          @wheel="handleScroll"
          class="h-full flex flex-row overflow-x-auto overflow-y-hidden hide-scroll gap-5"
        >
          <!-- chevron left -->
          <div class="px-1 text-white sticky left-0 translate-y-1/2">
            <font-awesome-icon icon="fa-solid fa-chevron-left" />
          </div>

          <img
            src="../../public/web_images/dt_dashboard.png"
            class="border-2 border-slate-400 rounded h-full"
            alt="mobile app screenshot"
          />
          <img
            src="../../public/web_images/dt_full_ui.png"
            class="border-2 border-slate-400 rounded h-full"
            alt="mobile app screenshot"
          />

          <img
            src="../../public/web_images/dt_contact_file.png"
            class="border-2 border-slate-400 rounded h-full"
            alt="mobile app screenshot"
          />
          <!-- right chevron -->
          <div class="px-1 text-white sticky right-0 translate-y-1/2">
            <font-awesome-icon icon="fa-solid fa-chevron-right" />
          </div>
        </div>

        <button
          @click="scrollToElement('mobileResponsive')"
          class="btn btn-md gradient-animation text-sky-50 capitalize rounded border-none"
        >
          Progressive Web Application
        </button>
      </div>

      <div
        ref="mobileResponsive"
        class="lg:h-full lg:max-h-full flex-1 flex flex-col items-center justify-center gap-12 bg-base-100 border-y-2 border-y-slate-400 border-dotted md:p-3 max-sm:py-16"
      >
        <h5 class="text-3xl md:text-5xl text-sky-50">Mobile App</h5>

        <div class="md:w-2/5">
          <h4 class="text-sky-50 p-3">
            Built with mobile responsitivity in mind. Allowing you to be fully
            operational on your mobile device just as you would on our desktop
            layout.
          </h4>
        </div>

        <div class="max-sm:h-96 h-full md:h-1/2 p-3">
          <!-- slide-3 -->
          <div class="h-full max-h-full flex-1 flex flex-col">
            <div
              @mouseenter="toggleHoverState"
              @mouseleave="toggleHoverState"
              @wheel="handleScroll"
              class="flex-1 rounded overflow-y-hidden flex flex-row justify-between items-center gap-5 overflow-x-auto hide-scroll"
            >
              <div class="px-1 text-white sticky left-0 translate-y-1/2">
                <font-awesome-icon icon="fa-solid fa-chevron-left" />
              </div>

              <img
                src="../../public/web_images/ss_login.jpg"
                class="h-full border-2 border-slate-400 rounded"
                alt="mobile app screenshot"
              />

              <img
                src="../../public/web_images/ss_dashboard.jpg"
                class="h-full border-2 border-slate-400 rounded"
                alt="mobile app screenshot"
              />

              <img
                src="../../public/web_images/ss_users_list.jpg"
                class="h-full border-2 border-slate-400 rounded"
                alt="mobile app screenshot"
              />

              <img
                src="../../public/web_images/ss_settings_list.jpg"
                class="h-full border-2 border-slate-400 rounded"
                alt="mobile app screenshot"
              />
              <img
                src="../../public/web_images/ss_create_contact.jpg"
                class="h-full border-2 border-slate-400 rounded"
                alt="mobile app screenshot"
              />

              <div class="px-1 text-white sticky right-0 translate-y-1/2">
                <font-awesome-icon icon="fa-solid fa-chevron-right" />
              </div>
            </div>
          </div>
        </div>

        <button
          @click="scrollToElement('settings')"
          class="btn btn-md gradient-animation text-sky-50 capitalize rounded border-none"
        >
          FinnyPi Capabilities
        </button>
      </div>

      <!-- slide-2 -->
      <div
        ref="settings"
        class="flex-1 flex flex-col items-center justify-center gap-12 bg-base-100 p-3"
      >
        <h5 class="text-3xl md:text-5xl text-sky-50 mt-16">Tons of Settings</h5>

        <div class="md:w-2/5">
          <h4 class="text-sky-50">
            Customize in a way that works well with your business. Create
            Statuses, Tags, Campaigns, Workflows, Payment Plans, Fields, and
            more!
          </h4>
        </div>

        <div class="md:w-2/3 mb-16">
          <!-- slide-3 -->
          <div class="flex flex-col">
            <div
              class="rounded overflow-hidden flex flex-row justify-center items-center gap-5"
            >
              <div class="grid grid-cols-2 xl:grid-cols-5 gap-5 md:gap-10">
                <div
                  v-for="(item, i) in settingsItems"
                  :class="`bg-base-200 shrink-0 p-2 rounded border ${item?.borderColor} text-center shadow-lg shadow-slate-900 flex flex-col justify-center items-center gap-2 cursor-pointer hover:bg-base-300`"
                >
                  <font-awesome-icon
                    :icon="item?.iconClass"
                    :class="`${item.color} text-white rounded-full p-2`"
                  />
                  <span class="text-xs md:text-sm text-white">{{
                    item.title
                  }}</span>

                  <span class="text-xs md:text-sm text-white">{{
                    item.description
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- FOOTER -->
      <div
        class="bg-slate-600 flex flex-row justify-between items-center flex-wrap gap-5 border-t border-t-slate-400"
      >
        <div class="max-md:w-full flex flex-row justify-center p-5 gap-5">
          <div
            class="bg-gray-50 shadow-2xl shadow-slate-900 flex flex-col justify-center items-center rounded-3xl"
          >
            <cSystemLogo
              classes="text-xl py-2 px-5"
              marginBottom="m-0"
            ></cSystemLogo>
            <div class="w-16">
              <img src="./../../public/web_images/Jesus.png" alt="" class="" />
            </div>
          </div>
          <div class="flex flex-col gap-1">
            <div class="flex flex-row gap-3">
              <button
                @click="toggleAddressModal"
                target="_blank"
                class="btn btn-xs bg-green-500 text-white border-none rounded-full"
              >
                <font-awesome-icon icon="fa-solid fa-location-dot" />
              </button>
              <p class="text-sky-50">{{ companyDetails.legal_address }}</p>
            </div>

            <Teleport to="#modals-target">
              <div
                v-if="showAddressModal"
                @click.self="toggleAddressModal"
                class="fixed left-0 top-0 z-70 w-screen h-screen backdrop-brightness-50 flex flex-col justify-center items-center"
              >
                <div
                  class="bg-base-100 h-full md:h-2/3 w-full md:w-1/2 border border-slate-400 rounded flex flex-col justify-center items-center"
                >
                  <div class="h-full w-full flex flex-col">
                    <div class="sticky top-0 right-0 flex flex-row justify-end">
                      <button
                        class="btn btn-xs md:btn-sm rounded-none text-xs bg-base-300 hover:bg-blue-500 border-none"
                        @click="toggleAddressModal"
                      >
                        <font-awesome-icon
                          icon="fa-solid fa-times"
                          class="text-white"
                          size="md"
                        />
                      </button>
                    </div>
                    <div class="flex flex-col gap-5 items-center">
                      <!-- <cSystemLogo
                        classes="px-2 py-2 text-5xl"
                        marginBottom="mb-0"
                      ></cSystemLogo> -->

                      <!-- <h3 class="text-4xl mb-3 font-light text-white">
                        Legal Address
                      </h3> -->
                    </div>
                    <div class="flex-1">
                      <div
                        class="grid grid-cols-1 md:grid-cols-2 p-2 gap-3 h-full justify-center"
                      >
                        <div
                          class="text-center border border-slate-400 text-white flex flex-col"
                        >
                          <div
                            class="flex flex-row justify-between items-center"
                          >
                            <h1 class="text-xl border-b border-b-slate-400 p-2">
                              Legal Address
                            </h1>
                            <div class="flex flex-col gap-3 pr-2">
                              <button
                                @click="
                                  copyTextAndAlert(
                                    'Legal Address',
                                    companyDetails.legal_address
                                  )
                                "
                                class="btn btn-sm capitalize text-white rounded bg-gray-500 border-none"
                              >
                                Copy Legal Address
                              </button>
                            </div>
                          </div>
                          <div
                            class="flex flex-col justify-center items-center h-full"
                          >
                            <iframe
                              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3323.991644580525!2d-117.73769010000001!3d33.579566199999995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80dce7cb1ddc62a3%3A0x47420c1495a25437!2s22501%20Chase%2C%20Aliso%20Viejo%2C%20CA%2092656!5e0!3m2!1sen!2sus!4v1702326036873!5m2!1sen!2sus"
                              width="100%"
                              height="100%"
                              style="border: 0"
                              allowfullscreen=""
                              loading="lazy"
                              referrerpolicy="no-referrer-when-downgrade"
                            ></iframe>
                            <h4 class="text-base p-2">
                              {{ companyDetails.legal_address }}
                            </h4>
                          </div>
                        </div>

                        <div
                          class="text-center border border-slate-400 text-white flex flex-col"
                        >
                          <div
                            class="flex flex-row justify-between items-center"
                          >
                            <h1 class="text-xl border-b border-b-slate-400 p-2">
                              Mailing Address
                            </h1>
                            <div class="flex flex-col gap-3 pr-2">
                              <button
                                @click="
                                  copyTextAndAlert(
                                    'Mailing Address',
                                    companyDetails.mailing_address
                                  )
                                "
                                class="btn btn-sm capitalize text-white rounded bg-gray-500 border-none"
                              >
                                Copy Mailing Address
                              </button>
                            </div>
                          </div>
                          <div
                            class="flex flex-col justify-center items-center h-full"
                          >
                            <iframe
                              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3323.991644580525!2d-117.73769010000001!3d33.579566199999995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80dce7cb1ddc62a3%3A0x47420c1495a25437!2s22501%20Chase%2C%20Aliso%20Viejo%2C%20CA%2092656!5e0!3m2!1sen!2sus!4v1702326036873!5m2!1sen!2sus"
                              width="100%"
                              height="100%"
                              style="border: 0"
                              allowfullscreen=""
                              loading="lazy"
                              referrerpolicy="no-referrer-when-downgrade"
                            ></iframe>
                            <h4 class="text-base p-2">
                              {{ companyDetails.mailing_address }}
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="flex flex-row justify-center">
                      <button
                        class="capitalize btn btn-sm btn-block rounded-none text-xs bg-red-500 hover:bg-red-700 border-none text-white"
                        @click="toggleAddressModal"
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Teleport>
            <div class="flex flex-row gap-3">
              <a
                @click="toggleCallModal"
                class="btn btn-xs bg-blue-500 text-white border-none rounded-full"
              >
                <font-awesome-icon icon="fa-solid fa-phone-flip" />
              </a>
              <p class="text-sky-50">{{ companyDetails.phone }}</p>
            </div>
            <div class="flex flex-row gap-3">
              <button
                @click="toggleEmailModal"
                class="btn btn-xs bg-red-500 text-white border-none rounded-full"
              >
                <font-awesome-icon icon="fa-solid fa-at" />
              </button>
              <p class="text-sky-50">info@finnypi.com</p>
            </div>
          </div>
        </div>
        <div class="flex flex-col max-md:w-full">
          <div class="flex flex-row justify-center gap-2 w-full">
            <button
              class="btn btn-sm md:btn-md rounded-full bg-blue-500 text-white border-none shadow-2xl shadow-slate-900"
            >
              <font-awesome-icon icon="fa-brands fa-facebook" />
            </button>
            <button
              class="btn btn-sm md:btn-md rounded-full bg-cyan-500 text-white border-none shadow-2xl shadow-slate-900"
            >
              <font-awesome-icon icon="fa-brands fa-twitter" />
            </button>
            <button
              class="btn btn-sm md:btn-md rounded-full bg-blue-500 text-white border-none shadow-2xl shadow-slate-900"
            >
              <font-awesome-icon icon="fa-brands fa-linkedin" />
            </button>
            <button
              class="btn btn-sm md:btn-md rounded-full bg-pink-500 text-white border-none shadow-2xl shadow-slate-900"
            >
              <font-awesome-icon icon="fa-brands fa-instagram" />
            </button>

            <button
              class="btn btn-sm md:btn-md rounded-full bg-red-500 text-white border-none shadow-2xl shadow-slate-900"
            >
              <font-awesome-icon icon="fa-brands fa-youtube" />
            </button>

            <button
              class="btn btn-sm md:btn-md rounded-full bg-gradient-to-br from-blue-500 via-black to-pink-500 text-white border-none shadow-2xl shadow-slate-900"
            >
              <!-- tiktok -->

              <font-awesome-icon icon="fa-brands fa-tiktok" />
            </button>
          </div>
        </div>

        <div class="flex flex-col max-md:w-full md:pr-5">
          <div class="flex flex-col justify-center md:items-start gap-2 w-full">
            <button
              @click="toggleTermsModal"
              class="text-white hover:text-blue-200"
            >
              Terms of Service
            </button>

            <Teleport to="#modals-target">
              <div
                @click.self="toggleTermsModal"
                v-if="websiteStore.showTermsOfService"
                class="fixed top-0 left-0 w-screen h-screen backdrop-brightness-50 flex flex-col justify-center items-center z-50"
              >
                <div
                  class="bg-gray-50 h-full md:w-1/2 md:h-1/2 h-50 overflow-y-auto rounded border border-slate-400"
                >
                  <div
                    class="pl-2 sticky top-0 right-0 flex flex-row justify-between items-center bg-base-100 border-b border-b-slate-400"
                  >
                    <p class="text-white">Terms & Conditions</p>
                    <button
                      class="btn btn-xs md:btn-sm rounded-none text-xs bg-base-300 hover:bg-blue-500 border-x-none border-r-none border-l border-l-slate-400"
                      @click="toggleTermsModal"
                    >
                      <font-awesome-icon
                        icon="fa-solid fa-times"
                        class="text-white"
                        size="md"
                      />
                    </button>
                  </div>
                  <WebsiteTerms></WebsiteTerms>
                  <div
                    class="flex flex-row justify-center sticky bottom-0 bg-base-100"
                  >
                    <button
                      class="capitalize btn btn-sm btn-block rounded-none text-xs bg-red-500 hover:bg-red-700 border-none text-white"
                      @click="toggleTermsModal"
                    >
                      Close Terms
                    </button>
                  </div>
                </div>
              </div>
            </Teleport>

            <button
              @click="togglePrivacyPolicyModal"
              class="text-white hover:text-blue-200"
            >
              Privacy Policy
            </button>

            <Teleport to="#modals-target">
              <div
                @click.self="togglePrivacyPolicyModal"
                v-if="websiteStore.showPrivacyPolicy"
                class="fixed top-0 left-0 w-screen h-screen backdrop-brightness-50 flex flex-col justify-center items-center z-50"
              >
                <div
                  class="bg-gray-50 h-full md:w-1/2 md:h-1/2 h-50 overflow-y-auto rounded border border-slate-400"
                >
                  <div
                    class="pl-2 sticky top-0 right-0 flex flex-row justify-between items-center bg-base-100 border-b border-b-slate-400"
                  >
                    <p class="text-white">Privacy Policy</p>
                    <button
                      class="btn btn-xs md:btn-sm rounded-none text-xs bg-base-300 hover:bg-blue-500 border-x-none border-r-none border-l border-l-slate-400"
                      @click="togglePrivacyPolicyModal"
                    >
                      <font-awesome-icon
                        icon="fa-solid fa-times"
                        class="text-white"
                        size="md"
                      />
                    </button>
                  </div>
                  <WebsitePrivacy></WebsitePrivacy>
                  <div
                    class="flex flex-row justify-center sticky bottom-0 bg-base-100"
                  >
                    <button
                      class="btn btn-sm btn-block rounded-none text-xs bg-red-500 hover:bg-red-700 border-none text-white capitalize"
                      @click="togglePrivacyPolicyModal"
                    >
                      Close Privacy Policy
                    </button>
                  </div>
                </div>
              </div>
            </Teleport>
          </div>
        </div>
        <div
          class="w-full text-center text-xs bg-slate-700 p-1 text-white border-t-2 border-t-slate-400 border-dotted"
        >
          COPYRIGHT 2019 - FinnyPi LLC | All Rights Reserved
        </div>
      </div>
    </div>

    <!-- footer button toolbar -->
    <div
      class="bg-base-300 flex-row max-sm:flex hidden justify-between border-t border-t-slate-400"
    >
      <router-link
        to="/sign-up"
        class="btn btn-sm md:btn-md rounded-none bg-emerald-500 text-white border-none capitalize"
      >
        Get Started
      </router-link>

      <div>
        <button
          @click="scrollToElement('pageTop')"
          class="fixed bottom-10 left-2 btn btn-xs rounded bg-base-300 text-white border border-slate-400"
        >
          <font-awesome-icon icon="fa-solid fa-chevron-up" />
        </button>

        <button
          class="fixed bottom-10 right-2 btn btn-md btn-square rounded-full bg-blue-500 text-white border border-white"
        >
          <font-awesome-icon icon="fa-solid fa-comments" />
        </button>

        <button
          @click="toggleEmailModal"
          class="btn btn-sm md:btn-md rounded-none bg-red-500 text-white border-none"
        >
          <font-awesome-icon icon="fa-solid fa-at" />
        </button>

        <Teleport to="#modals-target">
          <div
            v-if="showEmailModal"
            @click.self="toggleEmailModal"
            class="fixed left-0 top-0 z-70 w-screen h-screen backdrop-brightness-50 flex flex-col justify-center items-center"
          >
            <div
              class="bg-base-100 h-full md:h-1/2 w-full md:w-1/2 border border-slate-400 rounded flex flex-col justify-center items-center"
            >
              <div class="h-full w-full flex flex-col">
                <div class="sticky top-0 right-0 flex flex-row justify-end">
                  <button
                    class="btn btn-xs md:btn-sm rounded-none text-xs bg-base-300 hover:bg-blue-500 border-none"
                    @click="toggleEmailModal"
                  >
                    <font-awesome-icon
                      icon="fa-solid fa-times"
                      class="text-white"
                      size="md"
                    />
                  </button>
                </div>
                <div class="flex flex-col gap-5 items-center">
                  <cSystemLogo></cSystemLogo>

                  <h3 class="text-4xl mb-3 font-light text-white">Email Us</h3>
                </div>
                <div class="flex-1">
                  <div class="flex flex-col p-5 gap-3 h-full justify-center">
                    <div
                      class="text-center p-5 border border-slate-400 text-white"
                    >
                      <h4 class="text-xl">{{ companyDetails.email }}</h4>
                    </div>
                    <div class="flex flex-col gap-3">
                      <button
                        @click="
                          copyTextAndAlert('Email', `${companyDetails.email}`)
                        "
                        class="btn btn-sm md:btn-md capitalize text-white rounded bg-gray-500 border-none"
                      >
                        Copy Email
                      </button>

                      <a
                        :href="`mailto:${companyDetails.email}`"
                        class="btn btn-sm md:btn-md capitalize text-white rounded bg-emerald-500 border-none"
                      >
                        Compose Email
                      </a>
                    </div>
                  </div>
                </div>
                <div class="flex flex-row justify-center">
                  <button
                    class="capitalize btn btn-sm btn-block rounded-none text-xs bg-red-500 hover:bg-red-700 border-none text-white"
                    @click="toggleEmailModal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Teleport>

        <button
          @click="toggleCallModal"
          class="btn btn-sm md:btn-md rounded-none bg-indigo-500 text-white border-none"
        >
          <font-awesome-icon icon="fa-solid fa-phone-flip" />
        </button>

        <Teleport to="#modals-target">
          <div
            v-if="showCallModal"
            @click.self="toggleCallModal"
            class="fixed left-0 top-0 z-70 w-screen h-screen backdrop-brightness-50 flex flex-col justify-center items-center"
          >
            <div
              class="bg-base-100 h-full md:h-1/2 w-full md:w-1/2 border border-slate-400 rounded flex flex-col justify-center items-center"
            >
              <div class="h-full w-full flex flex-col">
                <div class="sticky top-0 right-0 flex flex-row justify-end">
                  <button
                    class="btn btn-xs md:btn-sm rounded-none text-xs bg-base-300 hover:bg-blue-500 border-none"
                    @click="toggleCallModal"
                  >
                    <font-awesome-icon
                      icon="fa-solid fa-times"
                      class="text-white"
                      size="md"
                    />
                  </button>
                </div>
                <div class="flex flex-col gap-5 items-center">
                  <cSystemLogo></cSystemLogo>

                  <h3 class="text-4xl mb-3 font-light text-white">
                    Phone Support
                  </h3>
                </div>
                <div class="flex-1">
                  <div class="flex flex-col p-5 gap-3 h-full justify-center">
                    <div
                      class="text-center p-5 border border-slate-400 text-white"
                    >
                      <h4 class="text-xl">{{ companyDetails.phone }}</h4>
                    </div>
                    <div class="flex flex-col gap-3">
                      <button
                        @click="
                          copyTextAndAlert('Phone', `${companyDetails.phone}`)
                        "
                        class="btn btn-sm md:btn-md capitalize text-white rounded bg-gray-500 border-none"
                      >
                        Copy Phone
                      </button>

                      <a
                        :href="`tel:${companyDetails.phone}`"
                        class="btn btn-sm md:btn-md capitalize text-white rounded bg-emerald-500 border-none"
                      >
                        Call Now
                      </a>

                      <a
                        :href="`sms:+1${companyDetails.phone.replaceAll(
                          '-',
                          ''
                        )}`"
                        class="btn btn-sm md:btn-md capitalize text-white rounded bg-blue-500 border-none"
                      >
                        Text Us
                      </a>
                    </div>
                  </div>
                </div>
                <div class="flex flex-row justify-center">
                  <button
                    class="capitalize btn btn-sm btn-block rounded-none text-xs bg-red-500 hover:bg-red-700 border-none text-white"
                    @click="toggleCallModal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Teleport>
      </div>
    </div>
  </div>
</template>
