import {
  //axios
  axios,

  //pinia
  defineStore,
  useGlobalToastsStore,
  useGlobalPromptsStore,

  //env base url
  BASE_API_URL,
} from "../clientImports";

export const useTenantsDepartmentsStore = defineStore({
  id: "tenants-departments",
  state: () => ({
    showTenantsDepartmentsCreateModal: false,
    showTenantsDepartmentsEditModal: false,

    idToEdit: "",

    tenantsDepartmentsList: [],

    currentlyCreatingDepartment: {
      name: "",
      descriiption: "",
    },

    currentlyEditingDepartment: {
      name: "",
      descriiption: "",
    },
  }),
  actions: {
    //two responsibilities - set the id to edit and then open the modal
    async initializeTenantsDepartmentsEditModal(departmentId) {
      //set's the id of the Department that we're editing
      this.setIdToEdit(departmentId);

      await this.fetchTenantDepartment();
      //opens the edit modal
      this.showTenantsDepartmentsEditModal = true;
    },

    toggleTenantsDepartmentsCreateModal() {
      this.showTenantsDepartmentsCreateModal =
        !this.showTenantsDepartmentsCreateModal;
    },

    toggleTenantsDepartmentsEditModal() {
      this.showTenantsDepartmentsEditModal =
        !this.showTenantsDepartmentsEditModal;
    },

    //fetches the Departments for listing
    async fetchTenantsDepartments() {
      try {
        const { data } = await axios.get(
          `${BASE_API_URL}/read-tenants-departments`
        );

        console.log(data, "FETCHED DEPARTMENTS");

        if (data.status === "success") {
          this.tenantsDepartmentsList = data.departmentsOptions.map(
            (department) => {
              return {
                id: department._id,
                name: department.name,
                description: department.description,
                system_default: department.system_default,
              };
            }
          );
        } else {
          console.error(data.message);
        }
      } catch (error) {
        console.error();
      }
    },

    //fetching for editing
    async fetchTenantDepartment() {
      const id = this.idToEdit;
      try {
        const { data } = await axios.get(
          `${BASE_API_URL}/read-tenants-department/${id}`
        );

        console.log(data, "FETCHED DEPARTMENT");

        if (data.status === "success") {
          console.log(data.department);
          this.currentlyEditingDepartment = data.department[0];

          console.log(this.currentlyEditingDepartment);
        } else {
          console.error(data.message);
        }
      } catch (error) {
        console.error();
      }
    },

    setIdToEdit(departmentId) {
      this.idToEdit = departmentId;
    },

    async createTenantsDepartment() {
      const departmentData = this.currentlyCreatingDepartment;
      try {
        const { data } = await axios.post(
          `${BASE_API_URL}/create-tenants-department`,
          departmentData
        );

        if (data.status === "success") {
          const toastsStore = useGlobalToastsStore();
          toastsStore.addToast({
            title: "Department Created",
            description: "",
            buttonExists: false,
            color: "emerald",
            type: 1,
          });

          //fetch the updated list of Departments
          this.fetchTenantsDepartments();
          this.toggleTenantsDepartmentsCreateModal();
        } else {
          console.error(data.message);
          toastsStore.addToast({
            title: "Error Creating Department",
            description: data.message,
            buttonExists: false,
            color: "red",
            type: 1,
          });
        }
      } catch (error) {
        console.error();
      }
    },

    async updateTenantsDepartment() {
      const id = this.idToEdit;
      try {
        const { data } = await axios.put(
          `${BASE_API_URL}/update-tenants-department/${id}`,
          this.currentlyEditingDepartment
        );

        if (data.status === "success") {
          console.log(data.message);

          this.toggleTenantsDepartmentsEditModal();
          //fetch the updated list of Departments
          this.fetchTenantsDepartments();

          //throw toast
          const toastsStore = useGlobalToastsStore();
          toastsStore.addToast({
            title: "Department Updated",
            description: "",
            buttonExists: false,
            color: "emerald",
            type: 1,
          });
        } else {
          console.error(data.message);
          toastsStore.addToast({
            title: "Error Updating Department",
            description: data.message,
            buttonExists: false,
            color: "red",
            type: 1,
          });
        }
      } catch (error) {
        console.error();
      }
    },

    async deleteTenantsDepartment(departmentId) {
      const promptStore = useGlobalPromptsStore();

      const group_id = departmentId;

      promptStore.addPrompt({
        type: "Department",
        title: "Delete Department?",
        message: `Are you sure you want to delete this Department?`,
      });

      const promptResponse = await promptStore.waitForPromptConfirmation();

      if (promptResponse === true) {
        promptStore.addPrompt({
          type: "Department",
          title: "Are You Sure?",
          message: `We'll hide this from your view so it can no longer be used. Any contacts or users that have this department assigned will remain assigned and have to be manually changed. Do you still want to proceed with the deletion?`,
        });

        const promptResponse2 = await promptStore.waitForPromptConfirmation();

        if (promptResponse2 === true) {
          try {
            const { data } = await axios.delete(
              `${BASE_API_URL}/delete-tenants-department/${group_id}`
            );

            if (data.status === "success") {
              //fetch the updated list of Departments
              this.fetchTenantsDepartments();
            } else {
              console.error(data.message);
              toastsStore.addToast({
                title: "Error Deleting Department",
                description: data.message,
                buttonExists: false,
                color: "red",
                type: 1,
              });
            }
          } catch (error) {
            console.error();
          }
          promptStore.removePrompt();
        } else {
          promptStore.removePrompt();
        }
      } else {
        promptStore.removePrompt();
      }
    },
  },
});
