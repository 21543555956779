<script setup>
defineOptions({
  name: 'SettingsStagesCreate', // Give the component a name
});
import {
  //axios
  axios,

  //vue
  createApp,
  ref,
  reactive,
  watch,
  computed,
  inject,
  provide,
  onMounted,
  onUnmounted,
  nextTick,
  Teleport,

  //pinia
  defineStore,
  createPinia,
  storeToRefs,

  //pinia stores
  useChatStore,
  useContactAlertsStore,
  useContactFileStore,
  useContactsStore,
  useGlobalPromptsStore,
  useGlobalToastsStore,
  useLoginStore,
  useTenantsStore,
  useUsersStore,
  useTenantsStagesStore,

  //vue-router
  useRouter,
  useRoute,

  //formkit

  //formkit config file
  FKsubmitForm,

  //fontawesome
  library,
  FontAwesomeIcon,
  fas,
  far,
  fab,

  //centralized data
  usersLevelOptions,
  fieldsDefaultValidation,
  stateOptions,

  //utilities
  getRandomNumberBetween,

  //helpers
  registerGlobalComponents,

  //utilities
  stripEmptyFields,

  //base api url
  BASE_API_URL,
} from "./../clientImports";

import {
  browserTimezone,
  timezoneOptions,
} from "./../centralized_data/timezoneOptions";

const router = useRouter();
const route = useRoute();

const loginStore = useLoginStore();

const usersStore = useUsersStore();
const toastsStore = useGlobalToastsStore();
const promptsStore = useGlobalPromptsStore();
const tenantsStagesStore = useTenantsStagesStore();

//use FKsubmitForm to submit form
const submitCreateForm = () => {
  return FKsubmitForm("create-stage");
};
</script>

<template>
  <cSidebarModal
    sidebar-position="right"
    :show-sidebar-initial="false"
    :show-sidebar-modal="tenantsStagesStore.showTenantsStagesCreateModal"
    :toggle-sidebar-modal="tenantsStagesStore.toggleTenantsStagesCreateModal"
    :default-button="false"
  >
    <template #title>
      <h2>Create Stage</h2>
    </template>

    <template #body>
      <FormKit
        id="create-stage"
        type="form"
        :incomplete-message="false"
        submit-label="Create"
        form-class="text-white h-full flex flex-col justify-between"
        @submit="tenantsStagesStore.createTenantsStage()"
        outer-class="m-0"
        :actions="false"
        :submit-attrs="{
          'data-theme': `light`,
          ignore: true,
        }"
      >
        <div class="grid grid-cols-1 gap-5">
          <FormKit
            type="text"
            label="Name"
            placeholder=""
            :validation="'required:trim|length:3, 40'"
            help-class="text-white"
            help=""
            outer-class="m-0 "
            input-class="$reset rounded w-full input input-sm text-gray-700 bg-gray-50"
            wrapper-class="max-w-full"
            v-model.trim="tenantsStagesStore.currentlyCreatingStage.name"
          />

          <FormKit
            type="select"
            label="Color"
            placeholder=""
            :validation="'required'"
            help-class="text-white"
            help=""
            outer-class="m-0 "
            :input-class="`$reset rounded w-full ${
              tenantsStagesStore.currentlyCreatingStage.color !== '' ||
              tenantsStagesStore.currentlyCreatingStage.color === null
                ? `bg-${tenantsStagesStore.currentlyCreatingStage.color}-500`
                : 'bg-gray-100 text-gray-700'
            } select select-sm`"
            wrapper-class="max-w-full"
            v-model="tenantsStagesStore.currentlyCreatingStage.color"
            :options="[
              //choose color disabled
              { value: '', label: 'Choose Color', attrs: { disabled: true } },
              { value: 'red', label: 'Red' },
              { value: 'orange', label: 'Orange' },
              { value: 'yellow', label: 'Yellow' },
              { value: 'green', label: 'Green' },
              { value: 'emerald', label: 'Emerald' },
              { value: 'blue', label: 'Blue' },
              { value: 'purple', label: 'Purple' },
              { value: 'teal', label: 'Teal' },
              { value: 'pink', label: 'Pink' },
              { value: 'slate', label: 'Slate' },
            ]"
          />

          <FormKit
            type="textarea"
            label="Description"
            placeholder=""
            :validation="'trim|length:1, 255'"
            help-class="text-white"
            help=""
            outer-class="m-0 "
            input-class="$reset rounded w-full input input-sm text-gray-700 bg-gray-50"
            wrapper-class="max-w-full"
            v-model.trim="tenantsStagesStore.currentlyCreatingStage.description"
          />
        </div>
      </FormKit>
    </template>

    <template #footer>
      <!-- BUTTON FOOTER - CANCEL & UPDATE Stage -->
      <div class="flex flex-row justify-between text-white w-full">
        <button
          @click="tenantsStagesStore.toggleTenantsStagesCreateModal"
          class="btn btn-xs md:btn-sm rounded bg-red-500 text-white hover:bg-red-700"
        >
          Cancel
        </button>
        <button
          @click="submitCreateForm"
          class="btn btn-xs md:btn-sm rounded bg-emerald-500 text-white hover:bg-emerald-700"
        >
          Create Stage
        </button>
      </div>
    </template>
  </cSidebarModal>
</template>
