<script setup>
defineOptions({
  name: 'SettingsPhoneNumbersEdit', // Give the component a name
});
import {
  //axios
  axios,

  //vue
  createApp,
  ref,
  reactive,
  watch,
  computed,
  inject,
  provide,
  onMounted,
  onUnmounted,
  nextTick,
  Teleport,

  //pinia
  defineStore,
  createPinia,
  storeToRefs,

  //pinia stores
  useChatStore,
  useContactAlertsStore,
  useContactFileStore,
  useContactsStore,
  useGlobalPromptsStore,
  useGlobalToastsStore,
  useLoginStore,
  useTenantsStore,
  useUsersStore,
  useTenantsPhoneNumbersStore,

  //vue-router
  useRouter,
  useRoute,

  //formkit

  //formkit config file
  FKsubmitForm,

  //fontawesome
  library,
  FontAwesomeIcon,
  fas,
  far,
  fab,

  //centralized data
  usersLevelOptions,
  fieldsDefaultValidation,
  stateOptions,

  //utilities
  getRandomNumberBetween,

  //helpers
  registerGlobalComponents,

  //utilities
  stripEmptyFields,

  //base api url
  BASE_API_URL,
} from "./../clientImports";

import {
  browserTimezone,
  timezoneOptions,
} from "./../centralized_data/timezoneOptions";

const router = useRouter();
const route = useRoute();

const loginStore = useLoginStore();

const usersStore = useUsersStore();
const toastsStore = useGlobalToastsStore();
const promptsStore = useGlobalPromptsStore();
const tenantsPhoneNumbersStore = useTenantsPhoneNumbersStore();

//use FKsubmitForm to submit form
const submitUpdateForm = () => {
  FKsubmitForm("edit-phone-numbers");
};
</script>

<template>
  <cSidebarModal
    sidebar-position="right"
    :show-sidebar-initial="false"
    :show-sidebar-modal="
      tenantsPhoneNumbersStore.showTenantsPhoneNumbersEditModal
    "
    :toggle-sidebar-modal="
      tenantsPhoneNumbersStore.toggleTenantsPhoneNumbersEditModal
    "
    :defaultButton="false"
  >
    <template #title>
      <h2>Edit Phone Number</h2>
    </template>

    <template #body>
      <FormKit
        id="edit-phone-numbers"
        type="form"
        :incomplete-message="false"
        submit-label="Update"
        form-class="text-white h-full flex flex-col justify-between"
        @submit="tenantsPhoneNumbersStore.updateTenantsPhoneNumber()"
        outer-class="m-0"
        :actions="false"
        :submit-attrs="{
          'data-theme': `light`,
          ignore: true,
        }"
      >
        <div class="grid grid-cols-3 gap-5">
          <FormKit
            type="text"
            label="Name"
            placeholder=""
            :validation="'required:trim|length:5, 40'"
            help-class="text-white"
            help=""
            outer-class="m-0 col-span-3"
            input-class="$reset rounded w-full input input-sm text-gray-700 bg-gray-50"
            wrapper-class="max-w-full"
            v-model.trim="
              tenantsPhoneNumbersStore.currentlyEditingPhoneNumber.name
            "
            :value="tenantsPhoneNumbersStore.currentlyEditingPhoneNumber.name"
          />

          <FormKit
            type="tel"
            label="Phone Number"
            placeholder=""
            :validation="[['required'], ['matches', /^\d{10}$/]]"
            help-class="text-white"
            help=""
            outer-class="m-0 col-span-3"
            input-class="$reset rounded w-full input input-sm text-gray-700 bg-gray-50"
            wrapper-class="max-w-full"
            v-model.trim="
              tenantsPhoneNumbersStore.currentlyEditingPhoneNumber.phone_number
            "
            :value="
              tenantsPhoneNumbersStore.currentlyEditingPhoneNumber.phone_number
            "
          />

          <FormKit
            type="select"
            label="Texting"
            :options="[
              { label: 'Disabled', value: false },
              { label: 'Active', value: true },
            ]"
            help-class="text-white"
            help=""
            outer-class="m-0 col-span-1"
            input-class="$reset rounded w-full input input-sm text-gray-700 bg-gray-50"
            wrapper-class="max-w-full"
            v-model="
              tenantsPhoneNumbersStore.currentlyEditingPhoneNumber.texting
            "
            :value="
              tenantsPhoneNumbersStore.currentlyEditingPhoneNumber.texting
            "
          />

          <FormKit
            type="select"
            label="Calling"
            :options="[
              { label: 'Disabled', value: false },
              { label: 'Active', value: true },
            ]"
            help-class="text-white"
            help=""
            outer-class="m-0 col-span-1"
            input-class="$reset rounded w-full input input-sm text-gray-700 bg-gray-50"
            wrapper-class="max-w-full"
            v-model="
              tenantsPhoneNumbersStore.currentlyEditingPhoneNumber.calling
            "
            :value="
              tenantsPhoneNumbersStore.currentlyEditingPhoneNumber.calling
            "
          />

          <FormKit
            type="select"
            label="Faxing"
            :options="[
              { label: 'Disabled', value: false },
              { label: 'Active', value: true },
            ]"
            help-class="text-white"
            help=""
            outer-class="m-0 col-span-1"
            input-class="$reset rounded w-full input input-sm text-gray-700 bg-gray-50"
            wrapper-class="max-w-full"
            v-model="
              tenantsPhoneNumbersStore.currentlyEditingPhoneNumber.faxing
            "
            :value="tenantsPhoneNumbersStore.currentlyEditingPhoneNumber.faxing"
          />

          <FormKit
            type="textarea"
            label="Description"
            placeholder=""
            :validation="'trim|length:1, 255'"
            help-class="text-white"
            help=""
            outer-class="m-0 col-span-3"
            input-class="$reset rounded w-full input input-sm text-gray-700 bg-gray-50"
            wrapper-class="max-w-full"
            v-model.trim="
              tenantsPhoneNumbersStore.currentlyEditingPhoneNumber.description
            "
            :value="
              tenantsPhoneNumbersStore.currentlyEditingPhoneNumber.description
            "
          />

          <FormKit
            type="select"
            label="Source"
            :options="[
              { label: 'Twilio', value: 'twilio' },
              { label: 'Text Vending', value: 'text-vending' },
              { label: 'Caller Ready', value: 'caller-ready' },
              { label: 'Other', value: 'other' },
            ]"
            help-class="text-white"
            outer-class="m-0 col-span-3"
            input-class="$reset rounded w-full input input-sm text-gray-700 bg-gray-50"
            wrapper-class="max-w-full"
            v-model="
              tenantsPhoneNumbersStore.currentlyEditingPhoneNumber.source
            "
            :value="tenantsPhoneNumbersStore.currentlyEditingPhoneNumber.source"
          />

          <FormKit
            type="checkbox"
            label="Set as Default Phone"
            outer-class="m-0 col-span-3"
            wrapper-class="mt-auto"
            v-model="
              tenantsPhoneNumbersStore.currentlyEditingPhoneNumber.default
            "
            :value="
              tenantsPhoneNumbersStore.currentlyEditingPhoneNumber.default
            "
          />
        </div>
      </FormKit>
    </template>

    <template #footer>
      <!-- BUTTON FOOTER - CANCEL & UPDATE AFFILIATE GROUP -->
      <div class="flex flex-row justify-between text-white w-full">
        <button
          @click="tenantsPhoneNumbersStore.toggleTenantsPhoneNumbersEditModal"
          class="btn btn-xs md:btn-sm rounded bg-red-500 text-white hover:bg-red-700"
        >
          Cancel
        </button>
        <button
          @click="submitUpdateForm"
          class="btn btn-xs md:btn-sm rounded bg-emerald-500 text-white hover:bg-emerald-700"
        >
          Update
        </button>
      </div>
    </template>
  </cSidebarModal>
</template>
