import { xLog } from "../../clientImports";

import { globalIsoToLocal } from "../globalTimezoneConverter";

import { formatName, formatPhoneNumber } from "../cellFormatters";

// Main formatting function
export const tenantsCampaignsTableFormatter = (header, value) => {
  // Function to detect HTML in a string
  const isHtml = (str) => {
    return str && str.startsWith("<") && str.endsWith(">");
  };

  // Check for undefined or null values
  if (header === undefined || value === undefined) {
    return { value, isHtml: false };
  }

  const lowerHeader = header.toLowerCase();
  let formattedValue = value;

  // For keyword "phone" in header
  if (lowerHeader === "user_phone_number") {
    formattedValue = formatPhoneNumber(value);
  }
  // For keywords "date", "created_at", "updated_at" in header
  else if (lowerHeader === "created_at" || lowerHeader === "updated_at") {
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      // second: "2-digit",
    };

    let formattedDate = globalIsoToLocal(value, options);

    // Replace slashes with hyphens and remove the comma
    formattedValue = formattedDate.replace(/\//g, "-").replace(",", "");
  }
  // For boolean type values
  else if (typeof value === "boolean") {
    formattedValue = value ? "FontAwesomeOn" : "FontAwesomeOff";
    return { value: formattedValue, isVueComponent: true, isHtml: false };
  }
  // For keyword "name" in header
  else if (
    lowerHeader === "first_name" ||
    lowerHeader === "last_name" ||
    lowerHeader === "phone_caller_id" ||
    lowerHeader === "updated_by"
  ) {
    formattedValue = formatName(value);
  }
  // Default case
  return {
    value: formattedValue,
    isVueComponent: false,
    isHtml: isHtml(formattedValue),
  };
};

//usage example:

// const x = [
//   {
//     _id: "64eea7c6cbbcce3aa0850d0d",
//     first_name: "Nick",
//     last_name: "Lancaster",
//     email: "nick@finnypi.app",
//     department: "compliance",
//     active: false,
//     passport_required: false,
//     level_access: "master",
//   },
//   {
//     _id: "64f6e6bf92a1a044112a38b7",
//     first_name: "Katie",
//     last_name: "Lancaster",
//     email: "katie@finnypi.app",
//     department: "support",
//     active: true,
//     passport_required: true,
//     level_access: "master",
//   },
// ];

// const y = transformUsersList(x);

// xLog("usersTableFormatter: ", JSON.stringify(y));
