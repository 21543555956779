export const businessTypeOptions = [
  { label: "- Select Business Type -", value: "", attrs: { disabled: true } },
  { value: "sole_proprietorship", label: "Sole Proprietorship" },
  { value: "partnership", label: "Partnership" },
  { value: "llc", label: "Limited Liability Company (LLC)" },
  { value: "corporation", label: "Corporation" },
  { value: "s_corporation", label: "S Corporation" },
  { value: "c_corporation", label: "C Corporation" },
  { value: "nonprofit", label: "Nonprofit Organization" },
  { value: "cooperative", label: "Cooperative (Co-op)" },
  { value: "limited_partnership", label: "Limited Partnership (LP)" },
  { value: "professional_corporation", label: "Professional Corporation (PC)" },
  { value: "b_corporation", label: "B Corporation (Benefit Corporation)" },
  { value: "franchise", label: "Franchise" },
  { value: "joint_venture", label: "Joint Venture" },
];
