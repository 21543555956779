<script setup>
defineOptions({
  name: 'ContactAttachmentsAdd', // Give the component a name
});
import {
  ref,
  onMounted,
  useTenantsAttachmentTypesStore,
  Teleport,
} from "../clientImports";
const tenantsAttachmentTypesStore = useTenantsAttachmentTypesStore();

const showAddContactAttachmentModal = ref(false);
const toggleAddContactAttachmentModal = () => {
  showAddContactAttachmentModal.value = !showAddContactAttachmentModal.value;
};
</script>

<template>
  <!-- button to toggle -->
  <button
    @click="toggleAddContactAttachmentModal"
    class="btn btn-xs md:btn-sm rounded-none bg-teal-500 text-white border-none"
  >
    <font-awesome-icon icon="fa-solid fa-arrow-up-from-bracket" />
  </button>
  <Teleport to="#modals-target">
    <transition
      enter-active-class="transition-opacity duration-300 ease-in-out"
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="transition-opacity duration-300 ease-in-out"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div
        @click.self="toggleAddContactAttachmentModal"
        v-if="showAddContactAttachmentModal"
        class="fixed top-0 left-0 w-screen h-screen z-50 flex items-center justify-center backdrop-brightness-50 cursor-pointer"
      >
        <transition
          enter-active-class="transition-all duration-300 ease-in-out"
          enter-from-class="-translate-x-full"
          enter-to-class="translate-x-0"
          leave-active-class="transition-all duration-300 ease-in-out"
          leave-from-class="translate-x-0"
          leave-to-class="-translate-x-full"
        >
          <div
            v-if="showAddContactAttachmentModal"
            class="flex flex-col justify-center items-center"
          >
            <cFileUpload
              v-if="
                tenantsAttachmentTypesStore.fetchTenantsAttachmentsDropdownList
              "
              uploadDestination="contact_attachments"
              uploadLocation="contact_file"
            >
              <template #close-button>
                <button
                  @click="toggleAddContactAttachmentModal"
                  class="btn btn-sm rounded capitalize text-white border-none"
                >
                  <!-- font awesome close icon -->
                  <font-awesome-icon icon="fa-solid fa-times" />
                </button>
              </template>
            </cFileUpload>
          </div>
        </transition>
      </div>
    </transition>
  </Teleport>
</template>
