<script setup>
defineOptions({
  name: 'ContactPaymentPlanStatus', // Give the component a name
});
import {
  //axios
  axios,

  //vue
  createApp,
  ref,
  reactive,
  watch,
  computed,
  inject,
  provide,
  onMounted,
  onUnmounted,
  nextTick,
  Teleport,

  //pinia
  defineStore,
  createPinia,
  storeToRefs,

  //pinia stores
  useChatStore,
  useContactAlertsStore,
  useContactFileStore,
  useContactsStore,
  useGlobalPromptsStore,
  useGlobalToastsStore,
  useLoginStore,
  useTenantsStore,

  //vue-router
  useRouter,
  useRoute,

  //formkit

  //formkit config file

  //fontawesome
  library,
  FontAwesomeIcon,
  fas,
  far,
  fab,

  //global components - all vue components
  registerGlobalComponents,

  //base api url
  BASE_API_URL,
} from "./../clientImports";

const toastsStore = useGlobalToastsStore();

const paymentPlanStatusOptions = ref([
  { id: 0, paymentPlanStatus: "Not Active" },
  { id: 1, paymentPlanStatus: "Active" },
  { id: 2, paymentPlanStatus: "Paused" },
  { id: 3, paymentPlanStatus: "Cancelled" },
  { id: 4, paymentPlanStatus: "NSF" },
  { id: 5, paymentPlanStatus: "Terminated" },
  { id: 6, paymentPlanStatus: "Completed" },
]);

const currentPaymentPlanStatus = ref({
  id: 0,
  paymentPlanStatus: "Not Active",
});

watch(currentPaymentPlanStatus, (n, o) => {
  toastsStore.addToast({
    title: "Payment Plan Status Updated",
    description: `Payment Plan Status set to: (${n.paymentPlanStatus})`,
    buttonExists: false,
    color: "sky",
    type: 2, // 1 = small, 2 = md, 3 = lg, 4 = lg dismissable
  });

  togglePaymentPlanStatusSelect();
});

const paymentPlanStatusColor = computed(() => {
  if (currentPaymentPlanStatus.value.id === 0) return "bg-red-500";
  if (currentPaymentPlanStatus.value.id === 1)
    return "bg-emerald-500 border-y-4 border-dotted border-base-100";
  if (currentPaymentPlanStatus.value.id === 2) return "bg-yellow-500";
  if (currentPaymentPlanStatus.value.id === 3) return "bg-red-500";
  if (currentPaymentPlanStatus.value.id === 4) return "bg-orange-500";
  if (currentPaymentPlanStatus.value.id === 5) return "bg-red-500";
  if (currentPaymentPlanStatus.value.id === 6) return "bg-emerald-500";
});

const paidTotal = ref(0);

const showPaymentPlanStatusSelect = ref(false);
const togglePaymentPlanStatusSelect = () => {
  showPaymentPlanStatusSelect.value = !showPaymentPlanStatusSelect.value;
};
</script>

<template>
  <div class="flex flex-row">
    <button
      @click="togglePaymentPlanStatusSelect"
      class="btn btn-xs rounded-none no-animation text-white capitalize"
    >
      Payments
    </button>
    <select
      v-model="currentPaymentPlanStatus"
      class="select select-xs text-white rounded-none"
      :class="[
        paymentPlanStatusColor,
        { 'max-sm:hidden': !showPaymentPlanStatusSelect },
      ]"
    >
      <option disabled selected value="0">Not Set</option>
      <option
        v-for="(paymentStatus, paymentStatusIndex) in paymentPlanStatusOptions"
        :value="paymentStatus"
      >
        {{ paymentStatus.paymentPlanStatus }}
      </option>
    </select>
    <button
      :class="{ 'max-sm:hidden': !showPaymentPlanStatusSelect }"
      class="btn btn-xs text-emerald-500 no-animation text-sm rounded-l-none rounded-r"
    >
      <font-awesome-icon icon="fa-solid fa-dollar-sign" />
      {{ paidTotal }}
    </button>
  </div>
</template>
