import {
  //vue app
  App,

  //vue
  createApp,

  //pinia
  createPinia,

  //vue router - routes
  router,

  //global components - all vue components
  registerGlobalComponents,

  //fontawesome
  library,
  FontAwesomeIcon,
  fas,
  far,
  fab,
} from "./clientImports";

//import vue router


import { plugin, defaultConfig } from "@formkit/vue";

import formKitConfig from "./formkit.config";

import VueChartkick from "vue-chartkick";
import "chartkick/chart.js";

// or
import { plugin as VueTippy } from "vue-tippy";
import "tippy.js/dist/tippy.css"; // optional for styling

//tailwind global styles
import "./assets/style.css";

import VueSignaturePad from "vue-signature-pad";

//set up fontawesome
library.add(fas, far, fab);

//create and configure the Vue app
const VueApp = createApp(App);

registerGlobalComponents(VueApp);

//set app to use fontawesome
VueApp.component("font-awesome-icon", FontAwesomeIcon);

//set app to use formkit config file
VueApp.use(plugin, defaultConfig(formKitConfig));

VueApp.use(
  VueTippy,
  // optional
  {
    directive: "tippy", // => v-tippy
    component: "tippy", // => <tippy/>
    componentSingleton: "tippy-singleton", // => <tippy-singleton/>,
    defaultProps: {
      placement: "auto-end",
      allowHTML: true,
    }, // => Global default options * see all props
  }
);

VueApp.use(VueSignaturePad);

VueApp.use(VueChartkick);

//set app to use pinia
VueApp.use(createPinia());

//set app to use vue router
VueApp.use(router);

//mount the Vue app
VueApp.mount("#app");

//register service worker - PWA
if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register("/sw.js")
    .then((reg) => console.log("PWA - Service Worker Registered", reg))
    .catch((err) =>
      console.log("PWA - Service Worker Registration Failed", err)
    );
}
