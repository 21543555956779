<script setup>
defineOptions({
  name: 'NetworkedCompanies', // Give the component a name
});
import {
  ref,
  onMounted,
  computed,
  watch,
  useNetworkedCompaniesStore,
  useGlobalPromptsStore,
  useTenantsStore,
  useContactsStore,
  useGlobalToastsStore,
  useLoginStore,
  useRoute,
  useRouter,
  useNetworkedUsersStore,
  copyText,
} from "../clientImports";

const router = useRouter();
const route = useRoute();

const loginStore = useLoginStore();
const tenantsStore = useTenantsStore();
const contactsStore = useContactsStore();
const networkedCompaniesStore = useNetworkedCompaniesStore();
const promptsStore = useGlobalPromptsStore();
const toastsStore = useGlobalToastsStore();
const networkedUsersStore = useNetworkedUsersStore();

const traverseToCompany = async (companyObj) => {
  console.log("companyObj ABOUT TO TRAVERSE", companyObj);
  promptsStore.addPrompt({
    title: "Switch Companies?",
    message: `Are you sure you want to switch to: ${companyObj.legalName}?`,
  });

  const promptResponse = await promptsStore.waitForPromptConfirmation();

  if (promptResponse === true) {
    promptsStore.removePrompt();

    networkedCompaniesStore.toggleNetworkedCompanies();

    const response = await networkedCompaniesStore.traverseToCompany(
      companyObj._id
    );

    loginStore.setSessionData(response.data);

    //add params to route and pass to tenant-switch
    router.push({
      name: "tenant-switch",
      params: {
        tenantLegalName: companyObj.legalName,
      },
    });

    if (response.data.status === "success") {
      //wipe contacts state list - precautionary measure to ensure once in new tenant that contacts aren't showing from old state
      contactsStore.contacts = null;
      networkedUsersStore.networkedOutUsersList = null;
      networkedUsersStore.networkedInUsersList = null;
      networkedUsersStore.currentNetworkView = "networked-out-view";
      // toastsStore.addToast({
      //   title: "Switching Tenant",
      //   description: "",
      //   buttonExists: false,
      //   color: "green",
      //   type: 2, // 1 = small, 2 = md, 3 = lg, 4 = lg dismissable
      // });
      // window.location.reload();
    } else {
      toastsStore.addToast({
        title: "Error Switching Tenant",
        description: "There was an error switching tenants. Please try again.",
        buttonExists: false,
        color: "red",
        type: 1, // 1 = small, 2 = md, 3 = lg, 4 = lg dismissable
      });
    }
  } else {
    promptsStore.removePrompt();
  }
};

const allNetworkedCompanies = computed(() => {
  return networkedCompaniesStore.networkedCompanies;
});

onMounted(async () => {
  const result = await networkedCompaniesStore.getNetworkedCompanies();
});
</script>
<template>
  <div
    class="w-full md:w-72 flex flex-col overflow-y-auto hide-scroll border-r border-r-slate-400 backdrop backdrop-brightness-50"
  >
    <div
      class="p-1 md:p-2 text-xs md:text-base border-b border-b-slate-400 text-center bg-base-300 text-white sticky top-0 flex flex-row items-center justify-between"
    >
      <font-awesome-icon icon="fa-solid fa-network-wired" /><span
        >Networked Companies
      </span>
      <button
        @click="networkedCompaniesStore.toggleNetworkedCompanies"
        class="btn btn-xs md:btn-sm text-white border border-slate-400"
      >
        <font-awesome-icon icon="fa-solid fa-chevron-left" />
      </button>
    </div>

    <div class="p-1 bg-blue-500 text-white flex flex-row items-center">
      <h1 class="text-xs flex-1">
        Current Company ID: {{ loginStore.sessionData.tenant._id }}
      </h1>
      <button
        @click="copyText(loginStore.sessionData.tenant._id)"
        class="btn btn-xs md:btn-sm bg-gray-500 hover:bg-gray-700 text-white border-none"
      >
        <font-awesome-icon icon="fa-regular fa-copy" />
      </button>
    </div>

    <cLoadingSpinner
      v-if="networkedCompaniesStore.networkedCompanies.length === 0"
    ></cLoadingSpinner>

    <div
      v-for="(networkCompany, i) in networkedCompaniesStore.networkedCompanies"
      :key="i"
      class="flex flex-row odd:bg-slate-800 bg-slate-700"
      v-show="networkCompany._id !== loginStore.sessionData.tenant._id"
      v-else-if="networkedCompaniesStore.networkedCompanies.length > 0"
    >
      <div class="flex flex-col flex-1 pl-3 py-2 gap-1 text-sm">
        <div class="flex flex-row text-white">
          {{ networkCompany.company_legal_name }}
        </div>

        <div
          v-if="networkCompany.network_authorized === true"
          class="flex flex-col flex-1 pl-3 py-2 gap-1 text-sm"
        >
          <div class="flex flex-row text-white text-xs">
            {{ networkCompany.company_display_name }}
          </div>
          <div class="flex flex-row text-white text-xs">
            {{ networkCompany.domain_prefix }}.app.finnypi.com
          </div>
          <div class="flex flex-row text-white text-xs">
            {{ networkCompany._id }}
          </div>
        </div>

        <div
          v-if="networkCompany.network_authorized === false"
          class="text-orange-500 text-center"
        >
          Pending Authorization
        </div>
      </div>
      <div class="self-stretch">
        <button
          v-if="networkCompany.network_authorized === true"
          @click="
            traverseToCompany({
              _id: networkCompany._id,
              legalName: networkCompany.company_legal_name,
            })
          "
          :class="
            networkCompany.originating_tenant === true
              ? 'bg-blue-500 hover:bg-blue-700'
              : 'bg-emerald-500 hover:bg-emerald-700'
          "
          class="h-full px-5 text-white text-xs border-none"
        >
          <!-- FONT AWESOME HOME ICON -->
          <font-awesome-icon
            v-if="networkCompany.originating_tenant === true"
            icon="fa-solid fa-home"
          />

          <font-awesome-icon
            v-else
            icon="fa-solid fa-arrow-right-from-bracket"
          />
        </button>
      </div>
    </div>

    <div
      v-if="networkedCompaniesStore.networkedCompanies.length < 2"
      class="p-3 bg-blue-400 text-white text-center"
    >
      No Networked Companies Yet
    </div>
  </div>
</template>
