<script setup>
defineOptions({
  name: 'SettingsAccountProfile', // Give the component a name
});
import {
  //axios
  axios,

  //vue
  createApp,
  ref,
  reactive,
  watch,
  computed,
  inject,
  provide,
  onMounted,
  onUnmounted,
  nextTick,
  Teleport,

  //pinia
  defineStore,
  createPinia,
  storeToRefs,

  //pinia stores
  useChatStore,
  useContactAlertsStore,
  useContactFileStore,
  useContactsStore,
  useGlobalPromptsStore,
  useGlobalToastsStore,
  useLoginStore,
  useTenantsStore,
  useTenantsAccountProfileStore,
  //vue-router
  useRouter,
  useRoute,

  //formkit
  FKsubmitForm,

  //formkit config file

  //fontawesome
  library,
  FontAwesomeIcon,
  fas,
  far,
  fab,

  //global components - all vue components
  registerGlobalComponents,

  //base api url
  BASE_API_URL,
} from "./../clientImports";

// Call the useContactsStore function to get an instance of the store
const contactsStore = useContactsStore();
const tenantsAccountProfile = useTenantsAccountProfileStore();

onMounted(async () => {
  // Call the fetchContacts method from the store
  await tenantsAccountProfile.fetchProfile();
});

const showFileUpload = ref(false);

//use FKsubmitForm to submit form
const submitCreateForm = () => {
  return FKsubmitForm("update-account-profile");
};
</script>

<template>
  <div class="h-full flex flex-col bg-base-100">
    <div class="w-full p-2 bg-base-300 text-white border-b border-slate-400">
      <font-awesome-icon icon="fa-regular fa-building" size="lg" /><span
        class="ml-3"
        >Account Profile</span
      >
    </div>
    <div class="w-full overflow-y-auto flex flex-row justify-between flex-1">
      <div class="flex-1 h-full overflow-y-auto text-white flex flex-col">
        <!-- TODO: will finish later - need to get working with uploads -->
        <!-- <div class="p-5">
          <button
            type="button"
            @click="showFileUpload = !showFileUpload"
            class="btn bg-base-100 text-white border border-slate-400 capitalize p-2 cursor-pointer hover:bg-gray-100 hover:text-gray-600"
          >
            Upload Company Logo
            
            <font-awesome-icon icon="fa-solid fa-upload" class="ml-2" />
          </button>
          <cFileUpload v-if="showFileUpload"></cFileUpload>
        </div> -->
        <FormKit
          id="update-account-profile"
          type="form"
          :incomplete-message="false"
          submit-label="Update"
          form-class="text-white p-5"
          @submit="tenantsAccountProfile.updateProfile()"
          :actions="false"
          :submit-attrs="{
            inputClass: 'text-white btn btn-block',
            wrapperClass: '',
            'data-theme': `light`,

            ignore: true,
          }"
        >
          <div class="grid grid-cols-12 gap-5">
            <FormKit
              type="text"
              label="Company Abbreviation"
              :validation="'required:trim|lower|length:2, 40'"
              value=""
              placeholder=""
              help-class="text-white"
              help=""
              input-class="$reset input input-sm md:input-md bg-gray-100 rounded w-full text-gray-600"
              wrapper-class="max-w-full"
              outer-class="col-span-12 md:col-span-6 xl:col-span-4"
              v-model="tenantsAccountProfile.company_data.company_abbreviation"
              :value="tenantsAccountProfile?.company_data?.company_abbreviation"
            />

            <FormKit
              type="text"
              label="Company Display Name"
              :validation="'required:trim|length:2, 40'"
              value=""
              placeholder=""
              help-class="text-white"
              help=""
              input-class="$reset input input-sm md:input-md bg-gray-100 rounded w-full text-gray-600"
              wrapper-class="max-w-full"
              outer-class="col-span-12 md:col-span-6 xl:col-span-4"
              v-model="tenantsAccountProfile.company_data.company_display_name"
              :value="tenantsAccountProfile?.company_data?.company_display_name"
            />
            <FormKit
              type="text"
              label="Company Legal Name"
              :validation="'required:trim|length:2, 40'"
              value=""
              placeholder=""
              help-class="text-white"
              help=""
              input-class="$reset input input-sm md:input-md bg-gray-100 rounded w-full text-gray-600"
              wrapper-class="max-w-full"
              outer-class="col-span-12 md:col-span-6 xl:col-span-4"
              v-model="tenantsAccountProfile.company_data.company_legal_name"
              :value="tenantsAccountProfile?.company_data?.company_legal_name"
            />
            <FormKit
              type="text"
              label="Website"
              :validation="[
                [
                  'matches',
                  /^([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(?::\d+)?(\/\S*)*$/,
                ],
              ]"
              :validation-messages="{
                matches: 'Please enter a valid URL - Example.com',
              }"
              value=""
              placeholder=""
              help-class="text-white"
              help=""
              input-class="$reset input input-sm md:input-md bg-gray-100 rounded w-full text-gray-600"
              wrapper-class="max-w-full"
              outer-class="col-span-12 md:col-span-6 xl:col-span-4"
              v-model="tenantsAccountProfile.company_data.website"
              :value="tenantsAccountProfile?.company_data?.website"
            />

            <FormKit
              type="text"
              label="Office Phone"
              value=""
              :validation="[['matches', /^\d{10}$/]]"
              :validation-messages="{
                matches: 'Must be 10 digits',
              }"
              placeholder=""
              help-class="text-white"
              help=""
              input-class="$reset input input-sm md:input-md bg-gray-100 rounded w-full text-gray-600"
              wrapper-class="max-w-full"
              outer-class="col-span-12 md:col-span-6 xl:col-span-4"
              v-model="tenantsAccountProfile.company_data.office_phone"
              :value="tenantsAccountProfile?.company_data?.office_phone"
            />

            <FormKit
              type="text"
              label="Customer Service Phone"
              :validation="[['matches', /^\d{10}$/]]"
              :validation-messages="{
                matches: 'Must be 10 digits',
              }"
              value=""
              placeholder=""
              help-class="text-white"
              help=""
              input-class="$reset input input-sm md:input-md bg-gray-100 rounded w-full text-gray-600"
              wrapper-class="max-w-full"
              outer-class="col-span-12 md:col-span-6 xl:col-span-4"
              v-model="
                tenantsAccountProfile.company_data.customer_service_phone
              "
              :value="
                tenantsAccountProfile?.company_data?.customer_service_phone
              "
            />

            <FormKit
              type="text"
              label="Office Street Address"
              :validation="'trim|length:2, 255'"
              value=""
              placeholder=""
              help-class="text-white"
              help=""
              input-class="$reset input input-sm md:input-md bg-gray-100 rounded w-full text-gray-600"
              wrapper-class="max-w-full"
              outer-class="col-span-12 md:col-span-8 xl:col-span-8"
              v-model="tenantsAccountProfile.company_data.office_street_address"
              :value="
                tenantsAccountProfile?.company_data?.office_street_address
              "
            />

            <FormKit
              type="text"
              label="Office Unit / Suite"
              :validation="'trim|length:2, 55'"
              value=""
              placeholder=""
              help-class="text-white"
              help=""
              input-class="$reset input input-sm md:input-md bg-gray-100 rounded w-full text-gray-600"
              wrapper-class="max-w-full"
              outer-class="col-span-12 md:col-span-4 xl:col-span-4"
              v-model="tenantsAccountProfile.company_data.office_suite_unit"
              :value="tenantsAccountProfile?.company_data?.office_suite_unit"
            />

            <FormKit
              type="text"
              label="Office City"
              value=""
              placeholder=""
              help-class="text-white"
              help=""
              input-class="$reset input input-sm md:input-md bg-gray-100 rounded w-full text-gray-600"
              wrapper-class="max-w-full"
              outer-class="col-span-12 md:col-span-6 xl:col-span-6"
              v-model="tenantsAccountProfile.company_data.office_city"
              :value="tenantsAccountProfile?.company_data?.office_city"
            />

            <FormKit
              type="text"
              label="Office State"
              :validation="'trim|length:2,2'"
              value=""
              placeholder=""
              help-class="text-white"
              help=""
              input-class="$reset input input-sm md:input-md bg-gray-100 rounded w-full text-gray-600"
              wrapper-class="max-w-full"
              outer-class="col-span-12 md:col-span-6 xl:col-span-3"
              v-model="tenantsAccountProfile.company_data.office_state"
              :value="tenantsAccountProfile?.company_data?.office_state"
            />
            <FormKit
              type="text"
              label="Office Zip"
              :validation="[['matches', /^\d{5}(-\d{4})?$/]]"
              :validation-messages="{
                matches: 'Please enter a valid Zip - 12345 or 12345-1234',
              }"
              value=""
              placeholder=""
              help-class="text-white"
              help=""
              input-class="$reset input input-sm md:input-md bg-gray-100 rounded w-full text-gray-600"
              wrapper-class="max-w-full"
              outer-class="col-span-12 md:col-span-6 xl:col-span-3"
              v-model="tenantsAccountProfile.company_data.office_zip"
              :value="tenantsAccountProfile?.company_data?.office_zip"
            />

            <!-- country -->
            <FormKit
              type="text"
              label="Office Country"
              :validation="'trim|length:2, 55'"
              value=""
              placeholder=""
              help-class="text-white"
              help=""
              input-class="$reset input input-sm md:input-md bg-gray-100 rounded w-full text-gray-600"
              wrapper-class="max-w-full"
              outer-class="col-span-12 md:col-span-6 xl:col-span-6"
              v-model="tenantsAccountProfile.company_data.office_country"
              :value="tenantsAccountProfile?.company_data?.office_country"
            />
          </div>
        </FormKit>
      </div>
    </div>
    <div
      class="w-full p-3 bg-base-300 text-white flex flex-row justify-between gap-5 border-t border-t-slate-400"
    >
      <div class="flex flex-row justify-end flex-1">
        <button
          @click="submitCreateForm('update-account-profile')"
          class="btn bg-emerald-500 text-white border-none capitalize"
        >
          Save Acount Profile
        </button>
      </div>
    </div>
  </div>
</template>
