<script setup>
defineOptions({
  name: 'ContactCommunicationTab', // Give the component a name
});
import {
  //axios
  axios,

  //vue
  createApp,
  ref,
  reactive,
  watch,
  computed,
  inject,
  provide,
  onMounted,
  onUnmounted,
  nextTick,
  Teleport,

  //pinia
  defineStore,
  createPinia,
  storeToRefs,

  //pinia stores
  useChatStore,
  useContactAlertsStore,
  useContactFileStore,
  useContactsStore,
  useGlobalPromptsStore,
  useGlobalToastsStore,
  useLoginStore,
  useTenantsStore,

  //vue-router
  useRouter,
  useRoute,

  //formkit

  //formkit config file

  //fontawesome
  library,
  FontAwesomeIcon,
  fas,
  far,
  fab,

  //global components - all vue components
  registerGlobalComponents,

  //base api url
  BASE_API_URL,
} from "./../clientImports";

const router = useRouter();
const route = useRoute();

const activeCommunicationTab = ref("");
</script>

<template>
  <div class="flex flex-col h-full">
    <!-- <div class="bg-white w-full flex flex-row justify-between p-2">
      <button class="btn capitalize bg-blue-500 border-none text-white btn-sm">
        Show Merge Fields
      </button>
    </div> -->
    <div class="w-full h-full bg-gray-100 flex flex-row justify-between">
      <div class="flex flex-col h-full">
        <!-- <div class="p-3 bg-slate-600 text-white">Methods</div> -->
        <button
          :class="[
            activeCommunicationTab === 'ContactCommunicationSMS'
              ? 'bg-blue-400 text-white'
              : 'bg-white text-gray-700',
          ]"
          @click="activeCommunicationTab = 'ContactCommunicationSMS'"
          class="bold bg-white capitalize p-1 text-xs md:text-sm md:p-3 rounded-none hover:bg-blue-600 hover:cursor-pointer hover:text-white border-b border-x-0 border-t-0 border-slate-300"
        >
          SMS
          {{ activeCommunicationTab === "ContactCommunicationSMS" ? ">" : "" }}
        </button>
        <button
          :class="[
            activeCommunicationTab === 'ContactCommunicationEmail'
              ? 'bg-blue-400 text-white'
              : 'bg-white text-gray-700',
          ]"
          @click="activeCommunicationTab = 'ContactCommunicationEmail'"
          class="bold bg-white capitalize p-1 text-xs md:text-sm md:p-3 rounded-none hover:bg-blue-600 hover:cursor-pointer hover:text-white border-b border-x-0 border-t-0 border-slate-300"
        >
          Email
          {{
            activeCommunicationTab === "ContactCommunicationEmail" ? ">" : ""
          }}
        </button>
      </div>
      <!-- DATA FORMS -->
      <div
        class="overflow-y-auto h-full flex flex-col flex-1 border-x border-slate-400 bg-white"
      >
        <div
          class="no-animation bg-white rounded-none text-gray-700 border-b-0 border-x-0 border-t-0 border-slate-300 h-full"
        >
          <!-- empty div to hold the form no-style -->
          <router-view></router-view>
          <component :is="activeCommunicationTab"></component>
        </div>
      </div>
      <!-- GROUP LINKS -->
      <!-- <div class="flex flex-col w-52">
        <div class="p-3 bg-slate-400 text-white">Groups</div>
      </div> -->
    </div>
    <!-- <div class="p-2 bg-white w-full flex flex-row justify-between items-center">
      <button class="btn capitalize bg-blue-500 border-none text-white">
        Send Form
      </button>
      <div class="flex flex-row gap-2">
        <button
          class="btn capitalize bg-emerald-500 border-none text-white rouned-none"
        >
          Save
        </button>
        <div class="dropdown dropdown-top dropdown-end">
          <label tabindex="0" class="btn bg-emerald-700 text-white border-none">
            <font-awesome-icon icon="fa-solid fa-chevron-down" />
          </label>
          <ul
            tabindex="0"
            class="dropdown-content menu shadow bg-base-100 w-52 rouned-lg"
          >
            <li class="bg-emerald-700 rouned-lg">
              <a href="" class="text-white">Save Static Copy</a>
            </li>
          </ul>
        </div>
      </div>
    </div> -->
  </div>
</template>
