import {
  //axios
  axios,

  //pinia
  defineStore,
  useGlobalToastsStore,
  useGlobalPromptsStore,

  //env base url
  BASE_API_URL,
} from "../clientImports";

export const useTenantsTemplatesNotificationsStore = defineStore({
  id: "tenants-templates-notifications",
  state: () => ({
    showTenantsTemplatesNotificationsCreateModal: false,
    showTenantsTemplatesNotificationsEditModal: false,

    idToEdit: "",

    tenantsTemplatesNotificationsList: [],

    currentlyCreatingTemplateNotification: {
      name: "",
      title: "",
      description: "",
      type: "",
      color: "",
      department_id: "",
    },

    currentlyEditingTemplateNotification: {
      name: "",
      title: "",
      description: "",
      type: "",
      color: "",
      department_id: "",
    },
  }),
  actions: {
    //two responsibilities - set the id to edit and then open the modal
    async initializeTenantsTemplatesNotificationsEditModal(
      templatesNotificationId
    ) {
      //set's the id of the TemplateNotification that we're editing
      this.setIdToEdit(templatesNotificationId);

      await this.fetchTenantTemplateNotification();
      //opens the edit modal
      this.showTenantsTemplatesNotificationsEditModal = true;
    },

    toggleTenantsTemplatesNotificationsCreateModal() {
      this.showTenantsTemplatesNotificationsCreateModal =
        !this.showTenantsTemplatesNotificationsCreateModal;
    },

    toggleTenantsTemplatesNotificationsEditModal() {
      this.showTenantsTemplatesNotificationsEditModal =
        !this.showTenantsTemplatesNotificationsEditModal;
    },

    //fetches the TemplatesNotifications for listing
    async fetchTenantsTemplatesNotifications() {
      console.log("FETCHING TENANTS TEMPLATES NOTIFICATIONS");
      try {
        const { data } = await axios.get(
          `${BASE_API_URL}/read-tenants-templates-notifications`
        );

        if (data.status === "success") {
          this.tenantsTemplatesNotificationsList =
            data.templatesNotificationsOptions.map((templatesNotification) => {
              return {
                id: templatesNotification._id,
                name: templatesNotification.title,
                title: templatesNotification.name,
                description: templatesNotification.description,
                type: templatesNotification.type,
                color: templatesNotification.color,
                department_id: templatesNotification.department_id,
                department: templatesNotification.department,
              };
            });
        } else {
          console.error(data.message);
        }
      } catch (error) {
        console.error();
      }
    },

    //fetching for editing
    async fetchTenantTemplateNotification() {
      const id = this.idToEdit;
      try {
        const { data } = await axios.get(
          `${BASE_API_URL}/read-tenants-templates-notification/${id}`
        );

        console.log(data, "FETCHED Notifications");

        if (data.status === "success") {
          console.log(data.templatesNotification);
          this.currentlyEditingTemplateNotification =
            data.templatesNotification[0];

          console.log(this.currentlyEditingTemplateNotification);
        } else {
          console.error(data.message);
        }
      } catch (error) {
        console.error();
      }
    },

    setIdToEdit(templatesNotificationId) {
      this.idToEdit = templatesNotificationId;
    },

    async createTenantsTemplateNotification() {
      const templatesNotificationData =
        this.currentlyCreatingTemplateNotification;

      console.log("PASSPORT DATA SENDING OUT: ", templatesNotificationData);
      try {
        const { data } = await axios.post(
          `${BASE_API_URL}/create-tenants-templates-notification`,
          templatesNotificationData
        );

        console.log("CREATE PASSPORT RESPONSE", data);

        if (data.status === "success") {
          const toastsStore = useGlobalToastsStore();
          toastsStore.addToast({
            title: "TemplateNotification Created",
            description: "",
            buttonExists: false,
            color: "emerald",
            type: 1,
          });

          //fetch the updated list of TemplatesNotifications
          await this.fetchTenantsTemplatesNotifications();
          this.toggleTenantsTemplatesNotificationsCreateModal();
        } else {
          console.error(data.message);
          toastsStore.addToast({
            title: "Error Creating TemplateNotification",
            description: data.message,
            buttonExists: false,
            color: "red",
            type: 1,
          });
        }
      } catch (error) {
        console.error();
      }
    },

    async updateTenantsTemplateNotification() {
      const id = this.idToEdit;
      try {
        const { data } = await axios.put(
          `${BASE_API_URL}/update-tenants-templates-notification/${id}`,
          this.currentlyEditingTemplateNotification
        );

        if (data.status === "success") {
          console.log(data.message);

          this.toggleTenantsTemplatesNotificationsEditModal();
          //fetch the updated list of TemplatesNotifications
          await this.fetchTenantsTemplatesNotifications();

          //throw toast
          const toastsStore = useGlobalToastsStore();
          toastsStore.addToast({
            title: "TemplateNotification Updated",
            description: "",
            buttonExists: false,
            color: "emerald",
            type: 1,
          });
        } else {
          console.error(data.message);
          toastsStore.addToast({
            title: "Error Updating TemplateNotification",
            description: data.message,
            buttonExists: false,
            color: "red",
            type: 1,
          });
        }
      } catch (error) {
        console.error();
      }
    },

    async deleteTenantsTemplateNotification(templatesNotificationId) {
      const promptStore = useGlobalPromptsStore();

      const group_id = templatesNotificationId;

      promptStore.addPrompt({
        type: "TemplateNotification",
        title: "Delete TemplateNotification?",
        message: `Are you sure you want to delete this TemplateNotification?`,
      });

      const promptResponse = await promptStore.waitForPromptConfirmation();

      if (promptResponse === true) {
        promptStore.addPrompt({
          type: "TemplateNotification",
          title: "Are You Sure?",
          message: `We'll hide this from your view so it can no longer be used. Any contacts or users that have this templatesNotification assigned will remain assigned and have to be manually changed. Do you still want to proceed with the deletion?`,
        });

        const promptResponse2 = await promptStore.waitForPromptConfirmation();

        if (promptResponse2 === true) {
          try {
            const { data } = await axios.delete(
              `${BASE_API_URL}/delete-tenants-templates-notification/${group_id}`
            );

            if (data.status === "success") {
              //fetch the updated list of TemplatesNotifications
              this.fetchTenantsTemplatesNotifications();
            } else {
              console.error(data.message);
              toastsStore.addToast({
                title: "Error Deleting TemplateNotification",
                description: data.message,
                buttonExists: false,
                color: "red",
                type: 1,
              });
            }
          } catch (error) {
            console.error();
          }
          promptStore.removePrompt();
        } else {
          promptStore.removePrompt();
        }
      } else {
        promptStore.removePrompt();
      }
    },

    testToast(toastObj) {
      const toastsStore = useGlobalToastsStore();
      toastsStore.addToast({
        title: toastObj.title,
        description: toastObj.description,
        buttonExists: false,
        color: toastObj.color,
        type: Number(toastObj.type),
      });
    },
  },
});
