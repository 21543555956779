<!-- CustomContextMenu.vue -->

<script setup>
defineOptions({
  name: 'RightClickMenu', // Give the component a name
});
import { ref, watch, onUnmounted, Teleport } from "../clientImports";

// State for visibility and position
const isVisible = ref(false);
const position = ref({ x: 0, y: 0 });

// Menu options
const options = ref([
  { label: "Back", action: () => window.history.back() },
  { label: "Reload", action: () => window.location.reload() },
  { label: "Forward", action: () => window.history.forward() },

  {
    label: "More Options",
    subOptions: [
      { label: "Sub-option 1", action: () => alert("Sub-option 1 clicked") },
      { label: "Sub-option 2", action: () => alert("Sub-option 2 clicked") },
    ],
  },
  // Your custom options
  { label: "Option 1", action: () => alert("Option 1 clicked") },
  { label: "Option 2", action: () => alert("Option 2 clicked") },
]);

const colSpanFormatter = (menuItemLabel) => {
  if (menuItemLabel === "Back") {
    return "col-span-2 border-r border-b border-slate-400 text-center";
  } else if (menuItemLabel === "Reload") {
    return "col-span-2 border-b border-slate-400 text-center";
  } else if (menuItemLabel === "Forward") {
    return "col-span-2 border-l border-b border-slate-400 text-center";
  } else {
    return "col-span-6";
  }
};

// Handle right click event
const handleRightClick = (event) => {
  if (event.ctrlKey) {
    event.preventDefault();
    position.value = { x: event.clientX, y: event.clientY };
    isVisible.value = true;
  }
};

// Close context menu
const closeContextMenu = () => {
  isVisible.value = false;
};

// Event listeners
window.addEventListener("contextmenu", handleRightClick);
window.addEventListener("click", closeContextMenu);

// Cleanup
onUnmounted(() => {
  window.removeEventListener("contextmenu", handleRightClick);
  window.removeEventListener("click", closeContextMenu);
});

// To track sub-menu visibility
const showSubmenu = ref([]);
</script>

<template>
  <Teleport to="#right-click-menu-target">
    <!-- right click menu -->
    <div
      v-if="isVisible"
      class="z-100 fixed top-0 h-full w-full left-0 backdrop-blur-sm"
    >
      <div
        class="absolute border border-slate-400 rounded shadow-md bg-base-300 text-white grid grid-cols-6"
        :style="{ top: `${position.y}px`, left: `${position.x}px` }"
        v-show="isVisible"
      >
        <div
          v-for="(option, index) in options"
          :key="index"
          @click="option.action"
          :class="colSpanFormatter(option.label)"
          class="py-1 px-2 text-left capitalize cursor-pointer hover:bg-slate-500 whitespace-nowrap relative"
          @mouseenter="showSubmenu[index] = true"
          @mouseleave="showSubmenu[index] = false"
        >
          <font-awesome-icon
            v-if="option.label === 'Back'"
            icon="fa-solid fa-arrow-left-long"
          />

          <font-awesome-icon
            v-else-if="option.label === 'Reload'"
            icon="fa-solid fa-rotate-right"
          />

          <font-awesome-icon
            v-else-if="option.label === 'Forward'"
            icon="fa-solid fa-arrow-right-long"
          />

          <span v-else>
            {{ option.label }}
            <span v-if="option.subOptions">
              {{ option.label }}
              <div
                class="absolute left-full top-0 bg-base-300 border border-slate-400 rounded-r shadow-md"
                v-show="showSubmenu[index]"
              >
                <div
                  v-for="(subOption, subIndex) in option.subOptions"
                  :key="`sub-${subIndex}`"
                  class="py-1 px-2 hover:bg-slate-500"
                  @click="subOption.action"
                >
                  {{ subOption.label }}
                </div>
              </div>
            </span>
          </span>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<style scoped></style>
