import { axios, defineStore, xLog, BASE_API_URL } from "./../clientImports";

export const useTenantsStore = defineStore({
  id: "tenantsStore",
  state: () => ({
    tenant: null,
    allTenants: null,
    showCreateTenantsModal: false,
  }),
  actions: {
    toggleCreateTenantModal() {
      this.showCreateTenantsModal = !this.showCreateTenantsModal;
    },
    // the tenants id is in the server session and is automatically applied on the server side
    async getTenantData(projectedData) {
      const response = await axios.get(
        `${BASE_API_URL}/tenants`,
        projectedData
      );
      // xLog("response", response);
      this.tenant = response.data;
    },

    async getAllTenants() {
      try {
        const response = await axios.get(`${BASE_API_URL}/tenants`);

        return response;
      } catch (error) {
        console.error(error.message);
      }
    },
  },
});
