// Import libraries
import App from "./App.vue";

//axios
import axios from "axios";
axios.defaults.withCredentials = true;

//axios interceptors - set headers here for all axios requests
// axios.interceptors.request.use(
//   (config) => {
//     // Append the current webpage URL as a custom header
//     config.headers["X-Page-Protocol"] = window.location.protocol;
//     config.headers["X-Page-Hostname"] = window.location.hostname;
//     config.headers["X-Page-Pathname"] = window.location.pathname;
//     return config;
//   },
//   (error) => {
//     // Handle request error here
//     return Promise.reject(error);
//   }
// );

//vue
import {
  createApp,
  ref,
  reactive,
  toRefs,
  watch,
  watchEffect,
  computed,
  inject,
  provide,
  onBeforeMount,
  onBeforeUnmount,
  onMounted,
  onUnmounted,
  onUpdated,
  nextTick,
  Teleport,
} from "vue";

//vuedraggable
import draggable from "vuedraggable";

//pinia
import { defineStore, createPinia, storeToRefs } from "pinia";

//pinia stores
import { useChatStore } from "./stores/chatStore";
import { useContactAlertsStore } from "./stores/contactAlertsStore";
import { useContactFileStore } from "./stores/contactFileStore";
import { useContactsStore } from "./stores/contactsStore";
import { useGlobalPromptsStore } from "./stores/globalPromptsStore";
import { useGlobalToastsStore } from "./stores/globalToastsStore";
import { useLoginStore } from "./stores/loginStore";
import { useMasterStore } from "./stores/masterStore";
import { useRecentContactsStore } from "./stores/recentContactsStore";
import { useRolesStore } from "./stores/rolesStore";
import { useSearchContactStore } from "./stores/searchContactStore";
import { useTenantsStore } from "./stores/tenantsStore";
import { useUsersStore } from "./stores/usersStore";
import { useNetworkedCompaniesStore } from "./stores/networkedCompaniesStore";
import { useContactTypesStore } from "./stores/contactTypesStore";
import { useTenantsCampaignsStore } from "./stores/tenantsCampaignsStore";
import { useTenantsTagsStore } from "./stores/tenantsTagsStore";
import { useContactAssignmentsStore } from "./stores/contactAssignmentsStore";
import { useGlobalUploadStore } from "./stores/globalUploadStore";
import { useSignUpStore } from "./stores/signUpStore";
import { useTenantsAttachmentTypesStore } from "./stores/tenantsAttachmentTypesStore";
import { useNetworkedUsersStore } from "./stores/networkedUsersStore";
import { useTenantsAffiliateGroupsStore } from "./stores/tenantsAffiliateGroupsStore";
import { useTenantsDepartmentsStore } from "./stores/tenantsDepartmentsStore";
import { useTenantsMilestonesStore } from "./stores/tenantsMilestonesStore";
import { useTenantsPassportsStore } from "./stores/tenantsPassportsStore";
import { useTenantsPhoneNumbersStore } from "./stores/tenantsPhoneNumbersStore";
import { useTenantsTemplatesEmailsStore } from "./stores/tenantsTemplatesEmailsStore";
import { useTenantsTemplatesNotesStore } from "./stores/tenantsTemplatesNotesStore";
import { useTenantsContactTypesStore } from "./stores/tenantsContactTypesStore";
import { useTenantsStagesStore } from "./stores/tenantsStagesStore";
import { useTenantsWorkflowStatusesStore } from "./stores/tenantsWorkflowStatusesStore";
import { useTenantsBotsStore } from "./stores/tenantsBotsStore";
import { useTenantsTemplatesSmsStore } from "./stores/tenantsTemplatesSmsStore";
import { useTenantsAnnouncementsStore } from "./stores/tenantsAnnouncementsStore";
import { useTenantsAccountTypesStore } from "./stores/tenantsAccountTypesStore";
import { useTenantsScheduleTypesStore } from "./stores/tenantsScheduleTypesStore";
import { useTenantsGatewaysStore } from "./stores/tenantsGatewaysStore";
import { useTenantsAccountProfileStore } from "./stores/tenantsAccountProfileStore";
import { useWebsiteStore } from "./stores/websiteStore";
import { useSupportTicketsStore } from "./stores/supportTicketsStore";
import { useGptStore } from "./stores/gptStore";
import { useTenantsSessionsStore } from "./stores/tenantsSessionsStore";
import { useTenantsFormsStore } from "./stores/tenantsFormsStore";
import { useContactFormsStore } from "./stores/contactFormsStore";
import { useTenantsTemplatesNotificationsStore } from "./stores/tenantsTemplatesNotificationsStore";
import { useTenantsPaymentsPresetsStore } from "./stores/tenantsPaymentsPresetsStore";
import { useTenantsRebuttalsStore } from "./stores/tenantsRebuttalsStore";
import { useTenantsUsageStore } from "./stores/tenantsUsageStore";
import { useTenantsPaymentsRoutesStore } from "./stores/tenantsPaymentsRoutesStore";

//vue-router
import {
  createRouter,
  createWebHistory,
  useRouter,
  useRoute,
} from "vue-router";

//formkit
import { submitForm as FKsubmitForm } from "@formkit/vue";

//tinymce wysiwyg editor
import Editor from "@tinymce/tinymce-vue";

//vue router - routes
import router from "./routes.js";

// Fontawesome related imports
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";

//centralized data
import { businessTypeOptions } from "./centralized_data/businessTypeOptions";
import { countriesOptions } from "./centralized_data/countriesOptions";
import { stateOptions } from "./centralized_data/usaStateOptions";
import {
  expirationMonths,
  expirationYears,
} from "./centralized_data/creditCardExpirationOptions";
import { usersLevelOptions } from "./centralized_data/usersLevelOptions";
import { departmentOptions } from "./centralized_data/departmentOptions";
import { fieldsDefaultValidation } from "./centralized_data/fieldsDefaultValidation";

//utilities - contact
import { identifyQueryTypes } from "./utilities/contacts/searchContactQueryIdentifier";

//utilities - general
import { cleanUpperCase } from "./utilities/cleanUpperCase";
import { copyText } from "./utilities/copyText";
import { getRandomNumberBetween } from "./utilities/randomizedNumber";
import { sortAndFilterProperties } from "./utilities/sortAndFilterProperties";
import { stripEmptyFields } from "./utilities/stripEmptyFields";
import { globalTableFormatter } from "./utilities/globalTableFormatter";
import { xLog } from "./utilities/clientLogger";
import { tableHeaderFormatting } from "./utilities/tableHeaderFormatting";
import { sanitizeFileName } from "./utilities/sanitizeFileName";

//helpers
import { registerGlobalComponents } from "./helpers/globalComponents"; //global components - all vue components

//base api url
import { BASE_API_URL } from "./clientConfig";

// Re-export
export {
  App,

  //axios
  axios,

  //vue
  createApp,
  ref,
  reactive,
  toRefs,
  watch,
  watchEffect,
  computed,
  inject,
  provide,
  onBeforeMount,
  onBeforeUnmount,
  onMounted,
  onUnmounted,
  onUpdated,
  nextTick,
  Teleport,

  //vuedraggable
  draggable,

  //pinia
  defineStore,
  createPinia,
  storeToRefs,

  //pinia stores
  useChatStore,
  useContactAlertsStore,
  useContactFileStore,
  useContactsStore,
  useGlobalPromptsStore,
  useGlobalToastsStore,
  useLoginStore,
  useMasterStore,
  useRecentContactsStore,
  useRolesStore,
  useSearchContactStore,
  useTenantsStore,
  useUsersStore,
  useNetworkedCompaniesStore,
  useContactTypesStore,
  useTenantsCampaignsStore,
  useTenantsTagsStore,
  useContactAssignmentsStore,
  useGlobalUploadStore,
  useSignUpStore,
  useTenantsAttachmentTypesStore,
  useNetworkedUsersStore,
  useTenantsAffiliateGroupsStore,
  useTenantsDepartmentsStore,
  useTenantsMilestonesStore,
  useTenantsPassportsStore,
  useTenantsPhoneNumbersStore,
  useTenantsTemplatesEmailsStore,
  useTenantsTemplatesNotesStore,
  useTenantsContactTypesStore,
  useTenantsStagesStore,
  useTenantsWorkflowStatusesStore,
  useTenantsBotsStore,
  useTenantsTemplatesSmsStore,
  useTenantsAnnouncementsStore,
  useTenantsAccountTypesStore,
  useTenantsScheduleTypesStore,
  useTenantsGatewaysStore,
  useTenantsAccountProfileStore,
  useWebsiteStore,
  useSupportTicketsStore,
  useGptStore,
  useTenantsSessionsStore,
  useTenantsFormsStore,
  useContactFormsStore,
  useTenantsTemplatesNotificationsStore,
  useTenantsPaymentsPresetsStore,
  useTenantsRebuttalsStore,
  useTenantsUsageStore,
  useTenantsPaymentsRoutesStore,

  //vue-router
  createRouter,
  createWebHistory,
  useRouter,
  useRoute,

  //formkit
  FKsubmitForm,

  //tinymce wysiwyg editor
  Editor,

  //vue router - routes
  router,

  //fontawesome
  library,
  FontAwesomeIcon,
  fas,
  far,
  fab,

  //centralized data
  businessTypeOptions,
  countriesOptions,
  stateOptions,
  expirationMonths,
  expirationYears,
  usersLevelOptions,
  departmentOptions,
  fieldsDefaultValidation,

  //utilities - contacts
  identifyQueryTypes,

  //utilities - general
  cleanUpperCase,
  copyText,
  getRandomNumberBetween,
  sortAndFilterProperties,
  stripEmptyFields,
  globalTableFormatter,
  xLog,
  tableHeaderFormatting,
  sanitizeFileName,

  //helpers
  registerGlobalComponents,

  //base api url
  BASE_API_URL,
};
