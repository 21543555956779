import {
  //axios
  axios,

  //pinia
  defineStore,
  useGlobalToastsStore,
  useGlobalPromptsStore,

  //env base url
  BASE_API_URL,
} from "../clientImports";

export const useTenantsAffiliateGroupsStore = defineStore({
  id: "tenants-affiliate-groups",
  state: () => ({
    showTenantsAffiliateGroupsCreateModal: false,
    showTenantsAffiliateGroupsEditModal: false,
    idToEdit: "",

    tenantsAffiliateGroupsList: [],

    currentlyCreatingAffiliateGroup: {
      name: "",
      abbreviation: "",
      entity_name: "",
      entity_phone: "",
      entity_email: "",
      entity_website: "",
      entity_street_address: "",
      entity_suite_unit: "",
      entity_city: "",
      entity_state: "",
      entity_zip: "",
    },

    currentlyEditingAffiliateGroup: {
      name: "",
      abbreviation: "",
      entity_name: "",
      entity_phone: "",
      entity_email: "",
      entity_website: "",
      entity_street_address: "",
      entity_suite_unit: "",
      entity_city: "",
      entity_state: "",
      entity_zip: "",
    },
  }),
  actions: {
    //two responsibilities - set the id to edit and then open the modal
    async initializeTenantsAffiliateGroupsEditModal(affiliiateGroupId) {
      //set's the id of the affiliate group that we're editing
      this.setIdToEdit(affiliiateGroupId);

      //opens the edit modal
      this.showTenantsAffiliateGroupsEditModal = true;
      await this.fetchTenantAffiliateGroup();
    },

    toggleTenantsAffiliateGroupsCreateModal() {
      this.showTenantsAffiliateGroupsCreateModal =
        !this.showTenantsAffiliateGroupsCreateModal;
    },

    toggleTenantsAffiliateGroupsEditModal() {
      this.showTenantsAffiliateGroupsEditModal =
        !this.showTenantsAffiliateGroupsEditModal;
    },

    //fetches the affiliate groups for listing
    async fetchTenantsAffiliateGroups() {
      try {
        const { data } = await axios.get(
          `${BASE_API_URL}/read-affiliate-groups`
        );

        if (data.status === "success") {
          this.tenantsAffiliateGroupsList = data.affiliateGroups.map(
            (affiliateGroup) => {
              return {
                id: affiliateGroup._id,
                name: affiliateGroup.name,
                abbreviation: affiliateGroup.abbreviation,
                entity_name: affiliateGroup.entity_name,
                entity_phone: affiliateGroup.entity_phone,
                entity_email: affiliateGroup.entity_email,
                entity_website: affiliateGroup.entity_website,
                entity_street_address: affiliateGroup.entity_street_address,
                entity_suite_unit: affiliateGroup.entity_suite_unit,
                entity_city: affiliateGroup.entity_city,
                entity_state: affiliateGroup.entity_state,
                entity_zip: affiliateGroup.entity_zip,
              };
            }
          );
        } else {
          console.error(data.message);
        }
      } catch (error) {
        console.error();
      }
    },

    //fetching for editing
    async fetchTenantAffiliateGroup() {
      const id = this.idToEdit;
      try {
        const { data } = await axios.get(
          `${BASE_API_URL}/read-affiliate-group/${id}`
        );

        if (data.status === "success") {
          console.log(data.affiliateGroup);
          this.currentlyEditingAffiliateGroup = data.affiliateGroup;

          console.log(this.currentlyEditingAffiliateGroup);
        } else {
          console.error(data.message);
        }
      } catch (error) {
        console.error();
      }
    },

    setIdToEdit(affiliateGroupId) {
      this.idToEdit = affiliateGroupId;
    },

    async createTenantsAffiliateGroup() {
      const affiliateGroupData = this.currentlyCreatingAffiliateGroup;
      try {
        const { data } = await axios.post(
          `${BASE_API_URL}/create-affiliate-group`,
          affiliateGroupData
        );

        if (data.status === "success") {
          const toastsStore = useGlobalToastsStore();
          toastsStore.addToast({
            title: "Affiliate Group Created",
            description: "",
            buttonExists: false,
            color: "emerald",
            type: 1,
          });

          //fetch the updated list of affiliate groups
          this.fetchTenantsAffiliateGroups();
        } else {
          console.error(data.message);
          toastsStore.addToast({
            title: "Error Creating Affiliate Group",
            description: data.message,
            buttonExists: false,
            color: "red",
            type: 1,
          });
        }
      } catch (error) {
        console.error();
      }
    },

    async updateTenantsAffiliateGroup(affiliateGroupData) {
      const group_id = this.idToEdit;
      try {
        const { data } = await axios.put(
          `${BASE_API_URL}/update-affiliate-group/${group_id}`,
          this.currentlyEditingAffiliateGroup
        );

        if (data.status === "success") {
          console.log(data.message);

          this.toggleTenantsAffiliateGroupsEditModal();
          //fetch the updated list of affiliate groups
          this.fetchTenantsAffiliateGroups();

          //throw toast
          const toastsStore = useGlobalToastsStore();
          toastsStore.addToast({
            title: "Affiliate Group Updated",
            description: "",
            buttonExists: false,
            color: "emerald",
            type: 1,
          });
        } else {
          console.error(data.message);
          toastsStore.addToast({
            title: "Error Updating Affiliate Group",
            description: data.message,
            buttonExists: false,
            color: "red",
            type: 1,
          });
        }
      } catch (error) {
        console.error();
      }
    },

    async deleteTenantsAffiliateGroup(affiliateGroupId) {
      const promptStore = useGlobalPromptsStore();

      const group_id = affiliateGroupId;

      promptStore.addPrompt({
        type: "Affiliate Group",
        title: "Delete Affiliate Group?",
        message: `Are you sure you want to delete this Affiliate Group?`,
      });

      const promptResponse = await promptStore.waitForPromptConfirmation();

      if (promptResponse === true) {
        promptStore.addPrompt({
          type: "Affiliate Group",
          title: "Are You Sure?",
          message: `We recommend you remove this affiliate group from all users in your list before deleting it. Are you sure you want to delete this affiliate group still?`,
        });

        const promptResponse2 = await promptStore.waitForPromptConfirmation();

        if (promptResponse2 === true) {
          try {
            const { data } = await axios.delete(
              `${BASE_API_URL}/delete-affiliate-group/${group_id}`
            );

            if (data.status === "success") {
              //fetch the updated list of affiliate groups
              this.fetchTenantsAffiliateGroups();
            } else {
              console.error(data.message);
              toastsStore.addToast({
                title: "Error Deleting Affiliate Group",
                description: data.message,
                buttonExists: false,
                color: "red",
                type: 1,
              });
            }
          } catch (error) {
            console.error();
          }
          promptStore.removePrompt();
        } else {
          promptStore.removePrompt();
        }
      } else {
        promptStore.removePrompt();
      }
    },
  },
});
