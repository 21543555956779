<script setup>
defineOptions({
  name: 'AnnouncementsList', // Give the component a name
});
import {
  //axios
  axios,

  //vue
  createApp,
  ref,
  reactive,
  watch,
  computed,
  inject,
  provide,
  onMounted,
  onUnmounted,
  nextTick,
  Teleport,

  //pinia
  defineStore,
  createPinia,
  storeToRefs,

  //pinia stores
  useChatStore,
  useContactAlertsStore,
  useContactFileStore,
  useContactsStore,
  useGlobalPromptsStore,
  useGlobalToastsStore,
  useLoginStore,
  useTenantsStore,
  useUsersStore,
  useTenantsPassportsStore,
  useTenantsAnnouncementsStore,

  //vue-router
  useRouter,
  useRoute,

  //formkit

  //formkit config file
  FKsubmitForm,

  //fontawesome
  library,
  FontAwesomeIcon,
  fas,
  far,
  fab,

  //centralized data
  usersLevelOptions,
  fieldsDefaultValidation,
  stateOptions,

  //utilities
  getRandomNumberBetween,

  //helpers
  registerGlobalComponents,

  //utilities
  stripEmptyFields,

  //base api url
  BASE_API_URL,
} from "./../clientImports";

import {
  browserTimezone,
  timezoneOptions,
} from "./../centralized_data/timezoneOptions";

const router = useRouter();
const route = useRoute();

const loginStore = useLoginStore();

const usersStore = useUsersStore();
const toastsStore = useGlobalToastsStore();
const promptsStore = useGlobalPromptsStore();
const tenantsPassportsStore = useTenantsPassportsStore();
const tenantsAnnouncementsStore = useTenantsAnnouncementsStore();

//onMounted function that fetches the tenantsAnnouncementsStore.tenantsAnnouncementsList
onMounted(async () => {
  await tenantsAnnouncementsStore.fetchTenantsAnnouncements(); //initial populate affiliate list
});

onUnmounted(() => {
  isMounted = false;
});

//use FKsubmitForm to submit form
const submitCreateForm = () => {
  return FKsubmitForm("create-passport");
};

//computed function that returns the tenantsAnnouncementsStore.tenantsAnnouncementsList with the id, title, announcement, color, forced
const getAnnouncements = computed(() => {
  const x = tenantsAnnouncementsStore.tenantsAnnouncementsList
    .filter((item) => item.forced === true || item.active === true)
    .map((announcement) => {
      return {
        id: announcement.id,
        title: announcement.title,
        announcement: announcement.announcement,
        color: announcement.color,
        forced: announcement.forced,
        active: announcement.active,
      };
    });

  console.log("THE ANNOUNCMENTS", x);

  return x;
});

//computed function which returns an array of objects where the active property is true and if the length is greater than 0 then returns true
</script>

<template>
  <transition
    enter-active-class="transition ease-out duration-300"
    enter-from-class="-translate-y-full opacity-0"
    enter-to-class="translate-y-0 opacity-100"
    leave-active-class="transition ease-in duration-300"
    leave-from-class="translate-y-0 opacity-100"
    leave-to-class="-translate-y-full opacity-0"
  >
    <div
      v-if="
        tenantsAnnouncementsStore.showAnnouncements &&
        getAnnouncements.length > 0
      "
      class="flex flex-col items-center md:justify-center fixed top-0 left-0 w-screen h-screen z-50 p-2 backdrop-brightness-50 backdrop-blur-md max-h-screen overflow-auto"
    >
      <div
        class="bg-base-100 p-5 border border-slate-400 rounded-lg flex flex-col items-center justify-center md:w-1/2"
      >
        <div class="flex flex-row items-center gap-2 mb-5">
          <span class="text-white">
            <font-awesome-icon icon="fa-solid fa-bullhorn" size="lg" />
          </span>
          <span class="text-white text-xl">Company Announcements </span>
          <span class="bg-red-500 text-white py-1 px-3 rounded-full">{{
            getAnnouncements.length
          }}</span>
        </div>
        <div class="flex flex-col gap-5 self-stretch">
          <div
            v-for="(announcement, i) in getAnnouncements"
            :key="i"
            :class="`bg-${announcement.color}-500 flex flex-row items-center gap-2 p-1 rounded`"
          >
            <span
              :class="`bg-${announcement.color}-700 text-blue-50  h-full w-10 rounded text-center flex flex-col items-center justify-center`"
            >
              <font-awesome-icon icon="fa-solid fa-triangle-exclamation" />
            </span>

            <!-- TITLE / ANNOUNCEMENT DIV -->
            <div class="flex flex-col flex-1">
              <span :class="`text-white text-lg`">{{
                announcement.title
              }}</span>
              <span :class="`text-white flex-1 text-xs md:text-base`">{{
                announcement.announcement
              }}</span>
            </div>
          </div>
        </div>
        <div>
          <button
            @click="tenantsAnnouncementsStore.showAnnouncements = false"
            class="bg-rose-500 hover:bg-rose-700 text-white border-none rounded p-2 mt-5"
          >
            Acknowledge
          </button>
        </div>
      </div>
    </div>
  </transition>
</template>
