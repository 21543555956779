<script setup>
defineOptions({
  name: 'SettingsRolesList', // Give the component a name
});
import {
  //axios
  axios,

  //vue
  ref,
  reactive,
  watch,
  onBeforeMount,

  //pinia stores
  useLoginStore,
  useGlobalPromptsStore,
  useGlobalToastsStore,
  useUsersStore,
  useRolesStore,

  //vue-router
  useRouter,
  useRoute,

  //formkit

  //formkit config file

  //fontawesome
  library,
  FontAwesomeIcon,
  fas,
  far,
  fab,

  //global components - all vue components
  registerGlobalComponents,

  //base api url
  BASE_API_URL,
} from "./../clientImports";

const loginStore = useLoginStore();
const promptsStore = useGlobalPromptsStore();
const toastsStore = useGlobalToastsStore();
const usersStore = useUsersStore();
const rolesStore = useRolesStore();

const route = useRoute();
const router = useRouter();

const rolesList = ref([]);

watch(
  () => rolesStore.fetchedRoles,
  (newVal) => {
    rolesList.value = newVal; // Update usersList whenever fetchedUsers changes
  }
);

onBeforeMount(async () => {
  await rolesStore.getAllRoles();
});

const deleteRole = async (roleId) => {
  try {
    promptsStore.addPrompt({
      type: "Role",
      title: "Delete Role?",
      message: `Are you sure you want to delete this Role?`,
    });

    const promptResponse = await promptsStore.waitForPromptConfirmation();

    if (promptResponse === true) {
      promptsStore.removePrompt();

      promptsStore.addPrompt({
        type: "Role",
        title: "Confirm Deletion of Role",
        message: `You will still need to remove the role from the users it currently exists on in order for this role to no longer effect those users. You may still proceed with the deletion of this role. Are you sure you want to delete this Role?`,
      });

      const promptResponse2 = await promptsStore.waitForPromptConfirmation();

      if (promptResponse2 === true) {
        promptsStore.removePrompt();

        const response = await rolesStore.deleteRole(roleId);

        if (response.data.status === "success") {
          toastsStore.addToast({
            title: "Role Deleted",
            description: "",
            buttonExists: false,
            color: "emerald",
            type: 1, // 1 = small, 2 = md, 3 = lg, 4 = lg dismissable
          });
          await rolesStore.getAllRoles();
        } else {
          toastsStore.addToast({
            title: "Error Deleting Role",
            description: "",
            buttonExists: false,
            color: "red",
            type: 1, // 1 = small, 2 = md, 3 = lg, 4 = lg dismissable
          });
        }
      } else {
        promptsStore.removePrompt();
      }
    } else {
      promptsStore.removePrompt();
    }
  } catch (error) {
    console.log(error);
  }
};
</script>

<template>
  <div class="flex-1 max-w-full h-full">
    <cLoadingSpinner v-if="rolesStore.fetchedRoles === null" />
    <table v-else-if="rolesStore?.fetchedRoles?.length > 0" class="w-full">
      <thead class="">
        <tr>
          <th
            v-for="(role, resultIndex) in rolesStore.fetchedRoles[0]"
            :key="resultIndex"
            class="bg-gray-500 text-white text-left p-1 md:px-5 md:py-2 md:text-base text-xs whitespace-nowrap"
          >
            {{ resultIndex.replaceAll("_", " ") }}
          </th>
          <th class="text-white bg-base-300 sticky right-0">
            <span class="hidden md:block"> Action </span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(role, resultIndex) in rolesStore.fetchedRoles"
          :key="resultIndex"
          class="odd:bg-slate-700 even:bg-slate-800"
        >
          <td
            v-for="(cellData, cellIndex) in role"
            :key="cellIndex"
            class="md:px-5 md:py-3 text-white text-xs md:text-base"
          >
            {{ cellData }}
          </td>
          <td class="bg-base-300 sticky right-0 p-1">
            <div
              class="flex flex-col md:flex-row justify-end md:items-center items-stretch"
            >
              <label
                v-if="
                  loginStore?.sessionData?.permissions?.includes(
                    'delete:roles'
                  ) ||
                  loginStore?.sessionData?.permissions?.includes(
                    '*:dev-permissions'
                  )
                "
                class="btn btn-xs md:btn-sm rounded-t rounded-b-none md:rounded-l md:rounded-r-none bg-rose-500 hover:bg-rose-700 text-white border-none"
                @click.stop="deleteRole(role._id)"
              >
                <font-awesome-icon icon="fa-solid fa-xmark" size="lg" />
              </label>

              <!-- BUG - might add this later - no editing roles for now - just have to add new and delete the old after -->
              <!-- <button
                class="btn btn-xs md:btn-sm rounded-b-none md:rounded-l-none md:rounded-r bg-blue-500 hover:bg-blue-700 text-white border-none"
                @click="rolesStore.openEditRoleModal(role._id)"
              >
                <font-awesome-icon icon="fa-solid fa-pen-to-square" />
              </button> -->
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <div v-else class="bg-red-400 text-white p-10 text-lg">
      <div class="flex flex-row justify-center items-center">
        <span class="ml-3">No Roles Exist - Error - Role must exist</span>
      </div>
    </div>

    <!-- <div>
      <SettingsRolesEdit />
    </div> -->
  </div>
</template>
