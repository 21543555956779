import {
  //axios
  axios,

  //pinia
  defineStore,

  //env base url
  BASE_API_URL,
} from "../clientImports";

export const useContactFileStore = defineStore({
  id: "contactFile",
  state: () => ({
    contactId: "", // the current contact file id - this will be used to fetch the contact file data

    //project these datapoints always
    contactFileDataDefault: [
      //simple data
      "first_name",
      "last_name",
      "email_primary",
      "phone_primary",
      "business_name",
      "business_ein",
      "business_type",
      "business_address",
      "business_suite_unit",
      "business_city",
      "business_state",
      "business_zip",
      "phone_primary",

      //1 to 1 data
      "stage",
      "campaign",
      "contact_type",
      "calls_opt_in",
      "sms_opt_in",
      "emails_opt_in",
      "mailers_opt_in",
      "esign_status",
      "tags",
      "created_at",
      "created_by",

      //many to one data
      "assignments",
    ],

    initialFetch: false,

    contactFileData: [],

    currentContactFile: null,
  }),
  actions: {
    async fetchContactFileData() {
      if (this.intitialFetch) {
      }

      const params = {
        id: this.contactId,
        componentsToLoad: this.contactFileDataDefault,
      };

      const response = await axios.get(`${BASE_API_URL}/contact-file`, {
        params,
      });

      this.currentContactFile = response.data.contactFileData;

      console.log("CONTACT FILE DATA - DEFAULTS: ", this.currentContactFile);
    },

    //pass the dataPoint and return it from the contact file - called on mount by the component
    async fetchContactFileDataAdditional(dataPoint) {
      const params = {
        id: this.contactId,
        componentsToLoad: [dataPoint],
      };

      const response = await axios.get(`${BASE_API_URL}/contact-file`, {
        params,
      });

      this.currentContactFile[`${dataPoint}`] =
        response.data.contactFileData[`${dataPoint}`];

      console.log(
        `CONTACT FILE DATA - ADDITIONAL: ${dataPoint}`,
        this.currentContactFile[`${dataPoint}`]
      );
    },

    async fetchCurrentData() {
      this.fetchContactFileData();
    },

    setCurrentContactFile(contactId) {
      this.contactId = contactId;
    },

    async updateContactType(contactTypeVal) {
      console.log("CONTACT TYPE PATCHING - STORE", contactTypeVal);
      const response = await axios.patch(
        `${BASE_API_URL}/update-contact-type/${this.contactId}`,
        {
          contactTypeVal,
        }
      );
    },

    async updateEsignStatus() {
      const response = await axios.patch(
        `${BASE_API_URL}/update-esign-status/${this.contactId}`
      );

      console.log(response.data);
    },

    async updateContactFlag(flagName, flagValue) {
      const { data } = await axios.patch(
        `${BASE_API_URL}/update-contact-flag/${this.contactId}`,
        {
          flagName,
          flagValue,
        }
      );

      return data;
    },

    // used when creating a new assignment item in the sub document on contact
    async createManyToOneSingle(fieldName, itemValToUpdate) {
      const response = await axios.patch(
        `${BASE_API_URL}/create-many-to-one-single/${this.contactId}`,
        {
          fieldName,
          itemValToUpdate,
        }
      );
    },

    async updateManyToOneSingle(fieldName, itemValToUpdate) {
      const response = await axios.patch(
        `${BASE_API_URL}/update-many-to-one-single/${this.contactId}`,
        {
          fieldName,
          itemValToUpdate,
        }
      );
    },

    //delete local storage data for this store
    deleteLocalStorageData() {},
  },

  //fieldValue is the id of the array item to be deleted from contact_${module} - always an id only
  async deleteManyToOneSingle(fieldName, itemIdToRemove) {
    const response = await axios.patch(
      `${BASE_API_URL}/delete-many-to-one-single/${this.contactId}`,
      {
        fieldName,
        itemIdToRemove,
      }
    );
  },

  // used when editing an assignment
  async createOrUpdateManyToOneSingle(fieldName, itemValToUpdate) {
    const response = await axios.patch(
      `${BASE_API_URL}/create-or-update-many-to-one-single/${this.contactId}`,
      {
        fieldName,
        itemValToUpdate,
      }
    );
  },
});
