<script setup>
defineOptions({
  name: 'cFileRead', // Give the component a name
});
// import from clientImports
import {
  ref,
  computed,
  reactive,
  onMounted,
  useGlobalUploadStore,
  useGlobalToastsStore,
} from "./../clientImports";

const props = defineProps({
  fileKey: {
    type: String,
    required: true,
    default:
      "contact_attachments/33339f2f88524f339f16723ae201bfb1_Screenshot_20231129_134349_Chrome.jpg",
  },
  // fileUrl: {
  //   type: String,
  //   required: true,
  // },
});

const uploadsStore = useGlobalUploadStore();
const toastsStore = useGlobalToastsStore();
const fileKey = ref(props.fileKey);

//call to globalUploadStore - getFile
const getFileUrl = async () => {
  const response = await uploadsStore.getFile(fileKey.value);
  const fileUrl = response.data.fileUrl;

  if (fileUrl) {
    const popupWidthPercentage = 0.3; // 30% of the current window width
    const popupHeightPercentage = 0.5; // 50% of the current window height

    const popupWidth = window.innerWidth * popupWidthPercentage;
    const popupHeight = window.innerHeight * popupHeightPercentage;

    // Calculate the left and top positions
    const left = window.screenX + (window.innerWidth - popupWidth);
    const top = window.screenY + (window.innerHeight - popupHeight);

    const windowFeatures = `width=${popupWidth},height=${popupHeight},top=${top},left=${left},scrollbars=yes`;

    window.open(fileUrl, "_blank", windowFeatures);
  }
};
</script>

<template>
  <!-- <vue-pdf-app :src="response?.data?.fileUrl"> </vue-pdf-app> -->
  <!-- <div></div> -->
  <button
    @click="getFileUrl"
    class="btn-xs md:btn-sm bg-emerald-500 rounded-none hover:bg-blue-700 text-white border-none"
  >
    <font-awesome-icon icon="fa-solid fa-up-right-from-square" />
  </button>
</template>
