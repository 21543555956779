<script setup>
defineOptions({
  name: "SettingsUsersNetwork", // Give the component a name
});
import {
  //axios
  axios,

  //vue
  createApp,
  ref,
  reactive,
  watch,
  computed,
  inject,
  provide,
  onMounted,
  onUnmounted,
  nextTick,
  Teleport,

  //pinia
  defineStore,
  createPinia,
  storeToRefs,

  //pinia stores
  useChatStore,
  useContactAlertsStore,
  useContactFileStore,
  useContactsStore,
  useGlobalPromptsStore,
  useGlobalToastsStore,
  useLoginStore,
  useTenantsStore,
  useTenantsAttachmentTypesStore,
  useGlobalUploadStore,
  useContactAssignmentsStore,
  useNetworkedUsersStore,
  useUsersStore,
  useNetworkedCompaniesStore,

  //vue-router
  useRouter,
  useRoute,

  //formkit
  FKsubmitForm,

  //fontawesome
  library,
  FontAwesomeIcon,
  fas,
  far,
  fab,

  //global components - all vue components
  registerGlobalComponents,

  //utils
  xLog,

  //base api url
  BASE_API_URL,
} from "./../clientImports";

const contactAssignmentsStore = useContactAssignmentsStore();

const networkedUsersStore = useNetworkedUsersStore();

const toastsStore = useGlobalToastsStore();

const promptsStore = useGlobalPromptsStore();

const usersStore = useUsersStore();

const networkedCompaniesStore = useNetworkedCompaniesStore();

// watch the currentNetworkView and fetch the networked users
watch(
  () => networkedUsersStore.currentNetworkView,
  async (newValue, oldValue) => {
    console.log("newVal", newValue);
    if (newValue === "networked-out-view") {
      await networkedUsersStore.getNetworkedOutUsers();

      networkedUsersStore.networkedInUsersList = null;
    }

    if (newValue === "networked-in-view") {
      await networkedUsersStore.getNetworkedInUsers();

      networkedUsersStore.networkedOutUsersList = null;
    }
  }
);

onMounted(async () => {
  await contactAssignmentsStore.fetchUsersOptions();

  await networkedUsersStore.getNetworkedOutUsers();

  await usersStore.fetchAllRoles();
});

const networkUserForm = ref({
  tenant_id: "",
  user_id: "",
});

const addNetworkOutUser = async () => {
  return FKsubmitForm("networked-out-users-form");
};

const submitAddNetworkedOutUser = async () => {
  const response = await networkedUsersStore.addNetworkedOutUser(
    networkUserForm.value
  );

  if (response.status === "success") {
    toastsStore.addToast({
      title: "User Shared With Tenant",
      description: "",
      buttonExists: false,
      color: "emerald",
      type: 1, // 1 = small, 2 = md, 3 = lg, 4 = lg dismissable
    });
    networkedCompaniesStore.getNetworkedCompanies();
    networkedUsersStore.getNetworkedOutUsers();
  }

  console.log(response);

  await networkedUsersStore.toggleAddNetworkedOutUsersModal();

  networkUserForm.value = {
    tenant_id: "",
    user_id: "",
  };
};

const unshareUserWithTenant = async (user_id, tenant_id) => {
  promptsStore.addPrompt({
    type: "Unshare This User From This Tenant?",
    title: "Unshare Networked Out User",
    message: `Are you sure you want to unshare this user from this tenant?`,
    color: "red",
    type: 1, // 1 = small, 2 = md, 3 = lg, 4 = lg dismissable
  });

  const promptResponse = await promptsStore.waitForPromptConfirmation();

  if (promptResponse === true) {
    promptsStore.removePrompt();

    const response = await networkedUsersStore.unshareNetworkedOutUser(
      user_id,
      tenant_id
    );

    if (response.status === "success") {
      toastsStore.addToast({
        title: "Removed Tenant From User",
        description: "",
        buttonExists: false,
        color: "emerald",
        type: 1, // 1 = small, 2 = md, 3 = lg, 4 = lg dismissable
      });
      await networkedCompaniesStore.getNetworkedCompanies();
      await networkedUsersStore.getNetworkedOutUsers();
    }
  } else {
    promptsStore.removePrompt();
  }
};
const evictNetworkedInUser = async (user_id) => {
  promptsStore.addPrompt({
    type: "Evict This User From This Tenant?",
    title: "Evict Networked Out User",
    message: `Are you sure you want to evict this user from your company?`,
    color: "red",
    type: 1, // 1 = small, 2 = md, 3 = lg, 4 = lg dismissable
  });

  const promptResponse = await promptsStore.waitForPromptConfirmation();

  if (promptResponse === true) {
    promptsStore.removePrompt();

    const response = await networkedUsersStore.evictNetworkedInUser(user_id);

    if (response.status === "success") {
      toastsStore.addToast({
        title: "User Evicted From Your Company",
        description: "",
        buttonExists: false,
        color: "emerald",
        type: 1, // 1 = small, 2 = md, 3 = lg, 4 = lg dismissable
      });

      await networkedUsersStore.getNetworkedInUsers();
    }
  } else {
    promptsStore.removePrompt();
  }
};

const setNetworkedInUserRole = async (user_id, user_tenant_id, role_obj) => {
  try {
    promptsStore.addPrompt({
      type: "Update User Role?",
      title: "Update User Role",
      message: `Are you sure you want to update this user's role?`,
      color: "blue",
      type: 1, // 1 = small, 2 = md, 3 = lg, 4 = lg dismissable
    });

    const promptResponse = await promptsStore.waitForPromptConfirmation();

    if (promptResponse === true) {
      promptsStore.removePrompt();

      const response = await networkedUsersStore.updateNetworkedInUsersRole(
        user_id,
        user_tenant_id,
        role_obj
      );

      if (response.status === "success") {
        toastsStore.addToast({
          title: "User Role Updated",
          description: "",
          buttonExists: false,
          color: "emerald",
          type: 1, // 1 = small, 2 = md, 3 = lg, 4 = lg dismissable
        });
      }

      console.log(response);

      await networkedUsersStore.getNetworkedInUsers();
    } else {
      promptsStore.removePrompt();
    }

    await networkedUsersStore.getNetworkedInUsers();
  } catch (error) {
    console.log(error);
  }
};
</script>

<template>
  <div class="flex flex-row h-full">
    <div class="flex-1 p-3">
      <div class="mb-3 flex flex-row items-center gap-3">
        <cSidebarModal
          sidebarPosition="right"
          :showSidebarModal="networkedUsersStore.showAddNetworkedOutUserModal"
          :toggleSidebarModal="
            networkedUsersStore.toggleAddNetworkedOutUsersModal
          "
          :hasBackdrop="true"
          :toggleButtonColor="`blue`"
          :defaultButton="false"
        >
          <template #toggle-button>
            <span class="hidden md:block mr-2">Share Out User</span>
            <font-awesome-icon icon="fa-solid fa-plus" />
          </template>
          <template #title>Share Out User </template>
          <template #button-group> </template>

          <template #body>
            <div class="h-full max-h-full">
              <div class="flex flex-row">
                <FormKit
                  :incomplete-message="false"
                  @submit="submitAddNetworkedOutUser"
                  id="networked-out-users-form"
                  type="form"
                  form-class="flex-1"
                  :actions="false"
                  :submit-attrs="{
                    inputClass: '',
                    wrapperClass: '',
                    'data-theme': `dark`,
                    help: '',
                    ignore: false,
                  }"
                >
                  <div class="flex flex-col md:flex-row gap-3 flex-1">
                    <FormKit
                      outer-class="m-0 flex-1"
                      input-class="$reset rounded w-full input input-sm text-gray-700 bg-gray-50"
                      wrapper-class="max-w-full"
                      v-model="networkUserForm.tenant_id"
                      type="text"
                      label="Tenant ID"
                      placeholder="24 Digit Tenant ID"
                      validation="required|length:23,25"
                      :validation-messages="{
                        length: 'Tenant ID must be 24 characters',
                      }"
                    />
                    <FormKit
                      outer-class="m-0 flex-1"
                      input-class="$reset select select-sm text-gray-700 bg-gray-50 rounded w-full"
                      wrapper-class="max-w-full"
                      type="select"
                      label="User"
                      validation="required"
                      :options="[
                        {
                          department_id: 0,
                          user_id: 0,
                          label: 'Select User',
                          value: 0,
                          attrs: { disabled: true },
                        },
                        ...contactAssignmentsStore.tenantsUsersOptions,
                      ]"
                      v-model="networkUserForm.user_id"
                    />
                  </div>
                </FormKit>
              </div>
            </div>
          </template>

          <template #footer>
            <div class="flex flex-row justify-between">
              <button
                @click="networkedUsersStore.toggleAddNetworkedOutUsersModal"
                class="bg-red-500 btn rounded text-white hover:bg-red-600 capitalize btn-sm border-none"
              >
                Cancel
              </button>

              <!-- submit button -->
              <button
                type="button"
                @click.stop="addNetworkOutUser"
                class="btn btn-sm rounded border-none text-white capitalize bg-emerald-500 mt-auto"
              >
                Add Networked Out User
              </button>
            </div>
          </template>
        </cSidebarModal>
      </div>

      <div
        v-if="networkedUsersStore.currentNetworkView === 'networked-out-view'"
        class="flex flex-col gap-3 mt-3"
      >
        <div class="" v-if="networkedUsersStore.networkedOutUsersList === null">
          <cLoadingSpinner />
        </div>
        <div
          v-else-if="networkedUsersStore.networkedOutUsersList.length > 0"
          :key="i"
          v-for="(user, i) in networkedUsersStore.networkedOutUsersList"
          class="flex flex-col gap-5 border border-slate-400 bg-base-100 rounded-md"
        >
          <div class="flex flex-row flex-wrap gap-3">
            <div
              class="p-3 flex flex-row flex-1 items-center border-b border-slate-400"
            >
              <h1 class="">
                {{ user.first_name + " " + user.last_name }}
              </h1>
              <h1 class="ml-auto">
                {{ user.email }}
              </h1>
            </div>

            <h1 class="flex flex-row w-full px-3">Shared With Tenants:</h1>
            <div class="flex flex-row p-3 gap-3">
              <div
                v-for="(tenant, i) in user.shared_with_tenant_ids"
                class="flex flex-row gap-3"
              >
                <div
                  class="flex flex-row gap-3 bg-gray-500 text-white p-1 rounded"
                >
                  {{ tenant.company_legal_name }}

                  <button
                    class="btn btn-xs"
                    @click="unshareUserWithTenant(user._id, tenant._id)"
                  >
                    <font-awesome-icon
                      icon="fa-solid fa-xmark"
                      class="text-white"
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="bg-blue-400 p-10 rounded text-center"
          v-else-if="networkedUsersStore.networkedOutUsersList.length === 0"
        >
          No Networked Out Users Yet
        </div>
      </div>

      <div
        v-if="networkedUsersStore.currentNetworkView === 'networked-in-view'"
        class="flex-1 flex flex-col gap-3"
      >
        <div class="" v-if="networkedUsersStore.networkedInUsersList === null">
          <cLoadingSpinner />
        </div>
        <div
          :key="i"
          v-for="(user, i) in networkedUsersStore.networkedInUsersList"
          class="flex flex-col gap-5 border border-slate-400 bg-base-100 rounded-md p-3"
          v-else-if="networkedUsersStore.networkedInUsersList.length > 0"
        >
          <div
            class="flex flex-col md:flex-row flex-wrap md:items-center gap-3"
          >
            <div
              class="flex-1 flex flex-col md:flex-row gap-3 md:justify-between items-start md:items-center border-slate-400"
            >
              <h1 class="">
                {{ user.first_name + " " + user.last_name }}
              </h1>
              <h1 class="">
                {{ user.email }}
              </h1>
              <h1 class="">
                {{ user.company_legal_name }}
              </h1>

              <h1 class="">{{ user.domain_prefix }}.app.finnypi.com</h1>
            </div>

            <div class="flex flex-col md:flex-row gap-3 items-center">
              <FormKit
                type="select"
                validation="required"
                :options="[
                  {
                    label: 'Select Role',
                    value: 0,
                    attrs: {
                      disabled: 'label' in user.role_value ? false : true,
                    },
                  },

                  ...usersStore.rolesOptions,
                ]"
                help-class="text-white"
                outer-class="m-0 $reset col-span-2"
                input-class="$reset select select-sm text-gray-700 bg-gray-50 rounded w-full"
                wrapper-class="max-w-full"
                :value="user?.role_value"
                @input="
                  setNetworkedInUserRole(user._id, user.user_tenant_id, $event)
                "
              />

              <button
                class="btn btn-xs md:btn-sm bg-red-500 text-white rounded border-none hover:bg-red-700 capitalize ml-auto"
                @click="evictNetworkedInUser(user._id)"
              >
                <span class="mr-2"> Evict </span>
                <font-awesome-icon icon="fa-solid fa-hand-peace" size="lg" />
              </button>
            </div>
          </div>
        </div>

        <div
          class="bg-blue-400 p-10 rounded text-center"
          v-else-if="networkedUsersStore.networkedInUsersList.length === 0"
        >
          No Networked In Users Yet
        </div>
      </div>
    </div>
  </div>
</template>
