import {
  //axios
  axios,

  //pinia
  defineStore,

  //env base url
  BASE_API_URL,
} from "../clientImports";

export const useWebsiteStore = defineStore({
  id: "website-store",
  state: () => ({
    showPrivacyPolicy: false,
    showTermsOfService: false,
  }),
  actions: {
    togglePrivacyPolicy() {
      this.showPrivacyPolicy = !this.showPrivacyPolicy;
    },
    toggleTermsOfService() {
      this.showTermsOfService = !this.showTermsOfService;
    },
  },
});
