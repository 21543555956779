import {
  //axios
  axios,

  //pinia
  defineStore,

  //env base url
  BASE_API_URL,
} from "../clientImports";

export const useChatStore = defineStore({
  id: "chat",
  state: () => ({
    chat: false, //will toggle to show the chat at the bottom of view port. Expands thrid row in vp with flex
  }),
  actions: {
    async toggleChat() {
      this.chat = !this.chat;
    },
  },
});
