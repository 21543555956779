<script setup>
defineOptions({
  name: 'cFlagOneToOne', // Give the component a name
});
import {
  //vue
  ref,
  computed,
  watch,
  reactive,
  Teleport,

  //formkit
  FKsubmitForm,

  //utils
  xLog,
  copyText,
} from "../clientImports";

const props = defineProps({
  selectName: {
    type: String,
    default: "Name of Select",
  },
  initialValue: {
    type: Object,
    default: "item1",
  },
  flagColor: {
    type: String,
    required: true,
    default: "teal",
  },
  selectOptions: {
    type: Array,
    required: true,
    default: () => {
      return [
        {
          label: "Item 1",
          value: "item1",
        },
        {
          label: "Item 2",
          value: "item2",
        },
        {
          label: "Item 3",
          value: "item3",
        },
      ];
    },
  },
});

const selectName = ref(props.selectName);

const selectOptions = ref(props.selectOptions);

const emits = defineEmits(["update:value"]);

const selectValue = ref(props.initialValue);

const updateFlag = (selectValue) => {
  props.updateFlag(selectValue);
};
</script>

<template>
  <!-- flag row -->
  <div class="grid grid-cols-2">
    <div
      :class="`text-white text-center flex flex-row justify-start p-1 md:p-2 bg-${flagColor}-500`"
    >
      <slot name="flag-icon"></slot>
      <span class="text-xs md:text-sm ml-2">{{ selectName }}</span>
    </div>
    <select
      @change="updateFlag(selectValue)"
      v-model="selectValue"
      class="text-white text-center text-xs md:text-sm"
      name=""
      id=""
    >
      <option
        v-for="(option, i) in selectOptions"
        :key="i"
        :value="option.value"
      >
        {{ option.label }}
      </option>
    </select>
  </div>
</template>
