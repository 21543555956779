<script setup>
defineOptions({
  name: 'Notifications', // Give the component a name
});
import {
  //axios
  axios,

  //vue
  createApp,
  ref,
  reactive,
  watch,
  computed,
  inject,
  provide,
  onMounted,
  onUnmounted,
  nextTick,
  Teleport,

  //pinia
  defineStore,
  createPinia,
  storeToRefs,

  //pinia stores
  useChatStore,
  useContactAlertsStore,
  useContactFileStore,
  useContactsStore,
  useGlobalPromptsStore,
  useGlobalToastsStore,
  useLoginStore,
  useTenantsStore,

  //vue-router
  useRouter,
  useRoute,

  //formkit

  //formkit config file

  //fontawesome
  library,
  FontAwesomeIcon,
  fas,
  far,
  fab,

  //global components - all vue components
  registerGlobalComponents,

  //base api url
  BASE_API_URL,
} from "./../clientImports";

const loginStore = useLoginStore();

const notificationsSidebar = ref(false);

const toggleNotificationsSidebar = () => {
  notificationsSidebar.value = !notificationsSidebar.value;
};

const notificationFeed = reactive([
  { id: 1, type: "esign", label: "E-Sign", content: "E-Signature Completed" },
  {
    id: 1,
    type: "re-sign",
    label: "Re-Sign",
    content: "E-Signature Re-Signed",
  },
  { id: 1, type: "payment", label: "Payment", content: "Payment Paid" },
  {
    id: 1,
    type: "doc",
    label: "Doc Upload",
    content: "Document Uploaded",
  },
]);
</script>

<template>
  <button
    v-if="
      loginStore?.sessionData?.permissions?.includes(
        'view:notifications tab'
      ) || loginStore?.sessionData?.permissions?.includes('*:dev-permissions')
    "
    @click="toggleNotificationsSidebar()"
    class="text-white capitalize border-none bg-yellow-500 rounded-l-none rounded-r drawer-button text-xs md:text-base btn btn-xs md:btn-sm"
  >
    <font-awesome-icon
      icon="fa-solid fa-bell"
      size="md"
      class="text-white"
    /><span class="hidden ml-2 lg:block">Notifications</span>
  </button>
  <Teleport to="#sidebars-target">
    <div v-show="notificationsSidebar" class="fixed z-50 drawer drawer-end">
      <input
        v-model="notificationsSidebar"
        type="checkbox"
        class="drawer-toggle"
      />
      <div class="drawer-content"></div>
      <div class="drawer-side">
        <label
          @click="toggleNotificationsSidebar"
          class="drawer-overlay"
        ></label>
        <ul class="menu w-96 bg-base-100 text-base-content">
          <div class="flex items-center h-16 px-5 text-white bg-yellow-500">
            <font-awesome-icon icon="fa-solid fa-bell" size="lg" />
            <h3 class="ml-2 text-lg font-bold">Notifications</h3>
          </div>
          <div class="p-3">
            <div
              v-for="(notification, notificationIndex) in notificationFeed"
              class="grid grid-cols-3 mb-3 text-white rounded"
            >
              <!-- TYPE LABEL -->
              <div
                :class="{
                  'bg-blue-500': notification.type === 'esign',
                  'bg-blue-700': notification.type === 're-sign',
                  'bg-emerald-500': notification.type === 'payment',
                  'bg-orange-500': notification.type === 'doc',
                }"
                class="col-span-1 p-3 text-center rounded-tl-lg"
              >
                {{ notification.label }}
              </div>

              <!-- NOTIFICATION CONTENT -->
              <div class="col-span-2 p-3 text-white rounded-tr-lg bg-slate-500">
                {{ notification.content }}
              </div>

              <!-- BUTTON TOOLBAR -->
              <div class="flex flex-row col-span-3 border-t">
                <button
                  class="flex-1 text-white capitalize border-none rounded-t-none rounded-r-none btn bg-sky-500 hover:bg-sky-700"
                >
                  <span class="mr-2">View {{ notification.type }}</span>
                  <font-awesome-icon icon="fa-solid fa-expand" />
                </button>
                <button
                  class="flex-1 text-white capitalize bg-red-500 border-none rounded-t-none rounded-l-none btn hover:bg-red-700"
                >
                  <span class="mr-2">Dismiss</span>
                </button>
              </div>
            </div>
          </div>
        </ul>
      </div>
    </div>
  </Teleport>
</template>
