<script setup>
defineOptions({
  name: 'ContactNotes', // Give the component a name
});
import {
  //axios
  axios,

  //vue
  createApp,
  ref,
  reactive,
  watch,
  computed,
  inject,
  provide,
  onMounted,
  onUnmounted,
  nextTick,
  Teleport,

  //pinia
  defineStore,
  createPinia,
  storeToRefs,

  //pinia stores
  useChatStore,
  useContactAlertsStore,
  useContactFileStore,
  useContactsStore,
  useGlobalPromptsStore,
  useGlobalToastsStore,
  useLoginStore,
  useTenantsStore,

  //vue-router
  useRouter,
  useRoute,

  //formkit

  //formkit config file

  //fontawesome
  library,
  FontAwesomeIcon,
  fas,
  far,
  fab,

  //global components - all vue components
  registerGlobalComponents,

  //centralized data
  countriesOptions,

  //base api url
  BASE_API_URL,
} from "./../clientImports";

const noteTemplates = ref([
  {
    id: 1,
    label: "Sale Made",
    value: `Client was interested and purchased a product.`,
    tags: "",
  },
  {
    id: 2,
    label: "Call Back Follow Up",
    value: `Client wasn't interested at the moment. Will reach back out later.`,
    tags: "",
  },
]);

const templateDropdown = ref("");
let noteTemplateChosen = ref("");

const applyTemplate = () => {};

watch(templateDropdown, (val) => {
  if (val) {
    noteTemplateChosen.value = val;
    setTimeout(() => {
      templateDropdown.value = "";
    }, 100);
  }
});

const showNotePublisher = ref(false);

const toggleNotePublisher = () => {
  showNotePublisher.value = !showNotePublisher.value;
};
</script>

<template>
  <button
    @click="toggleNotePublisher"
    class="btn btn-xs md:btn-sm border-none rounded-none bg-yellow-500 text-white capitalize"
  >
    <font-awesome-icon icon="fa-regular fa-note-sticky" />
  </button>

  <Teleport to="#modals-target">
    <transition
      enter-active-class="transition-opacity duration-300 ease-in-out"
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="transition-opacity duration-300 ease-in-out"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div
        @click.self="toggleNotePublisher"
        v-if="showNotePublisher"
        class="fixed top-0 left-0 w-screen h-screen z-50 flex items-center justify-center backdrop-brightness-50"
      >
        <div
          v-if="showNotePublisher"
          class="flex items-center justify-center md:w-1/4"
        >
          <transition
            enter-active-class="transition-all duration-300 ease-in-out"
            enter-from-class="-translate-x-full"
            enter-to-class="translate-x-0"
            leave-active-class="transition-all duration-300 ease-in-out"
            leave-from-class="translate-x-0"
            leave-to-class="-translate-x-full"
          >
            <FormKit
              :actions="false"
              type="form"
              :incomplete-message="false"
              submit-label="Publish Note"
              outer-class="bg-black"
              form-class="flex-1 flex-1"
              @submit.prevent
              :submit-attrs="{
                inputClass: 'btn btn-block capitalize',
                ignore: true,

                'outer-class': 'm-0 bg-green-300',
                'wrapper-class': 'bg-black',
              }"
            >
              <FormKit
                @change="applyTemplate"
                type="autocomplete"
                name="country"
                placeholder="Select/Search Template"
                :options="noteTemplates"
                input-class="input input-xs md:input-sm w-full text-white bg-gray-900 rounded-none"
                outer-class="m-0 rouned-none"
                wrapper-class="rounded-none max-w-full"
                inner-class="bg-slate-500 rounded-none"
                listboxButton="bg-base-100 rounded-none"
                option-class="text-black"
                dropdownWrapper="bg-white"
                v-model="templateDropdown"
              />

              <FormKit
                input-class="textarea rounded-none bg-yellow-200 text-gray-700 border border-slate-400 p-1 md:p-2 text-xs md:text-sm w-full"
                type="textarea"
                placeholder="...Type or Use a Template"
                wrapper-class="w-full max-w-full flex-1"
                outer-class="m-0 w-full flex-1"
                rows="8"
                v-model="noteTemplateChosen"
              />

              <button
                @click="publishNote"
                class="btn btn-xs md:btn-sm btn-block border-none rounded-none text-white capitalize"
              >
                Publish Note
              </button>
            </FormKit>
          </transition>
        </div>
      </div>
    </transition>
  </Teleport>
</template>
