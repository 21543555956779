<script setup>
defineOptions({
  name: 'cFileUpload', // Give the component a name
});
import {
  ref,
  computed,
  reactive,
  watch,
  onMounted,
  useGlobalUploadStore,
  useGlobalToastsStore,
  useTenantsAttachmentTypesStore,
  useContactFileStore,

  //router
  useRoute,
  useRouter,
} from "./../clientImports";

const props = defineProps({
  uploadDestination: {
    type: String,
    required: true,
    default: "tests",
  },
  uploadLocation: {
    type: String,
    required: true,
    default: "reusable_components",
  },
});

const route = useRoute();
const router = useRouter();

const contactFileStore = useContactFileStore();
const uploadStore = useGlobalUploadStore();
const toastsStore = useGlobalToastsStore();
const tenantsAttachmentTypesStore = useTenantsAttachmentTypesStore();

const fileInput = ref(null);
const MAX_SIZE_MB = 50; // Maximum file size in MB
const uploadDestination = ref(props.uploadDestination); //the final destination of the file
const uploadLocation = ref(props.uploadLocation); //which part of the UI the file was uploaded to

const triggerFileInput = () => {
  fileInput.value.click();
};

const handleDragOver = (event) => {
  event.stopPropagation();
  event.preventDefault();
  event.dataTransfer.dropEffect = "copy";
};

const handleDrop = (event) => {
  event.stopPropagation();
  event.preventDefault();
  const files = event.dataTransfer.files;
  processFiles(files);
};

function handleFiles(event) {
  const files = event.target.files;
  processFiles(files);
}
const setFileName = ref("");
const attachmentType = ref("");

const fileName = ref("");
const fileSize = ref("");
const fileType = ref("");

const uploadSuccessAlert = ref(false);

const formData = ref(new FormData());

const processFiles = async (files) => {
  formData.value = new FormData();
  for (let i = 0; i < files.length; i++) {
    if (files[i].size > MAX_SIZE_MB * 1024 * 1024) {
      alert(`File size should not exceed ${MAX_SIZE_MB} MB.`);
      return;
    }

    formData.value.append("file", files[i]);

    for (let [key, file] of formData.value.entries()) {
      if (file instanceof File) {
        fileName.value = file.name;
        setFileName.value = file.name;
        fileSize.value = file.size;
        fileType.value = file.type;
      }
    }
  }
};

const submitUploadFile = async () => {
  //if the uploadLocation is reusable_components we store in test/ in root directory
  if (uploadLocation.value === "reusable_components") {
    formData.value.append("file_ref_destination", uploadDestination.value);
  }

  //if the uploadDestination is contact_attachments and contact_id is present in the route params
  if (uploadLocation.value === "contact_file" && route.params.contact_id) {
    formData.value.append("contact_id", route.params.contact_id);
    formData.value.append("file_ref_destination", uploadDestination.value);
  }

  if (fileType.value) {
    formData.value.append(
      "file_format_type",
      getSimplifiedFileType(fileType.value).toLowerCase()
    );
  }

  if (attachmentType.value) {
    formData.value.append("attachment_type_id", attachmentType.value);
  }

  //if fileName doesn't match setFileName and setFileName is not empty or null then use setFileName
  if (fileName.value !== setFileName.value && setFileName.value !== "") {
    formData.value.append("renamed_file_name", setFileName.value);
  }

  const response = await uploadStore.uploadFile(formData.value);

  if (response.data.status === "success") {
    toastsStore.addToast({
      title: "File Upload Successful",
      description: "",
      buttonExists: false,
      color: "emerald",
      type: 2,
    });

    //if response status === success

    await contactFileStore.fetchContactFileDataAdditional("attachments");

    uploadSuccessAlert.value = true;

    for (let [key, file] of formData.value.entries()) {
      if (file instanceof File) {
        setFileName.value = "";
        fileName.value = "";
        fileSize.value = "";
        fileType.value = "";
        attachmentType.value = "";
      }
    }

    formData.value = new FormData();

    setTimeout(() => {
      uploadSuccessAlert.value = false;
    }, 3000);
  } else {
    toastsStore.addToast({
      title: "File Upload Failed",
      description: "",
      buttonExists: false,
      color: "red",
      type: 1,
    });
  }

  console.log(response);
};

// Mapping common MIME types to file extensions
const mimeToExtension = {
  "application/pdf": "PDF",
  "text/plain": "TXT",
  "image/jpeg": "JPEG",
  "image/png": "PNG",
  "image/webp": "WEBP",
  "image/gif": "GIF",
  "text/csv": "CSV",
  "application/zip": "ZIP",
  "application/msword": "DOC",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
    "DOCX",
  "application/vnd.ms-excel": "XLS",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "XLSX",
  "application/vnd.ms-powerpoint": "PPT",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation":
    "PPTX",
  // Add more mappings as needed
};

// Computed property to get the simplified file extension
const getSimplifiedFileType = (mimeType) => {
  return mimeToExtension[mimeType] || "";
};

const formatFileSize = (bytes) => {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const sizes = ["Bytes", "KB", "MB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
};

const attachmentTypesOptions = ref([]);

onMounted(async () => {
  console.log("MOUNTED ATTCAHMENT TENANTS");
  await tenantsAttachmentTypesStore.fetchTenantsAttachmentsDropdownList();

  attachmentTypesOptions.value =
    tenantsAttachmentTypesStore?.tenantsAttachmentTypesDropdownList;

  watch(
    () => tenantsAttachmentTypesStore?.tenantsAttachmentTypesDropdownList,
    (newVal, oldVal) => {
      attachmentTypesOptions.value = newVal;
    }
  );
});
</script>

<template>
  <div
    class="border-2 border-dashed border-slate-400 p-3 rounded-xl grid grid-cols-2 gap-5 bg-base-100 flex-wrap"
    @dragover.prevent="handleDragOver"
    @drop.prevent="handleDrop"
  >
    <div class="col-span-2 flex flex-row justify-end">
      <slot name="close-button"></slot>
    </div>
    <div
      v-if="!fileName && !fileSize && !fileType"
      class="w-full text-gray-100"
    >
      <span class="text-base md:text-lg text-gray-100 mr-2">
        Drag & Drop <br />
        Files Here
      </span>
    </div>
    <div
      v-if="!fileName && !fileSize && !fileType"
      class="w-full flex flex-row justify-center items-center h-full"
    >
      <button
        @click="triggerFileInput"
        class="btn btn-block rounded bg-base-100 border border-slate-400 capitalize text-gray-100 p-2 cursor-pointer"
      >
        <font-awesome-icon
          icon="fa-solid fa-arrow-up-from-bracket"
          class="mr-2"
        />
        Choose File
      </button>
    </div>
    <div
      v-if="uploadSuccessAlert"
      class="bg-emerald-500 p-3 text-white col-span-2 rounded"
    >
      <span> <font-awesome-icon icon="fa-solid fa-check" class="mr-2" /> </span>
      File Successfully Uploaded. Drop Another File to Upload.
    </div>
    <div
      v-if="fileName"
      class="col-span-2 flex flex-row items-center rounded-2xl border border-slate-400 divide-x"
    >
      <table class="table text-white w-full">
        <thead class="border-slate-400 border-b">
          <tr>
            <th>Original Name</th>
            <th>Size</th>
            <th>Type</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="fileName">
            <td>{{ fileName }}</td>
            <td>{{ formatFileSize(fileSize) }}</td>
            <td>{{ getSimplifiedFileType(fileType) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-if="fileSize && fileType" class="flex flex-row col-span-2 gap-3">
      <div class="flex flex-col flex-1">
        <FormKit
          label-class="text-white"
          inner-class="mt-2 mb-2"
          label="File Name"
          type="text"
          input-class="input input-sm rounded bg-base-300 text-white w-full"
          validation="required|length:5,55"
          :validation-messages="{
            length: 'Must be between 5 and 55 characters',
          }"
          messages-class="relative"
          message-class="bg-red-500 rounded text-xs text-white p-1"
          v-model.trim="setFileName"
        />
      </div>

      <div class="flex flex-col flex-1">
        <FormKit
          type="select"
          label="Type"
          :options="[
            {
              value: 0,
              label: '- Select Type -',
              attrs: { disabled: true },
            },
            ...attachmentTypesOptions,
          ]"
          input-class="$reset select select-sm rounded bg-base-300 text-white w-full"
          inner-class="mt-2 mb-2"
          outer-class="$reset text-white col-span-4 md:col-span-2"
          validation="required|alphanumeric"
          wrapper-class="w-full max-w-full"
          message-class="bg-red-500 rounded text-xs text-white p-1"
          v-model="attachmentType"
        />
      </div>
    </div>
    <div
      v-if="!fileSize || !fileType"
      class="p-3 bg-red-500 rounded-xl col-span-2 text-white"
    >
      <span> No File Uploaded Yet </span>
    </div>

    <input ref="fileInput" type="file" hidden @change="handleFiles" class="" />

    <div
      v-if="
        fileName &&
        fileSize &&
        fileType &&
        setFileName.length >= 5 &&
        attachmentType
      "
      class="flex flex-row justify-end col-span-2"
    >
      <!-- button to close modal -->
      <button
        @click="submitUploadFile(formData)"
        class="btn btn-sm rounded capitalize bg-emerald-500 text-white border-none"
      >
        Upload Document
      </button>
    </div>
  </div>
</template>
