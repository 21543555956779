<script setup props="['']">
import { ref, computed } from "vue";

const props = defineProps({
  passedItems: {
    type: Array,
    required: true,
  },
});

const items = ref([
  "Item 1",
  "Item 2",
  "Item 3",
  "Item 4",
  "Item 5",
  "Item 1",
  "Item 2",
  "Item 3",
  "Item 4",
  "Item 5",
]);
const searchText = ref("");
const isOpen = ref(false);

const filteredItems = computed(() => {
  return items.value.filter((item) =>
    item.toLowerCase().includes(searchText.value.toLowerCase())
  );
});

const selectItem = (item) => {
  searchText.value = item;
  isOpen.value = false;
};
</script>

<template>
  <div class="dropdown dropdown-bottom">
    <input
      type="text"
      placeholder="Search"
      class="input rounded-none"
      v-model="searchText"
      @focus="isOpen = true"
      @blur="isOpen = false"
    />
    <ul
      v-show="isOpen"
      class="dropdown-content absolute shadow-lg bg-slate-700 text-white z-10 w-64"
    >
      <li
        v-for="(item, index) in filteredItems"
        :key="index"
        class="p-2 cursor-pointer hover:bg-gray-600"
        @mousedown="selectItem(item)"
      >
        {{ item }}
      </li>
    </ul>
  </div>
</template>
