import moment from "moment-timezone";

const wrapStringsInObjects = (strArray) => {
  return strArray.map((str) => {
    return {
      label: str,
      value: str,
    };
  });
};

const getBrowserTimezone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

export const browserTimezone = getBrowserTimezone();

const retrievedTimezones = moment.tz.names();

export const timezoneOptions = wrapStringsInObjects(retrievedTimezones);
