import {
  //axios
  axios,

  //pinia
  defineStore,
  useGlobalToastsStore,
  useGlobalPromptsStore,

  //env base url
  BASE_API_URL,
} from "../clientImports";

export const useTenantsRebuttalsStore = defineStore({
  id: "tenants-rebuttals",
  state: () => ({
    showTenantsRebuttalsCreateModal: false,
    showTenantsRebuttalsEditModal: false,

    idToEdit: "",

    tenantsRebuttalsList: [],
    tenantsRebuttalsButtonsOnlyList: [],

    currentlyCreatingRebuttal: {
      active: true,
      name: "",
      title: "",
      rebuttal: "",
      color: "",
      description: "",
    },

    currentlyEditingRebuttal: {
      active: true,
      name: "",
      title: "",
      rebuttal: "",
      color: "",
      description: "",
    },
  }),
  actions: {
    //two responsibilities - set the id to edit and then open the modal
    async initializeTenantsRebuttalsEditModal(rebuttalId) {
      //set's the id of the Rebuttal that we're editing
      this.setIdToEdit(rebuttalId);

      await this.fetchTenantRebuttal();
      //opens the edit modal
      this.showTenantsRebuttalsEditModal = true;
    },

    toggleTenantsRebuttalsCreateModal() {
      this.showTenantsRebuttalsCreateModal =
        !this.showTenantsRebuttalsCreateModal;
    },

    toggleTenantsRebuttalsEditModal() {
      this.showTenantsRebuttalsEditModal = !this.showTenantsRebuttalsEditModal;
    },

    //fetches the Rebuttals for listing
    async fetchTenantsRebuttals() {
      try {
        const { data } = await axios.get(
          `${BASE_API_URL}/read-tenants-rebuttals`
        );

        if (data.status === "success") {
          this.tenantsRebuttalsList = data.rebuttalsOptions.map((rebuttal) => {
            return {
              id: rebuttal._id,
              name: rebuttal.name,
              title: rebuttal.title,
              rebuttal: rebuttal.rebuttal,
              color: rebuttal.color,
              description: rebuttal.description,
            };
          });
        } else {
          console.error(data.message);
        }
      } catch (error) {
        console.error();
      }
    },
    async fetchTenantsRebuttalsButtonsOnly() {
      try {
        const { data } = await axios.get(
          `${BASE_API_URL}/read-tenants-rebuttals-buttons-only`
        );

        if (data.status === "success") {
          this.tenantsRebuttalsButtonsOnlyList =
            data.rebuttalsButtonOptions.map((rebuttal) => {
              return {
                id: rebuttal._id,
                name: rebuttal.name,
                color: rebuttal.color,
              };
            });
        } else {
          console.error(data.message);
        }
      } catch (error) {
        console.error();
      }
    },

    //fetching for editing
    async fetchTenantRebuttal() {
      const id = this.idToEdit;
      try {
        const { data } = await axios.get(
          `${BASE_API_URL}/read-tenants-rebuttal/${id}`
        );

        console.log(data, "FETCHED MILESTONE");

        if (data.status === "success") {
          console.log(data.rebuttal);
          this.currentlyEditingRebuttal = data.rebuttal[0];

          console.log(this.currentlyEditingRebuttal);
        } else {
          console.error(data.message);
        }
      } catch (error) {
        console.error();
      }
    },

    setIdToEdit(rebuttalId) {
      this.idToEdit = rebuttalId;
    },

    async createTenantsRebuttal() {
      const rebuttalData = this.currentlyCreatingRebuttal;

      console.log("PASSPORT DATA SENDING OUT: ", rebuttalData);
      try {
        const { data } = await axios.post(
          `${BASE_API_URL}/create-tenants-rebuttal`,
          rebuttalData
        );

        console.log("CREATE PASSPORT RESPONSE", data);

        if (data.status === "success") {
          const toastsStore = useGlobalToastsStore();
          toastsStore.addToast({
            title: "Rebuttal Created",
            description: "",
            buttonExists: false,
            color: "emerald",
            type: 1,
          });

          //fetch the updated list of Rebuttals
          this.fetchTenantsRebuttals();
          this.fetchTenantsRebuttalsButtonsOnly();
          this.toggleTenantsRebuttalsCreateModal();
        } else {
          console.error(data.message);
          toastsStore.addToast({
            title: "Error Creating Rebuttal",
            description: data.message,
            buttonExists: false,
            color: "red",
            type: 1,
          });
        }
      } catch (error) {
        console.error();
      }
    },

    async updateTenantsRebuttal() {
      const id = this.idToEdit;
      try {
        const { data } = await axios.put(
          `${BASE_API_URL}/update-tenants-rebuttal/${id}`,
          this.currentlyEditingRebuttal
        );

        if (data.status === "success") {
          console.log(data.message);

          this.toggleTenantsRebuttalsEditModal();
          //fetch the updated list of Rebuttals
          this.fetchTenantsRebuttals();

          //throw toast
          const toastsStore = useGlobalToastsStore();
          toastsStore.addToast({
            title: "Rebuttal Updated",
            description: "",
            buttonExists: false,
            color: "emerald",
            type: 1,
          });
        } else {
          console.error(data.message);
          toastsStore.addToast({
            title: "Error Updating Rebuttal",
            description: data.message,
            buttonExists: false,
            color: "red",
            type: 1,
          });
        }
      } catch (error) {
        console.error();
      }
    },

    async deleteTenantsRebuttal(rebuttalId) {
      const promptStore = useGlobalPromptsStore();

      const group_id = rebuttalId;

      promptStore.addPrompt({
        type: "Rebuttal",
        title: "Delete Rebuttal?",
        message: `Are you sure you want to delete this Rebuttal?`,
      });

      const promptResponse = await promptStore.waitForPromptConfirmation();

      if (promptResponse === true) {
        promptStore.addPrompt({
          type: "Rebuttal",
          title: "Are You Sure?",
          message: `We'll hide this from your view so it can no longer be used. Any contacts or users that have this rebuttal assigned will remain assigned and have to be manually changed. Do you still want to proceed with the deletion?`,
        });

        const promptResponse2 = await promptStore.waitForPromptConfirmation();

        if (promptResponse2 === true) {
          try {
            const { data } = await axios.delete(
              `${BASE_API_URL}/delete-tenants-rebuttal/${group_id}`
            );

            if (data.status === "success") {
              //fetch the updated list of Rebuttals
              this.fetchTenantsRebuttals();
            } else {
              console.error(data.message);
              toastsStore.addToast({
                title: "Error Deleting Rebuttal",
                description: data.message,
                buttonExists: false,
                color: "red",
                type: 1,
              });
            }
          } catch (error) {
            console.error();
          }
          promptStore.removePrompt();
        } else {
          promptStore.removePrompt();
        }
      } else {
        promptStore.removePrompt();
      }
    },
  },
});
