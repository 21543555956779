<script setup>
defineOptions({
  name: 'SettingsWorkflowStatusesList', // Give the component a name
});
import {
  //axios
  axios,

  //vue
  ref,
  reactive,
  watch,
  onMounted,
  onBeforeMount,

  //pinia stores
  useLoginStore,
  useGlobalPromptsStore,
  useGlobalToastsStore,
  useUsersStore,
  useTenantsCampaignsStore,
  useTenantsWorkflowStatusesStore,

  //vue-router
  useRouter,
  useRoute,

  //formkit

  //formkit config file

  //fontawesome
  library,
  FontAwesomeIcon,
  fas,
  far,
  fab,

  //global components - all vue components
  registerGlobalComponents,

  //base api url
  BASE_API_URL,
  useTenantsMilestonesStore,
  computed,
} from "./../clientImports";

const promptsStore = useGlobalPromptsStore();
const toastsStore = useGlobalToastsStore();
const tenantsCampaignsStore = useTenantsCampaignsStore();
const tenantsWorkflowStatusesStore = useTenantsWorkflowStatusesStore();
const loginStore = useLoginStore();

const route = useRoute();
const router = useRouter();

onMounted(async () => {
  await tenantsWorkflowStatusesStore.fetchTenantsWorkflowStatuses(); //initial populate affiliate list
});

//computed function that returns a map of tenantsWorkflowStatusesList
const getTenantsWorkflowStatusesListing = computed(() => {
  return tenantsWorkflowStatusesStore.tenantsWorkflowStatusesList.map(
    (workflowStatus) => {
      return {
        id: workflowStatus.id,
        name: workflowStatus.name,
        description: workflowStatus.description,
        // payable: workflowStatus.payable,
        active: workflowStatus.active,
        // milestone_id: workflowStatus.milestone_id,
        // department_id: workflowStatus.department_id,
        // portal_milestone_id: "",
        // immediate_bot_id: workflowStatus.immediate_bot_id,
        // next_possible_statuses: workflowStatus.next_possible_statuses,
        // next_exact_status_id: workflowStatus.next_exact_status_id,
        // bot_countdowns: workflowStatus.bot_countdowns,
        system_default: workflowStatus.system_default,
      };
    }
  );
});
</script>

<template>
  <div
    class="left-0 top-0 sticky bg-base-100 p-1 border-b border-b-slate-400 text-white"
  >
    <div class="flex flex-row items-center gap-5">
      <div>Workflow Statuses {{ showTenantsWorkflowStatusesCreateModal }}</div>

      <div>
        <router-link
          class="btn btn-xs md:btn-sm bg-blue-500 hover:bg-blue-700 text-white border-none capitalize rounded"
          :to="{ name: 'settings-workflow-statuses-create' }"
        >
          <span class="mr-2"> Create Workflow Status </span>
          <font-awesome-icon icon="fa-solid fa-plus" />
        </router-link>
      </div>

      <SettingsWorkflowStatusesCreate />
    </div>
  </div>
  <div class="flex-1 max-w-full h-full">
    <cLoadingSpinner
      v-if="tenantsWorkflowStatusesStore.tenantsWorkflowStatusesList === null"
    />
    <table
      v-else-if="
        tenantsWorkflowStatusesStore.tenantsWorkflowStatusesList?.length > 0
      "
      class="w-full"
    >
      <thead class="">
        <tr>
          <th
            v-for="(
              workflowStatus, resultIndex
            ) in getTenantsWorkflowStatusesListing[0]"
            v-show="resultIndex !== 'system_default'"
            :key="resultIndex"
            class="bg-gray-500 text-white text-left p-1 md:px-5 md:py-2 md:text-base text-xs whitespace-nowrap"
          >
            {{ resultIndex.replaceAll("_", " ") }}
          </th>
          <th class="text-white bg-base-300 sticky right-0">
            <span class="hidden md:block"> Action </span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(
            workflowStatus, resultIndex
          ) in getTenantsWorkflowStatusesListing"
          :key="resultIndex"
          class="odd:bg-slate-700 even:bg-slate-800"
        >
          <td
            v-for="(cellData, cellIndex) in workflowStatus"
            :key="cellIndex"
            v-show="cellIndex !== 'system_default'"
            class="md:px-5 md:py-3 text-white text-xs md:text-base"
          >
            {{ cellData }}
          </td>
          <td class="bg-base-300 sticky right-0 p-1">
            <div
              class="flex flex-col md:flex-row justify-center md:items-center items-stretch"
            >
              <label
                v-if="
                  (loginStore?.sessionData?.permissions?.includes(
                    'delete:workflow-statuses'
                  ) ||
                    loginStore?.sessionData?.permissions?.includes(
                      '*:dev-permissions'
                    )) &&
                  !workflowStatus.system_default
                "
                class="btn btn-xs md:btn-sm rounded-t rounded-b-none md:rounded-l md:rounded-r-none bg-rose-500 hover:bg-rose-700 text-white border-none"
                @click.stop="
                  tenantsWorkflowStatusesStore.deleteTenantsWorkflowStatus(
                    workflowStatus.id
                  )
                "
              >
                <font-awesome-icon icon="fa-solid fa-xmark" size="lg" />
              </label>

              <!-- BUG - might add this later - no editing roles for now - just have to add new and delete the old after -->
              <!-- <button
                class="btn btn-xs md:btn-sm rounded-b-none md:rounded-l-none md:rounded-r bg-blue-500 hover:bg-blue-700 text-white border-none"
                @click="
                  tenantsWorkflowStatusesStore.initializeTenantsworkflowStatusesEditModal(
                    workflowStatus._id
                  )
                "
              >
                <font-awesome-icon icon="fa-solid fa-pen-to-square" />
              </button> -->

              <!-- need a router-link that sets a param with workflowStatus._id -->
              <router-link
                v-if="
                  (loginStore?.sessionData?.permissions?.includes(
                    'edit:workflow-statuses'
                  ) ||
                    loginStore?.sessionData?.permissions?.includes(
                      '*:dev-permissions'
                    )) &&
                  !workflowStatus.system_default
                "
                class="btn btn-xs md:btn-sm rounded-b rounded-t-none md:rounded-b-none md:rounded-l-none md:rounded-r bg-blue-500 hover:bg-blue-700 text-white border-none"
                :to="{
                  name: 'settings-workflow-statuses-edit',
                  params: { id: workflowStatus.id },
                }"
              >
                <font-awesome-icon icon="fa-solid fa-pen-to-square" />
              </router-link>

              <cSystemDefaultIcon v-if="workflowStatus.system_default" />
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <div v-else class="bg-blue-400 text-white p-10 text-lg">
      <div class="flex flex-row justify-center items-center">
        <span class="ml-3">No Workflow Statuses Exist Yet</span>
      </div>
    </div>
  </div>

  <div>
    <SettingsWorkflowStatusesEdit />
  </div>
</template>
