import {
  //axios
  axios,

  //pinia
  defineStore,

  //env base url
  BASE_API_URL,
} from "../clientImports";

export const useNetworkedCompaniesStore = defineStore({
  id: "networked-companies",
  state: () => ({
    showNetworkedCompanies: false,
    networkedCompanies: [],
  }),
  actions: {
    async toggleNetworkedCompanies() {
      this.showNetworkedCompanies = !this.showNetworkedCompanies;
    },

    async getNetworkedCompanies() {
      try {
        const response = await axios.get(
          `${BASE_API_URL}/get-all-networked-companies`
        );

        this.networkedCompanies = response.data.networkedCompanies;
      } catch (error) {
        console.error(error);
      }
    },
    async traverseToCompany(id) {
      console.log(id);
      try {
        const response = await axios.get(`${BASE_API_URL}/switch-tenant/${id}`);

        return response;
      } catch (error) {
        console.error(error);
      }
    },
  },
});
