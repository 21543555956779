import {
  //axios
  axios,

  //pinia
  defineStore,
  useGlobalToastsStore,
  useGlobalPromptsStore,
  useTenantsBotsStore,

  //env base url
  BASE_API_URL,
} from "../clientImports";

export const useTenantsWorkflowStatusesStore = defineStore({
  id: "tenants-workflow-statuses",
  state: () => ({
    showTenantsWorkflowStatusesCreateModal: false,
    showTenantsWorkflowStatusesEditModal: false,

    idToEdit: "",

    tenantsWorkflowStatusesList: [],

    currentlyCreatingWorkflowStatus: {
      active: true,
      name: "",
      description: "",
      payable: false,
      department_id: null,
      // portal_milestone_id: "",
      milestone_id: null,
      immediate_bot_id: null,
      next_possible_statuses: [],
      next_exact_status_id: null,
      bot_countdowns: [],
    },

    currentlyEditingWorkflowStatus: {
      active: true,
      name: "",
      description: "",
      payable: false,
      department_id: null,
      // portal_milestone_id: "",
      milestone_id: null,
      immediate_bot_id: null,
      next_possible_statuses: [],
      next_exact_status_id: null,
      bot_countdowns: [],
    },
  }),
  actions: {
    //two responsibilities - set the id to edit and then open the modal
    async initializeTenantsWorkflowStatusesEditModal(workflowStatusId) {
      //set's the id of the WorkflowStatus that we're editing
      this.setIdToEdit(workflowStatusId);

      //opens the edit modal
      this.showTenantsWorkflowStatusesEditModal = true;

      //wipe form
      this.currentlyEditingWorkflowStatus = {
        active: true,
        name: "",
        description: "",
        payable: false,
        department_id: null,
        // portal_milestone_id: "",
        milestone_id: null,
        immediate_bot_id: null,
        next_possible_statuses: [],
        next_exact_status_id: null,
        bot_countdowns: [],
      };

      await this.fetchTenantWorkflowStatus();
    },

    toggleTenantsWorkflowStatusesCreateModal() {
      this.showTenantsWorkflowStatusesCreateModal =
        !this.showTenantsWorkflowStatusesCreateModal;
    },

    toggleTenantsWorkflowStatusesEditModal() {
      this.showTenantsWorkflowStatusesEditModal =
        !this.showTenantsWorkflowStatusesEditModal;
    },

    //fetches the WorkflowStatuses for listing
    async fetchTenantsWorkflowStatuses() {
      try {
        const { data } = await axios.get(
          `${BASE_API_URL}/read-tenants-workflow-statuses`
        );

        if (data.status === "success") {
          this.tenantsWorkflowStatusesList = data.workflowStatusesOptions.map(
            (workflowStatus) => {
              return {
                id: workflowStatus._id,
                active: workflowStatus.active,
                name: workflowStatus.name,
                description: workflowStatus.description,
                payable: workflowStatus.payable,
                department_id: workflowStatus.department_id,
                // portal_milestone: workflowStatus.portal_milestone,
                milestone_id: workflowStatus.milestone_id,
                immediate_bot_id: workflowStatus.immediate_bot_id,
                next_possible_statuses: workflowStatus.next_possible_statuses,
                next_exact_status_id: workflowStatus.next_exact_status_id,
                bot_countdowns: workflowStatus.bot_countdowns,
                system_default: workflowStatus.system_default,
              };
            }
          );
        } else {
          console.error(data.message);
        }
      } catch (error) {
        console.error();
      }
    },

    //fetching for editing
    async fetchTenantWorkflowStatus() {
      const id = this.idToEdit;
      try {
        const { data } = await axios.get(
          `${BASE_API_URL}/read-tenants-workflow-status/${id}`
        );

        if (data.status === "success") {
          this.currentlyEditingWorkflowStatus = data.workflowStatus[0];

          this.currentlyEditingWorkflowStatus.bot_countdowns =
            data.workflowStatus[0].bot_countdowns.length > 0
              ? data.workflowStatus[0].bot_countdowns
              : [];
          this.currentlyEditingWorkflowStatus.next_possible_statuses =
            data.workflowStatus[0].next_possible_statuses.length > 0
              ? data.workflowStatus[0].next_possible_statuses
              : [];

          console.log(this.currentlyEditingWorkflowStatus);
        } else {
          console.log(data.message);
        }
      } catch (error) {
        console.log();
      }
    },

    setIdToEdit(workflowStatusId) {
      this.idToEdit = workflowStatusId;
    },

    async createTenantsWorkflowStatus() {
      // get a new set of next_possible_statuses
      const nextPossibleStatuses =
        this.currentlyCreatingWorkflowStatus.next_possible_statuses;
      const nextPossibleStatusesSet = new Set(nextPossibleStatuses);
      this.currentlyCreatingWorkflowStatus.next_possible_statuses = Array.from(
        nextPossibleStatusesSet
      );

      console.log("STATUS SET: ", nextPossibleStatusesSet);

      // remove any objects where the bot_id is null or countdown is null or 0
      const botCountdowns = this.currentlyCreatingWorkflowStatus.bot_countdowns;
      const botCountdownsFiltered = botCountdowns.filter(
        (botCountdown) =>
          botCountdown.bot_id !== null &&
          botCountdown.countdown !== null &&
          botCountdown.countdown !== 0
      );
      this.currentlyCreatingWorkflowStatus.bot_countdowns =
        botCountdownsFiltered;

      const workflowStatusData = this.currentlyCreatingWorkflowStatus;

      console.log("WorkflowStatus DATA SENDING OUT: ", workflowStatusData);
      try {
        const { data } = await axios.post(
          `${BASE_API_URL}/create-tenants-workflow-status`,
          workflowStatusData
        );

        console.log("CREATE WorkflowStatus RESPONSE", data);

        if (data.status === "success") {
          const toastsStore = useGlobalToastsStore();
          toastsStore.addToast({
            title: "Workflow Status Created",
            description: "",
            buttonExists: false,
            color: "emerald",
            type: 1,
          });

          //fetch the updated list of WorkflowStatuses
          this.fetchTenantsWorkflowStatuses();
          this.toggleTenantsWorkflowStatusesCreateModal();

          //wipe form
          this.currentlyCreatingWorkflowStatus = {
            active: true,
            name: "",
            description: "",
            payable: false,
            department_id: null,
            // portal_milestone_id: "",
            milestone_id: null,
            immediate_bot_id: null,
            next_possible_statuses: [],
            next_exact_status_id: null,
            bot_countdowns: [],
          };
        } else {
          console.log(data.message);
          toastsStore.addToast({
            title: "Error Creating WorkflowStatus",
            description: data.message,
            buttonExists: false,
            color: "red",
            type: 1,
          });
        }
      } catch (error) {
        console.error();
      }
    },

    async updateTenantsWorkflowStatus(
      botCountdownsData,
      nextPossibleStatusesData
    ) {
      const id = this.idToEdit;

      const botCountdowns = botCountdownsData;
      const nextPossibleStatuses = nextPossibleStatusesData;
      const botCountdownsFiltered = botCountdowns?.filter(
        (botCountdown) =>
          botCountdown.bot_id !== null &&
          botCountdown.countdown !== null &&
          botCountdown.countdown !== 0
      );

      const nextPossibleStatusesSet = new Set(nextPossibleStatuses);

      this.currentlyEditingWorkflowStatus.next_possible_statuses = Array.from(
        nextPossibleStatusesSet
      );

      this.currentlyEditingWorkflowStatus.bot_countdowns =
        botCountdownsFiltered;

      try {
        const { data } = await axios.put(
          `${BASE_API_URL}/update-tenants-workflow-status/${id}`,
          this.currentlyEditingWorkflowStatus
        );

        if (data.status === "success") {
          console.log(data.message);

          this.toggleTenantsWorkflowStatusesEditModal();
          //fetch the updated list of WorkflowStatuses
          this.fetchTenantsWorkflowStatuses();

          //throw toast
          const toastsStore = useGlobalToastsStore();
          toastsStore.addToast({
            title: "WorkflowStatus Updated",
            description: "",
            buttonExists: false,
            color: "emerald",
            type: 1,
          });
        } else {
          console.error(data.message);
          toastsStore.addToast({
            title: "Error Updating WorkflowStatus",
            description: data.message,
            buttonExists: false,
            color: "red",
            type: 1,
          });
        }
      } catch (error) {
        console.error();
      }
    },

    async deleteTenantsWorkflowStatus(workflowStatusId) {
      const promptStore = useGlobalPromptsStore();

      const group_id = workflowStatusId;

      promptStore.addPrompt({
        type: "WorkflowStatus",
        title: "Delete WorkflowStatus?",
        message: `Are you sure you want to delete this WorkflowStatus?`,
      });

      const promptResponse = await promptStore.waitForPromptConfirmation();

      if (promptResponse === true) {
        promptStore.addPrompt({
          type: "WorkflowStatus",
          title: "Are You Sure?",
          message: `We'll hide this from your view so it can no longer be used. Any contacts or users that have this WorkflowStatus assigned will remain assigned and have to be manually changed. Do you still want to proceed with the deletion?`,
        });

        const promptResponse2 = await promptStore.waitForPromptConfirmation();

        if (promptResponse2 === true) {
          try {
            const { data } = await axios.delete(
              `${BASE_API_URL}/delete-tenants-workflow-status/${group_id}`
            );

            if (data.status === "success") {
              //fetch the updated list of WorkflowStatuses
              this.fetchTenantsWorkflowStatuses();
            } else {
              console.error(data.message);
              toastsStore.addToast({
                title: "Error Deleting WorkflowStatus",
                description: data.message,
                buttonExists: false,
                color: "red",
                type: 1,
              });
            }
          } catch (error) {
            console.error();
          }
          promptStore.removePrompt();
        } else {
          promptStore.removePrompt();
        }
      } else {
        promptStore.removePrompt();
      }
    },
  },
});
