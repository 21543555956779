import {
  //axios
  axios,

  //pinia
  defineStore,

  //env base url
  BASE_API_URL,
} from "../clientImports";

export const useNetworkedUsersStore = defineStore({
  id: "networked-users",
  state: () => ({
    showAddNetworkedOutUserModal: false,

    currentNetworkView: "networked-out-view", //networked-out-users, networked-in-users

    networkedOutUsersList: null,
    networkedInUsersList: null,
  }),
  actions: {
    async toggleAddNetworkedOutUsersModal() {
      this.showAddNetworkedOutUserModal = !this.showAddNetworkedOutUserModal;
    },

    async addNetworkedOutUser(networkOutObj) {
      const { tenant_id, user_id } = networkOutObj;

      const response = await axios.patch(
        `${BASE_API_URL}/add-shared-with-tenant-id/${tenant_id}`,
        { user_id }
      );

      return response.data;
    },

    async getNetworkedOutUsers() {
      //fetch all users from users collection that have current sessions tenant_id

      const response = await axios.get(
        `${BASE_API_URL}/read-networked-out-users`
      );

      this.networkedOutUsersList = response.data.networkedOutUsersList;
    },

    async getNetworkedInUsers() {
      //fetch all users from users collection that have current sessions tenant_id

      const response = await axios.get(
        `${BASE_API_URL}/read-networked-in-users`
      );

      this.networkedInUsersList = response.data.networkedInUsersList;
    },

    async unshareNetworkedOutUser(user_id, tenant_id) {
      const response = await axios.patch(
        `${BASE_API_URL}/remove-one-shared-with-tenant-id/${user_id}`,
        { tenant_id }
      );
      return response.data;
    },

    async updateNetworkedInUsersRole(user_id, user_tenant_id, role_obj) {
      const response = await axios.patch(
        `${BASE_API_URL}/update-networked-in-users-role/${user_id}`,
        {
          user_tenant_id,
          role_obj,
        }
      );

      return response.data;
    },

    async evictNetworkedInUser(user_id) {
      const response = await axios.patch(
        `${BASE_API_URL}/evict-networked-in-user/${user_id}`
      );

      return response.data;
    },
  },
});
