<script setup>
defineOptions({
  name: 'cFileDownload', // Give the component a name
});
// import from clientImports
import {
  ref,
  computed,
  reactive,
  onMounted,
  useGlobalUploadStore,
  useGlobalToastsStore,
} from "./../clientImports";

const props = defineProps({
  fileKey: {
    type: String,
    required: true,
    default:
      "contact_attachments/33339f2f88524f339f16723ae201bfb1_Screenshot_20231129_134349_Chrome.jpg",
  },
  downloadUrl: {
    type: String,
    required: true,
  },
});

const uploadsStore = useGlobalUploadStore();
const toastsStore = useGlobalToastsStore();
const fileKey = ref(props.fileKey);

//call to globalUploadStore - getFile
const downloadFile = async () => {
  const response = await uploadsStore.downloadFile(fileKey.value);
  const downloadUrl = response.data.downloadUrl;

  if (downloadUrl) {
    window.open(downloadUrl, "_self");
  }
};
</script>

<template>
  <button
    @click="downloadFile"
    class="btn-xs md:btn-sm bg-indigo-500 rounded-b rounded-t-none md:rounded-l-none md:rounded-r hover:bg-blue-700 text-white border-none"
  >
    <font-awesome-icon
      icon="fa-solid fa-arrow-up-from-bracket"
      rotation="180"
    />
  </button>
</template>
