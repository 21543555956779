<script setup>
defineOptions({
  name: 'SettingsFormBuilder', // Give the component a name
});
import { contactNotesTableFormatter } from "../utilities/contact_file/contact_notes/contactNotesTableFormatter";
import {
  ref,
  watch,
  reactive,
  Teleport,
  computed,
  onMounted,
  useTenantsFormsStore,
  useLoginStore,
  useGlobalPromptsStore,
  FKsubmitForm,
  useContactsStore,
  useGlobalToastsStore,
  useTenantsRebuttalsStore,
} from "./../clientImports";

//vue component that make elements draggable
import draggable from "vuedraggable";

const loginStore = useLoginStore();

// Call the useContactsStore function to get an instance of the store
const contactsStore = useContactsStore();

const toastStore = useGlobalToastsStore();

const tenantsForms = useTenantsFormsStore();

const promptStore = useGlobalPromptsStore();

const rebuttalsStore = useTenantsRebuttalsStore();

onMounted(async () => {
  await tenantsForms.readFormFields();
  await tenantsForms.readForms();
  await rebuttalsStore.fetchTenantsRebuttalsButtonsOnly();
});

//custom system or rebuttals
const customOrSystem = ref("system");

const setCustomOrSystem = (type) => {
  customOrSystem.value = type;

  //reset the checkbox filters
  tenantsForms.filterByType = [];

  //reset the search field
  tenantsForms.searchField = "";
};

const showFieldFilters = ref(false);
const showCompanyForms = ref(false);

//will eventually use for date locale
const displayLocales = [
  { label: "English (United States)", value: "en-US" },
  { label: "English (United Kingdom)", value: "en-GB" },
  { label: "French (France)", value: "fr-FR" },
  { label: "German (Germany)", value: "de-DE" },
  { label: "Spanish (Spain)", value: "es-ES" },
  { label: "Japanese (Japan)", value: "ja-JP" },
  { label: "Chinese (Simplified, China)", value: "zh-CN" },
  { label: "Chinese (Traditional, Taiwan)", value: "zh-TW" },
  { label: "Korean (South Korea)", value: "ko-KR" },
  { label: "Russian (Russia)", value: "ru-RU" },
  { label: "Italian (Italy)", value: "it-IT" },
  { label: "Dutch (Netherlands)", value: "nl-NL" },
  { label: "Portuguese (Brazil)", value: "pt-BR" },
  { label: "Portuguese (Portugal)", value: "pt-PT" },
  { label: "Arabic (Saudi Arabia)", value: "ar-SA" },
  { label: "Hindi (India)", value: "hi-IN" },
  { label: "Swedish (Sweden)", value: "sv-SE" },
  { label: "Danish (Denmark)", value: "da-DK" },
  { label: "Norwegian (Norway)", value: "nb-NO" },
  { label: "Finnish (Finland)", value: "fi-FI" },
];

//will eventually use for currency field
const currencyTypes = [
  { label: "United States Dollar", value: "USD" },
  { label: "Euro", value: "EUR" },
  { label: "British Pound", value: "GBP" },
  { label: "Japanese Yen", value: "JPY" },
  { label: "Canadian Dollar", value: "CAD" },
  { label: "Australian Dollar", value: "AUD" },
  { label: "Swiss Franc", value: "CHF" },
  { label: "Chinese Yuan", value: "CNY" },
  { label: "Swedish Krona", value: "SEK" },
  { label: "New Zealand Dollar", value: "NZD" },
  { label: "Mexican Peso", value: "MXN" },
  { label: "Singapore Dollar", value: "SGD" },
  { label: "Hong Kong Dollar", value: "HKD" },
  { label: "Norwegian Krone", value: "NOK" },
  { label: "South Korean Won", value: "KRW" },
  { label: "Turkish Lira", value: "TRY" },
  { label: "Russian Ruble", value: "RUB" },
  { label: "Indian Rupee", value: "INR" },
  { label: "Brazilian Real", value: "BRL" },
  { label: "South African Rand", value: "ZAR" },
];

const attribute_checkboxes = ref([
  {
    value: "required",
    label: "Required",
  },
]);

const attributeToggles = reactive({
  toggle_labels: false,
  min_max_length: false,
  min_max_value: false,
  regex: false,
});

//computed function that returns the currentlyCreatingField.regex value as an actual regex
const getRegexObj = (regexPattern) => {
  const cleanedString = regexPattern.replace(/^\/|\/$/g, "");
  //return the regex object
  return new RegExp(cleanedString);
};

const checkRegex = (regexPattern) => {
  try {
    new RegExp(regexPattern);
    return true; // Set to true if the regex pattern is valid
  } catch (e) {
    toastStore.addToast({
      title: "Invalid Regex Pattern",
      message: "Please enter a valid regex pattern",
      buttonExists: false,
      color: "red",
      type: 1, // 1 = small, 2 = md, 3 = lg, 4 = lg dismissable
    });
    return false;
  }
};

const returnSnakeCase = (index) => {
  currentlyCreatingField.options[index].value = currentlyCreatingField.options[
    index
  ].label
    .trim()
    .toLowerCase()
    .replace(/\s+/g, "_")
    .replace(/[^\w]+/g, ""); // Removes all characters except numbers, letters, and underscores
};

const returnSnakeCaseSeparator = (index) => {
  form.value[index].title = form.value[index].title
    .trim()
    .toLowerCase()
    .replace(/\s+/g, "_")
    .replace(/[^\w]+/g, ""); // Removes all characters except numbers, letters, and underscores
};

const currentlyCreatingField = reactive({
  type: "",
  label: "",
  placeholder: "",
  help: "",

  currency: "USD",

  //for currency type
  display_locale: "",

  description: "",

  //should be called validation rules - mis named
  attributes: [],

  //holds the array of options for dropdowns, checkboxes, radios, etc
  options: [],

  //holds the validation messages for each rule
  validation_messages: {},

  //toggle labels
  on_value: "On",
  off_value: "Off",

  //native html attributes
  min_attribute: "",
  step: 1,
  max_attribute: "",

  //validation rules
  min_length: "",
  max_length: "",
  min_value: "",
  max_value: "",
  regex: "",

  //clean_name for the field
  get name() {
    return convertToSnakeCase(this.label) !== ""
      ? convertToSnakeCase(this.label) + "_" + Date.now()
      : "";
  },

  //the final validation array for each field
  get final_validation_array() {
    let validationArray = [];

    if (this.attributes.includes("required")) {
      validationArray.push(["required"]);
    }

    if (this.type === "text") {
      if (this.regex !== "" && attributeToggles.regex) {
        validationArray.push(["matches", new RegExp(getRegexObj(this.regex))]);

        console.log("ADDED REGEX TO VALIDATION ARRAY: ", validationArray);
      }
    }

    if (this.min_length !== "" && this.max_length !== "") {
      validationArray.push([
        "length",
        Number(this.min_length),
        Number(this.max_length)
          ? Number(this.max_length)
          : Number(this.min_length) + 1,
      ]);

      console.log("LENGTH ARRAY: ", validationArray[1]);
    }

    if (this.min_value !== "") {
      validationArray.push(["min", Number(this.min_value)]);
    }

    if (this.max_value !== "") {
      validationArray.push(["max", Number(this.max_value)]);
    }

    if (this.regex !== "" && attributeToggles.regex) {
      //check if "matches" exists in the currentlyCreatingField.validation_messages_dynamic and return true or false
      const matchesExists = validationArray.some((innerArray) =>
        innerArray.includes("matches")
      );

      if (matchesExists) {
        console.log("MATCHES EXISTS IN ARRAY");

        //delete matches array
        validationArray = validationArray.filter(
          (innerArray) => !innerArray.includes("matches")
        );

        //add new matches array
        validationArray.push(["matches", new RegExp(getRegexObj(this.regex))]);
      } else {
        validationArray.push(["matches", new RegExp(getRegexObj(this.regex))]);
      }
    }

    return validationArray;
  },

  get build_conditional_final_object() {
    let conditionalObjectBuild = {};

    if (this.type === "toggle") {
      conditionalObjectBuild = {
        type: this.type,
        label: this.label,
        name: this.name,
        help: this.help,
        validation: this.final_validation_array,
        validation_messages: this.validation_messages,
        on_value: this.on_value,
        off_value: this.off_value,
      };
    }

    if (this.type === "text") {
      conditionalObjectBuild = {
        //we don't need classes, just the attributes
        type: this.type,
        label: this.label,
        name: this.name,
        placeholder: this.placeholder,
        help: this.help,
        validation: this.final_validation_array,
        validation_messages: this.validation_messages,
      };
    }

    if (this.type === "textarea") {
      conditionalObjectBuild = {
        //we don't need classes, just the attributes
        type: this.type,
        label: this.label,
        name: this.name,
        placeholder: this.placeholder,
        help: this.help,
        validation: this.final_validation_array,
        validation_messages: this.validation_messages,
      };
    }

    if (this.type === "number") {
      conditionalObjectBuild = {
        //we don't need classes, just the attributes
        type: this.type,
        label: this.label,
        name: this.name,
        placeholder: this.placeholder,
        help: this.help,
        validation: this.final_validation_array,
        validation_messages: this.validation_messages,
        min: this.min_attribute,
        step: this.step,
        max: this.max_attribute,
      };
    }

    if (this.type === "email") {
      conditionalObjectBuild = {
        //we don't need classes, just the attributes
        type: this.type,
        label: this.label,
        name: this.name,
        placeholder: this.placeholder,
        help: this.help,
        validation: this.final_validation_array,
        validation_messages: this.validation_messages,
      };
    }

    if (this.type === "tel") {
      conditionalObjectBuild = {
        //we don't need classes, just the attributes
        type: this.type,
        label: this.label,
        name: this.name,
        validation: this.final_validation_array,
        placeholder: this.placeholder,
        help: this.help,
      };
    }

    if (this.type === "date") {
      conditionalObjectBuild = {
        //we don't need classes, just the attributes
        type: this.type,
        label: this.label,
        name: this.name,
        help: this.help,
        validation: this.final_validation_array,
        validation_messages: this.validation_messages,
        min: this.min_attribute,
        step: this.step,
        max: this.max_attribute,
      };
    }

    if (this.type === "time") {
      conditionalObjectBuild = {
        //we don't need classes, just the attributes
        type: this.type,
        label: this.label,
        name: this.name,
        help: this.help,
        validation: this.final_validation_array,
        validation_messages: this.validation_messages,
        min: this.min_attribute,
        step: this.step,
        max: this.max_attribute,
      };
    }

    if (this.type === "datetime-local") {
      conditionalObjectBuild = {
        //we don't need classes, just the attributes
        type: this.type,
        label: this.label,
        name: this.name,
        help: this.help,
        validation: this.final_validation_array,
        validation_messages: this.validation_messages,
        min: this.min_attribute,
        step: this.step,
        max: this.max_attribute,
      };
    }

    if (this.type === "date-picker") {
      conditionalObjectBuild = {
        //we don't need classes, just the attributes
        type: this.type,
        label: this.label,
        name: this.name,
        help: this.help,
        validation: this.final_validation_array,
        validation_messages: this.validation_messages,
      };
    }

    if (this.type === "datetime-picker") {
      conditionalObjectBuild = {
        //we don't need classes, just the attributes
        type: this.type,
        label: this.label,
        name: this.name,
        help: this.help,
        validation: this.final_validation_array,
        validation_messages: this.validation_messages,
      };
    }

    if (this.type === "time-picker") {
      conditionalObjectBuild = {
        //we don't need classes, just the attributes
        type: this.type,
        label: this.label,
        name: this.name,
        help: this.help,
        validation: this.final_validation_array,
        validation_messages: this.validation_messages,
      };
    }

    if (this.type === "slider") {
      conditionalObjectBuild = {
        //we don't need classes, just the attributes
        type: this.type,
        label: this.label,
        name: this.name,
        help: this.help,
        validation: this.final_validation_array,
        validation_messages: this.validation_messages,
        min: this.min_attribute,
        step: this.step,
        max: this.max_attribute,
      };
    }

    if (this.type === "dropdown") {
      conditionalObjectBuild = {
        //we don't need classes, just the attributes
        type: this.type,
        label: this.label,
        name: this.name,
        help: this.help,
        validation: this.final_validation_array,
        validation_messages: this.validation_messages,
        options: this.options,
      };
    }

    if (this.type === "dropdown-multi") {
      conditionalObjectBuild = {
        //we don't need classes, just the attributes
        type: this.type,
        label: this.label,
        name: this.name,
        help: this.help,
        validation: this.final_validation_array,
        validation_messages: this.validation_messages,
        options: this.options,
      };
    }

    //taglist
    if (this.type === "taglist") {
      conditionalObjectBuild = {
        //we don't need classes, just the attributes
        type: this.type,
        label: this.label,
        name: this.name,
        help: this.help,
        validation: this.final_validation_array,
        validation_messages: this.validation_messages,
        options: this.options,
      };
    }

    if (this.type === "select") {
      conditionalObjectBuild = {
        //we don't need classes, just the attributes
        type: this.type,
        label: this.label,
        name: this.name,
        help: this.help,
        validation: this.final_validation_array,
        validation_messages: this.validation_messages,
        options: this.options,
      };
    }

    if (this.type === "checkbox") {
      conditionalObjectBuild = {
        //we don't need classes, just the attributes
        type: this.type,
        label: this.label,
        name: this.name,
        help: this.help,
        validation: this.final_validation_array,
        validation_messages: this.validation_messages,
        options: this.options,
      };
    }

    if (this.type === "radio") {
      conditionalObjectBuild = {
        //we don't need classes, just the attributes
        type: this.type,
        label: this.label,
        name: this.name,
        help: this.help,
        validation: this.final_validation_array,
        validation_messages: this.validation_messages,
        options: this.options,
      };
    }

    //mask
    if (this.type === "mask") {
      conditionalObjectBuild = {
        //we don't need classes, just the attributes
        type: this.type,
        label: this.label,
        name: this.name,
        placeholder: this.placeholder,
        help: this.help,
        validation: this.final_validation_array,
        validation_messages: this.validation_messages,
        mask: this.mask,
      };
    }

    if (this.type === "color") {
      conditionalObjectBuild = {
        //we don't need classes, just the attributes
        type: this.type,
        label: this.label,
        name: this.name,
        placeholder: this.placeholder,
        help: this.help,
        validation: this.final_validation_array,
        validation_messages: this.validation_messages,
      };
    }

    return conditionalObjectBuild;
  },
});

const validation_messages_dynamic = computed(() => {
  let messages = {};

  if (currentlyCreatingField.attributes.includes("required")) {
    messages.required = "This field is required";
  }

  if (
    currentlyCreatingField.min_length === "" ||
    currentlyCreatingField.max_length === ""
  ) {
    delete messages.length;
  } else {
    messages.length = `Must be between ${currentlyCreatingField.min_length} and ${currentlyCreatingField.max_length} ${["dropdown-muli", "checkbox", "taglist"].includes(currentlyCreatingField.type) ? "options" : ["text", "textarea"].includes(currentlyCreatingField.type) ? "characters" : ["dropdown-muli", "checkbox"].includes(currentlyCreatingField.type)}`;
  }

  if (currentlyCreatingField.min_value === "") {
    delete messages.min;
  } else {
    messages.min = `Must be greater than ${currentlyCreatingField.min_value}`;
  }

  if (currentlyCreatingField.max_value === "") {
    delete messages.max;
  } else {
    messages.max = `Must be less than ${currentlyCreatingField.max_value}`;
  }

  if (
    currentlyCreatingField.regex === "" ||
    currentlyCreatingField.type !== "text"
  ) {
    delete messages.matches;
  } else {
    messages.matches = `Please enter a valid format`;
  }

  currentlyCreatingField.validation_messages = messages;

  return messages;
});

const field_option_types = ref([
  {
    value: "",
    label: "- Choose Type -",
  },
  {
    value: "separator",
    label: "Separator (Preview Only)",
  },
  {
    value: "text",
    label: "Text",
  },
  {
    value: "textarea",
    label: "Paragraph",
  },
  {
    value: "number",
    label: "Number",
  },
  {
    value: "currency",
    label: "Currency - (Coming Soon)",
    attrs: {
      disabled: true,
    },
  },
  {
    value: "email",
    label: "Email",
  },
  {
    value: "tel",
    label: "Phone",
  },
  {
    value: "toggle",
    label: "Toggle",
  },
  {
    value: "date",
    label: "Date (Native)",
  },

  {
    value: "time",
    label: "Time (Native)",
  },
  {
    value: "datetime-local",
    label: "Datetime Local (Native) - (Coming Soon)",
    attrs: {
      disabled: true,
    },
  },
  {
    value: "date-picker",
    label: "Date Picker",
  },
  {
    value: "datetime-picker",
    label: "Datetime Picker",
  },
  {
    value: "time-picker",
    label: "Time Picker",
  },

  {
    value: "slider",
    label: "Slider",
  },
  {
    value: "dropdown",
    label: "Dropdown",
  },
  {
    value: "dropdown-multi",
    label: "Dropdown Multi Select",
  },
  {
    value: "select",
    label: "Select",
  },
  {
    value: "checkbox",
    label: "Checkbox",
  },
  {
    value: "radio",
    label: "Radio",
  },
  {
    value: "color",
    label: "Color Picker",
  },
  {
    value: "taglist",
    label: "Tag List",
  },
  {
    value: "mask",
    label: "Mask",
  },
  {
    value: "hidden",
    label: "Hidden - (Coming Soon)",
    attrs: {
      disabled: true,
    },
  },
  {
    value: "file",
    label: "File - (Coming Soon)",
    attrs: {
      disabled: true,
    },
  },
]);

const previewField = ref(true); //this allows us to re-render the preview field as the changes are being made

const systemFields = ref();
const customFields = ref();

const customFormName = ref("");
const customFormType = ref("");
const form = ref([]);

const addNewFieldDynamic = (aboveOrBelow, index) => {
  if (aboveOrBelow === "above") {
    form.value.splice(index, 0, {
      type: "separator",
      title: "New Section",
      description: "Section Description",
    });
  } else {
    form.value.splice(index + 1, 0, {
      type: "separator",
      title: "New Section",
      description: "Section Description",
    });
  }
};

const addScriptBlockAboveOrBelow = (aboveOrBelow, index) => {
  if (aboveOrBelow === "above") {
    form.value.splice(index, 0, {
      type: "script",
      content: "Section Description",
    });
  } else {
    form.value.splice(index + 1, 0, {
      type: "script",
      content: "Section Description",
    });
  }
};

let currentFieldProperties = ref([]);

const currentField = (field) => {
  currentFieldProperties.value = field;

  console.log(currentFieldProperties.value);
};

const convertToSnakeCase = (input) => {
  // Replace spaces with underscores
  let result = input.replace(/\s+/g, "_");

  // Remove all non-alphanumeric characters except underscores
  result = result.replace(/[^a-zA-Z0-9_]/g, "");

  // Convert the string to lowercase
  result = result.toLowerCase();

  // Remove trailing underscores
  result = result.replace(/_+$/, "");

  return result;
};

//computed - clone of currentlyCreatingField
const currentlyCreatingFieldClone = computed(() => {
  return JSON.parse(JSON.stringify(currentlyCreatingField));
});

//allows resetting of the field preview
const fieldPreviewKey = ref(1);

//forces re-render of field preview
const incrementKey = () => {
  fieldPreviewKey.value++;
};

//attribute toggles
watch(
  attributeToggles,
  (newVal, oldVal) => {
    if (!newVal.min_max_length) {
      currentlyCreatingField.min_length = "";
      currentlyCreatingField.max_length = "";
      validation_messages_dynamic["length"] = "";
    } else {
      currentlyCreatingField.regex = "";
      validation_messages_dynamic["matches"] = "";
    }

    if (!newVal.min_max_value) {
      currentlyCreatingField.min_value = "";
      currentlyCreatingField.max_value = "";
      validation_messages_dynamic["min"] = "";
      validation_messages_dynamic["max"] = "";
    }

    if (!newVal.regex) {
      currentlyCreatingField.regex = "";
      validation_messages_dynamic["matches"] = "";
    } else {
      //reset the currentlyCreatingField.min_max_length to default values
      currentlyCreatingField.min_length = "";
      currentlyCreatingField.max_length = "";
      validation_messages_dynamic["length"] = "";
    }
  },
  { deep: true }
);

//watch currentlyCreatingFieldClone
let resetTimeout;
watch(
  currentlyCreatingFieldClone,
  (newVal, oldVal) => {
    //re-render the field block as the user makes changes
    if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
      //clear timer
      if (resetTimeout) {
        clearTimeout(resetTimeout);
      }

      //set timer
      resetTimeout = setTimeout(() => {
        //force re-render of preview field
        incrementKey();
      }, 1000);
    } else {
      console.log("Values are the same");
    }
  },
  { deep: true }
);

//function that creates the field
const createFormField = async () => {
  await tenantsForms.createFormField(
    currentlyCreatingField.build_conditional_final_object
  );

  //reset the field
  resetField();
};

//function to reset the currentlyCreatingField object to it's default state
const resetField = () => {
  currentlyCreatingField.type = "";
  currentlyCreatingField.label = "";
  currentlyCreatingField.placeholder = "";
  currentlyCreatingField.help = "";
  currentlyCreatingField.currency = "USD";
  currentlyCreatingField.display_locale = "";
  currentlyCreatingField.description = "";
  currentlyCreatingField.attributes = [];
  currentlyCreatingField.options = [];
  currentlyCreatingField.validation_messages = {};
  currentlyCreatingField.on_value = "On";
  currentlyCreatingField.off_value = "Off";
  currentlyCreatingField.min_attribute = "";
  currentlyCreatingField.step = 1;
  currentlyCreatingField.max_attribute = "";
  currentlyCreatingField.min_length = "";
  currentlyCreatingField.max_length = "";
  currentlyCreatingField.min_value = "";
  currentlyCreatingField.max_value = "";
  currentlyCreatingField.regex = "";
};

const submitTestFullForm = () => {
  return FKsubmitForm("test-custom-form");
};

//function on button that calls function to create form field
const submitCreateFieldForm = () => {
  return FKsubmitForm("create-field-form");
};

//test submit button - for validation checking
const submitTestFieldBtn = () => {
  return FKsubmitForm("submit-test-field");
};

//computed that returns a color based on the field type
const fieldTypeColor = (type) => {
  if (type === "text") {
    return "bg-emerald-500";
  }

  if (type === "textarea") {
    return "bg-emerald-500";
  }

  if (type === "number") {
    return "bg-purple-500";
  }

  if (type === "currency") {
    return "bg-green-500";
  }

  if (type === "email") {
    return "bg-blue-500";
  }

  if (type === "tel") {
    return "bg-red-500";
  }

  if (type === "toggle") {
    return "bg-teal-500";
  }

  if (type === "date") {
    return "bg-sky-500";
  }

  if (type === "time") {
    return "bg-sky-500";
  }

  if (type === "datetime-local") {
    return "bg-sky-500";
  }

  if (type === "date-picker") {
    return "bg-blue-600";
  }

  if (type === "datetime-picker") {
    return "bg-blue-600";
  }

  if (type === "time-picker") {
    return "bg-blue-600";
  }

  if (type === "slider") {
    return "bg-indigo-500";
  }

  if (type === "dropdown") {
    return "bg-purple-500";
  }

  if (type === "dropdown-multi") {
    return "bg-purple-500";
  }

  if (type === "select") {
    return "bg-purple-500";
  }

  if (type === "checkbox") {
    return "bg-violet-600";
  }

  if (type === "radio") {
    return "bg-violet-600";
  }

  if (type === "color") {
    return "bg-gradient-to-r from-red-500 via-yellow-500 to-blue-500";
  }

  if (type === "taglist") {
    return "bg-violet-700";
  }

  if (type === "mask") {
    return "bg-white";
  }
};

//submit function to trigger formkit validation
const submitTestForm = () => {
  console.log("ALL VALIDATION PASSED - FIELD SUBMITTED");

  toastStore.addToast({
    title: "Validation Passed",
    buttonExists: false,
    color: "emerald",
    type: 1, // 1 = small, 2 = md, 3 = lg, 4 = lg dismissable
  });
};

//computed function that returns the tenantsForms.fetchedCustomFields filtered by the searched string and the filterByType options
const filteredCustomFields = computed(() => {
  // const filterByType = tenantsForms.filterByType;
  const searchField = tenantsForms.searchField.trim().toLowerCase();

  let filteredResults = [];

  if (customOrSystem.value === "custom") {
    //filter and return the objects that have system = false
    filteredResults = tenantsForms.fetchedCustomFields.filter(
      (field) => field.system === false
    );
  }

  if (customOrSystem.value === "system") {
    //filter and return the objects that have system = true
    filteredResults = tenantsForms.fetchedCustomFields.filter(
      (field) => field.system === true
    );
  }

  if (tenantsForms.filterByType.length !== 0) {
    console.log("THERES A FILTER");
    filteredResults = tenantsForms.fetchedCustomFields.filter((field) =>
      tenantsForms.filterByType.includes(field.type)
    );
  }

  if (tenantsForms.searchField.trim().toLowerCase().length > 0) {
    console.log("THERES A SEARCH");
    const filterRoute =
      filteredResults.length > 0
        ? filteredResults
        : tenantsForms.fetchedCustomFields;
    filteredResults = filterRoute.filter((field) =>
      field.label.toLowerCase().includes(searchField)
    );

    console.log("RESULTS: ", filteredResults);
  }

  //if no results are found, return the original fetchedCustomFields
  // if (filteredResults.length === 0) {
  //   return tenantsForms.fetchedCustomFields;
  // }

  return filteredResults;
});

//computed property that returns a random 4 digit number
const randomFourDigitNumber = () => {
  return `${Math.floor(1000 + Math.random() * 9000)}`;
};

const wipeFormEditor = async () => {
  promptStore.addPrompt({
    type: "wipe-form-editor",
    title: "Are You Sure?",
    message:
      "Canceling will wipe all progress in the form editor. Do you still wish to proceed?",
  });

  const promptResponse = await promptStore.waitForPromptConfirmation();

  if (promptResponse === true) {
    form.value = [];
    currentRebuttalsSet.value = [];

    promptStore.removePrompt();

    toastStore.addToast({
      title: "Form Editor Wiped",
      message: "The form editor has been wiped",
      buttonExists: false,
      color: "red",
      type: 1, // 1 = small, 2 = md, 3 = lg, 4 = lg dismissable
    });
  } else {
    promptStore.removePrompt();
  }
};

//function that sets every objects focused property to false and then sets the object with a specified index's focused property to true
const setFocusedField = (index) => {
  form.value.forEach((field, i) => {
    field.focused = false;
  });

  form.value[index].focused = true;
};

//computed that builds the final form object
const buildFinalFormObject = computed(() => {
  //remove the focused property from all fields
  form.value.forEach((field) => {
    if (field.type === "separator") {
      field.anchor =
        field.title
          .trim()
          .toLowerCase()
          .replace(/\s+/g, "_")
          .replace(/[^\w]+/g, "") +
        "_" +
        randomFourDigitNumber() +
        "_" +
        Date.now(); // Removes all characters except numbers, letters, and underscores
    }

    if (field.type === "script") {
      field.script_name =
        field.content
          .trim()
          .toLowerCase()
          .replace(/\s+/g, "_")
          .replace(/[^\w]+/g, "")
          .slice(0, 10) +
        "_" +
        randomFourDigitNumber() +
        "_" +
        Date.now(); // Removes all characters except numbers, letters, and underscores
    }

    delete field.focused;
  });

  let finalFormObject = {
    name: customFormName.value,
    type: customFormType.value,
    elements: form.value,
  };

  //if rebuttals are present, add them to the 'rebuttals' array property

  finalFormObject.rebuttals = currentRebuttalsSet.value;

  console.log("FINAL FORM OBJECT: ", finalFormObject);

  return finalFormObject;
});

const form_builder_mode = ref("create");

const createNewForm = async () => {
  if (form.value.length > 0) {
    promptStore.addPrompt({
      type: "load-company-form",
      title: "Start New Form?",
      message:
        "Are you sure you want to start a new form? You will loose all progress on your current form.",
    });

    const promptResponse = await promptStore.waitForPromptConfirmation();

    if (promptResponse === true) {
      form.value = [];

      customFormName.value = "";
      customFormType.value = "";
      form.value = [];

      promptStore.removePrompt();

      toastStore.addToast({
        title: "Fresh Form Ready For Building",
        buttonExists: false,
        color: "green",
        type: 2, // 1 = small, 2 = md, 3 = lg, 4 = lg dismissable
      });

      //switch mode to "edit"
      form_builder_mode.value = "create";
    } else {
      promptStore.removePrompt();
    }
  } else {
    form.value = [];
    await tenantsForms.readForm(formId);

    customFormName.value = tenantsForms.currentlyEditingForm.name;
    customFormType.value = tenantsForms.currentlyEditingForm.type;
    form.value = tenantsForms.currentlyEditingForm.elements;
    showCompanyForms.value = false;

    form_builder_mode.value = "create";
  }
};

const toggleCompanyFormsSidebar = async () => {
  showCompanyForms.value = true;
  await tenantsForms.readForms();
};

const loadCompanyForm = async (formId) => {
  if (form.value.length > 0) {
    promptStore.addPrompt({
      type: "load-company-form",
      title: "Load Company Form",
      message:
        "Are you sure you want to load this company form? You will loose all progress on your current form.",
    });

    const promptResponse = await promptStore.waitForPromptConfirmation();

    if (promptResponse === true) {
      form.value = [];
      await tenantsForms.readForm(formId);

      customFormName.value = tenantsForms.currentlyEditingForm.name;
      customFormType.value = tenantsForms.currentlyEditingForm.type;
      form.value = tenantsForms.currentlyEditingForm.elements;

      promptStore.removePrompt();
      showCompanyForms.value = false;
      //return map with id, name, color, rebuttal_id
      currentRebuttalsSet.value = tenantsForms.currentlyEditingForm.rebuttals;

      toastStore.addToast({
        title: "Company Form Ready to Edit",

        buttonExists: false,
        color: "green",
        type: 2, // 1 = small, 2 = md, 3 = lg, 4 = lg dismissable
      });

      //switch mode to "edit"
      tenantsForms.currentlyEditingFormId = formId;
      form_builder_mode.value = "edit";
    } else {
      promptStore.removePrompt();
    }
  } else {
    form.value = [];
    await tenantsForms.readForm(formId);

    customFormName.value = tenantsForms.currentlyEditingForm.name;
    customFormType.value = tenantsForms.currentlyEditingForm.type;
    form.value = tenantsForms.currentlyEditingForm.elements;
    showCompanyForms.value = false;

    currentRebuttalsSet.value = tenantsForms.currentlyEditingForm.rebuttals;

    tenantsForms.currentlyEditingFormId = formId;
    form_builder_mode.value = "edit";
  }
};

const deleteForm = async (formId) => {
  promptStore.addPrompt({
    type: "delete-form",
    title: "Delete Form?",
    message: "Are you sure you want to delete this form?",
  });

  const promptResponse = await promptStore.waitForPromptConfirmation();

  if (promptResponse === true) {
    tenantsForms.deleteForm(formId);

    form.value = [];
    customFormName.value = "";
    customFormType.value = "";

    promptStore.removePrompt();

    toastStore.addToast({
      title: "Form Deleted",
      message: "The form has been deleted",
      buttonExists: false,
      color: "red",
      type: 1, // 1 = small, 2 = md, 3 = lg, 4 = lg dismissable
    });

    form_builder_mode.value = "create";
  } else {
    promptStore.removePrompt();
  }
};

const testCustomFormSubmit = () => {
  toastStore.addToast({
    title: "Form Submitted",
    description: "Your Form Pass Validation and has been submitted",
    buttonExists: false,
    color: "green",
    type: 3, // 1 = small, 2 = md, 3 = lg, 4 = lg dismissable
  });
};

const submitFormEditorForm2 = () => {
  if (form_builder_mode.value === "create") {
    tenantsForms.createForm(buildFinalFormObject.value);
  }

  if (form_builder_mode.value === "edit") {
    tenantsForms.updateForm(
      tenantsForms.currentlyEditingFormId,
      buildFinalFormObject.value
    );
  }
};

const submitFormEditorForm = () => {
  return FKsubmitForm("form-editor-form");
};

const fetchedRebuttals = ref([
  {
    id: "abc123",
    title: "Rebuttal 1",
  },
]);

const currentRebuttalsSet = ref([]);

const fetchedRebuttalsComputed = computed(() => {
  return fetchedRebuttals.value;
});

//computed that returns the rebuttalsStore.tenantsRebuttalsButtonsOnlyList array map with id, name, color
const tenantsRebuttalsButtonsOnlyList = computed(() => {
  return rebuttalsStore?.tenantsRebuttalsButtonsOnlyList.map((rebuttal) => {
    return {
      rebuttal_id: rebuttal.id,
      name: rebuttal.name,
      color: rebuttal.color,
    };
  });
});
</script>

<template>
  <Teleport to="#modals-target">
    <transition
      enter-active-class="transition-opacity duration-500 ease-in-out"
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="transition-opacity duration-500 ease-in-out"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div
        @click.self="tenantsForms.closeFormFieldAdderModal"
        v-if="tenantsForms.showFormFieldAdderModal"
        class="fixed h-screen w-screen backdrop-brightness-50 z-50 md:p-10 flex flex-col justify-center items-center"
      >
        <div
          v-if="tenantsForms.showFormFieldAdderModal"
          class="h-full w-full flex flex-col bg-base-100 rounded md:border border-slate-400"
        >
          <div class="text-white text-lg p-5 flex flex-row items-center">
            <h1>Create A field</h1>
            <button
              @click="tenantsForms.closeFormFieldAdderModal"
              class="ml-auto btn btn-sm border border-slate-400 text-gray-50"
            >
              <font-awesome-icon :icon="['fas', 'xmark']" />
            </button>
          </div>

          <FormKit
            id="create-field-form"
            type="form"
            :incomplete-message="false"
            submit-label="Create"
            form-class="text-white flex flex-col justify-between flex-1 max-h-full overflow-hidden border-t border-t-slate-400"
            @submit.self="createFormField"
            outer-class="m-0"
            :actions="false"
            :submit-attrs="{
              'data-theme': `light`,
              ignore: true,
            }"
          >
            <div
              class="flex flex-col md:flex-row flex-1 max-h-full max-md:overflow-y-auto"
            >
              <!-- LEFT SIDEBAR SETTINGS -->
              <div
                class="flex flex-col w-full md:w-96 gap-5 border-r border-r-slate-400 px-5 pb-5 bg-base-100 max-h-full h-full md:overflow-y-auto"
              >
                <FormKit
                  v-model="currentlyCreatingField.type"
                  type="dropdown"
                  name="Field Type"
                  label="Choose your field type"
                  placeholder="Choose Field Type"
                  :validation="[['required']]"
                  listitem-class="bg-base-300 hover:bg-gray-500"
                  selector-class="bg-gray-50 dropdown-lg"
                  dropdown-wrapper-class="border border-slate-400"
                  :options="field_option_types"
                />
                <FormKit
                  v-if="
                    currentlyCreatingField.type !== '' &&
                    currentlyCreatingField.type !== 'separator'
                  "
                  type="text"
                  label="Label"
                  placeholder=""
                  :validation="[['required'], ['length', 1, 1000]]"
                  help-class="text-white"
                  help="This will be the label the user sees"
                  outer-class="m-0 "
                  input-class="$reset rounded-sm w-full input input-sm text-gray-700 bg-gray-50"
                  wrapper-class="max-w-full"
                  v-model.trim="currentlyCreatingField.label"
                />

                <FormKit
                  v-if="
                    currentlyCreatingField.type !== '' &&
                    currentlyCreatingField.type !== 'select' &&
                    currentlyCreatingField.type !== 'radio' &&
                    currentlyCreatingField.type !== 'checkbox' &&
                    currentlyCreatingField.type !== 'hidden' &&
                    currentlyCreatingField.type !== 'taglist' &&
                    currentlyCreatingField.type !== 'dropdown' &&
                    currentlyCreatingField.type !== 'color' &&
                    currentlyCreatingField.type !== 'slider' &&
                    currentlyCreatingField.type !== 'toggle' &&
                    currentlyCreatingField.type !== 'date-picker' &&
                    currentlyCreatingField.type !== 'datetime-picker' &&
                    currentlyCreatingField.type !== 'time-picker' &&
                    currentlyCreatingField.type !== 'date' &&
                    currentlyCreatingField.type !== 'time' &&
                    currentlyCreatingField.type !== 'datetime-local' &&
                    currentlyCreatingField.type !== 'mask' &&
                    currentlyCreatingField.type !== 'separator'
                  "
                  type="text"
                  label="Placeholder"
                  :validation="[['length', 1, 50]]"
                  help-class="text-white"
                  help="Dimmed example input within field"
                  outer-class="m-0 "
                  input-class="$reset rounded-sm w-full input input-sm text-gray-700 bg-gray-50"
                  wrapper-class="max-w-full"
                  v-model.trim="currentlyCreatingField.placeholder"
                />

                <FormKit
                  v-if="
                    currentlyCreatingField.type !== '' &&
                    currentlyCreatingField.type !== 'separator'
                  "
                  type="text"
                  label="Help Description"
                  placeholder=""
                  :validation="[['length', 5, 40]]"
                  help-class="text-white"
                  help="This will give the input a help description below it"
                  outer-class="m-0 "
                  input-class="$reset rounded-sm w-full input input-sm text-gray-700 bg-gray-50"
                  wrapper-class="max-w-full"
                  v-model.trim="currentlyCreatingField.help"
                />

                <FormKit
                  v-if="
                    currentlyCreatingField.type !== '' &&
                    currentlyCreatingField.type !== 'separator'
                  "
                  type="textarea"
                  label="Description"
                  rows="1"
                  placeholder=""
                  :validation="[['length', 5, 255]]"
                  help-class="text-white"
                  help="(Recommended) Helps to track the purpose of the field"
                  outer-class="m-0 "
                  input-class="$reset rounded-sm w-full textarea-lg text-gray-700 bg-gray-50 p-2"
                  wrapper-class="max-w-full"
                  v-model="currentlyCreatingField.description"
                />

                <FormKit
                  v-if="
                    currentlyCreatingField.type !== '' &&
                    currentlyCreatingField.type !== 'separator'
                  "
                  type="checkbox"
                  help-class="text-white"
                  :options="attribute_checkboxes"
                  v-model="currentlyCreatingField.attributes"
                />

                <div
                  v-if="
                    currentlyCreatingField.type === 'number' ||
                    currentlyCreatingField.type === 'date' ||
                    currentlyCreatingField.type === 'time' ||
                    currentlyCreatingField.type === 'datetime-local' ||
                    currentlyCreatingField.type === 'slider'
                  "
                  class="flex flex-col gap-2 p-2 border border-slate-400"
                >
                  <FormKit
                    :type="
                      currentlyCreatingField.type === 'number' ||
                      currentlyCreatingField.type === 'slider'
                        ? 'number'
                        : currentlyCreatingField.type === 'date'
                          ? 'date'
                          : currentlyCreatingField.type === 'datetime-local'
                            ? 'datetime-local'
                            : currentlyCreatingField.type === 'time'
                              ? 'time'
                              : 'number'
                    "
                    label="Min"
                    placeholder=""
                    :validation="
                      (() => {
                        const baseValidation = [['length', 1, 50]];
                        return currentlyCreatingField.type === 'slider'
                          ? [['required'], ...baseValidation]
                          : baseValidation;
                      })()
                    "
                    help-class="text-white"
                    :help="
                      currentlyCreatingField.type === 'number'
                        ? 'Gives your number input a minimum value'
                        : currentlyCreatingField.type === 'slider'
                          ? 'Gives your slider input a minimum value'
                          : currentlyCreatingField.type === 'date'
                            ? 'A date minimum value'
                            : currentlyCreatingField.type === 'datetime-local'
                              ? 'Gives datetime-local inputs a custom minimum value'
                              : currentlyCreatingField.type === 'time'
                                ? 'Gives time inputs a custom minimum value'
                                : 'Gives number / slider inputs a custom minimum value'
                    "
                    outer-class="m-0"
                    input-class="$reset rounded-sm w-full input input-sm text-gray-700 bg-gray-50"
                    wrapper-class="max-w-full"
                    v-model.trim="currentlyCreatingField.min_attribute"
                  />
                  <FormKit
                    type="number"
                    label="Step Increment"
                    placeholder=""
                    :validation="'length:1, 50'"
                    help-class="text-white"
                    :help="
                      currentlyCreatingField.type === 'number'
                        ? 'Gives your number input a step increment'
                        : currentlyCreatingField.type === 'slider'
                          ? 'Gives your slider input a step increment'
                          : currentlyCreatingField.type === 'date'
                            ? 'A date step increment (Ex: 7 = 1 Week Increments)'
                            : currentlyCreatingField.type === 'datetime-local'
                              ? 'Gives datetime-local inputs a custom step increment'
                              : currentlyCreatingField.type === 'time'
                                ? 'Gives time inputs a custom step increment'
                                : 'Gives number / slider inputs a custom step increment'
                    "
                    outer-class="m-0 "
                    input-class="$reset rounded-sm w-full input input-sm text-gray-700 bg-gray-50"
                    wrapper-class="max-w-full"
                    v-model.trim="currentlyCreatingField.step"
                  />
                  <FormKit
                    :type="
                      currentlyCreatingField.type === 'number' ||
                      currentlyCreatingField.type === 'slider'
                        ? 'number'
                        : currentlyCreatingField.type === 'date'
                          ? 'date'
                          : currentlyCreatingField.type === 'datetime-local'
                            ? 'datetime-local'
                            : currentlyCreatingField.type === 'time'
                              ? 'time'
                              : 'number'
                    "
                    label="Max"
                    placeholder=""
                    :validation="
                      (() => {
                        const baseValidation = [['length', 1, 50]];
                        return currentlyCreatingField.type === 'slider'
                          ? [['required'], ...baseValidation]
                          : baseValidation;
                      })()
                    "
                    help-class="text-white"
                    :help="
                      currentlyCreatingField.type === 'number'
                        ? 'Gives your number input a maximum value'
                        : currentlyCreatingField.type === 'slider'
                          ? 'Gives your slider input a maximum value'
                          : currentlyCreatingField.type === 'date'
                            ? 'A date maximum value'
                            : currentlyCreatingField.type === 'datetime-local'
                              ? 'Gives datetime-local inputs a custom maximum value'
                              : currentlyCreatingField.type === 'time'
                                ? 'Gives time inputs a custom maximum value'
                                : 'Gives number / slider inputs a custom maximum value'
                    "
                    outer-class="m-0 "
                    input-class="$reset rounded-sm w-full input input-sm text-gray-700 bg-gray-50"
                    wrapper-class="max-w-full"
                    v-model.trim="currentlyCreatingField.max_attribute"
                  />
                </div>

                <FormKit
                  v-if="currentlyCreatingField.type === 'mask'"
                  type="text"
                  :mask="currentlyCreatingField.mask"
                  label="Mask Pattern"
                  placeholder="Ex: ###-###-#### or (###) ###-####"
                  :validation="[['required'], ['length', 1, 50]]"
                  help-class="text-white"
                  help="Tokens: h | # | a | *"
                  outer-class="m-0 "
                  input-class="$reset rounded-sm w-full input input-sm text-gray-700 bg-gray-50"
                  wrapper-class="max-w-full"
                  v-model.trim="currentlyCreatingField.mask"
                />

                <div
                  v-if="
                    currentlyCreatingField.type === 'select' ||
                    currentlyCreatingField.type === 'checkbox' ||
                    currentlyCreatingField.type === 'radio' ||
                    currentlyCreatingField.type === 'taglist' ||
                    currentlyCreatingField.type === 'dropdown' ||
                    currentlyCreatingField.type === 'dropdown-multi'
                  "
                  class="flex flex-col border border-slate-400 rounded"
                >
                  <div
                    class="p-2 flex flex-row items-center sticky top-0 z-[1] bg-base-100"
                  >
                    <h1>Dropdown Options</h1>
                    <button
                      type="button"
                      @click="
                        currentlyCreatingField.options.push({
                          value: '',
                          label: '',
                        })
                      "
                      class="btn btn-sm bg-base-300 hover:bg-black text-gray-50 border border-slate-400 ml-auto"
                    >
                      <font-awesome-icon :icon="['fas', 'plus']" />
                    </button>
                  </div>
                  <div>
                    <draggable
                      handle=".drag-handle"
                      v-model="currentlyCreatingField.options"
                      tag="div"
                      class="flex flex-col"
                      :animation="200"
                    >
                      <template #item="{ element: field, index }">
                        <div
                          :key="index"
                          class="p-2 flex flex-row items-end gap-2"
                        >
                          <button
                            class="btn drag-handle border border-slate-400 hover:bg-gray-500 text-gray-50"
                          >
                            <font-awesome-icon
                              :icon="['fas', 'grip-vertical']"
                            />
                          </button>

                          <!-- text input -->
                          <FormKit
                            v-model="
                              currentlyCreatingField.options[index].label
                            "
                            @keyup="returnSnakeCase(index)"
                            type="text"
                            label="Label"
                            placeholder=""
                            wrapper-class="flex-1 max-w-full"
                            outer-class="flex-1 m-0"
                            :validation="[['required'], ['length', 1, 255]]"
                            input-class="$reset rounded-sm w-full input input-sm text-gray-700 bg-gray-50"
                          />

                          <!-- text input -->
                          <FormKit
                            v-model="
                              currentlyCreatingField.options[index].value
                            "
                            type="text"
                            label="Value"
                            placeholder=""
                            wrapper-class="flex-1 max-w-full"
                            outer-class="flex-1 m-0"
                            :validation="[['required'], ['length', 1, 255]]"
                            input-class="$reset rounded-sm w-full input input-sm text-gray-700 bg-gray-50"
                          />

                          <!-- delete button -->
                          <button
                            type="button"
                            @click="
                              currentlyCreatingField.options.splice(index, 1)
                            "
                            class="btn bg-red-500 hover:bg-red-700 text-gray-50 border border-slate-400"
                          >
                            <font-awesome-icon :icon="['fas', 'trash-alt']" />
                          </button>
                        </div>
                      </template>
                    </draggable>
                  </div>
                </div>

                <!-- <pre>
                    {{
                    currentlyCreatingFieldClone.build_conditional_final_object
                  }}
                  </pre
                > -->
                <!-- <pre
                    {{ currentlyCreatingFieldClone }}
                  </pre
                >-->
              </div>
              <!-- RIGHT - PREVIEW -->
              <div class="flex-1 flex flex-col">
                <div class="flex-1 flex flex-col">
                  <div class="p-5 text-2xl">
                    <h1>Field Preview</h1>
                  </div>

                  <div
                    class="bg-gray-500 flex-1 flex flex-col items-center justify-center p-5"
                  >
                    <FormKit
                      v-if="currentlyCreatingFieldClone.type !== ''"
                      class="px-5 pb-5 flex flex-row"
                      id="submit-test-field"
                      type="form"
                      :incomplete-message="false"
                      submit-label="Create"
                      form-class=""
                      @submit="submitTestForm"
                      outer-class="m-0"
                      :actions="false"
                      :submit-attrs="{
                        'data-theme': `light`,
                        ignore: true,
                      }"
                    >
                      <!-- PREIVEW FIELD -->
                      <div
                        v-if="!previewField"
                        class="p-5 bg-emerald-500 text-white text-center rounded"
                      >
                        Ready to Create!
                      </div>
                      <div
                        v-if="previewField"
                        :key="fieldPreviewKey"
                        class="bg-slate-500 border border-slate-400 rounded w-full md:w-72 no-animation"
                      >
                        <div
                          v-if="currentlyCreatingField.type === 'separator'"
                          class="border-b border-b-slate-400 p-3"
                        >
                          <input
                            type="text"
                            class="input input-ghost w-full rounded-none"
                            placeholder="Section Name"
                            value=""
                          />
                          <input
                            type="text"
                            class="input input-xs input-ghost w-full rounded-none pl-4"
                            placeholder="Section Description"
                            value=""
                          />
                        </div>

                        <div
                          v-if="currentlyCreatingFieldClone.type === 'currency'"
                          class="flex flex-row items-center gap-5 p-5"
                        >
                          <FormKit
                            type="currency"
                            help-class="text-white"
                            wrapper-class="flex-1 max-w-full"
                            outer-class="flex-1 m-0"
                            input-class="$reset rounded-sm w-full input input-sm text-gray-700 bg-gray-50"
                            :label="
                              currentlyCreatingFieldClone.label ||
                              'My Currency Field'
                            "
                            :help="currentlyCreatingFieldClone.help"
                            :validation="
                              currentlyCreatingField.final_validation_array
                            "
                            :validation-messages="validation_messages_dynamic"
                            :min="
                              currentlyCreatingFieldClone.min_attribute || 0
                            "
                            :max="currentlyCreatingFieldClone.max_attribute"
                            step="0.0001"
                            :currency="currentlyCreatingFieldClone.currency"
                            :display-locale="currentlyCreatingFieldClone.locale"
                            decimals="4"
                            minDecimals="2"
                          />
                        </div>
                        <div
                          v-if="currentlyCreatingFieldClone.type === 'text'"
                          class="flex flex-row items-center gap-5 p-5"
                        >
                          <FormKit
                            type="text"
                            help-class="text-white"
                            wrapper-class="flex-1 max-w-full"
                            outer-class="flex-1 m-0"
                            input-class="$reset rounded-sm w-full input input-sm text-gray-700 bg-gray-50"
                            :label="currentlyCreatingFieldClone.label || 'Text'"
                            :help="currentlyCreatingFieldClone.help"
                            :placeholder="
                              currentlyCreatingFieldClone.placeholder
                            "
                            :validation="
                              currentlyCreatingField.final_validation_array
                            "
                            :validation-messages="validation_messages_dynamic"
                          />
                        </div>
                        <div
                          v-if="currentlyCreatingFieldClone.type === 'textarea'"
                          class="flex flex-row items-center gap-5 p-5"
                        >
                          <FormKit
                            type="textarea"
                            help-class="text-white"
                            wrapper-class="flex-1 max-w-full"
                            outer-class="flex-1 m-0"
                            input-class="$reset rounded-sm w-full textarea-lg text-gray-700 bg-gray-50 p-2"
                            :label="
                              currentlyCreatingFieldClone.label || 'Textarea'
                            "
                            :help="currentlyCreatingFieldClone.help"
                            :placeholder="
                              currentlyCreatingFieldClone.placeholder
                            "
                            :validation="
                              currentlyCreatingFieldClone.final_validation_array
                            "
                            :validation-messages="validation_messages_dynamic"
                          />
                        </div>
                        <div
                          v-if="currentlyCreatingFieldClone.type === 'number'"
                          class="flex flex-row items-center gap-5 p-5"
                        >
                          <FormKit
                            type="number"
                            help-class="text-white"
                            wrapper-class="flex-1 max-w-full"
                            outer-class="flex-1 m-0"
                            input-class="$reset rounded-sm w-full input input-sm text-gray-700 bg-gray-50"
                            :label="
                              currentlyCreatingFieldClone.label || 'Number'
                            "
                            :help="currentlyCreatingFieldClone.help"
                            :placeholder="
                              currentlyCreatingFieldClone.placeholder
                            "
                            :validation="[
                              ['number'],
                              ...currentlyCreatingFieldClone.final_validation_array,
                            ]"
                            :validation-messages="validation_messages_dynamic"
                            :min="currentlyCreatingFieldClone.min_attribute"
                            :step="currentlyCreatingFieldClone.step"
                            :max="currentlyCreatingFieldClone.max_attribute"
                          />
                        </div>
                        <div
                          v-if="currentlyCreatingFieldClone.type === 'email'"
                          class="flex flex-row items-center gap-5 p-5"
                        >
                          <FormKit
                            type="email"
                            help-class="text-white"
                            wrapper-class="flex-1 max-w-full"
                            outer-class="flex-1 m-0"
                            input-class="$reset rounded-sm w-full input input-sm text-gray-700 bg-gray-50"
                            :label="
                              currentlyCreatingFieldClone.label || 'Email'
                            "
                            :help="currentlyCreatingFieldClone.help"
                            :placeholder="
                              currentlyCreatingFieldClone.placeholder
                            "
                            :validation="[
                              ['email'],
                              ...currentlyCreatingFieldClone.final_validation_array,
                            ]"
                            :validation-messages="validation_messages_dynamic"
                          />
                        </div>
                        <div
                          v-if="currentlyCreatingFieldClone.type === 'tel'"
                          class="flex flex-row items-center gap-5 p-5"
                        >
                          <FormKit
                            type="tel"
                            help-class="text-white"
                            wrapper-class="flex-1 max-w-full"
                            outer-class="flex-1 m-0"
                            input-class="$reset rounded-sm w-full input input-sm text-gray-700 bg-gray-50"
                            :label="
                              currentlyCreatingFieldClone.label || 'Phone'
                            "
                            :help="currentlyCreatingFieldClone.help"
                            :placeholder="
                              currentlyCreatingFieldClone.placeholder
                            "
                            :validation="[
                              ['matches', /^\d{10}$/],
                              ...currentlyCreatingFieldClone.final_validation_array,
                            ]"
                            :validation-messages="[
                              {
                                matches: 'Must be a 10 digit number',
                              },
                            ]"
                          />
                        </div>
                        <div
                          v-if="currentlyCreatingFieldClone.type === 'toggle'"
                          class="flex flex-row items-center gap-5 p-5"
                        >
                          <FormKit
                            type="toggle"
                            help-class="text-white"
                            wrapper-class="flex-1 max-w-full"
                            outer-class="flex-1 m-0"
                            input-class="$reset rounded-sm w-full input input-sm text-gray-700 bg-gray-50"
                            :label="
                              currentlyCreatingFieldClone.label || 'My Toggle'
                            "
                            :help="currentlyCreatingFieldClone.help"
                            :validation="
                              currentlyCreatingField.final_validation_array
                            "
                            :validation-messages="validation_messages_dynamic"
                            :on-value="currentlyCreatingFieldClone.on_value"
                            :off-value="currentlyCreatingFieldClone.off_value"
                          />
                        </div>
                        <div
                          v-if="currentlyCreatingFieldClone.type === 'date'"
                          class="flex flex-row items-center gap-5 p-5"
                        >
                          <FormKit
                            type="date"
                            help-class="text-white"
                            wrapper-class="flex-1 max-w-full"
                            outer-class="flex-1 m-0"
                            input-class="rounded-sm w-full p-2 bg-base-100 text-white"
                            :label="currentlyCreatingFieldClone.label || 'Date'"
                            :help="currentlyCreatingFieldClone.help"
                            :validation="
                              currentlyCreatingFieldClone.final_validation_array
                            "
                            :validation-messages="validation_messages_dynamic"
                            :min="currentlyCreatingFieldClone.min_attribute"
                            :step="currentlyCreatingFieldClone.step"
                            :max="currentlyCreatingFieldClone.max_attribute"
                          />
                        </div>
                        <div
                          v-if="currentlyCreatingFieldClone.type === 'time'"
                          class="flex flex-row items-center gap-5 p-5"
                        >
                          <FormKit
                            help-class="text-white"
                            type="time"
                            wrapper-class="flex-1 max-w-full"
                            outer-class="flex-1 m-0"
                            input-class="$reset rounded-sm input input-sm w-full bg-base-100 text-white"
                            :label="currentlyCreatingFieldClone.label || 'Time'"
                            :help="currentlyCreatingFieldClone.help"
                            :validation="
                              currentlyCreatingFieldClone.final_validation_array
                            "
                            :validation-messages="validation_messages_dynamic"
                            :min="currentlyCreatingFieldClone.min_attribute"
                            :step="currentlyCreatingFieldClone.step"
                            :max="currentlyCreatingFieldClone.max_attribute"
                          />
                        </div>
                        <div
                          v-if="
                            currentlyCreatingFieldClone.type === 'date-picker'
                          "
                          class="flex flex-row items-center gap-5 p-5"
                        >
                          <FormKit
                            type="datepicker"
                            help-class="text-white"
                            label-class="text-gray-50"
                            wrapper-class="flex-1 max-w-full text-gray-700"
                            outer-class="flex-1 m-0"
                            input-class="rounded-sm w-full p-2 bg-gray-50"
                            open-button-class="bg-gray-50"
                            format="MM/DD/YYYY"
                            time-input-class="text-white"
                            time-class="text-gray-600"
                            :label="
                              currentlyCreatingFieldClone.label || 'Date Picker'
                            "
                            :help="currentlyCreatingFieldClone.help"
                            :validation="
                              currentlyCreatingFieldClone.final_validation_array
                            "
                            :validation-messages="validation_messages_dynamic"
                            :sequence="['day']"
                          />
                        </div>
                        <div
                          v-if="
                            currentlyCreatingFieldClone.type ===
                            'datetime-picker'
                          "
                          class="flex flex-row items-center gap-5 p-5"
                        >
                          <FormKit
                            type="datepicker"
                            help-class="text-white"
                            label-class="text-gray-50"
                            wrapper-class="flex-1 max-w-full text-gray-700"
                            outer-class="flex-1 m-0"
                            input-class="rounded-sm w-full p-2 bg-gray-50"
                            open-button-class="bg-gray-50"
                            time-input-class="text-white"
                            time-class="text-gray-600"
                            :label="
                              currentlyCreatingFieldClone.label ||
                              'Datetime Picker'
                            "
                            :help="currentlyCreatingFieldClone.help"
                            :validation="
                              currentlyCreatingFieldClone.final_validation_array
                            "
                            :validation-messages="validation_messages_dynamic"
                            :min="currentlyCreatingFieldClone.min_attribute"
                            :step="currentlyCreatingFieldClone.step"
                            :max="currentlyCreatingFieldClone.max_attribute"
                            :format="{ date: 'short', time: 'short' }"
                            :sequence="['day', 'time']"
                          />
                        </div>
                        <div
                          v-if="
                            currentlyCreatingFieldClone.type === 'time-picker'
                          "
                          class="flex flex-row items-center gap-5 p-5"
                        >
                          <FormKit
                            type="datepicker"
                            help-class="text-white"
                            label-class="text-gray-50"
                            wrapper-class="flex-1 max-w-full text-gray-700"
                            outer-class="flex-1 m-0"
                            input-class="rounded-sm w-full p-2 bg-gray-50"
                            open-button-class="bg-gray-50"
                            format="hh:mm A"
                            time-input-class="text-white"
                            time-class="text-gray-600"
                            :help="currentlyCreatingFieldClone.help"
                            :label="
                              currentlyCreatingFieldClone.label || 'Time Picker'
                            "
                            :validation="
                              currentlyCreatingFieldClone.final_validation_array
                            "
                            :validation-messages="validation_messages_dynamic"
                            :min="currentlyCreatingFieldClone.min_attribute"
                            :step="currentlyCreatingFieldClone.step"
                            :max="currentlyCreatingFieldClone.max_attribute"
                            :sequence="['time']"
                            picker-only
                            panel-header-class="$reset hidden"
                          />
                          <!-- LEFTOFF -->
                        </div>

                        <div
                          v-if="
                            currentlyCreatingFieldClone.type ===
                            'datetime-local'
                          "
                          class="flex flex-row items-center gap-5 p-5"
                        >
                          <FormKit
                            help-class="text-white"
                            type="datetime-local"
                            placeholder=""
                            wrapper-class="flex-1 max-w-full"
                            outer-class="flex-1 m-0"
                            input-class="$reset rounded-sm w-full input input-sm bg-base-100 text-gray-50"
                            :help="currentlyCreatingFieldClone.help"
                            :label="
                              currentlyCreatingFieldClone.label ||
                              'Datetime Local'
                            "
                            :validation="
                              currentlyCreatingFieldClone.final_validation_array
                            "
                            :validation-messages="validation_messages_dynamic"
                            :min="currentlyCreatingFieldClone.min_attribute"
                            :step="currentlyCreatingFieldClone.step"
                            :max="currentlyCreatingFieldClone.max_attribute"
                          />
                        </div>

                        <div
                          v-if="currentlyCreatingFieldClone.type === 'slider'"
                          class="flex flex-row items-center gap-5 p-5"
                        >
                          <FormKit
                            type="slider"
                            :label="
                              currentlyCreatingFieldClone.label || 'Slider'
                            "
                            :validation="
                              currentlyCreatingFieldClone.final_validation_array
                            "
                            :min="currentlyCreatingFieldClone.min_attribute"
                            :step="currentlyCreatingFieldClone.step"
                            :max="currentlyCreatingFieldClone.max_attribute"
                            :help="currentlyCreatingFieldClone.help"
                            help-class="text-white"
                            outer-class="flex-1 m-0 max-w-full"
                          />
                        </div>
                        <div
                          v-if="currentlyCreatingFieldClone.type === 'dropdown'"
                          class="flex flex-row items-center gap-5 p-5"
                        >
                          <FormKit
                            type="dropdown"
                            outer-class="flex-1 m-0 max-w-full"
                            input-class="$reset rounded-sm w-full text-gray-700 bg-gray-50"
                            listitem-class="bg-base-300 hover:bg-gray-500"
                            selector-class="bg-gray-50 dropdown-lg"
                            dropdown-wrapper-class="border border-slate-400"
                            :label="
                              currentlyCreatingFieldClone.label || 'Dropdown'
                            "
                            :help="currentlyCreatingFieldClone.help"
                            :options="currentlyCreatingFieldClone.options"
                            :validation="
                              currentlyCreatingFieldClone.final_validation_array
                            "
                            :validation-messages="validation_messages_dynamic"
                          />
                        </div>
                        <div
                          v-if="
                            currentlyCreatingFieldClone.type ===
                            'dropdown-multi'
                          "
                          class="flex flex-row items-center gap-5 p-5"
                        >
                          <FormKit
                            type="dropdown"
                            outer-class="flex-1 m-0 max-w-full"
                            input-class="$reset rounded-sm w-full text-gray-700 bg-gray-50"
                            listitem-class="bg-base-300 hover:bg-gray-500"
                            selector-class="bg-gray-50 dropdown-lg"
                            dropdown-wrapper-class="border border-slate-400"
                            :label="
                              currentlyCreatingFieldClone.label ||
                              'Dropdown Multi Select'
                            "
                            :help="currentlyCreatingFieldClone.help"
                            :options="currentlyCreatingFieldClone.options"
                            :validation="
                              currentlyCreatingFieldClone.final_validation_array
                            "
                            :validation-messages="validation_messages_dynamic"
                            multiple
                          />
                        </div>
                        <div
                          v-if="currentlyCreatingFieldClone.type === 'select'"
                          class="flex flex-row items-center gap-5 p-5"
                        >
                          <FormKit
                            type="select"
                            help-class="text-white"
                            wrapper-class="flex-1 max-w-full"
                            outer-class="flex-1 m-0"
                            input-class="$reset rounded-sm w-full input input-sm text-gray-700 bg-gray-50"
                            :label="
                              currentlyCreatingFieldClone.label || 'Select'
                            "
                            :help="currentlyCreatingFieldClone.help"
                            :validation="
                              currentlyCreatingFieldClone.final_validation_array
                            "
                            :validation-messages="validation_messages_dynamic"
                            :options="currentlyCreatingFieldClone.options"
                          />
                        </div>

                        <!-- checkbox -->
                        <div
                          v-if="currentlyCreatingFieldClone.type === 'checkbox'"
                          class="flex flex-row items-center gap-5 p-5"
                        >
                          <FormKit
                            type="checkbox"
                            help-class="text-white"
                            wrapper-class="flex-1 max-w-full"
                            outer-class="flex-1 m-0"
                            input-class="w-full text-gray-700 bg-gray-50"
                            :label="
                              currentlyCreatingFieldClone.label || 'Checkbox'
                            "
                            :help="currentlyCreatingFieldClone.help"
                            :validation="
                              currentlyCreatingFieldClone.final_validation_array
                            "
                            :validation-messages="validation_messages_dynamic"
                            :options="currentlyCreatingFieldClone.options"
                          />
                        </div>

                        <!-- radio -->
                        <div
                          v-if="currentlyCreatingFieldClone.type === 'radio'"
                          class="flex flex-row items-center gap-5 p-5"
                        >
                          <FormKit
                            :help="currentlyCreatingFieldClone.help"
                            help-class="text-white"
                            type="radio"
                            :label="
                              currentlyCreatingFieldClone.label ||
                              'Radio Buttons'
                            "
                            placeholder=""
                            wrapper-class="flex-1 max-w-full"
                            outer-class="flex-1 m-0"
                            :validation="
                              currentlyCreatingFieldClone.final_validation_array
                            "
                            :validation-messages="validation_messages_dynamic"
                            input-class="rounded-sm w-full text-gray-700 bg-gray-50"
                            :options="currentlyCreatingFieldClone.options"
                          />
                        </div>

                        <!-- color -->
                        <div
                          v-if="currentlyCreatingFieldClone.type === 'color'"
                          class="flex flex-row items-center gap-5 p-5"
                        >
                          <FormKit
                            :help="currentlyCreatingFieldClone.help"
                            help-class="text-white"
                            type="color"
                            :label="
                              currentlyCreatingFieldClone.label || 'Color'
                            "
                            wrapper-class="flex-1 max-w-full rounded-full"
                            outer-class="flex-1 m-0"
                            inner-class="$reset flex-1 max-w-full w-full border border-slate-400 rounded"
                            :validation="
                              currentlyCreatingFieldClone.final_validation_array
                            "
                            input-class="$reset w-full h-12 rounded-full text-gray-700 bg-gray-50 max-w-full"
                          />
                        </div>

                        <div
                          v-if="currentlyCreatingFieldClone.type === 'mask'"
                          class="flex flex-row items-center gap-5 p-5"
                        >
                          <FormKit
                            type="mask"
                            :mask="currentlyCreatingFieldClone.mask"
                            outer-class="flex-1 m-0"
                            :label="currentlyCreatingFieldClone.label || 'Mask'"
                            help-class="text-white"
                            input-class="$reset rounded-sm w-full input input-sm text-gray-700 bg-gray-50"
                            :help="currentlyCreatingFieldClone.help"
                          />
                        </div>
                        <div
                          v-if="currentlyCreatingFieldClone.type === 'taglist'"
                          class="flex flex-row items-center gap-5 p-5"
                        >
                          <FormKit
                            type="taglist"
                            :name="currentlyCreatingFieldClone.name"
                            outer-class="flex-1 m-0"
                            :label="
                              currentlyCreatingFieldClone.label || 'Tag List'
                            "
                            help-class="text-white"
                            selected-icon-class="border-r-2 border-r-black"
                            selector-class="bg-gray-50"
                            tags-class="bg-base-100"
                            tag-label-class="text-gray-600"
                            tag-class=""
                            tags-wrapper-class="bg-gray-50 text-white"
                            inner-class="bg-white"
                            select-icon-class="chevron-down text-gray-50 rounded-r border-l border-l-slate-400"
                            popover
                            input-class="rounded-sm w-full text-gray-700"
                            dropdown-wrapper-class="border border-slate-400"
                            listitem-class="bg-base-300 focus:bg-base-300 active:bg-blue-700 pl-7 hover:border-y-2 hover: border-r-2 hover:border-r-white hover:border-y-white"
                            option-class="bg-base-300 hover:border-y hover: border-r hover:border-r-white hover:border-y-white"
                            :options="currentlyCreatingFieldClone.options"
                            :filter="
                              (option, search) =>
                                option.label
                                  .toLowerCase()
                                  .startsWith(search.toLowerCase())
                            "
                            :help="currentlyCreatingFieldClone.help"
                            :validation="
                              currentlyCreatingFieldClone.final_validation_array
                            "
                            :validation-messages="validation_messages_dynamic"
                          />
                        </div>

                        <div
                          v-if="
                            currentlyCreatingFieldClone.type !== '' &&
                            currentlyCreatingFieldClone.type !== 'separator'
                          "
                          class="px-5 pb-5 flex flex-row"
                        >
                          <button
                            type="submit"
                            @click="submitTestFieldBtn"
                            class="btn capitalize text-gray-50 bg-blue-500 hover:bg-blue-700 border-none ml-auto"
                          >
                            Test Submit
                          </button>
                        </div>
                      </div>
                    </FormKit>
                    <div v-else>
                      <div
                        class="flex flex-row items-center gap-5 p-5 bg-blue-500 rounded"
                      >
                        <p class="text-xl text-center p-5">
                          Select a Field Type to Preview Field
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-if="
                    currentlyCreatingField.attributes.includes('required') &&
                    currentlyCreatingField.type !== 'tel'
                  "
                  class="flex flex-col"
                >
                  <div class="flex flex-col border-b border-b-slate-400">
                    <div class="flex flex-row items-center">
                      <p class="text-xl text-center p-5">Required</p>
                      <p class="">
                        - This field will be required to submit any form it is
                        within
                      </p>
                    </div>

                    <div class="px-5 pb-5">
                      <FormKit
                        type="text"
                        label="Required Validation Message"
                        placeholder="Required Validation Message"
                        wrapper-class="flex-1 max-w-full"
                        outer-class="flex-1 m-0"
                        :validation="[['required']]"
                        input-class="$reset rounded-sm w-full input input-sm text-gray-700 bg-gray-50"
                        v-model="
                          currentlyCreatingField.validation_messages.required
                        "
                        :value="
                          currentlyCreatingFieldClone.validation_messages[
                            'required'
                          ]
                        "
                      />
                    </div>
                  </div>
                </div>

                <div
                  v-if="currentlyCreatingField.type === 'toggle'"
                  class="flex flex-col border-b border-b-slate-400 gap-5 p-5"
                >
                  <div
                    v-if="attributeToggles.toggle_labels"
                    class="flex flex-col flex-1 gap-2"
                  >
                    <div class="flex-1 flex flex-row gap-5">
                      <FormKit
                        label="On Value"
                        type="text"
                        placeholder=""
                        wrapper-class="flex-1 max-w-full"
                        outer-class="flex-1 m-0"
                        :validation="[['required']]"
                        input-class="$reset rounded-sm w-full input input-sm text-gray-700 bg-gray-50"
                        v-model.trim="currentlyCreatingField.on_value"
                        :value="
                          currentlyCreatingField.type === 'toggle'
                            ? attributeToggles.toggle_labels
                              ? currentlyCreatingFieldClone.on_value
                              : 'On'
                            : null
                        "
                      />

                      <FormKit
                        label="Off Value"
                        type="text"
                        placeholder=""
                        wrapper-class="flex-1 max-w-full"
                        outer-class="flex-1 m-0"
                        :validation="[['required']]"
                        input-class="$reset rounded-sm w-full input input-sm text-gray-700 bg-gray-50"
                        v-model="currentlyCreatingField.off_value"
                        :value="
                          currentlyCreatingField.type === 'toggle'
                            ? attributeToggles.toggle_labels
                              ? currentlyCreatingFieldClone.off_value
                              : 'Off'
                            : null
                        "
                      />
                    </div>
                  </div>
                  <div>
                    <button
                      type="button"
                      @click="
                        attributeToggles.toggle_labels =
                          !attributeToggles.toggle_labels
                      "
                      :class="`${attributeToggles.toggle_labels ? 'bg-red-500 hover:bg-red-700' : 'bg-blue-500 hover:bg-blue-700'}`"
                      class="btn btn-block borde border-slate-400 text-gray-50"
                    >
                      {{
                        attributeToggles.toggle_labels === true
                          ? "Remove"
                          : "Add"
                      }}
                      Custom Toggle Labels
                    </button>
                  </div>
                </div>
                <div
                  v-if="
                    (currentlyCreatingField.type === 'text' ||
                      currentlyCreatingField.type === 'textarea' ||
                      currentlyCreatingField.type === 'dropdown-multi' ||
                      currentlyCreatingField.type === 'checkbox' ||
                      currentlyCreatingField.type === 'taglist') &&
                    !attributeToggles.regex
                  "
                  class="flex flex-col border-b border-b-slate-400 gap-5 p-5"
                >
                  <div
                    v-if="attributeToggles.min_max_length"
                    class="flex flex-col flex-1 gap-2"
                  >
                    <div class="flex-1 flex flex-row gap-5">
                      <FormKit
                        label="Minimum Length"
                        type="text"
                        placeholder=""
                        wrapper-class="flex-1 max-w-full"
                        outer-class="flex-1 m-0"
                        :validation="[
                          ['required'],
                          ['number'],
                          [
                            'min',
                            currentlyCreatingFieldClone.attributes.includes(
                              'required'
                            )
                              ? '1'
                              : '0',
                          ],
                        ]"
                        input-class="$reset rounded-sm w-full input input-sm text-gray-700 bg-gray-50"
                        v-model.trim="currentlyCreatingField.min_length"
                        :value="
                          currentlyCreatingFieldClone.attributes.includes(
                            'required'
                          )
                            ? '1'
                            : '0' || currentlyCreatingField.min_length
                        "
                      />

                      <FormKit
                        label="Max Length"
                        type="text"
                        placeholder=""
                        wrapper-class="flex-1 max-w-full"
                        outer-class="flex-1 m-0"
                        :validation="[
                          ['required'],
                          ['number'],
                          [
                            'min',
                            String(
                              Number(currentlyCreatingFieldClone.min_length) + 1
                            ),
                          ],
                        ]"
                        input-class="$reset rounded-sm w-full input input-sm text-gray-700 bg-gray-50"
                        v-model="currentlyCreatingField.max_length"
                      />
                    </div>

                    <div>
                      <FormKit
                        type="text"
                        label="Length Validation Message"
                        placeholder="Length Validation Message"
                        wrapper-class="flex-1 max-w-full"
                        outer-class="flex-1 m-0"
                        :validation="[['required']]"
                        input-class="$reset rounded-sm w-full input input-sm text-gray-700 bg-gray-50"
                        v-model="
                          currentlyCreatingField.validation_messages.length
                        "
                        :value="
                          currentlyCreatingFieldClone.validation_messages[
                            'length'
                          ]
                        "
                      />
                    </div>
                  </div>
                  <div>
                    <button
                      type="button"
                      v-if="!attributeToggles.regex"
                      @click="
                        attributeToggles.min_max_length =
                          !attributeToggles.min_max_length
                      "
                      :class="`${attributeToggles.min_max_length ? 'bg-red-500 hover:bg-red-700' : 'bg-blue-500 hover:bg-blue-700'}`"
                      class="btn btn-block borde border-slate-400 text-gray-50"
                    >
                      {{
                        attributeToggles.min_max_length === true
                          ? "Remove"
                          : "Add"
                      }}
                      Min / Max Length Rule
                    </button>
                  </div>
                </div>

                <div
                  v-if="currentlyCreatingField.type === 'number'"
                  class="flex flex-col border-b border-b-slate-400 gap-5 p-5"
                >
                  <div
                    v-if="attributeToggles.min_max_value"
                    class="flex flex-row gap-5"
                  >
                    <div class="flex flex-row flex-1 items-center">
                      <div class="flex-1 flex flex-col gap-2">
                        <FormKit
                          label="Min Value"
                          type="text"
                          placeholder=""
                          wrapper-class="flex-1 max-w-full"
                          outer-class="flex-1 m-0"
                          :validation="[
                            ['required'],
                            ['number'],
                            [
                              'min',
                              currentlyCreatingFieldClone.attributes.includes(
                                'required'
                              )
                                ? '1'
                                : '0',
                            ],
                          ]"
                          input-class="$reset rounded-sm w-full input input-sm text-gray-700 bg-gray-50"
                          v-model="currentlyCreatingField.min_value"
                        />

                        <FormKit
                          type="text"
                          label="Minimum Value Validation Message"
                          placeholder="Max Value Validation Message"
                          wrapper-class="flex-1 max-w-full"
                          outer-class="flex-1 m-0"
                          :validation="[['required']]"
                          input-class="$reset rounded-sm w-full input input-sm text-gray-700 bg-gray-50"
                          v-model="
                            currentlyCreatingField.validation_messages.min
                          "
                          :value="
                            currentlyCreatingFieldClone.validation_messages[
                              'min'
                            ]
                          "
                        />
                      </div>
                    </div>
                    <div class="flex flex-row flex-1 items-center">
                      <div class="flex-1 flex flex-col gap-2">
                        <FormKit
                          label="Max Value"
                          type="text"
                          placeholder=""
                          wrapper-class="flex-1 max-w-full"
                          outer-class="flex-1 m-0"
                          :validation="[
                            ['required'],
                            ['number'],
                            [
                              'min',
                              String(
                                Number(currentlyCreatingFieldClone.min_value) +
                                  1
                              ),
                            ],
                          ]"
                          input-class="$reset rounded-sm w-full input input-sm text-gray-700 bg-gray-50"
                          v-model="currentlyCreatingField.max_value"
                        />

                        <FormKit
                          label="Max Value Validation Message"
                          type="text"
                          placeholder="Max Value Validation Message"
                          wrapper-class="flex-1 max-w-full"
                          outer-class="flex-1 m-0"
                          :validation="[['required']]"
                          input-class="$reset rounded-sm w-full input input-sm text-gray-700 bg-gray-50"
                          v-model="
                            currentlyCreatingField.validation_messages.max
                          "
                          :value="
                            currentlyCreatingFieldClone.validation_messages[
                              'max'
                            ]
                          "
                        />
                      </div>
                    </div>
                  </div>
                  <div class="flex flex-row flex-1">
                    <button
                      type="button"
                      @click="
                        attributeToggles.min_max_value =
                          !attributeToggles.min_max_value
                      "
                      :class="`${attributeToggles.min_max_value ? 'bg-red-500 hover:bg-red-700' : 'bg-blue-500 hover:bg-blue-700'}`"
                      class="btn btn-block borde border-slate-400 text-gray-50"
                    >
                      {{
                        attributeToggles.min_max_value === true
                          ? "Remove"
                          : "Add"
                      }}
                      Min / Max Value Rule
                    </button>
                  </div>
                </div>

                <div
                  v-if="currentlyCreatingField.type === 'text'"
                  class="flex flex-row"
                >
                  <div
                    class="flex flex-col border-b border-b-slate-400 p-5 flex-1 gap-5"
                  >
                    <div
                      v-if="
                        attributeToggles.regex &&
                        !attributeToggles.min_max_length
                      "
                      class="flex flex-col gap-5"
                    >
                      <div class="flex flex-col gap-2">
                        <FormKit
                          type="text"
                          label="Matches Regex Pattern"
                          placeholder=""
                          wrapper-class="max-w-full"
                          help-class="text-white"
                          outer-class="flex-1 m-0"
                          :validation="[['required'], ['length', 1, 255]]"
                          input-class="$reset rounded-sm w-full input input-sm text-gray-700 bg-gray-50"
                          v-model="currentlyCreatingField.regex"
                        />
                      </div>

                      <div class="flex flex-col gap-2">
                        <div
                          class="flex flex-row gap-5 items-center w-full max-w-full overflow-x-auto"
                        >
                          <span
                            class="bg-base-100 pr-5 sticky left-0 border-r border-r-slate-400"
                            >Regex Presets:</span
                          >
                          <button
                            type="button"
                            @click="
                              currentlyCreatingField.regex =
                                '^\\d{3}-\\d{3}-\\d{4}$'
                            "
                            class="btn btn-sm capitalize text-gray-50 bg-blue-500 hover:bg-blue-700 border-none"
                          >
                            ###-###-####
                          </button>

                          <button
                            type="button"
                            @click="
                              currentlyCreatingField.regex =
                                '^\\(\\d{3}\\)\\d{3}-\\d{4}$'
                            "
                            class="btn btn-sm capitalize text-gray-50 bg-blue-500 hover:bg-blue-700 border-none"
                          >
                            (###)###-####
                          </button>

                          <button
                            type="button"
                            @click="
                              currentlyCreatingField.regex =
                                '^\\(\\d{3}\\) \\d{3}-\\d{4}$'
                            "
                            class="btn btn-sm capitalize text-gray-50 bg-blue-500 hover:bg-blue-700 border-none"
                          >
                            (###) ###-###
                          </button>

                          <button
                            type="button"
                            @click="
                              currentlyCreatingField.regex =
                                '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$'
                            "
                            class="btn btn-sm text-gray-50 bg-blue-500 hover:bg-blue-700 border-none capitalize"
                          >
                            Email
                          </button>
                          <button
                            type="button"
                            @click="
                              currentlyCreatingField.regex =
                                '^\\d{5}(-\\d{4})?$'
                            "
                            class="btn btn-sm text-gray-50 bg-blue-500 hover:bg-blue-700 border-none capitalize"
                          >
                            Zip Code (US)
                          </button>
                          <button
                            type="button"
                            @click="
                              currentlyCreatingField.regex =
                                '^(https?:\\/\\/)?([\\da-z\\.-]+)\\.([a-z\\.]{2,6})([\\/\\w \\.-]*)*\\/?$'
                            "
                            class="btn btn-sm text-gray-50 bg-blue-500 hover:bg-blue-700 border-none capitalize"
                          >
                            URL
                          </button>
                        </div>
                      </div>

                      <div class="">
                        <FormKit
                          label="Regex Validation Message"
                          type="text"
                          placeholder="Please enter a valid X format"
                          wrapper-class="max-w-full"
                          outer-class="flex-1 m-0"
                          :validation="[['required']]"
                          input-class="$reset rounded-sm w-full input input-sm text-gray-700 bg-gray-50"
                          v-model="
                            currentlyCreatingField.validation_messages.matches
                          "
                          :value="
                            currentlyCreatingFieldClone.validation_messages[
                              'matches'
                            ]
                          "
                        />
                      </div>
                    </div>

                    <div class="flex flex-row flex-1">
                      <button
                        v-if="!attributeToggles.min_max_length"
                        type="button"
                        @click="
                          attributeToggles.regex = !attributeToggles.regex
                        "
                        :class="`${attributeToggles.regex ? 'bg-red-500 hover:bg-red-700' : 'bg-blue-500 hover:bg-blue-700'}`"
                        class="btn btn-block borde border-slate-400 text-gray-50"
                      >
                        {{ attributeToggles.regex === true ? "Remove" : "Add" }}
                        REGEX Rule
                      </button>
                    </div>
                  </div>
                  <div class="flex flex-row"></div>
                </div>
                <div class="bg-base-100 p-5 flex flex-row justify-end">
                  <button
                    @mouseover="previewField = false"
                    @mouseout="previewField = true"
                    type="button"
                    @click="submitCreateFieldForm"
                    v-if="currentlyCreatingField.type !== 'separator'"
                    class="rounded btn bg-emerald-500 hover:bg-emerald-700 capitalize text-gray-50"
                  >
                    Save & Create Field
                  </button>
                </div>
              </div>
            </div>
          </FormKit>
        </div>
      </div>
    </transition>
  </Teleport>

  <div class="h-full flex flex-col bg-base-100">
    <div v-for="(item, index) in items" :key="index">{{ item }}</div>
    <div class="w-full p-3 bg-base-100 text-white">
      <font-awesome-icon icon="fa-solid fa-hammer" /><span class="ml-3"
        >Form Builder</span
      >
    </div>
    <div class="w-full overflow-y-auto flex flex-row justify-between flex-1">
      <div class="w-72 h-full bg-gray-50 border-r border-r-slate-400">
        <div class="h-full overflow-y-auto bg-base-100">
          <div class="bg-base-100 text-white sticky top-0">
            <div class="flex flex-row items-center p-2">
              <h1>Form Fields</h1>
              <button
                @click="showFieldFilters = true"
                class="ml-auto btn btn-sm capitalize text-white bg-emerald-500 border-none"
              >
                <font-awesome-icon :icon="['fas', 'filter']" />
                <span v-if="tenantsForms.filterByType.length > 0" class="ml-2">
                  {{ tenantsForms.filterByType.length || "" }} Applied
                </span>
              </button>
            </div>
            <div
              class="p-2 bg-base-100 text-white sticky top-0 flex flex-row items-center"
            >
              <button
                v-if="
                  loginStore?.sessionData?.permissions?.includes(
                    'create:tenant-form-fields'
                  ) ||
                  loginStore?.sessionData?.permissions?.includes(
                    '*:dev-permissions'
                  )
                "
                @click="tenantsForms.openFormFieldAdderModal"
                class="mr-auto btn btn-sm bg-blue-500 border-none capitalize text-white rounded"
              >
                <font-awesome-icon :icon="['fas', 'plus']" />
                <span class="ml-2">Add Field</span>
              </button>

              <router-link
                class="btn btn-xs md:btn-sm bg-blue-500 hover:bg-blue-700 text-white border-none capitalize rounded"
                :to="{ name: 'settings-rebuttals-create' }"
              >
                <span class="mr-2"> Add Rebuttal </span>
                <font-awesome-icon icon="fa-solid fa-plus" />
              </router-link>
            </div>
            <Teleport to="#modals-target">
              <transition
                enter-active-class="transition-opacity duration-500 ease-in-out"
                enter-from-class="opacity-0"
                enter-to-class="opacity-100"
                leave-active-class="transition-opacity duration-500 ease-in-out"
                leave-from-class="opacity-100"
                leave-to-class="opacity-0"
              >
                <div
                  @click.self="showFieldFilters = false"
                  v-if="showFieldFilters"
                  class="w-screen h-screen backdrop-brightness-50 fixed top-0 left-0 z-50 flex flex-col justify-center items-center"
                >
                  <transition
                    enter-active-class="transform transition-transform duration-500 ease-in-out"
                    enter-from-class="-translate-x-full"
                    enter-to-class="translate-x-0"
                    leave-active-class="transform transition-transform duration-500 ease-in-out"
                    leave-from-class="translate-x-0"
                    leave-to-class="-translate-x-full"
                  >
                    <div
                      class="fixed top-0 left-0 h-screen flex flex-col gap-5 items-start bg-slate-600 p-5 rounded w-72"
                    >
                      <div
                        class="flex flex-row items-center justify-start w-full gap-5"
                      >
                        <h1 class="text-lg text-gray-50">Filter By Type</h1>
                        <!-- button to clear filters -->
                        <button
                          @click="tenantsForms.filterByType = []"
                          class="ml-auto btn btn-sm bg-red-500 border-none capitalize text-white"
                        >
                          <font-awesome-icon :icon="['fas', 'times']" />
                          <span class="ml-2">Clear</span>
                        </button>
                      </div>
                      <!-- Formkit Checkbox -->
                      <FormKit
                        type="checkbox"
                        help-class="text-white"
                        label-class="text-gray-50"
                        legend-class="text-gray-50"
                        decorator-icon="checkbox"
                        wrapper-class="w-full max-w-full"
                        inner-class="bg-base-100"
                        outer-class="m-0 w-full max-w-full bg-slate-700"
                        fieldset-class="w-full max-w-full"
                        :options="[
                          {
                            label: 'Text',
                            value: 'text',
                          },
                          {
                            label: 'Textarea',
                            value: 'textarea',
                          },
                          {
                            label: 'Number',
                            value: 'number',
                          },
                          {
                            label: 'Currency',
                            value: 'currency',
                          },
                          {
                            label: 'Email',
                            value: 'email',
                          },
                          {
                            label: 'Phone',
                            value: 'tel',
                          },
                          {
                            label: 'URL',
                            value: 'url',
                          },
                          {
                            label: 'Date',
                            value: 'date',
                          },
                          {
                            label: 'Time',
                            value: 'time',
                          },
                          {
                            label: 'Datetime Local',
                            value: 'datetime-local',
                          },
                          {
                            label: 'Date Picker',
                            value: 'date-picker',
                          },
                          {
                            label: 'Time Picker',
                            value: 'time-picker',
                          },
                          {
                            label: 'Datetime Picker',
                            value: 'datetime-picker',
                          },
                          {
                            label: 'Slider',
                            value: 'slider',
                          },
                          {
                            label: 'Dropdown',
                            value: 'dropdown',
                          },
                          {
                            label: 'Dropdown Multi',
                            value: 'dropdown-multi',
                          },
                          {
                            label: 'Select',
                            value: 'select',
                          },
                          {
                            label: 'Checkbox',
                            value: 'checkbox',
                          },
                          {
                            label: 'Radio',
                            value: 'radio',
                          },
                          {
                            label: 'Color',
                            value: 'color',
                          },
                          {
                            label: 'Mask',
                            value: 'mask',
                          },
                          {
                            label: 'Taglist',
                            value: 'taglist',
                          },
                        ]"
                        v-model="tenantsForms.filterByType"
                      />
                    </div>
                  </transition>
                </div>
              </transition>
            </Teleport>
            <div class="flex flex-row justify-between items-center p-2">
              <!-- SEARCH FIELDS INPUT -->

              <input
                type="text"
                class="w-full p-2 bg-gray-50 text-gray-700 input input-sm"
                placeholder="Search Fields"
                v-model="tenantsForms.searchField"
              />
            </div>
            <div class="flex flex-row justify-between items-center px-2">
              <div class="grid grid-cols-2 gap-y-2 w-full">
                <button
                  type="button"
                  @click="setCustomOrSystem('custom')"
                  :class="`${customOrSystem === 'custom' ? 'bg-gray-50 border-b-4 text-gray-500 border-x-0 border-t-0 border-b-blue-400 hover:bg-gray-50 hover:border-b-blue-500' : 'bg-gray-50 text-gray-500 border-none hover:bg-blue-500 hover:text-gray-50'}`"
                  class="flex-1 btn btn-sm capitalize rounded-none"
                >
                  Custom
                </button>
                <button
                  type="button"
                  @click="setCustomOrSystem('system')"
                  :class="`${customOrSystem === 'system' ? 'bg-gray-50 border-b-4 text-gray-500 border-x-0 border-t-0 border-b-blue-400 hover:bg-gray-50 hover:border-b-blue-500' : 'bg-gray-50 text-gray-500 border-none hover:bg-blue-500 hover:text-gray-50'}`"
                  class="flex-1 btn btn-sm capitalize rounded-none"
                >
                  System
                </button>

                <button
                  type="button"
                  @click="setCustomOrSystem('rebuttals')"
                  :class="`${customOrSystem === 'rebuttals' ? 'bg-gray-50 border-b-4 text-gray-500 border-x-0 border-t-0 border-b-blue-400 hover:bg-gray-50 hover:border-b-blue-500' : 'bg-gray-50 text-gray-500 border-none hover:bg-blue-500 hover:text-gray-50'}`"
                  class="flex-1 btn btn-sm capitalize rounded-none col-span-2"
                >
                  Rebuttals
                </button>
              </div>
            </div>
          </div>

          <!-- Core Fields Holder -->
          <div class="bg-slate-500">
            <draggable
              v-if="customOrSystem !== 'rebuttals'"
              v-model="filteredCustomFields"
              tag="div"
              :group="{ name: 'form-editor', pull: 'clone', put: false }"
              class="flex flex-col p-2 gap-2 bg-base-100"
              :animation="500"
              :sort="false"
            >
              <template #item="{ element: field, index }">
                <div
                  :key="index"
                  tabindex="0"
                  :class="`${fieldTypeColor(field.type)} ${field.type !== 'mask' ? 'text-white' : 'text-gray-600'}`"
                  :type="field.type"
                  :label="field.label"
                  :messages="field.messages"
                  :message="field.message"
                  :help="field.help"
                  :validation="field.validation"
                  :validation-messages="field.validation_messages"
                  class="cursor-pointer hover:border-white border border-slate-300 rounded hover:border-2 hover:border-dashed flex flex-row justify-between items-center"
                >
                  <span v-if="field.label" class="pl-3 flex-1 text-xs">{{
                    field.label
                  }}</span>
                  <div
                    :class="!field.label ? 'flex-1' : ''"
                    class="flex flex-row justify-between items-center"
                  >
                    <div class="flex-1 py-1 pl-3">
                      <span class="mr-2 capitalize text-xs">
                        <font-awesome-icon
                          :icon="['fas', 'i-cursor']"
                          size="2xl"
                          v-if="field.type === 'text'"
                        />
                        <font-awesome-icon
                          :icon="['fas', 'paragraph']"
                          size="2xl"
                          v-if="field.type === 'textarea'"
                        />

                        <!-- number -->
                        <font-awesome-icon
                          :icon="['fas', 'sort-numeric-up']"
                          size="2xl"
                          v-if="field.type === 'number'"
                        />

                        <!-- currency -->
                        <font-awesome-icon
                          :icon="['fas', 'dollar-sign']"
                          size="2xl"
                          v-if="field.type === 'currency'"
                        />

                        <!-- email -->
                        <font-awesome-icon
                          :icon="['fas', 'envelope']"
                          size="2xl"
                          v-if="field.type === 'email'"
                        />

                        <!-- phone -->
                        <font-awesome-icon
                          :icon="['fas', 'phone']"
                          size="2xl"
                          v-if="field.type === 'tel'"
                        />

                        <!-- url -->
                        <font-awesome-icon
                          :icon="['fas', 'link']"
                          size="2xl"
                          v-if="field.type === 'toggle'"
                        />

                        <!-- date -->
                        <font-awesome-icon
                          :icon="['fas', 'calendar-alt']"
                          size="2xl"
                          v-if="field.type === 'date'"
                        />

                        <!-- time -->
                        <font-awesome-icon
                          :icon="['fas', 'clock']"
                          size="2xl"
                          v-if="field.type === 'time'"
                        />

                        <!-- datetime-local -->

                        <font-awesome-icon
                          :icon="['fas', 'calendar-day']"
                          size="2xl"
                          v-if="field.type === 'datetime-local'"
                        />

                        <!-- date picker -->
                        <font-awesome-icon
                          :icon="['fas', 'calendar-alt']"
                          size="2xl"
                          v-if="field.type === 'date-picker'"
                        />

                        <!-- time picker -->
                        <font-awesome-icon
                          :icon="['fas', 'clock']"
                          size="2xl"
                          v-if="field.type === 'time-picker'"
                        />

                        <!-- datetime picker -->
                        <font-awesome-icon
                          :icon="['fas', 'calendar-day']"
                          size="2xl"
                          v-if="field.type === 'datetime-picker'"
                        />

                        <!-- slider -->
                        <font-awesome-icon
                          :icon="['fas', 'sliders-h']"
                          size="2xl"
                          v-if="field.type === 'slider'"
                        />

                        <!-- dropdown -->
                        <font-awesome-icon
                          :icon="['fas', 'caret-down']"
                          size="2xl"
                          v-if="field.type === 'dropdown'"
                        />

                        <!-- dropdown-multi -->
                        <font-awesome-icon
                          :icon="['fas', 'caret-down']"
                          size="2xl"
                          v-if="field.type === 'dropdown-multi'"
                        />

                        <!-- select -->
                        <font-awesome-icon
                          :icon="['fas', 'caret-down']"
                          size="2xl"
                          v-if="field.type === 'select'"
                        />

                        <!-- checkbox -->
                        <font-awesome-icon
                          :icon="['fas', 'check-square']"
                          size="2xl"
                          v-if="field.type === 'checkbox'"
                        />

                        <!-- radio -->

                        <font-awesome-icon
                          :icon="['fas', 'dot-circle']"
                          size="2xl"
                          v-if="field.type === 'radio'"
                        />

                        <!-- color -->
                        <font-awesome-icon
                          :icon="['fas', 'palette']"
                          size="2xl"
                          v-if="field.type === 'color'"
                        />

                        <!-- mask -->
                        <font-awesome-icon
                          :icon="['fas', 'mask']"
                          size="2xl"
                          v-if="field.type === 'mask'"
                        />

                        <!-- taglist -->
                        <font-awesome-icon
                          :icon="['fas', 'tags']"
                          size="2xl"
                          v-if="field.type === 'taglist'"
                        />

                        <!-- separator -->
                        <font-awesome-icon
                          :icon="['fas', 'ellipsis-h']"
                          size="2xl"
                          v-if="field.type === 'separator'"
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </template>
            </draggable>
            <!-- <pre>
              {{ tenantsForms.currentlyEditingForm.rebuttals }}
            </pre>
            <pre>
                  {{ currentRebuttalsSet }}
                </pre
            > -->
            <draggable
              v-if="customOrSystem === 'rebuttals'"
              v-model="tenantsRebuttalsButtonsOnlyList"
              tag="div"
              :group="{ name: 'rebuttal-editor', pull: true, put: false }"
              class="flex flex-col p-2 gap-2 bg-base-100"
              :animation="500"
              :sort="false"
            >
              <template #item="{ element: rebuttal, index }">
                <div
                  :key="index"
                  tabindex="0"
                  :class="`bg-${rebuttal.color || 'blue'}-500`"
                  :id="rebuttal.rebuttal_id"
                  class="cursor-pointer hover:border-white border border-slate-300 rounded hover:border-2 hover:border-dashed flex flex-row justify-between items-center text-gray-50"
                >
                  <span class="pl-3 flex-1 text-xs">{{ rebuttal.name }}</span>
                  <div class="flex flex-row justify-between items-center">
                    <div class="flex-1 py-1 pl-3">
                      <span class="mr-2 capitalize text-xs">
                        <font-awesome-icon
                          :icon="['fas', 'table-tennis-paddle-ball']"
                          size="2xl"
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </template>
            </draggable>
          </div>
        </div>
      </div>
      <div class="relative w-72 h-full bg-base-100 p-5">
        <span
          v-if="currentRebuttalsSet.length < 1"
          class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white bg-blue-400 p-5 rounded border-4 border-gray-50 border-dashed"
        >
          Create, Drag, Drop and Order Rebuttals Here to Add them to Scripts and
          Forms.
        </span>

        <draggable
          v-model="currentRebuttalsSet"
          tag="div"
          group="rebuttal-editor"
          :animation="500"
          class="flex-1 bg-gray-500 w-full h-full border-4 border-dashed border-gray-50 rounded p-2 flex flex-col gap-2"
        >
          <template #item="{ element: rebuttal, index }">
            <div
              :key="index"
              :class="`bg-${rebuttal.color || 'blue'}-500`"
              class="text-gray-50 text-center rounded flex flex-row"
            >
              <span class="p-2">
                {{ rebuttal.name }}
              </span>

              <button
                @click="currentRebuttalsSet.splice(index, 1)"
                class="ml-auto btn rounded-r rounded-l-none btn-sm bg-red-500 border-none hover:bg-red-700 text-gray-50 h-full"
              >
                <font-awesome-icon :icon="['fas', 'trash']" />
              </button>
            </div>
          </template>
        </draggable>
      </div>

      <div class="flex-1 border-x h-full bg-gray-50 overflow-y-auto">
        <div class="bg-slate-500 text-white flex flex-col h-full">
          <div class="h-full overflow-y-auto p-5 bg-base-100 flex flex-col">
            <div
              class="relative flex-1 bg-gray-500 border-4 border-gray-50 rounded border-dashed flex flex-col justify-center"
            >
              <div
                v-if="
                  !loginStore?.sessionData?.permissions?.includes(
                    'create:tenant-forms'
                  ) &&
                  !loginStore?.sessionData?.permissions?.includes(
                    'edit:tenant-forms'
                  ) &&
                  !loginStore?.sessionData?.permissions?.includes(
                    '*:dev-permissions'
                  )
                "
                class="absolute top-1/2 left-1/2 transform text-center -translate-x-1/2 -translate-y-1/2 text-white bg-red-400 p-10 rounded border-4 border-gray-50 border-dashed"
              >
                You can play around in here but these changes won't save because
                you do not have permissions to "create" or "edit" forms.
              </div>

              <div
                v-if="
                  (form.length < 1 &&
                    loginStore?.sessionData?.permissions?.includes(
                      'create:tenant-forms'
                    ) &&
                    loginStore?.sessionData?.permissions?.includes(
                      'edit:tenant-forms'
                    )) ||
                  (form.length < 1 &&
                    loginStore?.sessionData?.permissions?.includes(
                      '*:dev-permissions'
                    ))
                "
                class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white bg-blue-400 p-10 rounded border-4 border-gray-50 border-dashed"
              >
                No Fields Added Yet. Add a Section or Field.
              </div>
              <FormKit
                id="test-custom-form"
                type="form"
                :incomplete-message="false"
                submit-label="Create"
                form-class="text-white flex flex-col justify-between flex-1 max-h-full overflow-hidden border-t border-t-slate-400"
                @submit.self="testCustomFormSubmit"
                outer-class="m-0"
                :actions="false"
                :submit-attrs="{
                  'data-theme': `light`,
                  ignore: true,
                }"
              >
                <draggable
                  v-model="form"
                  tag="div"
                  group="form-editor"
                  :animation="500"
                  class="p-5 flex-1"
                >
                  <template #item="{ element: field, index }">
                    <div
                      @mousedown="setFocusedField(index)"
                      :key="index"
                      :class="
                        field.focused
                          ? 'border-gray-50 border-4 border-dashed my-1'
                          : ''
                      "
                      class="relative bg-base-300 hover:bg-gray-600 w-full no-animation hover:cursor-grab"
                    >
                      <div
                        v-if="field.focused"
                        class="absolute top-1 right-1 z-10 flex flex-row gap-2"
                      >
                        <button
                          @click="addScriptBlockAboveOrBelow('above', index)"
                          class="btn btn-xs border border-slate-400 text-white capitalize"
                        >
                          <font-awesome-icon :icon="['fas', 'plus']" />
                          <span class="mx-2">Script</span>
                          <font-awesome-icon :icon="['fas', 'arrow-up']" />
                        </button>

                        <button
                          @click="addNewFieldDynamic('above', index)"
                          class="btn btn-xs border border-slate-400 text-white capitalize"
                        >
                          <font-awesome-icon :icon="['fas', 'plus']" />
                          <span class="mx-2">Section</span>
                          <font-awesome-icon :icon="['fas', 'arrow-up']" />
                        </button>
                      </div>
                      <div
                        v-if="field.focused"
                        class="absolute bottom-1 right-1 z-10 flex flex-row gap-2"
                      >
                        <button
                          @click="addScriptBlockAboveOrBelow('below', index)"
                          class="btn btn-xs border border-slate-400 text-white capitalize"
                        >
                          <font-awesome-icon :icon="['fas', 'plus']" />
                          <span class="mx-2">Script</span>
                          <font-awesome-icon :icon="['fas', 'arrow-down']" />
                        </button>

                        <button
                          @click="addNewFieldDynamic('below', index)"
                          class="btn btn-xs border border-slate-400 text-white capitalize"
                        >
                          <font-awesome-icon :icon="['fas', 'plus']" />
                          <span class="mx-2">Section</span>
                          <font-awesome-icon :icon="['fas', 'arrow-down']" />
                        </button>

                        <!-- button toolbar - delete -->
                        <button
                          v-if="field.focused"
                          @click="form.splice(index, 1)"
                          class="btn btn-xs text-gray-50 rounded bg-red-500 hover:bg-red-700 border border-slate-400"
                        >
                          <font-awesome-icon :icon="['fas', 'trash-alt']" />
                        </button>
                      </div>
                      <div
                        v-if="field.type === 'separator'"
                        class="border-b border-b-slate-400 p-3 bg-gray-600"
                      >
                        <input
                          type="text"
                          class="input input-ghost w-full rounded-none"
                          placeholder="Section Title"
                          v-model="field.title"
                        />
                        <input
                          type="text"
                          class="input input-xs input-ghost w-full rounded-none pl-4"
                          placeholder="Section Description"
                          v-model="field.description"
                        />
                      </div>

                      <div
                        v-if="field.type === 'script'"
                        class="border-b bg-blue-100 p-3 text-gray-600"
                      >
                        <textarea
                          class="bg-gray-50 w-full rounded-none textarea textarea-bordered text-lg"
                          placeholder="Script Block Content"
                          v-model="field.content"
                        ></textarea>
                      </div>

                      <div
                        v-if="field.type === 'currency'"
                        class="flex flex-row items-center gap-5 p-5"
                      >
                        <FormKit
                          type="currency"
                          help-class="text-white"
                          wrapper-class="flex-1 max-w-full"
                          outer-class="flex-1 m-0"
                          input-class="$reset rounded-sm w-full input input-sm text-gray-700 bg-gray-50"
                          :label="field.label || 'My Currency Field'"
                          :help="field.help"
                          :validation="field.validation"
                          :validation-messages="field.validation_messages"
                          :min="field.min_attribute || 0"
                          :max="field.max_attribute"
                          :step="field.step"
                          :currency="field.currency"
                          :display-locale="field.locale"
                          :decimals="field.decimals"
                          :minDecimals="field.min_decimals"
                        />
                      </div>
                      <div
                        v-if="field.type === 'text'"
                        class="flex flex-row items-center gap-5 p-5"
                      >
                        <FormKit
                          type="text"
                          help-class="text-white"
                          wrapper-class="flex-1 max-w-full"
                          outer-class="flex-1 m-0"
                          input-class="$reset rounded-sm w-full input input-sm text-gray-700 bg-gray-50"
                          :label="field.label || 'Text'"
                          :help="field.help"
                          :placeholder="field.placeholder"
                          :validation="field.validation"
                          :validation-messages="field.validation_messages"
                        />
                      </div>
                      <div
                        v-if="field.type === 'textarea'"
                        class="flex flex-row items-center gap-5 p-5"
                      >
                        <FormKit
                          type="textarea"
                          help-class="text-white"
                          wrapper-class="flex-1 max-w-full"
                          outer-class="flex-1 m-0"
                          input-class="$reset rounded-sm w-full textarea-lg text-gray-700 bg-gray-50 p-2"
                          :label="field.label || 'Textarea'"
                          :help="field.help"
                          :placeholder="field.placeholder"
                          :validation="field.validation"
                          :validation-messages="field.validation_messages"
                        />
                      </div>
                      <div
                        v-if="field.type === 'number'"
                        class="flex flex-row items-center gap-5 p-5"
                      >
                        <FormKit
                          type="number"
                          help-class="text-white"
                          wrapper-class="flex-1 max-w-full"
                          outer-class="flex-1 m-0"
                          input-class="$reset rounded-sm w-full input input-sm text-gray-700 bg-gray-50"
                          :label="field.label || 'Number'"
                          :help="field.help"
                          :placeholder="field.placeholder"
                          :validation="[['number'], ...field.validation]"
                          :validation-messages="field.validation_messages"
                          :min="field.min_attribute"
                          :step="field.step"
                          :max="field.max_attribute"
                        />
                      </div>
                      <div
                        v-if="field.type === 'email'"
                        class="flex flex-row items-center gap-5 p-5"
                      >
                        <FormKit
                          type="email"
                          help-class="text-white"
                          wrapper-class="flex-1 max-w-full"
                          outer-class="flex-1 m-0"
                          input-class="$reset rounded-sm w-full input input-sm text-gray-700 bg-gray-50"
                          :label="field.label || 'Email'"
                          :help="field.help"
                          :placeholder="field.placeholder"
                          :validation="[['email'], ...field.validation]"
                          :validation-messages="field.validation_messages"
                        />
                      </div>
                      <div
                        v-if="field.type === 'tel'"
                        class="flex flex-row items-center gap-5 p-5"
                      >
                        <FormKit
                          type="tel"
                          help-class="text-white"
                          wrapper-class="flex-1 max-w-full"
                          outer-class="flex-1 m-0"
                          input-class="$reset rounded-sm w-full input input-sm text-gray-700 bg-gray-50"
                          :label="field.label || 'Phone'"
                          :help="field.help"
                          :placeholder="field.placeholder"
                          :validation="[
                            ['matches', /^\d{10}$/],
                            ...field.validation,
                          ]"
                          :validation-messages="{
                            matches: 'Must be a 10 digit number',
                          }"
                        />
                      </div>
                      <div
                        v-if="field.type === 'toggle'"
                        class="flex flex-row items-center gap-5 p-5"
                      >
                        <FormKit
                          type="toggle"
                          help-class="text-white"
                          wrapper-class="flex-1 max-w-full"
                          outer-class="flex-1 m-0"
                          input-class="$reset rounded-sm w-full input input-sm text-gray-700 bg-gray-50"
                          :label="field.label || 'My Toggle'"
                          :help="field.help"
                          :validation="field.validation"
                          :validation-messages="field.validation_messages"
                          :on-value="field.on_value"
                          :off-value="field.off_value"
                        />
                      </div>
                      <div
                        v-if="field.type === 'date'"
                        class="flex flex-row items-center gap-5 p-5"
                      >
                        <FormKit
                          type="date"
                          help-class="text-white"
                          wrapper-class="flex-1 max-w-full"
                          outer-class="flex-1 m-0"
                          input-class="rounded-sm w-full p-2 bg-base-100 text-white"
                          :label="field.label || 'Date'"
                          :help="field.help"
                          :validation="field.validation"
                          :validation-messages="field.validation_messages"
                          :min="field.min"
                          :step="field.step"
                          :max="field.max"
                        />
                      </div>
                      <div
                        v-if="field.type === 'time'"
                        class="flex flex-row items-center gap-5 p-5"
                      >
                        <FormKit
                          help-class="text-white"
                          type="time"
                          wrapper-class="flex-1 max-w-full"
                          outer-class="flex-1 m-0"
                          input-class="$reset rounded-sm input input-sm w-full bg-base-100 text-white"
                          :label="field.label || 'Time'"
                          :help="field.help"
                          :validation="field.validation"
                          :validation-messages="field.validation_messages"
                          :min="field.min"
                          :step="field.step"
                          :max="field.max"
                        />
                      </div>
                      <div
                        v-if="field.type === 'date-picker'"
                        class="flex flex-row items-center gap-5 p-5"
                      >
                        <FormKit
                          type="datepicker"
                          help-class="text-white"
                          label-class="text-gray-50"
                          wrapper-class="flex-1 max-w-full text-gray-700"
                          outer-class="flex-1 m-0"
                          input-class="rounded-sm w-full p-2 bg-gray-50"
                          open-button-class="bg-gray-50"
                          format="MM/DD/YYYY"
                          time-input-class="text-white"
                          time-class="text-gray-600"
                          :label="field.label || 'Date Picker'"
                          :help="field.help"
                          :validation="field.validation"
                          :validation-messages="field.validation_messages"
                          :sequence="['day']"
                          picker-only
                        />
                      </div>
                      <div
                        v-if="field.type === 'datetime-picker'"
                        class="flex flex-row items-center gap-5 p-5"
                      >
                        <FormKit
                          type="datepicker"
                          help-class="text-white"
                          label-class="text-gray-50"
                          wrapper-class="flex-1 max-w-full text-gray-700"
                          outer-class="flex-1 m-0"
                          input-class="rounded-sm w-full p-2 bg-gray-50"
                          open-button-class="bg-gray-50"
                          time-input-class="text-white"
                          time-class="text-gray-600"
                          :label="field.label || 'Datetime Picker'"
                          :help="field.help"
                          :validation="field.validation"
                          :validation-messages="field.validation_messages"
                          :min="field.min_attribute"
                          :step="field.step"
                          :max="field.max_attribute"
                          :format="{ date: 'short', time: 'short' }"
                          :sequence="['day', 'time']"
                          picker-only
                        />
                      </div>
                      <div
                        v-if="field.type === 'time-picker'"
                        class="flex flex-row items-center gap-5 p-5"
                      >
                        <FormKit
                          type="datepicker"
                          help-class="text-white"
                          label-class="text-gray-50"
                          wrapper-class="flex-1 max-w-full text-gray-700"
                          outer-class="flex-1 m-0"
                          input-class="rounded-sm w-full p-2 bg-gray-50"
                          open-button-class="bg-gray-50"
                          format="hh:mm A"
                          time-input-class="text-white"
                          time-class="text-gray-600"
                          :help="field.help"
                          :label="field.label || 'Time Picker'"
                          :validation="field.validation"
                          :validation-messages="field.validation_messages"
                          :min="field.min_attribute"
                          :step="field.step"
                          :max="field.max_attribute"
                          :sequence="['time']"
                          panel-header-class="$reset hidden"
                          picker-only
                        />
                        <!-- LEFTOFF -->
                      </div>

                      <div
                        v-if="field.type === 'datetime-local'"
                        class="flex flex-row items-center gap-5 p-5"
                      >
                        <FormKit
                          help-class="text-white"
                          type="datetime-local"
                          placeholder=""
                          wrapper-class="flex-1 max-w-full"
                          outer-class="flex-1 m-0"
                          input-class="$reset rounded-sm w-full input input-sm bg-base-100 text-gray-50"
                          :help="field.help"
                          :label="field.label || 'Datetime Local'"
                          :validation="field.validation"
                          :validation-messages="field.validation_messages"
                          :min="field.min_attribute"
                          :step="field.step"
                          :max="field.max_attribute"
                        />
                      </div>

                      <div
                        v-if="field.type === 'slider'"
                        class="flex flex-row items-center gap-5 p-5"
                      >
                        <FormKit
                          type="slider"
                          :label="field.label || 'Slider'"
                          :validation="field.validation"
                          :min="field.min"
                          :step="field.step"
                          :max="field.max"
                          :help="field.help"
                          help-class="text-white"
                          wrapper-class="w-full max-w-full"
                          outer-class="flex-1 m-0 w-full max-w-full"
                          inner-class="flex-1 max-w-full w-full"
                          tooltip="true"
                        />
                      </div>
                      <div
                        v-if="field.type === 'dropdown'"
                        class="flex flex-row items-center gap-5 p-5"
                      >
                        <FormKit
                          type="dropdown"
                          outer-class="flex-1 m-0 max-w-full"
                          input-class="$reset rounded-sm w-full text-gray-700 bg-gray-50"
                          listitem-class="bg-base-300 hover:bg-gray-500"
                          listbox-class="border border-slate-400"
                          selector-class="bg-gray-50 dropdown-lg"
                          wrapper-class="w-full max-w-full"
                          help-class="text-white"
                          :label="field.label || 'Dropdown'"
                          :help="field.help"
                          :options="field.options"
                          :validation="field.validation"
                          :validation-messages="field.validation_messages"
                        />
                      </div>
                      <div
                        v-if="field.type === 'dropdown-multi'"
                        class="flex flex-row items-center gap-5 p-5"
                      >
                        <FormKit
                          type="dropdown"
                          wrapper-class="flex-1 m-0 w-full max-w-full"
                          outer-class="flex-1 m-0 w-full max-w-full"
                          inner-class="w-full max-w-full"
                          input-class="$reset rounded-sm w-full text-gray-700 bg-gray-50"
                          listitem-class="bg-base-300 hover:bg-gray-500"
                          listbox-class="border border-slate-400"
                          selector-class="bg-gray-50 dropdown-lg"
                          help-class="text-white"
                          :label="field.label || 'Dropdown Multi Select'"
                          :help="field.help"
                          :options="field.options"
                          :validation="field.validation"
                          :validation-messages="field.validation_messages"
                          multiple
                        />
                      </div>
                      <div
                        v-if="field.type === 'select'"
                        class="flex flex-row items-center gap-5 p-5"
                      >
                        <FormKit
                          type="select"
                          help-class="text-white"
                          wrapper-class="flex-1 max-w-full"
                          outer-class="flex-1 m-0"
                          input-class="$reset rounded-sm w-full input input-sm text-gray-700 bg-gray-50"
                          :label="field.label || 'Select'"
                          :help="field.help"
                          :validation="field.validation"
                          :validation-messages="field.validation_messages"
                          :options="field.options"
                        />
                      </div>

                      <!-- checkbox -->
                      <div
                        v-if="field.type === 'checkbox'"
                        class="flex flex-row items-center gap-5 p-5"
                      >
                        <FormKit
                          type="checkbox"
                          help-class="text-white"
                          wrapper-class="flex-1 max-w-full"
                          outer-class="flex-1 m-0"
                          input-class="w-full text-gray-700 bg-gray-50"
                          fieldset-class="w-full max-w-full"
                          :label="field.label || 'Checkbox'"
                          :help="field.help"
                          :validation="field.validation"
                          :validation-messages="field.validation_messages"
                          :options="field.options"
                        />
                      </div>

                      <!-- radio -->
                      <div
                        v-if="field.type === 'radio'"
                        class="flex flex-row items-center gap-5 p-5"
                      >
                        <FormKit
                          :help="field.help"
                          help-class="text-white"
                          type="radio"
                          :label="field.label || 'Radio Buttons'"
                          placeholder=""
                          wrapper-class="flex-1 max-w-full"
                          fieldset-class="flex-1 max-w-full w-full"
                          outer-class="flex-1 m-0"
                          :validation="field.validation"
                          :validation-messages="field.validation_messages"
                          input-class="rounded-sm w-full text-gray-700 bg-gray-50"
                          :options="field.options"
                        />
                      </div>

                      <!-- color -->
                      <div
                        v-if="field.type === 'color'"
                        class="flex flex-row items-center gap-5 p-5"
                      >
                        <FormKit
                          :help="field.help"
                          help-class="text-white"
                          type="color"
                          :label="`${field.label} (${field.value})` || 'Color'"
                          wrapper-class="flex-1 max-w-full rounded-full"
                          outer-class="flex-1 m-0"
                          inner-class="$reset flex-1 max-w-full w-full border border-slate-400 rounded"
                          :validation="field.validation"
                          input-class="$reset w-full h-12 rounded-full text-gray-700 bg-gray-50 max-w-full"
                          v-model="field.value"
                        />
                      </div>

                      <div
                        v-if="field.type === 'mask'"
                        class="flex flex-row items-center gap-5 p-5"
                      >
                        <FormKit
                          type="mask"
                          :mask="field.mask"
                          outer-class="flex-1 m-0"
                          :label="field.label || 'Mask'"
                          help-class="text-white"
                          wrapper-class="w-full max-w-full"
                          input-class="$reset rounded-sm w-full input input-sm text-gray-700 bg-gray-50"
                          :help="field.help"
                        />
                      </div>
                      <div
                        v-if="field.type === 'taglist'"
                        class="flex flex-row items-center gap-5 p-5"
                      >
                        <FormKit
                          type="taglist"
                          :name="field.name"
                          wrapper-class="w-full max-w-full"
                          outer-class="flex-1 m-0"
                          :label="field.label || 'Tag List'"
                          help-class="text-white"
                          selected-icon-class="text-black"
                          listbox-class="border border-slate-400"
                          selector-class="bg-gray-50"
                          tags-class=""
                          tag-label-class="text-gray-600"
                          tag-class=""
                          tags-wrapper-class="bg-gray-50 text-white"
                          inner-class="bg-white"
                          select-icon-class="chevron-down text-gray-50 rounded-r border-l border-l-slate-400"
                          popover
                          :close-on-select="false"
                          input-class="rounded-sm w-full text-gray-700"
                          dropdown-wrapper-class=""
                          listitem-class="bg-base-300 focus:bg-base-300 active:bg-blue-700 pl-7 hover:border-y-2 hover: border-r-2 hover:border-r-white hover:border-y-white"
                          option-class="bg-base-300 hover:border-y hover: border-r hover:border-r-white hover:border-y-white"
                          :options="field.options"
                          :filter="
                            (option, search) =>
                              option.label
                                .toLowerCase()
                                .startsWith(search.toLowerCase())
                          "
                          :help="field.help"
                          :validation="field.validation"
                          :validation-messages="field.validation_messages"
                        />
                      </div>
                    </div>
                  </template>
                </draggable>
              </FormKit>
            </div>
            <!-- <pre class="text-white">
            
                           {{ buildFinalFormObject }}
                          
                        </pre> -->
          </div>
        </div>
      </div>
      <FormKit
        id="form-editor-form"
        type="form"
        :incomplete-message="false"
        submit-label="Create"
        form-class="w-72 h-full bg-base-100 flex flex-col justify-between"
        @submit.self="submitFormEditorForm2(buildFinalFormObject)"
        outer-class="m-0"
        :actions="false"
        :submit-attrs="{
          'data-theme': `light`,
          ignore: true,
        }"
      >
        <!-- edit form properties -->
        <div class="p-2 h-full overflow-y-auto flex flex-col gap-5">
          <div class="flex flex-col gap-2">
            <button
              type="button"
              v-if="
                (tenantsForms?.fetchedCustomForms.length > 0 &&
                  loginStore?.sessionData?.permissions?.includes(
                    'edit:tenant-forms'
                  )) ||
                (tenantsForms?.fetchedCustomForms.length > 0 &&
                  loginStore?.sessionData?.permissions?.includes(
                    '*:dev-permissions'
                  ))
              "
              @click="toggleCompanyFormsSidebar"
              class="btn rounded text-white bg-blue-500 border-none capitalize"
            >
              <font-awesome-icon :icon="['fas', 'edit']" />
              <span class="ml-2">Company Forms</span>
            </button>

            <Teleport to="#modals-target">
              <transition
                enter-active-class="transition-opacity duration-500 ease-in-out"
                enter-from-class="opacity-0"
                enter-to-class="opacity-100"
                leave-active-class="transition-opacity duration-500 ease-in-out"
                leave-from-class="opacity-100"
                leave-to-class="opacity-0"
              >
                <div
                  @click.self="showCompanyForms = false"
                  v-if="showCompanyForms"
                  class="w-screen h-screen backdrop-brightness-50 fixed top-0 left-0 z-50 flex flex-col justify-center items-center"
                >
                  <transition
                    enter-active-class="transform transition-transform duration-500 ease-in-out"
                    enter-from-class="-translate-x-full"
                    enter-to-class="translate-x-0"
                    leave-active-class="transform transition-transform duration-500 ease-in-out"
                    leave-from-class="translate-x-0"
                    leave-to-class="-translate-x-full"
                  >
                    <div
                      class="fixed top-0 right-0 h-screen w-96 flex flex-col gap-5 items-start bg-slate-600 p-5 rounded"
                    >
                      <h1 class="text-lg text-gray-50">Your Companies Forms</h1>

                      <div
                        v-if="tenantsForms?.fetchedCustomForms.length < 1"
                        class="bg-blue-500 p-5 text-center text-gray-50 rounded"
                      >
                        No Forms Yet. Start Dragging and Dropping Fields to
                        Create one.
                      </div>

                      <div
                        v-for="(item, i) in tenantsForms?.fetchedCustomForms"
                        :key="i"
                        class="flex flex-row items-center bg-gray-50 w-full text-gray-600"
                      >
                        <span class="pl-5">
                          {{ item.name }}
                        </span>

                        <button
                          @click="loadCompanyForm(item._id)"
                          class="btn border-none ml-auto bg-blue-500 rounded-none text-gray-50"
                        >
                          <font-awesome-icon :icon="['fas', 'edit']" />
                        </button>
                        <button
                          @click="deleteForm(item._id)"
                          class="btn border-none bg-red-500 rounded-none text-gray-50"
                        >
                          <font-awesome-icon :icon="['fas', 'trash-alt']" />
                        </button>
                      </div>
                    </div>
                  </transition>
                </div>
              </transition>
            </Teleport>

            <button
              v-if="form.length > 0"
              @click="createNewForm"
              class="btn rounded text-white bg-emerald-500 border-none capitalize"
            >
              <font-awesome-icon :icon="['fas', 'arrows-rotate']" />
              <span class="ml-2">New Form</span>
            </button>
          </div>

          <FormKit
            type="text"
            label="Form Name"
            input-class="$reset input w-full bg-gray-50 text-gray-500 rounded-none"
            placeholder="Enter Form Name"
            validation-message-class="text-white"
            :validation="[['required'], ['length', 5, 25]]"
            :validation-messages="{
              required: 'Plese enter a form name',
            }"
            outer-class="m-0"
            v-model="customFormName"
          />

          <!-- formkit select -->
          <FormKit
            type="select"
            label="Form Type"
            input-class="$reset w-full select bg-gray-50 text-gray-500 rounded-none"
            :options="[
              {
                label: '- Choose Type -',
                value: '',
                attrs: {
                  disabled: true,
                },
              },
              {
                label: 'Form',
                value: 'form',
              },
              {
                label: 'Script',
                value: 'script',
              },
            ]"
            v-model="customFormType"
            :validation="[['required']]"
            outer-class="m-0 w-full max-w-full"
            inner-class="w-full max-w-full"
            wrapper-class="w-full max-w-full"
          />

          <div class="grid grid-cols-2 gap-3">
            <div class="flex flex-row col-span-2">
              <button
                type="button"
                v-if="
                  (form.length > 0 &&
                    loginStore?.sessionData?.permissions?.includes(
                      'create:tenant-forms'
                    ) &&
                    (form_builder_mode === 'create' ||
                      form_builder_mode === 'edit')) ||
                  (form.length > 0 &&
                    loginStore?.sessionData?.permissions?.includes(
                      '*:dev-permissions'
                    ) &&
                    (form_builder_mode === 'create' ||
                      form_builder_mode === 'edit'))
                "
                @click="submitFormEditorForm"
                class="flex-1 btn rounded-l rounded-r-none bg-emerald-500 hover:bg-emerald-700 text-white border-none capitalize leading-normal h-auto py-5"
              >
                Save {{ form_builder_mode === "edit" ? "as" : "" }} New Form
              </button>

              <div class="dropdown dropdown-end">
                <div
                  tabindex="0"
                  role="button"
                  class="btn rounded-l-none rounded-r bg-emerald-500 hover:bg-emerald-700 text-white border-none capitalize leading-normal h-auto py-6"
                >
                  <font-awesome-icon :icon="['fas', 'chevron-down']" />
                </div>
                <ul
                  tabindex="0"
                  class="dropdown-content menu bg-base-100 rounded-box z-[1] w-52 p-2 shadow"
                >
                  <li>
                    <button
                      type="button"
                      v-if="
                        (form.length > 0 &&
                          form_builder_mode === 'edit' &&
                          loginStore?.sessionData?.permissions?.includes(
                            'edit:tenant-forms'
                          )) ||
                        (form.length > 0 &&
                          form_builder_mode === 'edit' &&
                          loginStore?.sessionData?.permissions?.includes(
                            '*:dev-permissions'
                          ))
                      "
                      @click="submitFormEditorForm"
                      class="btn rounded bg-emerald-500 hover:bg-emerald-700 text-white border-none capitalize leading-normal h-auto py-5"
                    >
                      Save Edits To Company Form
                    </button>
                  </li>
                </ul>
              </div>
            </div>

            <!-- <button
              type="button"
              v-if="
                (form.length > 0 &&
                form_builder_mode === 'edit' &&
                loginStore?.sessionData?.permissions?.includes(
                  'edit:tenant-forms'
                )) || (
                  form.length > 0 &&
                  form_builder_mode === 'edit' &&
                  loginStore?.sessionData?.permissions?.includes(
                    '*:dev-permissions'
                  )
                 )
              "
              @click="submitFormEditorForm"
              class="btn rounded bg-emerald-500 hover:bg-emerald-700 text-white border-none capitalize leading-normal h-auto py-5"
            >
              Save Edits To Company Form
            </button> -->

            <button
              type="button"
              v-if="form.length > 0"
              @click="wipeFormEditor"
              class="btn rounded text-white bg-red-500 hover:bg-red-700 border-none capitalize leading-normal h-auto py-5"
            >
              <span class="">Cancel</span>
            </button>

            <button
              v-if="form.length > 0"
              type="button"
              @click="submitTestFullForm"
              class="btn rounded capitalize text-gray-50 bg-blue-500 hover:bg-blue-700 border-none leading-normal h-auto py-5"
            >
              Test Form Submit Validation
            </button>
          </div>
        </div>
      </FormKit>
    </div>
  </div>
</template>
