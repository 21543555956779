import { axios, defineStore, xLog, BASE_API_URL } from "./../clientImports";

export const useRolesStore = defineStore({
  id: "rolesStore",
  state: () => ({
    fetchedRoles: null, // the list of existing roles
    roleToCreate: null,
    showCreateRoleModal: false,
    allPermissions: null,
    showEditRoleModal: false,

    idToEdit: null,

    currentEditingRole: null,
  }),
  actions: {
    toggleCreateRoleModal() {
      this.showCreateRoleModal = !this.showCreateRoleModal;
    },
    //used to populate create role permissions options for choices
    async getAllPermissions() {
      const response = await axios.get(`${BASE_API_URL}/read-permissions`);

      this.allPermissions = response.data.permissions;
    },

    async createRole(roleData) {
      const results = await axios.post(`${BASE_API_URL}/create-role`, roleData);

      return results;
    },

    //roles listing
    async getAllRoles() {
      const response = await axios.get(`${BASE_API_URL}/read-roles`);

      //create map and return all minus the tenant_id field

      this.fetchedRoles = response.data.roles.map((role) => {
        return {
          _id: role._id,
          name: role.name,
          display_name: role.display_name,
          description: role.description,
        };
      });

      return response;
    },

    async deleteRole(role_id) {
      const response = await axios.delete(
        `${BASE_API_URL}/delete-role/${role_id}`
      );

      return response;
    },

    openEditRoleModal(roleId) {
      this.showEditRoleModal = !this.showEditRoleModal;

      this.setIdToEdit(roleId);

      this.readRole();
    },

    closeEditRoleModal() {
      this.showEditRoleModal = false;
    },

    setIdToEdit(id) {
      this.idToEdit = id;
    },

    async readRole() {
      const role_id = this.idToEdit;
      const response = await axios.get(`${BASE_API_URL}/read-role/${role_id}`);

      this.currentEditingRole = response.data.role;
    },

    async updateRole(id, roleUpdateData) {
      const response = await axios.patch(`${BASE_API_URL}/update-role/${id}`, {
        roleUpdateData,
      });

      return response.data;
    },
  },
});
