// masterStore.js
import {
  defineStore,
  useChatStore,
  useContactAlertsStore,
  useContactFileStore,
  useContactsStore,
  // useGlobalPromptsStore,
  // useGlobalToastsStore,
  useLoginStore,
  useTenantsStore,
  useUsersStore,
  useNetworkedCompaniesStore,
  useNetworkedUsersStore,

  //utils
  xLog,
} from "../clientImports";

export const useMasterStore = defineStore({
  id: "master",
  actions: {
    resetAllStores() {
      const allStores = [
        useChatStore(),
        useContactAlertsStore(),
        useContactFileStore(),
        useContactsStore(),
        // useGlobalPromptsStore(), //don't wipe
        // useGlobalToastsStore(), //don't wipe
        useLoginStore(),
        useTenantsStore(),
        useUsersStore(),
        useNetworkedCompaniesStore(),
        useNetworkedUsersStore(),
      ];

      //reset each store to its default state
      allStores.forEach((store) => {
        store.$reset();
      });
    },
  },
});
