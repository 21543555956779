<script setup>
defineOptions({
  name: 'ContactAssignments', // Give the component a name
});
import {
  //axios
  axios,

  //vue
  createApp,
  ref,
  reactive,
  watch,
  computed,
  inject,
  provide,
  onMounted,
  onUnmounted,
  nextTick,
  Teleport,

  //pinia
  defineStore,
  createPinia,
  storeToRefs,

  //pinia stores
  useChatStore,
  useContactAlertsStore,
  useContactFileStore,
  useContactsStore,
  useGlobalPromptsStore,
  useGlobalToastsStore,
  useLoginStore,
  useTenantsStore,
  useContactAssignmentsStore,

  //vue-router
  useRouter,
  useRoute,

  //formkit

  //formkit config file

  //fontawesome
  library,
  FontAwesomeIcon,
  fas,
  far,
  fab,

  //global components - all vue components
  registerGlobalComponents,
  xLog,

  //base api url
  BASE_API_URL,
} from "./../clientImports";

const contactAssignmentsStore = useContactAssignmentsStore();
const contactFileStore = useContactFileStore();
const toastsStore = useGlobalToastsStore();

const promptsStore = useGlobalPromptsStore();

// the actual assignment object
let contactAssignments = reactive([
  // { id: 1, department: "creator", userId: 1, username: "Joe Bent" },
  // { id: 2, department: "processor", userId: 2, username: "Justin Land" },
  // { id: 3, department: "sales", userId: 3, username: "Johnny Mayoral" },
]);

// the options for the assignment department
let assignmentDepartmentOptions = ref([]);

let tenantsUserOptions = ref([]);

const deleteAssignment = async (fieldName, index) => {
  try {
    promptsStore.addPrompt({
      type: "Assignment",
      title: "Delete Assignment?",
      message: `Are You Sure You Want to Delete This User Assignment`,
    });

    const promptResponse = await promptsStore.waitForPromptConfirmation();

    if (promptResponse === true) {
      await contactFileStore.deleteManyToOneSingle("assignments", index);

      promptsStore.removePrompt();

      toastsStore.addToast({
        title: "Assignment Deleted",
        description: "Assignment has been deleted",
        buttonExists: false,
        color: "red",
        type: 1, // 1 = small, 2 = md, 3 = lg, 4 = lg dismissable
      });
    } else if (promptResponse === false) {
      //do nothing
      promptsStore.removePrompt();
    }
  } catch (error) {
    console.log(error);
  }
};

const contactAssignmentsModal = ref(false);
const togglecontactAssignmentsModal = () => {
  contactAssignmentsModal.value = !contactAssignmentsModal.value;
};

onMounted(async () => {
  await contactAssignmentsStore.fetchDepartmentsOptions();
  await contactAssignmentsStore.fetchUsersOptions();

  watch(
    () => contactFileStore.currentContactFile?.assignments,
    (newVal, oldVal) => {
      contactAssignments = newVal;

      console.log("FETCHED CONTACT ASSIGNMENTS: ", contactAssignments);
    }
  );
});

const createNewAssignment = async () => {
  if (assignmentToAdd.department_id !== 0 && assignmentToAdd.user_id !== 0) {
    await contactFileStore.createManyToOneSingle(
      "assignments",
      assignmentToAdd
    );
    assignmentToAdd = {
      department_id: "",
      user_id: "",
    };
  }
};

const updateAssignment = (assignmentIndex, assignment) => {
  if (assignmentToAdd.department_id !== 0 && assignmentToAdd.user_id !== 0) {
    contactFileStore.updateManyToOneSingle(
      "assignments",
      assignmentIndex,
      assignment
    );

    toastsStore.addToast({
      title: "Assignment Updated",
      buttonExists: false,
      color: "emerald",
      type: 1, // 1 = small, 2 = md, 3 = lg, 4 = lg dismissable
    });
  } else {
  }
};

let assignmentToAdd = reactive({
  department_id: 0,
  user_id: 0,
});

const showAssignmentAdder = ref(false);

const toggleAssignmentAdder = () => {
  showAssignmentAdder.value = !showAssignmentAdder.value;
};
</script>

<template>
  <div class="bg-base-100 flex flex-row items-center">
    <div
      class="bg-indigo-500 text-white p-1 md:p-2 flex-1 text-xs md:text-sm whitespace-nowrap flex flex-row items-center"
    >
      <span class="flex-1">
        <font-awesome-icon icon="fa-solid fa-users-between-lines" /> Assignments
      </span>
      <span
        :class="`${
          contactAssignments.length === 0 ? 'bg-red-500' : 'bg-sky-500'
        }`"
        class="p-0.5 rounded text-xs border border-white"
        >{{
          contactAssignments.length > 0
            ? `${contactAssignments.length} ${
                contactAssignments.length > 1 ? "Users" : "User"
              }`
            : "None"
        }}
      </span>
    </div>
    <div class="self-stretch">
      <button
        @click.stop="toggleAssignmentAdder"
        class="bg-blue-500 px-4 text-white capitalize rounded-none h-full"
      >
        <font-awesome-icon icon="fa-solid fa-plus" size="sm" />
      </button>
    </div>
  </div>

  <!-- ADDER -->
  <div class="flex flex-col text-gray-700 bg-white">
    <div v-if="showAssignmentAdder" class="flex flex-col">
      <div class="flex flex-row justify-between items-center gap-0">
        <div class="capitalize font-bold w-3/6">
          <select
            @change="createNewAssignment()"
            class="text-white w-full border-none select-sm py-0 px-3 text-xs md:text-sm"
            type="select"
            v-model="assignmentToAdd.department_id"
          >
            <option
              :value="assignmentAdding.value"
              v-for="(assignmentAdding, i) in [
                {
                  label: '- Select Dept -',
                  value: 0,
                },
                ...contactAssignmentsStore.tenantsDepartmentsOptions,
              ]"
            >
              {{ assignmentAdding.label }}
            </option>
          </select>
        </div>

        <div class="text-right w-3/6">
          <select
            @change="createNewAssignment()"
            class="text-white w-full border-none select-sm py-0 px-3 text-xs md:text-sm"
            type="select"
            v-model="assignmentToAdd.user_id"
          >
            <option
              :value="assignmentAdding.value"
              v-for="(assignmentAdding, i) in [
                {
                  department_id: 0,
                  user_id: 0,
                  label: '- Select User -',
                  value: 0,
                },
                ...contactAssignmentsStore.tenantsUsersOptions,
              ]"
            >
              {{ assignmentAdding.label }}
            </option>
          </select>
        </div>

        <button
          @click.stop="toggleAssignmentAdder"
          class="btn btn-sm bg-red-500 hover:bg-red-600 border-none rounded-none text-white"
        >
          <font-awesome-icon icon="fa-solid fa-xmark" />
        </button>
      </div>
    </div>
    <!-- LOOPED ASSIGNMENTS -->
    <div
      v-for="(assignment, assignmentIndex) in contactFileStore
        .currentContactFile?.assignments"
      :key="assignmentIndex"
      class="flex flex-col"
    >
      <div class="flex flex-row justify-between items-center bg-base-100">
        <div class="capitalize font-bold w-3/6">
          <FormKit
            @change="updateAssignment(assignmentIndex, assignment)"
            outer-class="m-0 rounded-none bg-base-100"
            input-class="$reset text-white w-full border-none select-sm py-0 px-3 text-xs md:text-sm"
            inner-class="rounded-none border-none"
            type="select"
            :options="[
              {
                department_id: 0,
                user_id: 0,
                label: 'Select Dept',
                value: 0,
                attrs: { disabled: true },
              },
              ...contactAssignmentsStore.tenantsDepartmentsOptions,
            ]"
            v-model="assignment.department_id"
            :value="assignment._id"
          />
        </div>

        <div class="text-right w-3/6">
          <FormKit
            @change="updateAssignment(assignmentIndex, assignment)"
            outer-class="m-0 rounded-none bg-base-100"
            input-class="$reset py-0 px-3 select-sm text-white w-full border-none text-xs md:text-sm"
            inner-class="rounded-none border-none"
            type="select"
            :options="[
              {
                department_id: 0,
                user_id: 0,
                label: 'Select User',
                value: 0,
                attrs: { disabled: true },
              },
              ...contactAssignmentsStore.tenantsUsersOptions,
            ]"
            v-model="assignment.user_id"
            :value="assignment.user_id"
          />
        </div>

        <button
          @click="deleteAssignment('assignments', assignment._id)"
          class="btn btn-sm bg-red-500 hover:bg-red-600 border border-slate-400 rounded-none text-white"
        >
          <font-awesome-icon icon="fa-solid fa-xmark" />
        </button>
      </div>
    </div>
  </div>
</template>
