import {
  //axios
  axios,

  //pinia
  defineStore,
  useGlobalToastsStore,
  useGlobalPromptsStore,

  //env base url
  BASE_API_URL,
} from "../clientImports";

export const useTenantsTemplatesSmsStore = defineStore({
  id: "tenants-templates-sms",
  state: () => ({
    showTenantsTemplatesSmsCreateModal: false,
    showTenantsTemplatesSmsEditModal: false,

    idToEdit: "",

    tenantsTemplatesSmsList: [],

    currentlyCreatingTemplatesSms: {
      active: true,
      name: "",
      description: "",
      from_number: "",
      to_number: "{phone_primary}",
      body: "",
    },

    currentlyEditingTemplatesSms: {
      active: true,
      name: "",
      description: "",
      from_number: "",
      to_number: "{phone_primary}",
      body: "",
    },
  }),
  actions: {
    //two responsibilities - set the id to edit and then open the modal
    async initializeTenantsTemplatesSmsEditModal(templatesSmsId) {
      //set's the id of the TemplatesSms that we're editing
      this.setIdToEdit(templatesSmsId);

      //opens the edit modal
      this.showTenantsTemplatesSmsEditModal = true;

      await this.fetchTenantTemplateSms();
    },

    toggleTenantsTemplatesSmsCreateModal() {
      this.showTenantsTemplatesSmsCreateModal =
        !this.showTenantsTemplatesSmsCreateModal;
    },

    toggleTenantsTemplatesSmsEditModal() {
      this.showTenantsTemplatesSmsEditModal =
        !this.showTenantsTemplatesSmsEditModal;
    },

    //fetches the TemplatesSms for listing
    async fetchTenantsTemplatesSms() {
      try {
        const { data } = await axios.get(
          `${BASE_API_URL}/read-tenants-templates-sms`
        );

        if (data.status === "success") {
          this.tenantsTemplatesSmsList = data.templatesSmsOptions.map(
            (templatesSms) => {
              return {
                id: templatesSms._id,
                active: templatesSms.active,
                name: templatesSms.name,
                description: templatesSms.description,
                from_number: templatesSms.from_number,
                to_number: templatesSms.to_number,
                body: templatesSms.body,
              };
            }
          );
        } else {
          console.error(data.message);
        }
      } catch (error) {
        console.error();
      }
    },

    //fetching for editing
    async fetchTenantTemplateSms() {
      const id = this.idToEdit;
      try {
        const { data } = await axios.get(
          `${BASE_API_URL}/read-tenants-template-sms/${id}`
        );

        console.log(data, "FETCHED SMS TEMPLATE");

        if (data.status === "success") {
          console.log(data.templatesSms);
          this.currentlyEditingTemplatesSms = data.templatesSms[0];

          console.log(this.currentlyEditingTemplatesSms);
        } else {
          console.error(data.message);
        }
      } catch (error) {
        console.error();
      }
    },

    setIdToEdit(templatesSmsId) {
      this.idToEdit = templatesSmsId;
    },

    async createTenantsTemplatesSms() {
      const templatesSmsData = this.currentlyCreatingTemplatesSms;

      console.log("SMS TEMPLATE DATA SENDING OUT: ", templatesSmsData);
      try {
        const { data } = await axios.post(
          `${BASE_API_URL}/create-tenants-template-sms`,
          templatesSmsData
        );

        console.log("CREATE PASSPORT RESPONSE", data);

        if (data.status === "success") {
          const toastsStore = useGlobalToastsStore();
          toastsStore.addToast({
            title: "TemplatesSms Created",
            description: "",
            buttonExists: false,
            color: "emerald",
            type: 1,
          });

          //fetch the updated list of TemplatesSms
          this.fetchTenantsTemplatesSms();
          this.toggleTenantsTemplatesSmsCreateModal();
        } else {
          console.error(data.message);
          toastsStore.addToast({
            title: "Error Creating TemplatesSms",
            description: data.message,
            buttonExists: false,
            color: "red",
            type: 1,
          });
        }
      } catch (error) {
        console.error();
      }
    },

    async updateTenantsTemplatesSms() {
      const id = this.idToEdit;
      try {
        const { data } = await axios.put(
          `${BASE_API_URL}/update-tenants-template-sms/${id}`,
          this.currentlyEditingTemplatesSms
        );

        if (data.status === "success") {
          console.log(data.message);

          this.toggleTenantsTemplatesSmsEditModal();
          //fetch the updated list of TemplatesSms
          this.fetchTenantsTemplatesSms();

          //throw toast
          const toastsStore = useGlobalToastsStore();
          toastsStore.addToast({
            title: "TemplatesSms Updated",
            description: "",
            buttonExists: false,
            color: "emerald",
            type: 1,
          });
        } else {
          console.error(data.message);
          toastsStore.addToast({
            title: "Error Updating TemplatesSms",
            description: data.message,
            buttonExists: false,
            color: "red",
            type: 1,
          });
        }
      } catch (error) {
        console.error();
      }
    },

    async deleteTenantsTemplatesSms(templatesSmsId) {
      const promptStore = useGlobalPromptsStore();

      const group_id = templatesSmsId;

      promptStore.addPrompt({
        type: "TemplatesSms",
        title: "Delete TemplatesSms?",
        message: `Are you sure you want to delete this TemplatesSms?`,
      });

      const promptResponse = await promptStore.waitForPromptConfirmation();

      if (promptResponse === true) {
        promptStore.addPrompt({
          type: "TemplatesSms",
          title: "Are You Sure?",
          message: `We'll hide this from your view so it can no longer be used. Any contacts or users that have this templatesSms assigned will remain assigned and have to be manually changed. Do you still want to proceed with the deletion?`,
        });

        const promptResponse2 = await promptStore.waitForPromptConfirmation();

        if (promptResponse2 === true) {
          try {
            const { data } = await axios.delete(
              `${BASE_API_URL}/delete-tenants-template-sms/${group_id}`
            );

            if (data.status === "success") {
              //fetch the updated list of TemplatesSms
              this.fetchTenantsTemplatesSms();
            } else {
              console.error(data.message);
              toastsStore.addToast({
                title: "Error Deleting TemplatesSms",
                description: data.message,
                buttonExists: false,
                color: "red",
                type: 1,
              });
            }
          } catch (error) {
            console.error();
          }
          promptStore.removePrompt();
        } else {
          promptStore.removePrompt();
        }
      } else {
        promptStore.removePrompt();
      }
    },
  },
});
