<script setup>
defineOptions({
  name: 'ContactCommunicationEmail', // Give the component a name
});
import {
  //axios
  axios,

  //vue
  createApp,
  ref,
  reactive,
  watch,
  computed,
  inject,
  provide,
  onMounted,
  onUnmounted,
  nextTick,
  Teleport,

  //pinia
  defineStore,
  createPinia,
  storeToRefs,

  //pinia stores
  useChatStore,
  useContactAlertsStore,
  useContactFileStore,
  useContactsStore,
  useGlobalPromptsStore,
  useGlobalToastsStore,
  useLoginStore,
  useTenantsStore,

  //vue-router
  useRouter,
  useRoute,

  //formkit

  //formkit config file

  //fontawesome
  library,
  FontAwesomeIcon,
  fas,
  far,
  fab,

  //global components - all vue components
  registerGlobalComponents,

  //base api url
  BASE_API_URL,
} from "./../clientImports";

const router = useRouter();
const route = useRoute();

const contactEmails = ref([
  { from: "me", subject: "subject", body: "body test", direction: "outbound" },
  { from: "me", subject: "subject", body: "body test", direction: "inbound" },
  { from: "me", subject: "subject", body: "body test", direction: "outbound" },
  // { from: "me", subject: "subject", body: "body test", direction: "inbound" },
  // { from: "me", subject: "subject", body: "body test", direction: "outbound" },
  // { from: "me", subject: "subject", body: "body test", direction: "inbound" },
  // { from: "me", subject: "subject", body: "body test", direction: "outbound" },
  // { from: "me", subject: "subject", body: "body test", direction: "inbound" },
  // { from: "me", subject: "subject", body: "body test", direction: "outbound" },
  // { from: "me", subject: "subject", body: "body test", direction: "inbound" },
  // { from: "me", subject: "subject", body: "body test", direction: "outbound" },
  // { from: "me", subject: "subject", body: "body test", direction: "inbound" },
  // { from: "me", subject: "subject", body: "body test", direction: "outbound" },
  // { from: "me", subject: "subject", body: "body test", direction: "inbound" },
  // { from: "me", subject: "subject", body: "body test", direction: "outbound" },
  // { from: "me", subject: "subject", body: "body test", direction: "inbound" },
  // { from: "me", subject: "subject", body: "body test", direction: "outbound" },
  // { from: "me", subject: "subject", body: "body test", direction: "inbound" },
  // { from: "me", subject: "subject", body: "body test", direction: "outbound" },
  // { from: "me", subject: "subject", body: "body test", direction: "inbound" },
  // { from: "me", subject: "subject", body: "body test", direction: "outbound" },
  // { from: "me", subject: "subject", body: "body test", direction: "inbound" },
  // { from: "me", subject: "subject", body: "body test", direction: "outbound" },
  // { from: "me", subject: "subject", body: "body test", direction: "inbound" },
  // { from: "me", subject: "subject", body: "body test", direction: "outbound" },
  // { from: "me", subject: "subject", body: "body test", direction: "inbound" },
  // { from: "me", subject: "subject", body: "body test", direction: "outbound" },
  // { from: "me", subject: "subject", body: "body test", direction: "inbound" },
  // { from: "me", subject: "subject", body: "body test", direction: "outbound" },
  // { from: "me", subject: "subject", body: "body test", direction: "inbound" },
  // { from: "me", subject: "subject", body: "body test", direction: "outbound" },
  // { from: "me", subject: "subject", body: "body test", direction: "inbound" },
]);
</script>

<template>
  <div class="flex flex-col h-full">
    <!-- <div class="bg-white w-full flex flex-row justify-between p-2">
      <button class="btn capitalize bg-blue-500 border-none text-white btn-sm">
        Show Merge Fields
      </button>
    </div> -->
    <div class="w-full flex-1 bg-gray-100 flex flex-row justify-between">
      <div class="flex flex-col h-full">
        <!-- <div class="p-3 bg-slate-600 text-white">Methods</div> -->
        <button
          @click=""
          class="bold bg-white capitalize p-3 rounded-none text-gray-700 hover:bg-emerald-400 hover:cursor-pointer hover:text-white border-b border-x-0 border-t-0 border-slate-300"
        >
          Conversation
        </button>
        <button
          @click=""
          class="bold bg-white capitalize p-3 rounded-none text-gray-700 hover:bg-emerald-400 hover:cursor-pointer hover:text-white border-b border-x-0 border-t-0 border-slate-300"
        >
          Inbox
        </button>
        <button
          class="bold bg-white capitalize p-3 rounded-none text-gray-700 hover:bg-emerald-400 hover:cursor-pointer hover:text-white border-b border-x-0 border-t-0 border-slate-300"
        >
          Sent
        </button>
      </div>
      <!-- DATA FORMS -->
      <div
        class="overflow-y-auto flex flex-col flex-1 border-x border-slate-400 bg-white"
      >
        <div
          class="h-full flex flex-col overflow-y-auto bg-white rounded-none text-gray-700 border-b-0 border-x-0 border-t-0 border-slate-300"
        >
          <!-- empty div to hold the form no-style -->
          <div class="overflow-y-auto w-full flex flex-col flex-1 justify-end">
            <div
              v-for="(email, emailIndex) in contactEmails"
              :key="emailIndex"
              :class="[
                email.direction === 'inbound' ? 'chat-start ' : 'chat-end',
              ]"
              class="chat w-full"
            >
              <div
                :class="[
                  email.direction === 'inbound'
                    ? 'bg-emerald-500'
                    : 'bg-blue-500',
                ]"
                class="chat-bubble text-white w-full"
              >
                <p>From: {{ email.from }}</p>
                <p>Subject: {{ email.subject }}</p>
                <p>Body: {{ email.body }}</p>
              </div>
            </div>
          </div>
          <div class="flex flex-row justify-between">
            <input type="text" class="input w-full text-white rounded-none" />
            <button class="btn bg-blue-500 border-none rounded-none text-white">
              Send Email
            </button>
          </div>
        </div>
      </div>
      <!-- GROUP LINKS -->
      <div class="flex flex-col w-52">
        <div class="p-3 bg-slate-400 text-white">Groups</div>
      </div>
    </div>
    <!-- <div class="p-2 bg-white w-full flex flex-row justify-between items-center">
      <button class="btn capitalize bg-blue-500 border-none text-white">
        Send Form
      </button>
      <div class="flex flex-row gap-2">
        <button
          class="btn capitalize bg-emerald-500 border-none text-white rouned-none"
        >
          Save
        </button>
        <div class="dropdown dropdown-top dropdown-end">
          <label tabindex="0" class="btn bg-emerald-700 text-white border-none">
            <font-awesome-icon icon="fa-solid fa-chevron-down" />
          </label>
          <ul
            tabindex="0"
            class="dropdown-content menu shadow bg-base-100 w-52 rouned-lg"
          >
            <li class="bg-emerald-700 rouned-lg">
              <a href="" class="text-white">Save Static Copy</a>
            </li>
          </ul>
        </div>
      </div>
    </div> -->
  </div>
</template>
