import {
  //axios
  axios,

  //pinia
  defineStore,
  useGlobalToastsStore,
  useGlobalPromptsStore,

  //env base url
  BASE_API_URL,
} from "../clientImports";

export const useTenantsStagesStore = defineStore({
  id: "tenants-stages",
  state: () => ({
    fetchedTenantsStagesOptions: [],

    showTenantsStagesCreateModal: false,
    showTenantsStagesEditModal: false,

    idToEdit: "",

    tenantsStagesList: [],

    currentlyCreatingStage: {
      name: "",
      color: "",
      description: "",
    },

    currentlyEditingStage: {
      name: "",
      color: "",
      description: "",
    },
  }),
  actions: {
    //two responsibilities - set the id to edit and then open the modal
    async initializeTenantsStagesEditModal(stageId) {
      //set's the id of the Stage that we're editing
      this.setIdToEdit(stageId);

      this.showTenantsStagesEditModal = true;

      await this.fetchTenantStage();
      //opens the edit modal
    },

    toggleTenantsStagesCreateModal() {
      this.showTenantsStagesCreateModal = !this.showTenantsStagesCreateModal;
    },

    toggleTenantsStagesEditModal() {
      this.showTenantsStagesEditModal = !this.showTenantsStagesEditModal;
    },

    //fetch tenants stages options
    async fetchTenantsStagesOptions() {
      try {
        const { data } = await axios.get(
          `${BASE_API_URL}/fetch-tenants-stages`
        );

        if (data.status === "success") {
          this.fetchedTenantsStagesOptions = data.stagesOptions.map((stage) => {
            return {
              id: stage._id,
              color: stage.color,
              name: stage.name,
            };
          });
        }
      } catch (error) {
        console.error();
      }
    },

    //fetches the Stages for listing
    async fetchTenantsStages() {
      try {
        const { data } = await axios.get(`${BASE_API_URL}/read-tenants-stages`);

        if (data.status === "success") {
          this.tenantsStagesList = data.stagesOptions.map((stage) => {
            return {
              id: stage._id,
              name: stage.name,
              description: stage.description,
              system_default: stage.system_default,
            };
          });
        } else {
          console.error(data.message);
        }
      } catch (error) {
        console.error();
      }
    },

    //fetching for editing
    async fetchTenantStage() {
      const id = this.idToEdit;
      try {
        const { data } = await axios.get(
          `${BASE_API_URL}/read-tenants-stage/${id}`
        );

        console.log(data, "FETCHED MILESTONE");

        if (data.status === "success") {
          console.log(data.stage);
          this.currentlyEditingStage = data.stage[0];

          console.log(this.currentlyEditingStage);
        } else {
          console.error(data.message);
        }
      } catch (error) {
        console.error();
      }
    },

    setIdToEdit(stageId) {
      this.idToEdit = stageId;
    },

    async createTenantsStage() {
      const stageData = this.currentlyCreatingStage;

      console.log("PASSPORT DATA SENDING OUT: ", stageData);
      try {
        const { data } = await axios.post(
          `${BASE_API_URL}/create-tenants-stage`,
          stageData
        );

        console.log("CREATE PASSPORT RESPONSE", data);

        if (data.status === "success") {
          const toastsStore = useGlobalToastsStore();
          toastsStore.addToast({
            title: "Stage Created",
            description: "",
            buttonExists: false,
            color: "emerald",
            type: 1,
          });

          //fetch the updated list of Stages
          this.fetchTenantsStages();
          this.toggleTenantsStagesCreateModal();
        } else {
          console.error(data.message);
          toastsStore.addToast({
            title: "Error Creating Stage",
            description: data.message,
            buttonExists: false,
            color: "red",
            type: 1,
          });
        }
      } catch (error) {
        console.error();
      }
    },

    async updateTenantsStage() {
      const id = this.idToEdit;
      try {
        const { data } = await axios.put(
          `${BASE_API_URL}/update-tenants-stage/${id}`,
          this.currentlyEditingStage
        );

        if (data.status === "success") {
          console.log(data.message);

          this.toggleTenantsStagesEditModal();
          //fetch the updated list of Stages
          this.fetchTenantsStages();

          //throw toast
          const toastsStore = useGlobalToastsStore();
          toastsStore.addToast({
            title: "Stage Updated",
            description: "",
            buttonExists: false,
            color: "emerald",
            type: 1,
          });
        } else {
          console.error(data.message);
          toastsStore.addToast({
            title: "Error Updating Stage",
            description: data.message,
            buttonExists: false,
            color: "red",
            type: 1,
          });
        }
      } catch (error) {
        console.error();
      }
    },

    async deleteTenantsStage(stageId) {
      const promptStore = useGlobalPromptsStore();

      const group_id = stageId;

      promptStore.addPrompt({
        type: "Stage",
        title: "Delete Stage?",
        message: `Are you sure you want to delete this Stage?`,
      });

      const promptResponse = await promptStore.waitForPromptConfirmation();

      if (promptResponse === true) {
        promptStore.addPrompt({
          type: "Stage",
          title: "Are You Sure?",
          message: `We'll hide this from your view so it can no longer be used. Any contacts or users that have this stage assigned will remain assigned and have to be manually changed. Do you still want to proceed with the deletion?`,
        });

        const promptResponse2 = await promptStore.waitForPromptConfirmation();

        if (promptResponse2 === true) {
          try {
            const { data } = await axios.delete(
              `${BASE_API_URL}/delete-tenants-stage/${group_id}`
            );

            if (data.status === "success") {
              //fetch the updated list of Stages
              this.fetchTenantsStages();
            } else {
              console.error(data.message);
              toastsStore.addToast({
                title: "Error Deleting Stage",
                description: data.message,
                buttonExists: false,
                color: "red",
                type: 1,
              });
            }
          } catch (error) {
            console.error();
          }
          promptStore.removePrompt();
        } else {
          promptStore.removePrompt();
        }
      } else {
        promptStore.removePrompt();
      }
    },
  },
});
