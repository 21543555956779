<script setup>
defineOptions({
  name: 'PasswordResetPage', // Give the component a name
});
import {
  //axios
  axios,

  //vue
  createApp,
  ref,
  reactive,
  watch,
  computed,
  inject,
  provide,
  onMounted,
  onUnmounted,
  nextTick,
  Teleport,

  //pinia
  defineStore,
  createPinia,
  storeToRefs,

  //pinia stores
  useChatStore,
  useContactAlertsStore,
  useContactFileStore,
  useContactsStore,
  useGlobalPromptsStore,
  useGlobalToastsStore,
  useLoginStore,
  useTenantsStore,

  //vue-router
  useRouter,
  useRoute,

  //formkit

  //formkit config file

  //fontawesome
  library,
  FontAwesomeIcon,
  fas,
  far,
  fab,

  //global components - all vue components
  registerGlobalComponents,

  //utils
  xLog,

  //base api url
  BASE_API_URL,
} from "./../clientImports";

// Call the useLoginStore function to get an instance of the store
const loginStore = useLoginStore();

const toastsStore = useGlobalToastsStore();

const router = useRouter();
const route = useRoute();

// TODO - un commment these and delete static credentials before launch
// const companyName = ref("");
// const email = ref("");
// const password = ref("");

const companyName = ref("finpi");
const email = ref("nick@finnypi.app");
const password = ref("finnypi123!");

const passwordReset = async () => {};
</script>

<template>
  <div
    class="flex flex-col justify-center items-center h-screen bg-gradient-to-br from-orange-300 to-orange-600 p-5"
  >
    <!-- Form -->

    <div class="bg-white p-10 rounded w-full md:w-96">
      <div class="w-full text-center">
        <cSystemLogo></cSystemLogo>
      </div>
      <h3
        class="text-lg text-center text-white mb-8 bg-gradient-to-br from-orange-300 to-orange-600 mt-2"
      >
        Account Password Recovery
      </h3>

      <FormKit
        type="form"
        :incomplete-message="false"
        submit-label="Reset Password"
        @submit="passwordReset"
        actions-class="mt-8"
        :actions="false"
        :submit-attrs="{
          wrapperClass: 'flex flex-row justify-between',
          inputClass:
            '$reset bg-blue-500 w-full max-w-full rounded py-3 px-2 text-white',
          ignore: false,
        }"
      >
        <FormKit
          label-class="text-gray-700"
          inner-class="mt-2 mb-2"
          label="Company Abbreviation"
          type="text"
          input-class="bg-base-300 text-white w-full"
          validation="required|length:3"
          :validation-messages="{ length: '3 Character Minimum' }"
          messages-class="relative"
          message-class="absolute -top-5 right-2 bg-orange-500 rounded text-xs text-white p-1"
          v-model="companyName"
        />

        <FormKit
          label-class="text-gray-700"
          inner-class="mt-2 mb-2"
          label="Email"
          type="text"
          input-class="bg-base-300 text-white w-full"
          validation="required|email"
          :validation-messages="{ email: 'Invalid Email Format' }"
          messages-class="relative"
          message-class="absolute -top-5 right-2 bg-orange-500 rounded text-xs text-white p-1"
          v-model="email"
        />

        <FormKit
          type="button"
          label="Request Password Reset"
          input-class="$reset bg-gradient-to-br from-orange-300 to-orange-600 w-full max-w-full rounded py-3 px-2 text-white mt-7"
        />

        <div class="flex flex-row justify-between gap-4">
          <FormKit
            type="button"
            label="Sign Up"
            outer-class="w-full"
            input-class="$reset btn btn-sm rounded capitalize text-white bg-emerald-500 hover:bg-emerald-700 border-none w-full"
            @click="router.push({ name: 'sign-up' })"
          />

          <FormKit
            type="button"
            label="Sign In"
            outer-class="w-full"
            input-class="$reset btn btn-sm rounded capitalize text-white bg-indigo-500 hover:bg-indigo-700 border-none w-full"
            @click="router.push({ name: 'login' })"
          />
        </div>
      </FormKit>
    </div>
  </div>
</template>
