import {
  axios,
  createRouter,
  createWebHistory,
  BASE_API_URL,
  xLog,

  //stores
  useUsersStore,
  useTenantsCampaignsStore,
  useLoginStore,
  useRolesStore,
  useMasterStore,
  useGlobalToastsStore,
  useTenantsAffiliateGroupsStore,
  useTenantsDepartmentsStore,
  useTenantsMilestonesStore,
  useTenantsPassportsStore,
  useTenantsPhoneNumbersStore,
  useTenantsTemplatesEmailsStore,
  useTenantsTemplatesNotesStore,
  useTenantsContactTypesStore,
  useTenantsStagesStore,
  useTenantsWorkflowStatusesStore,
  useTenantsBotsStore,
  useTenantsTemplatesSmsStore,
  useTenantsTagsStore,
  useTenantsAnnouncementsStore,
  useTenantsAccountTypesStore,
  useTenantsScheduleTypesStore,
  useTenantsGatewaysStore,
  useWebsiteStore,
  useSupportTicketsStore,
  useTenantsSessionsStore,
  useTenantsTemplatesNotificationsStore,
  useTenantsPaymentsPresetsStore,
  useTenantsRebuttalsStore,
  useTenantsPaymentsRoutesStore,
  useGptStore,
} from "./clientImports";

import { getGlobalComponents } from "./helpers/globalComponents";

const allComponents = getGlobalComponents();

// const Website = () => import("./components/Website.vue");

// const cReusableComponents = () =>
//   import("./components/cReusableComponents.vue");
// const cLoadingSpinner = () => import("./components/cLoadingSpinner.vue");
// const cColumnSelector = () => import("./components/cColumnSelector.vue");
// const cTable = () => import("./components/cTable.vue");
// const cTinyEditor = () => import("./components/cTinyEditor.vue");
// const cPayWall = () => import("./components/cPayWall.vue");
// const cTermsViolation = () => import("./components/cTermsViolation.vue");
// const cSidebarModal = () => import("./components/cSidebarModal.vue");
// const cSystemLogo = () => import("./components/cSystemLogo.vue");
// const ContactFileNameBar = () => import("./components/ContactFileNameBar.vue");
// const cFlagManyToOne = () => import("./components/cFlagManyToOne.vue");
// const cFlagOneToOne = () => import("./components/cFlagOneToOne.vue");
// const cFileRead = () => import("./components/cFileRead.vue");
// const cFileUpload = () => import("./components/cFileUpload.vue");
// const cFileDownload = () => import("./components/cFileDownload.vue");
// const cTaglist = () => import("./components/cTaglist.vue");

// const MainPage = () => import("./components/MainPage.vue");
// const DashboardTab = () => import("./components/DashboardTab.vue");
// const ContactsTab = () => import("./components/ContactsTab.vue");
// const TasksTab = () => import("./components/TasksTab.vue");
// const EventsTab = () => import("./components/EventsTab.vue");
// const ReportsTab = () => import("./components/ReportsTab.vue");
// const BillablesTab = () => import("./components/BillablesTab.vue");
// const PayrollTab = () => import("./components/PayrollTab.vue");
// const SettingsTab = () => import("./components/SettingsTab.vue");
// const SettingsTenants = () => import("./components/SettingsTenants.vue");
// const SettingsTenantsCreate = () =>
//   import("./components/SettingsTenantsCreate.vue");
// const SettingsAccountProfile = () =>
//   import("./components/SettingsAccountProfile.vue");
// const SettingsAccountTypesList = () =>
//   import("./components/SettingsAccountTypesList.vue");
// const SettingsAccountTypesCreate = () =>
//   import("./components/SettingsAccountTypesCreate.vue");
// const SettingsAccountTypesEdit = () =>
//   import("./components/SettingsAccountTypesEdit.vue");
// const SettingsAnnouncementsList = () =>
//   import("./components/SettingsAnnouncementsList.vue");
// const SettingsAnnouncementsCreate = () =>
//   import("./components/SettingsAnnouncementsCreate.vue");
// const SettingsAnnouncementsEdit = () =>
//   import("./components/SettingsAnnouncementsEdit.vue");
// const SettingsApiInbound = () => import("./components/SettingsApiInbound.vue");
// const SettingsApiOutbound = () =>
//   import("./components/SettingsApiOutbound.vue");

// const SettingsBotsList = () => import("./components/SettingsBotsList.vue");
// const SettingsBotsEdit = () => import("./components/SettingsBotsEdit.vue");
// const SettingsBotsCreate = () => import("./components/SettingsBotsCreate.vue");

// const SettingsCampaigns = () => import("./components/SettingsCampaigns.vue");

// const SettingsCampaignsList = () =>
//   import("./components/SettingsCampaignsList.vue");
// const SettingsCommissions = () =>
//   import("./components/SettingsCommissions.vue");
// const SettingsAffiliateGroupsList = () =>
//   import("./components/SettingsAffiliateGroupsList.vue");
// const SettingsAffiliateGroupsCreate = () =>
//   import("./components/SettingsAffiliateGroupsCreate.vue");
// const SettingsAffiliateGroupsEdit = () =>
//   import("./components/SettingsAffiliateGroupsEdit.vue");

// const SettingsContactTypesList = () =>
//   import("./components/SettingsContactTypesList.vue");
// const SettingsContactTypesCreate = () =>
//   import("./components/SettingsContactTypesCreate.vue");
// const SettingsContactTypesEdit = () =>
//   import("./components/SettingsContactTypesEdit.vue");

// const SettingsDepartmentsList = () =>
//   import("./components/SettingsDepartmentsList.vue");
// const SettingsDepartmentsCreate = () =>
//   import("./components/SettingsDepartmentsCreate.vue");
// const SettingsDepartmentsEdit = () =>
//   import("./components/SettingsDepartmentsEdit.vue");

// const SettingsDocuments = () => import("./components/SettingsDocuments.vue");
// const SettingsTemplatesEmailsList = () =>
//   import("./components/SettingsTemplatesEmailsList.vue");
// const SettingsTemplatesEmailsCreate = () =>
//   import("./components/SettingsTemplatesEmailsCreate.vue");
// const SettingsTemplatesEmailsEdit = () =>
//   import("./components/SettingsTemplatesEmailsEdit.vue");
// const SettingsEvents = () => import("./components/SettingsEvents.vue");
// const SettingsExpert = () => import("./components/SettingsExpert.vue");
// const SettingsFormBuilder = () =>
//   import("./components/SettingsFormBuilder.vue");
// const SettingsForms = () => import("./components/SettingsForms.vue");
// const SettingsMilestonesList = () =>
//   import("./components/SettingsMilestonesList.vue");
// const SettingsMilestonesCreate = () =>
//   import("./components/SettingsMilestonesEdit.vue");
// const SettingsMilestonesEdit = () =>
//   import("./components/SettingsMilestonesEdit.vue");
// const SettingsTemplatesNotesList = () =>
//   import("./components/SettingsTemplatesNotesList.vue");
// const SettingsTemplatesNotesCreate = () =>
//   import("./components/SettingsTemplatesNotesCreate.vue");
// const SettingsTemplatesNotesEdit = () =>
//   import("./components/SettingsTemplatesNotesEdit.vue");
// const SettingsTemplateNotificationsCreate = () =>
//   import("./components/SettingsTemplatesNotificationsCreate.vue");
// const SettingsTemplatesNotificationsList = () =>
//   import("./components/SettingsTemplatesNotificationsList.vue");
// const SettingsTemplateNotificationsEdit = () =>
//   import("./components/SettingsTemplatesNotificationsEdit.vue");
// const SettingsOccurences = () => import("./components/SettingsOccurences.vue");
// const SettingsPassportsList = () =>
//   import("./components/SettingsPassportsList.vue");
// const SettingsPassportsCreate = () =>
//   import("./components/SettingsPassportsCreate.vue");
// const SettingsPassportsEdit = () =>
//   import("./components/SettingsPassportsEdit.vue");
// const SettingsWorkflowStatusesList = () =>
//   import("./components/SettingsWorkflowStatusesList.vue");
// const SettingsWorkflowStatusesCreate = () =>
//   import("./components/SettingsWorkflowStatusesCreate.vue");
// const SettingsWorkflowStatusesEdit = () =>
//   import("./components/SettingsWorkflowStatusesEdit.vue");
// const SettingsGatewaysList = () =>
//   import("./components/SettingsGatewaysList.vue");
// const SettingsPaymentsPresetsCreate = () =>
//   import("./components/SettingsPaymentsPresetsCreate.vue");
// const SettingsPaymentsPresetsList = () =>
//   import("./components/SettingsPaymentsPresetsList.vue");
// const SettingsPaymentsPresetsEdit = () =>
//   import("./components/SettingsPaymentsPresetsEdit.vue");
// const SettingsPaymentsRoutesList = () =>
//   import("./components/SettingsPaymentsRoutesList.vue");
// const SettingsScheduleTypesList = () =>
//   import("./components/SettingsScheduleTypesList.vue");

// const SettingsPhoneNumbersList = () =>
//   import("./components/SettingsPhoneNumbersList.vue");
// const SettingsPhoneNumbersCreate = () =>
//   import("./components/SettingsPhoneNumbersCreate.vue");
// const SettingsPhoneNumbersEdit = () =>
//   import("./components/SettingsPhoneNumbersEdit.vue");

// const SettingsRoles = () => import("./components/SettingsRoles.vue");
// const SettingsRolesCreate = () =>
//   import("./components/SettingsRolesCreate.vue");
// const SettingsRolesList = () => import("./components/SettingsRolesList.vue");

// const SettingsRebuttalsList = () =>
//   import("./components/SettingsRebuttalsList.vue");
// const SettingsRebuttalsCreate = () =>
//   import("./components/SettingsRebuttalsCreate.vue");
// const SettingsRebuttalsEdit = () =>
//   import("./components/SettingsRebuttalsEdit.vue");

// const SettingsStagesList = () => import("./components/SettingsStagesList.vue");
// const SettingsStagesCreate = () =>
//   import("./components/SettingsStagesCreate.vue");
// const SettingsStagesEdit = () => import("./components/SettingsStagesEdit.vue");

// const SettingsTemplatesSmsList = () =>
//   import("./components/SettingsTemplatesSmsList.vue");
// const SettingsTemplatesSmsCreate = () =>
//   import("./components/SettingsTemplatesSmsCreate.vue");
// const SettingsTemplatesSmsEdit = () =>
//   import("./components/SettingsTemplatesSmsEdit.vue");

// const SettingsSubscription = () =>
//   import("./components/SettingsSubscription.vue");
// const SettingsEmailDomains = () =>
//   import("./components/SettingsEmailDomains.vue");
// const SettingsTagsList = () => import("./components/SettingsTagsList.vue");
// const SettingsTasks = () => import("./components/SettingsTasks.vue");
// const SettingsUsage = () => import("./components/SettingsUsage.vue");
// const SettingsUsers = () => import("./components/SettingsUsers.vue");
// const SupportTab = () => import("./components/SupportTab.vue");
// const SupportArticles = () => import("./components/SupportArticles.vue");
// const SupportTicketsList = () => import("./components/SupportTicketsList.vue");

// const SupportEmail = () => import("./components/SupportEmail.vue");
// const SupportPhone = () => import("./components/SupportPhone.vue");
// const SupportGPT = () => import("./components/SupportGPT.vue");
// const SupportGPTSettings = () => import("./components/SupportGPTSettings.vue");
// const LoginPage = () => import("./components/LoginPage.vue");
// const SignUpPage = () => import("./components/SignUpPage.vue");
// const PasswordResetPage = () => import("./components/PasswordResetPage.vue");
// const ContactFilePage = () => import("./components/ContactFilePage.vue");
// const ContactFormsTabWrapper = () =>
//   import("./components/ContactFormsTabWrapper.vue");
// const ContactCommunicationTab = () =>
//   import("./components/ContactCommunicationTab.vue");
// const ContactCommunicationEmail = () =>
//   import("./components/ContactCommunicationEmail.vue");
// const ContactCommunicationSms = () =>
//   import("./components/ContactCommunicationSms.vue");
// const ContactNotesTab = () => import("./components/ContactNotesTab.vue");
// const ContactHistoryTab = () => import("./components/ContactHistoryTab.vue");
// const ContactAttachmentsTab = () =>
//   import("./components/ContactAttachmentsTab.vue");

// const GlobalModal = () => import("./components/GlobalModal.vue");
// const CreateContact = () => import("./components/CreateContact.vue");
// const SettingsUsersList = () => import("./components/SettingsUsersList.vue");
// const SettingsUsersCreate = () =>
//   import("./components/SettingsUsersCreate.vue");
// const SettingsUsersEdit = () => import("./components/SettingsUsersEdit.vue");
// const SettingsUsersNetwork = () =>
//   import("./components/SettingsUsersNetwork.vue");

// const TenantSwitchScreen = () => import("./components/TenantSwitchScreen.vue");

// //catch all component - 404 page
// const NotFoundPage = () => import("./components/NotFoundPage.vue");

const routes = [
  //website route
  {
    path: "/tenant-switch/:tenantLegalName",
    name: "tenant-switch",
    component: () => import("./components/TenantSwitchScreen.vue"),
  },
  //route to preview reusable components
  {
    path: "/reusable",
    name: "reusable-components",
    component: () => import("./components/cReusableComponents.vue"),
    children: [
      {
        path: "system-logo",
        name: "system-logo",
        component: () => import("./components/cSystemLogo.vue"),
      },
      {
        path: "loading-spinner",
        name: "loading-spinner",
        component: () => import("./components/cLoadingSpinner.vue"),
      },
      {
        path: "column-selector",
        name: "column-selector",
        component: () => import("./components/cColumnSelector.vue"),
      },
      {
        path: "table",
        name: "table",
        component: () => import("./components/cTable.vue"),
      },
      {
        path: "wysiwyg",
        name: "wysiwyg",
        component: () => import("./components/cTinyEditor.vue"),
      },
      {
        path: "sidebar-modal",
        name: "sidebar-modal",
        component: () => import("./components/cSidebarModal.vue"),
      },
      {
        path: "contact-file-name-bar",
        name: "contact-file-name-bar",
        component: () => import("./components/ContactFileNameBar.vue"),
      },
      {
        path: "flag-many-to-one",
        name: "flag-many-to-one",
        component: () => import("./components/cFlagManyToOne.vue"),
      },
      {
        path: "flag-one-to-one",
        name: "flag-one-to-one",
        component: () => import("./components/cFlagOneToOne.vue"),
      },
      {
        path: "read-file",
        name: "read-file",
        component: () => import("./components/cFileRead.vue"),
      },

      {
        path: "upload-file",
        name: "upload-file",
        component: () => import("./components/cFileUpload.vue"),
      },
      {
        path: "download-file",
        name: "download-file",
        component: () => import("./components/cFileDownload.vue"),
      },
      {
        path: "taglist",
        name: "taglist",
        component: () => import("./components/cTaglist.vue"),
      },
    ],
  },
  {
    path: "/home",
    name: "root",
    component: () => import("./components/MainPage.vue"),
    children: [
      {
        path: "dashboard",
        name: "dashboard-tab",
        component: () => import("./components/DashboardTab.vue"),
      },
      {
        path: "contacts",
        name: "contacts-tab",
        component: () => import("./components/ContactsTab.vue"),
      },

      {
        path: "tasks",
        name: "tasks-tab",
        component: () => import("./components/TasksTab.vue"),
      },

      {
        path: "events",
        name: "events-tab",
        component: () => import("./components/EventsTab.vue"),
      },

      {
        path: "reports",
        name: "reports-tab",
        component: () => import("./components/ReportsTab.vue"),
      },

      {
        path: "billables",
        name: "billables-tab",
        component: () => import("./components/BillablesTab.vue"),
      },

      {
        path: "payroll",
        name: "payroll-tab",
        component: () => import("./components/PayrollTab.vue"),
      },

      {
        path: "contact-file/:contact_id",
        name: "contact-file",
        component: () => import("./components/ContactFilePage.vue"),
        children: [
          {
            path: "forms",
            name: "contact-file-forms-tab",
            component: () => import("./components/ContactFormsTabWrapper.vue"),
          },

          {
            path: "communication",
            name: "contact-file-communication-tab",
            component: () => import("./components/ContactCommunicationTab.vue"),
            children: [
              {
                path: "email",
                name: "contact-file-communication-email",
                component: () =>
                  import("./components/ContactCommunicationEmail.vue"),
              },
              {
                path: "sms",
                name: "contact-file-communication-sms",
                component: () =>
                  import("./components/ContactCommunicationSms.vue"),
              },
            ],
          },
          {
            path: "attachments",
            name: "contact-file-attachments-tab",
            component: () => import("./components/ContactAttachmentsTab.vue"),
          },
          {
            path: "notes",
            name: "contact-file-notes-tab",
            component: () => import("./components/ContactNotesTab.vue"),
          },

          {
            path: "history",
            name: "contact-file-history-tab",
            component: () => import("./components/ContactHistoryTab.vue"),
          },
        ],
      },

      {
        path: "settings",
        name: "settings-tab",
        component: () => import("./components/SettingsTab.vue"),
        children: [
          {
            path: "companies",
            name: "settings-companies",
            component: () => import("./components/SettingsTenants.vue"),
            meta: {
              tabTitle: "Companies",
              sectionName: "Tenants",
              sectionSort: 1,
              settingIcon: "fa-regular fa-building",
              sort: 1,
            },
            children: [
              {
                path: "create",
                name: "settings-companies-create",
                component: () =>
                  import("./components/SettingsTenantsCreate.vue"),
              },
            ],
          },
          {
            path: "account-profile",
            name: "settings-account-profile",
            component: () => import("./components/SettingsAccountProfile.vue"),
            meta: {
              tabTitle: "Account Profile",
              sectionName: "Account",
              sectionSort: 1,
              settingIcon: "fa-solid fa-building-user",
              sort: 1,
            },
          },
          {
            path: "account-types",
            name: "settings-account-types-list",
            component: () =>
              import("./components/SettingsAccountTypesList.vue"),
            meta: {
              tabTitle: "Account Types",
              sectionName: "Payments",
              sectionSort: 9,
              settingIcon: "fa-solid fa-credit-card",
              sort: 3,
            },
            children: [
              {
                path: "create",
                name: "settings-account-types-create",
                beforeEnter: (to, from, next) => {
                  const tenantsAccountTypesStore =
                    useTenantsAccountTypesStore();

                  console.log("hello from beforeEach");
                  tenantsAccountTypesStore.showTenantsAccountTypesCreateModal = true;
                  next(false);
                },
              },
              {
                path: "edit/:id",
                name: "settings-account-types-edit", // Naming the route
                beforeEnter: (to, from, next) => {
                  const tenantsAccountTypesStore =
                    useTenantsAccountTypesStore();
                  const id = to.params.id;

                  console.log("STORE EDIT ID: ", id);

                  tenantsAccountTypesStore.initializeTenantsAccountTypesEditModal(
                    id
                  );
                  next(false);
                },
              },
            ],
          },
          {
            path: "announcements",
            name: "settings-announcements",
            component: () =>
              import("./components/SettingsAnnouncementsList.vue"),
            meta: {
              tabTitle: "Announcements",
              sectionName: "Account",
              sectionSort: 1,
              settingIcon: "fa-solid fa-bullhorn",
              sort: 4,
            },
            children: [
              {
                path: "create",
                name: "settings-announcements-create",
                //afterEnter

                beforeEnter: (to, from, next) => {
                  const tenantsAnnouncementsStore =
                    useTenantsAnnouncementsStore();
                  tenantsAnnouncementsStore.toggleTenantsAnnouncementsCreateModal();
                  next(false);
                },
              },
              {
                path: "edit/:id",
                name: "settings-announcements-edit", // Naming the route
                beforeEnter: (to, from, next) => {
                  const tenantsAnnouncementsStore =
                    useTenantsAnnouncementsStore();
                  const id = to.params.id;

                  tenantsAnnouncementsStore.initializeTenantsAnnouncementsEditModal(
                    id
                  );
                  next(false);
                },
              },
            ],
          },
          {
            path: "api-inbound",
            name: "settings-api-inbound",
            component: () => import("./components/SettingsApiInbound.vue"),
            meta: {
              tabTitle: "Inbound",
              sectionName: "Webhooks",
              sectionSort: 8,
              settingIcon: "fa-solid fa-arrow-left-long",
              sort: 1,
            },
          },
          {
            path: "api-outbound",
            name: "settings-api-outbound",
            component: () => import("./components/SettingsApiOutbound.vue"),
            meta: {
              tabTitle: "Outbound",
              sectionName: "Webhooks",
              sectionSort: 8,
              settingIcon: "fa-solid fa-arrow-right-long",
              sort: 2,
            },
          },
          {
            path: "bots-list",
            name: "settings-bots-list",
            component: () => import("./components/SettingsBotsList.vue"),
            meta: {
              tabTitle: "Bots",
              sectionName: "Automation",
              sectionSort: 10,
              settingIcon: "fa-solid fa-robot",
              sort: 1,
            },
            children: [
              {
                path: "create",
                name: "settings-bots-create",
                beforeEnter: (to, from, next) => {
                  const tenantsBotsStore = useTenantsBotsStore();
                  tenantsBotsStore.toggleTenantsBotsCreateModal();
                  next(false);
                },
              },
              {
                path: "edit/:id",
                name: "settings-bots-edit", // Naming the route
                beforeEnter: (to, from, next) => {
                  const tenantsBotsStore = useTenantsBotsStore();
                  const id = to.params.id;

                  console.log("STORE EDIT ID: ", id);

                  tenantsBotsStore.initializeTenantsBotsEditModal(id);
                  next(false);
                },
              },
            ],
          },
          {
            path: "campaigns",
            name: "settings-campaigns",
            component: () => import("./components/SettingsCampaigns.vue"),
            meta: {
              tabTitle: "Campaigns",
              sectionName: "File Associations",
              sectionSort: 3,
              settingIcon: "fa-brands fa-sourcetree",
              sort: 2,
            },
            redirect: { name: "settings-campaigns-list" },
            children: [
              {
                path: "list",
                name: "settings-campaigns-list",
                component: () =>
                  import("./components/SettingsCampaignsList.vue"),
              },
              {
                path: "edit/:id",
                name: "settings-campaigns-edit", // Naming the route
                beforeEnter: (to, from, next) => {
                  const tenantsCampaignsStore = useTenantsCampaignsStore();
                  const id = to.params.id;

                  tenantsCampaignsStore.setIdToEdit(id);
                  tenantsCampaignsStore.toggleEditCampaignModal();
                  next(false);
                },
              },
            ],
          },
          {
            path: "commissions",
            name: "settings-commissions",
            component: () => import("./components/SettingsCommissions.vue"),
            meta: {
              tabTitle: "Commissions",
              sectionName: "User",
              sectionSort: 2,
              settingIcon: "fa-solid fa-sack-dollar",
              sort: 5,
            },
          },
          {
            path: "contact-types-list",
            name: "settings-contact-types-list",
            component: () =>
              import("./components/SettingsContactTypesList.vue"),
            meta: {
              tabTitle: "Contact Types",
              sectionName: "File Associations",
              sectionSort: 3,
              settingIcon: "fa-solid fa-clone",
              sort: 0,
            },
            children: [
              {
                path: "create",
                name: "settings-contact-types-create",
                beforeEnter: (to, from, next) => {
                  const tenantsContactTypesStore =
                    useTenantsContactTypesStore();
                  tenantsContactTypesStore.toggleTenantsContactTypesCreateModal();
                  next(false);
                },
              },
              {
                path: "edit/:id",
                name: "settings-contact-types-edit", // Naming the route
                beforeEnter: (to, from, next) => {
                  const tenantsContactTypesStore =
                    useTenantsContactTypesStore();
                  const id = to.params.id;

                  console.log("STORE EDIT ID: ", id);

                  tenantsContactTypesStore.initializeTenantsContactTypesEditModal(
                    id
                  );

                  next(false);
                },
              },
            ],
          },
          {
            path: "departments-list",
            name: "settings-departments-list",
            component: () => import("./components/SettingsDepartmentsList.vue"),
            meta: {
              tabTitle: "Departments",
              sectionName: "User",
              sectionSort: 2,
              settingIcon: "fa-solid fa-building-user",
              sort: 2,
            },
            children: [
              {
                path: "edit/:id",
                name: "settings-departments-edit", // Naming the route
                beforeEnter: (to, from, next) => {
                  const tenantsDepartmentsStore = useTenantsDepartmentsStore();
                  const id = to.params.id;

                  console.log("STORE EDIT ID: ", id);

                  tenantsDepartmentsStore.initializeTenantsDepartmentsEditModal(
                    id
                  );
                  next(false);
                },
              },
              {
                path: "create",
                name: "settings-departments-create", // Naming the route
                beforeEnter: (to, from, next) => {
                  const tenantsDepartmentsStore = useTenantsDepartmentsStore();
                  tenantsDepartmentsStore.toggleTenantsDepartmentsCreateModal();
                  next(false);
                },
              },
            ],
          },
          {
            path: "documents",
            name: "settings-documents",
            component: () => import("./components/SettingsDocuments.vue"),
            meta: {
              tabTitle: "Documents",
              sectionName: "Account",
              sectionSort: 1,
              settingIcon: "fa-solid fa-file",
              sort: 5,
            },
          },
          {
            path: "emails",
            name: "settings-templates-emails-list",
            component: () =>
              import("./components/SettingsTemplatesEmailsList.vue"),
            meta: {
              tabTitle: "Emails",
              sectionName: "Templates",
              sectionSort: 4,
              settingIcon: "fa-solid fa-envelope",
              sort: 2,
            },
            children: [
              {
                path: "create",
                name: "settings-templates-emails-create",
                beforeEnter: (to, from, next) => {
                  const tenantsTemplatesEmailsStore =
                    useTenantsTemplatesEmailsStore();
                  tenantsTemplatesEmailsStore.toggleTenantsTemplatesEmailsCreateModal();
                  next(false);
                },
              },
              {
                path: "edit/:id",
                name: "settings-templates-emails-edit", // Naming the route
                beforeEnter: (to, from, next) => {
                  const tenantsTemplatesEmailsStore =
                    useTenantsTemplatesEmailsStore();

                  const id = to.params.id;
                  tenantsTemplatesEmailsStore.initializeTenantsTemplatesEmailsEditModal(
                    id
                  );

                  next(false);
                },
              },
            ],
          },
          {
            path: "events",
            name: "settings-events",
            component: () => import("./components/SettingsEvents.vue"),
            meta: {
              tabTitle: "Events",
              sectionName: "Assignments",
              sectionSort: 5,
              settingIcon: "fa-solid fa-calendar-days",
              sort: 1,
            },
          },
          {
            path: "expert",
            name: "settings-expert",
            component: () => import("./components/SettingsExpert.vue"),
            meta: {
              tabTitle: "Expert Settings",
              sectionName: "Expert",
              settingIcon: "", //not used
              sort: "", //not used
            },
          },
          {
            path: "form-builder",
            name: "settings-form-builder",
            component: () => import("./components/SettingsFormBuilder.vue"),
            meta: {
              tabTitle: "Builder",
              sectionName: "Forms",
              sectionSort: 7,
              settingIcon: "fa-solid fa-hammer",
              sort: 1,
            },
          },
          {
            path: "forms",
            name: "settings-forms",
            component: () => import("./components/SettingsForms.vue"),
            meta: {
              tabTitle: "Forms",
              sectionName: "Forms",
              sectionSort: 7,
              settingIcon: "fa-regular fa-rectangle-list",
              sort: 2,
            },
          },

          {
            path: "rebuttals",
            name: "settings-rebuttals",
            component: () => import("./components/SettingsRebuttalsList.vue"),
            meta: {
              tabTitle: "Rebuttals",
              sectionName: "Forms",
              sectionSort: 7,
              settingIcon: ["fas", "table-tennis-paddle-ball"],
              sort: 3,
            },
            children: [
              {
                path: "create",
                name: "settings-rebuttals-create",
                beforeEnter: (to, from, next) => {
                  const tenantsRebuttalsStore = useTenantsRebuttalsStore();
                  tenantsRebuttalsStore.toggleTenantsRebuttalsCreateModal();
                  next(false);
                },
              },
              {
                path: "edit/:id",
                name: "settings-rebuttals-edit", // Naming the route
                beforeEnter: (to, from, next) => {
                  const tenantsRebuttalsStore = useTenantsRebuttalsStore();
                  const id = to.params.id;

                  tenantsRebuttalsStore.initializeTenantsRebuttalsEditModal(id);
                  next(false);
                },
              },
            ],
          },
          {
            path: "milestones-list",
            name: "settings-milestones-list",
            component: () => import("./components/SettingsMilestonesList.vue"),
            meta: {
              tabTitle: "Milestones",
              sectionName: "File Associations",
              sectionSort: 3,
              settingIcon: "fa-solid fa-star",
              sort: 3,
            },
            children: [
              {
                path: "edit/:id",
                name: "settings-milestones-edit", // Naming the route
                beforeEnter: (to, from, next) => {
                  const tenantsMilestonesStore = useTenantsMilestonesStore();
                  const id = to.params.id;

                  console.log("STORE EDIT ID: ", id);

                  tenantsMilestonesStore.initializeTenantsMilestonesEditModal(
                    id
                  );
                  next(false);
                },
              },
              {
                path: "create",
                name: "settings-milestones-create", // Naming the route
                beforeEnter: (to, from, next) => {
                  const tenantsMilestonesStore = useTenantsMilestonesStore();
                  tenantsMilestonesStore.toggleTenantsMilestonesCreateModal();
                  next(false);
                },
              },
            ],
          },
          {
            path: "notes",
            name: "settings-templates-notes-list",
            component: () =>
              import("./components/SettingsTemplatesNotesList.vue"),
            meta: {
              tabTitle: "Notes",
              sectionName: "Templates",
              sectionSort: 4,
              settingIcon: "fa-solid fa-note-sticky",
              sort: 1,
            },
            children: [
              {
                path: "create",
                name: "settings-templates-notes-create",
                beforeEnter: (to, from, next) => {
                  const tenantsTemplatesNotesStore =
                    useTenantsTemplatesNotesStore();
                  tenantsTemplatesNotesStore.toggleTenantsTemplatesNotesCreateModal();
                  next(false);
                },
              },
              {
                path: "edit/:id",
                name: "settings-templates-notes-edit",
                beforeEnter: (to, from, next) => {
                  const tenantsTemplatesNotesStore =
                    useTenantsTemplatesNotesStore();
                  const id = to.params.id;

                  tenantsTemplatesNotesStore.initializeTenantsTemplatesNotesEditModal(
                    id
                  );
                  next(false);
                },
              },
            ],
          },
          {
            path: "notifications",
            name: "settings-templates-notifications-list",
            component: () =>
              import("./components/SettingsTemplatesNotificationsList.vue"),
            meta: {
              tabTitle: "Notifications",
              sectionName: "Templates",
              sectionSort: 4,
              settingIcon: "fa-solid fa-bell",
              sort: 4,
            },
            children: [
              {
                path: "create",
                name: "settings-templates-notifications-create",
                beforeEnter: (to, from, next) => {
                  const tenantsTemplatesNotificationsStore =
                    useTenantsTemplatesNotificationsStore();
                  tenantsTemplatesNotificationsStore.toggleTenantsTemplatesNotificationsCreateModal();
                  next(false);
                },
              },
              {
                path: "edit/:id",
                name: "settings-templates-notifications-edit",
                beforeEnter: (to, from, next) => {
                  const tenantsTemplatesNotificationsStore =
                    useTenantsTemplatesNotificationsStore();
                  const id = to.params.id;

                  tenantsTemplatesNotificationsStore.initializeTenantsTemplatesNotificationsEditModal(
                    id
                  );
                  next(false);
                },
              },
            ],
          },
          {
            path: "occurences",
            name: "settings-occurences",
            component: () => import("./components/SettingsOccurences.vue"),
            meta: {
              tabTitle: "Occurences",
              sectionName: "Automation",
              sectionSort: 10,
              settingIcon: "fa-solid fa-bolt",
              sort: 2,
            },
          },
          {
            path: "passports",
            name: "settings-passports",
            component: () => import("./components/SettingsPassportsList.vue"),

            meta: {
              tabTitle: "Passports",
              sectionName: "User",
              sectionSort: 2,
              settingIcon: "fa-solid fa-key",
              sort: 6,
            },

            children: [
              {
                path: "create",
                name: "settings-passports-create",
                beforeEnter: (to, from, next) => {
                  const tenantsPassportsStore = useTenantsPassportsStore();
                  tenantsPassportsStore.toggleTenantsPassportsCreateModal();
                  next(false);
                },
              },

              {
                path: "edit/:id",
                name: "settings-passports-edit", // Naming the route
                beforeEnter: (to, from, next) => {
                  const tenantsPassportsStore = useTenantsPassportsStore();
                  const id = to.params.id;

                  tenantsPassportsStore.initializeTenantsPassportsEditModal(id);
                  next(false);
                },
              },
            ],
          },
          {
            path: "payment-connections",
            name: "settings-gateways-list",
            component: () => import("./components/SettingsGatewaysList.vue"),
            meta: {
              tabTitle: "Gateways",
              sectionName: "Payments",
              sectionSort: 9,
              settingIcon: "fa-solid fa-building-columns",
              sort: 1,
            },
            children: [
              {
                path: "create",
                name: "settings-gateways-create",
                beforeEnter: (to, from, next) => {
                  const tenantsGatewaysStore = useTenantsGatewaysStore();
                  tenantsGatewaysStore.toggleTenantsGatewaysCreateModal();
                  next(false);
                },
              },
              {
                path: "edit/:id",
                name: "settings-gateways-edit", // Naming the route
                beforeEnter: (to, from, next) => {
                  const tenantsGatewaysStore = useTenantsGatewaysStore();
                  const id = to.params.id;

                  tenantsGatewaysStore.initializeTenantsGatewaysEditModal(id);
                  next(false);
                },
              },
            ],
          },
          {
            path: "payment-presets",
            name: "settings-payments-presets",
            component: () =>
              import("./components/SettingsPaymentsPresetsList.vue"),
            meta: {
              tabTitle: "Payment Presets",
              sectionName: "Payments",
              sectionSort: 9,
              settingIcon: "fa-solid fa-list",
              sort: 5,
            },
            children: [
              {
                //create
                path: "create",
                name: "settings-payments-presets-create",
                beforeEnter: (to, from, next) => {
                  const tenantsPaymentsPresetsStore =
                    useTenantsPaymentsPresetsStore();
                  tenantsPaymentsPresetsStore.toggleTenantsPaymentsPresetsCreateModal();
                  next(false);
                },
              },
              {
                //edit
                path: "edit/:id",
                name: "settings-payments-presets-edit", // Naming the route
                beforeEnter: (to, from, next) => {
                  const tenantsPaymentsPresetsStore =
                    useTenantsPaymentsPresetsStore();
                  const id = to.params.id;

                  tenantsPaymentsPresetsStore.initializeTenantsPaymentsPresetsEditModal(
                    id
                  );
                  next(false);
                },
              },
            ],
          },
          {
            path: "payment-routes",
            name: "settings-payments-routes",
            component: () =>
              import("./components/SettingsPaymentsRoutesList.vue"),
            meta: {
              tabTitle: "Routes",
              sectionName: "Payments",
              sectionSort: 9,
              settingIcon: "fa-solid fa-money-bill-transfer",
              sort: 2,
            },
            children: [
              {
                path: "create",
                name: "settings-payments-routes-create",
                beforeEnter: (to, from, next) => {
                  const tenantsPaymentsRoutesStore =
                    useTenantsPaymentsRoutesStore();
                  tenantsPaymentsRoutesStore.toggleTenantsPaymentsRoutesCreateModal();
                  next(false);
                },
              },
              {
                path: "edit/:id",
                name: "settings-payments-routes-edit", // Naming the route
                beforeEnter: (to, from, next) => {
                  const tenantsPaymentsRoutesStore =
                    useTenantsPaymentsRoutesStore();
                  const id = to.params.id;

                  tenantsPaymentsRoutesStore.initializeTenantsPaymentsRoutesEditModal(
                    id
                  );
                  next(false);
                },
              },
            ],
          },
          {
            path: "schedule-types",
            name: "settings-schedule-types-list",
            component: () =>
              import("./components/SettingsScheduleTypesList.vue"),
            meta: {
              tabTitle: "Schedule Types",
              sectionName: "Payments",
              sectionSort: 9,
              settingIcon: "fa-solid fa-calendar-days",
              sort: 4,
            },
            children: [
              {
                path: "create",
                name: "settings-schedule-types-create",
                beforeEnter: (to, from, next) => {
                  const tenantsScheduleTypesStore =
                    useTenantsScheduleTypesStore();
                  tenantsScheduleTypesStore.toggleTenantsScheduleTypesCreateModal();
                  next(false);
                },
              },
              {
                path: "edit/:id",
                name: "settings-schedule-types-edit", // Naming the route
                beforeEnter: (to, from, next) => {
                  const tenantsScheduleTypesStore =
                    useTenantsScheduleTypesStore();
                  const id = to.params.id;

                  tenantsScheduleTypesStore.initializeTenantsScheduleTypesEditModal(
                    id
                  );
                  next(false);
                },
              },
            ],
          },
          {
            path: "phone-numbers-list",
            name: "settings-phone-numbers-list",
            component: () =>
              import("./components/SettingsPhoneNumbersList.vue"),
            meta: {
              tabTitle: "Phone Numbers",
              sectionName: "Communications",
              sectionSort: 6,
              settingIcon: "fa-solid fa-phone",
              sort: 2,
            },
            children: [
              {
                path: "create",
                name: "settings-phone-numbers-create",
                beforeEnter: (to, from, next) => {
                  const tenantsPhoneNumbersStore =
                    useTenantsPhoneNumbersStore();
                  tenantsPhoneNumbersStore.toggleTenantsPhoneNumbersCreateModal();
                  next(false);
                },
              },
              {
                path: "edit/:id",
                name: "settings-phone-numbers-edit", // Naming the route
                beforeEnter: (to, from, next) => {
                  const tenantsPhoneNumbersStore =
                    useTenantsPhoneNumbersStore();
                  const id = to.params.id;

                  tenantsPhoneNumbersStore.initializeTenantsPhoneNumbersEditModal(
                    id
                  );
                  next(false);
                },
              },
            ],
          },
          {
            path: "roles",
            name: "settings-roles",
            component: () => import("./components/SettingsRoles.vue"),
            meta: {
              tabTitle: "Roles",
              sectionName: "User",
              sectionSort: 2,
              settingIcon: "fa-brands fa-redhat",
              sort: 3,
            },
            redirect: { name: "settings-list-roles" },
            children: [
              {
                path: "create",
                name: "settings-create-roles",
                beforeEnter: (to, from, next) => {
                  const rolesStore = useRolesStore();
                  rolesStore.toggleCreateRoleModal();
                  next(false);
                },
              },
              {
                path: "list",
                name: "settings-list-roles",
                component: () => import("./components/SettingsRolesList.vue"),
              },
            ],
          },

          {
            path: "settings-templates-sms-list",
            name: "settings-templates-sms-list",
            component: () =>
              import("./components/SettingsTemplatesSmsList.vue"),
            meta: {
              tabTitle: "SMS",
              sectionName: "Templates",
              sectionSort: 4,
              settingIcon: "fa-solid fa-message",
              sort: 3,
            },
            children: [
              {
                path: "create",
                name: "settings-templates-sms-create",
                beforeEnter: (to, from, next) => {
                  const tenantsTemplatesSmsStore =
                    useTenantsTemplatesSmsStore();
                  tenantsTemplatesSmsStore.toggleTenantsTemplatesSmsCreateModal();
                  next(false);
                },
              },
              {
                path: "edit/:id",
                name: "settings-templates-sms-edit",
                beforeEnter: (to, from, next) => {
                  const tenantsTemplatesSmsStore =
                    useTenantsTemplatesSmsStore();
                  const id = to.params.id;

                  tenantsTemplatesSmsStore.initializeTenantsTemplatesSmsEditModal(
                    id
                  );
                  next(false);
                },
              },
            ],
          },
          {
            path: "stages",
            name: "settings-stages-list",
            component: () => import("./components/SettingsStagesList.vue"),
            meta: {
              tabTitle: "Stages",
              sectionName: "File Associations",
              sectionSort: 3,
              settingIcon: "fa-solid fa-bars-staggered",
              sort: 1,
            },
            children: [
              {
                path: "create",
                name: "settings-stages-create",
                beforeEnter: (to, from, next) => {
                  const tenantsStagesStore = useTenantsStagesStore();
                  tenantsStagesStore.toggleTenantsStagesCreateModal();
                  next(false);
                },
              },
              {
                path: "edit/:id",
                name: "settings-stages-edit", // Naming the route
                beforeEnter: (to, from, next) => {
                  const tenantsStagesStore = useTenantsStagesStore();
                  const id = to.params.id;

                  console.log("STORE EDIT ID: ", id);

                  tenantsStagesStore.initializeTenantsStagesEditModal(id);
                  next(false);
                },
              },
            ],
          },
          {
            path: "workflow-statuses-list",
            name: "settings-workflow-statuses-list",
            component: () =>
              import("./components/SettingsWorkflowStatusesList.vue"),
            meta: {
              tabTitle: "Statuses",
              sectionName: "File Associations",
              sectionSort: 3,
              settingIcon: "fa-solid fa-flag",
              sort: "value",
            },
            children: [
              {
                path: "create",
                name: "settings-workflow-statuses-create",
                beforeEnter: (to, from, next) => {
                  const tenantsWorkflowStatusesStore =
                    useTenantsWorkflowStatusesStore();

                  tenantsWorkflowStatusesStore.toggleTenantsWorkflowStatusesCreateModal();
                  next(false);
                },
              },
              {
                path: "edit/:id",
                name: "settings-workflow-statuses-edit", // Naming the route
                beforeEnter: (to, from, next) => {
                  const tenantsWorkflowStatusesStore =
                    useTenantsWorkflowStatusesStore();
                  const id = to.params.id;

                  tenantsWorkflowStatusesStore.initializeTenantsWorkflowStatusesEditModal(
                    id
                  );
                  next(false);
                },
              },
            ],
          },
          {
            path: "subscription",
            name: "settings-subscription",
            component: () => import("./components/SettingsSubscription.vue"),
            meta: {
              tabTitle: "Subscription",
              sectionName: "Account",
              sectionSort: 1,
              settingIcon: "fa-solid fa-thumbs-up",
              sort: 2,
            },
          },
          {
            path: "email-domains",
            name: "settings-email-domains",
            component: () => import("./components/SettingsEmailDomains.vue"),
            meta: {
              tabTitle: "Email Domains",
              sectionName: "Communications",
              sectionSort: 6,
              settingIcon: "fa-solid fa-at",
              sort: 1,
            },
          },
          {
            path: "tags",
            name: "settings-tags-list",
            component: () => import("./components/SettingsTagsList.vue"),
            meta: {
              tabTitle: "Tags",
              sectionName: "File Associations",
              sectionSort: 3,
              settingIcon: "fa-solid fa-tags",
              sort: "value",
            },
            children: [
              {
                path: "create",
                name: "settings-tags-create",
                beforeEnter: (to, from, next) => {
                  const tenantsTagsStore = useTenantsTagsStore();
                  tenantsTagsStore.toggleTenantsTagsCreateModal();
                  next(false);
                },
              },
              {
                path: "edit/:id",
                name: "settings-tags-edit", // Naming the route
                beforeEnter: (to, from, next) => {
                  const tenantsTagsStore = useTenantsTagsStore();
                  const id = to.params.id;

                  tenantsTagsStore.initializeTenantsTagsEditModal(id);
                  next(false);
                },
              },
            ],
          },
          {
            path: "tasks",
            name: "settings-tasks",
            component: () => import("./components/SettingsTasks.vue"),
            meta: {
              tabTitle: "Tasks",
              sectionName: "Assignments",
              sectionSort: 5,
              settingIcon: "fa-solid fa-list-check",
              sort: 2,
            },
          },
          {
            path: "usage",
            name: "settings-usage",
            component: () => import("./components/SettingsUsage.vue"),
            meta: {
              tabTitle: "Usage",
              sectionName: "Account",
              sectionSort: 1,
              settingIcon: "fa-solid fa-gauge-high",
              sort: 3,
            },
          },
          {
            path: "affiliate-groups-list",
            name: "affiliate-groups-list",
            component: () =>
              import("./components/SettingsAffiliateGroupsList.vue"),
            meta: {
              tabTitle: "Affiliate Groups",
              sectionName: "User",
              sectionSort: 2,
              settingIcon: ["fas", "people-roof"],
              sort: 0,
            },
            children: [
              {
                path: "edit/:id",
                name: "affiliate-groups-edit", // Naming the route
                beforeEnter: (to, from, next) => {
                  const tenantsAffiliateGroupsStore =
                    useTenantsAffiliateGroupsStore();

                  const id = to.params.id;

                  console.log("STORE EDIT ID: ", id);

                  tenantsAffiliateGroupsStore.initializeTenantsAffiliateGroupsEditModal(
                    id
                  );
                  next(false);
                },
              },
              {
                path: "create",
                name: "affiliate-groups-create", // Naming the route
                beforeEnter: (to, from, next) => {
                  const tenantsAffiliateGroupsStore =
                    useTenantsAffiliateGroupsStore();

                  tenantsAffiliateGroupsStore.toggleTenantsAffiliateGroupsCreateModal();
                  next(false);
                },
              },
            ],
          },
          {
            path: "users",
            name: "settings-users",
            component: () => import("./components/SettingsUsers.vue"),
            meta: {
              tabTitle: "Users",
              sectionName: "User",
              sectionSort: 2,
              settingIcon: "fa-solid fa-users",
              sort: 1,
            },
            redirect: { name: "settings-list-users" },
            children: [
              {
                path: "create",
                name: "settings-create-users",
                beforeEnter: (to, from, next) => {
                  const usersStore = useUsersStore();
                  usersStore.toggleCreateUserModal();
                  next(false);
                },
              },
              {
                path: "list",
                name: "settings-list-users",
                component: () => import("./components/SettingsUsersList.vue"),
              },

              {
                path: "edit/:id",
                name: "settings-edit-user", // Naming the route
                beforeEnter: (to, from, next) => {
                  const id = to.params.id;

                  const usersStore = useUsersStore();
                  usersStore.setIdToEdit(id);
                  usersStore.toggleEditUserModal();
                  next(false);
                },
              },
              {
                path: "network",
                name: "settings-network-users",
                component: () =>
                  import("./components/SettingsUsersNetwork.vue"),
              },
            ],
          },
        ],
      },

      {
        path: "support",
        name: "support-tab",
        component: () => import("./components/SupportTab.vue"),
        children: [
          {
            path: "articles",
            name: "support-articles",
            component: () => import("./components/SupportArticles.vue"),
            meta: {
              tabTitle: "Articles",
              sectionName: "Knowledge Base",
              sectionSort: 1,
              settingIcon: "fa-solid fa-newspaper",
              sort: "value",
            },
          },

          {
            path: "tickets",
            name: "support-tickets-list",
            component: () => import("./components/SupportTicketsList.vue"),
            meta: {
              tabTitle: "Tickets",
              sectionName: "Customer Support",
              sectionSort: 2,
              settingIcon: "fa-solid fa-ticket",
              sort: "value",
            },
            children: [
              {
                path: "create",
                name: "support-tickets-create",
                beforeEnter: (to, from, next) => {
                  const supportTicketsStore = useSupportTicketsStore();
                  supportTicketsStore.showSupportTicketsCreateModal = true;
                  console.log("CREATE TICKET");
                  next(false);
                },
              },

              {
                path: "edit/:id",
                name: "support-tickets-edit", // Naming the route
                beforeEnter: (to, from, next) => {
                  const supportTicketsStore = useSupportTicketsStore();
                  const id = to.params.id;
                  console.log("YESSIR EDIT TICKET");
                  supportTicketsStore.initializeSupportTicketsEditModal(id);
                  next(false);
                },
              },
            ],
          },

          {
            path: "email",
            name: "support-email",
            component: () => import("./components/SupportEmail.vue"),
            meta: {
              tabTitle: "Email",
              sectionName: "Customer Support",
              sectionSort: 2,
              settingIcon: "fa-solid fa-envelope",
              sort: "value",
            },
          },

          {
            path: "phone",
            name: "support-phone",
            component: () => import("./components/SupportPhone.vue"),
            meta: {
              tabTitle: "Phone",
              sectionName: "Customer Support",
              sectionSort: 2,
              settingIcon: "fa-solid fa-phone",
              sort: "value",
            },
          },

          {
            path: "gpt-settings",
            name: "support-gpt-settings",
            component: () => import("./components/SupportGPTSettings.vue"),
            meta: {
              tabTitle: "GPT Settings",
              sectionName: "FinnyPi GPT",
              settingIcon: "", //not used
              sort: "", //not used
            },
          },
        ],
      },

      {
        path: "session-dashboard",
        name: "session-dashboard",
        beforeEnter: (to, from, next) => {
          const tenantSessionStore = useTenantsSessionsStore();
          tenantSessionStore.toggleSessionDashboard();
          next(false);
        },
      },

      {
        path: "gpt",
        name: "support-gpt",
        beforeEnter: (to, from, next) => {
          const gptStore = useGptStore();
          gptStore.toggleGptWindow();
          next(false);
        },
      },
    ],
  },

  {
    path: "/login",
    name: "login",
    component: () => import("./components/LoginPage.vue"),
  },
  {
    path: "/sign-up",
    name: "sign-up",
    component: () => import("./components/SignUpPage.vue"),
  },
  {
    path: "/password-reset",
    name: "password-reset",
    component: () => import("./components/PasswordResetPage.vue"),
  },

  {
    path: "/paywall",
    name: "paywall",
    component: () => import("./components/cPayWall.vue"),
  },
  {
    path: "/terms-violation",
    name: "terms-violation",
    component: () => import("./components/cTermsViolation.vue"),
  },
  {
    path: "/",
    name: "website",
    component: () => import("./components/Website.vue"),
    children: [
      {
        path: "privacy-policy",
        name: "privacy-policy",
        beforeEnter: (to, from, next) => {
          const websiteStore = useWebsiteStore();
          websiteStore.togglePrivacyPolicy();

          console.log("PRIVACY POLICY TOGGLED");
          next({ name: "website" });
        },
      },
      {
        path: "terms-of-service",
        name: "terms-of-service",
        beforeEnter: (to, from, next) => {
          const websiteStore = useWebsiteStore();
          websiteStore.toggleTermsOfService();

          console.log("TERMS TOGGLED");
          next({ name: "website" });
        },
      },
    ],
  },

  {
    path: "/create-contact",
    component: () => import("./components/GlobalModal.vue"),
    children: [
      { path: "", component: () => import("./components/CreateContact.vue") },
    ],
  },

  {
    path: "/:pathMatch(.*)*",
    name: "not-found",
    component: () => import("./components/NotFoundPage.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

const publicRoutes = [
  "/",
  "/privacy-policy",
  "/terms-of-service",
  "/login",
  "/sign-up",
  "/password-reset",
  "/paywall",
  "/terms-violation",
];

const isPublicRoute = (route) => {
  return publicRoutes.includes(route.path) || route.name === "not-found";
};

// BUG - functional but the logic doens't make very much sense as a result of this - need to remove try / catch and restart vite - it's always forcing catch block for some reason when no session
const checkSession = async () => {
  try {
    const response = await axios.get(`${BASE_API_URL}/session-data`);
    return response.data;
  } catch (error) {
    throw new Error("Session check failed");
  }
};

const handleSubscriptionStatus = (subscription, next) => {
  if (subscription === "nsf" || subscription === "canceled") {
    next({ name: "paywall" });
  } else if (subscription === "violation") {
    next({ name: "terms-violation" });
  } else if (subscription === "active") {
    next();
  }
};

router.beforeEach(async (to, from, next) => {
  // xLog("Navigating from:", from.fullPath);
  // xLog("Navigating to:", to.fullPath);

  if (isPublicRoute(to)) {
    next();
  } else {
    // console.log("PRIVATE ROUTE");
    try {
      const sessionData = await checkSession();

      if (sessionData.status !== "failed") {
        handleSubscriptionStatus(sessionData.session.tenant.subscription, next);
      } else if (sessionData.status === "failed") {
        console.log("RESPONSE: Session Expired");
        const masterStore = useMasterStore();
        const loginStore = useLoginStore();
        const toastsStore = useGlobalToastsStore();

        toastsStore.addToast({
          title: "Please Login",
          buttonExists: false,
          color: "orange",
          type: 2, // 1 = small, 2 = md, 3 = lg, 4 = lg dismissable
        });

        masterStore.resetAllStores();
        loginStore.deleteLocalStorageData();
        next("/login");
      }
    } catch (error) {
      console.log(`ERROR: error`);
      const masterStore = useMasterStore();
      const loginStore = useLoginStore();
      const toastsStore = useGlobalToastsStore();

      toastsStore.addToast({
        title: "Please Login",
        buttonExists: false,
        color: "orange",
        type: 2, // 1 = small, 2 = md, 3 = lg, 4 = lg dismissable
      });

      masterStore.resetAllStores();
      loginStore.deleteLocalStorageData();
      next("/login");
    }
  }
});

router.afterEach(async (to, from) => {
  //do something after each route
});

export default router;
