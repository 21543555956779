import {
  //axios
  axios,

  //pinia
  defineStore,

  //env base url
  BASE_API_URL,
} from "../clientImports";

export const useContactAssignmentsStore = defineStore({
  id: "contact-assignments",
  state: () => ({
    tenantsDepartmentsOptions: [],
    tenantsUsersOptions: [],
    showContactAssignmentsModal: false,
    //will toggle to show the chat at the bottom of view port. Expands thrid row in vp with flex
  }),
  actions: {
    async toggleContactAssignmentsModal() {
      this.showContactAssignmentsModal = !this.showContactAssignmentsModal;
    },

    async fetchDepartmentsOptions() {
      const response = await axios.get(
        `${BASE_API_URL}/read-tenants-departments`
      );

      this.tenantsDepartmentsOptions = response.data.departmentsOptions.map(
        (department) => {
          return {
            value: department._id,
            label: department.name,
          };
        }
      );
    },
    async fetchUsersOptions() {
      const response = await axios.get(`${BASE_API_URL}/read-users-options`);

      this.tenantsUsersOptions = response.data.usersOptions.map((user) => {
        return {
          value: user._id,
          label: `${user.first_name} ${user.last_name}`,
        };
      });
    },
  },
  getters: {
    getTenantsDepartmentsOptions: (state) => {
      return state.tenantsDepartmentsOptions;
    },
    getTenantsUsersOptions: (state) => {
      return state.tenantsUsersOptions;
    },
  },
});
