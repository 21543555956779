<script setup>
defineOptions({
  name: 'SettingsPaymentsRoutesList', // Give the component a name
});
import {
  //axios
  axios,

  //vue
  ref,
  reactive,
  watch,
  computed,
  onMounted,
  onBeforeMount,

  //pinia stores
  useLoginStore,
  useGlobalPromptsStore,
  useGlobalToastsStore,
  useUsersStore,
  useTenantsCampaignsStore,
  useTenantsPaymentsRoutesStore,

  //vue-router
  useRouter,
  useRoute,

  //formkit

  //formkit config file

  //fontawesome
  library,
  FontAwesomeIcon,
  fas,
  far,
  fab,

  //global components - all vue components
  registerGlobalComponents,

  //base api url
  BASE_API_URL,
} from "./../clientImports";

const promptsStore = useGlobalPromptsStore();
const toastsStore = useGlobalToastsStore();
const tenantsCampaignsStore = useTenantsCampaignsStore();
const tenantsPaymentsRoutesStore = useTenantsPaymentsRoutesStore();
const loginStore = useLoginStore();

const route = useRoute();
const router = useRouter();

onMounted(async () => {
  await tenantsPaymentsRoutesStore.fetchTenantsPaymentsRoutes(); //initial populate affiliate list
});

//write a computed function that returns the paymentsRoutesList in a map with only it's id and name
const paymentsRoutesList = computed(() => {
  return tenantsPaymentsRoutesStore.tenantsPaymentsRoutesList.map(
    (paymentsRoute) => {
      return {
        id: paymentsRoute.id,
        name: paymentsRoute.name,
        payment_type: paymentsRoute.payment_method_type,
        active: paymentsRoute.active,
      };
    }
  );
});
</script>

<template>
  <div
    class="left-0 top-0 sticky bg-base-100 p-1 border-b border-b-slate-400 text-white"
  >
    <div class="flex flex-row items-center gap-5">
      <div>PaymentsRoutes</div>

      <div>
        <router-link
          class="btn btn-xs md:btn-sm bg-blue-500 hover:bg-blue-700 text-white border-none capitalize rounded"
          :to="{ name: 'settings-payments-routes-create' }"
        >
          <span class="mr-2"> Create Route </span>
          <font-awesome-icon icon="fa-solid fa-plus" />
        </router-link>
      </div>

      <SettingsPaymentsRoutesCreate />
    </div>
  </div>
  <div class="flex-1 max-w-full h-full">
    <cLoadingSpinner v-if="paymentsRoutesList === null" />
    <table v-else-if="paymentsRoutesList?.length > 0" class="w-full">
      <thead class="">
        <tr>
          <th
            v-for="(paymentsRoute, resultIndex) in paymentsRoutesList[0]"
            :key="resultIndex"
            class="bg-gray-500 text-white text-left p-1 md:px-5 md:py-2 md:text-base text-xs whitespace-nowrap"
          >
            {{ resultIndex.replaceAll("_", " ") }}
          </th>
          <th class="text-white bg-base-300 sticky right-0">
            <span class="hidden md:block"> Action </span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(paymentsRoute, resultIndex) in paymentsRoutesList"
          :key="resultIndex"
          class="odd:bg-slate-700 even:bg-slate-800"
        >
          <td
            v-for="(cellData, cellIndex) in paymentsRoute"
            :key="cellIndex"
            class="md:px-5 md:py-3 text-white text-xs md:text-base"
          >
            {{ cellData }}
          </td>
          <td class="bg-base-300 sticky right-0 p-1">
            <div
              class="flex flex-col md:flex-row justify-end md:items-center items-stretch"
            >
              <label
                v-if="
                  loginStore?.sessionData?.permissions?.includes(
                    'delete:payments-routes'
                  ) ||
                  loginStore?.sessionData?.permissions?.includes(
                    '*:dev-permissions'
                  )
                "
                class="btn btn-xs md:btn-sm rounded-t rounded-b-none md:rounded-l md:rounded-r-none bg-rose-500 hover:bg-rose-700 text-white border-none"
                @click.stop="
                  tenantsPaymentsRoutesStore.deleteTenantsPaymentsRoute(
                    paymentsRoute.id
                  )
                "
              >
                <font-awesome-icon icon="fa-solid fa-xmark" size="lg" />
              </label>

              <!-- BUG - might add this later - no editing roles for now - just have to add new and delete the old after -->
              <!-- <button
                class="btn btn-xs md:btn-sm rounded-b-none md:rounded-l-none md:rounded-r bg-blue-500 hover:bg-blue-700 text-white border-none"
                @click="
                  tenantsPaymentsRoutesStore.initializeTenantsPaymentsRoutesEditModal(
                    paymentsRoute._id
                  )
                "
              >
                <font-awesome-icon icon="fa-solid fa-pen-to-square" />
              </button> -->

              <!-- need a router-link that sets a param with paymentsRoute._id -->
              <router-link
                v-if="
                  loginStore?.sessionData?.permissions?.includes(
                    'edit:payments-routes'
                  ) ||
                  loginStore?.sessionData?.permissions?.includes(
                    '*:dev-permissions'
                  )
                "
                class="btn btn-xs md:btn-sm rounded-b-none md:rounded-l-none md:rounded-r bg-blue-500 hover:bg-blue-700 text-white border-none"
                :to="{
                  name: 'settings-payments-routes-edit',
                  params: { id: paymentsRoute.id },
                }"
              >
                <font-awesome-icon icon="fa-solid fa-pen-to-square" />
              </router-link>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <div v-else class="bg-blue-400 text-white p-10 text-lg">
      <div class="flex flex-row justify-center items-center">
        <span class="ml-3">No Payments Routes Exist Yet</span>
      </div>
    </div>
  </div>

  <div>
    <SettingsPaymentsRoutesEdit />
  </div>
</template>
