export const usersLevelOptions = [
  { value: 0, label: "- Select Level Access -", attrs: { disabled: true } },
  { value: "admin", label: "Admin - Can see All files" },
  { value: "user", label: "User - Can see where files Assigned" },
  {
    value: "affiliate-admin",
    label: "Affiliate Admin - Can see all files in Affilliate Group",
  },
  {
    value: "affiliate-user",
    label:
      "Affiliate User - Can see where files Assigned and in Affilliate Group",
  },
];
