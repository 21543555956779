import {
  //axios
  axios,

  //pinia
  defineStore,
  useLoginStore,
  useGlobalToastsStore,

  //env base url
  BASE_API_URL,
} from "../clientImports";

export const useTenantsAccountProfileStore = defineStore({
  id: "tenants-account-profile",
  state: () => ({
    company_data: {
      company_abbreviation: "",
      company_display_name: "",
      company_legal_name: "",
      website: "",
      office_phone: "",
      customer_service_phone: "",
      office_street_address: "",
      office_suite_unit: "",
      office_city: "",
      office_state: "",
      office_zip: "",
      office_country: "",
      office_timezone: "",
    },
  }),
  actions: {
    async fetchProfile() {
      try {
        const { data } = await axios.get(
          `${BASE_API_URL}/read-tenants-account-profile`
        );
        console.log("DATA PROFILE: ", data);
        //use object assign to assign the data to the state
        Object.assign(this.company_data, data.tenant[0]);
      } catch (error) {
        console.error(error);
      }
    },

    async updateProfile() {
      try {
        const { data } = await axios.put(
          `${BASE_API_URL}/update-tenants-account-profile`,
          this.company_data
        );
        console.log("DATA PROFILE UPDATE: ", data);

        if (data.status === "success") {
          const toastsStore = useGlobalToastsStore();
          toastsStore.addToast({
            title: "Account Profile Updated",
            description:
              "Changes may require you to logout to reflect in the main araeas of the UI",
            buttonExists: false,
            color: "emerald",
            type: 3,
          });
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
});
