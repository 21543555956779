<script setup>
defineOptions({
  name: 'ContactStage', // Give the component a name
});
import {
  //axios
  axios,

  //vue
  createApp,
  ref,
  reactive,
  watch,
  computed,
  inject,
  provide,
  onMounted,
  onUnmounted,
  nextTick,
  Teleport,

  //pinia
  defineStore,
  createPinia,
  storeToRefs,

  //pinia stores
  useChatStore,
  useContactAlertsStore,
  useContactFileStore,
  useContactsStore,
  useGlobalPromptsStore,
  useGlobalToastsStore,
  useTenantsStagesStore,
  useLoginStore,
  useTenantsStore,

  //vue-router
  useRouter,
  useRoute,

  //formkit

  //formkit config file

  //fontawesome
  library,
  FontAwesomeIcon,
  fas,
  far,
  fab,

  //global components - all vue components
  registerGlobalComponents,

  //base api url
  BASE_API_URL,
} from "./../clientImports";

const tenantsStagesStore = useTenantsStagesStore();

const contactFileStore = useContactFileStore();

const toastsStore = useGlobalToastsStore();

const currentStage = ref("");

const showStageSelect = ref(false);
const toggleStageSelect = () => {
  showStageSelect.value = !showStageSelect.value;
};

onMounted(async () => {
  await tenantsStagesStore.fetchTenantsStagesOptions(); //initial populate affiliate list

  currentStage.value = contactFileStore?.currentContactFile?.stage;
});

const fileStage = computed(() => {
  currentStage.value = contactFileStore?.currentContactFile?.stage;

  return currentStage.value;
});

const updateStage = async (value) => {
  const data = await contactFileStore.updateContactFlag("stage", value);

  if (data.status === "success") {
    toastsStore.addToast({
      title: "Stage Updated",
      description: "",
      buttonExists: false,
      color: "emerald",
      type: 1,
    });
  }

  console.log("UPDATE SINGLE STAGE RESPONSE", data);
};
</script>

<template>
  <div class="flex flex-row">
    <button
      @click="toggleStageSelect"
      class="btn btn-xs no-animation rounded-none text-white capitalize"
    >
      Stage
    </button>
    <select
      @change="updateStage($event.target.value)"
      :class="`select select-xs text-white rounded-none w-28 hover:w-auto`"
      v-model="currentStage"
    >
      <option disabled selected>Choose Status</option>
      <option
        v-for="(
          stage, stageIndex
        ) in tenantsStagesStore.fetchedTenantsStagesOptions"
        :value="stage.id"
      >
        {{ stage.name }}
      </option>
    </select>
  </div>
</template>
