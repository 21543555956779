<script setup>
defineOptions({
  name: 'SettingsTagsEdit', // Give the component a name
});
import {
  //axios
  axios,

  //vue
  createApp,
  ref,
  reactive,
  watch,
  computed,
  inject,
  provide,
  onMounted,
  onUnmounted,
  nextTick,
  Teleport,

  //pinia
  defineStore,
  createPinia,
  storeToRefs,

  //pinia stores
  useChatStore,
  useContactAlertsStore,
  useContactFileStore,
  useContactsStore,
  useGlobalPromptsStore,
  useGlobalToastsStore,
  useLoginStore,
  useTenantsStore,
  useUsersStore,
  useTenantsTagsStore,

  //vue-router
  useRouter,
  useRoute,

  //formkit

  //formkit config file
  FKsubmitForm,

  //fontawesome
  library,
  FontAwesomeIcon,
  fas,
  far,
  fab,

  //centralized data
  usersLevelOptions,
  fieldsDefaultValidation,
  stateOptions,

  //utilities
  getRandomNumberBetween,

  //helpers
  registerGlobalComponents,

  //utilities
  stripEmptyFields,

  //base api url
  BASE_API_URL,
} from "./../clientImports";

import {
  browserTimezone,
  timezoneOptions,
} from "./../centralized_data/timezoneOptions";

const router = useRouter();
const route = useRoute();

const loginStore = useLoginStore();

const usersStore = useUsersStore();
const toastsStore = useGlobalToastsStore();
const promptsStore = useGlobalPromptsStore();
const tenantsTagsStore = useTenantsTagsStore();

//use FKsubmitForm to submit form
const submitUpdateForm = () => {
  FKsubmitForm("edit-tags");
};
</script>

<template>
  <cSidebarModal
    sidebar-position="right"
    :show-sidebar-initial="false"
    :show-sidebar-modal="tenantsTagsStore.showTenantsTagsEditModal"
    :toggle-sidebar-modal="tenantsTagsStore.toggleTenantsTagsEditModal"
    :defaultButton="false"
  >
    <template #title>
      <h2>Edit Tag</h2>
    </template>

    <template #body>
      <FormKit
        id="edit-tags"
        type="form"
        :incomplete-message="false"
        submit-label="Update"
        form-class="text-white h-full flex flex-col justify-between"
        @submit="tenantsTagsStore.updateTenantsTag()"
        outer-class="m-0"
        :actions="false"
        :submit-attrs="{
          'data-theme': `light`,
          ignore: true,
        }"
      >
        <div class="grid grid-cols-1 gap-5">
          <div class="flex flex-row items-center gap-5">
            <FormKit
              type="text"
              label="Name"
              placeholder=""
              :validation="'required:trim|length:5, 40'"
              help-class="text-white"
              help=""
              outer-class="m-0 flex-1"
              input-class="$reset rounded w-full input input-sm text-gray-700 bg-gray-50"
              wrapper-class="max-w-full"
              v-model.trim="tenantsTagsStore.currentlyEditingTag.name"
            />

            <FormKit
              outer-class="col-span-1"
              type="toggle"
              name="toggle"
              label="Active"
              alt-label-position
              v-model="tenantsTagsStore.currentlyEditingTag.active"
            />
          </div>
          <FormKit
            type="select"
            label="Color"
            placeholder=""
            :validation="'required'"
            help-class="text-white"
            help=""
            outer-class="m-0 "
            :input-class="`$reset rounded w-full ${
              tenantsTagsStore.currentlyEditingTag.color !== '' ||
              tenantsTagsStore.currentlyEditingTag.color === null
                ? `bg-${tenantsTagsStore.currentlyEditingTag.color}-500`
                : 'bg-gray-100 text-gray-700'
            } select select-sm`"
            wrapper-class="max-w-full"
            v-model="tenantsTagsStore.currentlyEditingTag.color"
            :options="[
              //choose color disabled
              { value: '', label: 'Choose Color', attrs: { disabled: true } },
              { value: 'red', label: 'Red' },
              { value: 'orange', label: 'Orange' },
              { value: 'yellow', label: 'Yellow' },
              { value: 'green', label: 'Green' },
              { value: 'emerald', label: 'Emerald' },
              { value: 'blue', label: 'Blue' },
              { value: 'purple', label: 'Purple' },
              { value: 'teal', label: 'Teal' },
              { value: 'pink', label: 'Pink' },
              { value: 'slate', label: 'Slate' },
            ]"
          />

          <FormKit
            type="textarea"
            label="Description"
            placeholder=""
            :validation="'trim|length:1, 255'"
            help-class="text-white"
            help=""
            outer-class="m-0 "
            input-class="$reset rounded w-full input input-sm text-gray-700 bg-gray-50"
            wrapper-class="max-w-full"
            v-model.trim="tenantsTagsStore.currentlyEditingTag.description"
            :value="tenantsTagsStore.currentlyEditingTag.description"
          />
        </div>
      </FormKit>
    </template>

    <template #footer>
      <!-- BUTTON FOOTER - CANCEL & UPDATE AFFILIATE GROUP -->
      <div class="flex flex-row justify-between text-white w-full">
        <button
          @click="tenantsTagsStore.toggleTenantsTagsEditModal"
          class="btn btn-xs md:btn-sm rounded bg-red-500 text-white hover:bg-red-700"
        >
          Cancel
        </button>
        <button
          @click="submitUpdateForm"
          class="btn btn-xs md:btn-sm rounded bg-emerald-500 text-white hover:bg-emerald-700"
        >
          Update
        </button>
      </div>
    </template>
  </cSidebarModal>
</template>
