<script setup>
defineOptions({
  name: 'DashboardTab', // Give the component a name
});
import { ref, onMounted } from "../clientImports";

const chartData = ref({ "2017-05-13": 2, "2017-05-14": 5 });

onMounted(() => {
  chartData.value = {
    "2023-01-01": 2,
    "2023-01-02": 5,
    "2023-01-03": 7,
    "2023-01-04": 4,
  };
});

const toggleFocusedChart = (chart) => {
  if (focusedChart.value === chart) {
    focusedChart.value = "";
    return;
  } else {
    focusedChart.value = chart;
  }
};
const focusedChart = ref("");
</script>

<template>
  <div
    class="w-full flex-col grid grid-cols-2 lg:grid-cols-3 md:p-2 p-0.5 gap-0.5 md:gap-2"
  >
    <!-- 1 -->

    <div
      :class="
        focusedChart === 'chart-1'
          ? 'fixed top-0 left-0 h-screen w-screen z-50'
          : 'h-40 md:h-60 relative'
      "
      class="rounded-none md:rounded-lg bg-base-100 py-5 px-0.5 border border-slate-400"
    >
      <!-- line chart -->
      <button
        @click="toggleFocusedChart('chart-1')"
        class="btn btn-xs md:btn-sm top-0 right-0 absolute md:top-2 md:right-2 text-white"
      >
        <font-awesome-icon
          v-if="focusedChart === 'chart-1'"
          icon="fa-solid fa-xmark"
        />
        <font-awesome-icon v-else icon="fa-solid fa-expand" />
      </button>
      <line-chart
        label="Value"
        :data="chartData"
        width="100%"
        height="100%"
      ></line-chart>
    </div>
    <!-- 2 -->

    <div
      :class="
        focusedChart === 'chart-2'
          ? 'fixed top-0 left-0 h-screen w-screen z-50'
          : 'h-40 md:h-60 relative'
      "
      class="rounded-none md:rounded-lg bg-base-100 py-5 px-0.5 border border-slate-400"
    >
      <button
        @click="toggleFocusedChart('chart-2')"
        class="btn btn-xs md:btn-sm top-0 right-0 absolute md:top-2 md:right-2 text-white"
      >
        <font-awesome-icon
          v-if="focusedChart === 'chart-2'"
          icon="fa-solid fa-xmark"
        />
        <font-awesome-icon v-else icon="fa-solid fa-expand" />
      </button>
      <pie-chart
        label="Statuses"
        width="100%"
        height="100%"
        :data="[
          ['Blueberry', 44],
          ['Strawberry', 23],
        ]"
      ></pie-chart>
    </div>

    <!-- 4 -->

    <div
      :class="
        focusedChart === 'chart-3'
          ? 'fixed top-0 left-0 h-screen w-screen z-50'
          : 'h-40 md:h-60 relative'
      "
      class="rounded-none md:rounded-lg bg-base-100 py-5 px-0.5 border border-slate-400"
    >
      <button
        @click="toggleFocusedChart('chart-3')"
        class="btn btn-xs md:btn-sm top-0 right-0 absolute md:top-2 md:right-2 text-white"
      >
        <font-awesome-icon
          v-if="focusedChart === 'chart-3'"
          icon="fa-solid fa-xmark"
        />
        <font-awesome-icon v-else icon="fa-solid fa-expand" />
      </button>
      <column-chart
        label="Status Count"
        width="100%"
        height="100%"
        :data="[
          ['Sun', 32],
          ['Mon', 46],
          ['Tue', 28],
        ]"
      ></column-chart>
    </div>

    <div
      :class="
        focusedChart === 'chart-4'
          ? 'fixed top-0 left-0 h-screen w-screen z-50'
          : 'h-40 md:h-60 relative'
      "
      class="rounded-none md:rounded-lg bg-base-100 py-5 px-0.5 border border-slate-400"
    >
      <button
        @click="toggleFocusedChart('chart-4')"
        class="btn btn-xs md:btn-sm top-0 right-0 absolute md:top-2 md:right-2 text-white"
      >
        <font-awesome-icon
          v-if="focusedChart === 'chart-4'"
          icon="fa-solid fa-xmark"
        />
        <font-awesome-icon v-else icon="fa-solid fa-expand" />
      </button>
      <area-chart
        width="100%"
        height="100%"
        :data="{
          '2017-01-01 00:00:00 -0800': 2,
          '2017-01-01 00:01:00 -0800': 5,
        }"
      ></area-chart>
    </div>

    <!-- 5 -->

    <div
      :class="
        focusedChart === 'chart-5'
          ? 'fixed top-0 left-0 h-screen w-screen z-50'
          : 'h-40 md:h-60 relative'
      "
      class="rounded-none md:rounded-lg bg-base-100 py-5 px-0.5 border border-slate-400"
    >
      <button
        @click="toggleFocusedChart('chart-5')"
        class="btn btn-xs md:btn-sm top-0 right-0 absolute md:top-2 md:right-2 text-white"
      >
        <font-awesome-icon
          v-if="focusedChart === 'chart-5'"
          icon="fa-solid fa-xmark"
        />
        <font-awesome-icon v-else icon="fa-solid fa-expand" />
      </button>
      <line-chart
        width="100%"
        height="100%"
        :data="[
          {
            name: 'Workout',
            data: {
              '2017-01-01 00:00:00 -0800': 3,
              '2017-01-02 00:00:00 -0800': 4,
            },
          },
          {
            name: 'Call parents',
            data: {
              '2017-01-01 00:00:00 -0800': 5,
              '2017-01-02 00:00:00 -0800': 3,
            },
          },
        ]"
      />
    </div>

    <!-- 6 -->

    <div
      :class="
        focusedChart === 'chart-6'
          ? 'fixed top-0 left-0 h-screen w-screen z-50'
          : 'h-40 md:h-60 relative'
      "
      class="rounded-none md:rounded-lg bg-base-100 py-5 px-0.5 border border-slate-400"
    >
      <button
        @click="toggleFocusedChart('chart-6')"
        class="btn btn-xs md:btn-sm top-0 right-0 absolute md:top-2 md:right-2 text-white"
      >
        <font-awesome-icon
          v-if="focusedChart === 'chart-6'"
          icon="fa-solid fa-xmark"
        />
        <font-awesome-icon v-else icon="fa-solid fa-expand" />
      </button>
      <pie-chart
        width="100%"
        height="100%"
        :donut="true"
        :data="[
          ['Blueberry', 44],
          ['Strawberry', 23],
        ]"
      ></pie-chart>
    </div>

    <!-- 7 -->

    <div
      :class="
        focusedChart === 'chart-7'
          ? 'fixed top-0 left-0 h-screen w-screen z-50'
          : 'h-40 md:h-60 relative'
      "
      class="rounded-none md:rounded-lg bg-base-100 py-5 px-0.5 border border-slate-400"
    >
      <button
        @click="toggleFocusedChart('chart-7')"
        class="btn btn-xs md:btn-sm top-0 right-0 absolute md:top-2 md:right-2 text-white"
      >
        <font-awesome-icon
          v-if="focusedChart === 'chart-7'"
          icon="fa-solid fa-xmark"
        />
        <font-awesome-icon v-else icon="fa-solid fa-expand" />
      </button>
      <line-chart
        width="100%"
        height="100%"
        prefix="$"
        :data="[
          ['Drinks', 44],
          ['Cheese', 23],
        ]"
      ></line-chart>
    </div>

    <!-- 8 -->
    <div
      :class="
        focusedChart === 'chart-8'
          ? 'fixed top-0 left-0 h-screen w-screen z-50'
          : 'h-40 md:h-60 relative'
      "
      class="rounded-none md:rounded-lg bg-base-100 py-5 px-0.5 border border-slate-400"
    >
      <button
        @click="toggleFocusedChart('chart-8')"
        class="btn btn-xs md:btn-sm top-0 right-0 absolute md:top-2 md:right-2 text-white"
      >
        <font-awesome-icon
          v-if="focusedChart === 'chart-8'"
          icon="fa-solid fa-xmark"
        />
        <font-awesome-icon v-else icon="fa-solid fa-expand" />
      </button>
      <line-chart
        :data="[
          ['Drinks', 44],
          ['Cheese', 23],
        ]"
        suffix="%"
        width="100%"
        height="100%"
      ></line-chart>
    </div>
  </div>
</template>
