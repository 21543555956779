import {
  //axios
  axios,

  //pinia
  defineStore,
  useGlobalToastsStore,
  useGlobalPromptsStore,

  //env base url
  BASE_API_URL,
} from "../clientImports";

export const useTenantsAnnouncementsStore = defineStore({
  id: "tenants-announcements",
  state: () => ({
    showTenantsAnnouncementsCreateModal: false,
    showTenantsAnnouncementsEditModal: false,

    showAnnouncements: true,

    announcements: [],

    idToEdit: "",

    tenantsAnnouncementsList: [],

    currentlyCreatingAnnouncement: {
      active: true,
      color: "",
      announcement: "",
      title: "",
      announcement: "",
      description: "",
    },

    currentlyEditingAnnouncement: {
      active: true,
      color: "",
      announcement: "",
      title: "",
      announcement: "",
      description: "",
    },
  }),
  actions: {
    //two responsibilities - set the id to edit and then open the modal
    async initializeTenantsAnnouncementsEditModal(announcementId) {
      //set's the id of the Announcement that we're editing
      this.setIdToEdit(announcementId);

      await this.fetchTenantAnnouncement();
      //opens the edit modal
      this.showTenantsAnnouncementsEditModal = true;
    },

    toggleTenantsAnnouncementsCreateModal() {
      this.showTenantsAnnouncementsCreateModal =
        !this.showTenantsAnnouncementsCreateModal;
    },

    toggleTenantsAnnouncementsEditModal() {
      this.showTenantsAnnouncementsEditModal =
        !this.showTenantsAnnouncementsEditModal;
    },

    //fetches the Announcements for listing
    async fetchTenantsAnnouncements() {
      try {
        const { data } = await axios.get(
          `${BASE_API_URL}/read-tenants-announcements`
        );

        if (data.status === "success") {
          this.tenantsAnnouncementsList = data.announcementsOptions.map(
            (announcement) => {
              return {
                id: announcement._id,
                title: announcement.title,
                announcement: announcement.announcement,
                color: announcement.color,
                active: announcement.active,
                description: announcement.description,
                forced: announcement.forced,
              };
            }
          );
        } else {
          console.error(data.message);
        }
      } catch (error) {
        console.error();
      }
    },

    //fetching for editing
    async fetchTenantAnnouncement() {
      const id = this.idToEdit;
      try {
        const { data } = await axios.get(
          `${BASE_API_URL}/read-tenants-announcement/${id}`
        );

        console.log(data, "FETCHED MILESTONE");

        if (data.status === "success") {
          console.log(data.announcement);
          this.currentlyEditingAnnouncement = data.announcement[0];

          console.log(this.currentlyEditingAnnouncement);
        } else {
          console.error(data.message);
        }
      } catch (error) {
        console.error();
      }
    },

    setIdToEdit(announcementId) {
      this.idToEdit = announcementId;
    },

    async createTenantsAnnouncement() {
      const announcementData = this.currentlyCreatingAnnouncement;

      console.log("PASSPORT DATA SENDING OUT: ", announcementData);
      try {
        const { data } = await axios.post(
          `${BASE_API_URL}/create-tenants-announcement`,
          announcementData
        );

        console.log("CREATE PASSPORT RESPONSE", data);

        if (data.status === "success") {
          const toastsStore = useGlobalToastsStore();
          toastsStore.addToast({
            title: "Announcement Created",
            description: "",
            buttonExists: false,
            color: "emerald",
            type: 1,
          });

          //fetch the updated list of Announcements
          this.fetchTenantsAnnouncements();
          this.toggleTenantsAnnouncementsCreateModal();
        } else {
          console.error(data.message);
          toastsStore.addToast({
            title: "Error Creating Announcement",
            description: data.message,
            buttonExists: false,
            color: "red",
            type: 1,
          });
        }
      } catch (error) {
        console.error();
      }
    },

    async updateTenantsAnnouncement() {
      const id = this.idToEdit;
      try {
        const { data } = await axios.put(
          `${BASE_API_URL}/update-tenants-announcement/${id}`,
          this.currentlyEditingAnnouncement
        );

        if (data.status === "success") {
          console.log(data.message);

          this.toggleTenantsAnnouncementsEditModal();
          //fetch the updated list of Announcements
          this.fetchTenantsAnnouncements();

          //throw toast
          const toastsStore = useGlobalToastsStore();
          toastsStore.addToast({
            title: "Announcement Updated",
            description: "",
            buttonExists: false,
            color: "emerald",
            type: 1,
          });
        } else {
          console.error(data.message);
          toastsStore.addToast({
            title: "Error Updating Announcement",
            description: data.message,
            buttonExists: false,
            color: "red",
            type: 1,
          });
        }
      } catch (error) {
        console.error();
      }
    },

    async deleteTenantsAnnouncement(announcementId) {
      const promptStore = useGlobalPromptsStore();

      const group_id = announcementId;

      promptStore.addPrompt({
        type: "Announcement",
        title: "Delete Announcement?",
        message: `Are you sure you want to delete this Announcement?`,
      });

      const promptResponse = await promptStore.waitForPromptConfirmation();

      if (promptResponse === true) {
        promptStore.addPrompt({
          type: "Announcement",
          title: "Are You Sure?",
          message: `We'll hide this from your view so it can no longer be used. Any contacts or users that have this announcement assigned will remain assigned and have to be manually changed. Do you still want to proceed with the deletion?`,
        });

        const promptResponse2 = await promptStore.waitForPromptConfirmation();

        if (promptResponse2 === true) {
          try {
            const { data } = await axios.delete(
              `${BASE_API_URL}/delete-tenants-announcement/${group_id}`
            );

            if (data.status === "success") {
              //fetch the updated list of Announcements
              this.fetchTenantsAnnouncements();
            } else {
              console.error(data.message);
              toastsStore.addToast({
                title: "Error Deleting Announcement",
                description: data.message,
                buttonExists: false,
                color: "red",
                type: 1,
              });
            }
          } catch (error) {
            console.error();
          }
          promptStore.removePrompt();
        } else {
          promptStore.removePrompt();
        }
      } else {
        promptStore.removePrompt();
      }
    },
  },
});
