import "@formkit/themes/genesis";
import { genesisIcons } from "@formkit/icons";
import { createProPlugin, inputs } from "@formkit/pro";
import {
  createMultiStepPlugin,
  createAutoAnimatePlugin,
  createAutoHeightTextareaPlugin,
} from "@formkit/addons";
import "@formkit/addons/css/multistep";
import "@formkit/pro/genesis";

const pro = createProPlugin("fk-68b6649cd5", inputs);
const autoAnimate = createAutoAnimatePlugin(
  {
    /* optional AutoAnimate config */
    // default:
    duration: 250,
    easing: "ease-in-out",
    delay: 0,
  },
  {
    /* optional animation targets object */
    // default:
    global: ["outer", "inner"],
    form: ["form"],
    repeater: ["items"],
  }
);
//prettier-ignore
const config = {
  debug: true, // Enable debugging mode
  plugins: [
    pro,
    autoAnimate,
    createMultiStepPlugin(),
    createAutoHeightTextareaPlugin(),
  ],
  icons: { ...genesisIcons },
};

export default config;
