<script setup>
defineOptions({
  name: 'SettingsUsersCreate', // Give the component a name
});
import {
  //axios
  axios,

  //vue
  reactive,
  computed,
  watch,
  ref,
  onMounted,
  Teleport,

  //pinia stores
  useGlobalPromptsStore,
  useGlobalToastsStore,
  useUsersStore,
  useLoginStore,

  //formkit
  FKsubmitForm,

  //vue-router
  useRouter,
  useRoute,

  //utilities
  usersLevelOptions,
  departmentOptions, //TODO - need to replace this with fetched departments from db
  fieldsDefaultValidation,
  cleanUpperCase,

  //base api url
  BASE_API_URL,
} from "./../clientImports";

import {
  browserTimezone,
  timezoneOptions,
} from "./../centralized_data/timezoneOptions";

const router = useRouter();
const route = useRoute();

const toastsStore = useGlobalToastsStore();
const promptsStore = useGlobalPromptsStore();
const usersStore = useUsersStore();
const loginStore = useLoginStore();

onMounted(async () => {
  await usersStore.fetchAllRoles();
  await usersStore.fetchAllAffiliateGroups();
});

const resetUser = () => {
  for (const key in user) {
    user[key] = getDefault(key);
  }
};

const getDefault = (key) => {
  const defaults = {
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    phone_caller_id: "",
    user_phone_number: "",
    user_phone_extension: "",
    active: false,
    level_access: "",
    passport_required: false,
    department: "",
    affiliate_group_id: "",
    roles: {},
    alert_email: false,
    timezone: "",
  };
  return defaults[key];
};

const affiliateGroupOptions = computed(() => {
  return [
    {
      label: "- No Group Selected -",
      value: "",
    },
    ...usersStore.affiliateGroupOptions,
  ];
});

const user = reactive({
  first_name: "",
  last_name: "",
  email: "",
  password: "",
  // password_confirmation: "",

  // vendor_user_id: "",

  phone_caller_id: "",

  user_phone_number: "",
  user_phone_extension: "",

  active: false,
  level_access: "",

  passport_required: false,
  department: "",

  affiliate_group_id: "",

  roles: {},

  alert_email: false,

  timezone: "",
  // support_admin: false, - should only be set by master

  // tenant_id: "", - set by server
  // created_by: "", - set by server
  // updated_by: "", - set by server

  // created_at: "",
  // updated_at: "",
});

//need to watch user.level access and set the affiliate_group_id to 0 if the level access is not affiliate
const resetAffiliateGroup = (event) => {
  if (
    event.target.value !== "affiliate-admin" &&
    event.target.value !== "affiliate-user"
  ) {
    user.affiliate_group_id = "";
  }
};

user.timezone = browserTimezone;

const createUser = async () => {
  const response = await usersStore.createUser(user);

  if (response.status === "success") {
    toastsStore.addToast({
      title: "User Created",
      description: `${user.first_name} ${user.last_name} has been created successfully`,
      buttonExists: false,
      color: "emerald",
      type: 1,
    });

    resetUser();

    usersStore.toggleCreateUserModal();

    await usersStore.readUsers();
  } else {
    toastsStore.addToast({
      title: "Error",
      description: `${response.specific}`,
      buttonExists: false,
      color: "red",
      type: 3,
    });
  }
};

const submitCreateUser = async () => {
  FKsubmitForm("user-creation-form");
};
</script>

<template>
  <cSidebarModal
    v-if="
      loginStore?.sessionData?.permissions?.includes('create:users') ||
      loginStore?.sessionData?.permissions?.includes('*:dev-permissions')
    "
    sidebarPosition="right"
    :showSidebarModal="usersStore.showCreateUserModal"
    :toggleSidebarModal="
      loginStore?.sessionData?.permissions?.includes('create:users') ||
      loginStore?.sessionData?.permissions?.includes('*:dev-permissions')
        ? () => $router.push({ name: 'settings-create-users' })
        : null
    "
    :hasBackdrop="true"
    :toggleButtonColor="`blue`"
    :defaultButton="true"
  >
    <template #toggle-button>
      <span class="hidden md:block mr-2">Create User</span>
      <font-awesome-icon icon="fa-solid fa-plus" />
    </template>
    <template #title>Create User</template>
    <template #button-group> </template>

    <template #body>
      <div class="h-full max-h-full">
        <FormKit
          id="user-creation-form"
          type="form"
          :incomplete-message="false"
          submit-label="Create"
          form-class="text-white h-full flex flex-col justify-between"
          @submit="createUser"
          outer-class="m-0"
          :actions="false"
          :submit-attrs="{
            'data-theme': `light`,
            ignore: true,
          }"
        >
          <div class="flex-1 flex flex-col justify-start">
            <div class="grid grid-cols-2 gap-5 h-full max-h-full">
              <FormKit
                type="text"
                label="First Name"
                value=""
                placeholder=""
                :validation="
                  'required:trim|' + fieldsDefaultValidation.name.validation
                "
                help-class="text-white"
                help=""
                outer-class="m-0"
                input-class="$reset rounded w-full input input-sm text-gray-700 bg-gray-50"
                wrapper-class="max-w-full"
                v-model.trim="user.first_name"
              />

              <FormKit
                type="text"
                label="Last Name"
                placeholder=""
                :validation="
                  'required:trim|' + fieldsDefaultValidation.name.validation
                "
                help-class="text-white"
                help=""
                outer-class="m-0"
                input-class="$reset rounded w-full input input-sm text-gray-700 bg-gray-50"
                wrapper-class="max-w-full"
                v-model.trim="user.last_name"
              />

              <FormKit
                type="email"
                label="Email"
                placeholder=""
                :validation="
                  'required:trim|' + fieldsDefaultValidation.email.validation
                "
                help-class="text-white"
                help=""
                outer-class="m-0"
                input-class="$reset rounded w-full input input-sm text-gray-700 bg-gray-50"
                wrapper-class="max-w-full"
                v-model.trim="user.email"
              />

              <!-- <FormKit
                              type="text"
                              label="Vendor User ID"
                              placeholder=""
                              help-class="text-white"
                              help=""
                              outer-class="m-0"
                              input-class="input input-sm text-gray-700 bg-gray-50"
                              wrapper-class="max-w-full"
                              v-model.trim="user.vendor_user_id"
                            /> -->

              <FormKit
                type="text"
                label="Phone Caller ID"
                placeholder=""
                help-class="text-white"
                :validation="fieldsDefaultValidation.callerId.validation"
                help=""
                outer-class="m-0"
                input-class="$reset rounded w-full input input-sm text-gray-700 bg-gray-50"
                wrapper-class="max-w-full"
                v-model.trim="user.phone_caller_id"
              />

              <FormKit
                type="tel"
                label="User Phone Number"
                placeholder=""
                :validation="fieldsDefaultValidation.phone.validation"
                :validation-messages="
                  fieldsDefaultValidation.phone.validationMessages
                "
                help-class="text-white"
                help=""
                outer-class="m-0"
                input-class="$reset rounded w-full input input-sm text-gray-700 bg-gray-50"
                wrapper-class="max-w-full"
                v-model.trim="user.user_phone_number"
              />

              <FormKit
                type="text"
                label="User Phone Extension"
                placeholder=""
                help-class="text-white"
                help=""
                validation="length:1,10"
                outer-class="m-0"
                input-class="$reset rounded w-full input input-sm text-gray-700 bg-gray-50"
                wrapper-class="max-w-full"
                v-model.trim="user.user_phone_extension"
              />

              <FormKit
                type="select"
                label="Level Access"
                validation="required"
                :value="0"
                :options="usersLevelOptions"
                @change="resetAffiliateGroup($event)"
                help-class="text-white"
                help=""
                outer-class="m-0 $reset col-span-2"
                input-class="$reset select select-sm text-gray-700 bg-gray-50 rounded w-full"
                wrapper-class="max-w-full"
                v-model="user.level_access"
              />
              <FormKit
                v-if="
                  user.level_access === 'affiliate-admin' ||
                  user.level_access === 'affiliate-user'
                "
                type="select"
                label="Affiliate Group"
                :options="affiliateGroupOptions"
                help-class="text-white"
                help=""
                :validation="`${
                  user.level_access === 'affiliate-admin' ||
                  user.level_access === 'affiliate-user'
                    ? 'required'
                    : ''
                }`"
                outer-class="m-0 $reset col-span-2"
                input-class="$reset select select-sm text-gray-700 bg-gray-50 rounded w-full"
                wrapper-class="max-w-full"
                v-model="user.affiliate_group_id"
              />

              <FormKit
                type="select"
                label="Department"
                placeholder=""
                validation="required"
                help-class="text-white"
                outer-class="m-0 $reset col-span-2"
                :options="departmentOptions"
                help=""
                input-class="$reset rounded w-full select select-sm text-gray-700 bg-gray-50"
                wrapper-class="max-w-full"
                v-model.trim="user.department"
              />

              <FormKit
                type="select"
                label="Role"
                :validation="[
                  [
                    !loginStore?.sessionData?.permissions?.includes(
                      '*:dev-permissions'
                    )
                      ? 'required'
                      : '',
                  ],
                ]"
                :options="[
                  {
                    value: {},
                    label: '- No Role Selected -',
                    attrs: { disabled: true },
                  },
                  ...usersStore.rolesOptions,
                ]"
                help-class="text-white"
                help="Hides/Shows Certain Features Based on Role"
                outer-class="m-0 $reset col-span-2"
                input-class="$reset select select-sm text-gray-700 bg-gray-50 rounded w-full"
                wrapper-class="max-w-full"
                v-model="user.roles"
              />

              <FormKit
                type="password"
                label="Password"
                name="password"
                placeholder=""
                :validation="
                  'required:trim|' +
                  fieldsDefaultValidation?.password?.validation
                "
                help-class="text-white"
                help=""
                outer-class="m-0"
                input-class="$reset rounded w-full input input-sm text-gray-700 bg-gray-50"
                wrapper-class="max-w-full"
                v-model.trim="user.password"
              />

              <FormKit
                type="password"
                label="Confirm Password"
                name="password_confirm"
                placeholder=""
                :validation="
                  fieldsDefaultValidation?.passwordConfirm?.validation
                "
                help-class="text-white"
                help=""
                outer-class="m-0"
                input-class="$reset rounded w-full input input-sm text-gray-700 bg-gray-50"
                wrapper-class="max-w-full"
                v-model.trim="user.password_confirmation"
              />

              <FormKit
                type="autocomplete"
                label="Timezone of User"
                validation="required"
                :options="timezoneOptions"
                listbox-class="border border-slate-400 rounded"
                listitem-class="bg-white"
                listbox-button-class="bg-gray-50 text-gray-700"
                option-class="text-gray-700"
                selection-appearance="text-input"
                selection-class="text-gray-700"
                help-class="text-white"
                :help="`Your Device Timezone: ${browserTimezone}`"
                outer-class="m-0 col-span-2"
                input-class="rounded-r-none text-gray-700 bg-gray-50 rounded w-full"
                wrapper-class="max-w-full"
                v-model="user.timezone"
                :value="user.timezone"
                :filter="
                  (option, search) =>
                    option.label.toLowerCase().includes(search.toLowerCase())
                "
              />

              <FormKit
                type="checkbox"
                label="Passport Required - Forces IP Restriction on User"
                help-class="text-white"
                help=""
                outer-class="m-0 col-span-2"
                input-class="text-gray-700 bg-gray-50"
                decorator-class="bg-white"
                wrapper-class="max-w-full"
                v-model="user.passport_required"
              />

              <FormKit
                type="checkbox"
                label="Enable Appointment Email Alerts"
                help-class="text-white"
                help=""
                outer-class="m-0 col-span-2"
                input-class="input input-sm text-gray-700 bg-gray-50"
                wrapper-class="max-w-full"
                v-model="user.alert_email"
              />

              <FormKit
                type="radio"
                label="Make User Active"
                :options="[
                  {
                    label: 'Yes - You will be billed for the user immediately',
                    value: true,
                  },
                  {
                    label:
                      'No - User is not immediately billed and will list under Inactive Users',
                    value: false,
                  },
                ]"
                :validation="`required`"
                outer-class="m-0 col-span-2 border border-white border-width-2 bg-red-500 rounded"
                fieldset-class="$reset w-full max-w-full p-3"
                wrapper-class="w-full max-w-full"
                decorator-class="border border-white"
                decorator-icon-class="bg-white rounded-full border-white border"
                v-model="user.active"
              />
            </div>
          </div>
        </FormKit>
      </div>
    </template>

    <template #footer>
      <div class="flex flex-row justify-between">
        <button
          @click="usersStore.toggleCreateUserModal"
          class="bg-red-500 btn rounded text-white hover:bg-red-600 capitalize btn-sm border-none"
        >
          Cancel
        </button>

        <button
          @click="submitCreateUser"
          class="bg-emerald-500 btn rounded text-white capitalize btn-sm border-none"
        >
          Create User
        </button>
      </div>
    </template>
  </cSidebarModal>
</template>
