import {
  //axios
  axios,

  //pinia
  defineStore,

  //env base url
  BASE_API_URL,
  useRecentContactsStore,
} from "../clientImports";

export const useContactsStore = defineStore({
  id: "contacts",
  state: () => ({
    recentContactsStore: null,

    //create contact modal data toggle
    showCreateContactModal: false,

    //fetched columns from contacts schema
    contactsColumns: [],

    defaultColumns: [
      { id: 0, columnName: "id", uniqueName: "_id" },
      { id: 1, columnName: "first name", uniqueName: "first_name" },
      { id: 3, columnName: "last name", uniqueName: "last_name" },
      { id: 4, columnName: "email primary", uniqueName: "email_primary" },
      { id: 7, columnName: "phone primary", uniqueName: "phone_primary" },
    ],

    //selected columns - local storage ok
    selectedColumns: JSON.parse(localStorage.getItem("selectedColumns")) || [
      { id: 0, columnName: "id", uniqueName: "_id" },
      { id: 1, columnName: "first name", uniqueName: "first_name" },
      { id: 3, columnName: "last name", uniqueName: "last_name" },
      { id: 4, columnName: "email primary", uniqueName: "email_primary" },
      { id: 7, columnName: "phone primary", uniqueName: "phone_primary" },
    ],

    // PAGINATION vv - set and used from local storage
    pageCount: Number(JSON.parse(localStorage.getItem("pageCount"))) || 10, //the number of contacts to return per page
    skipCount: Number(JSON.parse(localStorage.getItem("skipCount"))) || 0, //the number of contacts to skip in the query - used for pagination
    page: Number(JSON.parse(localStorage.getItem("page"))) || 0, //the page number used to calculate skipCount - used for pagination
    //PAGINATION ^^

    //query results vv
    contacts: null,
    contactsCount: 0,
    currentPageCount: 0,
    contactsResultsPageCount: 0,
    //query results ^^
  }),
  actions: {
    initializeSharedStores() {
      this.recentContactsStore = useRecentContactsStore();
    },

    toggleCreateContactModal() {
      this.showCreateContactModal = !this.showCreateContactModal;
    },

    async createContact(contactDetails) {
      const result = await axios.post(
        `${BASE_API_URL}/create-contact`,
        contactDetails
      );

      return result;
    },

    async setSelectedColumns() {
      localStorage.setItem(
        "selectedColumns",
        JSON.stringify(this.selectedColumns)
      );

      this.fetchContacts();
    },

    async fetchContacts() {
      try {
        const body = {
          selectedColumns: this.selectedColumns,
          pageCount: this.pageCount,
          skipCount: this.skipCount,
        };

        const response = await axios.post(`${BASE_API_URL}/get-contacts`, body);

        this.contacts = response.data.contacts;

        this.contactsCount = response.data.contactsCount;

        this.contactsResultsPageCount = response.data.contactsResultsPageCount;

        this.currentPageCount = response.data.currentPageCount;
      } catch (error) {
        console.log(error);
      }
    },

    //TODO - no local storage needed
    async fetchContactsColumns() {
      //fetches via schema design
      const response = await axios.get(`${BASE_API_URL}/get-contacts-columns`);

      this.contactsColumns = [...response.data.contactsColumns];

      this.fetchContacts();

      return response;
    },

    async deleteContact(id) {
      try {
        const response = await axios.delete(
          `${BASE_API_URL}/delete-contact/${id}`
        );

        if (response.data.status === "success") {
          this.page = 0;
          this.initializeSharedStores();
          this.recentContactsStore.removeFromRecentContacts(id);
          return response.data;
        }
      } catch (error) {
        console.log(error);
        // Handle error
      }
    },

    //delete localStorage data function
    deleteLocalStorageData() {
      localStorage.removeItem("selectedColumns");
      localStorage.removeItem("pageCount");
      localStorage.removeItem("skipCount");
      localStorage.removeItem("page");
    },
  },
});
