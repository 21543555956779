<script setup>
defineOptions({
  name: 'ContactNotesTab', // Give the component a name
});
import {
  //axios
  axios,

  //vue
  createApp,
  ref,
  reactive,
  watch,
  computed,
  inject,
  provide,
  onMounted,
  onUnmounted,
  nextTick,
  Teleport,

  //pinia
  defineStore,
  createPinia,
  storeToRefs,

  //pinia stores
  useChatStore,
  useContactAlertsStore,
  useContactFileStore,
  useContactsStore,
  useGlobalPromptsStore,
  useGlobalToastsStore,
  useLoginStore,
  useTenantsStore,

  //vue-router
  useRouter,
  useRoute,

  //formkit

  //formkit config file

  //fontawesome
  library,
  FontAwesomeIcon,
  fas,
  far,
  fab,

  //global components - all vue components
  registerGlobalComponents,

  //utils
  xLog,

  //base api url
  BASE_API_URL,
} from "./../clientImports";

const router = useRouter();
const route = useRoute();

const noteHistoryFeed = reactive([
  {
    _id: "1",
    note: "This client was dope",
    user: "Nicholas Lancaster",
    date: "01/01/1985",
    origin: "user",
    color: "purple",
  },
  {
    _id: "1",
    note: "This client was not that dope",
    user: "Nicholas Lancaster",
    date: "01/01/1985",
    origin: "bot",
    color: "yellow",
  },
  {
    _id: "1",
    note: "This is where my note would be",
    user: "Link Zelda",
    date: "01/01/1985",
    origin: "automation",
    color: "green",
  },
]);

const deleteNote = async (note) => {
  xLog("Note to Delete: ", note);
};

const editNote = async (note) => {
  xLog("Note to Edit: ", note);
};
</script>

<template>
  <div class="w-full h-full">
    <cTable
      :tableName="'Notes'"
      :tableData="noteHistoryFeed"
      :onDelete="deleteNote"
      :onEdit="editNote"
    />
  </div>
</template>
