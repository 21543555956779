<script setup>
defineOptions({
  name: 'SettingsCampaignsCreate', // Give the component a name
});
import {
  //axios
  axios,

  //vue
  reactive,
  ref,
  onMounted,
  Teleport,

  //pinia stores
  useGlobalPromptsStore,
  useGlobalToastsStore,
  useUsersStore,

  //vue-router
  useRouter,
  useRoute,

  //utilities
  usersLevelOptions,
  departmentOptions,
  fieldsDefaultValidation,
  cleanUpperCase,

  //base api url
  BASE_API_URL,

  //pinia
  useTenantsCampaignsStore,

  //formkit
  FKsubmitForm,
} from "./../clientImports";

import {
  browserTimezone,
  timezoneOptions,
} from "./../centralized_data/timezoneOptions";

const router = useRouter();
const route = useRoute();

const tenantsCampaignsStore = useTenantsCampaignsStore();
const toastsStore = useGlobalToastsStore();

const createCampaignData = ref({
  name: "",
  description: "",
});

const submitCreateCampaignForm = async () => {
  return FKsubmitForm("create-tenant-campaign-form");
};

const createCampaign = async () => {
  try {
    console.log("createCampaignData", createCampaignData.value);
    const { data } = await tenantsCampaignsStore.createTenantCampaign(
      createCampaignData.value
    );

    if (data.status === "success") {
      createCampaignData.value = {};
      tenantsCampaignsStore.toggleCreateCampaignModal();
      toastsStore.addToast({
        title: "Campaign Created Successfully",
        color: "emerald",
        type: 1,
      });

      await tenantsCampaignsStore.fetchTenantsCampaignsListing();
    }
  } catch (error) {
    console.log(error);

    toastsStore.addToast({
      title: "Error Creating Campaign",
      color: "red",
      type: 1,
    });
  }
};
</script>

<template>
  <cSidebarModal
    sidebar-position="right"
    :show-sidebar-modal="tenantsCampaignsStore.showCreateCampaignModal"
    :toggle-sidebar-modal="tenantsCampaignsStore.toggleCreateCampaignModal"
  >
    <template #title> Create Campaign </template>

    <template #body>
      <div class="w-full flex flex-col">
        <FormKit
          @submit="createCampaign"
          id="create-tenant-campaign-form"
          :incomplete-message="false"
          type="form"
          :actions="false"
          :submit-attrs="{
            inputClass: '',
            wrapperClass: '',
            'data-theme': `dark`,
            help: '',
            ignore: true,
          }"
        >
          <FormKit
            type="text"
            label="Name"
            form-class="w-full"
            outer-class="w-full"
            wrapper-class="w-full max-w-full"
            inner-class="w-full"
            input-class="w-full max-w-full bg-base-300 text-white"
            v-model="createCampaignData.name"
            validation="required"
          ></FormKit>
          <FormKit
            type="textarea"
            input-class="bg-base-300 text-white"
            wrapper-class="max-w-full"
            label="Description"
            v-model="createCampaignData.description"
          ></FormKit>
        </FormKit>
      </div>
    </template>

    <template #footer>
      <div class="flex flex-row justify-between">
        <button
          type="button"
          @click="tenantsCampaignsStore.toggleCreateCampaignModal"
          class="btn btn-sm bg-red-500 rounded border-none text-white capitalize"
        >
          Cancel
        </button>

        <button
          type="button"
          @click.stop="submitCreateCampaignForm"
          class="btn btn-sm rounded border-none text-white capitalize bg-emerald-500"
        >
          Create Campaign
        </button>
      </div>
    </template>
  </cSidebarModal>
</template>
