<script setup>
defineOptions({
  name: 'cSystemLogo', // Give the component a name
});
import {
  //vue
  ref,
  computed,
  watch,
  reactive,

  //formkit
  FKsubmitForm,

  //utils
  xLog,
} from "../clientImports";

const props = defineProps({
  fontSize: {
    type: String,
    default: "text-5xl",
  },

  padding: {
    type: String,
    default: "p-5",
  },

  classes: {
    type: String,
    default: "p-5 text-5xl mb-5",
  },

  marginBottom: {
    type: String,
    default: "mb-5",
  },
});

const fontSize = ref(props.fontSize);
const padding = ref(props.padding);
const classes = ref(props.classes);
const marginBottom = ref(props.marginBottom);
</script>

<template>
  <div :class="`text-center ${marginBottom}`">
    <h3
      :class="`font-pacifico ${classes}`"
      class="inline text-center bg-gradient-to-tr from-orange-500 via-indigo-500 to-green-500 mask-text"
    >
      FinnyPi
    </h3>
  </div>
</template>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Pacifico&display=swap");

.font-pacifico {
  font-family: "Pacifico", cursive;
}
</style>
