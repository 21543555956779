<script setup>
defineOptions({
  name: 'SettingsPaymentsRoutesCreate', // Give the component a name
});
import {
  //axios
  axios,

  //vue
  createApp,
  ref,
  reactive,
  watch,
  computed,
  inject,
  provide,
  onMounted,
  onUnmounted,
  nextTick,
  Teleport,

  //pinia
  defineStore,
  createPinia,
  storeToRefs,

  //pinia stores
  useChatStore,
  useContactAlertsStore,
  useContactFileStore,
  useContactsStore,
  useGlobalPromptsStore,
  useGlobalToastsStore,
  useLoginStore,
  useTenantsStore,
  useUsersStore,
  useTenantsPaymentsRoutesStore,
  useTenantsAccountTypesStore,
  useTenantsScheduleTypesStore,
  useTenantsGatewaysStore,

  //vue-router
  useRouter,
  useRoute,

  //formkit

  //formkit config file
  FKsubmitForm,

  //fontawesome
  library,
  FontAwesomeIcon,
  fas,
  far,
  fab,

  //centralized data
  usersLevelOptions,
  fieldsDefaultValidation,
  stateOptions,

  //utilities
  getRandomNumberBetween,

  //helpers
  registerGlobalComponents,

  //utilities
  stripEmptyFields,

  //base api url
  BASE_API_URL,
} from "./../clientImports";

import {
  browserTimezone,
  timezoneOptions,
} from "./../centralized_data/timezoneOptions";

const router = useRouter();
const route = useRoute();

const loginStore = useLoginStore();

const usersStore = useUsersStore();
const toastsStore = useGlobalToastsStore();
const promptsStore = useGlobalPromptsStore();
const tenantsPaymentsRoutesStore = useTenantsPaymentsRoutesStore();
const tenantsAccountTypesStore = useTenantsAccountTypesStore();
const tenantsScheduleTypesStore = useTenantsScheduleTypesStore();
const tenantsGatewaysStore = useTenantsGatewaysStore();

//onmounted
onMounted(async () => {
  await tenantsAccountTypesStore.fetchTenantsAccountTypes(); //initial populate affiliate list
  await tenantsScheduleTypesStore.fetchTenantsScheduleTypes(); //initial populate affiliate list
  await tenantsGatewaysStore.fetchTenantsGateways(); //initial populate affiliate list
});

//computed function that returns the Account Types Options in a map with the label and value
const accountTypesOptions = computed(() => {
  return tenantsAccountTypesStore.tenantsAccountTypesList.map((accountType) => {
    return {
      label: accountType.name,
      value: accountType.id,
    };
  });
});

//computed function that returns the Schedule Types Options in a map with the label and value
const scheduleTypesOptions = computed(() => {
  return tenantsScheduleTypesStore.tenantsScheduleTypesList.map(
    (scheduleType) => {
      return {
        label: scheduleType.name,
        value: scheduleType.id,
      };
    }
  );
});

//computed function that returns the Gateways Options in a map with the label and value
const gatewaysOptions = computed(() => {
  return tenantsGatewaysStore.tenantsGatewaysList.map((gateway) => {
    return {
      label: gateway.name,
      value: gateway.id,
    };
  });
});

//computed function that returns the Account Type Label
const returnAccountTypeLabel = computed(() => {
  const accountType = tenantsAccountTypesStore.tenantsAccountTypesList.find(
    (accountType) =>
      accountType.id ===
      tenantsPaymentsRoutesStore.currentlyCreatingPaymentsRoute.account_type_id
  );
  return accountType ? accountType.name : "No Account Type Selected";
});

//computed function that returns the Schedule Type Label
const returnScheduleTypeLabel = computed(() => {
  const scheduleType = tenantsScheduleTypesStore.tenantsScheduleTypesList.find(
    (scheduleType) =>
      scheduleType.id ===
      tenantsPaymentsRoutesStore.currentlyCreatingPaymentsRoute.schedule_type_id
  );
  return scheduleType ? scheduleType.name : "No Schedule Type Selected";
});

//computed function that returns the Gateway Label
const returnGatewayLabel = computed(() => {
  const gateway = tenantsGatewaysStore.tenantsGatewaysList.find(
    (gateway) =>
      gateway.id ===
      tenantsPaymentsRoutesStore.currentlyCreatingPaymentsRoute.gateway_id
  );
  return gateway ? gateway.name : "No Gateway Selected";
});

//use FKsubmitForm to submit form
const submitCreateForm = () => {
  return FKsubmitForm("create-payments-route");
};
</script>

<template>
  <cSidebarModal
    sidebar-position="right"
    :show-sidebar-initial="false"
    :show-sidebar-modal="
      tenantsPaymentsRoutesStore.showTenantsPaymentsRoutesCreateModal
    "
    :toggle-sidebar-modal="
      tenantsPaymentsRoutesStore.toggleTenantsPaymentsRoutesCreateModal
    "
    :default-button="false"
  >
    <template #title>
      <h2>Create Payments Route</h2>
    </template>

    <template #body>
      <FormKit
        id="create-payments-route"
        type="form"
        :incomplete-message="false"
        submit-label="Create"
        form-class="text-white h-full flex flex-col justify-between"
        @submit="tenantsPaymentsRoutesStore.createTenantsPaymentsRoute()"
        outer-class="m-0"
        :actions="false"
        :submit-attrs="{
          'data-theme': `light`,
          ignore: true,
        }"
      >
        <div class="grid grid-cols-1 gap-5">
          <div class="flex flex-row gap-5">
            <FormKit
              type="text"
              label="Name"
              placeholder=""
              :validation="'required:trim|length:5, 40'"
              help-class="text-white"
              help=""
              outer-class="m-0 flex-1"
              input-class="$reset rounded w-full input input-sm text-gray-700 bg-gray-50"
              wrapper-class="max-w-full"
              v-model.trim="
                tenantsPaymentsRoutesStore.currentlyCreatingPaymentsRoute.name
              "
            />
            <FormKit
              outer-class=""
              type="toggle"
              name="toggle"
              label="Active"
              alt-label-position
              v-model="
                tenantsPaymentsRoutesStore.currentlyCreatingPaymentsRoute.active
              "
            />
          </div>

          <!-- schedule type -->
          <FormKit
            type="select"
            label="Account Type"
            placeholder=""
            :validation="[['required']]"
            :validation-messages="{
              required:
                'The Account Type is required to classify which type of account this payment route is for',
            }"
            help-class="text-white"
            help="Classify the schedule type with a custom schedule type"
            outer-class="m-0 "
            input-class="$reset rounded w-full input input-sm text-gray-700 bg-gray-50"
            wrapper-class="max-w-full"
            v-model.trim="
              tenantsPaymentsRoutesStore.currentlyCreatingPaymentsRoute
                .account_type_id
            "
            :options="[
              {
                value: '',
                label: '- Choose Schedule Type',
                attrs: { disabled: true },
              },
              ...accountTypesOptions,
            ]"
          />

          <!-- schedule type -->
          <FormKit
            type="select"
            label="Schedule Type"
            placeholder=""
            :validation="[['required']]"
            :validation-messages="{
              required:
                'The Schedule Type is required to classify the payment schedule for reporting purposes',
            }"
            help-class="text-white"
            help="Classify the schedule type with a custom schedule type"
            outer-class="m-0 "
            input-class="$reset rounded w-full input input-sm text-gray-700 bg-gray-50"
            wrapper-class="max-w-full"
            v-model.trim="
              tenantsPaymentsRoutesStore.currentlyCreatingPaymentsRoute
                .schedule_type_id
            "
            :options="[
              {
                value: '',
                label: '- Choose Schedule Type',
                attrs: { disabled: true },
              },
              ...scheduleTypesOptions,
            ]"
          />

          <FormKit
            type="select"
            label="Payment Gateway"
            placeholder=""
            :validation="[['required']]"
            :validation-messages="{
              required:
                'The Payment Gateway is required to route the money to your desired merchant',
            }"
            help-class="text-white"
            help="Classify the schedule type with a custom schedule type"
            outer-class="m-0 "
            input-class="$reset rounded w-full input input-sm text-gray-700 bg-gray-50"
            wrapper-class="max-w-full"
            v-model.trim="
              tenantsPaymentsRoutesStore.currentlyCreatingPaymentsRoute
                .gateway_id
            "
            :options="[
              {
                value: '',
                label: '- Choose Schedule Type',
                attrs: { disabled: true },
              },
              ...gatewaysOptions,
            ]"
          />

          <FormKit
            type="select"
            label="Payment Method Type"
            placeholder=""
            :validation="[['required']]"
            :validation-messages="{
              required:
                'The Payment Method Type is required to process the right type of payments',
            }"
            help-class="text-white"
            help="Classify the schedule type with a custom schedule type"
            outer-class="m-0 "
            input-class="$reset rounded w-full input input-sm text-gray-700 bg-gray-50"
            wrapper-class="max-w-full"
            v-model.trim="
              tenantsPaymentsRoutesStore.currentlyCreatingPaymentsRoute
                .payment_method_type
            "
            :options="[
              {
                value: '',
                label: '- Choose Schedule Type',
                attrs: { disabled: true },
              },
              {
                value: 'credit-card',
                label: 'Credit Card',
              },
              {
                value: 'checking',
                label: 'Checking Account',
              },
              {
                value: 'savings',
                label: 'Savings Account',
              },
            ]"
          />

          <FormKit
            type="textarea"
            label="Description"
            placeholder=""
            :validation="'trim|length:1, 255'"
            help-class="text-white"
            help=""
            outer-class="m-0 "
            input-class="$reset rounded w-full input input-sm text-gray-700 bg-gray-50"
            wrapper-class="max-w-full"
            v-model.trim="
              tenantsPaymentsRoutesStore.currentlyCreatingPaymentsRoute
                .description
            "
          />

          <div
            v-if="
              tenantsPaymentsRoutesStore.currentlyCreatingPaymentsRoute
                .account_type_id &&
              tenantsPaymentsRoutesStore.currentlyCreatingPaymentsRoute
                .schedule_type_id &&
              tenantsPaymentsRoutesStore.currentlyCreatingPaymentsRoute
                .gateway_id &&
              tenantsPaymentsRoutesStore.currentlyCreatingPaymentsRoute
                .payment_method_type
            "
            class="bg-emerald-500 p-5 rounded flex flex-col gap-5"
          >
            <h1 class="text-center text-xl">Payment Routing Explaination</h1>
            <span> All contact's in:</span>
            <ul class="list-disc list-inside space-y-1">
              <li>
                <span class="font-bold underline underline-offset-8 mx-2"
                  >Client</span
                >
                Stage
              </li>
              <li>
                <span class="font-bold underline underline-offset-8 mx-2"
                  >Active</span
                >Payment Plan Status
              </li>
            </ul>
            <span>Where the payment schedule item has a:</span>
            <ul class="list-disc list-inside space-y-1">
              <li>
                <span class="font-bold underline underline-offset-8 mx-2">{{
                  returnAccountTypeLabel
                }}</span
                >Account Type
              </li>
              <li>
                <span class="font-bold underline underline-offset-8 mx-2">
                  {{ returnScheduleTypeLabel }} </span
                >Schedule Type
              </li>
              <li>
                <span
                  class="font-bold underline underline-offset-8 mx-2 capitalize"
                  >{{
                    tenantsPaymentsRoutesStore.currentlyCreatingPaymentsRoute.payment_method_type.replaceAll(
                      "-",
                      " "
                    )
                  }}</span
                >Account on File
              </li>
            </ul>
            <span>
              Will be routed to the:
              <span class="font-bold underline underline-offset-8 mx-2">{{
                returnGatewayLabel
              }}</span>
              Gateway
            </span>
          </div>
        </div>
      </FormKit>
    </template>

    <template #footer>
      <!-- BUTTON FOOTER - CANCEL & UPDATE PaymentsRoute -->
      <div class="flex flex-row justify-between text-white w-full">
        <button
          @click="
            tenantsPaymentsRoutesStore.toggleTenantsPaymentsRoutesCreateModal
          "
          class="btn btn-xs md:btn-sm rounded bg-red-500 text-white hover:bg-red-700"
        >
          Cancel
        </button>
        <button
          @click="submitCreateForm"
          class="btn btn-xs md:btn-sm rounded bg-emerald-500 text-white hover:bg-emerald-700"
        >
          Create PaymentsRoute
        </button>
      </div>
    </template>
  </cSidebarModal>
</template>
