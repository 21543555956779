import {
  //axios
  axios,

  //pinia
  defineStore,
  useGlobalPromptsStore,
  useLoginStore,

  //env base url
  BASE_API_URL,
  useGlobalToastsStore,
} from "../clientImports";

export const useTenantsSessionsStore = defineStore({
  id: "tenants-sessions",
  state: () => ({
    showSessionsDashboard: false,

    tenantsSessions: [],
  }),
  actions: {
    toggleSessionDashboard() {
      this.showSessionsDashboard = !this.showSessionsDashboard;
    },

    async submitPrompt() {
      console.log("SUBMIT PROMPT");
    },

    async readTenantSessions() {
      const loginStore = useLoginStore();

      console.log("FETCHING SESSIONS");
      try {
        const { data } = await axios.get(
          `${BASE_API_URL}/read-tenants-sessions`
        );

        console.log("DATA", data);

        const sessionsParsed = data.sessions
          .filter(
            (sesh) => sesh.user_id !== loginStore.sessionData.foundUser._id
          )
          .map((sesh) => {
            return {
              ...sesh,
              // session: JSON.parse(sesh.session),
            };
          });

        this.tenantsSessions = sessionsParsed;
      } catch (error) {
        console.log(error);
      }
    },

    async destroyUserSession(user_id) {
      const promptStore = useGlobalPromptsStore();

      const id = user_id;

      promptStore.addPrompt({
        type: "User Logout",
        title: "Logout User?",
        message: `Are you sure you want to log this user out?`,
      });

      const promptResponse = await promptStore.waitForPromptConfirmation();

      if (promptResponse === true) {
        try {
          const { data } = await axios.delete(
            `${BASE_API_URL}/destroy-user-session/${id}`
          );

          if (data.status === "success") {
            const toastsStore = useGlobalToastsStore();
            //if successfull throw toast notification
            toastsStore.addToast({
              title: "User Logged Out",
              description: data.message,
              buttonExists: false,
              color: "green",
              type: 1,
            });
          } else {
            console.error(data.message);
            toastsStore.addToast({
              title: "Error Deleting Ticket",
              description: data.message,
              buttonExists: false,
              color: "red",
              type: 1,
            });
          }
        } catch (error) {
          console.error();
        }
        promptStore.removePrompt();
      } else {
        promptStore.removePrompt();
      }
    },
  },
});
