<script setup>
defineOptions({
  name: 'cSidebarModal', // Give the component a name
});
import {
  //vue
  ref,
  computed,
  watch,
  reactive,

  //formkit
  FKsubmitForm,

  //utils
  xLog,
} from "../clientImports";

const props = defineProps({
  sidebarPosition: {
    type: String,
    required: true,
    default: "right",
  },
  showSidebarInitial: {
    type: Boolean,
    default: false,
  },
  showSidebarModal: {
    type: Boolean,
    required: true,
  },
  toggleSidebarModal: {
    type: Function,
    required: true,
    default: () => {
      xLog("toggleSidebarModal", "toggleSidebarModal function not passed in");
    },
  },
  toggleButtonColor: {
    type: String,
    default: "blue",
  },
  defaultButton: {
    type: Boolean,
    default: true,
  },
  hasBackdrop: {
    type: Boolean,
    default: true,
  },
});

const toggleButtonColor = ref(props.toggleButtonColor);

const hasBackdrop = ref(props.hasBackdrop);

const showSidebarModal = ref();

watch(
  () => props.showSidebarModal,
  (newVal) => {
    showSidebarModal.value = newVal;
  }
);

const toggleSidebarModal = () => {
  props.toggleSidebarModal();
};

const closeByBackdrop = () => {
  if (showSidebarModal.value) {
    toggleSidebarModal();
  }
};

const escapeSidebarModal = () => {
  if (showSidebarModal.value) {
    toggleSidebarModal();
  }
};
</script>

<template>
  <button
    v-if="defaultButton"
    @keydown.esc="escapeSidebarModal"
    @click="toggleSidebarModal"
    :class="`bg-${toggleButtonColor}-500 hover:bg-${toggleButtonColor}-700`"
    class="text-white capitalize border-none rounded btn btn-xs md:btn-sm"
  >
    <slot name="toggle-button">
      <font-awesome-icon icon="fa-solid fa-plus" />
    </slot>
  </button>
  <Teleport to="#modals-target">
    <transition
      enter-active-class="transition-opacity duration-500 ease-in-out"
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="transition-opacity duration-500 ease-in-out"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div
        v-if="showSidebarModal"
        @click.self="closeByBackdrop"
        :class="hasBackdrop ? 'backdrop-blur-sm' : ''"
        class="fixed top-0 left-0 flex flex-col items-end w-full h-full cursor-pointer z-70"
      ></div>
    </transition>

    <!-- modal sidebar -->
    <transition
      enter-active-class="transition-all duration-300 ease-in-out"
      :enter-from-class="`${
        sidebarPosition === 'right' ? 'translate-x-full' : '-translate-x-full'
      }`"
      enter-to-class="translate-x-0"
      leave-active-class="transition-all duration-300 ease-in-out"
      leave-from-class="translate-x-0"
      :leave-to-class="`${
        sidebarPosition === 'right' ? 'translate-x-full' : '-translate-x-full'
      }`"
    >
      <div
        v-if="showSidebarModal"
        :class="
          sidebarPosition === 'right'
            ? 'right-0 border-l border-slate-400'
            : 'left-0 border-r border-slate-400'
        "
        class="fixed top-0 flex flex-col w-full h-full max-h-full divide-y z-70 bg-base-100 md:w-1/3 divide-slate-400"
      >
        <!-- title -->
        <div
          class="flex flex-row items-center justify-between p-2 md:p-5 text-white w-full"
        >
          <slot name="title">Modal Title</slot>

          <div class="flex-1 flex flex-row justify-end pl-2">
            <slot name="button-group">
              <!-- <button
                class="text-white capitalize bg-blue-500 rounded btn btn-sm"
              >
                Button
              </button> -->
            </slot>
            <button
              @click="toggleSidebarModal"
              class="text-white border-none rounded-full btn btn-sm bg-slate-500 hover:bg-red-500 ml-auto"
            >
              <font-awesome-icon icon="fa-solid fa-xmark" />
            </button>
          </div>
        </div>
        <div class="flex-1 p-2 md:p-5 overflow-y-auto text-white">
          <slot name="body">Modal Body - Overflows Vertically</slot>
        </div>
        <div class="p-2 md:p-5">
          <slot name="footer"></slot>
        </div>
      </div>
    </transition>
  </Teleport>
</template>
