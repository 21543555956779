<script setup>
defineOptions({
  name: 'SettingsPaymentsPresetsEdit', // Give the component a name
});
import {
  //axios
  axios,

  //vue
  createApp,
  ref,
  reactive,
  watch,
  watchEffect,
  computed,
  inject,
  provide,
  onMounted,
  onUnmounted,
  nextTick,
  Teleport,

  //pinia
  defineStore,
  createPinia,
  storeToRefs,

  //pinia stores
  useChatStore,
  useContactAlertsStore,
  useContactFileStore,
  useContactsStore,
  useGlobalPromptsStore,
  useGlobalToastsStore,
  useLoginStore,
  useTenantsStore,
  useUsersStore,
  useTenantsPaymentsPresetsStore,
  useTenantsScheduleTypesStore,

  //vue-router
  useRouter,
  useRoute,

  //formkit

  //formkit config file
  FKsubmitForm,

  //fontawesome
  library,
  FontAwesomeIcon,
  fas,
  far,
  fab,

  //centralized data
  usersLevelOptions,
  fieldsDefaultValidation,
  stateOptions,

  //utilities
  getRandomNumberBetween,

  //helpers
  registerGlobalComponents,

  //utilities
  stripEmptyFields,

  //base api url
  BASE_API_URL,
} from "./../clientImports";

import {
  browserTimezone,
  timezoneOptions,
} from "./../centralized_data/timezoneOptions";

const router = useRouter();
const route = useRoute();

const loginStore = useLoginStore();

const usersStore = useUsersStore();
const toastsStore = useGlobalToastsStore();
const promptsStore = useGlobalPromptsStore();
const tenantsScheduleTypesStore = useTenantsScheduleTypesStore();
const tenantsPaymentsPresetsStore = useTenantsPaymentsPresetsStore();

onMounted(async () => {
  await tenantsScheduleTypesStore.fetchTenantsScheduleTypes();
});

//computed that returns tenantsScheduleTypesStore.tenantsScheduleTypesList in a map with id as the value and name as the label
const scheduleTypesList = computed(() => {
  return tenantsScheduleTypesStore.tenantsScheduleTypesList.map((item) => {
    return {
      value: item.id,
      label: item.name,
    };
  });
});

//watchEffect that watches the plan_amount and number_of_payments and set the frequency_amount
watchEffect(() => {
  const planAmount =
    tenantsPaymentsPresetsStore.currentlyEditingPaymentsPreset.plan_amount;

  const numberOfPayments =
    tenantsPaymentsPresetsStore.currentlyEditingPaymentsPreset
      .number_of_payments;

  if (planAmount && numberOfPayments) {
    tenantsPaymentsPresetsStore.currentlyEditingPaymentsPreset.frequency_amount =
      parseFloat(
        (Number(planAmount) / (Number(numberOfPayments) || 1)).toFixed(2)
      );
  }
});

const formatAsCurrency = (value) => {
  return value.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });
};

//use FKsubmitForm to submit form
const submitUpdateForm = () => {
  FKsubmitForm("edit-payments-presets");
};
</script>

<template>
  <cSidebarModal
    sidebar-position="right"
    :show-sidebar-initial="false"
    :show-sidebar-modal="
      tenantsPaymentsPresetsStore.showTenantsPaymentsPresetsEditModal
    "
    :toggle-sidebar-modal="
      tenantsPaymentsPresetsStore.toggleTenantsPaymentsPresetsEditModal
    "
    :defaultButton="false"
  >
    <template #title>
      <h2>Edit PaymentsPreset</h2>
    </template>

    <template #body>
      <FormKit
        id="edit-payments-presets"
        type="form"
        :incomplete-message="false"
        submit-label="Update"
        form-class="text-white h-full flex flex-col justify-between"
        @submit="tenantsPaymentsPresetsStore.updateTenantsPaymentsPreset()"
        outer-class="m-0"
        :actions="false"
        :submit-attrs="{
          'data-theme': `light`,
          ignore: true,
        }"
      >
        <div class="grid grid-cols-1 gap-5">
          <div class="flex flex-row gap-5">
            <FormKit
              type="text"
              label="Name"
              placeholder=""
              :validation="'required:trim|length:5, 40'"
              help-class="text-white"
              help=""
              outer-class="m-0 flex-1"
              input-class="$reset rounded w-full input input-sm text-gray-700 bg-gray-50"
              wrapper-class="max-w-full"
              v-model.trim="
                tenantsPaymentsPresetsStore.currentlyEditingPaymentsPreset.name
              "
            />

            <FormKit
              outer-class="col-span-1"
              type="toggle"
              name="toggle"
              label="Active"
              alt-label-position
              v-model="
                tenantsPaymentsPresetsStore.currentlyEditingPaymentsPreset
                  .active
              "
            />
          </div>

          <!-- schedule frequency -->
          <FormKit
            type="select"
            label="Schedule Frequency"
            placeholder=""
            :validation="'required'"
            help-class="text-white"
            help="Select the frequency of the payments"
            outer-class="m-0 "
            input-class="$reset rounded w-full input input-sm text-gray-700 bg-gray-50"
            wrapper-class="max-w-full"
            v-model.trim="
              tenantsPaymentsPresetsStore.currentlyEditingPaymentsPreset
                .schedule_frequency
            "
            :options="[
              { value: 'daily', label: 'Daily' },
              { value: 'weekly', label: 'Weekly' },
              { value: 'bi-weekly', label: 'Bi-Weekly' },
              { value: 'monthly', label: 'Monthly' },
              { value: 'quarterly', label: 'Quarterly' },
              { value: 'semi-annually', label: 'Semi-Annually' },
              { value: 'annually', label: 'Annually' },
            ]"
          />
          <div class="grid grid-cols-2 gap-2">
            <FormKit
              type="text"
              label="Plan Total Amount"
              placeholder=""
              :validation="[['required'], ['numeric']]"
              help-class="text-white"
              help="The amount the customer will pay over the lifetime of the plan"
              outer-class="m-0 "
              input-class="$reset rounded w-full input input-sm text-gray-700 bg-gray-50"
              wrapper-class="max-w-full"
              v-model.trim="
                tenantsPaymentsPresetsStore.currentlyEditingPaymentsPreset
                  .plan_amount
              "
            />

            <FormKit
              type="number"
              label="Number of Payments"
              placeholder=""
              :validation="'required|numeric|min:1'"
              help-class="text-white"
              help="The total count of payments the customer will make for this plan"
              outer-class="m-0 "
              input-class="$reset rounded w-full input input-sm text-gray-700 bg-gray-50"
              wrapper-class="max-w-full"
              v-model.trim="
                tenantsPaymentsPresetsStore.currentlyEditingPaymentsPreset
                  .number_of_payments
              "
            />

            <div
              v-if="
                (
                  tenantsPaymentsPresetsStore.currentlyEditingPaymentsPreset
                    .plan_amount /
                  (tenantsPaymentsPresetsStore.currentlyEditingPaymentsPreset
                    .number_of_payments || 1)
                ).toFixed(2) > 0
              "
              class="col-span-2 text-center p-5 bg-green-400"
            >
              Will draft
              <span class="font-bold underline underline-offset-8 mx-2">
                {{
                  formatAsCurrency(
                    Number(
                      (
                        tenantsPaymentsPresetsStore
                          .currentlyEditingPaymentsPreset.plan_amount /
                        (tenantsPaymentsPresetsStore
                          .currentlyEditingPaymentsPreset.number_of_payments ||
                          1)
                      ).toFixed(2)
                    )
                  )
                }}
              </span>

              once
              <span
                class="uppercase font-bold underline underline-offset-8 ml-2"
              >
                {{
                  tenantsPaymentsPresetsStore.currentlyEditingPaymentsPreset
                    .schedule_frequency
                }} </span
              >,
              <span class="underline font-bold underline-offset-8 mx-2">
                {{
                  tenantsPaymentsPresetsStore.currentlyEditingPaymentsPreset
                    .number_of_payments || 1
                }}
              </span>

              time{{
                tenantsPaymentsPresetsStore.currentlyEditingPaymentsPreset
                  .number_of_payments > 1
                  ? "s"
                  : ""
              }}
            </div>
          </div>

          <!-- schedule type -->
          <FormKit
            type="select"
            label="Schedule Type"
            placeholder=""
            :validation="[['required']]"
            :validation-messages="{
              required:
                'The Schedule Type is required to classify the payment schedule for reporting purposes',
            }"
            help-class="text-white"
            help="Classify the schedule type with a custom schedule type"
            outer-class="m-0 "
            input-class="$reset rounded w-full input input-sm text-gray-700 bg-gray-50"
            wrapper-class="max-w-full"
            v-model.trim="
              tenantsPaymentsPresetsStore.currentlyEditingPaymentsPreset
                .schedule_type
            "
            :options="[
              {
                value: '',
                label: '- Choose Schedule Type',
                attrs: { disabled: true },
              },
              ...scheduleTypesList,
            ]"
          />

          <FormKit
            type="textarea"
            label="Description"
            placeholder=""
            :validation="'trim|length:1, 255'"
            help-class="text-white"
            help=""
            outer-class="m-0 "
            input-class="$reset rounded w-full input input-sm text-gray-700 bg-gray-50"
            wrapper-class="max-w-full"
            v-model.trim="
              tenantsPaymentsPresetsStore.currentlyEditingPaymentsPreset
                .description
            "
          />
        </div>
      </FormKit>
    </template>

    <template #footer>
      <!-- BUTTON FOOTER - CANCEL & UPDATE AFFILIATE GROUP -->
      <div class="flex flex-row justify-between text-white w-full">
        <button
          @click="
            tenantsPaymentsPresetsStore.toggleTenantsPaymentsPresetsEditModal
          "
          class="btn btn-xs md:btn-sm rounded bg-red-500 text-white hover:bg-red-700"
        >
          Cancel
        </button>
        <button
          @click="submitUpdateForm"
          class="btn btn-xs md:btn-sm rounded bg-emerald-500 text-white hover:bg-emerald-700"
        >
          Update
        </button>
      </div>
    </template>
  </cSidebarModal>
</template>
