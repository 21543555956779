<script setup>
defineOptions({
  name: 'SettingsWorkflowStatusesEdit', // Give the component a name
});
import {
  //axios
  axios,

  //vue
  createApp,
  ref,
  reactive,
  watch,
  computed,
  inject,
  provide,
  onMounted,
  onUnmounted,
  nextTick,
  Teleport,

  //pinia
  defineStore,
  createPinia,
  storeToRefs,

  //pinia stores
  useChatStore,
  useContactAlertsStore,
  useContactFileStore,
  useContactsStore,
  useGlobalPromptsStore,
  useGlobalToastsStore,
  useLoginStore,
  useTenantsStore,
  useUsersStore,
  useTenantsWorkflowStatusesStore,
  useTenantsBotsStore,
  useTenantsMilestonesStore,
  useTenantsDepartmentsStore,

  //vue-router
  useRouter,
  useRoute,

  //formkit

  //formkit config file
  FKsubmitForm,

  //fontawesome
  library,
  FontAwesomeIcon,
  fas,
  far,
  fab,

  //centralized data
  usersLevelOptions,
  fieldsDefaultValidation,
  stateOptions,

  //utilities
  getRandomNumberBetween,

  //helpers
  registerGlobalComponents,

  //utilities
  stripEmptyFields,

  //base api url
  BASE_API_URL,
} from "./../clientImports";

import {
  browserTimezone,
  timezoneOptions,
} from "./../centralized_data/timezoneOptions";

const router = useRouter();
const route = useRoute();

const loginStore = useLoginStore();

const usersStore = useUsersStore();
const toastsStore = useGlobalToastsStore();
const promptsStore = useGlobalPromptsStore();
const tenantsWorkflowStatusesStore = useTenantsWorkflowStatusesStore();
const tenantsBotsStore = useTenantsBotsStore();
const tenantsMilestonesStore = useTenantsMilestonesStore();

const tenantsDepartmentsStore = useTenantsDepartmentsStore();

const nextPossibleStatuses = ref([]);
const botCountdowns = ref([]);

onMounted(async () => {
  await tenantsMilestonesStore.getTenantsMilestones();
  await tenantsDepartmentsStore.fetchTenantsDepartments();
  await tenantsWorkflowStatusesStore.fetchTenantsWorkflowStatuses();
  await tenantsBotsStore.fetchTenantsBots();
});

const milestoneOptions = computed(() => {
  return tenantsMilestonesStore.tenantsMilestonesOptions;
});

//use FKsubmitForm to submit form
const submitUpdateForm = () => {
  FKsubmitForm("edit-workflow-statuses");
};

const addNextPossibleStatus = () => {
  nextPossibleStatuses.value.push("");
  // nextPossibleStatuses.value.push({});
};
const addRecurringBot = (index) => {
  botCountdowns.value.push({ bot_id: null, countdown: 0 });

  // nextPossibleStatuses.value.push({});
};

const departmentOptions = computed(() => {
  const map = tenantsDepartmentsStore.tenantsDepartmentsList.map((item) => {
    return {
      value: item.id,
      label: item.name,
    };
  });

  return map;
});

const workflowStatuses = computed(() => {
  const map = tenantsWorkflowStatusesStore.tenantsWorkflowStatusesList.map(
    (item) => {
      return {
        value: item.id,
        label: item.name,
      };
    }
  );
  return map;
});

const botOptions = computed(() => {
  const map = tenantsBotsStore.tenantsBotsList.map((bot) => {
    return {
      value: bot.id,
      label: bot.name + " - " + bot.description,
    };
  });

  return map;
});

watch(
  () => nextPossibleStatuses.value,
  (newValue) => {
    tenantsWorkflowStatusesStore.currentlyEditingWorkflowStatus.next_possible_statuses =
      newValue?.next_possible_statuses || [];
  }
);

watch(
  () => tenantsWorkflowStatusesStore.currentlyEditingWorkflowStatus,
  (newValue) => {
    nextPossibleStatuses.value = newValue.next_possible_statuses || [];

    botCountdowns.value = newValue?.bot_countdowns || [];
  }
);
</script>

<template>
  <cSidebarModal
    sidebar-position="right"
    :show-sidebar-initial="false"
    :show-sidebar-modal="
      tenantsWorkflowStatusesStore.showTenantsWorkflowStatusesEditModal
    "
    :toggle-sidebar-modal="
      tenantsWorkflowStatusesStore.toggleTenantsWorkflowStatusesEditModal
    "
    :defaultButton="false"
  >
    <template #title>
      <h2>Edit WorkflowStatus</h2>
    </template>

    <template #body>
      <FormKit
        id="edit-workflow-statuses"
        type="form"
        :incomplete-message="false"
        submit-label="Update"
        form-class="text-white h-full flex flex-col justify-between"
        @submit="
          tenantsWorkflowStatusesStore.updateTenantsWorkflowStatus(
            botCountdowns,
            nextPossibleStatuses
          )
        "
        outer-class="m-0"
        :actions="false"
        :submit-attrs="{
          'data-theme': `light`,
          ignore: true,
        }"
      >
        <div class="grid grid-cols-1 gap-5">
          <div class="flex flex-row gap-5">
            <FormKit
              type="text"
              label="Name"
              placeholder=""
              :validation="'required:trim|length:5, 40'"
              help-class="text-white"
              help=""
              outer-class="m-0 flex-1"
              input-class="$reset rounded w-full input input-sm text-gray-700 bg-gray-50"
              wrapper-class="max-w-full"
              v-model.trim="
                tenantsWorkflowStatusesStore.currentlyEditingWorkflowStatus.name
              "
              :value="
                tenantsWorkflowStatusesStore.currentlyEditingWorkflowStatus.name
              "
            />

            <FormKit
              outer-class="col-span-1"
              type="toggle"
              name="toggle"
              label="Active"
              alt-label-position
              v-model="
                tenantsWorkflowStatusesStore.currentlyEditingWorkflowStatus
                  .active
              "
              :value="
                tenantsWorkflowStatusesStore.currentlyEditingWorkflowStatus
                  .active
              "
            />
          </div>

          <FormKit
            type="textarea"
            label="Description"
            placeholder=""
            :validation="'trim|length:1, 255'"
            help-class="text-white"
            help=""
            outer-class="m-0 "
            input-class="$reset rounded w-full input input-sm text-gray-700 bg-gray-50"
            wrapper-class="max-w-full"
            v-model.trim="
              tenantsWorkflowStatusesStore.currentlyEditingWorkflowStatus
                .description
            "
            :value="
              tenantsWorkflowStatusesStore.currentlyEditingWorkflowStatus
                .description
            "
          />
          <FormKit
            type="select"
            label="Department"
            placeholder=""
            :validation="'required'"
            help-class="text-white"
            help="Which Department does this Status belong to?"
            outer-class="m-0"
            input-class="$reset rounded w-full input input-sm text-gray-700 bg-gray-50"
            wrapper-class="max-w-full"
            v-model="
              tenantsWorkflowStatusesStore.currentlyEditingWorkflowStatus
                .department_id
            "
            :value="
              tenantsWorkflowStatusesStore.currentlyEditingWorkflowStatus
                .department_id
            "
            :options="[
              {
                value: null,
                label: '- Choose a Department -',
                attrs: { disabled: true, selected: true },
              },
              ...departmentOptions,
            ]"
          />

          <!-- milestone select option -->
          <FormKit
            type="select"
            label="Milestone"
            placeholder=""
            :validation="'required'"
            help-class="text-white"
            help="Which Milestone does this Status belong to?"
            outer-class="m-0"
            input-class="$reset rounded w-full input input-sm text-gray-700 bg-gray-50"
            wrapper-class="max-w-full"
            v-model="
              tenantsWorkflowStatusesStore.currentlyEditingWorkflowStatus
                .milestone_id
            "
            :value="
              tenantsWorkflowStatusesStore.currentlyEditingWorkflowStatus
                .milestone_id
            "
            :options="[
              {
                value: null,
                label: '- Choose a Milestone -',
                attrs: { disabled: true, selected: true },
              },
              ...milestoneOptions,
            ]"
          />

          <!-- formkit select immediate bot -->
          <FormKit
            type="select"
            label="Payable"
            placeholder=""
            help-class="text-white"
            help="When a payment clears on a file in this status, is there a commissions payable?"
            outer-class="m-0"
            input-class="$reset rounded w-full input input-sm text-gray-700 bg-gray-50"
            wrapper-class="max-w-full"
            v-model="
              tenantsWorkflowStatusesStore.currentlyEditingWorkflowStatus
                .payable
            "
            :value="
              tenantsWorkflowStatusesStore.currentlyEditingWorkflowStatus
                .payable
            "
            :options="[
              {
                value: true,
                label:
                  'Yes - Assigned Sales Rep will be counted in commission report',
              },
              {
                value: false,
                label:
                  'No - No commission will be counted when a file is in this status',
              },
            ]"
          />

          <FormKit
            type="select"
            label="Immediately Run This Bot"
            placeholder=""
            help-class="text-white"
            help="Which Bot will be triggered when this Status is placed on a Contact?"
            outer-class="m-0"
            input-class="$reset rounded w-full input input-sm text-gray-700 bg-gray-50"
            wrapper-class="max-w-full"
            v-model="
              tenantsWorkflowStatusesStore.currentlyEditingWorkflowStatus
                .immediate_bot_id
            "
            :value="
              tenantsWorkflowStatusesStore.currentlyEditingWorkflowStatus
                .immediate_bot_id
            "
            :options="[
              {
                value: null,
                label: 'None - No Bot will be triggered',
              },
              ...botOptions,
            ]"
          />

          <FormKit
            label="Next Exact Status"
            type="select"
            placeholder=""
            help-class="text-white"
            help="What is the next exact workflow status after this one?"
            outer-class="m-0 flex-1"
            input-class="$reset rounded w-full input input-sm text-gray-700 bg-gray-50"
            wrapper-class="max-w-full"
            v-model="
              tenantsWorkflowStatusesStore.currentlyEditingWorkflowStatus
                .next_exact_status_id
            "
            :value="
              tenantsWorkflowStatusesStore.currentlyEditingWorkflowStatus
                .next_exact_status_id
            "
            :options="[
              {
                value: null,
                label: '- Choose Next Possible Status -',
              },
              {
                value: null,
                label: 'None',
              },
              ...workflowStatuses,
            ]"
          />

          <div
            v-if="workflowStatuses.length !== 0"
            class="border border-slate-400 p-2 flex flex-col gap-4 rounded"
          >
            <div class="flex flex-row items-center">
              <h1>Next Possible Statuses</h1>
              <button
                type="button"
                @click="addNextPossibleStatus"
                class="btn btn-sm ml-auto border border-slate-400 hover:bg-gray-100 hover:text-gray-600"
              >
                <font-awesome-icon icon="fa-solid fa-plus" />
              </button>
            </div>

            <div
              v-for="(status, i) in nextPossibleStatuses"
              :key="i"
              class="flex flex-row items-end gap-4"
            >
              <FormKit
                type="select"
                placeholder=""
                help-class="text-white"
                outer-class="m-0 flex-1"
                input-class="$reset rounded w-full input input-sm text-gray-700 bg-gray-50"
                wrapper-class="max-w-full"
                v-model="nextPossibleStatuses[i]"
                :options="[
                  {
                    value: null,
                    label: '- Choose Next Possible Status -',
                  },
                  ...workflowStatuses,
                ]"
                :value="nextPossibleStatuses[i]"
              />

              <button
                type="button"
                @click="nextPossibleStatuses.splice(i, 1)"
                class="btn btn-sm border border-red-400 text-red-500 hover:bg-red-500 hover:text-white"
              >
                <font-awesome-icon icon="fa-solid fa-trash" />
              </button>
            </div>
          </div>

          <div class="border border-slate-400 p-2 flex flex-col gap-4 rounded">
            <div class="flex flex-row items-center">
              <h1>Bot Countdowns</h1>
              <button
                type="button"
                @click="addRecurringBot(i)"
                class="btn btn-sm ml-auto border border-slate-400 hover:bg-gray-100 hover:text-gray-600"
              >
                <font-awesome-icon icon="fa-solid fa-plus" />
              </button>
            </div>

            <div
              v-for="(status, i) in botCountdowns"
              :key="i"
              class="flex flex-row items-end gap-4"
            >
              <FormKit
                type="select"
                label="Run This Bot"
                placeholder=""
                :validation="'required'"
                help-class="text-white"
                help=""
                outer-class="m-0 flex-1"
                input-class="$reset rounded w-full select select-sm text-gray-700 bg-gray-50"
                wrapper-class="max-w-full"
                v-model="botCountdowns[i].bot_id"
                :options="[
                  {
                    value: null,
                    label: '- Choose a Bot -',
                    attrs: { disabled: true, selected: true },
                  },
                  ...botOptions,
                ]"
              />

              <!-- number only field -->
              <FormKit
                type="number"
                :label="`In ${botCountdowns[i].countdown} Days`"
                placeholder=""
                :validation="`required|numeric|min:${i > 0 ? Number(botCountdowns[i - 1].countdown) + 1 : 1}`"
                :validation-messages="{
                  min: `Must be a minimun of ${i > 0 ? Number(botCountdowns[i - 1].countdown) + 1 : 1}`,
                }"
                help-class="text-white"
                help=""
                outer-class="m-0 flex-1"
                input-class="$reset rounded w-full input input-sm text-gray-700 bg-gray-50"
                wrapper-class="max-w-full"
                v-model.trim="botCountdowns[i].countdown"
              />

              <button
                type="button"
                @click="botCountdowns.splice(i, 1)"
                class="btn btn-sm border border-red-400 text-red-500 hover:bg-red-500 hover:text-white"
              >
                <font-awesome-icon icon="fa-solid fa-trash" />
              </button>
            </div>
          </div>
        </div>
      </FormKit>
    </template>

    <template #footer>
      <!-- BUTTON FOOTER - CANCEL & UPDATE AFFILIATE GROUP -->
      <div class="flex flex-row justify-between text-white w-full">
        <button
          @click="
            tenantsWorkflowStatusesStore.toggleTenantsWorkflowStatusesEditModal
          "
          class="btn btn-xs md:btn-sm rounded bg-red-500 text-white hover:bg-red-700"
        >
          Cancel
        </button>
        <button
          @click="submitUpdateForm"
          class="btn btn-xs md:btn-sm rounded bg-emerald-500 text-white hover:bg-emerald-700"
        >
          Update
        </button>
      </div>
    </template>
  </cSidebarModal>
</template>
