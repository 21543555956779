<script setup>
defineOptions({
  name: 'ContactBots', // Give the component a name
});
import {
  //axios
  axios,

  //vue
  createApp,
  ref,
  reactive,
  watch,
  computed,
  inject,
  provide,
  onMounted,
  onUnmounted,
  nextTick,
  Teleport,

  //pinia
  defineStore,
  createPinia,
  storeToRefs,

  //pinia stores
  useChatStore,
  useContactAlertsStore,
  useContactFileStore,
  useContactsStore,
  useGlobalPromptsStore,
  useGlobalToastsStore,
  useLoginStore,
  useTenantsStore,

  //vue-router
  useRouter,
  useRoute,

  //formkit

  //formkit config file

  //fontawesome
  library,
  FontAwesomeIcon,
  fas,
  far,
  fab,

  //global components - all vue components
  registerGlobalComponents,

  //base api url
  BASE_API_URL,
} from "./../clientImports";

const toastsStore = useGlobalToastsStore();

const contactBots = ref([
  { label: "Bot 1", value: { id: 1, name: "Bot 1", routine: [] } },
  { label: "Bot 2", value: { id: 2, name: "Bot 2", routine: [] } },
]);

let botToRun = ref();

const runBot = async (id, option) => {
  const shouldRunBot = confirm(
    `Are you sure you want to run this bot --> ${botToRun.label}?
    This routine will be run: ${botToRun.routine}`
  );

  if (shouldRunBot) {
    //run bot, based on response send toast

    toastsStore.addToast({
      title: "Bot Ran",
      description: `Routine Executed by: (${botToRun})`,
      buttonExists: false,
      color: "sky",
      type: 1, // 1 = small, 2 = md, 3 = lg, 4 = lg dismissable
    });
  }
};

//TODO - heldup by main functionality - need to get all basic contact storing, filtering, updating, etc then I can build the bots on top of that
</script>

<template>
  <div class="flex flex-col items-center justify-center">
    <div class="flex flex-row items-center relative z-10">
      <div class="form-control">
        <div class="dropdown dropdown-end">
          <button
            tabindex="0"
            class="btn btn-xs md:btn-sm text-white capitalize bg-indigo-500 border-y-0 border-r-0 rounded-none border-none px-2"
          >
            <font-awesome-icon icon="fa-solid fa-robot" />
          </button>
          <!-- dropdown menu -->
          <div
            tabindex="0"
            class="dropdown-content menu p-0 shadow bg-base-100 rounded-none w-52 mt-1"
          >
            <FormKit
              type="form"
              :incomplete-message="false"
              #default="{ value }"
              :actions="false"
            >
              <FormKit
                v-model="botToRun"
                type="autocomplete"
                name="bot-options"
                :options="contactBots"
                placeholder="Search Bot"
                outerClass="m-0 rounded-none border-none"
                inputClass="input input-bordered w-full text-white bg-gray-900 rounded-l-none"
                listBoxButtonClass="rounded-none"
                selectIconClass="text-white"
                :filter="
                  (option, search) =>
                    option.label.toLowerCase().startsWith(search.toLowerCase())
                "
              >
                <!--SELECTION SLOT-->
                <template #selection="{ selections, handlers }">
                  <div
                    v-for="option in selections"
                    :key="option.value"
                    class="formkit-selection"
                  >
                    <div class="formkit-option">
                      <img :src="option.logo" :alt="option.label + ' logo'" />
                      <span>
                        {{ option.label }}
                      </span>
                    </div>
                    <button
                      @click.prevent="handlers.removeSelection(option)()"
                      tabindex="-1"
                      type="button"
                      aria-controls="input_1"
                      class="formkit-remove-selection"
                    >
                      <span class="formkit-close-icon formkit-icon"
                        ><svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 12 16"
                        >
                          <path
                            d="M10,12.5c-.13,0-.26-.05-.35-.15L1.65,4.35c-.2-.2-.2-.51,0-.71,.2-.2,.51-.2,.71,0L10.35,11.65c.2,.2,.2,.51,0,.71-.1,.1-.23,.15-.35,.15Z"
                            fill="currentColor"
                          ></path>
                          <path
                            d="M2,12.5c-.13,0-.26-.05-.35-.15-.2-.2-.2-.51,0-.71L9.65,3.65c.2-.2,.51-.2,.71,0,.2,.2,.2,.51,0,.71L2.35,12.35c-.1,.1-.23,.15-.35,.15Z"
                            fill="currentColor"
                          ></path>
                        </svg>
                      </span>
                    </button>
                  </div>
                </template>
                <!--/SELECTION SLOT-->
              </FormKit>
              <pre wrap class="bg-white">{{ value }} {{ botToRun }}</pre>
            </FormKit>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
