// Utility function to remove non-numeric characters from a string
const removeNonNumeric = (str) => str.replace(/\D+/g, "");

export const identifyQueryTypes = (query) => {
  const queryTypes = [];

  // Identify emails
  if (/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(query)) {
    const emailQueries = [
      "email_primary",
      "email_secondary",
      "email_work",
      "business_email",
      "business_email_secondary",
      "spouse_email_primary",
      "spouse_email_secondary",
      "spouse_email_work",
    ].map((field) => ({ [field]: query }));
    queryTypes.push(...emailQueries);
  }

  // Identify ObjectIds
  if (/^[a-f\d]{24}$/i.test(query)) {
    queryTypes.push({ _id: query });
    return queryTypes;
  }

  // Identify phone numbers
  const numericPhone = removeNonNumeric(query);
  if (/^1?\d{10}$/.test(numericPhone)) {
    const phoneQueries = [
      "phone_primary",
      "phone_secondary",
      "phone_mobile",
      "phone_work",
      "business_phone_primary",
      "business_phone_secondary",
      "spouse_phone_primary",
      "spouse_phone_secondary",
      "spouse_phone_mobile",
      "spouse_phone_work",
    ].map((field) => ({ [field]: numericPhone.slice(-10) }));
    queryTypes.push(...phoneQueries);
  }

  // Names and company
  if (/^[A-Za-z\s]+$/.test(query)) {
    const [firstName, ...rest] = query.split(" ");
    const lastName = rest.join(" ");

    const nameQueries = [];

    // Include first_name and last_name together only if there's a space
    if (rest.length > 0) {
      nameQueries.push({ first_name: firstName, last_name: lastName });
    }

    nameQueries.push(
      { first_name: firstName },
      { last_name: firstName },
      { business_name: query }
    );

    queryTypes.push(...nameQueries);
  }

  console.log("QUERY TYPES: ", queryTypes);

  return queryTypes;
};
