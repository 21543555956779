<script setup>
defineOptions({
  name: 'ContactCommunicationSMS', // Give the component a name
});
import {
  //axios
  axios,

  //vue
  createApp,
  ref,
  reactive,
  watch,
  computed,
  inject,
  provide,
  onMounted,
  onUnmounted,
  nextTick,
  Teleport,

  //pinia
  defineStore,
  createPinia,
  storeToRefs,

  //pinia stores
  useChatStore,
  useContactAlertsStore,
  useContactFileStore,
  useContactsStore,
  useGlobalPromptsStore,
  useGlobalToastsStore,
  useLoginStore,
  useTenantsStore,

  //vue-router
  useRouter,
  useRoute,

  //formkit

  //formkit config file

  //fontawesome
  library,
  FontAwesomeIcon,
  fas,
  far,
  fab,

  //global components - all vue components
  registerGlobalComponents,

  //base api url
  BASE_API_URL,
} from "./../clientImports";

const router = useRouter();
const route = useRoute();

const smsFilterOption = ref("conversation");
</script>

<template>
  <div class="flex flex-col h-full">
    <div class="w-full h-full bg-gray-100 flex flex-row justify-between">
      <div class="flex flex-col h-full">
        <!-- <div class="p-3 bg-slate-600 text-white">Inboxes</div> -->
        <button
          :class="[
            smsFilterOption === 'conversation'
              ? 'bg-blue-400 text-white'
              : 'bg-white text-gray-700',
          ]"
          @click="smsFilterOption = 'conversation'"
          class="bold bg-white capitalize p-1 text-xs md:text-sm md:p-3 rounded-none hover:bg-emerald-400 hover:cursor-pointer hover:text-white border-b border-x-0 border-t-0 border-slate-300"
        >
          Conversation
        </button>
        <button
          :class="[
            smsFilterOption === 'in'
              ? 'bg-blue-400 text-white'
              : 'bg-white text-gray-700',
          ]"
          @click="smsFilterOption = 'in'"
          class="bold bg-white capitalize p-1 text-xs md:text-sm md:p-3 rounded-none hover:bg-emerald-400 hover:cursor-pointer hover:text-white border-b border-x-0 border-t-0 border-slate-300"
        >
          In
        </button>
        <button
          :class="[
            smsFilterOption === 'out'
              ? 'bg-blue-400 text-white'
              : 'bg-white text-gray-700',
          ]"
          @click="smsFilterOption = 'out'"
          class="bold bg-white capitalize p-1 text-xs md:text-sm md:p-3 rounded-none hover:bg-emerald-400 hover:cursor-pointer hover:text-white border-b border-x-0 border-t-0 border-slate-300"
        >
          Out
        </button>
      </div>
      <!-- DATA FORMS -->
      <div
        class="overflow-y-auto h-full flex flex-col flex-1 border-x border-slate-400 bg-white"
      >
        <div
          class="h-full no-animation bg-white rounded-none text-gray-700 border-b-0 border-x-0 border-t-0 border-slate-300"
        >
          <!-- empty div to hold the form no-style -->
          <div class="w-full flex flex-col flex-1 justify-end h-full">
            <div class="chat chat-start">
              <div class="chat-bubble chat-bubble-primary">
                What kind of nonsense is this
              </div>
            </div>
            <div class="chat chat-start">
              <div class="chat-bubble chat-bubble-secondary">
                Put me on the Council and not make me a Master!??
              </div>
            </div>
            <div class="chat chat-start">
              <div class="chat-bubble chat-bubble-accent">
                That's never been done in the history of the Jedi. It's
                insulting!
              </div>
            </div>
            <div class="chat chat-end">
              <div class="chat-bubble chat-bubble-info">Calm down, Anakin.</div>
            </div>
            <div class="chat chat-end">
              <div class="chat-bubble chat-bubble-success">
                You have been given a great honor.
              </div>
            </div>
            <div class="chat chat-end">
              <div class="chat-bubble chat-bubble-warning">
                To be on the Council at your age.
              </div>
            </div>
            <div class="chat chat-end">
              <div class="chat-bubble chat-bubble-error">
                It's never happened before.
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- GROUP LINKS -->
      <div class="flex flex-col w-52">
        <div class="p-1 text-xs md:text-sm md:p-3 bg-slate-400 text-white">
          Links
        </div>
      </div>
    </div>
  </div>
</template>
