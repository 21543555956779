<script setup>
defineOptions({
  name: 'SettingsRolesEdit', // Give the component a name
});
import {
  ref,
  reactive,
  watch,
  computed,
  useRolesStore,
  useLoginStore,
  useGlobalToastsStore,
  useRoute,
  useRouter,
  FKsubmitForm,
  onMounted,
} from "../clientImports";

const rolesStore = useRolesStore();
const loginStore = useLoginStore();
const toastsStore = useGlobalToastsStore();

const route = useRoute();
const router = useRouter();

let fetchedPermissions = ref([]);

const fetchPermissions = async () => {
  await rolesStore.getAllPermissions();
};

let permissionsOptions = reactive();

watch(
  () => rolesStore.currentEditingRole,
  async (newVal) => {
    if (newVal) {
      await fetchPermissions();
      permissionsOptions = rolesStore.allPermissions;
      name.value = newVal.name;
      display_name.value = newVal.display_name;
      description.value = newVal.description;
    }
  }
);

const setReactive = (obj, key, value) => {
  obj[key] = value;
};

// Global Select All toggle
const selectAll = ref(false);
const toggleAll = () => {
  selectAll.value = !selectAll.value;
  for (let group of permissionsOptions) {
    setReactive(group, "selected", selectAll.value);
    for (let category in group.permissions) {
      setReactive(group.permissions[category], "selected", selectAll.value);
      for (let permission of group.permissions[category]) {
        setReactive(permission, "selected", selectAll.value);
      }
    }
  }
};

// Group-level toggle
const toggleGroup = (group) => {
  for (let category in group.permissions) {
    setReactive(group.permissions[category], "selected", group.selected);
    for (let permission of group.permissions[category]) {
      setReactive(permission, "selected", group.selected);
    }
  }
};

// Category-level toggle
const toggleCategory = (perms) => {
  for (let permission of perms) {
    setReactive(permission, "selected", perms.selected);
  }
};

const selectedPermissions = computed(() => {
  const allPermissions = [];

  permissionsOptions?.forEach((group) => {
    Object.values(group.permissions).forEach((permissionType) => {
      permissionType.forEach((permission) => {
        if (permission.selected) {
          allPermissions.push(permission._id);
        }
      });
    });
  });

  return allPermissions;
});

const name = ref("");
const display_name = ref("");
const description = ref("");

let roleData = computed(() => {
  return {
    name: name.value,
    display_name: display_name.value,
    description: description.value,
    permissions: selectedPermissions.value,
  };
});

// Reactive Search Term
const searchTerm = ref("");

// Computed Property for Filtered Groups
const filteredGroups = computed(() => {
  if (!searchTerm.value.trim()) return permissionsOptions; // If search term is empty, return all groups

  return permissionsOptions.filter((group) =>
    group.group.toLowerCase().includes(searchTerm.value.toLowerCase())
  );
});

const submitFormKitForm = () => {
  FKsubmitForm("edit-role-form");
};
const toggleSteps = () => {
  if (roleStep.value === 1) {
    roleStep.value = 2;
  } else if (roleStep.value === 2) {
    roleStep.value = 1;
  }
};
const roleStep = ref(1);

const createRole = async () => {
  const response = await rolesStore.createRole(roleData.value);

  console.log("response", response);

  if (response.data.status === "success") {
    toastsStore.addToast({
      title: "Role Created",
      description: "",
      buttonExists: false,
      color: "emerald",
      type: 1, // 1 = small, 2 = md, 3 = lg, 4 = lg dismissable
    });

    rolesStore.toggleCreateRoleModal();
    await rolesStore.getAllRoles();
  } else {
    toastsStore.addToast({
      title: "Error Creating Role",
      description: "",
      buttonExists: false,
      color: "red",
      type: 1, // 1 = small, 2 = md, 3 = lg, 4 = lg dismissable
    });
  }
};
</script>

<template>
  <cSidebarModal
    sidebarPosition="right"
    :showSidebarModal="rolesStore.showEditRoleModal"
    :toggleSidebarModal="
      rolesStore.showEditRoleModal
        ? rolesStore.closeEditRoleModal
        : rolesStore.openEditRoleModal
    "
    :defaultButton="false"
    toggleButtonColor="blue"
  >
    <template #toggle-button>
      <span class="mr-2">Edit Role</span>
      <font-awesome-icon icon="fa-solid fa-plus" />
    </template>
    <template #title>Create Role {{ rolesStore.idToEdit }}</template>

    <template #button-group>
      <!-- Global Select All -->
      <div class="flex flex-row justify-end gap-2 flex-1">
        <!-- Search Input -->
        <div v-if="roleStep === 2" class="flex-1 flex flex-row justify-center">
          <input
            class="input input-sm bg-white rounded text-gray-600 w-2/3 border-none"
            v-model="searchTerm"
            placeholder="Search Permission Group"
          />
        </div>

        <button
          v-if="roleStep === 2"
          :class="
            roleStep === 2 && searchTerm.length === 0
              ? 'bg-blue-500 hover:bg-blue-700'
              : 'bg-gray-500 hover:bg-gray-700 disabled:opacity-50 disabled cursor-not-allowed'
          "
          class="btn bg-blue-500 text-white btn-sm rounded capitalize border-none"
          @click="
            roleStep === 2 && searchTerm.length === 0 ? toggleAll() : null
          "
        >
          {{ selectAll ? "Deselect" : "Select" }} All
        </button>
      </div>
    </template>

    <template #body>
      <FormKit
        id="edit-role-form"
        type="form"
        :incomplete-message="false"
        form-class="text-white h-full flex flex-col gap-2"
        @submit="createRole"
        :actions="false"
        :submit-attrs="{
          'data-theme': `light`,
          ignore: true,
        }"
      >
        <div v-show="roleStep === 1">
          <div class="grid grid-cols-2 gap-5">
            <FormKit
              type="text"
              label="Name"
              placeholder=""
              :validation="'required:trim|length:1,50'"
              help-class="text-white"
              help=""
              outer-class="m-0"
              input-class="$reset rounded w-full input input-sm text-gray-700 bg-gray-50"
              wrapper-class="max-w-full"
              v-model.trim="name"
            />
            <FormKit
              type="text"
              label="Display Name"
              placeholder=""
              :validation="'required:trim|length:1,50'"
              help-class="text-white"
              help="This is what users will see"
              outer-class="m-0"
              input-class="$reset rounded w-full input input-sm text-gray-700 bg-gray-50"
              wrapper-class="max-w-full"
              v-model.trim="display_name"
            />
          </div>
          <FormKit
            type="textarea"
            label="Description"
            placeholder=""
            :validation="'trim|length:0,50'"
            help-class="text-white"
            help=""
            outer-class="m-0"
            input-class="$reset rounded w-full input input-sm text-gray-700 bg-gray-50 py-2"
            wrapper-class="max-w-full"
            v-model.trim="description"
          />
        </div>

        <div v-show="roleStep === 2" class="flex flex-col gap-2">
          <div
            v-for="group in filteredGroups"
            :key="group.group"
            class="bg-gray-500 text-white rounded border border-slate-400"
          >
            <div
              class="flex flex-row justify-between items-center hover:bg-gray-700 hover:text-white border-b border-slate-400"
            >
              <div
                @click="group.show = !group.show"
                class="pr-2 flex flex-row justify-between items-center flex-1 gap-3 cursor-pointer"
              >
                <label class="ml-2 cursor-pointer">{{ group.group }}</label>

                <p class="cursor-pointer">{{ group.description }}</p>
              </div>

              <div class="flex flex-row items-center p-1">
                <tippy
                  :content="`Select All ${group.group} Roles`"
                  class="flex flex-row items-center"
                >
                  <input
                    type="checkbox"
                    v-model="group.selected"
                    @change="toggleGroup(group)"
                    class="checkbox checkbox-primary text-white border-none bg-white"
                  />
                </tippy>
              </div>
            </div>

            <transition
              enter-active-class="transition-all duration-300 ease-in-out"
              enter-from-class="transform translate-y-2.5 opacity-0"
              enter-to-class="transform translate-y-0 opacity-100"
              leave-active-class="transition-all duration-300 ease-in-out"
              leave-from-class="transform translate-y-0 opacity-100"
              leave-to-class="transform translate-y-2.5 opacity-0"
            >
              <div v-if="group.show" class="p-1 flex flex-col gap-2 bg-gray-50">
                <div
                  v-for="(perms, category) in group.permissions"
                  :key="category"
                  class="border border-slate-400 rounded text-white"
                >
                  <div
                    v-if="perms.length > 0"
                    class="flex flex-row items-center justify-between hover:bg-gray-700 bg-gray-500"
                  >
                    <div
                      @click="perms.show = !perms.show"
                      class="p-1 flex-1 flex flex-row items-center justify-between gap-3 cursor-pointer pl-2 border-b border-slate-400"
                    >
                      <label class="capitalize">{{ category }}</label>
                    </div>
                    <tippy
                      :content="`Select All ${
                        category[0].toUpperCase() + category.slice(1)
                      } ${group.group} Roles`"
                      class="flex flex-row items-center p-1 border-b border-slate-400 capitalize"
                    >
                      <input
                        type="checkbox"
                        v-model="perms.selected"
                        @change="toggleCategory(perms)"
                        class="checkbox checkbox-primary text-white border-none bg-white"
                      />
                    </tippy>
                  </div>

                  <transition
                    enter-active-class="transition-all duration-300 ease-in-out"
                    enter-from-class="transform translate-y-1 opacity-0"
                    enter-to-class="transform translate-y-0 opacity-100"
                    leave-active-class="transition-all duration-300 ease-in-out"
                    leave-from-class="transform translate-y-0 opacity-100"
                    leave-to-class="transform translate-y-1 opacity-0"
                  >
                    <div v-if="perms.show" class="flex flex-col gap-2">
                      <div
                        v-for="permission in perms"
                        :key="permission.permission"
                        class="flex flex-row items-center gap-3 bg-gray-500 text-white"
                      >
                        <div class="flex flex-col w-full">
                          <div
                            class="p-2 flex-1 flex flex-row items-center gap-3 bg-blue-500"
                          >
                            <tippy
                              :content="`Add To This Role`"
                              class="flex flex-row items-center"
                            >
                              <input
                                :id="permission.permission"
                                type="checkbox"
                                v-model="permission.selected"
                                class="checkbox checkbox-primary border-none bg-white"
                              />
                            </tippy>
                            <label
                              :for="permission.permission"
                              class="cursor-pointer flex-1"
                              >{{ permission.label }}</label
                            >
                          </div>
                          <div
                            class="flex-1 p-1 bg-yellow-200 border-t border-slate-400"
                          >
                            <p class="flex-1 text-xs italic text-gray-600">
                              {{ permission.description }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </transition>
                </div>
              </div>
            </transition>
          </div>
        </div>
      </FormKit>
      <pre>

        {{ filteredGroups }}
        {{ rolesStore.currentEditingRole }}
      </pre>
    </template>

    <template #footer>
      <transition-group
        name="btn-group"
        tag="div"
        class="flex flex-row justify-between items-center"
        enter-active-class="transition-transform duration-300 ease-out"
        leave-active-class="transition-transform duration-300 ease-in"
        move-class="transition-transform duration-300 ease-out"
      >
        <button
          key="cancel"
          @click="rolesStore.toggleCreateRoleModal"
          class="btn btn-sm bg-red-500 rounded border-none text-white capitalize"
        >
          Cancel
        </button>

        <button
          key="toggleSteps"
          @click="name && display_name ? toggleSteps() : null"
          :class="
            name && display_name
              ? 'bg-blue-500 hover:bg-blue-700'
              : 'bg-gray-500 hover:bg-gray-700 disabled:opacity-50 disabled cursor-not-allowed'
          "
          class="btn btn-sm text-white rounded capitalize border-none transition-transform duration-300 ease-out"
        >
          {{ roleStep === 1 ? "Next - Add Permissions" : "Back - Role Name" }}
        </button>

        <button
          key="submit"
          v-if="selectedPermissions.length > 0"
          @click="selectedPermissions.length > 0 ? submitFormKitForm() : null"
          :class="
            selectedPermissions.length > 0
              ? 'bg-emerald-500 hover:bg-emerald-700'
              : 'bg-gray-500 hover:bg-gray-700 disabled:opacity-50 disabled cursor-not-allowed'
          "
          class="btn btn-sm rounded border-none text-white capitalize transition-transform duration-100 ease-out"
        >
          Create Role
        </button>
      </transition-group>
    </template>
  </cSidebarModal>
</template>
