import {
  //axios
  axios,

  //pinia
  defineStore,

  //env base url
  BASE_API_URL,
} from "../clientImports";

export const useContactTypesStore = defineStore({
  id: "contact-types",
  state: () => ({
    contactTypesOptions: [],
  }),
  actions: {
    //fetch the options in the list and populate the dropdown
    async fetchContactTypeOptions() {
      try {
        const response = await axios.get(
          `${BASE_API_URL}/fetch-tenants-contact-types`
        );

        this.contactTypesOptions = response.data.contactTypeOptions;
      } catch (error) {
        console.log(error.message);
      }
    },
  },
});
