<script setup>
defineOptions({
  name: 'SettingsAffiliateGroupsCreate', // Give the component a name
});
import {
  //axios
  axios,

  //vue
  createApp,
  ref,
  reactive,
  watch,
  computed,
  inject,
  provide,
  onMounted,
  onUnmounted,
  nextTick,
  Teleport,

  //pinia
  defineStore,
  createPinia,
  storeToRefs,

  //pinia stores
  useChatStore,
  useContactAlertsStore,
  useContactFileStore,
  useContactsStore,
  useGlobalPromptsStore,
  useGlobalToastsStore,
  useLoginStore,
  useTenantsStore,
  useUsersStore,
  useTenantsAffiliateGroupsStore,

  //vue-router
  useRouter,
  useRoute,

  //formkit

  //formkit config file
  FKsubmitForm,

  //fontawesome
  library,
  FontAwesomeIcon,
  fas,
  far,
  fab,

  //centralized data
  usersLevelOptions,
  departmentOptions,
  fieldsDefaultValidation,
  stateOptions,

  //utilities
  getRandomNumberBetween,

  //helpers
  registerGlobalComponents,

  //utilities
  stripEmptyFields,

  //base api url
  BASE_API_URL,
} from "./../clientImports";

import {
  browserTimezone,
  timezoneOptions,
} from "./../centralized_data/timezoneOptions";

const router = useRouter();
const route = useRoute();

const loginStore = useLoginStore();

const usersStore = useUsersStore();
const toastsStore = useGlobalToastsStore();
const promptsStore = useGlobalPromptsStore();
const tenantsAffiliateGroupsStore = useTenantsAffiliateGroupsStore();

//use FKsubmitForm to submit form
const submitCreateForm = () => {
  return FKsubmitForm("create-affiliate-group");
};
</script>

<template>
  <cSidebarModal
    sidebar-position="right"
    :show-sidebar-initial="false"
    :show-sidebar-modal="
      tenantsAffiliateGroupsStore.showTenantsAffiliateGroupsCreateModal
    "
    :toggle-sidebar-modal="
      tenantsAffiliateGroupsStore.toggleTenantsAffiliateGroupsCreateModal
    "
    :default-button="false"
  >
    <template #title>
      <h2>Create Affiliate Group</h2>
    </template>

    <template #body>
      <FormKit
        id="create-affiliate-group"
        type="form"
        :incomplete-message="false"
        submit-label="Create"
        form-class="text-white h-full flex flex-col justify-between"
        @submit="tenantsAffiliateGroupsStore.createTenantsAffiliateGroup()"
        outer-class="m-0"
        :actions="false"
        :submit-attrs="{
          'data-theme': `light`,
          ignore: true,
        }"
      >
        <div class="grid grid-cols-2 gap-5">
          <FormKit
            type="text"
            label="Name"
            placeholder=""
            :validation="'required:trim|length:5, 40'"
            help-class="text-white"
            help=""
            outer-class="m-0 "
            input-class="$reset rounded w-full input input-sm text-gray-700 bg-gray-50"
            wrapper-class="max-w-full"
            v-model.trim="
              tenantsAffiliateGroupsStore.currentlyCreatingAffiliateGroup.name
            "
            :value="
              tenantsAffiliateGroupsStore.currentlyCreatingAffiliateGroup.name
            "
          />

          <FormKit
            type="text"
            label="Abbreviation"
            placeholder=""
            :validation="'required:trim|length:2,10'"
            help-class="text-white"
            help=""
            outer-class="m-0 "
            input-class="$reset rounded w-full input input-sm text-gray-700 bg-gray-50"
            wrapper-class="max-w-full"
            v-model.trim="
              tenantsAffiliateGroupsStore.currentlyCreatingAffiliateGroup
                .abbreviation
            "
            :value="
              tenantsAffiliateGroupsStore.currentlyCreatingAffiliateGroup
                .abbreviation
            "
          />
          <FormKit
            type="text"
            label="Entity Name"
            placeholder=""
            :validation="'required:trim|length:4, 100'"
            help-class="text-white"
            help=""
            outer-class="m-0 "
            input-class="$reset rounded w-full input input-sm text-gray-700 bg-gray-50"
            wrapper-class="max-w-full"
            :value="
              tenantsAffiliateGroupsStore.currentlyCreatingAffiliateGroup
                .entity_name
            "
            v-model.trim="
              tenantsAffiliateGroupsStore.currentlyCreatingAffiliateGroup
                .entity_name
            "
          />
          <FormKit
            type="tel"
            label="Entity Phone"
            placeholder=""
            :validation="'trim|length:10,10|number'"
            help-class="text-white"
            help=""
            outer-class="m-0 "
            input-class="$reset rounded w-full input input-sm text-gray-700 bg-gray-50"
            wrapper-class="max-w-full"
            :value="
              tenantsAffiliateGroupsStore.currentlyCreatingAffiliateGroup
                .entity_phone
            "
            v-model.trim="
              tenantsAffiliateGroupsStore.currentlyCreatingAffiliateGroup
                .entity_phone
            "
          />
          <FormKit
            type="email"
            label="Entity Email"
            placeholder=""
            :validation="'email|trim'"
            help-class="text-white"
            help=""
            outer-class="m-0 "
            input-class="$reset rounded w-full input input-sm text-gray-700 bg-gray-50"
            wrapper-class="max-w-full"
            :value="
              tenantsAffiliateGroupsStore.currentlyCreatingAffiliateGroup
                .entity_email
            "
            v-model.trim="
              tenantsAffiliateGroupsStore.currentlyCreatingAffiliateGroup
                .entity_email
            "
          />
          <FormKit
            type="url"
            label="Entity Website"
            placeholder=""
            :validation="'trim|length:5, 100'"
            help-class="text-white"
            help=""
            outer-class="m-0 "
            input-class="$reset rounded w-full input input-sm text-gray-700 bg-gray-50"
            wrapper-class="max-w-full"
            v-model.trim="
              tenantsAffiliateGroupsStore.currentlyCreatingAffiliateGroup
                .entity_website
            "
            :value="
              tenantsAffiliateGroupsStore.currentlyCreatingAffiliateGroup
                .entity_website
            "
          />
          <FormKit
            type="text"
            label="Entity Street Address"
            placeholder=""
            :validation="'trim|length:8, 100'"
            help-class="text-white"
            help=""
            outer-class="m-0 "
            input-class="$reset rounded w-full input input-sm text-gray-700 bg-gray-50"
            wrapper-class="max-w-full"
            v-model.trim="
              tenantsAffiliateGroupsStore.currentlyCreatingAffiliateGroup
                .entity_street_address
            "
            :value="
              tenantsAffiliateGroupsStore.currentlyCreatingAffiliateGroup
                .entity_street_address
            "
          />
          <FormKit
            type="text"
            label="Entity Suite/Unit"
            placeholder=""
            :validation="'trim|length:5, 8'"
            help-class="text-white"
            help=""
            outer-class="m-0 "
            input-class="$reset rounded w-full input input-sm text-gray-700 bg-gray-50"
            wrapper-class="max-w-full"
            v-model.trim="
              tenantsAffiliateGroupsStore.currentlyCreatingAffiliateGroup
                .entity_suite_unit
            "
            :value="
              tenantsAffiliateGroupsStore.currentlyCreatingAffiliateGroup
                .entity_suite_unit
            "
          />

          <FormKit
            type="text"
            label="Entity City"
            placeholder=""
            :validation="'trim|length:5, 100'"
            help-class="text-white"
            help=""
            outer-class="m-0 "
            input-class="$reset rounded w-full input input-sm text-gray-700 bg-gray-50"
            wrapper-class="max-w-full"
            v-model.trim="
              tenantsAffiliateGroupsStore.currentlyCreatingAffiliateGroup
                .entity_city
            "
            :value="
              tenantsAffiliateGroupsStore.currentlyCreatingAffiliateGroup
                .entity_city
            "
          />

          <FormKit
            type="select"
            label="Entity State"
            :options="stateOptions"
            placeholder=""
            :validation="'trim|length:2, 2|alpha'"
            help-class="text-white"
            help=""
            outer-class="m-0 "
            input-class="$reset rounded w-full input input-sm text-gray-700 bg-gray-50"
            wrapper-class="max-w-full"
            v-model="
              tenantsAffiliateGroupsStore.currentlyCreatingAffiliateGroup
                .entity_state
            "
          />

          <FormKit
            type="text"
            label="Entity Zip"
            value=""
            placeholder=""
            :validation="'trim|length:5,5|numeric'"
            help-class="text-white"
            help=""
            outer-class="m-0 "
            input-class="$reset rounded w-full input input-sm text-gray-700 bg-gray-50"
            wrapper-class="max-w-full"
            v-model.trim="
              tenantsAffiliateGroupsStore.currentlyCreatingAffiliateGroup
                .entity_zip
            "
            :value="
              tenantsAffiliateGroupsStore.currentlyCreatingAffiliateGroup
                .entity_zip
            "
          />
        </div>
      </FormKit>
    </template>

    <template #footer>
      <!-- BUTTON FOOTER - CANCEL & UPDATE AFFILIATE GROUP -->
      <div class="flex flex-row justify-between text-white w-full">
        <button
          @click="
            tenantsAffiliateGroupsStore.toggleTenantsAffiliateGroupsCreateModal
          "
          class="btn btn-xs md:btn-sm rounded bg-red-500 text-white hover:bg-red-700"
        >
          Cancel
        </button>
        <button
          @click="submitCreateForm"
          class="btn btn-xs md:btn-sm rounded bg-emerald-500 text-white hover:bg-emerald-700"
        >
          Create Affiliate Group
        </button>
      </div>
    </template>
  </cSidebarModal>
</template>
