import {
  //axios
  axios,

  //pinia
  defineStore,

  //env base url
  BASE_API_URL,
} from "../clientImports";

export const useSignUpStore = defineStore({
  id: "sign-up-store",
  state: () => ({
    signUpData: {},
  }),
  actions: {
    async submitSignUpData(formData) {},
  },
});
