import { io } from "socket.io-client";
import {
  //axios
  axios,

  //pinia
  defineStore,

  //env base url
  BASE_API_URL,
  useRouter,
  useRoute,

  //stores
  useGlobalToastsStore,
  useTenantsSessionsStore,
  useGptStore,
} from "../clientImports";

export const useLoginStore = defineStore({
  id: "login",
  state: () => ({
    sessionData: JSON.parse(localStorage.getItem("sessionData")) || null, // load initial state from localStorage

    socket: null,
    socket_id: null,
    socketStatus: null,

    domainUrlPrefix: null,
  }),
  actions: {
    setDomainPrefix(domainPrefix) {
      this.domainUrlPrefix = domainPrefix;
    },

    //gets set when the initial login is successful
    setSessionData(sessionData) {
      this.sessionData = sessionData;
      localStorage.setItem("sessionData", JSON.stringify(sessionData)); // store state in localStorage
    },

    updateSessionData(sessionDataChanges) {
      this.sessionData.foundUser = {
        ...this.sessionData.foundUser,
        ...sessionDataChanges,
      };
      localStorage.setItem("sessionData", JSON.stringify(this.sessionData)); // store state in localStorage
    },

    deleteLocalStorageData() {
      console.log("LOCAL STORAGE - SESSION WIPED");
      localStorage.removeItem("sessionData");
    },

    //used to set a wipe the session data
    async setSocketIdOnSession(socket_id) {
      const response = await axios.patch(
        // send the socket_id to the set-socket-id-on-session route
        `${BASE_API_URL}/set-socket-id-on-session/${socket_id}`
      );

      return response;
    },

    initSocketConnection() {
      const protocol =
        import.meta.env.VITE_ENV !== "staging" &&
        import.meta.env.VITE_ENV !== "production"
          ? "wss://"
          : "wss://";

      const endUrl =
        import.meta.env.VITE_ENV !== "staging" &&
        import.meta.env.VITE_ENV !== "production"
          ? "localhost:3000"
          : `${this.sessionData.tenant.domain_prefix}.app.finnypi.com`;

      console.log("SOCKET URL BASED ON ENV: ", `${protocol}${endUrl}`);

      const toastsStore = useGlobalToastsStore();
      // Initialize the WebSocket connection
      this.socket = io(`${protocol}${endUrl}`); // Or your server URL

      //listen for connection
      this.socket.on("connect", (socket) => {
        this.socketStatus = "connected";
        console.log("Socket Connected");

        this.socket_id = this.socket.id;

        this.setSocketIdOnSession(this.socket_id);
      });

      //listen for disconnection
      this.socket.on("disconnect", () => {
        this.socketStatus = "disconnected";
        console.log("Socket Disconnected");

        this.socket_id = null;
        this.setSocketIdOnSession(this.socket_id); //wipe the socket_id from the session
      });

      //listen for socket_status_global
      this.socket.on("socket_status_global", (data) => {
        console.log("LAST SOCKET STATUS: ", this.socketStatus);

        toastsStore.addToast({
          title: `${data.title}`,
          description: `${data.description}`,
          buttonExists: false,
          color: "blue",
          type: 4, // 1 = small, 2 = md, 3 = lg, 4 = lg dismissable
        });
      });

      //listen for socket_status_client_unique
      this.socket.on("socket_status_client_unique", (data) => {
        toastsStore.addToast({
          title: `${data.title}`,
          description: `${data.description}`,
          buttonExists: false,
          color: "blue",
          type: 4, // 1 = small, 2 = md, 3 = lg, 4 = lg dismissable
        });
      });
      //listen for socket_status_client_unique
      this.socket.on("session_took_over", (data) => {
        toastsStore.addToast({
          title: `${data.title}`,
          description: `${data.description}`,
          buttonExists: false,
          color: "orange",
          type: 5, // 1 = small, 2 = md, 3 = lg, 4 = lg dismissable
        });

        //broswer page refresh - forces the catch of session change and redirect back to login
        setTimeout(() => {
          window.location.reload();
        }, 5000);
      });

      this.socket.on("forced_logout", (data) => {
        toastsStore.addToast({
          title: `${data.title}`,
          description: `${data.description}`,
          buttonExists: false,
          color: "orange",
          type: 4, // 1 = small, 2 = md, 3 = lg, 4 = lg dismissable
        });

        //broswer page refresh - forces the catch of session change and redirect back to login
        setTimeout(() => {
          window.location.reload();
        }, 5000);
      });

      this.socket.on("tenants_active_users_panel", (data) => {
        const tenantsSessionsStore = useTenantsSessionsStore();

        tenantsSessionsStore.tenantsSessions = data.sessions;

        console.log("TENANTS ACTIVE USERS PANEL DATA", data);
      });

      this.socket.on("support_ai_chat_response", (data) => {
        const gptStore = useGptStore();

        gptStore.conversation.push({ data });

        console.log("SUPPORT AI CHAT RESPONSE", data);
      });
    },
  },
});
