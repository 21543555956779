<script setup>
defineOptions({
  name: 'CompanyLogo', // Give the component a name
});
import {
  //axios
  axios,

  //vue
  createApp,
  ref,
  reactive,
  watch,
  computed,
  inject,
  provide,
  onMounted,
  onUnmounted,
  nextTick,
  Teleport,

  //pinia
  defineStore,
  createPinia,
  storeToRefs,

  //pinia stores
  useChatStore,
  useContactAlertsStore,
  useContactFileStore,
  useContactsStore,
  useGlobalPromptsStore,
  useGlobalToastsStore,
  useLoginStore,
  useTenantsStore,
  useNetworkedCompaniesStore,

  //vue-router
  useRouter,
  useRoute,

  //formkit

  //formkit config file

  //fontawesome
  library,
  FontAwesomeIcon,
  fas,
  far,
  fab,

  //global components - all vue components
  registerGlobalComponents,

  //base api url
  BASE_API_URL,
} from "./../clientImports";

const networkedCompaniesStore = useNetworkedCompaniesStore();

//setup loginStore
const loginStore = useLoginStore();

const imageSrc = ref("");

//computed function that returns Every character at the beggining of each word in a string
const getInitials = computed(() => {
  return loginStore?.sessionData?.tenant?.company_display_name
    ?.split(" ")
    .map((word) => word[0])
    .join("");
});
</script>
<template>
  <div
    @click="
      loginStore?.sessionData?.permissions?.includes(
        'view:networked companies'
      ) || loginStore?.sessionData?.permissions?.includes('*:dev-permissions')
        ? networkedCompaniesStore.toggleNetworkedCompanies()
        : null
    "
    class="bg-base-300 hover:bg-slate-700 h-full border-r flex flex-row justify-between items-center border-r-slate-400 cursor-pointer"
  >
    <h1
      class="font-pacifico truncate text-xs md:text-xl px-2 md:px-5 p-2 bg-gradient-to-tr from-orange-500 via-indigo-500 to-green-500 mask-text"
      v-if="loginStore?.sessionData?.tenant?.company_display_name"
    >
      <span class="whitespace-nowrap hidden md:block">{{
        loginStore?.sessionData?.tenant?.company_display_name
      }}</span>
      <span class="whitespace-nowrap md:hidden">{{ getInitials }}</span>
    </h1>
    <img v-else class="h-full" :src="imageSrc" alt="" />
  </div>
</template>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Pacifico&display=swap");

.font-pacifico {
  font-family: "Pacifico", cursive;
}
</style>
