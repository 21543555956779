<script setup>
defineOptions({
  name: 'cTaglist', // Give the component a name
});
import { ref, watch, onMounted } from "./../clientImports";

// Define props
const props = defineProps({
  label: {
    type: String,
    required: false,
  },
  options: {
    type: Array,
    required: true,
    default: [
      {
        value: "example_1",
        label: "Example 1",
        color: "bg-red-500",
        description: "Example 1 description",
      },
      {
        value: "example_2",
        label: "Example 2",
        color: "bg-blue-500",
        description: "Example 2 description",
      },
    ],
  },
  currentValue: {
    type: Array,
    required: false,
  },
});

// Define emit event
const emits = defineEmits(["taglist-changed"]);

// Reactive ref for the taglist values
const theValues = ref(props.currentValue);

// Flag to track if component has mounted
let isMounted = ref(false);

// Watch for changes in theValues and emit the new value after component mount
watch(
  theValues,
  (newVal) => {
    if (isMounted.value) {
      emits("taglist-changed", newVal);
    }
  },
  { immediate: false }
);

// Set isMounted to true after the component has mounted
onMounted(() => {
  isMounted.value = true;
});
</script>

<template>
  <FormKit type="form" #default="{ value }" :actions="false">
    <FormKit
      type="taglist"
      name="taglist"
      :label="props.label"
      label-class="text-gray-50"
      placeholder="Example: Toyota"
      :options="props.options"
      popover
      selection-appearance="option"
      multiple
      selector-class="$reset w-full max-w-full"
      wrapper-class="$reset w-full max-w-full"
      tags-class="gap-2"
      v-model="theValues"
    >
      <!--TAG SLOT-->
      <template #tag="{ handlers, option, classes }">
        <div
          v-tippy="{ content: option.description, arrow: true }"
          :class="`bg-${option.color}-500`"
          class="flex flex-row items-center rounded text-white overflow-clip"
        >
          <span :class="classes.tagLabel" class="">
            {{ option.label }}
          </span>
          <button
            @click.prevent="handlers.removeSelection(option)()"
            tabindex="-1"
            type="button"
            aria-controls="input_1"
            class="text-white hover:bg-red-700 p-2"
          >
            <!-- font awesome x -->
            <font-awesome-icon icon="fa-solid fa-times" />
          </button>
        </div>
      </template>
      <!--/TAG SLOT-->
    </FormKit>
  </FormKit>
</template>
