<script setup>
defineOptions({
  name: 'SettingsTab', // Give the component a name
});
import {
  //axios
  axios,

  //vue
  createApp,
  ref,
  reactive,
  watch,
  computed,
  inject,
  provide,
  onMounted,
  onUnmounted,
  nextTick,
  Teleport,

  //pinia
  defineStore,
  createPinia,
  storeToRefs,

  //pinia stores
  useChatStore,
  useContactAlertsStore,
  useContactFileStore,
  useContactsStore,
  useGlobalPromptsStore,
  useGlobalToastsStore,
  useLoginStore,
  useTenantsStore,

  //vue-router
  useRouter,
  useRoute,

  //formkit

  //fontawesome
  library,
  FontAwesomeIcon,
  fas,
  far,
  fab,

  //global components - all vue components
  registerGlobalComponents,

  //base api url
  BASE_API_URL,
} from "./../clientImports";

// Call the useContactsStore function to get an instance of the store
const contactsStore = useContactsStore();

const router = useRouter();
const route = useRoute();

//gets the settings route and it's children, groups them by sectionName with meta property
const sortedSubProps = router.options.routes
  .find((route) => route.name === "root")
  .children.find((route) => route.name === "settings-tab")
  .children.reduce((acc, route) => {
    if (
      route.meta &&
      route.meta.sectionName &&
      route.meta.sectionName !== "Expert"
    ) {
      if (!acc[route.meta.sectionName]) {
        acc[route.meta.sectionName] = [];
      }
      acc[route.meta.sectionName].push(route);
      acc[route.meta.sectionName].sort((a, b) => a.meta.sort - b.meta.sort); // Sorting within each sectionName group
    }
    return acc;
  }, {});

const sortedMainProps = Object.entries(sortedSubProps)
  .sort((a, b) => a[1][0].meta.sectionSort - b[1][0].meta.sectionSort)
  .reduce((acc, [key, value]) => {
    acc[key] = value;
    return acc;
  }, {});

const minimizedSettings = ref(false);
</script>

<template>
  <div class="h-full w-full flex flex-row">
    <!-- settings list -->
    <div
      class="w-auto bg-gray-50 overflow-hidden flex flex-col flex-shrink-0 justify-between border-r border-slate-400"
    >
      <!-- top div of settings at start -->
      <div
        :class="minimizedSettings ? 'justify-center' : 'justify-between'"
        class="flex flex-row items-center bg-base-300 text-white text-xs md:text-base p-1 md:p-1 border-b border-slate-400"
      >
        <div class="md:pl-2" v-if="!minimizedSettings">Settings</div>
        <button
          @click="minimizedSettings = !minimizedSettings"
          class="btn btn-xs md:btn-sm bg-gray-500 text-white"
        >
          <font-awesome-icon
            v-if="!minimizedSettings"
            icon="fa-solid fa-chevron-left"
          />
          <font-awesome-icon
            v-if="minimizedSettings"
            icon="fa-solid fa-chevron-right"
          />
        </button>
      </div>
      <div
        :class="minimizedSettings ? 'text-center' : ''"
        class="h-full overflow-y-auto hide-scroll"
      >
        <div
          v-for="(keyValue, keyName) in sortedMainProps"
          :class="minimizedSettings ? 'border-b-2 border-slate-400' : ''"
        >
          <h1
            v-if="!minimizedSettings"
            class="sticky top-0 w-full bg-base-100 p-1 md:p-3 text-xs md:text-base text-white"
          >
            <span class="">
              {{ keyName }}
            </span>
          </h1>

          <router-link
            v-for="(link, i) in keyValue"
            :to="{ name: link.name }"
            active-class="disabled bg-indigo-400 text-white border-l-2 md:border-l-8 border-indigo-700"
            class="p-1 md:p-3 text-xs md:text-base block hover:bg-indigo-400 hover:text-white cursor-pointer rounded-none capitalize text-gray-700"
          >
            <font-awesome-icon :icon="link.meta.settingIcon" />
            <span v-if="!minimizedSettings" class="ml-3">{{
              link.meta.tabTitle
            }}</span>
          </router-link>
        </div>
      </div>
      <!-- options at end of div -->
      <div class="w-full flex flex-col">
        <router-link
          :to="{ name: 'settings-expert' }"
          active-class="disabled"
          class="p-1 md:p-3 text-xs md:text-base text-center text-white block hover:bg-indigo-400 hover:text-white cursor-pointer bg-gradient-to-t from-red-800 via-rose-600 to-red-400 border-none capitalize"
        >
          <font-awesome-icon icon="fa-solid fa-user-tie" />
          <span v-if="!minimizedSettings" class="ml-3"> Expert Settings </span>
        </router-link>
      </div>
    </div>
    <!-- main setting view -->
    <div class="flex-1 overflow-x-auto">
      <router-view></router-view>
    </div>
  </div>

  <!-- <div class="flex-1 bg-gray-50 h-full overflow-hidden"></div> -->
</template>
